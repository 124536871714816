import { orderAgainItemProxy } from "../../proxies/orderAgainItemProxy";

import { is3DPTechnology } from "../../utils/itemUtils";
import { isCustomMaterial, isCustomSurfaceFinish } from "../../utils/inputUtils";

import { TECHNOLOGY_OPTION_TYPE } from "../../constants/NewPartConstants";
import { ITEM_STATUS_MAPPING, TWO_D_IMAGE_URLS_KEY } from "../../constants";


// ------------------------------------------------------------------------------------------------

export const prepareOrderAgainItem = async ({
  existingItem,
  technologyOptions,
  loadMaterialCategoryOptions,
  loadThreeDMaterialOptions,
  loadSurfaceFinishOptions,
}) => {
  let existingItemProxy = new Proxy(existingItem, orderAgainItemProxy);
  let targetItem = existingItemProxy.getOrderAgainItem();
  targetItem = {
    ...targetItem,
    status: ITEM_STATUS_MAPPING.VERIFYING,
    checked: true,
  }

  targetItem.cadPart = existingItem.originalFiles?.split(',');

  if (targetItem.imageFile === null) {
    targetItem.imageFile = '';
  }

  if (targetItem.partApplication === null) {
    targetItem.partApplication = '';
  }

  if (!technologyOptions.includes(targetItem.technology)) {
    targetItem.technology = TECHNOLOGY_OPTION_TYPE.CUSTOM_FABRICATION_TECHNOLOGY;
    targetItem.otherTechnology = targetItem.technology;
  } else {
    targetItem.otherTechnology = null;
  }

  const metadata = existingItem.quoteMetadata || existingItem.metadata || {};
  let materialOptions = [];
  if (!is3DPTechnology(targetItem.technology)) {
    const { options: materialCategories } = await loadMaterialCategoryOptions(
      {
        technology: targetItem.technology
      },
      false,
    );
    materialOptions = materialCategories.flatMap(item => item.options);
  } else {
    const { options: threeDPMaterials } = await loadThreeDMaterialOptions({
      technology: targetItem.technology,
      threeDTechnology: metadata.threeDTechnology,
    }, false).catch(() => ({
      options: ['Custom Material'],
    }));
    materialOptions = threeDPMaterials;
    targetItem.threeDTechnology = metadata.threeDTechnology;
    targetItem.threeDInfill = metadata.threeDInfill;
    targetItem.threeDLayerThickness = metadata.threeDLayerThickness;
  }

  const material = existingItem.quoteMaterial || existingItem.material;
  if (isCustomMaterial(material)) {
    const otherMaterial = existingItem.quoteCustomMaterial || existingItem.customMaterial;
    targetItem.otherMaterial = otherMaterial;
    targetItem.material = 'Custom Material';
  } else if (!materialOptions.includes(material)) {
    targetItem.otherMaterial = material;
    targetItem.material = 'Custom Material';
  } else {
    targetItem.material = material;
    targetItem.otherMaterial = null;
  }

  const params = {
    technology: targetItem.technology,
    threeDTechnology: metadata.threeDTechnology,
    material: targetItem.material,
  }

  const { options: allSurfaceOptions } = await loadSurfaceFinishOptions(params, false);
  const surfaceFinish = existingItem.quoteSurfaceFinish || existingItem.surfaceFinish;
  if (isCustomSurfaceFinish(surfaceFinish)) {
    const otherSurfaceFinish = existingItem.quoteCustomSurfaceFinish
      || existingItem.customSurfaceFinish;
    targetItem.otherSurfaceFinish = otherSurfaceFinish;
    targetItem.surfaceFinish = 'Custom Finish';
  } else if (!allSurfaceOptions.includes(surfaceFinish)) {
    targetItem.otherSurfaceFinish = surfaceFinish;
    targetItem.surfaceFinish = 'Custom Finish';
  } else {
    targetItem.surfaceFinish = surfaceFinish;
    targetItem.otherSurfaceFinish = null;
  }

  targetItem.materialColor = existingItem.quoteMaterialColor || existingItem.materialColor;
  targetItem.color = existingItem.quoteColor || existingItem.color;
  targetItem.orderAgain = true;
  targetItem.qty = Number(existingItem.quoteQty)
    || Number(existingItem.quantity)
    || Number(existingItem.qty);

  if (targetItem.twoDImageUrl) {
    targetItem[TWO_D_IMAGE_URLS_KEY] = [targetItem.twoDImageUrl];
  }

  return targetItem;
}
