import React, { useReducer } from 'react';

import YesNoPopup from '../components/popups/YesNoPopup';

import { updateXeroInvoiceItemsStatus } from '../apis/xeroApi';

import { notifyError, notifySuccess } from '../services/notificationService';

function withConfirmDeleteXeroInvoiceItemsPopupHOC(WrappedComponent) {
  return function WrappedComponentWithConfirmDeleteXeroInvoiceItemsPopupHOC(
    props
  ) {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        xeroInvoiceID: null,
        xeroInvoiceUrl: null,
        refetchTableData: () => {},
        loadingUpdateStatus: false,
      }
    );

    const updateXeroInvoiceStatusHandler = () => {
      updateLocalState({ loadingUpdateStatus: true });
      updateXeroInvoiceItemsStatus(localState.xeroInvoiceID, {
        status: 'DELETED',
      })
        .then(() => {
          updateLocalState({ open: false });
          localState.refetchTableData();
          notifySuccess('Invoice deleted successfully');
        })
        .catch((err) => {
          notifyError(err.message);
        })
        .finally(() => {
          updateLocalState({ loadingUpdateStatus: false });
        });
    };

    const renderTitlePopup = () => {
      return (
        <div>
          Are you sure to update the{' '}
          <a href={localState.xeroInvoiceUrl} target='_blank' rel='noreferrer'>
            Invoice
          </a>{' '}
          status to deleted?
        </div>
      );
    };

    return (
      <>
        <WrappedComponent
          updateConfirmDeletePopupHoCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <YesNoPopup
            open={localState.open}
            title={renderTitlePopup()}
            handleNo={() => updateLocalState({ open: false })}
            handleYes={updateXeroInvoiceStatusHandler}
            loading={localState.loadingUpdateStatus}
          />
        )}
      </>
    );
  };
}

export default withConfirmDeleteXeroInvoiceItemsPopupHOC;
