import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { isDate, isEmpty } from 'lodash';

import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  withStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import { colors } from '../../palette';

import { getSurfaceFinishWithCustomizationsText, is3DPTechnology } from '../../utils/itemUtils';
import SingleImage from '../../components/images/SingleImage';
import { TWO_D_IMAGE_URLS_KEY } from '../../constants';
import { isEmptyValue } from '../../utils/commonUtils';
import { isCustomMaterial } from '../../utils/inputUtils';
import { convertCamelCaseToTitle } from '../../utils/stringUtils';

import { FtrSmallButton } from "../ftr-components/FtrButton";
import { FtrTypography } from "../ftr-components";
import { FtrCheckboxHeader } from '../ftr-components/table/FtrCheckboxHeader';

// Style components
const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    maxWidth: '950px',
  },
  container: {
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
    },
  },
  twoDImageContainer: {
    width: '160px',
    minWidth: '160px',
    height: '160px',
    minHeight: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px',
    marginBottom: '5px',
  },
  renderImage: {
    width: '160px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
      '& $render3dCAD': {
        display: 'block',
      },
    },
    padding: '1rem 1rem 1rem 1rem',
    radius: '20px',
  },
  tableRowRoot: {
    verticalAlign: 'top',
    backgroundColor: colors.fontWhite,
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '1.25rem',
  },
  hovered: {
    backgroundColor: colors.neutral020,
  },
  menuItemSelected: {
    backgroundColor: colors.neutral020,
  },
  addAddressButton: {
    color: colors.blue050,
  },
  deleteBtn: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
      minWidth: 'auto',
    },
  },
  trashIcon: {
    background: 'red',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
  summaryItemName: {
    width: '100%',
    fontSize: '14px',
    display: 'block',
    fontWeight: 600,
    lineHeight: '19.07px',
    paddingLeft: '0.75rem',
    paddingBottom: '5px',
    color: '#565656',
  },
  summaryText: {
    paddingLeft: '0.75rem',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.07px',
    color: colors.neutral060,
    width: '80%',
    alignItems: 'left',
    justifyContent: 'left',
    wordBreak: 'break-word',
  },
  title: {
    fontWeight: "bolder",
    fontSize: '24px',
    color: colors.neutral080,
    padding: '25px 25px',
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#565656',
  },
  specifications: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '10px 0px',
    width: '100%',
  },
  specificationKey: {
    fontWeight: 550,
    fontSize: '16px',
    color: colors.neutral080,
    flex: '0px 0px 200px',
    minWidth: '200px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '170px',
    }
  },
  specificationValue: {
    fontWeight: 400,
    fontSize: '16px',
    color: colors.neutral080,
    flex: 1,
  },
}));

/**
 * @param {Object} props
 * @param {Boolean} props.open - Whether the dialog is open or not
 * @param {Object} props.item - The current selected item
 * @param {Object} props.parts - The parts to apply the changes to
 * @param {Object} props.changes - The changes to apply to the parts
 * @param {Object} props.groupedChanges - The fields that are dependent on each other
 * @param {() => void} props.handleClose - The function to call when the dialog is closed
 * @param {() => void} props.handleConfirm - The function to call when the dialog is confirmed
 */
function ApplyToAllDialog(props) {
  const classes = useStyles();

  const {
    open,
    item,
    parts,
    changes,
    groupedChanges,
    loading = false,
    handleClose,
    handleConfirm,
  } = props;

  const [selectedFields, setSelectedFields] = useState({});
  const [selectedParts, setSelectedParts] = useState({});

  const [allFieldsSelected, setAllFieldsSelected] = useState(false);
  const [allPartsSelected, setAllPartsSelected] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  // Define custom styles for the Checkbox component
  const CustomCheckbox = withStyles({
    root: {
      '&$checked': {
        color: colors.blue060, // Blue color when checked
      },
    },
    checked: {},
  })((props) => <Checkbox color={colors.neutral050} {...props} />);

  // Selects all fields and parts when the dialog is opened
  useEffect(() => {
    setSelectedFields({ ...changes, ...groupedChanges });
    setSelectedParts({ ...parts });
  }, []);

  // Sets the select all checkbox for "Choose Parts"
  useEffect(() => {
    if (Object.keys(selectedParts).length === parts.length) {
      setAllPartsSelected(true);
    } else {
      setAllPartsSelected(false);
    }
  }, [selectedParts, parts])


  // Sets the select all checkbox for "Specifications"
  useEffect(() => {
    if (Object.keys(selectedFields).length ===
      Object.keys(changes).length + Object.keys(groupedChanges).length) {
      setAllFieldsSelected(true);
    } else {
      setAllFieldsSelected(false);
    }
  }, [selectedFields, changes, groupedChanges])

  useEffect(() => {
    setSelectedParts((prevSelectedParts) => {
      const newSelectedParts = { ...prevSelectedParts };
      newSelectedParts[item.id] = item;
      return newSelectedParts;
    });
  }, [item]);

  const onFieldCheck = (event) => {
    const { name, checked } = event.target;
    setSelectedFields((prevSelectedFields) => {
      const newSelectedFields = { ...prevSelectedFields };
      if (checked) {
        newSelectedFields[name] = changes[name];
      } else {
        delete newSelectedFields[name];
      }
      return newSelectedFields;
    });
  }

  const onGroupedFieldCheck = (event) => {
    const { checked } = event.target;
    setSelectedFields((prevSelectedFields) => {
      const newSelectedFields = { ...prevSelectedFields };
      if (checked) {
        Object.entries(groupedChanges).forEach(([key, value]) => {
          newSelectedFields[key] = value;
        });
      } else {
        Object.keys(groupedChanges).forEach(key => {
          delete newSelectedFields[key];
        });
      }
      return newSelectedFields;
    });
  }

  const onAllFieldsCheck = (event) => {
    if (event.target.checked) {
      setSelectedFields({
        ...changes,
        ...groupedChanges,
      });
    } else {
      setSelectedFields({});
    }
  }

  const onAllPartsCheck = (event) => {
    if (event.target.checked) {
      setSelectedParts({ ...parts });
    } else {
      setSelectedParts({});
    }
  }

  const onItemCheck = (event) => {
    const { name, checked } = event.target;
    setSelectedParts((prevSelectedParts) => {
      const newSelectedParts = { ...prevSelectedParts };
      if (checked) {
        newSelectedParts[name] = parts[name];
      } else {
        delete newSelectedParts[name];
      }
      return newSelectedParts;
    });
  }

  const confirmChanges = () => {
    handleConfirm(selectedFields, Object.values(selectedParts));
  }

  const render2DImage = (value) => {
    const imageUrl = value[TWO_D_IMAGE_URLS_KEY]
      ? value[TWO_D_IMAGE_URLS_KEY][0]
      : null;
    return imageUrl !== 'loading' ? (
      <SingleImage
        url={imageUrl}
        width={70}
        height={70}
        noBorder
      />
    ) : (
      <div className={classes.twoDImageContainer}>
        <CircularProgress
          style={{
            width: 30,
            height: 30,
          }}
        />
      </div>
    );
  };

  const renderItemInfo = (item) => {
    return (
      <Grid container justifyContent='flex-start' alignItems='flex-start' border='2px solid grey' style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className={classes.summaryItemName}>{item.name}</div>
        <Grid item xs={12} sm={5} md="auto" className={classes.summaryText}>
          {`${item.technology}`}
          {is3DPTechnology(item.technology) && `, ${item.threeDTechnology}`}
          {`, ${item.material}`}
          {isCustomMaterial(item.material) && `, ${item.otherMaterial}`}
          {!isEmpty(item.materialColor) && `, ${item.materialColor}`}
          {is3DPTechnology(item.technology) && !isEmpty(item.threeDInfill) && `, ${Number(item.threeDInfill * 100).toFixed(0)}%`}
          {is3DPTechnology(item.technology) && !isEmpty(item.threeDLayerThickness) && `, ${item.threeDLayerThickness.toFixed(2)}mm`}
          {(getSurfaceFinishWithCustomizationsText(item) !== 'NIL') && `, ${getSurfaceFinishWithCustomizationsText(item)}`}
          {`, Qty: ${item.qty}`}
          {`, +/- ${item.tolerance}mm`}
          {!isEmpty(item.remarks) && `, ${item.remarks}`}
          {`, ${isDate(item.expectedLeadTime) ? item.expectedLeadTime.toDateString() : "No Preference"}`}
          {!isEmpty(item.partApplication) && `, ${item.partApplication}`}
        </Grid>
      </Grid>
    );
  };

  const renderField = (key, value) => {
    switch (key) {
      case "Tolerance":
        value = `+/- ${value}mm`;
        break;
      case "Expected Lead Time":
        value = isDate(value) ? value.toDateString() : "No Preference";
        break;
      case "3 D Technology":
        key = "3D Technology";
        break;
      case "3 D Infill":
        key = "3D Infill";
        value = `${Number(value * 100).toFixed(0)}%`;
        break;
      case "3 D Layer Thickness":
        key = "3D Layer Thickness";
        value = `${Number(value).toFixed(2)}mm`;
        break;
      case "Qty":
        key = "Quantity";
        break;
      default:
        break;
    }
    return <div key={key} className={classes.specifications}>
      <span className={classes.specificationKey}>{key}</span>
      <span className={classes.specificationValue}>{value}</span>
    </div>
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (loading) {
          return;
        }
        handleClose();
      }}
      classes={{ paper: classes.paper }}
      scroll="paper"
      maxWidth="lg"
      fullScreen={isMobile}
    >
      <FtrTypography type='heading' fontSize='22' style={{ padding: '30px 0px 20px 40px' }}>
        Apply to all parts
      </FtrTypography>
      <DialogContent style={{ borderRadius: '30px' }}>
        <Grid container direction="row" spacing={1} style={{ display: 'flex', borderRadius: '30px', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Grid item xs={12} sm={5} style={{ width: 'calc(55% - 10px)', borderRadius: '20px', marginRight: isMobile ? '0rem' : '2.5rem', marginBottom: isMobile ? '1.9rem' : '0rem' }}>
            <FtrCheckboxHeader
              checked={allFieldsSelected}
              onChange={onAllFieldsCheck}
              title='Specifications'
            />
            <FormGroup>
              {Object.entries(groupedChanges).map(([key, value], index) => { // Render grouped fields first
                if (index === 0) { // First row ('technology' field) is handled separately
                  return (
                    <FormControlLabel
                      key={key}
                      control={
                        <CustomCheckbox
                          name={key}
                          onChange={onGroupedFieldCheck}
                          checked={!isEmptyValue(selectedFields['technology'])}
                          style={{ padding: isMobile ? 'auto' : '0.6rem 0.6rem' }}
                        />
                      }
                      label={renderField(convertCamelCaseToTitle(key), value)}
                      style={{
                        display: 'flex',
                        margin: '0 0 -0.1rem 0',
                        backgroundColor: !isEmptyValue(selectedFields['technology']) ? colors.paleBlue : colors.neutral010,
                        border: `1px solid ${colors.neutral030}`,
                        boxSizing: 'border-box', // Ensure consistent box sizing
                      }}
                    />
                  );
                } else {
                  return (
                    <div key={key}>
                      <label
                        style={{
                          display: 'flex',
                          margin: '0 0 -0.1rem 0',
                          border: `1px solid ${colors.neutral030}`,
                          boxSizing: 'border-box', // Ensure consistent box sizing
                          padding: '0px 45px',
                          backgroundColor: !isEmptyValue(selectedFields['technology']) ? colors.paleBlue : colors.neutral010,
                        }}>
                        {renderField(convertCamelCaseToTitle(key), value.toString())}
                      </label>
                    </div>
                  );
                }
              })}

              {changes && Object.entries(changes).map(([key, value], index, arr) => {
                const isLastItem = index === arr.length - 1;
                return (
                  <FormControlLabel
                    key={key}
                    control={
                      <CustomCheckbox
                        name={key}
                        onChange={onFieldCheck}
                        checked={key in selectedFields}
                        style={{ padding: '0px 10px' }}
                      />
                    }
                    label={renderField(convertCamelCaseToTitle(key), value)}
                    style={{
                      display: 'flex',
                      margin: '0 0 -0.1rem 0',
                      border: `1px solid ${colors.neutral030}`,
                      boxSizing: 'border-box', // Ensure consistent box sizing
                      backgroundColor: key in selectedFields ? colors.paleBlue : colors.neutral010,
                      borderRadius: isLastItem ? '0px 0px 20px 20px' : '0px'
                    }}
                  />
                );
              })}
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={5} style={{ width: '45%', borderRadius: '20px' }}>
            <FormControlLabel
              key='select-all-parts'
              control={
                <CustomCheckbox
                  name='select-all-parts'
                  onChange={onAllPartsCheck}
                  checked={allPartsSelected}
                  style={{ padding: '0px 10px' }}
                />
              }
              style={{
                backgroundColor: colors.neutral030,
                width: '100%',
                height: '68px',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                margin: '0 0 0 0',
              }}
              label={<Typography className={classes.subtitle}>Select Parts</Typography>}
            />
            <FormGroup>
              {parts && Object.entries(parts).map(([key, value], index, arr) => {
                const isLastItem = index === arr.length - 1;

                return (
                  <FormControlLabel
                    key={key}
                    control={
                      <CustomCheckbox
                        name={key}
                        onChange={onItemCheck}
                        checked={key in selectedParts}
                        style={{ padding: '0px 10px' }}
                      />
                    }
                    label={
                      <div style={{ display: "flex", padding: '15px 0px' }}>
                        {render2DImage(value)}
                        {renderItemInfo(value)}
                      </div>
                    }
                    style={{
                      margin: '0 0 -0.1rem 0',
                      width: '100%',
                      backgroundColor: key in selectedParts ? colors.paleBlue : colors.neutral010,
                      border: `1px solid ${colors.neutral030}`,
                      boxSizing: 'border-box', // Ensure consistent box sizing
                      borderRadius: isLastItem ? '0px 0px 20px 20px' : '0px'
                    }}
                  />
                );
              })}
            </FormGroup>
          </Grid>
        </Grid>
        <DialogActions>
          <FtrSmallButton
            color='blue'
            onClick={confirmChanges}
            disabled={isEmpty(selectedParts) || isEmpty(selectedFields)}
            style={{ marginTop: '30px' }}
          >
            {loading && <CircularProgress size={20} />}
            &nbsp;Proceed
          </FtrSmallButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default ApplyToAllDialog;
