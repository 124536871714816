import { getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

export const generatePoAutomationEmailsTrackingApi = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/generate-po-automation-email-tracking/tracking`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
