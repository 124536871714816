import { useEffect, useReducer, useState } from "react"

import { ceil, get } from "lodash";
import { isEmptyValue } from "../utils/commonUtils";
import { includesIgnoreCase } from "../utils/stringUtils";

export const useMuiTableSearchAndPagination = ({
  initialRows = [],
  initialColumns = [],
  rowsPerPage = 10,
  showAll = false,
}) => {
  const [allRows, setAllRows] = useState(initialRows);
  const [filteredRows, setFilteredRows] = useState(initialRows);
  const [columns, setColumns] = useState(initialColumns);
  const [displayRows, setDisplayRows] = useState([]);
  const [tablePaginationState, updateTablePaginationState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      from: 0,
      to: 10,
      totalCount: 0,
      totalPages: 0,
      currentPage: 1,
      rowsPerPage,
    }
  );
  const [searchTerm, setSearchTerm] = useState('');

  const getFilteredRows = (allRows, columns) => {
    const results = [];
    for (const row of (allRows ?? [])) {
      let searchSatisfied = false;
      for (const column of columns) {
        let data = '';
        const exportData = column['exportData'];
        if (typeof exportData === 'function') {
          data = `${exportData(row)}`;
        }
        else if (!isEmptyValue(column['field'])) {
          data = `${get(row, column['field'])}`;
        } else {
          continue;
        }
        if (includesIgnoreCase(data, searchTerm)) {
          searchSatisfied = true;
          break;
        }
      }
      if (searchSatisfied) {
        results.push(row);
      }
    }
    return results;
  }

  useEffect(() => {
    const filteredRows = isEmptyValue(searchTerm)
      ? allRows ?? []
      : getFilteredRows(allRows, columns);
    setFilteredRows(filteredRows);
    const from = showAll
      ? 0
      : (tablePaginationState.currentPage - 1) * tablePaginationState.rowsPerPage;
    let to = showAll
      ? filteredRows?.length
      : from + tablePaginationState.rowsPerPage;
    if (to > filteredRows?.length) {
      to = filteredRows.length;
    }
    const totalPages = ceil(filteredRows?.length / tablePaginationState.rowsPerPage);
    updateTablePaginationState({
      totalPages,
      from,
      to,
      totalCount: filteredRows?.length,
    });
    const displayRows = filteredRows?.slice(from, to);
    setDisplayRows(displayRows);
  }, [
    allRows,
    columns,
    tablePaginationState.rowsPerPage,
    tablePaginationState.currentPage,
    searchTerm,
  ]);

  const updateRowsPerPage = (rowsPerPage) => {
    updateTablePaginationState({
      rowsPerPage,
    });
  }

  const setCurrentPage = (currentPage) => {
    updateTablePaginationState({
      currentPage,
    });
  }

  return [
    {
      displayRows,
      filteredRows,
      ...tablePaginationState,
      searchTerm,
    },
    {
      setAllRows,
      setColumns,
      updateRowsPerPage,
      setCurrentPage,
      setSearchTerm,
    }
  ]
}
