import React from 'react';

import { FtrS4 } from '.';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------------------

function FtrTableEmptyRows(props) {
  const {
    text = 'No matches found',
  } = props;

  return (
    <FtrS4 style={{ color: colors.neutral060, textAlign: 'center', marginTop: '2rem' }}>
      {text}
    </FtrS4>
  );
}

export default FtrTableEmptyRows;
