import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';


// -------------------------------------------------------------------------------------------------

export const checkSimilarityPoQuotation = async (quotationID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/purchase-order/check-similarity-quotation/${quotationID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const checkSimilarityPoItem = async (itemID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/purchase-order/check-similarity-item/${itemID}`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).isSimilar;
  });
}
