import React, { useReducer } from 'react';

import FtrDeletePopup from '../components/ftr-components/FtrDeletePopup';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

function withFtrDeletePopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      title: 'Do you want to remove existing file?',
      onConfirm: () => { },
      onClose: () => { },
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    return (
      <>
        <WrappedComponent
          {...props}
          updateDeletePopupHOCState={updateLocalState}
        />
        {localState.open && (
          <FtrDeletePopup
            title={localState.title}
            subtitle=''
            open={localState.open}
            onConfirm={localState.onConfirm}
            onClose={() => {
              localState.onClose();
              updateLocalState({ open: false });
            }}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withFtrDeletePopupHOC;
