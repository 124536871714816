import {
  ADD_2D_IMAGE,
  RESET_2D_IMAGES_MAPPING,
  UPDATE_2D_IMAGE,
} from '../actions/twoDImages';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case RESET_2D_IMAGES_MAPPING: {
      return defaultState;
    }
    case ADD_2D_IMAGE:
    case UPDATE_2D_IMAGE: {
      const { key, value, errorMessage } = action.payload;
      return {
        ...state,
        [key]: value ?? errorMessage,
      };
    }
    default:
      return state;
  }
};
