import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Divider,
  Drawer,
  useMediaQuery,
  Link as LinkButton,
} from '@material-ui/core';

import CertificationSummary from '../../components/forms/part-upload-step-two/CertificationSummary';
import DisplayShippingOptions from '../../components/forms/part-upload-step-two/DisplayShippingOptions';
import FtrSvgImage from '../../components/images/FtrSvgImage';
import PricingSummaryDisplayPresentational from '../../components/forms/part-upload-step-two/PricingSummaryDisplayPresentational';
import FtrShowHidePromoCodeField from '../../components/ftr-components/FtrShowHidePromoCodeField';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import {
  FtrBanner,
  FtrBoldText,
  FtrButton,
  FtrTypography,
} from '../../components/ftr-components';
import Row from '../../components/Row';

import { initMultiCheckoutFormState } from '../../actions/multiCheckoutForm';

import LeftArrowIcon from '../../assets/icons/left_arrow_icon.svg';
import RedWarningSvgIcon from '../../assets/icons/red_warning.svg';

import { useCreditsBalance } from '../../hooks/useCreditsBalanceHook';
import useCustomerSettingsHook from '../../hooks/useCustomerSettingsHook';

import { extractPriceFromCurrencyString } from '../../utils/currencyUtils';
import { formatDeliveryDate } from '../../utils/dateTimeUtils';
import { getShippingCostOptions } from '../../utils/shippingUtils';
import { getSummaryLabels } from '../../utils/checkoutSummaryUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';

import ProjectItemsContext from '../../context/ProjectItemsContext';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { COUNTRY_NAMES, DELIVERY_OPTIONS_DISPLAY_MAPPING } from '../../constants/';
import { CUSTOMER_CREDIT_TYPES } from '../../constants/customerConstants';

import { colors } from '../../palette';

import withMctDeliveryBreakdownPopupHOC from '../../hocs/withMctDeliveryBreakdownPopupHOC';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '20%',
    minWidth: 300,
    background: colors.neutral010,
  },
  option: {
    '&:hover': {
      backgroundColor: colors.neutral020,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: colors.neutral020,
  },
  actionBtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  listItem: {
    display: 'block',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    }
  },
}));

function Heading(props) {
  return (
    <FtrTypography
      type='heading'
      fontSize='18'
      style={{ color: colors.neutral080, paddingBottom: '0.5rem' }}
    >
      {props.children}
    </FtrTypography>
  )
}

function CheckoutSummaryDrawer(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const isLowerMediumHeightScreen = useMediaQuery('(max-height:1080px)');

  const {
    addresses,
    currentProjectDetail,
    customerID,
    creditType,
    projectID,
    multiCheckoutForm,
    shippingModeList,
    multiCheckoutFormStatus,
    selectedQuotes,
    shippingMode,
    qcReports,
    currency,
    exchangeRate,
    setShowCheckoutSummaryFullPanel,
    refetchProjectInfoByID,
    setAddresses,
    setShippingMode,
    setUserSwitchShippingMode,
    updateConfirmOrderPopupState,
    isShareQuotationForm,
    updateShareQuotePopupState,
    isAdminView,
  } = useContext(ProjectItemsContext);

  const { customerSettings } = useCustomerSettingsHook(customerID);

  const authUserRole = useSelector(getUserRoleSelector);

  const {
    open,
    onClose = () => { },
    selectShippingOption = () => { },
    updateMctDeliveryBreakdownPopupHOCState = () => { },
  } = props;

  const selectedShippingOption = shippingModeList.find((option) => option.value === shippingMode);
  const estimatedDeliveryDate = selectedShippingOption?.date;

  const checkoutPriceSummary = multiCheckoutForm?.checkoutPriceSummary;
  const shippingCostOptions = getShippingCostOptions({
    deliveryDateModes: checkoutPriceSummary?.deliveryDateModes,
    deliveryModePrices: checkoutPriceSummary.deliveryModePrices,
  });

  const loading = multiCheckoutFormStatus === 'loading';

  const shippingCountry = multiCheckoutForm?.country;

  const showGst = shippingCountry === COUNTRY_NAMES.SINGAPORE;

  const {
    shippingFeeText,
    serviceFeeText,
    gstText,
    totalText,
    qcReportText,
  } = getSummaryLabels({
    showGst,
    gstPercent: checkoutPriceSummary?.gstPercent || 0,
  });

  const shippingCost = checkoutPriceSummary?.deliveryFeeInNumber || 0;
  const platformFee = checkoutPriceSummary?.platformFee || 0;
  const qcReportFee = checkoutPriceSummary?.qcReportFee || 0;
  const gstValue = extractPriceFromCurrencyString(checkoutPriceSummary?.gstInclAllFeeStr) || 0;
  const subTotal = extractPriceFromCurrencyString(checkoutPriceSummary?.totalCost) || 0;
  const estimatedTotal = extractPriceFromCurrencyString(checkoutPriceSummary?.totalCostInclGstStr) || 0;
  const originalTotal = extractPriceFromCurrencyString(checkoutPriceSummary?.totalCostBeforeDiscountStr) || 0;
  const totalDiscount = extractPriceFromCurrencyString(checkoutPriceSummary?.totalDiscountStr) || 0;
  const totalDiscountStr = checkoutPriceSummary?.totalDiscountStr;
  const totalDiscountText = `- ${totalDiscountStr}`;
  const factoremCustomDiscountCode = multiCheckoutForm?.factoremCustomDiscountCode || '';
  const { errorMessages, hasCustomDeliveryFee, isDhlApiSuccess } = checkoutPriceSummary || {};
  const isShippingApiFailed = !isEmptyValue(errorMessages) && !hasCustomDeliveryFee && !isDhlApiSuccess;
  const disableCheckout = isShippingApiFailed && !isAdminView;

  const {
    userCredits,
    creditBalanceAfter,
  } = useCreditsBalance(estimatedTotal);

  const renderShippingCostInfo = () => {
    return (
      <DisplayShippingOptions
        title={DELIVERY_OPTIONS_DISPLAY_MAPPING[shippingMode]}
        subInfo={estimatedDeliveryDate
          ? `Receive by ${formatDeliveryDate(estimatedDeliveryDate)}`
          : 'No estimation available'
        }
        loading={loading}
        price={selectedShippingOption?.fee}
        // titleTooltip={shippingTooltip}
        // titleTooltip={selectedOption?.toolTipText}
        shippingMode={shippingMode}
        shippingCostOptions={shippingCostOptions}
        selectShippingOption={selectShippingOption}
        hidePrice={disableCheckout}
      />
    );
  }

  const renderViewBreakdown = () => {
    const { dhlResult = {} } = checkoutPriceSummary || {};
    const { dhlShippingFee, dhlMultiplier } = dhlResult;

    const showViewBreakdown = isAdminView && (dhlShippingFee > 0 || dhlMultiplier > 0);

    if (!showViewBreakdown) {
      return null;
    }

    return (
      <LinkButton
        variant='link'
        style={{ cursor: 'pointer', fontSize: '14px' }}
        onClick={() => {
          updateMctDeliveryBreakdownPopupHOCState({
            open: true,
            data: {
              priceDetails: checkoutPriceSummary,
              deliveryOption: shippingMode,
              deliveryCost: checkoutPriceSummary.deliveryFeeStr,
            },
          });
        }}
      >
        View breakdown
      </LinkButton>
    );
  }

  const renderShippingErrorMessage = (message) => {
    return (
      <FtrBanner type='warning'>
        <Row>
          <img src={RedWarningSvgIcon} />
          <FtrTypography type='body' style={{ color: colors.red030 }} fontSize='14'>
            {message}
          </FtrTypography>
        </Row>
      </FtrBanner>
    )
  }

  const renderShippingMessage = () => {
    const { errorMessages } = checkoutPriceSummary || {};

    if (isShippingApiFailed) {
      if (!isAdminView) {
        return renderShippingErrorMessage('Please contact Factorem team to update your shipment fee');
      }
      return (
        <>
          {errorMessages.map((message, index) => (
            <div key={index}>
              {renderShippingErrorMessage(message)}
            </div>
          ))}
        </>
      )
    }

    return null;
  }

  const renderShippingSummary = () => {
    if (isEmptyValue(selectedQuotes)) {
      return;
    }

    return (
      <>
        <Heading>
          Shipping
        </Heading>
        <FlexColumn>
          {renderShippingCostInfo()}
          {renderShippingMessage()}
          {renderViewBreakdown()}
        </FlexColumn>
      </>
    );
  }

  const renderPricingSummary = () => {
    if (isEmptyValue(selectedQuotes) || disableCheckout) {
      return;
    }

    const renderSelectedQuoteText = () => {
      const text = selectedQuotes.length > 1
        ? `${selectedQuotes.length} quotes selected`
        : `${selectedQuotes.length} quote selected`;

      return (
        <FtrBoldText fontSize='16' style={{ color: colors.neutral070 }}>
          {text}
        </FtrBoldText>
      )
    }

    return (
      <>
        <Heading>
          Checkout Summary
        </Heading>
        {renderSelectedQuoteText()}
        <Box style={{ height: '1.5rem' }} />
        <PricingSummaryDisplayPresentational.Container>
          <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
            title='Subtotal'
            price={subTotal}
            currency={currency}
            exchangeRate={1}
            loading={loading}
          />
          <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
            title={shippingFeeText}
            price={shippingCost}
            currency={currency}
            exchangeRate={1}
            // tooltip={shippingTooltip}
            loading={loading}
          />
          <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
            title={serviceFeeText}
            price={platformFee}
            currency={currency}
            exchangeRate={1}
            // tooltip={shippingTooltip}
            loading={loading}
          />
          {qcReportFee > 0 && (
            <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
              title={qcReportText}
              price={qcReportFee}
              currency={currency}
              exchangeRate={1}
              loading={loading}
            />
          )}
          {gstValue !== 0 && (
            <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
              title={gstText}
              price={gstValue}
              currency={currency}
              exchangeRate={exchangeRate ?? 1}
              loading={loading}
            />
          )}
          {renderPromoCodeField()}
          {totalDiscount > 0 && (
            <FlexColumn style={{ gap: 0 }}>
              <Divider style={{ margin: '1rem 0' }} />
              <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
                price={originalTotal}
                title={totalText}
                currency={currency}
                exchangeRate={1}
                loading={loading}
              />
              <PricingSummaryDisplayPresentational.SubRow
                price={totalDiscountText}
                title='Discount'
                loading={loading}
              />
              <PricingSummaryDisplayPresentational.SubRow
                price={factoremCustomDiscountCode}
                title='Code'
                loading={loading}
              />
            </FlexColumn>
          )}
          <Divider style={{ margin: '1rem 0' }} />
          <PricingSummaryDisplayPresentational.ConvertedPriceTotalRow
            title={totalDiscount > 0
              ? 'Total payable.'
              : totalText
            }
            price={estimatedTotal}
            currency={currency}
            exchangeRate={1}
            loading={loading}
          />
          {userCredits > 0 && <Box style={{ height: '1rem' }} />}
          {userCredits > 0 && (
            <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
              title='Credit Available'
              price={parseFloat(userCredits)}
              currency={currency}
              exchangeRate={1}
              loading={loading}
            />
          )}
          {userCredits > 0 && (
            <PricingSummaryDisplayPresentational.ConvertedPriceSubRow
              title='Credit Balance (after transaction)'
              price={parseFloat(creditBalanceAfter)}
              currency={currency}
              exchangeRate={1}
              loading={loading}
            />
          )}
        </PricingSummaryDisplayPresentational.Container>
      </>
    );
  }

  const renderCheckoutButton = () => {
    return (
      <FtrButton
        variant='contained'
        color='blue'
        fullWidth
        onClick={() => {
          updateConfirmOrderPopupState({
            open: true,
            customerID,
            selectedQuotes,
            shippingMode,
            shippingModeList,
            addresses,
            currency,
            currentProjectDetail,
            onClose: () => {
              setShowCheckoutSummaryFullPanel(true);
            },
            onShippingModeChange: (value) => {
              setShippingMode(value);
              setUserSwitchShippingMode(true);
            },
            setAddresses,
            refreshProject: () => {
              refetchProjectInfoByID();
              dispatch(initMultiCheckoutFormState());
            },
          });
          onClose();
        }}
        disabled={loading || disableCheckout}
        id='checkout-btn'
      >
        Checkout
      </FtrButton>
    );
  }

  const renderShareQuotationFormButton = () => {
    return (
      <FtrButton
        variant='contained'
        color='blue'
        fullWidth
        onClick={() => {
          updateShareQuotePopupState({
            open: true,
            projectID,
            selectedQuotes,
            shippingMode,
            customerID,
            addresses,
          });
        }}
        disabled={loading}
      >
        Share Quote
      </FtrButton>
    );
  }

  const renderPromoCodeField = () => {
    if (creditType !== CUSTOMER_CREDIT_TYPES.NON_CREDIT
      || customerSettings?.seePromoCode !== 1
    ) {
      return;
    }

    return (
      <FtrShowHidePromoCodeField />
    );
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      variant='persistent'
      PaperProps={{ className: classes.drawer }}
      onClose={() => {
        onClose();
      }}
    >
      <div className={classes.listItem}>
        <FtrSvgImage
          src={LeftArrowIcon}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            cursor: 'pointer',
          }}
          width={6}
          height={12}
          onClick={onClose}
        />
      </div>
      <div className={classes.listItem}>
        <CertificationSummary
          qcReports={qcReports}
          selectedItems={selectedQuotes}
          editable={false}
        />
      </div>
      <div className={classes.listItem}>
        {renderShippingSummary()}
      </div>
      <div className={classes.listItem}>
        {renderPricingSummary()}
      </div>
      <div className={classes.listItem}>
        {!isShareQuotationForm && renderCheckoutButton()}
        {isShareQuotationForm && renderShareQuotationFormButton()}
      </div>
      {/* Add box to prevent PBrain from blocking checkout data for admin role */}
      {isAdminOrHigherRole(authUserRole) && isLowerMediumHeightScreen && (
        <Box style={{ minHeight: '9rem' }} />
      )}
    </Drawer>
  );
}

export default withMctDeliveryBreakdownPopupHOC(CheckoutSummaryDrawer);
