import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect, useDispatch } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { get, isEqual } from "lodash";

import { makeStyles } from "@material-ui/core/styles";

import {
  Badge,
  Breadcrumbs,
  Button,
  ButtonBase,
  ClickAwayListener,
  Divider,
  Fab,
  Grid,
  Grow,
  IconButton,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  // Add as AddIcon,
  Close as CloseIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DoneOutlined as DoneOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  MoreVert,
  ShoppingCart as ShoppingCartIcon,
} from "@material-ui/icons";

import ForwardEmailIcon from "../../assets/icons/forward_to_inbox_white.svg";

import ApproveMultipleQuotationsPopup from "../../components/popups/ApproveMultipleQuotationsPopup";
import ConfirmOrderPopup from "../../components/popups/ConfirmOrderPopup";
import CustomerItemMuiTable from "../../components/tables/CustomerItemMuiTable";
import DeleteWarningPopupV2 from "../../components/popups/DeleteWarningPopupV2";
import DeliveryOptionsPanel from "../../components/panels/DeliveryOptionsPanel";
import EditProjectOwnerPopup from "../../components/popups/EditProjectOwnerPopup";
import LoadingBackDropText from '../../components/LoadingBackDropText';
import MovePartsPopup from "../../pages/manage-project-items/MovePartsPopup";
import MultiCheckoutSummaryPanel from "../../components/panels/MultiCheckoutSummaryPanel";
import QcReportsDisplay from "../../components/info/QcReportsDisplay";
import QualityCertificationsPopup from "../../components/popups/QualityCertificationsPopup";
import ShareQuotationFormPopup from "../../components/popups/confirm-order-popup/ShareQuotationFormPopup";

import {
  customerDisableProject,
  editMultipleProjects,
  getProjectInfoByID,
  getProjectOwners,
  removeProjectOwner,
  updateProjectOwner,
  updateQcReports
} from '../../apis/projectApi';
import {
  deleteAssociatedOwner,
  getAssociatedOwners,
  getUserAddresses,
  getUserInfoWithCache,
} from '../../apis/userApi';

import { setUnitType } from "../../actions";
import {
  getCheckoutPricingSummary,
  initMultiCheckoutFormState,
  resetCheckoutPricingSummary,
  updateMultiCheckoutFormState,
  // updateMultiCheckoutCurrency,
  updateMulticheckoutFormAddress,
  updatePaymentType,
  updateShippingMode,
} from "../../actions/multiCheckoutForm";

import { usePrevious } from "../../hooks/usePrevious";

import { getItemDetailsApi } from '../../apis/itemApi';
import {
  checkCountryAddress,
  getDefaultAddresses,
} from '../../utils/addressUtils';
import { isEmptyValue } from "../../utils/commonUtils";
import { formatDeliveryDate } from "../../utils/dateTimeUtils";
import * as deliveryUtils from "../../utils/deliveryUtils";
import { getItemStageStatusText } from "../../utils/itemUtils";

import { notifyError, notifySuccess } from "../../services/notificationService";

import { COUNTRY_NAMES } from "../../constants/countryConstants";
import { CURRENCY } from '../../constants';
import { ITEM_STATUS_MAPPING } from "../../constants/itemStatus";
import { PAYMENT_TYPE } from '../../constants/paymentConstants';
import { SHIPPING_MODES } from "../../constants/checkoutConstants";
import {
  CUSTOMER_CANCEL_ORDER_REASON_MAPPING,
  DEFAULT_SHIP_MODE_LIST,
} from "../../constants/itemConstants";
import {
  FEATURE_FLAG_MULTI_CHECKOUT,
  FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP,
} from "../../constants/featureFlagConstants";

import { colors } from "../../palette";


// --------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    height: "100%",
    width: "100%",
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: colors.fontWhite,
    }
  },
  formComponent: {
    padding: '1rem 2rem',
    position: 'relative',
  },
  projectLabel: {
    margin: "30px 0 0",
    display: "flex",
  },
  projectHeader: {
    display: "flex",
    alignItems: "center",
  },
  textField: {
    color: colors.fontBlack,
  },
  headerStyle: {
    color: colors.blue060,
    fontSize: "12pt",
    fontWeight: 600,
    marginRight: "10px",
  },
  textStyle: {
    fontSize: "12pt",
  },
  supportStyle: {
    fontSize: "11pt",
    color: colors.fontFaintGrey,
  },
  breadcrumbLink: {
    color: colors.tertiaryBlue,
    fontSize: "11pt",
    "&:hover": {
      cursor: "pointer",
    }
  },
  breadcrumbLinkBold: {
    fontSize: "11pt",
  },
  chip: {
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: colors.menuItemSelected,
  },
  quoteNumberBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'white',
      color: 'green',
      border: '1px solid green',
    },
  },
  cartLoading: {
    fontSize: 10,
  },
  menuItem: {
    borderRadius: 12,
    margin: '0 4px',
    fontWeight: 400,
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
  },
}));

/**
 * @deprecated
 * @param {*} props 
 * @returns 
 */
export function ManageProjectItems(props) {
  const classes = useStyles();

  const {
    multiCheckoutForm,
    currency,
    exchangeRate,
  } = props;

  const checkoutPanelRef = useRef(null);
  const moreActionsRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const location = useLocation()

  const projectIdFromUri = window.location.href.split("/")[4];
  let projectID = projectIdFromUri
    ? projectIdFromUri
    : props.location.state.projectID;

  const previousCurrency = usePrevious(currency);
  const previousExchangeRate = usePrevious(exchangeRate);

  const { status: multiCheckoutFormStatus } = multiCheckoutForm;

  const [customerID, setCustomerID] = useState(null);
  const [currentProjectDetail, setCurrentProjectDetail] = useState(null);
  const [allParts, setAllParts] = useState([]);
  const [filteredParts, setFilteredParts] = useState([]);
  const [currentProjectName, setCurrentProjectName] = useState(null);
  const [onEditProject, setOnEditProject] = useState(false);
  const [deleteProjectDialog, setDeleteProjectDialog] = useState(false);
  const [movePartsDialog, setMovePartsDialog] = useState(false);
  const [projectToBeDeleted, setProjectToBeDeleted] = useState(null);
  const [isProjectDeletable, setIsProjectDeletable] = useState(true);
  const [projectOwner, setProjectOwner] = useState(null);
  const [openEditOwnerDialog, setOpenEditOwnerDialog] = useState(false);
  const [associatedOwners, setAssociatedOwners] = useState([]);
  const [creatorEmail, setCreatorEmail] = useState(null);
  const [selectedFilters] = useState([]);
  const [openQualityPopup, setOpenQualityPopup] = useState(false);
  const [qcReports, setQcReports] = useState(null);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [openApprovePopup, setOpenApprovePopup] = useState(false);
  const [shippingModeList, setShippingModeList] = useState(Object.values(DEFAULT_SHIP_MODE_LIST));
  const [shippingMode, setShippingMode] = useState(null);
  const [disableShippingMode, setDisableShippingMode] = useState(true);
  const [showCheckoutSummaryFullPanel, setShowCheckoutSummaryFullPanel] = useState(false);
  const [userSwitchShippingMode, setUserSwitchShippingMode] = useState(false);
  const [isShareQuotationForm, setIsShareQuotationForm] = useState(false);
  const [showShareQuotePopup, setShowShareQuotePopup] = useState(false);
  const [addresses, setAddresses] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [openMoreActions, setOpenMoreActions] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [hasDeliveryInfo, setHasDeliveryInfo] = useState(false);

  const previousSelectedQuotes = usePrevious(selectedQuotes);
  const previousShippingMode = usePrevious(shippingMode);
  const previousAddresses = usePrevious(addresses);

  useEffect(() => {
    dispatch(initMultiCheckoutFormState());
    return () => {
      dispatch(resetCheckoutPricingSummary());
      setSelectedQuotes([]);
    }
  }, []);

  useEffect(() => {
    const path = location.pathname.split('/')
    const isUrlVacAdmin = path[3] === 'vac'
    // update the redux unit type if admin with a vac url views the project
    if (isUrlVacAdmin && userDetail) {
      dispatch(setUnitType(userDetail.unitType))
    }
  }, [location.pathname, userDetail])

  const getProject = async (projectID) => {
    setProjectLoading(true);
    try {
      const response = await getProjectInfoByID(projectID);
      const userID = response.userId;
      setCustomerID(userID);
      const projectDetail = {
        projectID: response.id,
        userID,
        name: response.name,
        dateCreated: response.dateCreated.slice(0, 19).replace("T", " "),
        status: response.status,
        items: response.items,
        userEmail: response.userEmail,
        userName: response.userName,
        qcReports: response.qcReports,
        multipleCheckouts: response.multipleCheckouts,
      };
      setCurrentProjectDetail(projectDetail);
      setCurrentProjectName(projectDetail.name);
      setAllParts(
        response.items.filter(object => {
          return object.status !== 0;
        })
      );
      setIsProjectDeletable(
        response.items.some((item) => {
          return item.status <= ITEM_STATUS_MAPPING.QUOTES_AVAILABLE
            && item.priceBidded === null;
        })
      );
      setProjectOwner({
        ownerEmail: response.userEmail,
        ownerName: response.userName,
      });
      setCreatorEmail(response.userEmail);
      setProjectLoading(false);
    } catch {
      notifyError('Failed to fetch project details. Please try again later.');
    }
  }

  const handleEditProject = async (projects) => {
    const payload = {
      projects: projects,
      userID: customerID,
    };
    try {
      await editMultipleProjects(payload);
      notifySuccess('Your project name has been changed!');
      getProject(projectID);
    } catch {
      notifyError('Your project name cannot be changed. Please try again later.');
    }
  };

  const updateProjectOwnerDisplay = async () => {
    return getProjectOwners(projectID)
      .then(owners => {
        if (!isEmptyValue(owners)) {
          setProjectOwner(owners[0]);
        } else if (currentProjectDetail) {
          setProjectOwner({
            ownerEmail: currentProjectDetail.userEmail,
            ownerName: currentProjectDetail.userName,
          });
        }
      });
  }

  useEffect(() => {
    getProject(projectID)
      .then(updateProjectOwnerDisplay);
    dispatch(initMultiCheckoutFormState());
  }, [projectID]);

  useEffect(() => {
    getAssociatedOwners(customerID)
      .then(setAssociatedOwners);
  }, [customerID]);

  useEffect(() => {
    // dispatch(updateMultiCheckoutCurrency(currency));
    if (currency === CURRENCY.SGD) {
      dispatch(updatePaymentType(PAYMENT_TYPE.STRIPE_PAYNOW));
    } else {
      dispatch(updatePaymentType(PAYMENT_TYPE.STRIPE_CREDIT_CARD));
    }
  }, [currency]);

  useEffect(() => {
    const newFilteredParts = isEmptyValue(selectedFilters)
      ? allParts
      : allParts.filter(item => {
        const itemStatusText = getItemStageStatusText(item);
        return selectedFilters.includes(itemStatusText);
      });
    setFilteredParts(newFilteredParts);
  }, [allParts, selectedFilters]);

  useEffect(() => {
    if (!isEmptyValue(currentProjectDetail)) {
      setQcReports(currentProjectDetail.qcReports);
    }
  }, [currentProjectDetail]);

  const updateCheckoutSummary = () => {
    if (
      isEqual(previousSelectedQuotes, selectedQuotes)
      && isEqual(previousCurrency, currency)
      && isEqual(previousExchangeRate, exchangeRate)
      && isEqual(previousShippingMode, shippingMode)
      && isEqual(previousAddresses?.shipping?.country, addresses?.shipping?.country)
    ) {
      // this to prevent calling get price API multiple times
      return;
    }

    if (
      !isEmptyValue(previousAddresses?.shipping?.country)
      && isEmptyValue(addresses?.shipping?.country)
    ) {
      // prevent recall get price api
      return;
    }

    if (hasDeliveryInfo && isEmptyValue(addresses?.id)) {
      // prevent recall get price api
      return;
    }

    if (!isEmptyValue(selectedQuotes)) {
      if (shippingMode) {
        dispatch(getCheckoutPricingSummary({
          selectedQuotes,
          currency,
          exchangeRate,
          shippingMode,
          country: addresses?.shipping?.country,
          deliveryInfoID: addresses?.id,
        }));
      }
    } else {
      dispatch(resetCheckoutPricingSummary());
    }
  };

  useEffect(() => {
    updateCheckoutSummary();
    setUserSwitchShippingMode(false);
  }, [selectedQuotes, currency, exchangeRate]);

  useEffect(() => {
    updateCheckoutSummary();
  }, [shippingMode, addresses?.shipping?.country, addresses?.id]);

  useEffect(() => {
    const { selectedQuotes } = multiCheckoutForm ?? {};
    if (isEmptyValue(selectedQuotes)) {
      setShowCheckoutSummaryFullPanel(false);
    }
    setSelectedQuotes(selectedQuotes ?? []);
    const deliveryDateModes = get(multiCheckoutForm, ['checkoutPriceSummary', 'deliveryDateModes']);
    const deliveryModePrices = get(multiCheckoutForm, ['checkoutPriceSummary', 'deliveryModePrices']);
    if (!isEmptyValue(deliveryDateModes)) {
      setShippingModeList(Object.values(DEFAULT_SHIP_MODE_LIST)
        .filter(mode => Object.keys(deliveryModePrices).includes(mode.value))
        .map(mode => {
          const dateStr = deliveryDateModes[mode.value];
          return {
            ...mode,
            date: formatDeliveryDate(dateStr),
            fee: Number(deliveryModePrices[mode.value].fee) === 0 ? 'Free' : deliveryModePrices[mode.value].feeStr,
          };
        }));
      const bestShippingMode = deliveryUtils.getBestShippingMode(deliveryModePrices);
      if (bestShippingMode && bestShippingMode !== shippingMode && !userSwitchShippingMode) {
        setShippingMode(bestShippingMode);
        dispatch(updateShippingMode(bestShippingMode, false));
      }
      setDisableShippingMode(false);
    } else {
      setShippingModeList(Object.values(DEFAULT_SHIP_MODE_LIST));
      setDisableShippingMode(true);
      if (
        addresses?.shipping?.country
        && addresses?.shipping?.country !== COUNTRY_NAMES.SINGAPORE
      ) {
        // if shipping destination is outside of SGP then only SAME_DATE shipping mode is allowed
        setShippingMode(SHIPPING_MODES.SAME_DATE);
        dispatch(updateShippingMode(SHIPPING_MODES.SAME_DATE, false));
      } else {
        setShippingMode(SHIPPING_MODES.ONE_THREE_DAYS);
        dispatch(updateShippingMode(SHIPPING_MODES.ONE_THREE_DAYS, false));
      }
    }
  }, [multiCheckoutForm?.selectedQuotes, multiCheckoutForm?.checkoutPriceSummary]);

  useEffect(() => {
    const itemID = currentProjectDetail?.items[0]?.itemID;
    if (itemID) {
      let userInfo = null;
      getItemDetailsApi(itemID)
        .then((item) => getUserInfoWithCache(item?.userID))
        .then((user) => {
          userInfo = user;
          setUserDetail(userInfo)
          return getUserAddresses(user?.userID);
        })
        .then((userAddresses) => {
          const { defaultBilling, defaultShipping } = getDefaultAddresses(userAddresses);
          if (!isEmptyValue(userAddresses)) {
            setHasDeliveryInfo(true);
          }

          const _billing = defaultBilling || defaultShipping;
          let _addresses = {
            id: defaultShipping?.deliveryInfoID,
            shipping: {
              deliveryInfoID: defaultShipping?.deliveryInfoID,
              address: defaultShipping?.address || userInfo?.address,
              contactNumber: defaultShipping?.contactNumber || userInfo?.contact,
              contactName: defaultShipping?.contactName || userInfo?.name,
              country:
                defaultShipping?.country ||
                checkCountryAddress(defaultShipping?.address) ||
                userInfo?.country,
              postalCode: defaultShipping?.postalCode,
            },
            billing: {
              deliveryInfoID: _billing?.deliveryInfoID,
              address: _billing?.address,
              contactNumber: _billing?.contactNumber || userInfo?.contact,
              contactName: _billing?.contactName || userInfo?.name,
              country:
                _billing?.country ||
                checkCountryAddress(_billing?.address) ||
                userInfo?.country,
            },
          };
          setAddresses(_addresses);
          dispatch(updateMulticheckoutFormAddress({
            country: _addresses?.shipping?.country,
            deliveryInfoID: _addresses?.id,
          }));
          dispatch(updateMultiCheckoutFormState({
            billingAddressID: _billing?.deliveryInfoID,
          }));

          if (
            _addresses.shipping.country
            && _addresses.shipping.country !== COUNTRY_NAMES.SINGAPORE
          ) {
            // if shipping destination is outside of SGP then only SAME_DATE shipping mode is allowed
            setShippingMode(SHIPPING_MODES.SAME_DATE);
            dispatch(updateShippingMode(SHIPPING_MODES.SAME_DATE, false));
          } else {
            setShippingMode(SHIPPING_MODES.ONE_THREE_DAYS);
            dispatch(updateShippingMode(SHIPPING_MODES.ONE_THREE_DAYS, false));
          }
        })
        .catch(err => console.error(err));
    }
  }, [currentProjectDetail]);

  const handleProjectNameOnChange = event => {
    setCurrentProjectName(event.target.value);
  };

  const handleEditButtonOnClick = () => {
    setOnEditProject(true);
  };

  const handleDoneButtonOnClick = () => {
    let newProjectDetail = {
      name: currentProjectName,
      dateCreated: currentProjectDetail.dateCreated,
      status: currentProjectDetail.status,
      userID: currentProjectDetail.userID,
      projectID: currentProjectDetail.projectID,
      items: currentProjectDetail.items
    }
    handleEditProject([newProjectDetail]);
    setOnEditProject(false);
  };

  const handleConfirmDeleteProject = ({ feedback, additionalRemarks }) => {
    // deleteProject(projectToBeDeleted);
    const body = {
      projectIDs: [projectToBeDeleted.projectID],
      feedback: CUSTOMER_CANCEL_ORDER_REASON_MAPPING[feedback] || feedback,
      additionalRemarks,
    };
    customerDisableProject(body)
      .then(() => {
        notifySuccess('Your project has been deleted!');
        props.history.push("/manage-projects");
      })
      .catch(() => {
        notifyError('Your project name cannot be changed. Please try again later.');
      });
    setProjectToBeDeleted(null);
    setDeleteProjectDialog(false);
  };

  const handleCancelDeleteProject = () => {
    setProjectToBeDeleted(null);
    setDeleteProjectDialog(false);
  };

  const handleDeleteProject = project => {
    setProjectToBeDeleted(project);
    setDeleteProjectDialog(true);
  };

  const handleChangeOwner = (ownerName, ownerEmail) => {
    let ownerChangePromise;
    if (ownerEmail === currentProjectDetail.userEmail) {
      const body = {
        ownerEmail: projectOwner.ownerEmail,
      }
      ownerChangePromise = () => removeProjectOwner(projectID, body);
    } else {
      const body = {
        ownerEmail,
        ownerName,
      }
      ownerChangePromise = () => updateProjectOwner(projectID, body);
    }
    ownerChangePromise()
      .then(() => notifySuccess(`Owner has been updated successfully!`))
      .then(updateProjectOwnerDisplay)
      .then(() => getAssociatedOwners(customerID))
      .then(setAssociatedOwners)
      .catch((err) => notifyError(err.message));
  }

  const handleDeleteAssociateOwner = (ownerEmail) => {
    deleteAssociatedOwner(customerID, ownerEmail)
      .then(() => notifySuccess(`Owner has been deleted successfully!`))
      .then(updateProjectOwnerDisplay)
      .then(() => getAssociatedOwners(customerID))
      .then(setAssociatedOwners)
      .catch((err) => notifyError(err.message));
  }

  const isEditable = (item) => item.status === 5 || item.status === 6
    || (item.status === 1 && item.price === null);

  // TODO: temporarily disabled this
  // const handleFilterChange = (event) => {
  //   const { name, checked } = event.target;
  //   const newFilters = checked
  //     ? [...selectedFilters, name]
  //     : selectedFilters.filter(item => item !== name);
  //   setSelectedFilters(newFilters);
  // }

  const renderProjectOwner = () => {
    if (isEmptyValue(projectOwner)) {
      return null;
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: 'wrap',
        }}
      >
        <Typography className={classes.headerStyle}>
          Project Owner:
        </Typography>
        <Typography>
          {projectOwner.ownerName} {`<${projectOwner.ownerEmail}>`}
        </Typography>
        <IconButton aria-label="edit" onClick={() => setOpenEditOwnerDialog(true)}>
          <EditOutlinedIcon />
        </IconButton>
      </div>
    );
  }

  const renderEditOwnerDialog = () => {
    const ownerList = isEmptyValue(associatedOwners)
      ? associatedOwners
      : [
        ...associatedOwners,
        {
          ownerName: currentProjectDetail.userName,
          ownerEmail: currentProjectDetail.userEmail,
        }
      ];
    return (
      <EditProjectOwnerPopup
        dialog={openEditOwnerDialog}
        associatedOwners={ownerList}
        currentOwnerEmail={projectOwner ? projectOwner.ownerEmail : null}
        handleClose={() => setOpenEditOwnerDialog(false)}
        onOwnerChange={handleChangeOwner}
        onDeleteItem={handleDeleteAssociateOwner}
        creatorEmail={creatorEmail}
      />
    );
  }

  const renderProjectQcReports = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {/* TODO: temporarily removed */}
        {/* <div>
          <Button
            color="primary"
            size="medium"
            startIcon={<AddIcon />}
            onClick={() => setOpenQualityPopup(true)}
            disabled
          >
            Quality Certifications
          </Button>
        </div> */}
        <Typography className={classes.headerStyle}>
          Selected QC Report(s):
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '1rem',
            marginTop: '0.3rem',
          }}
        >
          <QcReportsDisplay qcReports={qcReports} />
        </div>
      </div>
    );
  }

  const renderQualityCertificationsPopup = () => {
    return (
      <QualityCertificationsPopup
        dialog={openQualityPopup}
        value={qcReports}
        onClose={() => setOpenQualityPopup(false)}
        onOk={(value) => {
          updateQcReports({ projectID, qcReports: value })
            .then(() => {
              setQcReports(value);
              setOpenQualityPopup(false);
              notifySuccess(`QC Reports is updated successfully`)
            })
            .catch(() => {
              notifyError(`Unable to update QC Reports`)
            });
        }}
      />
    )
  }

  const renderShippingModes = () => {
    return (
      <div
        style={{
          marginBottom: '1rem',
        }}
      >
        {['success', 'loading'].includes(multiCheckoutFormStatus) && (
          <DeliveryOptionsPanel
            shippingMode={shippingMode}
            onChange={(value) => {
              setShippingMode(value);
              dispatch(updateShippingMode(value));
              setUserSwitchShippingMode(true);
            }}
            shippingModeList={shippingModeList}
            disabled={disableShippingMode}
            status={multiCheckoutFormStatus}
          />
        )}
      </div>
    );
  }

  const renderCustomerItemTable = useCallback(() => {
    return (
      <CustomerItemMuiTable
        data={filteredParts}
        getItems={getProject}
        projectID={projectID}
        multipleCheckouts={currentProjectDetail ? currentProjectDetail.multipleCheckouts : []}
      />
    );
  }, [filteredParts, projectID]);

  const renderSupportMessage = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '1rem',
        }}
      >
        <div className={classes.supportStyle}>
          Need help updating your requirements?
          Contact us via&nbsp;
          <Tooltip title="+65 8925 8615">
            <a href="https://api.whatsapp.com/send?phone=6589258615&text=">
              WhatsApp
            </a>
          </Tooltip>
          &nbsp;or&nbsp;
          <Tooltip title="sales@factorem.co">
            <a href="mailto:sales@factorem.co">
              Email
            </a>
          </Tooltip>
          .
        </div>
      </div>
    );
  }

  const renderCheckoutSummary = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: 'sticky',
          top: '2rem',
          width: '100%',
        }}
      >
        {renderShippingModes()}
        <MultiCheckoutSummaryPanel
          onCheckOutClick={() => {
            if (isShareQuotationForm) {
              setShowShareQuotePopup(true);
              setShowCheckoutSummaryFullPanel(false);
            } else {
              setOpenApprovePopup(true);
              setShowCheckoutSummaryFullPanel(false);
            }
          }}
          showGst={addresses?.shipping?.country === COUNTRY_NAMES.SINGAPORE}
          buttonText={isShareQuotationForm ? "Share Quote" : "Checkout"}
          shippingCountry={addresses?.shipping?.country}
        />
        <span ref={checkoutPanelRef} />
      </div>
    );
  }

  const renderCheckoutFabButton = () => {
    return (
      <ButtonBase
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          borderRadius: '5rem',
          backgroundColor: colors.successGreen,
          margin: 'auto',
          marginRight: "1.5vw",
          cursor: 'pointer',
        }}
        onClick={() => {
          if (isMobile) {
            checkoutPanelRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsShareQuotationForm(false);
          } else {
            setShowCheckoutSummaryFullPanel(!showCheckoutSummaryFullPanel);
            setIsShareQuotationForm(false);
          }
        }}
        focusRipple
      >
        <Fab
          data-cy="shopping-cart-button"
          size={isMobile ? 'small' : 'medium'}
          color="primary"
          aria-label="checkout"
          disableRipple // Use ripple for the entire div instead
        >
          <Badge
            className={classes.quoteNumberBadge}
            badgeContent={selectedQuotes.length}
          >
            <ShoppingCartIcon />
          </Badge>
        </Fab>
        <Typography
          style={{
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          {get(multiCheckoutForm, ['checkoutPriceSummary', 'totalCost'])}
        </Typography>
      </ButtonBase>
    );
  }

  const renderShareQuotationFabButton = () => {
    return (
      <Tooltip
        title='Share quotation form'
        arrow
        placement='bottom'
      >
        <Fab
          data-cy="share-quotation-button"
          size={isMobile ? 'small' : 'medium'}
          aria-label="shareQuotation"
          style={{
            backgroundColor: multiCheckoutFormStatus !== 'success'
              ? colors.lightGray
              : colors.tertiaryBlue
          }}
          onClick={() => {
            if (isMobile) {
              checkoutPanelRef.current.scrollIntoView({ behavior: 'smooth' });
              setIsShareQuotationForm(true);
            } else {
              setShowCheckoutSummaryFullPanel(!showCheckoutSummaryFullPanel);
              setIsShareQuotationForm(true);
            }
          }}
          disabled={multiCheckoutFormStatus !== 'success'}
        >
          <Badge
            className={classes.quoteNumberBadge}
          >
            <img
              style={{ width: "30px", height: "30px" }}
              className={classes.imageIcon}
              src={ForwardEmailIcon} alt="quotation-sharing-icon" />
          </Badge>
        </Fab>
      </Tooltip>
    );
  }

  const renderSimpleCheckoutInfoRow = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 99,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            borderRadius: '5rem',
            margin: 'auto',
          }}
        >
          {renderCheckoutFabButton()}
          {renderShareQuotationFabButton()}
        </div>
      </Grid>
    );
  }

  const renderCheckoutSummarySlide = () => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 1000,
          overflowY: 'auto',
          overflowX: 'auto',
          height: '100%',
          paddingLeft: 25,
        }}
      >
        <Slide direction="left" in={showCheckoutSummaryFullPanel} mountOnEnter unmountOnExit>
          <div
            style={{
              backgroundColor: 'white',
              padding: '0.5rem',
              paddingTop: '1rem',
              position: 'relative',
            }}>
            <Fab
              style={{
                position: 'absolute',
                top: 0,
                left: -15,
                zIndex: 1999,
              }}
              aria-label="close"
              onClick={() => {
                setShowCheckoutSummaryFullPanel(false);
                setIsShareQuotationForm(false);
              }}
              size="small"
            >
              <CloseIcon />
            </Fab>
            {renderCheckoutSummary()}
          </div>
        </Slide>
      </div>
    );
  }

  const renderProjectActionButton = () => {
    return (
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}
      >
        <IconButton
          aria-label="more-actions"
          onClick={() => { setOpenMoreActions(true) }}
          ref={moreActionsRef}
        >
          <MoreVert />
        </IconButton>
        <Popper
          open={openMoreActions}
          anchorEl={moreActionsRef.current}
          transition
          disablePortal
          style={{
            zIndex: 1,
          }}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom'
                  ? 'center top'
                  : 'center bottom',
              }}
            >
              <Paper style={{ borderRadius: 8 }}>
                <ClickAwayListener
                  onClickAway={() => setOpenMoreActions(false)}
                >
                  <MenuList>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setDeleteProjectDialog(true);
                        setOpenMoreActions(false);
                      }}
                    >
                      Delete Project
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMovePartsDialog(true);
                        setOpenMoreActions(false);
                      }}
                      data-cy='logout-btn'
                    >
                      Move Parts
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }

  return (
    <div className={classes.formComponent}>
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            className={classes.breadcrumbLink}
            onClick={() => props.history.goBack()}
            aria-current="page"
          >
            All projects
          </Link>
          <Typography className={classes.breadcrumbLinkBold}>
            {currentProjectName}
          </Typography>
        </Breadcrumbs>
      </div>
      {!isMobile && renderCheckoutSummarySlide()}
      <div className={classes.projectLabel}>
        {/* overflow: clip overwrites parent overflow for position: sticky to work */}
        <Grid container spacing={2} style={{ overflow: 'clip' }}>
          <Grid container row xs={12}>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <div className={classes.projectHeader}>
                  <Typography className={classes.headerStyle}>
                    Project Name:
                  </Typography>
                  <TextField
                    size="small"
                    value={currentProjectName}
                    disabled={!onEditProject}
                    onChange={handleProjectNameOnChange}
                    InputProps={{ classes: { disabled: classes.textField } }}
                  />
                  {onEditProject ? (
                    <IconButton
                      aria-label="edit-success"
                      onClick={handleDoneButtonOnClick}
                    >
                      <DoneOutlinedIcon />
                    </IconButton>
                  ) : (
                    <IconButton aria-label="edit" onClick={handleEditButtonOnClick}>
                      <EditOutlinedIcon />
                    </IconButton>
                  )}
                  {isProjectDeletable &&
                    <Tooltip title="Delete Project">
                      <span>
                        <Button
                          onClick={() => handleDeleteProject(currentProjectDetail)}
                        >
                          <DeleteOutlinedIcon />
                        </Button>
                      </span>
                    </Tooltip>
                  }
                </div>
                <div className={classes.projectHeader}>
                  <Typography className={classes.headerStyle}>
                    Project ID:
                  </Typography>
                  <TextField style={{ paddingTop: 3 }}
                    disabled={true}
                    InputProps={{ classes: { disabled: classes.textField } }}
                    inputProps={{
                      "data-cy": "projectIDField",
                    }}
                    size="small"
                    value={projectID}
                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12}>
                {renderProjectOwner()}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
              {renderProjectQcReports()}
              {renderProjectActionButton()}
            </Grid>
            <Grid item xs={12}
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              <Divider />
            </Grid>
            {/* TODO: temporary remove */}
            {/* <div
              style={{
                marginTop: '1rem',
              }}
            >
              <ProjectStatusFiltersBar onFilterChange={handleFilterChange} />
            </div> */}
            <div className={classes.body}>
              {renderCustomerItemTable()}
              {!allParts.every(isEditable) && renderSupportMessage()}
            </div>
          </Grid>
          {FEATURE_FLAG_MULTI_CHECKOUT === 'true'
            && ['success', 'loading'].includes(multiCheckoutFormStatus)
            && !isEmptyValue(selectedQuotes)
            && renderSimpleCheckoutInfoRow()
          }
          {FEATURE_FLAG_MULTI_CHECKOUT === 'true'
            && isMobile
            && selectedQuotes.length > 0
            && (
              <div
                style={{
                  marginTop: '2rem',
                  width: '100%',
                }}
              >
                {renderCheckoutSummary()}
              </div>
            )
          }
        </Grid>
      </div>
      {deleteProjectDialog && (
        <DeleteWarningPopupV2
          open={deleteProjectDialog}
          onConfirm={handleConfirmDeleteProject}
          onCancel={handleCancelDeleteProject}
          onClose={handleCancelDeleteProject}
        />
      )}
      {openEditOwnerDialog && renderEditOwnerDialog()}
      {openQualityPopup && renderQualityCertificationsPopup()}
      {openApprovePopup && FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP !== 'true' && (
        <ApproveMultipleQuotationsPopup
          dialog={openApprovePopup}
          customerID={customerID}
          handleClose={() => {
            setOpenApprovePopup(false);
            setShowCheckoutSummaryFullPanel(true);
          }}
          quotes={selectedQuotes}
          shippingMode={shippingMode}
          onShippingModeChange={value => {
            setShippingMode(value);
            setUserSwitchShippingMode(true);
          }}
          shippingModeList={shippingModeList}
          refreshProject={() => {
            getProject(projectID);
            dispatch(initMultiCheckoutFormState());
            setOpenApprovePopup(false);
          }}
          currency={currency}
          project={currentProjectDetail}
          onAddressesChange={setAddresses}
        />
      )}
      {openApprovePopup && FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP === 'true' && (
        <ConfirmOrderPopup
          open={openApprovePopup}
          customerID={customerID}
          onClose={() => {
            setOpenApprovePopup(false);
            setShowCheckoutSummaryFullPanel(true);
          }}
          quotes={selectedQuotes}
          shippingMode={shippingMode}
          onShippingModeChange={value => {
            setShippingMode(value);
            setUserSwitchShippingMode(true);
          }}
          shippingModeList={shippingModeList}
          refreshProject={() => {
            getProject(projectID);
            dispatch(initMultiCheckoutFormState());
            setOpenApprovePopup(false);
          }}
          currency={currency}
          project={currentProjectDetail}
          onAddressesChange={setAddresses}
        />
      )}
      {showShareQuotePopup && (
        <ShareQuotationFormPopup
          open={showShareQuotePopup}
          handleClose={() => setShowShareQuotePopup(false)}
          projectID={projectID}
          itemIDList={selectedQuotes.map(quote => quote.itemID)}
          quotationIDList={selectedQuotes.map(quote => quote.quotationID)}
          shippingMode={shippingMode}
          userID={customerID}
          addresses={addresses}
        />
      )}
      {movePartsDialog && (
        <MovePartsPopup
          open={movePartsDialog}
          handleClose={() => setMovePartsDialog(false)}
          items={currentProjectDetail?.items}
        />
      )}
      {projectLoading && (
        <LoadingBackDropText
          open={projectLoading}
          text="Loading project..."
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
    multiCheckoutForm: state.multiCheckoutForm,
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
    country: state.auth.user.country,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withRouter(withConnect(ManageProjectItems));
