import React from 'react';

import { Box, Tooltip } from '@material-ui/core';

import HtmlContentDefect from '../HtmlContentDefect';
import { FtrButton } from '../ftr-components';
import { FlexRowCenter } from '../layouts/FlexLayouts';
import SingleImage from '../images/SingleImage';

import withDefectVisualizationPopupHOC from '../popups/withDefectVisualizationPopupHOC';
import withMouseHoverHOC from '../images/withMouseHoverHOC';

import { isEmptyValue } from '../../utils/commonUtils';
import { snakeCaseToTitleCase } from '../../utils/stringUtils';

import { colors } from '../../palette';

const SingleImageWithMouseEnter = withMouseHoverHOC(SingleImage);

function DefectThumbnail(props) {
  const {
    visualizationLinks,
    defectKey,
    handleView3DDefect,
    twoDImageUrl,
    cadFile,
    stateIndicator,
    ...restProps
  } = props;

  if (['failed', 'loading'].includes(stateIndicator)) {
    return null;
  }

  const defectUrl = visualizationLinks?.[defectKey];

  // If defectUrl is empty then render twoDImageUrl and native renderer
  if (isEmptyValue(defectUrl)) {
    return (
      <SingleImageWithMouseEnter
        url={twoDImageUrl}
        noBorder
        width={180}
        height={180}
        borderRadius={10}
        textStyle={{ color: 'white' }}
        text='View DFM Analysis'
        cadFile={cadFile}
        onClick={() => handleView3DDefect()}
        style={{ border: '1px solid' }}
        {...restProps}
      />
    );
  }

  return (
    <div>
      <Box
        style={{
          border: `1px solid ${colors.neutral060}`,
          borderRadius: '10px',
          width: 180,
          height: 180,
        }}
      >
        <HtmlContentDefect url={defectUrl} borderRadius='10px' />
      </Box>
      <FlexRowCenter style={{ marginTop: '8px' }}>
        <Tooltip title={`View Defect ${snakeCaseToTitleCase(defectKey)}`} arrow>
          <div>
            <FtrButton
              variant='outlined'
              color='blue'
              style={{
                marginLeft: '10px',
                padding: '3px 9px',
                minWidth: 'max-content',
              }}
              size='small'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleView3DDefect(defectUrl);
              }}
              {...restProps}
            >
              {snakeCaseToTitleCase(defectKey)}
            </FtrButton>
          </div>
        </Tooltip>
      </FlexRowCenter>
    </div>
  );
}

export default withDefectVisualizationPopupHOC(DefectThumbnail);
