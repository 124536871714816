// Import settings
import React from "react";

//Import material UI components
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Import color palette
import { colors } from "../palette";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 9000,
    color: colors.fontWhite
  },
  circularProgress: {
    position: "absolute"
  },
  circularProgressText: {
    position: "absolute",
    marginTop: "110px",
  }
}));

function LoadingBackDropText(props) {
  const classes = useStyles();
  const { text, open } = props;

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" className={classes.circularProgress} />

      <h3 className={classes.circularProgressText}>{text}</h3>
    </Backdrop>
  );
}

export default LoadingBackDropText;
