import {
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILURE,
  EDIT_PROFILE_LOADING,
  GET_PROFILE_FAILURE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_LOADING
} from "../actions/editProfile";
import { LOGOUT_SUCCESS } from "../actions/";

const defaultState = {
  editProfileLoading: false,
  editProfileSuccess: false,
  editProfileFailure: false,
  getProfileLoading: false,
  getProfileSuccess: false,
  getProfileFailure: false,
  profile: {},
  role: "",
  name: "",
  userID: "",
  email: "",
  companyName: "",
  companyDesc: "",
  secondaryName: "",
  secondaryEmail: "",
  secondaryContact: "",
  address: "",
  contact: "",
  profilePic: "",
  credits: ""
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_PROFILE_LOADING:
      return {
        ...state,
        getProfileLoading: true,
        getProfileSuccess: false,
        getProfileFailure: false
      };
    case GET_PROFILE_SUCCESS:
      return {
        getProfileLoading: false,
        getProfileSuccess: true,
        getProfileFailure: false,
        profile: action.profile,
        role: action.profile.role,
        name: action.profile.name,
        country: action.profile.country,
        userID: action.profile.userID,
        email: action.profile.email,
        companyID: action.profile.cCompanyID || action.profile.companyID,
        companyName: action.profile.cCompanyName || action.profile.companyName,
        companyDesc: action.profile.companyDesc,
        secondaryName: action.profile.secondaryName,
        secondaryEmail: action.profile.secondaryEmail,
        secondaryContact: action.profile.secondaryContact,
        address: action.profile.address,
        contact: action.profile.contact,
        profilePic: action.profile.profilePic,
        ndaFileUrl: action.profile.ndaFileUrl,
        ndaVerified: action.profile.ndaVerified,
        credits: action.profile.credits,
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        getProfileLoading: false,
        getProfileSuccess: false,
        getProfileFailure: true
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    case EDIT_PROFILE_LOADING:
      return {
        ...state,
        editProfileLoading: true,
        editProfileSuccess: false,
        editProfileFailure: false
      };
    case EDIT_PROFILE_FAILURE:
      return {
        ...state,
        editProfileLoading: false,
        editProfileSuccess: false,
        editProfileFailure: true
      };
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        editProfileLoading: false,
        editProfileSuccess: true,
        editProfileFailure: false,
        name: action.profile.name,
        profilePic: action.profile.profilePic
      };
    default:
      return state;
  }
};
