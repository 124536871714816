import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { FtrButton, FtrH5, FtrLightText } from '../ftr-components';

import { useUserFeedbackHook } from '../../hooks/useUserFeedbackHook';

import { renderQuestionV2 } from '../../utils/userFeedbackUtils';

import { ROLE_TYPES } from '../../constants';

import { colors } from '../../palette';
import { FlexColumn } from '../layouts/FlexLayouts';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      borderRadius: '1rem',
      width: 'auto',
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    cursor: 'pointer',
  },
}));

function UserFeedbackPopupV2(props) {
  const classes = useStyles();

  const {
    open,
    handleClose = () => { },
    userID,
    questionList = [],
    userRole = ROLE_TYPES.BUYER,
    submitLocation,
    checkoutID
  } = props;

  const [
    {
      qAndA,
      hasAllQuestionAnswered,
    },
    {
      updateAnswer,
      submitUserFeedback,
    },
  ] = useUserFeedbackHook({ questionList, checkoutID });

  const renderBody = () => {
    return (
      <FlexColumn
        style={{
          gap: '1.5rem',
        }}
      >
        {qAndA?.map((question => {
          return renderQuestionV2({
            question,
            handler: updateAnswer,
          });
        }))}
      </FlexColumn>
    );
  }

  return (
    <Dialog
      open={open}
      maxWidth='md'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <FlexColumn>
          <FtrH5>
            Just a minute for your feedback
          </FtrH5>
          <FtrLightText>
            We’re constantly working on improving experience at Factorem and value your feedback.
          </FtrLightText>
        </FlexColumn>
      </DialogTitle>
      <div
        style={{
          padding: '1rem 0 1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
          }}
        >
          {renderBody()}
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '1rem',
          }}
        >
          <FtrButton
            color='blue'
            onClick={() => {
              const submitRoleLocation = userRole === ROLE_TYPES.SUPPLIER
                ? 'First order shipped'
                : 'First checkout';
              const _submitLocation = submitLocation ?? submitRoleLocation;
              submitUserFeedback(userID, _submitLocation);
              handleClose();
            }}
            disabled={!hasAllQuestionAnswered}
          >
            Submit
          </FtrButton>
        </div>
      </div>
    </Dialog>
  );
}

export default UserFeedbackPopupV2;
