import React from 'react';
import { compose } from 'redux';

import withLoadingBackDropTextHOC from '../../hocs/withLoadingBackDropTextHOC';
import withSnackbarHOC from '../../hocs/withSnackbarHOC';
import withMovePartsPopupHOC from './withMovePartsPopupHOC';
import withDeletePartsPopupHOC from './withDeletePartsPopupHOC';
import withShareQuotePopupHOC from './withShareQuotePopupHOC';
import withConfirmOrderPopupHOC from './withConfirmOrderPopupHOC';
import withDeleteProjectFeedbackPopupHOC from './withDeleteProjectFeedbackPopupHOC';
import withEditProjectOwnerPopupHOC from './withEditProjectOwnerPopupHOC';
import withQualityCertificationsPopupHOC from './withQualityCertificationsPopupHOC';
import withAdminViewRedAlertBannerHOC from './withAdminViewRedAlertBannerHOC';
import withWelcomeBonusPopupHOC from '../../hocs/withWelcomeBonusPopupHOC';


// -------------------------------------------------------------------------------------------------

function withManageProjectItemsPopupsHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {

    const {
      setLoadingMessage = () => { },
    } = props;

    return (
      <>
        <WrappedComponent
          setLoadingMessage={setLoadingMessage}
          {...props}
        />
      </>
    );
  }

  return compose(
    withLoadingBackDropTextHOC,
    withMovePartsPopupHOC,
    withSnackbarHOC,
    withDeletePartsPopupHOC,
    withShareQuotePopupHOC,
    withConfirmOrderPopupHOC,
    withDeleteProjectFeedbackPopupHOC,
    withEditProjectOwnerPopupHOC,
    withQualityCertificationsPopupHOC,
    withAdminViewRedAlertBannerHOC,
    withWelcomeBonusPopupHOC,
  )(WrappedComponentWithHOC);
}

export default withManageProjectItemsPopupsHOC;
