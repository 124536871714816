import { useEffect, useReducer } from 'react';
import { uniqueId } from 'lodash';

import { isEmptyValue } from '../utils/commonUtils';

import { getCadPartS3Key, uploadFileToS3 } from '../services/s3Service';
import { convert2DImage } from '../services/convert2DImageService';
import { add2DImage, update2DImage } from '../actions/twoDImages';

const CAD_PART_ACTION_TYPE = {
  ADD_CAD_PART: 'ADD_CAD_PART',
  UPLOAD_CAD_FILE: 'UPLOAD_CAD_FILE',
  UPDATE_CAD_PART: 'UPDATE_CAD_PART',
  REMOVE_CAD_PART: 'REMOVE_CAD_PART',
  UPDATE_2D_IMAGE: 'UPDATE_2D_IMAGE',
  RESET_LIST_PART: 'RESET_LIST_PART',
  UPDATE_FILES_UPLOAD_STATUS: 'UPDATE_FILES_UPLOAD_STATUS',
};

/**
 * @deprecated
 */
export const useCadPartFileUpload = (reduxDispatch, initialState = []) => {
  function cadPartListReducer(state, action) {
    switch (action.type) {
      case CAD_PART_ACTION_TYPE.ADD_CAD_PART: {
        const newList = [...state.data, action.payload];
        return { data: newList };
      }
      case CAD_PART_ACTION_TYPE.UPDATE_CAD_PART: {
        const { id } = action.payload;
        const newList = state.data.map((item) => {
          if (item.id !== id) {
            return item;
          }
          return {
            ...item,
            ...action.payload,
            uploadStatus: 'success',
          };
        });
        return { data: newList };
      }
      case CAD_PART_ACTION_TYPE.REMOVE_CAD_PART: {
        const newList = state.data.filter(
          (item) => item.link !== action.payload.link
        );
        return { data: [...newList] };
      }
      case CAD_PART_ACTION_TYPE.UPDATE_2D_IMAGE: {
        const cadPart = state.data.find(
          (item) => item.link === action.payload.link
        );
        if (!cadPart) {
          return state;
        }
        cadPart.imageLoading = false;
        cadPart['2DImageUrl'] = action.payload['2DImageUrl'];
        cadPart.imageConvertingError = action.payload.errorMessage;
        return { data: [...state.data] };
      }
      case CAD_PART_ACTION_TYPE.RESET_LIST_PART: {
        return { data: [] };
      }
      case CAD_PART_ACTION_TYPE.UPDATE_FILES_UPLOAD_STATUS: {
        return {
          ...state,
          filesUploadStatus: action.payload,
        };
      }
      default:
        throw new Error(`Action type ${action.type} is missing.`);
    }
  }

  const [cadPartListState, dispatch] = useReducer(cadPartListReducer, {
    data: initialState,
    filesUploadStatus: '',
  });

  useEffect(() => {
    if (isEmptyValue(cadPartListState?.data)) {
      return;
    }
    const uploadDone = !cadPartListState.data.some(item => item.uploadStatus === 'ongoing');
    dispatch({
      type: CAD_PART_ACTION_TYPE.UPDATE_FILES_UPLOAD_STATUS,
      payload: uploadDone ? 'success' : 'ongoing',
    });
  }, [cadPartListState?.data]);

  const addCadPart = (cadPart) => {
    dispatch({
      type: CAD_PART_ACTION_TYPE.ADD_CAD_PART,
      payload: cadPart,
    });
  };

  const removeCadPart = (cadPartLink) => {
    dispatch({
      type: CAD_PART_ACTION_TYPE.REMOVE_CAD_PART,
      payload: {
        link: cadPartLink,
      },
    });
  };

  const update2DImageUrl = (s3ObjectUrl, twoDImageUrl, errorMessage) => {
    dispatch({
      type: CAD_PART_ACTION_TYPE.UPDATE_2D_IMAGE,
      payload: {
        link: s3ObjectUrl,
        '2DImageUrl': twoDImageUrl,
        errorMessage,
      },
    });
  };

  const updateCadPart = (payload) => {
    dispatch({
      type: CAD_PART_ACTION_TYPE.UPDATE_CAD_PART,
      payload,
    });
  };

  const resetListPart = () => {
    dispatch({
      type: CAD_PART_ACTION_TYPE.RESET_LIST_PART,
    });
  };

  const uploadCadFile = async (file) => {
    const id = uniqueId();
    addCadPart({
      id,
      uploadStatus: 'ongoing',
    });

    try {
      const data = await uploadFileToS3(file, getCadPartS3Key(file));
      const fileSize = parseInt(file.size / 1000, 10);
      const s3ObjectUrl = data.Location.split(' ').join('%20');
      const cadPart = {
        id,
        fileSize,
        link: s3ObjectUrl,
        imageLoading: false,
        file,
      };
      cadPart.imageLoading = true;
      updateCadPart(cadPart); // hook internal state
      reduxDispatch(add2DImage({ key: s3ObjectUrl, value: 'loading' })); // redux state
      convert2DImage({ file_url: s3ObjectUrl })
        .then((data_1) => {
          let twoDImageUrl;
          let errorMessage;
          if (isEmptyValue(data_1.error)) {
            twoDImageUrl = data_1['s3_file_url'];
          } else {
            errorMessage = data_1.error?.message;
            updateCadPart({
              id,
              imageLoading: false,
              error: true,
              errorMessage,
            }); // hook internal state
          }
          update2DImageUrl(s3ObjectUrl, twoDImageUrl, errorMessage); // internal state
          reduxDispatch(
            update2DImage({ key: s3ObjectUrl, value: twoDImageUrl, errorMessage }) // redux state
          );
        })
        .catch(() => {
          updateCadPart({
            id,
            imageLoading: false,
            error: true,
          }); // hook internal state
        });
      return s3ObjectUrl;
    } catch (err) {
      alert(err);
    }
  };

  return [
    cadPartListState,
    {
      addCadPart,
      updateCadPart,
      removeCadPart,
      update2DImageUrl,
      resetListPart,
      uploadCadFile,
    },
  ];
};
