import {
  GET_INVOICES_LOADING,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_LOADING,
  CREATE_INVOICE_FAILURE
} from "../actions";
import { LOGOUT_SUCCESS } from "../actions/";

const defaultState = {
  getInvoicesLoading: false,
  getInvoicesSuccess: false,
  getInvoicesFailure: false,
  invoices: [],
  createInvoiceLoading: false,
  createInvoiceSuccess: false,
  createInvoiceFailure: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_INVOICES_LOADING:
      return {
        ...state,
        getInvoicesLoading: true,
        getInvoicesSuccess: false,
        getInvoicesFailure: false
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        getInvoicesLoading: false,
        getInvoicesSuccess: true,
        getInvoicesFailure: false,
        invoices: action.invoices
      };
    case GET_INVOICES_FAILURE:
      return {
        ...state,
        getInvoicesLoading: false,
        getInvoicesSuccess: false,
        getInvoicesFailure: true
      };
    case CREATE_INVOICE_LOADING:
      return {
        ...state,
        createInvoiceLoading: true,
        createInvoiceSuccess: false,
        createInvoiceFailure: false
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        createInvoiceLoading: false,
        createInvoiceSuccess: true,
        createInvoiceFailure: false
      };
    case CREATE_INVOICE_FAILURE:
      return {
        ...state,
        createInvoiceLoading: false,
        createInvoiceSuccess: false,
        createInvoiceFailure: true
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
};
