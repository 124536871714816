import { getAuthorizedHeader } from "../utils/apiUtils";

import { BACKEND_SERVICE_URL } from "../constants";

export const regeneratePpeForItem = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/db-migrations/update-item-instant-ppe-log`;
  const body = {
    mode: 'ITEM_LIST',
    itemIDs: [itemID],
  };
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
