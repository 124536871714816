import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const getUnSeenUserInstantQuotesLog = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-instant-quotes-log/unseen/${userID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateUnSeenToSeenLog = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-instant-quotes-log/has-seen`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
