import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent, DialogTitle, Tooltip } from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import FtrTypography, { FtrH6, FtrLightText } from '../../ftr-components/FtrTypography';

import ChatSvgIcon from '../../../assets/icons/chat.svg';
import WhatsappSvgIcon from '../../../assets/icons/whatsapp.svg';
import EmailSvgIcon from '../../../assets/icons/email.svg';

import Row from '../../Row';

import { colors } from '../../../palette';

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function ContactSupportPopup(props) {
  const {
    open,
    onClose = () => { },
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={ChatSvgIcon} alt='icon' />
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <FtrH6 style={{ marginBottom: '1rem' }}>
          Talk with our team anytime
        </FtrH6>
        <FtrLightText>
          Select your preferred option
        </FtrLightText>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '2rem',
          }}
        >
          <Tooltip title="+65 8925 8615">
            <div>
              <Row
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const url = 'https://api.whatsapp.com/send?phone=6589258615&text=';
                  window.open(url, '_blank');
                }}
              >
                <img style={{ padding: '1rem' }} src={WhatsappSvgIcon} alt='icon' />
                <FtrTypography>
                  Whatsapp us
                </FtrTypography>
              </Row>
            </div>
          </Tooltip>
          <Tooltip title="sales@factorem.co">
            <di>
              <Row
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const mailToLink = 'mailto:sales@factorem.co';
                  window.location.href = mailToLink;
                }}
              >
                <img style={{ padding: '1rem' }} src={EmailSvgIcon} alt='icon' />
                <FtrTypography>
                  Email us
                </FtrTypography>
              </Row>
            </di>
          </Tooltip>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ContactSupportPopup;
