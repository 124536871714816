// Import settings
import React, { Fragment, useState } from 'react';

import { colors } from '../../palette';
import { round } from "lodash";

// Import material UI components
import {
  Backdrop,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles/index';

import { validateInput } from '../../utils/inputUtils';
import { getPpePriceForItem } from '../../utils/ppeUtils';

// Style the components
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: colors.fontWhite,
  },
  backdropContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  space: {
    width: theme.spacing(2),
  },
  tableRowRoot: {
    "&$tableRowSelected, &$tableRowSelected:hover": {
      backgroundColor: colors.secondaryBlue,
    }
  },
  tableRowSelected: {
    backgroundColor: colors.secondaryBlue,
  },
  titleText: {
    fontSize: '12pt',
    fontWeight: '600',
    marginRight: '10px',
  },
}));
  
function SetsInput(props) {
  const classes = useStyles();
  const { itemState, submitUpdatedItemState } = props;
  
  const [loading, setLoading] = useState(false);
  const [currentSets, setCurrentSets] = useState("1");
  const [newSets, setNewSets] = useState("1");

  const handleSetsOnChange = (event) => {
    setNewSets(event.target.value);
  };

  const hasSetQuantityChanged = !(currentSets === validateInput(newSets));

  const handleLoadNewProjectSets = async () => {
    if (newSets && hasSetQuantityChanged) {
      const validatedQty = validateInput(newSets);
      let newItemState = itemState;
      setLoading(true);
      newItemState = await Promise.all(newItemState.map((item) => {
        const newQty = round(item.qty / parseInt(currentSets, 10) * parseInt(validatedQty, 10), 0);
        let updatedPart = {
          ...item,
          qty: newQty,
        };
        return getPpePriceForItem(updatedPart);
      }))
      setLoading(false);
      setNewSets(validatedQty);
      setCurrentSets(validatedQty);
      submitUpdatedItemState(newItemState);
    } 
    // round off set qty, if non-integer keyed in
    if (newSets) {
      const validatedQty = validateInput(newSets);
      setNewSets(validatedQty);
    }
    // display old set qty if sets input blank
    if (!newSets) {
      setNewSets(currentSets);
    }
  };
  
  return (
    <Fragment>
      <Backdrop className={classes.backdrop} open={loading}>
        <div className={classes.backdropContent}>
          <CircularProgress color='inherit' />
          <Typography
            style={{
              textAlign: 'center',
            }}
          >
            Please wait for a couple of seconds while we generate the best price
            for you. Do not refresh this page or close the browser.
          </Typography>
        </div>
      </Backdrop>
      <Typography className={classes.titleText}>Sets:</Typography>
      <TextField
        inputProps={{
          inputMode: 'numeric', 
          style: { textAlign: 'center' }
        }}
        style={{
          width: 70
        }}
        type='number'
        value={newSets}
        onBlur={handleLoadNewProjectSets}
        onChange={handleSetsOnChange}
      />  
    </Fragment>
  );
}
  
export default SetsInput;  
