import {
  InputBase,
  MenuItem,
  Select,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { STANDARD_TOLERANCE_OPTIONS } from '../../constants/NewPartConstants';
import {
  DISPLAY_UNIT_OPTIONS,
  UNIT_TYPES,
} from '../../constants/unitConstants';
import { colors } from '../../palette';
import clsx from 'clsx';
import InfoIcon from '../icons/InfoIcon';

const BootstrapInput = withStyles((theme) => ({
  input: {
    minWidth: '7.5rem',
    borderRadius: 5,
    position: 'relative',
    backgroundColor: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    height: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    padding: '3px 0.5rem',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: colors.inputBorderBlue,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  select: {
    '& .MuiSelect-select.MuiSelect-select': {
      height: '2rem',
      fontSize: '1rem',
      padding: '5px 0.875rem',
      minWidth: '25rem',
    },
  },
}));

/**
 * A component to switch between metric and imperial units for tolerance settings.
 *
 * @param {Object} props - The component's props.
 * @param {string} props.unitType - The current unit type ('metric' or 'imperial').
 * @param {function} props.setUnitType - A function to update the unit type.
 *
 * @returns {JSX.Element} - The SwitchUnit component.
 */
export const SwitchUnit = (props) => {
  const { unitType, setUnitType, textStyle } = props;
  const unitText =
    unitType === UNIT_TYPES.METRIC
      ? 'imperial units (inches)'
      : 'metric units (mm)';
  return (
    <div style={{ display: 'flex', columnGap: '0.5rem' }}>
      <span
        style={{
          fontSize: 'small',
          fontStyle: 'italic',
          textDecoration: 'underline',
          color: colors.blueLink,
          cursor: 'pointer',
          ...textStyle,
        }}
        onClick={() => {
          const _unitType =
            unitType === UNIT_TYPES.METRIC
              ? UNIT_TYPES.IMPERIAL
              : UNIT_TYPES.METRIC;
          setUnitType(_unitType);
        }}
      >
        Switch to {unitText}
      </span>
      <InfoIcon toolTipText='Switch this setting to suit the units used in the drawing for this part' />
    </div>
  );
};

/**
 *
 * @param {Object} props
 * @param {string|number} props.value
 * @param {() => void} props.onChange
 * @param {'metric'|'imperial'} props.unitType
 * @param {'normal'|'big'} props.size
 * @param {boolean} props.disableBootstrapInput
 * @param {string} props.label
 * @param {() => void} props.setUnitType
 */
function ToleranceDropdown(props) {
  const {
    value,
    onChange,
    unitType = 'metric',
    size = 'normal',
    disableBootstrapInput = false,
    label = null,
    setUnitType = () => null,
  } = props;
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
      <Select
        id='tolerance'
        input={
          !disableBootstrapInput ? (
            <BootstrapInput
              className={clsx(size === 'big' && classes.select)}
            />
          ) : null
        }
        style={{ flex: !disableBootstrapInput ? '0 1 auto' : '1' }}
        value={value}
        onChange={onChange}
        label={label}
      >
        {STANDARD_TOLERANCE_OPTIONS[unitType]?.map((option) => (
          <MenuItem key={option} value={option}>
            +/- {option}
            {DISPLAY_UNIT_OPTIONS[unitType]?.LENGTH}
          </MenuItem>
        ))}
      </Select>
      <SwitchUnit setUnitType={setUnitType} unitType={unitType} />
    </div>
  );
}

export default ToleranceDropdown;
