import { getAuthorizedHeader } from "../utils/apiUtils";

import { BACKEND_SERVICE_URL } from "../constants";

export const deleteItemCollectionNotice = async (itemCollectionNoticeID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/item-collection-notice/${itemCollectionNoticeID}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

