import {
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAILURE,
  RESET_STATUS,
  QUESTION_ANSWERED,
  REMOVE_PASSWORD,
} from "../actions/createUser";

const defaultState = {
  signupSuccess: false,
  signupFailure: false,
  verifyEmailSuccess: false,
  verifyEmailFailure: false,
  resendEmailSuccess: false,
  resendEmailFailure: false,
  questionAnswered: false,
  email: "",
  name: "",
  password: null,
  country: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SIGNUP_FAILURE:
      return {
        ...state,
        signupSuccess: false,
        signupFailure: true
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        signupSuccess: true,
        signupFailure: false,
        email: action.email,
        name: action.name,
        password: action.password,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmailSuccess: true,
        verifyEmailFailure: false,
        country: action.country,
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        resendEmailFailure: false,
        resendEmailSuccess: false,
        verifyEmailSuccess: false,
        verifyEmailFailure: true
      };
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmailSuccess: false,
        verifyEmailFailure: false,
        resendEmailSuccess: true,
        resendEmailFailure: false,
      };
    case RESEND_EMAIL_FAILURE:
      return {
        ...state,
        resendEmailSuccess: false,
        resendEmailFailure: true,
      };
    case QUESTION_ANSWERED:
      return {
        ...state,
        questionAnswered: true,
      };
    case REMOVE_PASSWORD:
      return {
        ...state,
        password: null,
      };
    case RESET_STATUS:
      return {
        ...defaultState,
        email: state.email,
        name: state.name,
        country: state.country,
      }
    default:
      return state;
  }
};
