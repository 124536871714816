import { notifyError, notifySuccess } from '../services/notificationService';

import { updateCustomerSettingsApi } from '../apis/customerSettingsApi';

export const TOGGLE_PART_UPLOAD_REVAMP = 'TOGGLE_PART_UPLOAD_REVAMP';
export const SET_PART_UPLOAD_REVAMP = 'SET_PART_UPLOAD_REVAMP';
export const UPDATE_CUSTOMER_SETTINGS = 'UPDATE_CUSTOMER_SETTINGS';


// --------------------------------------------------------------------------------------------

export const setPartUploadRevamp = (value) => {
  return {
    type: SET_PART_UPLOAD_REVAMP,
    payload: value,
  }
}

export const setCustomerSettings = (value) => {
  return {
    type: UPDATE_CUSTOMER_SETTINGS,
    payload: value,
  }
}

export const updatePartUploadRevampFeature = (userID, { partUploadRevampFeature }) => (dispatch) => {
  return updateCustomerSettingsApi(userID, { partUploadRevampFeature })
    .then(() => {
      dispatch(setPartUploadRevamp(partUploadRevampFeature));
      notifySuccess('Setting has been updated successfully');
    })
    .catch(() => {
      notifyError(`Updated failed`);
    });
};

export const updateCustomerSettings = (userID, body,) => async (dispatch) => {
  await updateCustomerSettingsApi(userID, body);
  dispatch({
    type: UPDATE_CUSTOMER_SETTINGS,
    payload: body,
  });
};

export const updateCustomerSettingsWithToastNotification = (userID, body,) => async (dispatch) => {
  try {
    dispatch(updateCustomerSettings(userID, body));
    notifySuccess('Setting has been updated successfully');
  } catch {
    notifyError(`Updated failed`);
  }
};
