export const IMAGES_EXTENSIONS = ['.jpg', '.png', '.jpeg'];

export const PDF_IMAGES_EXTENSIONS = [...IMAGES_EXTENSIONS, 'pdf'];

export const FIFTY_MB_IN_BYTES = 52428800;

export const ALLOWED_FILE_TYPES_IPHONE = [
  // Document formats
  ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".txt", ".rtf", ".html", ".htm",

  // Image formats
  ".jpg", ".jpeg", ".png", ".gif", ".heic", ".bmp", ".tiff", ".tif",

  // Video formats
  ".mp4", ".m4v", ".mov", ".avi", ".hevc", ".mkv",

  // Audio formats
  ".mp3", ".aac", ".wav", ".m4a", ".flac",

  // Archive formats
  ".zip", ".rar", ".7z",

  // 3D and CAD formats (may need third-party apps)
  // ".stl", ".obj", ".3mf", ".step", ".stp",

  // E-book formats
  ".epub", ".mobi",

  // Miscellaneous formats
  ".csv", ".ics"
];
