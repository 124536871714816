import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const getItemAndQuotesInfo = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/international-shipping/items-and-quotes-info`;
  return fetch(requestUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getPackageOptimizationInfo = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/international-shipping/package-info`;
  return fetch(requestUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
