import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';

import {
  DescriptionOutlined as DescriptionOutlinedIcon,
  MonetizationOn as MonetizationOnIcon,
} from '@material-ui/icons';

import DateFormatMethod from '../components/DateFormatMethod';
import DownloadableFileWithActions from './list-items/DownloadableFileWithActions';
import InfoIcon from './icons/InfoIcon';
import YesNoPopup from './popups/YesNoPopup';
import { FtrTooltip } from './ftr-components';

import FileIcon from '../assets/img/file.png';

import {
  addItemCustomerPoFiles,
  getQuotationFormForItemWithCache,
  removeItemCustomerPoFiles,
} from '../apis/itemApi';

import { downloadS3File } from '../utils/fileUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { convertPriceWithQuantityToCurrency, getCurrencySymbol } from '../utils/currencyUtils';
import { showUnitValueFromMetric } from '../utils/userUtils';
import {
  getDeliveryOptionTooltipText,
  getFileNameFromCadFile,
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
  getTechnologyDisplayText,
  is3DPTechnology,
} from '../utils/itemUtils';

import ProjectItemsContext from '../context/ProjectItemsContext';

import { notifyError, notifySuccess } from '../services/notificationService';

import { CURRENCY, ITEM_STATUS_MAPPING } from '../constants';
import { THREE_D_P_FDM_TECH } from '../constants/NewPartConstants';
import { DELIVERY_OPTIONS_DISPLAY_MAPPING, ITEM_FILE_UPLOAD_TYPE } from '../constants/itemConstants';
import { CUSTOMER_CREDIT_TYPES } from '../constants/customerConstants';

import { colors } from '../palette';


// -------------------------------------------------------------------------------------------------------

// Style components
const useStyles = makeStyles(() => ({
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  rowDetail: {
    color: colors.fontGrey,
    display: 'flex',
    fontSize: '0.95rem',
    marginBottom: 9,
  },
  rowTitle: {
    fontWeight: 600,
  },
  formRowTitle: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  rowContent: {
    marginLeft: 5,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
}));

function PartDetails(props) {
  const classes = useStyles();

  const {
    creditType,
  } = useContext(ProjectItemsContext);

  const {
    partInfo,
    currency,
    getItems,
  } = props;

  const [openDeletePoFilePopup, setOpenDeletePoFilePopup] = useState(false);
  const [poFileToBeDeleted, setPoFileToBeDeleted] = useState(null);
  const [customerPoFiles, setCustomerPoFiles] = useState(
    (partInfo.customerUploadFiles || [])
      .filter(uploadItem => uploadItem.type === ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO)
  );
  const [quotationFormUrl, setQuotationFormUrl] = useState(partInfo.quotationForm || partInfo.qQuotationForm);

  const currencySymbol = getCurrencySymbol(currency);

  const showXeroInvoiceUrl = !isEmptyValue(partInfo.invoiceUrl)
    && (
      creditType === CUSTOMER_CREDIT_TYPES.NON_CREDIT
      || partInfo.status === ITEM_STATUS_MAPPING.DELIVERED
    );

  useEffect(() => {
    if (isEmptyValue(quotationFormUrl) || quotationFormUrl !== 'loading') {
      return;
    }

    updateQuotationFormUrl();
  }, [quotationFormUrl, partInfo.itemID]);

  useEffect(() => {
    setCustomerPoFiles((partInfo.customerUploadFiles || [])
      .filter(uploadItem => uploadItem.type === ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO))
  }, [partInfo.customerUploadFiles]);

  function updateQuotationFormUrl() {
    if (isEmptyValue(partInfo.itemID)) {
      return;
    }

    getQuotationFormForItemWithCache(partInfo.itemID)
      .then(url => {
        if (url) {
          setQuotationFormUrl(url)
        } else {
          // recall if getting null due to time out at BE side
          updateQuotationFormUrl();
        }
      });
  }

  const handleDeletePoFile = () => {
    setOpenDeletePoFilePopup(false);
    const body = [{
      itemID: partInfo.itemID,
      files: [poFileToBeDeleted],
    }]
    removeItemCustomerPoFiles(body)
      .then(() => {
        notifySuccess('File deleted successfully');
        setCustomerPoFiles(customerPoFiles.filter(file => file.fileName !== poFileToBeDeleted.fileName || file.url !== poFileToBeDeleted.url));
        getItems();
      })
      .catch(() => notifyError('Error deleting file'));
  }

  const renderCustomerPoFiles = () => {
    if (isEmptyValue(customerPoFiles)) {
      return null;
    }

    return (
      <div
        style={{
          marginTop: '0.2rem',
        }}
      >
        <div className={classes.rowTitle}>PO Files:</div>
        <div
          style={{
            marginTop: '0.3rem',
            paddingLeft: '0.3rem',
          }}
        >
          {customerPoFiles.map((item) => (
            <DownloadableFileWithActions
              key={`${item.fileName}-${item.url.split('/').pop()}`}
              fileUrl={item.url}
              fileName={item.fileName}
              showDeleteButton={partInfo.status !== ITEM_STATUS_MAPPING.DELIVERED}
              onDelete={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setOpenDeletePoFilePopup(true);
                setPoFileToBeDeleted(item);
              }}
              editable
              onSave={(fileName) => {
                addItemCustomerPoFiles([{
                  itemID: partInfo.itemID,
                  files: [{
                    ...item,
                    fileName,
                  }]
                }]).then(getItems);
              }}
            />
          ))}
        </div>
      </div>
    );
  }

  const renderDeletePoFilePopup = () => {
    return (
      <YesNoPopup
        open={openDeletePoFilePopup}
        handleNo={() => setOpenDeletePoFilePopup(false)}
        handleYes={() => {
          handleDeletePoFile();
          setOpenDeletePoFilePopup(false);
        }}
        body={`${poFileToBeDeleted.fileName}`}
      />
    );
  }

  const renderExpectedPrice = () => {
    if (!partInfo.expectedPrice || !partInfo.qExchangeRates) {
      return null;
    }
    const exchangeRate = partInfo.qExchangeRates["exchangeRates"]["exchange_rates"][partInfo.currency];
    const expectedPrice = currency === CURRENCY.SGD
      ? Number(partInfo.expectedPrice)
      : Number(partInfo.expectedPrice) * Number(exchangeRate);
    return (
      <div className={classes.rowDetail}>
        <div className={classes.rowTitle}>Expected Price:</div>
        <div className={classes.rowContent}>
          {`${currencySymbol} ${expectedPrice.toFixed(2)}`}
        </div>
      </div>
    );
  }


  const renderAcceptedPrice = () => {
    if (!partInfo.priceBidded) {
      return 'NIL';
    }
    const exchangeRate = partInfo.qExchangeRates["exchangeRates"]["exchange_rates"][partInfo.currency];
    const { totalPriceStr } = convertPriceWithQuantityToCurrency({
      totalPrice: partInfo.totalPrice,
      currency,
      exchangeRate,
      quantity: partInfo.quantity
    });
    return totalPriceStr;
  }

  const renderRowInfo = (title, value) => {
    return (
      <div className={classes.rowDetail}>
        <div className={classes.rowTitle}>{title}:</div>
        <div className={classes.rowContent}>
          {value}
        </div>
      </div>
    );
  }

  const render3DPrintingMetadata = (metadata) => {
    if (isEmptyValue(metadata)) {
      return null;
    }
    const {
      threeDTechnology,
      threeDInfill,
      threeDLayerThickness,
    } = metadata;

    return (
      <div>
        {threeDTechnology && renderRowInfo('3D Technology', threeDTechnology)}
        {threeDTechnology === THREE_D_P_FDM_TECH && String(threeDInfill).length && renderRowInfo('3D Infill', `${Number(threeDInfill * 100).toFixed(0)}%`)}
        {threeDTechnology === THREE_D_P_FDM_TECH && String(threeDLayerThickness).length && renderRowInfo('3D Layer Thickness', `${threeDLayerThickness}mm`)}
      </div>
    )
  }

  const renderQuotationFormUrl = () => {
    if (isEmptyValue(quotationFormUrl)) {
      return null;
    }

    return (
      <div
        className={classes.rowDetail}
        style={{
          marginTop: '0.5rem',
        }}
      >
        <div className={classes.formRowTitle}>Quotation Form:</div>
        <div className={classes.rowContent}>
          {(!quotationFormUrl || quotationFormUrl === 'loading') && (
            <FtrTooltip description='Quotation Form PDF file is generating'>
              <CircularProgress size={20} />
            </FtrTooltip>
          )}
          {quotationFormUrl && quotationFormUrl?.startsWith('http') && (
            <IconButton
              style={{
                padding: 2,
              }}
              aria-label='pdf'
              onClick={() => downloadS3File(quotationFormUrl)}
            >
              <DescriptionOutlinedIcon style={{ fill: colors.uploadFileBorder }} />
            </IconButton>
          )}
        </div>
      </div>
    )
  }

  const renderInvoiceUrl = () => {
    if (!showXeroInvoiceUrl) {
      return null;
    }

    return (
      <div
        className={classes.rowDetail}
        style={{
          marginTop: '0.5rem',
        }}
      >
        <div className={classes.formRowTitle}>Invoice:</div>
        <div className={classes.rowContent}>
          <IconButton
            style={{
              padding: 2,
            }}
            aria-label='pdf'
            onClick={() => window.open(partInfo.invoiceUrl)}
          >
            <MonetizationOnIcon style={{ fill: colors.uploadFileBorder }} />
          </IconButton>
        </div>
      </div>
    );
  }

  const renderRequestSameFabricatorInfo = () => {
    if (isEmptyValue(partInfo.requestSameFabricator)) {
      return null;
    }
    const text = partInfo.requestSameFabricator
      ? 'Yes, use same factory for this order.'
      : 'Yes, match order to all qualified factories.';
    return (
      <div className={classes.rowDetail}>
        <div className={classes.rowTitle}>Repeat Order:</div>
        <div
          className={classes.rowContent}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {text}
          &nbsp;
          <InfoIcon toolTipText="Please contact us if you would like to change this option." />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Grid container spacing={1} style={{ maxWidth: 900 }}>
        <Grid item xs={6}>
          <div className={classes.rowDetail}>
            <div className={classes.rowTitle}>Name:</div>
            <div className={classes.rowContent}>
              {partInfo.name ? partInfo.name : ''}
            </div>
          </div>
          <div className={classes.rowDetail}>
            <div className={classes.rowTitle}>Date Uploaded:</div>
            <div className={classes.rowContent}>
              {partInfo.datePosted && (
                <DateFormatMethod date={partInfo.datePosted} />
              )}
            </div>
          </div>
          <Divider />
          {partInfo.status <= ITEM_STATUS_MAPPING.QUOTES_AVAILABLE && !partInfo.priceBidded ? (
            <div>
              <div className={classes.rowDetail} style={{ marginTop: 15 }}>
                <div className={classes.rowTitle}>Quantity:</div>
                <div className={classes.rowContent}>
                  {partInfo.qty
                    ? partInfo.qty
                    : partInfo.quantity
                      ? partInfo.quantity
                      : ''}
                </div>
              </div>
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Technology:</div>
                <div className={classes.rowContent}>
                  {getTechnologyDisplayText(partInfo)}
                </div>
              </div>
              {partInfo.material && (
                <div className={classes.rowDetail}>
                  <div className={classes.rowTitle}>Material:</div>
                  <div className={classes.rowContent}>{getMaterialWithColorText(partInfo)}</div>
                </div>
              )}
              {is3DPTechnology(partInfo.technology) && render3DPrintingMetadata(partInfo.metadata)}
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Surface Finish:</div>
                <div className={classes.rowContent}>
                  {getSurfaceFinishWithCustomizationsText(partInfo)}
                </div>
              </div>
              {partInfo.tolerance && (
                <div className={classes.rowDetail}>
                  <div className={classes.rowTitle}>Tolerance:</div>
                  <div className={classes.rowContent}>
                    +/- {showUnitValueFromMetric(partInfo.tolerance, partInfo.unitType)}&nbsp;
                  </div>
                  <InfoIcon toolTipText="This is the tightest tolerance indicated in your design." />
                </div>
              )}
              {renderExpectedPrice()}
              {partInfo.deadline &&
                <div className={classes.rowDetail}>
                  <div className={classes.rowTitle}>Expected Lead Time:</div>
                  <div className={classes.rowContent}>
                    <DateFormatMethod date={partInfo.deadline} />
                  </div>
                </div>
              }
              {partInfo.partApplication &&
                <div className={classes.rowDetail}>
                  <div className={classes.rowTitle}>Part Application:</div>
                  <div className={classes.rowContent}>
                    {partInfo.partApplication}
                  </div>
                </div>
              }
              {partInfo.description &&
                <div className={classes.rowDetail}>
                  <div className={classes.rowTitle}>Description:</div>
                  <div className={classes.rowContent}>{partInfo.description}</div>
                </div>
              }
              {renderRequestSameFabricatorInfo()}
            </div>
          ) : (
            <div>
              <div className={classes.rowDetail} style={{ marginTop: 15 }}>
                <div className={classes.rowTitle}>Quantity:</div>
                <div className={classes.rowContent}>
                  {partInfo.quoteQty ? partInfo.quoteQty : 'NIL'}
                </div>
              </div>
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Accepted Price:</div>
                <div className={classes.rowContent}>
                  {renderAcceptedPrice()}
                </div>
              </div>
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Technology:</div>
                <div className={classes.rowContent}>
                  {getTechnologyDisplayText(partInfo)}
                </div>
              </div>
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Material:</div>
                <div className={classes.rowContent}>
                  {getMaterialWithColorText({
                    material: partInfo.quoteMaterial,
                    customMaterial: partInfo.quoteCustomMaterial,
                    materialColor: partInfo.quoteMaterialColor,
                  })}
                </div>
              </div>
              {is3DPTechnology(partInfo.technology) && render3DPrintingMetadata(partInfo.qMetadata)}
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Surface Finish:</div>
                <div className={classes.rowContent}>
                  {getSurfaceFinishWithCustomizationsText({
                    surfaceFinish: partInfo.quoteSurfaceFinish,
                    customSurfaceFinish: partInfo.quoteCustomSurfaceFinish,
                    color: partInfo.quoteColor,
                  })}
                </div>
              </div>
              {partInfo.tolerance && (
                <div className={classes.rowDetail}>
                  <div className={classes.rowTitle}>Tolerance:</div>
                  <div className={classes.rowContent}>
                    +/- {showUnitValueFromMetric(partInfo.tolerance, partInfo.unitType)}&nbsp;
                  </div>
                  <InfoIcon toolTipText="This is the tightest tolerance indicated in your design." />
                </div>
              )}
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Lead Time:</div>
                <div className={classes.rowContent}>
                  {partInfo.leadTime && partInfo.markupLeadTime
                    ? parseInt(partInfo.leadTime, 10) +
                    partInfo.markupLeadTime +
                    ' working day(s)'
                    : partInfo.leadTime
                      ? partInfo.leadTime + ' working day(s)'
                      : 'NIL'}
                </div>
              </div>
              {partInfo.partApplication &&
                <div className={classes.rowDetail}>
                  <div className={classes.rowTitle}>Part Application:</div>
                  <div className={classes.rowContent}>
                    {partInfo.partApplication}
                  </div>
                </div>
              }
              {partInfo.description &&
                <div className={classes.rowDetail}>
                  <div className={classes.rowTitle}>Description:</div>
                  <div className={classes.rowContent}>{partInfo.description}</div>
                </div>
              }
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Supplier Remarks:</div>
                <div className={classes.rowContent}>
                  {partInfo.remarks ? partInfo.remarks : 'NIL'}
                </div>
              </div>
              <div className={classes.rowDetail}>
                <div className={classes.rowTitle}>Delivery Option:</div>
                <div className={classes.rowContent}>
                  {DELIVERY_OPTIONS_DISPLAY_MAPPING[partInfo.deliveryOption]}&nbsp;
                </div>
                <InfoIcon toolTipText={getDeliveryOptionTooltipText(partInfo.deliveryOption)} />
              </div>
              {renderRequestSameFabricatorInfo()}
              <div>
                <Divider />
                {renderQuotationFormUrl()}
                {renderInvoiceUrl()}
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          <div>
            <div className={classes.rowTitle}>Design/CAD Files:</div>
            <div className={classes.rowContent}>
              {partInfo.originalFiles ? (
                <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {partInfo.originalFiles.split(',').map((link, index) => {
                    const fileName = getFileNameFromCadFile(link);
                    return (
                      <ListItem
                        key={index}
                        style={{
                          backgroundColor: colors.uploadFileBg,
                          width: 260,
                          margin: 5,
                          border: `solid 1px ${colors.uploadFileBorder}`,
                          borderRadius: '5px',
                          height: '3.875rem',
                          color: 'inherit',
                          cursor: 'pointer',
                        }}
                        component='a'
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadS3File(link);
                        }}
                      >
                        <ListItemAvatar
                          style={{ display: 'flex', minWidth: '2.8rem' }}
                        >
                          <img
                            src={FileIcon}
                            alt='icon'
                            style={{ height: '1.875rem' }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          style={{
                            width: 130,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 12,
                          }}
                          primary={fileName}
                          className={classes.itemText}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              ) : partInfo.cadPart ? (
                <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {partInfo.cadPart.map((link, index) => {
                    const fileName = getFileNameFromCadFile(link);
                    return (
                      <ListItem
                        key={index}
                        style={{
                          backgroundColor: colors.uploadFileBg,
                          width: 260,
                          margin: 5,
                          border: `solid 1px ${colors.uploadFileBorder}`,
                          borderRadius: '5px',
                          height: '3.875rem',
                          color: 'inherit',
                          cursor: 'pointer',
                        }}
                        component='a'
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadS3File(link);
                        }}
                      >
                        <ListItemAvatar
                          style={{ display: 'flex', minWidth: '2.8rem' }}
                        >
                          <img
                            src={FileIcon}
                            alt='icon'
                            style={{ height: '1.875rem' }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          style={{
                            width: 130,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: 12,
                          }}
                          primary={fileName}
                          className={classes.itemText}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <div>NIL</div>
              )}
            </div>
          </div>
          <div>
            {renderCustomerPoFiles()}
          </div>
        </Grid>
      </Grid>
      {openDeletePoFilePopup && renderDeletePoFilePopup()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currency: state.auth.location.currency,
  };
}


const withConnect = connect(mapStateToProps, null);

export default withConnect(PartDetails);
