import {
  backAction,
  continueAction,
  restartTourStep,
  stepNumberButton,
} from './tourConstants';
import {
  closeSidebarMobile,
  hideOtherPopup,
  openSidebarMobile,
} from './tourUtils';

export const getPartUploadStepOneTourSteps = (params = {}) => {
  const isMobile = params.isMobile;
  return [
    {
      attachTo: {
        element: '#select-technology-area',
        on: isMobile ? 'top' : 'bottom',
      },
      buttons: [
        stepNumberButton,
        { ...stepNumberButton, text: '1 out of 5' },
        continueAction,
      ],
      showOn: async function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      beforeShowPromise: () => {
        closeSidebarMobile();
        hideOtherPopup();
      },
      title: 'Select Your Manufacturing Technology',
      text: [
        'Simply select the manufacturing technology best fitted to your requirements here.',
      ],
    },
    {
      attachTo: {
        element: '#upload-part-box-step-one-area',
        on: isMobile ? 'top' : 'bottom',
      },
      buttons: [
        backAction,
        { ...stepNumberButton, text: '2 out of 5' },
        continueAction,
      ],
      showOn: function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      beforeShowPromise: () => closeSidebarMobile(),
      title: 'Upload Your Design Files',
      text: [
        'Simply drag and drop multiple 3D files for your project here. You can upload a .zip BOM folder too!',
      ],
    },
    {
      attachTo: {
        element: '#manage-projects-tab',
        on: isMobile ? 'bottom' : 'right',
      },
      buttons: [
        backAction,
        { ...stepNumberButton, text: '3 out of 5' },
        continueAction,
      ],
      showOn: async function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      beforeShowPromise: () => openSidebarMobile(),
      title: 'Review all your quotations and orders here',
      text: [
        'Easily monitor the status of your orders, retrieve your past order history, and reorder them seamlessly with a single button.',
      ],
    },
    {
      attachTo: {
        element: '#parts-library-tab',
        on: isMobile ? 'bottom' : 'right',
      },
      buttons: [
        backAction,
        { ...stepNumberButton, text: '4 out of 5' },
        continueAction,
      ],
      showOn: async function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      beforeShowPromise: () => openSidebarMobile(),
      title: 'A place for all your parts information',
      text: [
        'All the parts that you have ever uploaded into our platform are stored here. You can upload parts directly to the Parts Library too if you do not want to make any order.',
      ],
    },
    {
      attachTo: {
        element: '#buyer-profile',
        on: isMobile ? 'bottom' : 'right',
      },
      buttons: [
        backAction,
        { ...stepNumberButton, text: '5A out of 5' },
        continueAction,
      ],
      showOn: async function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      beforeShowPromise: () => openSidebarMobile(),
      title: 'View Your Profile',
      text: ['Click here to configure your settings!'],
    },
    {
      attachTo: {
        element: '#profile-menu',
        on: isMobile ? 'bottom' : 'right',
      },
      buttons: [
        backAction,
        { ...stepNumberButton, text: '5B out of 5' },
        continueAction,
      ],
      showOn: async function() {
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
      beforeShowPromise: () => {
        openSidebarMobile();
        // open profile menu item
        document.querySelector('#profile-menu-item').click();
      },
      title: 'Update Your Buyer Profile',
      text: [
        'Enter your favourite headshot and contact details here. Need to sign additional NDA with Factorem? Upload it in this step.',
      ],
    },
    restartTourStep(params),
  ];
};
