import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';

import { newBuyerSignup, signupByAdmin } from '../apis/signupApi';

import { loginUser } from './auth';

import { isEmptyValue } from '../utils/commonUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

import { BACKEND_SERVICE_URL, ROLE_TYPES } from '../constants';


// ----------------------------------------------------------------------------------

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';
export const QUESTION_ANSWERED = 'QUESTION_ANSWERED';
export const REMOVE_PASSWORD = 'REMOVE_PASSWORD';
export const RESET_STATUS = 'RESET_STATUS';

const cookies = new Cookies();

const receiveSignup = (props, redirectPath) => {
  const path = redirectPath || '/';
  props.history.push(path);
  return {
    type: SIGNUP_SUCCESS,
  };
};

export const receiveSignupAfterQuote = (email, name, password) => {
  return {
    type: SIGNUP_SUCCESS,
    email,
    name,
    password,
  };
};

const questionAnswered = () => {
  return {
    type: QUESTION_ANSWERED,
  };
};

const signupError = () => {
  toast.error('Signup Error!', {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: SIGNUP_FAILURE
  };
};

const openVerifyEmailPage = (email, name, props) => {
  props.history.push('/verify-email');
  return {
    type: SIGNUP_SUCCESS,
    email,
    name,
  };
};

const emailUsed = () => {
  toast.error('Email has been used. Please login with your email.', {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: SIGNUP_FAILURE
  };
};

const verifyEmailFailure = () => {
  return {
    type: VERIFY_EMAIL_FAILURE
  };
};

const resendEmailSuccess = () => {
  return {
    type: RESEND_EMAIL_SUCCESS
  };
};

const resendEmailFailure = () => {
  toast.error('Failed to resend verification email. Please try again!', {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: RESEND_EMAIL_FAILURE
  };
};

export const resetStatus = () => {
  return {
    type: RESET_STATUS,
  };
};

export const verifyEmailSuccess = (country) => {
  return {
    type: VERIFY_EMAIL_SUCCESS,
    country,
  };
};

export const removeCreateUserPassword = () => {
  return {
    type: REMOVE_PASSWORD,
  };
};

export const signupUser = (userData, props, isAdmin = false) => (dispatch) => {
  const isSignUpV2 = userData.isSignUpV2;

  const signupFunc = isAdmin ? signupByAdmin : newBuyerSignup;

  const newUser = {
    ...userData,
    registrationDate: new Date(),
  };

  return signupFunc(newUser).then(function (response) {
    if (response.ok) {
      if (isAdmin) {
        dispatch(receiveSignup(props, '/manage-users'));
        notifySuccess('Successfully created a new account!');
        return;
      }
      if (isSignUpV2) {
        dispatch(receiveSignupAfterQuote(userData.email, userData.name, userData.password));
      } else if (userData.role === ROLE_TYPES.BUYER) {
        dispatch(openVerifyEmailPage(userData.email, userData.name, props));
        props.history.push('/verify-email');
      } else {
        dispatch(receiveSignup(props));
        notifySuccess('Successfully created a new account!');
      }
    } else {
      response.json().then(result => {
        if (result.error === 'Email has been used.') {
          dispatch(emailUsed());
        } else {
          dispatch(signupError());
        }
      });
    }
  }).catch(function () {
    dispatch(signupError());
  });
};

export const signupContinueUser = (userData) => async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/signup-buyer-continue`;

  return fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('token')}`
    },
    body: JSON.stringify(userData)
  })
    .then(response => {
      if (!response.ok) {
        notifyError('Failed to sign up. Please contact our admin.')
      }
    })
    .catch(() => {
      notifyError('Failed to sign up. Please contact our admin.')
    });
};

export const signupCompleteUser = (userData, props) => async (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/signup-buyer-complete`;

  return fetch(requestUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData)
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(receiveSignup(props));
      } else {
        dispatch(signupError());
      }
    })
    .catch(() => {
      dispatch(signupError());
    });
};

export const completeAnswerQuestion = (userData) => async (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/signup-buyer-complete`;

  return fetch(requestUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData)
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(questionAnswered());
        notifySuccess('Welcome to Factorem!');
      } else {
        dispatch(signupError());
      }
    })
    .catch(() => {
      dispatch(signupError());
    });
};

export const resendEmail = (userEmail, userName, isSignUpV2 = false) => dispatch => {
  const requestUrl = isSignUpV2
    ? `${BACKEND_SERVICE_URL}/verify-number-email`
    : `${BACKEND_SERVICE_URL}/verify-email`;
  let data = {
    email: userEmail,
    name: userName
  };
  return fetch(requestUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.status === 200) {
        // Successful response
        if (!isSignUpV2) {
          notifySuccess('A new verification email has been sent to your email!');
        }
        dispatch(resendEmailSuccess());
      } else {
        dispatch(resendEmailFailure());
      }
    })
    .catch(() => {
      dispatch(resendEmailFailure());
    });
};

export const verifyEmail = ({
  confirmationCode,
  verificationNumber,
  userEmail,
  password,
  isWithSamplePart,
}) => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/email-confirmation/`;
  let data = {
    userEmail,
    confirmationCode,
    verificationNumber,
    isWithSamplePart,
  };
  return fetch(requestUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
    .then(async response => {
      if (response.status !== 200) {
        // Invalid verification
        dispatch(verifyEmailFailure());
        return;
      }
      const res = await response.json();
      const { country } = res.data;

      // Successful response
      dispatch(verifyEmailSuccess(country));
      if (!isEmptyValue(verificationNumber)) {
        dispatch(loginUser(userEmail, password));
      }
    })
    .catch(() => {
      dispatch(verifyEmailFailure());
    });
};
