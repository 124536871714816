import { toast } from 'react-toastify';
import queryString from 'query-string';

import { isEmptyValue } from '../utils/commonUtils';
import {
  fetchWithErrorHandling,
  getAuthorizedHeader,
  witchCacheResponse,
} from '../utils/apiUtils';

import {
  notifyInfo,
  notifySuccess,
  updateNotification,
} from '../services/notificationService';

import { BACKEND_SERVICE_URL } from '../constants';


// -------------------------------------------------------------------------------------------------

const CACHE_DURATION = 10 * 60; // 10 minutes in second

const stringifyItemIDListForUrl = (itemIDList) => {
  return !isEmptyValue(itemIDList)
    ? `${queryString.stringify({ itemIDList }, { arrayFormat: 'bracket' })}`
    : '';
}

let currentController = new AbortController();
export const getCheckoutPricingSummary = async (body) => {
  if (currentController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    currentController.abort();
  }
  currentController = new AbortController();
  const signal = currentController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/price`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
    signal,
  });
}

export const getCheckoutPricingSummaryWithCache = witchCacheResponse(
  getCheckoutPricingSummary,
  CACHE_DURATION,
  'getCheckoutPricingSummary',
);

export const getAllTrackings = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/trackings`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getTrackingByID = async (id) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/trackings/${id}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getTrackingsByItemIDs = async (itemIDList) => {
  const queryParamStr = `?${stringifyItemIDListForUrl(itemIDList)}`;
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/trackings${queryParamStr}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getSupplierTrackingsByItemIDs = async (itemIDList) => {
  const queryParamStr = `?${stringifyItemIDListForUrl(itemIDList)}`;
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/suppliers/trackings${queryParamStr}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getCombinedQuotationsFormUrl = async (body) => {
  const toastId = notifyInfo('Downloading quotation...');
  const requestUrl = `${BACKEND_SERVICE_URL}/pdf/quotations/combined`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    updateNotification(toastId, 'Downloaded quotation successfully');
    return (await response.json()).data;
  }).catch((e) => {
    console.error(e.message)
    updateNotification(toastId, 'Get combined quotations form failed!', 'error');
  });
}

export const deliverItems = async (body) => {
  const toastId = toast('Updating delivery status...', { type: toast.TYPE.INFO, autoClose: false });
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/deliver`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      const res = await response.json();
      toast.update(toastId, {
        render: res.error ?? 'Updated delivery status failed.',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      throw Error(response.statusText);
    }
    const data = await response.json();
    const formattedIDs = body.itemsData.map(item => `<#${item.itemID}>`).join(', ');
    let toastMessage;
    if (data.isDelivered) {
      toastMessage = `ItemID ${formattedIDs} status updated to Delivered`;
    } else {
      toastMessage = `ItemID ${formattedIDs} status updated to Order Ready`;
    }
    toast.update(toastId, {
      render: toastMessage,
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    });
    return data;
  });
}

export const invoiceItems = async (body) => {
  const toastId = toast('Updating invoice status...', { type: toast.TYPE.INFO, autoClose: false });
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/invoice`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      toast.update(toastId, {
        render: 'Email failed to send.',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      throw Error(response.statusText);
    }
    const res = await response.json();
    const { checkoutType, checkoutIDs } = res.data;
    const typeStr = checkoutType === 'credit' ? 'Invoice' : 'Stripe';
    toast.update(toastId, {
      render: `<${typeStr}> email sent successfully to finance@factorem.co.`,
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    });
    if (checkoutIDs.length !== 0) {
      const itemIDsStr = checkoutIDs.toString();
      notifySuccess(`ItemID ${itemIDsStr} status have been switched to Delivered.`);
    } else {
      notifySuccess('All items have already been delivered.');
    }
    return res;
  });
}

export const collectedItems = async (body) => {
  const toastId = toast('Updating collected items...', { type: toast.TYPE.INFO, autoClose: false });
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/collected`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      toast.update(toastId, {
        render: 'Updated collected items failed.',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      throw Error(response.statusText);
    }
    const data = await response.json();
    const formattedIDs = body.itemsData.map(item => `<#${item.itemID}>`).join(', ');
    toast.update(toastId, {
      render: `Collected ItemID ${formattedIDs}`,
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    });
    return data;
  });
}

export const shipItems = async (body) => {
  const toastId = toast('Updating ship items...', { type: toast.TYPE.INFO, autoClose: false });
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/ship-items`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      toast.update(toastId, {
        render: 'Updated ship items failed.',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      throw Error(response.statusText);
    }
    const data = await response.json();
    const formattedIDs = body.itemsData.map(item => `#${item.itemID}`).join(', ');
    toast.update(toastId, {
      render: `Shipment Properties updated for Item ${formattedIDs}`,
      type: toast.TYPE.SUCCESS,
      autoClose: 3000,
    });
    return data;
  });
}

export const reGenerateCombinedQuoteForMultiCheckout = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/generate-combined-quote`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const updateMultipleCheckout = async (checkoutID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/${checkoutID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const generateXeroInvoiceForMultiCheckout = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getMultiCheckoutTrackingsForProjectApi = async (projectID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/trackings/projects/${projectID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const getAllOrderByCustomerApi = async (customerID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/customers/${customerID}/trackings`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

export const addCustomerPoFilesForMultiCheckoutApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/customer-po`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const deleteCustomerPoFileForMultiCheckoutApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/multiple-checkout/customer-po`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}
