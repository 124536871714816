import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { colors } from '../../palette';

import factoremLargeLogo from '../../assets/img/factorem-large-logo.png';
import factoremLogoSmall from '../../assets/img/factorem-logo-with-name-small.png';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: colors.background,
    }
  },
  logo: {
    top: '5%',
    left: '5%',
    zIndex: -1,
    position: 'absolute',
    maxWidth: '15.625rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '9.375rem',
    },
  },
  largeLogo: {
    zIndex: -1,
    right: '0px',
    bottom: '0px',
    position: 'absolute',
    maxWidth: '31.25rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '12.5rem',
    },
  },
}));

function Background() {
  const classes = useStyles();

  return (
    <Fragment>
      <a href='https://www.factorem.co' target='_blank'>
        <img
          src={factoremLogoSmall}
          alt='Factorem Logo'
          className={classes.logo}
        />
      </a>
      <img
        className={classes.largeLogo}
        src={factoremLargeLogo}
        alt='Large Factorem Logo'
      />
    </Fragment>
  );
}

export default Background;
