import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  CircularProgress,
  Container,
  Divider,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { notifyError } from '../../services/notificationService';
import { generateTitleplate, getItemDetailsApi } from '../../apis/itemApi';
import { Link } from 'react-router-dom';
import { downloadS3File } from '../../utils/fileUtils';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
} from '../../utils/itemUtils';
import { DISPLAY_UNIT_DROPDOWN, UNIT_TYPES } from '../../constants/unitConstants';
import { convertMetricToImperial } from '../../utils/userUtils';

import {
    getLatestTechnicalDrawingExtractionAttemptById,
  } from '../../apis/technicalDrawingExtractionApi';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

export function GenerateTitleplate(props) {
  const { user } = props;
  const classes = useStyles();

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      itemID: '',
      projectID: '',
      userID: '',
      title: '',
      quantity: 0,
      datePosted: '',
      deadline: null,
      description: '',
      material: null,
      otherMaterial: null,
      surfaceFinish: null,
      otherSurfaceFinish: null,
      pocName: user.name,
      pocContact: user.contact,
      tolerance: '',
      defaultTolerance: 0,
      unitType: UNIT_TYPES.METRIC,
      isShow: false,
      downloadUrl: '',
      processing: false,
      scale: 'Not found',
      paperSize: 'Not found',
    }
  );

  useEffect(() => {
    const getData = setTimeout(() => {
      if (localState.itemID) {
        getItemDetailsApi(localState.itemID)
          .then((data) => {
            const {
              itemID,
              userID,
              projectID,
              datePosted,
              referenceName,
              name: nameItem,
              quantity,
              deadline,
              description,
              tolerance,
              unitType,
            } = data;
            const title = `${referenceName || String(itemID).padStart(4, "0")}_${nameItem}`;
            updateLocalState({
              itemID,
              projectID,
              userID,
              title,
              quantity,
              datePosted,
              deadline,
              description,
              material: getMaterialWithColorText(data),
              surfaceFinish: getSurfaceFinishWithCustomizationsText(data),
              defaultTolerance: tolerance,
              tolerance: unitType === UNIT_TYPES.METRIC ? tolerance : convertMetricToImperial(tolerance),
              unitType,
              isShow: true,
            });
          })
          .catch(() => notifyError('Unable to get item'));

        // Fetch technical drawing details
        getLatestTechnicalDrawingExtractionAttemptById(localState.itemID)
          .then((data) => {
            updateLocalState({
              paperSize:  get(data, "output.paperSize", "Not found"),
              scale:  get(data, "output.scale", "Not found")
            });
          })
          .catch(() => notifyError('Unable to get technical drawing details for item'));
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [localState.itemID]);

  useEffect(() => {
    if (localState.unitType) {
      updateLocalState({
        tolerance:
          localState.unitType === UNIT_TYPES.METRIC
            ? localState.defaultTolerance
            : convertMetricToImperial(localState.defaultTolerance),
      });
    }
  }, [localState.unitType]);

  const handleGenerateTitleplate = () => {
    const body = {
      itemID: localState.itemID,
      projectID: localState.projectID,
      userID: localState.userID,
      title: localState.title,
      editedQuantity: localState.quantity,
      description: localState.description || '',
      editedMaterial: localState.material,
      editedSurfaceFinish: localState.surfaceFinish,
      name: localState.pocName,
      contact: localState.pocContact,
      tolerance: localState.tolerance,
      unitType: localState.unitType,
      scale: localState.scale,
      paperSize: localState.paperSize,
    };
    updateLocalState({ downloadUrl: '', processing: true });
    generateTitleplate(body)
      .then(({ data }) => updateLocalState({ downloadUrl: data }))
      .catch(() => notifyError('Unable to generate Titleplate'))
      .finally(() => updateLocalState({ processing: false }));
  };

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <TextField
          label='Item ID'
          value={localState.itemID}
          onChange={(evt) => {
            updateLocalState({ itemID: evt.target.value });
          }}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          placeholder='eg: 1234'
          type='number'
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        {localState.isShow && (
          <React.Fragment>
            <Divider style={{ margin: '1rem 0' }} />
            <div
              style={{
                display: 'grid',
                gap: '0.5rem 1rem',
                gridTemplateColumns: 'repeat(2, 1fr)',
              }}
            >
              <TextField
                label='Title'
                value={localState.title}
                onChange={(evt) => {
                  updateLocalState({ title: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='POC Name'
                value={localState.pocName}
                onChange={(evt) => {
                  updateLocalState({ pocName: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='POC Contact'
                value={localState.pocContact}
                onChange={(evt) => {
                  updateLocalState({ pocContact: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='Quantity'
                value={localState.quantity}
                onChange={(evt) => {
                  updateLocalState({ quantity: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                type='number'
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='Description'
                value={localState.description}
                onChange={(evt) => {
                  updateLocalState({ description: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                multiline
                style={{ marginRight: '1.25rem' }}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='Material'
                value={localState.material}
                onChange={(evt) => {
                  updateLocalState({ material: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='Surface Finish'
                value={localState.surfaceFinish}
                onChange={(evt) => {
                  updateLocalState({ surfaceFinish: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='Scale'
                value={localState.scale}
                onChange={(evt) => {
                  updateLocalState({ scale: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                type='text'
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='Paper size'
                value={localState.paperSize}
                onChange={(evt) => {
                  updateLocalState({ paperSize: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                type='text'
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label='Tolerance'
                value={localState.tolerance}
                onChange={(evt) => {
                  updateLocalState({ tolerance: evt.target.value });
                }}
                variant='outlined'
                margin='dense'
                style={{ marginRight: '1.25rem' }}
                type='number'
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                labelId='unit-type'
                select
                required
                label='Units'
                value={localState.unitType}
                variant='outlined'
                margin='dense'
                onChange={(evt) => {
                  updateLocalState({ unitType: evt.target.value });
                }}
              >
                {Object.values(UNIT_TYPES).map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {DISPLAY_UNIT_DROPDOWN[unit]}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </React.Fragment>
        )}
        <div style={{ marginTop: '1rem' }}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleGenerateTitleplate}
            disabled={!localState.isShow || localState.processing}
          >
            {localState.processing && (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
            Generate Titleplate
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '1rem',
            flexDirection: 'column',
          }}
        >
          <span style={{ minWidth: 'max-content' }}>Download Link:&nbsp;</span>
          {localState.processing && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          <Link
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              downloadS3File(localState.downloadUrl);
            }}
          >
            {localState.downloadUrl}
          </Link>
        </div>
      </div>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

const withConnect = connect(mapStateToProps);

export default withConnect(GenerateTitleplate);
