import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import {
  VisibilityOutlined,
  VisibilityOffOutlined,
} from '@material-ui/icons';

import Slideshow from '../components/images/Slideshow';
import Description from '../components/texts/Description';

import factoremLogoSmall from '../assets/img/factorem-logo-with-name-small.png';
import factoremLogoWhite from '../assets/img/factorem-logo-white.png';

import { createAlertNotification } from "../apis/alertApi";

import {
  loginUser,
} from '../actions/index';

import { isEmptyValue } from '../utils/commonUtils';
import { getUserAgentInfo, getUserFirstName } from '../utils/userUtils';

import { getCookie, removeCookie } from '../services/cookiesService';
import { notifyError, notifySuccess } from '../services/notificationService';

import { REDIRECT_URL_KEY } from '../constants/cookiesConstants';

import { colors } from '../palette';

const useStyles = makeStyles(theme => ({
  login: {
    marginTop: 12,
    width: '100%',
    fontSize: '14pt',
    fontWeight: 'bold',
    textTransform: 'none',
    background: colors.buttonBlue,
    color: colors.fontWhite,
    '&:hover': {
      backgroundColor: colors.blue050,
    },
  },
  inputField: {
    borderRadius: 5,
    marginBottom: '0.95rem',
    background: colors.mainLightBlue,
    width: '100%',
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.fontBlack,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '30pt',
    letterSpacing: '-0.6px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    fontSize: '14pt',
    marginBottom: '1.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12pt',
      textAlign: 'center',
    },
  },
  forgotPassword: {
    float: 'right',
    fontSize: '11pt',
    color: colors.buttonBlue,
    textDecoration: 'none',
    '.visited': {
      color: colors.buttonBlue,
    },
    '&:hover': {
      color: colors.blue050,
    },
  },
  container: {
    height: '100%',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    position: 'relative',
    alignItems: 'center',
    backgroundColor: colors.blue050,
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      backgroundSize: 'cover',
      backgroundImage: colors.mobileBackground,
    },
  },
  video: {
    left: "25%",
    height: '100%',
    position: 'fixed',
    transform: "translate(-50%, -50%)",
  },
  form: {
    height: '100%',
    position: 'relative',
    alignItems: 'center',
    background: colors.mainLightBlue,
    [theme.breakpoints.down('sm')]: {
      background: 'none',
    },
  },
  others: {
    width: '100%',
    marginTop: '5px',
    fontSize: '14pt',
    fontWeight: 'bold',
    textTransform: 'none',
    color: colors.fontGrey,
    background: colors.fontWhite,
    border: `solid 1px ${colors.fontGrey}`,
  },
  mobile: {
    fontSize: '10pt',
    paddingTop: '2rem',
    color: colors.fontWhite,
  },
  paper: {
    display: "flex",
    borderRadius: 10,
    padding: "1.75rem 1.55rem",
    flexDirection: "column",
    boxShadow: '0 6px 1.25rem 0 rgba(0, 0, 0, 0.20)',
  },
  partner: {
    top: 10,
    right: 20,
    zIndex: 1,
    fontWeight: 600,
    fontSize: "12pt",
    position: 'absolute',
    color: colors.blue050,
    textTransform: "none",
    '&:hover': {
      background: "none",
      color: colors.fontBlackGrey,
    },
    [theme.breakpoints.down('sm')]: {
      top: "2.3rem"
    },
  },
}));

function Login(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    isAuthenticated,
    loginUser,
    userName
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loginAttempted, setLoginAttempted] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrl = getCookie(REDIRECT_URL_KEY);
      if (!isEmptyValue(redirectUrl)) {
        removeCookie(REDIRECT_URL_KEY, { path: '/' });
        if (!redirectUrl.includes('/summary')) {
          props.history.push(redirectUrl);
        }
      }
    }
  }, [isAuthenticated]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (isEmpty(email)) {
      notifyError(`Email is required.`);
      return;
    }
    if (isEmpty(password)) {
      notifyError(`Password is required.`);
      return;
    }
    const userAgentInfo = await getUserAgentInfo(email)
      .catch(error => {
        const body = {
          title: '[FE] Get User Agent Info Failed!',
          message: error?.message,
          errorStack: error?.stack,
          additionalInfo: {
            email,
          },
        }
        createAlertNotification(body);
      });
    setIsProcessing(true);
    loginUser(email, password, userAgentInfo)
      .then(() => {
        setIsProcessing(false);
        setLoginAttempted(true);
      })
      .catch(() => {
        setIsProcessing(false);
      });
  };

  useEffect(() => {
    // 2 conditions to check for successful login:
    //   1. `loginUser` has resolved
    //   2. `isAuthenticated` is true
    // `loginAttempted` is needed to signal that `loginUser` has resolved in `handleSubmit`
    // We need to wait for the redux state `isAuthenticated` to be updated
    if (isAuthenticated && loginAttempted) {
      notifySuccess(`Welcome back, ${getUserFirstName(userName)}!`);
    }
  }, [isAuthenticated]);

  const renderLoginForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Typography className={classes.title}>Login</Typography>
        <Typography className={classes.subtitle}>
          to continue your manufacturing journey
        </Typography>
        <Typography className={classes.inputLabel}>Email</Typography>
        <TextField
          id='email'
          name='email'
          autoFocus
          className={classes.inputField}
          onChange={handleEmailChange}
          variant='outlined'
          size='small'
        />
        <Typography className={classes.inputLabel}>
          Password
          <a href='/reset' className={classes.forgotPassword}>
            Forgot Password?
          </a>
        </Typography>
        <TextField
          id='password'
          name='password'
          type={showPassword ? 'text' : 'password'}
          className={classes.inputField}
          onChange={handlePasswordChange}
          variant='outlined'
          size='small'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type='submit'
          variant='contained'
          margin='normal'
          className={classes.login}
          disabled={isProcessing}
        >
          {isProcessing === true && <CircularProgress className={classes.circularProgress} size={20} />}
          &nbsp;Login
        </Button>
        <Box style={{
          height: '2rem',
        }}>
        </Box>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          color: colors.fontGrey,
          fontWeight: 'bold',
        }}>
          <Divider style={{ width: '47%' }} />
          &nbsp;or&nbsp;
          <Divider style={{ width: '47%' }} />
        </div>
        <div
          style={{
            width: '100%',
            marginTop: 30,
            fontSize: '10pt'
          }}
        >
          <Button
            type='button'
            className={classes.others}
            component={Link}
            to='/signup'
            data-cy='create-account'
          >
            Create an Account
          </Button>
        </div>
      </form>
    );
  }

  const renderAdditionalInfo = useMemo(() => {
    return (
      <Grid container direction='column' spacing={1} className={classes.mobile}>
        <Description size={4} />
      </Grid>
    );
  }, []);

  const renderPartnerButton = () => {
    return (
      <Button
        type='button'
        className={classes.partner}
        target='_blank'
        href='https://www.factorem.co/partner'
      >
        Become a Partner
      </Button>
    );
  }

  return (
    <Grid container className={classes.container}>
      {isAuthenticated && <Redirect to='/' />}
      <Grid item sm={6}>
        {renderPartnerButton()}
        {!isMobile
          ? <Fragment>
            <a href='https://www.factorem.co' target='_blank' rel="noreferrer">
              <img
                src={factoremLogoWhite}
                alt='icon'
                style={{
                  height: '4rem',
                  position: 'absolute',
                  top: '2rem',
                  left: '4rem',
                  zIndex: 2
                }}
              />
            </a>
            <Slideshow />
          </Fragment>
          : <a href='https://www.factorem.co' target='_blank' rel="noreferrer">
            <img
              src={factoremLogoSmall}
              alt='icon'
              style={{
                height: '3rem',
                position: 'absolute',
                top: '2rem',
                left: '2rem',
                zIndex: 2
              }}
            />
          </a>
        }
      </Grid>
      <Grid container component="main" justifyContent='center' className={classes.form}>
        <div style={{
          padding: isMobile ? '7rem 5% 5rem 5%' : '0 25% 0 25%',
        }}>
          {isMobile
            ? <Fragment>
              <Paper className={classes.paper}>
                {renderLoginForm()}
              </Paper>
              {renderAdditionalInfo}
            </Fragment>
            : renderLoginForm()
          }
        </div>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth && state.auth.isLoggingIn,
    loginError: state.auth && state.auth.loginError,
    isAuthenticated: state.auth?.isAuthenticated ?? false,
    userName: state.auth && state.auth.user && state.auth.user.name,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    loginUser: (email, password, userAgentInfo) => dispatch(loginUser(email, password, userAgentInfo, props))
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(Login);
