import { useEffect, useState } from 'react';

/**
 * check if Continue button is at the bottom of screen then add padding to prevent
 * overlapping with PBrain Factorem button
 * @param {*} elementRef
 * @param {Array} watchers
 * @returns
 */
const useBottomCheck = (elementRef, watchers = []) => {
  const [isBottom, setIsBottom] = useState(false);

  const boundingClient = elementRef?.current?.getBoundingClientRect();

  useEffect(() => {
    if (typeof boundingClient === 'undefined') {
      setIsBottom(false);
      return;
    }
    const root = document.querySelector(':root');
    const pbrainBottom = root.style.getPropertyValue('--pbrainBottom');
    const pbrainBottomValue = Number(pbrainBottom?.slice(0, -2) ?? 0); // remove px and convert to number
    const windowHeight = window.innerHeight;
    const factoremLogoHeight = 60;
    const minHeight = windowHeight - pbrainBottomValue - factoremLogoHeight;
    setIsBottom(boundingClient.bottom >= minHeight);
  }, [boundingClient?.bottom, ...watchers]);

  return isBottom;
};

export default useBottomCheck;
