import React, { useEffect } from "react";
import queryString from 'query-string';

import OrderSuccessfulPopup from "../../components/popups/confirm-order-popup/OrderSuccessfulPopup";

import withPaymentSuccessPopupHOCV2 from "./withPaymentSuccessPopupHOCV2";
import { useSelector } from "react-redux";
import { getUserIDSelector } from "../../selectors/userSelector";
import { getFeedbackOfUsers } from "../../apis/userFeedbackApi";
import { isEmptyValue } from "../../utils/commonUtils";


// ------------------------------------------------------------------------------

const PaymentSuccessV2 = (props) => {
  const {
    updatePopupsHocState = () => { },
  } = props;

  const parsed = queryString.parse(location.search);
  const projectID = parsed.projectID;

  const userID = useSelector(getUserIDSelector);

  useEffect(() => {
    if (userID) {
      getFeedbackOfUsers(userID)
        .then(data => {
          if (isEmptyValue(data)) {
            updatePopupsHocState({ showFeedbackPopup: true });
          }
        });
    }
  }, [userID]);

  return (
    <>
      <OrderSuccessfulPopup
        open={true}
        projectID={projectID}
      />
    </>
  );
};

export default withPaymentSuccessPopupHOCV2(PaymentSuccessV2);
