import React, { useReducer } from 'react';

import DeleteWarningPopupV2 from '../components/popups/DeleteWarningPopupV2';

import { customerDisableItem } from '../apis/itemApi';

import { isEmptyValue } from '../utils/commonUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

import { CUSTOMER_CANCEL_ORDER_REASON_MAPPING } from '../constants/';


// -------------------------------------------------------------------------------------------------

function withDeletePartConfirmPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    const handleConfirmDeleteOrder = ({ feedback, additionalRemarks }) => {
      const itemID = localState.itemID;
      const body = {
        itemID,
        feedback: CUSTOMER_CANCEL_ORDER_REASON_MAPPING[feedback] || feedback,
        additionalRemarks,
      }
      customerDisableItem(body)
        .then(() => {
          notifySuccess('Your uploaded part has been deleted!');
          localState.getItems(localState.projectID);
        })
        .catch(() => {
          notifyError('Unable to delete part. Please try again later!');
        });
      updateLocalState({ open: false });
    };

    const handleCancelDeleteOrder = () => {
      updateLocalState({ open: false });
    };

    return (
      <>
        <WrappedComponent
          updateDeletePartConfirmPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <DeleteWarningPopupV2
            open={localState.open}
            onConfirm={handleConfirmDeleteOrder}
            onCancel={handleCancelDeleteOrder}
            onClose={handleCancelDeleteOrder}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withDeletePartConfirmPopupHOC;
