import { getAuthorizedHeader } from "../utils/apiUtils";

import { BACKEND_SERVICE_URL } from "../constants";


// --------------------------------------------------------------------

export const signupByAdmin = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/signup`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}

export const newBuyerSignup = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/signup-buyer`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
}
