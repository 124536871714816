import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Tab, Tabs } from '@material-ui/core';

// import CreateDeliveryOrder from './manage-delivery-order/CreateDeliveryOrder';
import CreateDeliveryOrderMuiTable from './manage-delivery-order/CreateDeliveryOrderMuiTable';
// import ViewDeliveryOrders from './manage-delivery-order/ViewDeliveryOrders';
import ViewDeliveryOrdersMuiTable from './manage-delivery-order/ViewDeliveryOrdersMuiTable';

import { colors } from '../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  VIEW_DOS: 'View DOs',
  VIEW_DOS_MUI_TABLE: 'View DOs MUI',
  CREATE_DO: 'Create DO',
  CREATE_DO_MUI_TABLE: 'Create DO MUI',
};

const TABS = [
  TAB_KEYS.VIEW_DOS,
  TAB_KEYS.CREATE_DO,
];

function ManageDeliveryOrders() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = TABS[selectedTab];
    switch (tabName) {
      case TAB_KEYS.VIEW_DOS:
        return <ViewDeliveryOrdersMuiTable />;
      case TAB_KEYS.CREATE_DO:
        return <CreateDeliveryOrderMuiTable />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <h1>Manage DOs</h1>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {TABS.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}

export default ManageDeliveryOrders;
