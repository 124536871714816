import React from 'react';

import FtrSvgImage from '../../images/FtrSvgImage';

import EditPencilIcon from '../../../assets/icons/edit_pencil_icon.svg';


// ----------------------------------------------------------------------------------

function FtrEditIconButton(props) {
  const {
    onClick = () => { },
  } = props;

  return (
    <FtrSvgImage
      src={EditPencilIcon}
      width={16}
      height={16}
      style={{
        justifyContent: 'end',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

export default FtrEditIconButton;
