import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles/index';

import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  Typography,
  DialogActions,
  Paper,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import { FtrButton, FtrTypography } from '../ftr-components';

import { convertPriceToCurrencyNoPrefix, getCurrencySymbol } from '../../utils/currencyUtils';
import { getBulkPricingDiscount } from '../../utils/itemUtils';
import { getPpePriceForItem } from '../../utils/ppeUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: colors.fontWhite,
  },
  backdropContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableRowRoot: {
    "&$tableRowSelected, &$tableRowSelected:hover": {
      backgroundColor: colors.blue010,
    }
  },
  tableRowSelected: {
    backgroundColor: colors.blue010,
    border: `2px solid ${colors.blue040}`
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const ColumnHeader = withStyles({
  root: {
    fontWeight: 'bold',
    textAlign: 'end',
    padding: '1rem 2rem'
  },
})(MuiTableCell);

const TableCell = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '16px',
    color: colors.neutral070
  },
})(MuiTableCell);

function BulkPricingPopup(props) {
  const classes = useStyles();

  const {
    dialog,
    item,
    currency,
    exchangeRate,
    handleClose,
    submitUpdatedPart,
    setQty = () => { },
  } = props;

  const [loading, setLoading] = useState(false);
  const [quantitySelected, setQuantitySelected] = useState("");
  const [quantityArray, setQuantityArray] = useState([]);

  useEffect(() => {
    if (!item) {
      return;
    }
    setQuantitySelected(item.qty.toString());
  }, [item])

  const isItemSelected = (itemQty) => {
    return itemQty === quantitySelected;
  }

  const handleChange = (selectedQty) => {
    setQuantitySelected(selectedQty);
  }

  const handleProceed = async () => {
    const quantity = parseInt(quantitySelected, 10);
    setQty(quantity);
    let updatedPart = {
      ...item,
      qty: quantity,
    };

    setLoading(true);
    updatedPart = await getPpePriceForItem(updatedPart);
    setLoading(false);
    submitUpdatedPart(updatedPart);
    handleClose();
  }

  function generateTableData() {
    let _quantityArray = [];
    const bulkPricing = item.bulkPricing;
    for (const qty in bulkPricing) {
      const initialPrice = bulkPricing["1"];
      const isManualRFQ = isEmptyValue(bulkPricing[qty]);
      const totalPrice = isManualRFQ ? 0 : bulkPricing[qty];
      const unitPrice = totalPrice / qty;
      _quantityArray.push({
        isManualRFQ: isManualRFQ,
        qty: qty,
        total: totalPrice,
        unit: unitPrice,
        discount: getBulkPricingDiscount(initialPrice, unitPrice),
      });
    }
    setQuantityArray(_quantityArray);
  }

  useEffect(() => {
    generateTableData();
  }, []);

  const renderBackdrop = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <div className={classes.backdropContent}>
          <CircularProgress color='inherit' />
          <Typography
            style={{
              textAlign: 'center',
            }}
          >
            Please wait for a couple of seconds while we generate the best price
            for you. Do not refresh this page or close the browser.
          </Typography>
        </div>
      </Backdrop>
    );
  }

  return (
    <Fragment>
      <Dialog
        maxWidth='xl'
        open={dialog}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '30px',
            padding: '0.5rem'
          }
        }}
      >
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <DialogTitle>
          <FtrTypography type='heading' fontSize='24'>
            Bulk Pricing
          </FtrTypography>
          <FtrTypography type='subHeading' fontSize='18'>
            {item.name}
          </FtrTypography>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} style={{ borderRadius: '16px' }}>
            <Table size="small" style={{ borderColor: `1px solid ${colors.neutral030}` }}>
              <TableHead style={{ backgroundColor: colors.neutral030 }}>
                <TableRow>
                  <ColumnHeader>
                    <FtrTypography type='subHeading' fontSize='16'>Quantity</FtrTypography>
                  </ColumnHeader>
                  <ColumnHeader>
                    <FtrTypography type='subHeading' fontSize='16'>Unit Price ({getCurrencySymbol(currency)})</FtrTypography>
                  </ColumnHeader>
                  <ColumnHeader>
                    <FtrTypography type='subHeading' fontSize='16'>Total Price ({getCurrencySymbol(currency)})</FtrTypography>
                  </ColumnHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {quantityArray.map(curr => {
                  return (
                    <TableRow
                      classes={{
                        root: classes.tableRowRoot,
                        selected: classes.tableRowSelected
                      }}
                      hover
                      key={curr.qty}
                      onClick={() => handleChange(curr.qty)}
                      selected={isItemSelected(curr.qty)}
                    >
                      <TableCell align="right">{curr.qty}</TableCell>
                      {curr.isManualRFQ ?
                        <Fragment>
                          <TableCell></TableCell>
                          <TableCell align="right">MANUAL RFQ</TableCell>
                        </Fragment>
                        : <Fragment>
                          <TableCell align="right">{
                            convertPriceToCurrencyNoPrefix({
                              price: curr.unit,
                              currency,
                              exchangeRate
                            })}
                          </TableCell>
                          <TableCell align="right">{
                            convertPriceToCurrencyNoPrefix({
                              price: curr.total,
                              currency,
                              exchangeRate
                            })}
                          </TableCell>
                        </Fragment>
                      }
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions>
            <FtrButton
              color='blue'
              size='small'
              onClick={() => handleProceed()}
              style={{ marginTop: '1rem' }}
              loading={loading}
              disabled={loading}
            >
              Proceed
            </FtrButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(BulkPricingPopup);
