import JSZip from "jszip";
import { saveAs } from "file-saver";
import { isArray } from "lodash";

import { getSupportedFileTypesWithCache } from "../apis/configurationApi";
import { pdfFlatten } from "../apis/pdfApi";
import { supplierDownloadCADFileAPI } from "../apis/s3Api";

import { isEmptyValue } from './commonUtils';
import { getFileNameFromCadFile } from './itemUtils';
import { isUnixTimestampValid } from './dateTimeUtils';
import { isFileNeedWaterMark } from "./supplierCadDownloadUtils";
import { isArrayString } from "./arrayUtils";

import { notifyError, notifySuccess } from "../services/notificationService";
import { generatePresignedUrl } from "../services/s3Service";

import { CAD_RENDERER_TO_3D_TYPES } from '../constants/cadRendererConstants';
import { IMAGE_2D_GENERATE_SUPPORT_FILE_TYPES } from "../constants/twoDImageConstants";
import { PO_FORM_DIR_NAME, REACT_APP_ENV } from '../constants';
import { ALLOWED_FILE_TYPES_IPHONE } from "../constants/fileConstants";

// --------------------------------------------------------------------------------------------------------------------

export const downloadAllFiles = async (urlList, folderName) => {
  if (isEmptyValue(urlList)) {
    return;
  }

  const zip = new JSZip();
  const folder = zip.folder(folderName);
  await Promise.all(
    urlList.map(link =>
      fetch(link)
        .then(response => response.blob())
        .then(blob => folder.file(link.split("/").reverse()[0], blob))
    )
  );
  return zip.generateAsync({ type: "blob" })
    .then(blob => saveAs(blob, `${folderName}.zip`));
}

export const downloadAllS3Files = async (urlList, folderName) => {
  if (isEmptyValue(urlList)) {
    return;
  }
  const zip = new JSZip();
  const folder = zip.folder(folderName);
  await Promise.all(
    urlList.map(async (link) => {
      const signedUrl = await generatePresignedUrl(link);
      const response = await fetch(signedUrl);
      const blob = await response.blob();
      folder.file(link.split("/").reverse()[0], blob);
    })
  );
  return zip.generateAsync({ type: "blob" })
    .then(blob => saveAs(blob, `${folderName}.zip`));
}

export const downloadAllS3FilesWithWaterMark = async (urlList, folderName) => {
  if (isEmptyValue(urlList)) {
    return;
  }

  const zip = new JSZip();
  const folder = zip.folder(folderName);
  await Promise.all(
    urlList.map(async (s3ObjUrl) => {
      const blob = isFileNeedWaterMark(s3ObjUrl)
        ? await supplierDownloadCADFileAPI(s3ObjUrl)
        : await generatePresignedUrl(s3ObjUrl)
          .then(fetch)
          .then(response => response.blob());
      const fileName = getFileNameFromUrl(s3ObjUrl);
      folder.file(fileName, blob);
    })
  );

  return zip.generateAsync({ type: "blob" })
    .then(blob => saveAs(blob, `${folderName}.zip`));
}

/**
 *
 * @param urlObjectList array of objects with url and subFolderName (usually itemID) and download file name
 * @param  folderName
 * @returns
 */
export const downloadAllFilesSeparatedBySubFolders = async (urlObjectList, folderName) => {
  if (isEmptyValue(urlObjectList)) {
    return;
  }
  const zip = new JSZip();
  const folder = zip.folder(folderName);
  await Promise.all(
    urlObjectList.map(({ subFolder, url: link, fileName: downloadFilename }) =>
      fetch(link)
        .then(response => response.blob())
        .then(blob => {
          const fileName = downloadFilename || getFileNameFromCadFile(link);
          folder.file(`${subFolder}/${fileName}`, blob);
        })
    )
  );
  return zip.generateAsync({ type: "blob" })
    .then(blob => saveAs(blob, `${folderName}.zip`));
}

/**
 *
 * @param urlObjectList array of objects with url and subFolderName (usually itemID) and download file name
 * @param  folderName
 * @returns
 */
export const downloadAllS3FilesSeparatedBySubFolders = async (urlObjectList, folderName) => {
  if (isEmptyValue(urlObjectList)) {
    return;
  }
  const zip = new JSZip();
  const folder = zip.folder(folderName);
  await Promise.all(
    urlObjectList.map(async ({ subFolder, url: link, fileName: downloadFilename }) => {
      const signedUrl = await generatePresignedUrl(link);
      return fetch(signedUrl)
        .then(response => response.blob())
        .then(blob => {
          const fileName_1 = downloadFilename || getFileNameFromCadFile(link);
          folder.file(`${subFolder}/${fileName_1}`, blob);
        });
    })
  );
  return zip.generateAsync({ type: "blob" })
    .then(blob => saveAs(blob, `${folderName}.zip`));
}

export const downloadAllS3FilesSeparatedBySubFoldersWithWaterMark = async (urlObjectList, folderName) => {
  if (isEmptyValue(urlObjectList)) {
    return;
  }

  const zip = new JSZip();
  const folder = zip.folder(folderName);
  await Promise.all(
    urlObjectList.map(async ({ subFolder, url: s3ObjUrl, fileName: downloadFilename }) => {
      const blob = isFileNeedWaterMark(s3ObjUrl)
        ? await supplierDownloadCADFileAPI(s3ObjUrl)
        : await generatePresignedUrl(s3ObjUrl)
          .then(fetch)
          .then(response => response.blob());

      const fileName_1 = downloadFilename || getFileNameFromCadFile(s3ObjUrl);
      folder.file(`${subFolder}/${fileName_1}`, blob);
    })
  );

  return zip.generateAsync({ type: "blob" })
    .then(blob => saveAs(blob, `${folderName}.zip`));
}

export const is2DGenerationSupport = (fileExtension) => {
  return IMAGE_2D_GENERATE_SUPPORT_FILE_TYPES.includes(fileExtension.toLowerCase());
}

/**
 * Download file from link
 * @param {String} url
 * @param {Object} options
 * @param {String} options.fileName
 * @param {String} options.target
 */
export const createDownloadFile = (url, options = {}) => {
  const { fileName, target } = options
  const link = document.createElement("a");
  if (fileName) {
    link.download = fileName
  }
  link.href = url;
  if (target) {
    link.target = target;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadS3File = async (url, options = {}) => {
  const {
    isViewOnBrowser = false,
    fileNameParam = null,
    signal,
  } = options;
  return generatePresignedUrl(url)
    .then(async (signedUrl) => {
      try {
        const response = await fetch(signedUrl, { signal });
        const blob = await response.blob();
        // Create a temporary URL for the blob object
        const fileURL = URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = "_blank";
        if (!isViewOnBrowser) {
          link.download = fileNameParam || getFileNameFromUrl(url);
        }

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up the temporary resources
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading the file:', error);
      }
    })
    .catch(error => {
      console.error('Error downloading the file:', error);
    });
}

export const getS3File = (url, options = {}) => {
  const { fileNameParam = null, type = '' } = options;
  return new Promise((resolve) => {
    generatePresignedUrl(url).then((signedUrl) => {
      fetch(signedUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = fileNameParam || url.split('/').pop();
          const file = new File([blob], fileName, { type });
          resolve(file);
        })
        .catch((error) => {
          console.error('Error downloading the file:', error);
        });
    });
  });
};

export const getPORevisedUploadUrl = async (files, oldPOUrl) => {
  if (isEmptyValue(files)) {
    notifyError('No file selected');
  }
  const file = files[0];
  const keyArr = oldPOUrl.split('/');
  let [oldFileName, extension] = keyArr[keyArr.length - 1].split('.');
  extension = extension || 'pdf';
  const revStr = '_Rev';
  let newRev = 2;
  if (oldFileName.includes(revStr)) {
    const [oldFileNamePart, rev] = oldFileName.split(revStr);
    oldFileName = oldFileNamePart;
    newRev = Number(rev) + 1;
  }
  const newFileName = `${oldFileName}${revStr}${newRev}`;
  const customFileName = `${PO_FORM_DIR_NAME}/${newFileName}.${extension}`;
  const formData = new FormData();
  formData.append('filename', customFileName);
  formData.append('file', file);
  const result = await pdfFlatten(formData);
  notifySuccess('PDF(s) flattened successfully!');
  const s3ObjectUrl = result.data;
  return s3ObjectUrl;
};

/**
 *
 * @param {String[]} files
 * @param {String[]} types
 * @returns
 */
export const findCadFiles = (files, types = CAD_RENDERER_TO_3D_TYPES) => (
  files.find(item => types.includes(item.split('.').at(-1).toLowerCase())) || ""
)

/**
 * @param {String[]} files
 */
export const getUrlFileBySupportedFileTypes = async (files) => {
  // try to get the first 3D file first, if not then get the first supported file
  const cadFile = findCadFiles(files);
  if (cadFile) {
    return cadFile;
  }
  const { supportFileTypes } = await getSupportedFileTypesWithCache({ allSupportedTypes: true });
  return findCadFiles(files, supportFileTypes);
}


  /**
   * @function
   * @param {String | String[]} [cadFile=''] - cad file string or array
   * @returns {String} - the cad file for 3D rendering if exists, otherwise null
   * @description
   * Given a cad file string or array, return the cad file for 3D rendering if exists, otherwise null.
   * It will first try to find the first 3D file, if not then find the first supported file.
   */
export const getCadFileTo3DRenderer = (cadFile = '') => {
  if (isEmptyValue(cadFile)) {
    return null
  }
  if (typeof cadFile === 'string') {
    return findCadFiles(cadFile.split(','))
  }
  if (isArrayString(cadFile)) {
    return findCadFiles(cadFile)
  }
  return null;
}

/**
 * Extracts the timestamp from a file name in a given URL if it follows the format `filename_timestamp`.
 *
 * Example URLs and their outputs:
 * - http://.../qc_report_1234556869.pdf -> qc_report.pdf
 * - eg: http://.../qc%20report_1234556869.pdf -> qc report.pdf
 *
 * @param {*} fileUrl
 */
export const extractFileNameWithoutTimestampFromUrl = (fileUrl) => {
  if (isEmptyValue(fileUrl)) {
    return '';
  }
  const fileNameWithExtension = decodeURIComponent(fileUrl).split('/').pop();
  const fileExtension = fileNameWithExtension.split('.').pop();
  let fileName = fileNameWithExtension.split('.').slice(0, -1).join('.');
  fileName = fileName.includes('_')
    ? fileName.split('_').slice(0, -1).join('_')
    : fileName;
  return `${fileName}.${fileExtension}`;
}

/**
 * Extracts the timestamp from a file name in a given URL if it follows the format `filename_timestamp`.
 *
 * Example URLs and their outputs:
 * - `http://.../SupplierInvoice/Invoice_0046513901_1234556869.pdf` -> `1234556869`
 * - `http://.../qc_report_1234556869.pdf` -> `1234556869`
 * - `http://.../SupplierInvoice/Invoice_0046513901.pdf` -> ``
 *
 * @param {string} fileUrl - The URL of the file to extract the timestamp from.
 * @returns {string} The timestamp as a string if valid, otherwise an empty string.
 */
export const extractTimestampStringFromUrl = (fileUrl) => {
  if (isEmptyValue(fileUrl)) {
    return '';
  }
  const fileNameWithExtension = decodeURIComponent(fileUrl).split('/').pop();
  const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.');
  const parts = fileName.split('_');
  const timestamp = parts.pop();
  return isUnixTimestampValid(timestamp) ? timestamp : '';
}

/**
 * Checks if a file name in the given URL ends with a Unix timestamp.
 *
 * File names that pass this tests will end in a valid Unix timestamp
 * which must be preceded by at least two underscores.
 * Example URLs and their outputs:
 * - `http://.../SupplierInvoice/Invoice_0046513901_1234556869.pdf` -> true
 * - `http://.../qc_report_1234556869.pdf` -> false
 * - `http://.../SupplierInvoice/Invoice_0046513901.pdf` -> false
 *
 * @param {string} fileUrl - The URL of the file to check.
 * @returns {boolean} Returns `true` if the file name ends with a valid Unix timestamp, `false` otherwise.
 */
export const isFileNameWithTimestamp = (fileUrl) => {
  if (isEmptyValue(fileUrl)) {
    return false;
  }
  const fileNameWithExtension = decodeURIComponent(fileUrl).split('/').pop();
  const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.');
  const parts = fileName.split('_');
  if (parts.length < 3) {
    return false; // Need at least two underscores before the timestamp
  }
  return isUnixTimestampValid(parts.at(-1));
}

/**
 * Extracts the file name in a given URL without the file extension
 *
 * Example URL and its output:
 * - http://.../qc_report_1234556869.pdf -> qc_report_1234556869
 *
 * @param {*} fileUrl
 */
export const extractFileNameFromUrlFull = (fileUrl) => {
  if (isEmptyValue(fileUrl)) {
    return '';
  }

  const fileNameWithExtension = decodeURIComponent(fileUrl).split('/').pop();
  return fileNameWithExtension;
}

export const isPdfFile = (filePath) => {
  if (isEmptyValue(filePath)) {
    return false;
  }

  return filePath.toLowerCase().endsWith('.pdf');
}

export const extractAndDecodeFileNameAndExtensionFromUrl = (fileUrl) => {
  if (!fileUrl) {
    return [];
  }

  const fullDecodedFileName = extractFileNameWithoutTimestampFromUrl(decodeURIComponent(fileUrl));
  const lastIndexOfDot = fullDecodedFileName.lastIndexOf('.');
  const fileName = fullDecodedFileName.substring(0, lastIndexOfDot);
  const fileExtension = fullDecodedFileName.substring(lastIndexOfDot + 1);
  return [fileName, fileExtension];
}

export const getFileExtension = (file = '') => {
  const fileNameArr = file.split('.');
  if (fileNameArr.length > 1) {
    return fileNameArr[fileNameArr.length - 1].toLowerCase();
  }
  return '';
}

/**
 * Splits a filename containing an extension into two parts, where the string
 * after the last dot is taken to be the file extension.
 *
 * @param filename
 * @returns {*[]}
 */
export const splitFilenameByExtension = (filename) => {
  if (isEmptyValue(filename)) {
    return [];
  }
  const fileNameParts = filename.split('.');
  const filenameWithoutExtension = fileNameParts
    .slice(0, -1)
    .join('.');
  const fileExtension = fileNameParts.at(-1);
  return [filenameWithoutExtension, fileExtension];
}

/**
 * Returns the decoded file name and removes the timestamp from the filename if it exists,
 * for files which are known to not have a file extension.
 *
 * @param filename
 * @returns {string}
 */
export const stripTimestampFromFilenameWithoutExtension = (filename) => {
  if (isEmptyValue(filename)) {
    return '';
  }
  const decodedFileName = decodeURIComponent(filename); // to handle encoded URL file name which contains special characters
  const partNameArr = decodedFileName.split('_');
  const possibleTimestamp = partNameArr.at(-1);
  return partNameArr.length > 1 && isUnixTimestampValid(possibleTimestamp)
    ? partNameArr.slice(0, -1).join('_')
    : partNameArr.join('_');
}

/**
 * Returns the decoded file name and removes the timestamp from the filename if it exists,
 * for files which are known to have a file extension.
 *
 * @param filename
 * @returns {string}
 */
export const stripTimestampFromFilenameWithExtension = (filename) => {
  if (isEmptyValue(filename)) {
    return '';
  }
  const [filenameWithoutExtension, fileExtension] = splitFilenameByExtension(filename);
  return `${stripTimestampFromFilenameWithoutExtension(filenameWithoutExtension)}.${fileExtension}`;
}

const getFilenameWithExtensionFromUrl = (fileUrl) => {
  if (isEmptyValue(fileUrl)) {
    return '';
  }
  return fileUrl.split('/').pop();
}

/**
 * Checks if there's no extension or just a dot at the end in a filename
 *
 * @param filename
 * @returns {boolean}
 */
const hasExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf('.');
  return lastDotIndex !== -1 && lastDotIndex !== filename.length - 1;
}

/**
 * Returns the file name from an url.
 *
 * This function will strip the timestamp, if it exists in the filename.
 *
 * If the filename contains at least one dot, the function assumes that the
 * string following the last dot is the extension.
 *
 * @param fileUrl
 * @returns {string}
 */
export const getFileNameFromUrl = (fileUrl) => {
  const filenameWithPossibleExtension = getFilenameWithExtensionFromUrl(fileUrl);

  if (hasExtension(filenameWithPossibleExtension)) {
    return stripTimestampFromFilenameWithExtension(filenameWithPossibleExtension);
  }

  return stripTimestampFromFilenameWithoutExtension(filenameWithPossibleExtension);
}

/**
 * Similar to getFileNameFromUrl, but this function additionally strips
 * any file extension if it exists
 *
 * @param fileUrl
 * @returns {string}
 */
export const getFileNameWithoutExtensionFromUrl = (fileUrl) => {
  const filenameWithPossibleExtension = getFilenameWithExtensionFromUrl(fileUrl);

  if (hasExtension(filenameWithPossibleExtension)) {
    const [filenameWithoutExtension, _] = splitFilenameByExtension(filenameWithPossibleExtension);
    return stripTimestampFromFilenameWithoutExtension(filenameWithoutExtension);
  }

  return stripTimestampFromFilenameWithoutExtension(filenameWithPossibleExtension);
}

export const filterFilesByExtension = (files, extensions) => {
  return files?.filter(fileName => extensions?.some(ext =>
    fileName?.toLowerCase()?.endsWith(ext)
  ));
}

export const convertBytesToMB = (bytes) => {
  const value = (bytes / 1024 / 1024).toFixed(2);
  return `${value} MB`;
}

export const isImageFile = (filePath) => {
  if (isEmptyValue(filePath)) {
    return false;
  }

  return filePath.toLowerCase().endsWith('.jpg') || filePath.toLowerCase().endsWith('.jpeg') || filePath.toLowerCase().endsWith('.png');
}

export const removeFirstSlashIfExists = (key) => {
  if (key.startsWith('/')) {
    return key.substring(1);
  }
  return key;
}

/**
 * get default file for sample upload
 *
 * @returns {Array} An array with one sample file.
 */
export const getDefaultCADFile = async () => {
  const fileURL =
    REACT_APP_ENV === 'prod'
      ? 'https://factorem-s3-bucket.s3.ap-southeast-1.amazonaws.com/CadPart/8600e001-0365-47b9-badd-ebf660a53bd1_1684720689363.STEP'
      : 'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/CadPart/8600e001-0365-47b9-badd-ebf660a53bd1_1684720689363.step';

  // Fetch the file content
  let file = await getS3File(fileURL, {
    fileNameParam: 'Factorem_Sample_Part.STEP',
  });
  const files = [file];
  return files;
};

/**
 * Get the appropriate file types for iPhone.
 * If the provided file types are not in the allowed list, it will return "*", allowing any file type.
 * 
 * @param {string|string[]} fileTypesToCheck - A string or array of file types to check.
 * @returns {string} - A comma-separated list of allowed file types, or "*" if some file types are not allowed.
 *
 * @example
 * // Returns ".pdf, .zip"
 * getFileTypesForIphone('.pdf, .zip');
 * 
 * @example
 * // Returns "*"
 * getFileTypesForIphone('.step, .stl');
 */
export const getFileTypesForIphone = (fileTypesToCheck) => {
  if (typeof fileTypesToCheck !== 'string' && !isArray(fileTypesToCheck)) {
    return '*';
  }
  if (typeof fileTypesToCheck === 'string') {
    fileTypesToCheck = fileTypesToCheck.split(',').map(type => type.trim());
  }
  const isAllowed = fileTypesToCheck?.every(fileType => ALLOWED_FILE_TYPES_IPHONE.includes(fileType));
  if (isAllowed) {
    return fileTypesToCheck.join(', ')
  }
  return '*'
}