import React, { useEffect, useReducer } from 'react';
import { colors } from '../palette';
import { connect } from 'react-redux';
import { convertOldFormattedContact } from '../utils/userUtils';
import { getUserInfoWithCache } from '../apis/userApi';
import { addressInfoText } from '../utils/addressUtils';

function DisplayAddress(props) {
  const { data, name, contact, textWrap, country, userID } = props;
  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      contactName: name,
      contactNumber: contact,
    }
  );

  useEffect(() => {
    if (userID) {
      getUserInfoWithCache(userID).then((user) => {
        updateLocalState({
          contactName: user?.name,
          contactNumber: user?.contact,
        });
      });
    }
  }, [userID]);

  // address cannot be empty
  if (!data?.address) {
    return null;
  }
  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: '14px',
        textWrap: textWrap ? 'wrap' : 'normal',
        overflowY: 'auto',
        maxHeight: 110,
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        <span style={{ fontWeight: '600' }}>
          {data?.contactName || localState.contactName}
        </span>
        <span
          style={{
            width: '1px',
            height: '1rem',
            backgroundColor: colors.bgGrey,
          }}
        />
        <div>
          {data?.contactNumber ||
            convertOldFormattedContact(localState.contactNumber, country)}
        </div>
      </div>
      <span>{addressInfoText(data)}</span>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    name: state.auth.user.name,
    contact: state.auth.user.contact,
    country: state.auth.user.country,
  };
}

const withConnect = connect(mapStateToProps);

export default withConnect(DisplayAddress);
