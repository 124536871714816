import React from 'react';

import { Tooltip } from '@material-ui/core';

import WarningIconMui from '@material-ui/icons/ReportProblemOutlined';

import { colors } from '../../palette';

function WarningIcon(props) {
  const { toolTipText = '', className, style } = props;
  return (
    <Tooltip
      title={toolTipText}
      placement='top'
      arrow
      className={className}
      style={style}
      disableHoverListener={!toolTipText}
    >
      <WarningIconMui
        style={{
          fontSize: '1.5rem',
          color: colors.warningYellow,
        }}
      />
    </Tooltip>
  );
}

export default WarningIcon;
