import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';

import { colors } from '../../palette';

const useStyles = (props) => makeStyles((theme) => {
  return {
    root: {
      padding: '2px 8px',
      display: 'flex',
      alignItems: 'center',
      height: 40,
      width: props?.width || 325,
      margin: '0.3rem',
      borderRadius: 10,
      boxSizing: 'border-box',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      '& .MuiInputBase-input': {
        paddingLeft: 5,
      }
    },
    iconButton: {
      padding: '0.3rem',
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }
});

/**
 * Search Component with Icon Search
 * @param {Object} props - The component props.
 * @param {() => void} props.onSearch - To get the update of the text
 * @param {String} props.searchTerm - Default search text
 * @param {Boolean} props.useDebounceSearch - To use debounce
 * @param {Boolean} props.autoFocus - To use autofocus field
 * @param {String} props.placeholder - Instruction text when text field is empty
 * @returns {Number|String} - To adjust the width
 * @returns {JSX.Element} The rendered FtrSearchBar component.
 */
export default function FtrSearchBar(props) {
  const {
    onSearch = () => { },
    searchTerm: searchTermParam = '',
    useDebounceSearch = false,
    autoFocus = true,
    placeholder = 'Search',
    width = '325px',
    ...rest
  } = props;


  const classes = useStyles({ width })();

  const inputRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState(useDebounceSearch ? '' : searchTermParam);
  const [timer, setTimer] = useState(null);

  if (autoFocus) {
    inputRef?.current?.focus();
  }

  useEffect(() => {
    setSearchTerm(searchTermParam);
  }, [searchTermParam]);

  const handleSearch = (e) => {
    const _searchTerm = e.target.value;
    setSearchTerm(_searchTerm);

    if (timer) {
      clearTimeout(timer);
    }

    if (!useDebounceSearch) {
      onSearch(_searchTerm);
      inputRef?.current?.focus();
    } else {
      const _timer = setTimeout(() => {
        onSearch(_searchTerm);
        inputRef?.current?.focus();
      }, 500);
      setTimer(_timer);
    }
  }

  const handleResetSearchTerm = () => {
    inputRef?.current?.focus();
    setSearchTerm('');
    onSearch('');
  }

  return (
    <Paper
      component="form"
      className={classes.root}
      elevation={1}
      variant="outlined"
      square
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...rest}
    >
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={handleSearch}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        value={searchTerm}
        autoFocus={autoFocus}
        data-cy="search-field"
        startAdornment={<SearchIcon htmlColor={colors.neutral060} />}
        style={{ width: width }}
      />
      {searchTerm && (
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={handleResetSearchTerm}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  );
}
