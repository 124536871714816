export const EMAIL_TEMPLATE = {
  VERIFY_EMAIL_1: 'VERIFY_EMAIL_1',
  VERIFY_EMAIL_2: 'VERIFY_EMAIL_2',
  VERIFY_EMAIL_3: 'VERIFY_EMAIL_3',
  SIGNUP_FOLLOW_UP_1: 'SIGNUP_FOLLOW_UP_1',
  SIGNUP_FOLLOW_UP_2: 'SIGNUP_FOLLOW_UP_2',
  SIGNUP_FOLLOW_UP_3: 'SIGNUP_FOLLOW_UP_3',
  SIGNUP_FOLLOW_UP_4: 'SIGNUP_FOLLOW_UP_4',
  SIGNUP_FOLLOW_UP_5: 'SIGNUP_FOLLOW_UP_5',
};

export const AUTOMATIC_CHASER_EMAIL_TYPE = {
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  SIGNUP_FOLLOW_UP: 'SIGNUP_FOLLOW_UP',
};

export const EMAIL_TEMPLATE_TRIGGER = {
  [EMAIL_TEMPLATE.VERIFY_EMAIL_1]: {
    keyDate: 'registrationDate',
    afterInHours: 2,
  },
  [EMAIL_TEMPLATE.VERIFY_EMAIL_2]: {
    keyDate: 'registrationDate',
    afterInHours: 48,
  },
  [EMAIL_TEMPLATE.VERIFY_EMAIL_3]: {
    keyDate: 'registrationDate',
    afterInHours: 72,
  },
  [EMAIL_TEMPLATE.SIGNUP_FOLLOW_UP_1]: {
    keyDate: 'verificationDate',
    afterInHours: 24,
  },
  [EMAIL_TEMPLATE.SIGNUP_FOLLOW_UP_2]: {
    keyDate: 'verificationDate',
    afterInHours: 72,
  },
  [EMAIL_TEMPLATE.SIGNUP_FOLLOW_UP_3]: {
    keyDate: 'verificationDate',
    afterInHours: 168, // 1 week
  },
  [EMAIL_TEMPLATE.SIGNUP_FOLLOW_UP_4]: {
    keyDate: 'verificationDate',
    afterInHours: 336, // 2 weeks
  },
  [EMAIL_TEMPLATE.SIGNUP_FOLLOW_UP_5]: {
    keyDate: 'verificationDate',
    afterInHours: 720, // 1 month or 30 days
  },
};

export const EMAIL_TRIGGER_ACTION_NAMES = {
  WATCH_ITEMS: 'watch-items',
  REMOVE_ITEMS: 'remove-items',
  FEEDBACK_ORDER_COMPLETION: 'feedback-order-completion',
  GET_SUPPLIER_INVOICE: 'get-supplier-invoice',
}

export const EMAIL_BREVO_TYPES = {
  RFQ_ITEM: 'rfq-item',
  RFQ_PROJECT: 'rfq-project',
  RFQ_ITEM_REMINDER: 'rfq-item-reminder',
  RFQ_PROJECT_REMINDER: 'rfq-project-reminder',
  ORDERS_CONFIRMATION: 'orders-confirmation',
  NEW_RFQ_PROJECT_QUOTATIONS: 'new-rfq-project-quotations',
  EXPIRING_ITEMS: 'expiring-items',
  ORDER_READY_NEED_UPDATE: 'order-ready-need-update',
  ORDER_READY_READY_TO_SHIP: 'order-ready-ready-to-ship',
  REMINDER_SUBMIT_INVOICE: 'reminder-submit-invoice',
  REMINDER_ORDER_DELIVERY_DUE_SOON: 'reminder-order-delivery-due-soon',
  REMINDER_ORDERS_ACCEPTANCE: 'reminder-orders-acceptance',
  ORDERS_ACCEPTANCE: 'po-orders-acceptance',
};

export const EMAIL_TEMPLATE_NAME = {
  ITEM_PROJECT_CHANGED_NOTIFICATION: 'itemProjectChangedNotification',
  APPROVE_QUOTATION: 'approveQuotation',
  ENQUIRY_RECEIVED: 'enquiryReceived',
  EXPIRING_ITEMS: 'expiringItems',
  FABRICATION_IN_PROGRESS: 'fabricationInProgress',
  FABRICATION_IN_PROGRESS_PROJECT: 'fabricationInProgressProject',
  GENERATE_RFQ_ITEMS: 'generateRFQItems',
  GENERATE_RFQ_PROJECTS: 'generateRFQProjects',
  ITEM_VERIFIED: 'itemVerified',
  MULTIPLE_ENQUIRIES_RECEIVED: 'multipleEnquiriesReceived',
  NDA_VERIFIED: 'ndaVerified',
  NEW_ITEM: 'newItem',
  NEW_NDA_UPLOAD: 'newNdaUpload',
  NEW_QUOTATION: 'newQuotation',
  NEW_QUOTATIONS_VERIFY: 'newQuotationsVerify',
  NEW_QUOTATION_VERIFY: 'newQuotationVerify',
  NEW_RFQ_PROJECT_QUOTATIONS: 'newRfqProjectQuotations',
  NEW_USER: 'newUser',
  ONBOARDING_STEP_0: 'onboardingStep0',
  ONBOARDING_STEP_1: 'onboardingStep1',
  ONBOARDING_STEP_2: 'onboardingStep2',
  ONBOARDING_STEP_3A: 'onboardingStep3a',
  ONBOARDING_STEP_3B: 'onboardingStep3b',
  ONBOARDING_STEP_4A: 'onboardingStep4a',
  ONBOARDING_STEP_4B: 'onboardingStep4b',
  ONBOARDING_STEP_5: 'onboardingStep5',
  ORDERS_ACCEPTANCE: 'ordersAcceptance',
  ORDERS_CONFIRMATION: 'ordersConfirmation',
  ORDER_CONFIRMATION: 'orderConfirmation',
  ORDER_READY_READY_TO_SHIP: 'orderReadyReadyToShip',
  ORDER_READY_NEED_UPDATE: 'orderReadyNeedUpdate',
  ORDER_READY_NEED_VERIFY: 'orderReadyNeedVerify',
  ORDER_READY_VERIFIED: 'orderReadyVerified',
  PARTS_DELIVERED_CREDIT: 'partsDeliveredCredit',
  PARTS_DELIVERED_STRIPE: 'partsDeliveredStripe',
  PDF_GENERATOR_ERROR: 'pdfGeneratorError',
  PROJECT_ITEMS_VERIFIED: 'projectItemsVerified',
  PROJECT_OWNER_UPDATE: 'projectOwnerUpdate',
  QUOTES_EXPIRED: 'quotesExpired',
  RESET_ACCOUNT: 'resetAccount',
  SHARE_QUOTATION: 'shareQuotation',
  TEST_EMAIL: 'testEmail',
  VERIFY_EMAIL: 'verifyEmail',
  VERIFY_NUMBER_EMAIL: 'verifyNumberEmail',
  WATCHING_JOB: 'watchingJob',
  WELCOME_USER: 'welcomeUser',
  VERIFY_EMAIL_1: 'verifyEmail1',
  VERIFY_EMAIL_2: 'verifyEmail2',
  VERIFY_EMAIL_3: 'verifyEmail3',
  SIGNUP_FOLLOW_UP_1: 'signupFollowUp1',
  SIGNUP_FOLLOW_UP_2: 'signupFollowUp2',
  SIGNUP_FOLLOW_UP_3: 'signupFollowUp3',
  SUPPLIER_PROJECT_COMPLETE: 'supplierProjectComplete',
  SUPPLIER_INVOICE_HUBDOC: 'supplierInvoiceHubdoc',
  REMINDER_RFQ_PROJECTS: 'reminderRFQProjects',
  REMINDER_RFQ_ITEMS: 'reminderRFQItems',
  REMINDER_ORDERS_ACCEPTANCE: 'reminderOrdersAcceptance',
  REMINDER_SUBMIT_INVOICE: 'reminderSubmitInvoice',
  REMINDER_ORDER_DELIVERY_DUE_SOON: 'reminderOrderDeliveryDueSoon',
  CURATED_RFQ_ITEMS: 'curatedRfqItems',
  CURATED_RFQ_PROJECTS: 'curatedRfqProjects',
};

export const ADMIN_EMAIL_SCHEDULER = 'scheduler@factorem.co';
