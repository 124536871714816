import {
  GET_ORDERS_FAILURE,
  GET_ORDERS_LOADING,
  GET_ORDERS_SUCCESS
} from "../actions";
import { LOGOUT_SUCCESS } from "../actions/";

const defaultState = {
  orders: [],
  getOrdersLoading: false,
  getOrdersSuccess: false,
  getOrdersFailure: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ORDERS_LOADING:
      return {
        ...state,
        getOrdersLoading: true,
        getOrdersSuccess: false,
        getOrdersFailure: false
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        getOrdersLoading: false,
        getOrdersSuccess: true,
        getOrdersFailure: false,
        orders: action.orders
      };
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        getOrdersLoading: false,
        getOrdersSuccess: false,
        getOrdersFailure: true
      };
    case LOGOUT_SUCCESS:
      return defaultState;
    default:
      return state;
  }
};
