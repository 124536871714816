import { supplierDownloadCADFileAPI } from "../apis/s3Api"

import { downloadBlobUtil } from "./downloadUtils";
import { getFileNameFromCadFile } from "./itemUtils";


// -------------------------------------------------------------------------------------------------

const WATERMARK_FILE_TYPES = [
  'stp',
  'step',
]

export const isFileNeedWaterMark = (s3ObjUrl) => {
  for (const fileType of WATERMARK_FILE_TYPES) {
    if (s3ObjUrl.toLowerCase().endsWith(fileType)) {
      return true;
    }
  }

  return false;
}

export const supplierDownloadCadFile = async (s3ObjUrl) => {
  const blob = await supplierDownloadCADFileAPI(s3ObjUrl);
  const fileName = getFileNameFromCadFile(s3ObjUrl);
  downloadBlobUtil(blob, fileName);
}
