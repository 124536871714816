import { isEmpty } from "lodash";

const KEY_TEXT_MAPPING = {
  priceBiddedPerUnit: 'Price bidded',
  cmmPrice: 'CMM Price',
}

export const validatePriceParams = (params) => {
  const error = {};
  for (const [key, value] of Object.entries(params)) {
    if (typeof value === "string" && isEmpty(value)) {
      error[key] = `Field ${KEY_TEXT_MAPPING[key] || key} is required`;
    } else if (Number.isNaN(Number(value))) {
      error[key] = `Invalid value`;
    }
  }
  return error;
}
