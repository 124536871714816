import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect } from 'react-redux';

import Title from '../Title';
import { notifyError, notifySuccess } from '../../services/notificationService';
import { useQuery, useQueryClient } from 'react-query';
import { deleteDeliveryInfo } from '../../apis/deliveryInfoApi';
import { colors } from '../../palette';
import CreateUpdateAddressPopup from './CreateUpdateAddressPopup';
import YesNoPopup from './YesNoPopup';
import { getUserAddresses, getUserInfo } from '../../apis/userApi';
import { convertOldFormattedContact } from '../../utils/userUtils';
import { addressInfoText } from '../../utils/addressUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 1000,
    minWidth: 200,
    borderRadius: 0,
    margin: '1rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  rowTitle: {
    fontWeight: 600,
  },
  rowContent: {
    marginLeft: 5,
  },
  deleteIcon: {
    cursor: 'pointer',
    color: colors.hotRed,
  },
  editIcon: {
    cursor: 'pointer',
    color: colors.solidBlue,
  },
  checkIcon: {
    cursor: 'pointer',
    color: colors.successGreen,
  },
  rowAddress: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
    '&:hover $rowActions': {
      visibility: 'visible',
    },
  },
  rowActions: {
    gap: '5px',
    paddingLeft: '5px',
    visibility: 'hidden',
    display: 'flex',
  },
  containerAddress: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  containerColumn: {},
}));

function EditorAddressesPopup(props) {
  const classes = useStyles();
  const { open, onClose, userID, onSuccess = () => null, myUserID } = props;

  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data } = useQuery('getUserAddresses', () => getUserAddresses(userID));
  const { data: userInfo } = useQuery('getUserInfo', () => getUserInfo(userID));

  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedDeliveryInfoID, setSelectedDeliveryInfoID] = useState(null);
  const [showCreateUpdatePopup, setShowCreateUpdatePopup] = useState({
    type: 'create',
    status: false,
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  useEffect(() => {
    if (data && selectedDeliveryInfoID) {
      const address = data.find(
        (item) => item.deliveryInfoID === selectedDeliveryInfoID
      );
      if (address) {
        address.contactName = address.contactName || userInfo.name
        address.contactNumber = address.contactNumber || convertOldFormattedContact(userInfo.contact, userInfo.country)
        setSelectedAddress(address);
      }
    }
  }, [data, selectedDeliveryInfoID]);

  if (!data) {
    return null;
  }

  const handleDeleteDeliveryInfo = () => {
    deleteDeliveryInfo(selectedAddress?.deliveryInfoID)
      .then(() => {
        notifySuccess('Successfully deleted a address');
        queryClient.invalidateQueries('getUserAddresses');
        onSuccess();
      })
      .catch(() => {
        notifyError('Failed deleted a address');
      });
  };

  // sorted by default shipping and default billing
  const sortedData = data?.sort(
    (a, b) =>
      b.defaultShipping - a.defaultShipping ||
      b.defaultBilling - a.defaultBilling
  );

  return (
    <React.Fragment>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={onClose}
        aria-labelledby='editor-addresses-popup'
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id='editor-addresses-popup'>
          <Title contentTitle={`Manage Addresses`} size='small' />
        </DialogTitle>
        <div
          style={{
            paddingBottom: '2rem',
          }}
        >
          <DialogContent
            style={{
              padding: `0 ${isMobile ? '2rem' : '3rem'}`,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <section>
                <div style={{ margin: '0' }}>
                  <div
                    style={{
                      fontSize: '1.2rem',
                      marginBottom: '1rem',
                    }}
                  >
                    Address
                    {userID !== myUserID && ` - ${userInfo?.name} (${userInfo?.userID})`}
                  </div>
                  {sortedData.map((address) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                      }}
                      key={address?.deliveryInfoID}
                    >
                      <Grid container>
                        <Grid
                          item
                          md={10}
                          xs={12}
                          onClick={() => {
                            if (isMobile) {
                              setSelectedDeliveryInfoID(address?.deliveryInfoID);
                              setShowCreateUpdatePopup({
                                type: 'update',
                                status: true,
                              });
                            }
                          }}
                        >
                          <div className={classes.containerAddress}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  marginBottom: '3px',
                                }}
                              >
                                {address?.contactName || userInfo?.name}
                              </div>
                              <span
                                style={{
                                  width: '1px',
                                  height: '1.5rem',
                                  backgroundColor: colors.bgGrey,
                                }}
                              />
                              <div>
                                {address.contactNumber || convertOldFormattedContact(userInfo?.contact, userInfo?.country)}
                              </div>
                            </div>
                            <div>{addressInfoText(address) || 'address'}</div>
                            <div
                              style={{
                                display: 'flex',
                                gap: '0.5rem',
                              }}
                            >
                              {!!address?.defaultShipping && (
                                <span
                                  style={{
                                    border: `1px solid ${colors.solidBlue}`,
                                    padding: '3px',
                                    color: colors.solidBlue,
                                    width: 'fit-content',
                                  }}
                                >
                                  Default Shipping
                                </span>
                              )}
                              {!!address?.defaultBilling && (
                                <span
                                  style={{
                                    border: `1px solid ${colors.solidBlue}`,
                                    padding: '3px',
                                    color: colors.solidBlue,
                                    width: 'fit-content',
                                  }}
                                >
                                  Default Billing
                                </span>
                              )}
                            </div>
                          </div>
                        </Grid>
                        {!isMobile && (
                          <Grid
                            item
                            md={2}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'end',
                              gap: '5px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: '0.5rem',
                              }}
                            >
                              <Tooltip title="Edit" arrow>
                                <div
                                  style={{
                                    color: colors.solidBlue,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setSelectedDeliveryInfoID(
                                      address?.deliveryInfoID
                                    );
                                    setShowCreateUpdatePopup({
                                      type: 'update',
                                      status: true,
                                    });
                                  }}
                                >
                                  Edit
                                </div>
                              </Tooltip>
                              <Tooltip title={address?.defaultShipping ? "Can't delete default shipping address" : 'Delete'} arrow>
                                <div
                                  style={{
                                    color: colors.solidBlue,
                                    cursor: address?.defaultShipping ? 'not-allowed' : 'pointer',
                                  }}
                                  onClick={() => {
                                    if (!address?.defaultShipping) {
                                      setShowDeletePopup(true);
                                      setSelectedDeliveryInfoID(
                                        address?.deliveryInfoID
                                      );
                                    }
                                  }}
                                >
                                  Delete
                                </div>
                              </Tooltip>
                            </div>
                          </Grid>
                        )}
                      </Grid>

                      <Divider
                        style={{
                          width: '100%',
                          margin: '0.5rem 0',
                        }}
                      />
                    </div>
                  ))}
                </div>
              </section>
              <Button
                variant='contained'
                color='primary'
                style={{
                  width: 'fit-content',
                  marginTop: '5px',
                }}
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={() =>
                  setShowCreateUpdatePopup({
                    type: 'create',
                    status: true,
                  })
                }
              >
                Add a new address
              </Button>
            </div>
          </DialogContent>
        </div>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <YesNoPopup
        title='Are you sure that you want to delete this address?'
        open={showDeletePopup}
        handleNo={() => setShowDeletePopup(false)}
        handleYes={() => {
          handleDeleteDeliveryInfo();
          setShowDeletePopup(false);
        }}
      />
      <CreateUpdateAddressPopup
        open={showCreateUpdatePopup.status}
        type={showCreateUpdatePopup.type}
        data={selectedAddress}
        userID={userID}
        onSuccess={onSuccess}
        onClose={() =>
          setShowCreateUpdatePopup((prev) => ({
            ...prev,
            status: false,
          }))
        }
      />
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    myUserID: state.auth.user.userID,
    myUserName: state.auth.user.name,
    myUserContact: state.auth.user.contact,
  };
}

const withConnect = connect(mapStateToProps);

export default withConnect(EditorAddressesPopup);
