import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

import { getUserInfoWithCache } from '../../../apis/userApi';

import { downloadS3File } from '../../../utils/fileUtils';

import { colors } from '../../../palette';


// ---------------------------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  checkboxForm: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
    },
    maxWidth: 800,
    display: "flex",
    alignItems: "center",
  },
  checkboxFormLabel: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function AcknowledgmentCheckbox(props) {
  const classes = useStyles();

  const {
    checkAck,
    onCheck = () => { },
    userID,
  } = props;

  const downloadQuotationTerms = () => {
    getUserInfoWithCache(userID)
      .then(data => {
        if (data.quotationTermsUrl) {
          downloadS3File(data.quotationTermsUrl);
        } else {
          const link = document.createElement("a");
          link.href = "/QuotationTerms.pdf";
          link.download = "QuotationTerms.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  }

  const handleOnClickTerms = (e) => {
    e.preventDefault();
    e.stopPropagation();
    downloadQuotationTerms();
  }

  return (
    <FormGroup
      className={classes.checkboxForm}
      row
    >
      <FormControlLabel
        className={classes.checkboxFormLabel}
        control={
          <Checkbox
            data-cy="term-required-checkbox"
            checked={checkAck}
            onChange={evt => onCheck(evt.target.checked)}
            name="checkAck"
          />
        }
        label={
          <React.Fragment>
            By approving this quote, I acknowledge that all part details are final and that there will
            be no design changes allowed for this order. In addition, I agree to the{" "}
            <span onClick={handleOnClickTerms}
              style={{ textDecoration: "underline", cursor: "pointer", color: colors.blue050 }}>
              Terms and Conditions
            </span>
            {" "}outlined in the Quotation Form.
            Factorem will begin fabrication upon confirmation.
          </React.Fragment>
        }
      />
    </FormGroup>
  );
}

export default AcknowledgmentCheckbox;
