import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { colors } from '../../palette';

import { openPartSummaryPage } from '../../actions';

import {
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
  Link,
  Select,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Cancel as CancelIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  FolderOutlined as FolderOutlinedIcon,
} from '@material-ui/icons';

import {
  TECHNOLOGY_OPTIONS,
  MATERIAL_OPTIONS,
} from '../../constants/NewPartConstants';

import BlueDragDropSmall from '../../components/BlueDragDropSmall';
import ItemColorPalettePopup from '../../components/popups/ItemColorPalettePopup';

import FileIcon from '../../assets/img/file.png';
import InfoIcon from '../../components/icons/InfoIcon';
import { getFileNameFromCadFile, getSurfaceOptionsByTech } from '../../utils/itemUtils';
import { CADPART_DIRNAME } from '../../constants';
import { uploadFileToS3 } from '../../services/s3Service';
import { downloadS3File } from '../../utils/fileUtils';
import ToleranceDropdown from '../../components/dropdowns/ToleranceDropdown';
import { UNIT_TYPES } from '../../constants/unitConstants';
import { convertImperialToMetric, convertMetricToImperial } from '../../utils/userUtils';

const useStyles = makeStyles(() => ({
  body: {
    height: '100%',
  },
  breadcrumbsWrapper: {
    padding: '14px 45px 0',
    fontSize: '11pt',
  },
  breadcrumbsLink: {
    textDecoration: 'none',
    color: colors.solidBlue,
  },
  colorButton: {
    fontSize: 15,
    padding: '0px 8px',
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    textTransform: 'none',
    justifyContent: 'left',
    height: '32px',
  },
  formComponent: {
    height: '75%',
    padding: '25px 45px',
  },
  inputField: {
    fontSize: 15,
    padding: '0px 8px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '60%',
  },
  inputFieldRight: {
    fontSize: 15,
    padding: '0px 8px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '40%',
    marginLeft: 10,
  },
  inputLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    width: '20%',
  },
  rightSectionLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  rightSectionField: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontDarkGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    marginTop: 7,
  },
  sectionTitle: {
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    fontSize: 12,
    color: colors.fontDarkGrey,
    fontWeight: '600',
  },
  sectionSubtitle: {
    fontSize: 13,
    color: colors.fontLightGrey,
    marginTop: 7,
  },
  cncErrorMessage: {
    fontSize: 13,
    color: colors.errorRed,
    marginTop: 5,
    fontWeight: '100',
  },
  gridBorder: {
    border: `1px solid ${colors.bgLightGrey}`,
    padding: 10,
    borderBottom: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  rightGridBorder: {
    border: `1px solid ${colors.bgLightGrey}`,
    padding: 10,
    borderLeft: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  sectionForm: {
    margin: '20px 0',
  },
  sectionFormField: {
    display: 'flex',
    margin: '15px 0',
  },
  rightSectionFormField: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px 0',
  },
  commentInputField: {
    fontSize: 15,
    padding: 8,
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '100%',
  },
  sectionFiles: {
    margin: '15px 0 0',
  },
  noFilesSection: {
    height: 40,
    background: colors.bgLightGrey,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
  },
  fileUploadWording: {
    fontSize: 14,
    color: colors.fontLightGrey,
    marginLeft: 10,
  },
  buttonWrapper: {
    display: 'flex',
  },
  fileItem: {
    backgroundColor: colors.uploadFileBg,
    margin: '5px 0',
    height: 35,
    border: `solid 1px ${colors.uploadFileBorder}`,
    borderRadius: '5px',
    color: 'inherit',
    cursor: 'pointer',
  },
  itemText: {
    '& span, & svg': {
      fontSize: 14,
    },
  },
  breadcrumbLink: {
    color: colors.tertiaryBlue,
    fontSize: '11pt',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  breadcrumbLinkBold: {
    fontSize: '11pt',
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    minWidth: '200px',
    borderRadius: 5,
    position: 'relative',
    backgroundColor: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    padding: '3px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: colors.inputBorderBlue,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

function ModifyAddPart(props) {
  if (typeof props.location.item === 'undefined') {
    return (
      <Redirect
        to={{
          pathname: '/summary',
          state: { from: props.location },
        }}
      />
    );
  }

  let _tolerance = props.location.item.tolerance
  if (unitType === UNIT_TYPES.IMPERIAL) {
    _tolerance = convertMetricToImperial(_tolerance)
  }

  const { openSummaryPage, unitType } = props;
  const classes = useStyles();
  const [otherTechnology, setOtherTechnology] = useState(
    props.location.item.otherTechnology || ''
  );
  const [otherMaterial, setOtherMaterial] = useState(
    props.location.item.otherMaterial || ''
  );
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState(
    props.location.item.otherSurfaceFinish || ''
  );
  const [itemName, setItemName] = useState(props.location.item.name || '');
  const [itemTechnology, setItemTechnology] = useState(
    props.location.item.technology || ''
  );
  const [itemMaterial, setItemMaterial] = useState(
    props.location.item.material || ''
  );
  const [surfaceFinishOptions, setSurfaceFinishOptions] = useState(getSurfaceOptionsByTech(itemTechnology));
  const [itemSurfaceFinish, setItemSurfaceFinish] = useState(
    props.location.item.surfaceFinish || ''
  );
  const [itemTolerance, setItemTolerance] = useState(_tolerance || '');
  const [itemRemarks, setItemRemarks] = useState(
    props.location.item.remarks || ''
  );
  const [itemQty, setItemQty] = useState(props.location.item.qty || 1);
  const [itemPartApplication, setItemPartApplication] = useState(
    props.location.item.partApplication || ''
  );
  const [itemFiles, setItemFiles] = useState(props.location.item.cadPart || []);
  const [itemColor, setItemColor] = useState(props.location.item.color || '');
  const [colorDialogOpen, setColorDialogOpen] = useState(false);
  const techIsUnsure = itemTechnology === 'Others';
  const materialIsOthers = itemMaterial === 'Others';
  const surfaceFinishIsOthers = itemSurfaceFinish === 'Others';
  const technologyIs3DPrinting = itemTechnology === '3D Printing';
  const surfaceFinishIsPainting = itemSurfaceFinish === 'Painting';
  const surfaceFinishIsCoating = itemSurfaceFinish === 'Powder Coating';
  /**
   * If technology is 3D Printing and surface finish is painting
   * or powder coating, color input label will take on the label
   * of the surface finish
   */
  let colorInputLabel = surfaceFinishIsPainting
    ? 'Paint Color'
    : surfaceFinishIsCoating
      ? 'Powder Coat Color'
      : 'Color';

  const handleSubmitColor = (selectedColor) => {
    setItemColor(selectedColor || itemColor);
    setColorDialogOpen(false);
  };

  useEffect(() => {
    if (
      technologyIs3DPrinting ||
      surfaceFinishIsPainting ||
      surfaceFinishIsCoating
    ) {
      setItemColor(itemColor || 'Does not matter');
    } else {
      setItemColor('');
    }
  }, [itemTechnology, itemSurfaceFinish]);

  useEffect(() => {
    const newOptions = getSurfaceOptionsByTech(itemTechnology);
    setSurfaceFinishOptions(newOptions);
    setItemSurfaceFinish(newOptions[0]);
  }, [itemTechnology]);

  function handleCadFilesChange(files) {
    let stringOfLinks = [...itemFiles];
    Promise.all(
      Array.from(files).map((file) => {
        const splitFilename = file.name.split('.');
        const preFilename = splitFilename.slice(0, -1).join('.');
        const fileExtension = splitFilename[splitFilename.length - 1];
        const s3ObjectKey = `${CADPART_DIRNAME}/${preFilename}_${file.lastModified}.${fileExtension}`;
        return uploadFileToS3(file, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(' ').join('%20'));
          })
          .catch((err) => {
            alert(err);
          });
      })
    ).then(() => {
      setItemFiles(stringOfLinks);
    });
  }

  function handleSubmitForm() {
    const formDataAvailable = props.location.allItems;

    if (!itemName) {
      toast.error('Missing name field.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!itemQty) {
      toast.error('Missing quantity field.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // Create new part and details
      const updatedPart = {
        id: props.location.item.id,
        status: props.location.item.status,
        qty: itemQty,
        technology: itemTechnology,
        otherTechnology,
        surfaceFinish: itemSurfaceFinish,
        otherSurfaceFinish,
        material: itemMaterial,
        otherMaterial,
        cadPart: itemFiles,
        userID: props.location.item.userID,
        tolerance: unitType === UNIT_TYPES.METRIC ? itemTolerance : convertImperialToMetric(itemTolerance),
        remarks: itemRemarks,
        checked: false,
        partApplication: itemPartApplication,
        name: itemName,
        deleted: props.location.item.deleted,
        imageFile: '',
        deliveryPreference: 'on_premise',
        color: itemColor,
      };

      // Remove the object from the array
      const newFormData = formDataAvailable;
      newFormData[props.location.item.id] = updatedPart;

      // Updated total form data
      openSummaryPage(newFormData);
    }
  }

  const content = (
    <Fragment>
      <Grid container spacing={0}>
        <Grid container item direction='column' spacing={0} xs={8}>
          <Grid item>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>
                Upload technical drawings
              </div>
              <div className={classes.sectionSubtitle}>
                Upload technical drawings indicating critical requirements and
                specify standard. Any dimensions not indicated will be kept to
                our{' '}
                <a
                  href='https://factorem.medium.com/factorems-iso-2768-machining-guidelines-74a44cc75e5b'
                  style={{ color: colors.fontDarkGrey }}
                >
                  ISO 2768 Medium standard
                </a>
                . You may also upload any additional files relevant to this part
                here.
              </div>
              {itemTechnology === 'CNC Machining' &&
                !itemFiles.some((fileName) =>
                  fileName.toLowerCase().includes('.pdf')
                ) ? (
                <div className={classes.cncErrorMessage}>
                  Please upload complementing 2D files/technical drawings to
                  your parts here. This will help us ensure that we don’t miss
                  out on any component details.
                </div>
              ) : (
                <div />
              )}
              <div className={classes.sectionForm}>
                <BlueDragDropSmall handleFiles={handleCadFilesChange} />
                <div className={classes.sectionFiles}>
                  {itemFiles.length === 0 ? (
                    <div className={classes.noFilesSection}>
                      <InsertDriveFileOutlinedIcon
                        style={{ color: colors.fontLightGrey, fontSize: 20 }}
                      />
                      <div className={classes.fileUploadWording}>
                        No files uploaded
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                  <List>
                    {itemFiles.map((link, index) => {
                      const fileName = getFileNameFromCadFile(link);
                      return (
                        <ListItem
                          key={index}
                          className={classes.fileItem}
                          component='a'
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadS3File(link);
                          }}
                        >
                          <ListItemAvatar style={{ display: 'flex' }}>
                            <img
                              src={FileIcon}
                              alt='icon'
                              style={{ height: 15 }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            style={{
                              width: 130,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              fontSize: 12,
                            }}
                            primary={fileName}
                            className={classes.itemText}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={() => {
                                const cadPartFiles = [...itemFiles];
                                cadPartFiles.splice(index, 1);
                                setItemFiles(cadPartFiles);
                              }}
                            >
                              <CancelIcon
                                color='error'
                                style={{ fontSize: '15pt' }}
                              />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>Order specifications</div>
              <div className={classes.sectionForm}>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Order Name
                  </InputLabel>
                  <InputBase
                    id='name'
                    name='name'
                    className={classes.inputField}
                    value={itemName}
                    onChange={(evt) => {
                      setItemName(evt.target.value);
                    }}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Part Application
                  </InputLabel>
                  <InputBase
                    id='partApplication'
                    name='partApplication'
                    className={classes.inputField}
                    value={itemPartApplication}
                    onChange={(evt) => {
                      setItemPartApplication(evt.target.value);
                    }}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Quantity
                  </InputLabel>
                  <Input
                    className={classes.inputField}
                    disableUnderline={true}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 100000,
                      type: 'number',
                    }}
                    value={itemQty}
                    onChange={(evt) => {
                      setItemQty(evt.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.gridBorder}>
              <div className={classes.sectionTitle}>
                Part specifications
              </div>
              <div className={classes.sectionForm}>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Technology
                  </InputLabel>
                  <Select
                    id='technology'
                    input={<BootstrapInput />}
                    value={itemTechnology}
                    onChange={(evt) => {
                      const newTech = evt.target.value;
                      setItemTechnology(newTech);
                      if (
                        newTech === 'Others' ||
                        newTech === 'Not Sure' ||
                        newTech === 'Design Service'
                      ) {
                        setItemMaterial('Others');
                      } else {
                        setItemMaterial(MATERIAL_OPTIONS[newTech][0]);
                      }
                    }}
                  >
                    {TECHNOLOGY_OPTIONS.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <InputBase
                    disabled={!techIsUnsure}
                    id='otherTechnology'
                    name='otherTechnology'
                    placeholder='Custom technology'
                    type='text'
                    className={classes.inputFieldRight}
                    onChange={(evt) => {
                      setOtherTechnology(evt.target.value);
                    }}
                    value={otherTechnology}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Material
                  </InputLabel>
                  <Select
                    id='material'
                    input={<BootstrapInput />}
                    value={itemMaterial}
                    onChange={(evt) => {
                      const newMaterial = evt.target.value;
                      setItemMaterial(newMaterial);
                    }}
                  >
                    {MATERIAL_OPTIONS[itemTechnology].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <InputBase
                    disabled={!materialIsOthers}
                    id='otherMaterial'
                    name='otherMaterial'
                    placeholder='Other material'
                    type='text'
                    className={classes.inputFieldRight}
                    onChange={(evt) => {
                      setOtherMaterial(evt.target.value);
                    }}
                    value={otherMaterial}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Surface Finish
                  </InputLabel>
                  <Select
                    id='surfaceFinish'
                    input={<BootstrapInput />}
                    value={itemSurfaceFinish}
                    onChange={(evt) => {
                      setItemSurfaceFinish(evt.target.value);
                    }}
                  >
                    {surfaceFinishOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <InputBase
                    disabled={!surfaceFinishIsOthers}
                    id='otherSurfaceFinish'
                    name='otherSurfaceFinish'
                    placeholder='Other surface finish'
                    type='text'
                    className={classes.inputFieldRight}
                    onChange={(evt) => {
                      setOtherSurfaceFinish(evt.target.value);
                    }}
                    value={otherSurfaceFinish}
                  />
                </div>
                <div className={classes.sectionFormField}>
                  <InputLabel className={classes.inputLabel}>
                    Tolerance&nbsp;
                    <InfoIcon toolTipText="Please indicate tightest tolerance in your design" />
                  </InputLabel>
                  <ToleranceDropdown
                    value={itemTolerance}
                    onChange={(evt) => {
                      setItemTolerance(evt.target.value);
                    }}
                    unitType={unitType}
                  />
                </div>
                {technologyIs3DPrinting ||
                  surfaceFinishIsCoating ||
                  surfaceFinishIsPainting ? (
                  <div className={classes.sectionFormField}>
                    <InputLabel className={classes.inputLabel}>
                      {colorInputLabel}
                    </InputLabel>
                    <Button
                      className={classes.colorButton}
                      onClick={() => setColorDialogOpen(true)}
                    >
                      {itemColor}
                    </Button>
                    <ItemColorPalettePopup
                      dialogOpen={colorDialogOpen}
                      handleDialogClose={() => setColorDialogOpen(false)}
                      handleSubmitColor={(selectedColor) =>
                        handleSubmitColor(selectedColor)
                      }
                    />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </Grid>
          <Grid item>
            <div
              className={classes.gridBorder}
              style={{ borderBottom: `1px solid ${colors.bgLightGrey}` }}
            >
              <div className={classes.sectionTitle}>Comments</div>
              <div className={classes.sectionFormField}>
                <InputBase
                  multiline
                  rows='6'
                  id='remarks'
                  name='remarks'
                  type='text'
                  placeholder='Type additional comments here'
                  value={itemRemarks}
                  onChange={(evt) => {
                    setItemRemarks(evt.target.value);
                  }}
                  className={classes.commentInputField}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.rightGridBorder}>
            <div className={classes.rightSectionFormField}>
              <div className={classes.rightSectionLabel}>Qty required</div>
              <div className={classes.rightSectionField}>{itemQty}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Fragment>
  );

  return (
    <Fragment>
      <div className={classes.formComponent}>
        <div className={classes.formHeader}>
          <div>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link
                className={classes.breadcrumbLink}
                onClick={() => props.history.goBack()}
                aria-current='page'
              >
                All parts
              </Link>
              <Typography className={classes.breadcrumbLinkBold}>
                Edit details
              </Typography>
            </Breadcrumbs>
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              size='small'
              style={{ color: colors.tertiaryBlue }}
              onClick={() => handleSubmitForm()}
            >
              <FolderOutlinedIcon style={{ fontSize: 20 }} />
              <div style={{ marginLeft: 4 }}>Save details</div>
            </Button>
          </div>
        </div>
        {content}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '10px 0 20px',
          }}
        >
          <div className={classes.buttonWrapper}>
            <Button
              size='small'
              style={{ color: colors.tertiaryBlue }}
              onClick={() => handleSubmitForm()}
            >
              <FolderOutlinedIcon style={{ fontSize: 20 }} />
              <div style={{ marginLeft: 4 }}>Save details</div>
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    unitType: state.auth.user.unitType,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    openSummaryPage: (formDataAvailable) =>
      dispatch(openPartSummaryPage(formDataAvailable, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);
export default withRouter(withConnect(ModifyAddPart));
