import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import AcknowledgmentCheckboxV2 from './confirm-order-popup/AcknowledgmentCheckboxV2';
import ConfirmOrderOrderDetailsStepPage from './confirm-order-popup/ConfirmOrderOrderDetailsStepPage';
import ConfirmOrderPartDetailsStepPage from './confirm-order-popup/ConfirmOrderPartDetailsStepPage';
import FtrStepper from '../ftr-components/FtrStepper';
import { FlexColumnCenter, FlexRow } from '../layouts/FlexLayouts';
import { FtrButton, FtrH6, FtrTooltip } from '../ftr-components';


import { getFeedbackOfUsers } from '../../apis/userFeedbackApi';
import { getUserCreditType, getUserInfo } from '../../apis/userApi';

import { confirmMultiCheckout, getCustomerDeliveryInfoList } from '../../actions/multiCheckoutForm';

import withLoadingBackDropTextHOC from '../../hocs/withLoadingBackDropTextHOC';
import withConfirmOrderPopupsHOCV2 from './confirm-order-popup/withConfirmOrderPopupsHOCV2';

import { retrieveUserCredits } from '../../actions';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { getMultiCheckoutFormSelector } from '../../selectors/multiCheckoutFormSelector';
import { getUserRoleSelector } from '../../selectors/userSelector';

import { isEmptyValue } from '../../utils/commonUtils';

import { CUSTOMER_CREDIT_TYPES } from '../../constants/customerConstants';

import { GTM_ID_MAP } from '../../constants/GTMConstants';
import { ROLE_TYPES } from '../../constants';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------------

// Style the components
const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem 0 0 0',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
  checkoutSubmit: {
    background: `${colors.confirmCheckoutButton} !important`,
    color: 'white !important',
    '&:disabled': {
      background: `${colors.confirmCheckoutButtonDisabled} !important`,
    }
  }
}));

export const ConfirmOrderPopupContext = React.createContext();

function ConfirmOrderPopup(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    open,
    onClose = () => { },
    customerID,
    updatePopupsHocState = () => { },
    setLoadingMessage = () => { },
  } = props;

  const [{ isMobile }] = useDeviceCheck();

  const userRole = useSelector(getUserRoleSelector);
  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);
  
  const pricingStatus = multiCheckoutForm.status;

  const hasAddress = !!multiCheckoutForm.deliveryInfoID;

  const {
    data: creditType,
  } = useQuery(['getUserCreditType', customerID], () => {
    if (isEmptyValue(customerID)) {
      return CUSTOMER_CREDIT_TYPES.NON_CREDIT;
    }

    return getUserCreditType(customerID);
  });

  const {
    data: customerFeedback,
  } = useQuery(
    ['getFeedbackOfUsers', customerID],
    () => getFeedbackOfUsers(customerID)
  );

  const {
    data: userCredits,
  } = useQuery(
    ['getUserCredits', customerID],
    () => getUserInfo(customerID).then(user => user.credits),
  );

  const [activeStep, setActiveStep] = useState(0);
  const [checkAck, setCheckAck] = useState(false);

  useEffect(() => {
    dispatch(retrieveUserCredits(customerID));
    dispatch(getCustomerDeliveryInfoList(customerID));
  }, [
    customerID,
  ]);

  useEffect(() => {
    if (
      isEmptyValue(customerFeedback)
      && multiCheckoutForm.confirmOrderStatus === 'success'
    ) {
      setTimeout(() => {
        updatePopupsHocState({ showFeedbackPopup: true });
      }, 2000);
    }
  }, [
    customerFeedback,
    multiCheckoutForm.confirmOrderStatus,
  ]);

  useEffect(() => {
    setLoadingMessage(multiCheckoutForm.loadingMessage);
  }, [
    multiCheckoutForm.loadingMessage,
  ]);

  useEffect(() => {
    if (typeof multiCheckoutForm.showAcceptedPopup === 'boolean') {
      updatePopupsHocState({ showAcceptedPopup: multiCheckoutForm.showAcceptedPopup });
    }
  }, [
    multiCheckoutForm.showAcceptedPopup,
  ]);

  const steps = getSteps();

  function getSteps() {
    return [
      'Part Details',
      'Order Details',
      'Payment',
    ];
  }

  const handleSubmit = () => {
    dispatch(confirmMultiCheckout({
      customerID,
      creditType,
    }));
  }

  const renderStepsList = () => {
    return (
      <FtrStepper
        activeStep={activeStep}
        steps={steps}
      />
    );
  }

  const renderSubmitButton = () => {
    const buttonText = creditType === CUSTOMER_CREDIT_TYPES.CREDIT
      ? 'Accept Quote'
      : 'Confirm & Pay';

    let tooltipText = !checkAck ? 'Please acknowledge the terms on the left to proceed.' : '';
    if (!hasAddress) {
      tooltipText = 'Please add address!';
    }

    return (
      <FtrTooltip
        description={tooltipText}
      >
        <div>
          <FtrButton
            id={creditType === CUSTOMER_CREDIT_TYPES.CREDIT
              ? GTM_ID_MAP.BTN_ACCEPT_QUOTE
              : 'confirm-and-pay-btn'
            }
            data-cy='checkout-submit-btn'
            color='blue'
            customClass={classes.checkoutSubmit}
            onClick={handleSubmit}
            disabled={
              !checkAck
              || !hasAddress
              || multiCheckoutForm.confirmOrderStatus === 'loading'
              || pricingStatus === 'loading'
              || ![ROLE_TYPES.BUYER, ROLE_TYPES.SUPER_ADMIN].includes(userRole)
            }
            loading={multiCheckoutForm.confirmOrderStatus === 'loading'}
            style={{
              height: 55,
              width: 166,
              fontSize: 17,
              fontWeight: 600,
            }}
          >
            {buttonText}
          </FtrButton>
        </div>
      </FtrTooltip>
    );
  }

  const renderPartDetailsPage = () => {
    return (
      <ConfirmOrderPartDetailsStepPage />
    );
  }

  const renderOrderDetailsPage = () => {
    return (
      <ConfirmOrderOrderDetailsStepPage />
    );
  }

  const renderPaymentPage = () => {

  }

  const renderCurrentStepPage = () => {
    switch (activeStep) {
      case 0:
        return renderPartDetailsPage();
      case 1:
        return renderOrderDetailsPage();
      case 2:
        return renderPaymentPage();
      default:
        return null;
    }
  }

  const renderAcknowledgementCheckbox = () => {
    if (activeStep === 0) {
      return <Box />;
    }

    return (
      <AcknowledgmentCheckboxV2
        userID={customerID}
        checkAck={checkAck}
        onCheck={setCheckAck}
      />
    );
  }

  const renderActionButtons = () => {
    return (
      <FlexRow>
        <FtrButton
          variant='text'
          style={{
            color: colors.neutral070,
            height: 55,
            fontSize: 17,
            fontWeight: 600,
          }}
          onClick={() => {
            if (activeStep === 0) {
              onClose();
              return;
            }
            setActiveStep(val => val - 1);
          }}
        >
          Back
        </FtrButton>
        {activeStep === 0 && (
          <FtrButton
            color='blue'
            size='large'
            onClick={() => {
              setActiveStep(1);
            }}
            style={{
              marginLeft: '1rem',
              height: 55,
              fontSize: 17,
              fontWeight: 600,
            }}
            id='next-checkout-btn'
          >
            Next
          </FtrButton>
        )}
        {activeStep === 1 && renderSubmitButton()}
      </FlexRow>
    );
  }

  return (
    <ConfirmOrderPopupContext.Provider
      value={{
        creditType,
        updatePopupsHocState,
        userCredits,
        customerID,
      }}
    >
      <Dialog
        open={open}
        maxWidth='lg'
        fullWidth
        scroll='body'
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        PaperProps={{ className: classes.dialog }}
        onClose={onClose}
      >
        <DialogContent
          className={classes.dialogContent}
        >
          <FtrH6 style={{ marginBottom: '1rem' }}>
            Confirm your order
          </FtrH6>
          <div
            style={{
              width: '100%',
              maxWidth: 550,
            }}>
            {renderStepsList()}
          </div>
          <div
            style={{
              width: '100%',
              marginBottom: '1rem',
              padding: '0 1.5rem',
              boxSizing: 'border-box',
            }}
          >
            {renderCurrentStepPage()}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: colors.neutral010,
            padding: '1rem 2.5rem',
            boxShadow: '0px 10px 40px 0px #00000026',
          }}>
          {isMobile && (
            <FlexColumnCenter>
              {renderAcknowledgementCheckbox()}
              {renderActionButtons()}
            </FlexColumnCenter>
          )}
          {!isMobile && (
            <FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
              {renderAcknowledgementCheckbox()}
              {renderActionButtons()}
            </FlexRow>
          )}
        </DialogActions>
      </Dialog>
    </ConfirmOrderPopupContext.Provider>
  );
}


export default withConfirmOrderPopupsHOCV2(
  withLoadingBackDropTextHOC(ConfirmOrderPopup)
);
