import React from 'react';

import FtrSvgImage from '../../images/FtrSvgImage';
import FtrTypography from '../FtrTypography';
import { FtrIconButton } from '../FtrButton';
import { FlexRow } from '../../layouts/FlexLayouts';

import ReloadIcon from '../../../assets/icons/reload_icon.svg';
import ReloadDisabledIcon from '../../../assets/icons/reload_disabled_icon.svg';

import { colors } from '../../../palette';


// --------------------------------------------------------------------------------------------

function FtrOrderAgainButton(props) {
  const {
    handleClick = () => { },
    disabled,
  } = props;

  return (
    <FtrIconButton
      onClick={handleClick}
      variant='outlined'
      {...props}
    >
      <FlexRow style={{ marginLeft: 4, marginRight: 4 }}>
        <FtrSvgImage
          src={ReloadIcon}
          disabledIcon={ReloadDisabledIcon}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={disabled}
        />
        <FtrTypography
          fontSize='16'
          style={{
            fontWeight: 600,
            color: props.disabled ? `${colors.neutral050}99` : colors.blue060,
          }}>
          Order Again
        </FtrTypography>
      </FlexRow>
    </FtrIconButton>
  );
}

export default FtrOrderAgainButton;
