import React from 'react';
import { Link } from 'react-router-dom';


// -------------------------------------------------------------------------------------------------

function FtrLinkText(props) {
  const {
    text = '',
    onClick = () => { },
  } = props;

  return (
    <Link
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      {text}
    </Link>
  );
}

export default FtrLinkText;
