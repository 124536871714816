import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { setCustomerSettings } from '../actions/customerSettings';

import { getCustomerSettings } from '../apis/customerSettingsApi';

import { isEmptyValue } from '../utils/commonUtils';


// --------------------------------------------------------------------------------------------

const useCustomerSettingsHook = (userID) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const {
    data: customerSettings,
  } = useQuery(
    ['getCustomerSettings', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }

      return getCustomerSettings(userID);
    },
  );

  useEffect(() => {
    if (customerSettings) {
      dispatch(setCustomerSettings(customerSettings));
    }
  }, [customerSettings]);

  const invalidateQuery = () => {
    queryClient.invalidateQueries('getCustomerSettings');
  };

  return {
    customerSettings,
    invalidateQuery,
  }
};

export default useCustomerSettingsHook;
