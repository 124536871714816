import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles/index';

import Title from '../Title';
import ThreeDViewer from '../../pages/generation-tools/ThreeDViewer';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: '2000 !important',
  },
  paper: {
    width: '50rem',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  dialogContent: {
    padding: '0 3rem',
    paddingBottom: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0.5rem',
    },
  },
}));

function ThreeDViewerPopup(props) {
  const { fileUrl, onClose } = props;

  const classes = useStyles();

  return (
    <Dialog
      maxWidth='xl'
      open={true}
      onClose={onClose}
      aria-labelledby='3d-cad-renderer-popup'
      classes={{ paper: classes.paper, root: classes.root }}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <DialogTitle id='3d-viewer-popup'>
        <Title contentTitle='3D CAD Renderer' size='small' />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ThreeDViewer fileUrl={fileUrl} hideUploadFile />
        </div>
      </DialogContent>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default ThreeDViewerPopup;
