// Import settings
import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uniqueId } from 'lodash';

// Import material UI components
import { makeStyles } from '@material-ui/core';

// Import customised components
import UploadPartsStatus from './UploadPartsStatus';
import ProjectsView from './ProjectsView';
import { FtrHeading } from '../../components/ftr-components';
import FilterProject from './components/inputs/FilterProject';
import PartsLibraryFirstTimePopup from '../../components/popups/PartsLibraryFirstTimePopup';
import { PARTS_LIBRARY_FIRST_TIME } from '../../components/tour/guidedTours/tourConstants';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import UploadFilesField from '../../components/forms/form-fields/UploadFilesField';
import { ReactComponent as HelpIcon } from '../../assets/icons/help_icon.svg'

// Import API
import { getPartsLibraryProjectByUserID } from '../../apis/partsLibraryApi';
import { getGuidedTourCompletionStatus } from '../../apis/tourApi';
import { uploadFileToS3 } from '../../apis/s3Api';

import { colors } from '../../palette';
import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';

import { isEmptyValue, sleep } from '../../utils/commonUtils';
import { is2DGenerationSupport } from '../../utils/fileUtils';

import { useIntersectionSelector } from '../../hooks/useIntersectionSelector';
import useDeviceCheck from '../../hooks/useDeviceCheck';
import { useCadPartFileUpload } from '../../hooks/useCadPartFileUpload';

import { getCadPartS3Key } from '../../services/s3Service';
import { convert2DImage } from '../../services/convert2DImageService';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: colors.fontWhite,
    },
  },
  formComponent: {
    height: '75%',
    padding: '2rem 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 1rem',
    }
  },
  heading: {
    color: colors.neutral070,
  },
}));

function PartsLibrary(props) {
  const classes = useStyles();
  const [{ isMobile }] = useDeviceCheck();

  const { userID } = props;

  const [params, setParams] = useState(null);
  const [searchValue, setSearchValue] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const [saveButtonEnable, setSaveButtonEnable] = useState(true);
  const [numberDataObserver, setNumberDataObserver] = useState(20); // default 20 data
  const [statusChecked, setStatusChecked] = useState([]);
  const [showPartsLibraryFirstTimePopup, updateShowPartsLibraryFirstTimePopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      withApi: false,
    }
  );

  const { isLoading, data, isFetching } = useQuery(
    ['partsLibraryProjects', userID, params],
    () => {
      if (!params || !userID) {
        return;
      }
      return getPartsLibraryProjectByUserID(userID, params);
    }
  );

   // Progress bar
   const [numSuccessUploadedFiles, setNumSuccessUploadedFiles] = useState(0);
   const [numTotalFiles, setNumTotalFiles] = useState(0);
 
   const [
    cadPartListState,
     { addCadPart, updateCadPart, update2DImageUrl, removeCadPart },
   ] = useCadPartFileUpload();
   
   const handleFiles = async (newFiles) => {
     setSaveButtonEnable(false);
     setNumSuccessUploadedFiles(0);
     setNumTotalFiles(0);
 
     Promise.all(
       Array.from(newFiles).map(file => {
         const splitFilename = file.name.split('.');
         const fileExtension = splitFilename[splitFilename.length - 1];
 
         const id = uniqueId();
         addCadPart({
           id,
           uploadStatus: 'ongoing',
           file
         });
 
         setNumTotalFiles((numTotalFiles) => numTotalFiles + 1);
 
         return uploadFileToS3(file, getCadPartS3Key(file)).then(async (data) => {
           setNumSuccessUploadedFiles((numUploadedSuccessS3Files) => numUploadedSuccessS3Files + 1);
 
           const fileSize = parseInt(file.size / 1000, 10);
           const s3ObjectUrl = data.Location.split(' ').join('%20');
           const cadPart = {
             id,
             fileSize,
             link: s3ObjectUrl,
             imageLoading: false,
             file
           }
           updateCadPart(cadPart);
           if (is2DGenerationSupport(fileExtension)) {
             cadPart.imageLoading = true;
             await convert2DImage({ file_url: s3ObjectUrl })
               .then((data) => {
                 const twoDImageUrl = data['s3_file_url'];
                 update2DImageUrl(s3ObjectUrl, twoDImageUrl);
               })
               .catch(() => {
                 updateCadPart({
                   id,
                   imageLoading: false,
                   error: true,
                 });
               });
           }
         })
       })
     ).then(() => setSaveButtonEnable(true));
   };

  const handlePartsLibraryFirstTime = async () => {
    const [partsLibraryFirstTime] = await Promise.all([
      getGuidedTourCompletionStatus(userID, PARTS_LIBRARY_FIRST_TIME),
      sleep(4) // 4 seconds
    ])
    if (!partsLibraryFirstTime?.data?.completed) {
      updateShowPartsLibraryFirstTimePopup({ open: true, withApi: true })
    }
  }

  useEffect(() => {
    handlePartsLibraryFirstTime()
  }, [])

  const handleOnChange = ({ sortDateCreated, search, statusFilter }) => {
    const statusChecked = statusFilter.filter((item) => item.checked).map(item => item.label);
    setStatusChecked(statusChecked);
    setParams({ sortDateCreated });
    setSearchValue(search);
  };

  const checkSearchValue = (values) => {
    if (isEmptyValue(searchValue)) {
      return false;
    }
    for (const value of values) {
      const isFound =
        value &&
        String(value)
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase());
      if (isFound) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const _filteredData = isEmptyValue(searchValue) && isEmptyValue(statusChecked)
      ? data
      : data?.filter((project) => {
          const foundProject = checkSearchValue([
            project.projectName,
            project.projectLibraryID,
          ]);
          const foundItem = project.items?.some((item) =>
            checkSearchValue([
              item.name,
              item.material,
              item.materialColor,
              item.technology,
              item.quantity,
              item.itemLibraryID,
              item.customMaterial,
              item.surfaceFinish,
              item.customSurfaceFinish,
            ]) ||
            statusChecked.includes(item.status) 
          );
          return foundProject || foundItem;
        });
      const _dataShow = !isEmptyValue(_filteredData) ? _filteredData?.slice(0, numberDataObserver) : [];
      setDataShow(_dataShow);
  }, [data, searchValue, numberDataObserver, statusChecked]);

  useIntersectionSelector(
    `#project-library-index-${numberDataObserver - 1}`, // check the last index
    (entry) => {
      if (entry.isIntersecting) {
        setNumberDataObserver(numberDataObserver + 10); // add 10 data if isIntersecting
      }
    }
  );

  return (
    <div className={classes.body}>
      <div className={classes.formComponent}>
        <FlexRow>
          <FtrHeading className={classes.heading}>Parts Library</FtrHeading>
          <HelpIcon 
            style={{ cursor: 'pointer' }}
            onClick={() => updateShowPartsLibraryFirstTimePopup({ open: true, withApi: false })}
          />
          {isMobile && (
            <UploadFilesField
              technology={TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE} // to allow all file types
              handleFiles={handleFiles}
              fullWidth
              id='drag-parts-library'
              simpleLabel
              labelText='Upload file'
            />
          )}
        </FlexRow>
        {!isMobile && (
          <UploadFilesField
            technology={TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE} // to allow all file types
            handleFiles={handleFiles}
            isAdditionalInfoShown
            fullWidth
            id='drag-parts-library'
          />
        )}
        <UploadPartsStatus
          saveButtonEnable={saveButtonEnable}
          numSuccessUploadedFiles={numSuccessUploadedFiles}
          numTotalFiles={numTotalFiles}
          data={cadPartListState.data}
          removeCadPart={removeCadPart}
        />
        <FilterProject
          style={{ margin: '1rem 0 2rem' }}
          onChange={handleOnChange}
        />
        <ProjectsView
          data={dataShow}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      </div>
      {showPartsLibraryFirstTimePopup?.open && (
        <PartsLibraryFirstTimePopup
          open={showPartsLibraryFirstTimePopup.open}
          withApi={showPartsLibraryFirstTimePopup.withApi}
          setOpen={(value) => updateShowPartsLibraryFirstTimePopup({ open: value })}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(PartsLibrary));
