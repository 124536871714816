import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const updateSkipNewSignupProcesses = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/email-tracking/new-signup`;
  return fetch(requestUrl, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
