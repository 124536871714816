/* eslint-disable react/prop-types */
// Import settings
import React from 'react';

// Import color palette
import { colors } from '../../palette';

// Import material UI components
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

// Style components
const useStyles = makeStyles(() => ({
  submitBtn: {
    textTransform: 'uppercase',
    background: colors.solidBlue,
    borderRadius: '5px',
    fontSize: '11pt',
    letterSpacing: '0.15em',
    color: colors.fontWhite,
    padding: '9px 60px',
    fontWeight: '300',
  },
  smallSubmitBtn: {
    textTransform: 'uppercase',
    background: colors.solidBlue,
    borderRadius: '5px',
    fontSize: '9pt',
    letterSpacing: '0.15em',
    color: colors.fontWhite,
    padding: '10px 15px',
    textAlign: 'center',
    fontWeight: '300',
  },
}));

function SolidBlueButton(props) {
  const classes = useStyles();
  const { btnContent, onBtnClick, component, to, size, disabled } = props;
  if (size === 'small') {
    return (
      <Button
        data-cy={`${btnContent.toLowerCase()}-button`}
        className={classes.smallSubmitBtn}
        onClick={onBtnClick}
        variant='contained'
        component={component ? component : 'span'}
        to={to ? to : null}
        disabled={disabled}
      >
        {btnContent}
      </Button>
    );
  }
  return (
    <Button
      data-cy={`${btnContent.toLowerCase()}-button`}
      className={classes.submitBtn}
      onClick={onBtnClick}
      variant='contained'
      component={component ? component : 'span'}
      to={to ? to : null}
      disabled={disabled}
    >
      {btnContent}
    </Button>
  );
}

export default SolidBlueButton;
