import { OPEN_POPUP, FILE_RENDER, URL_FILE_RENDER } from '../actions';

const defaultState = {
  isOpen: false,
  file: null,
  urlFile: null,
  loadFirstTime: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_POPUP:
      let newState = {
        ...state,
        isOpen: action.open,
      };
      // load first time when the popup is opened for the first time
      if (!state.loadFirstTime && action.open) {
        newState.loadFirstTime = true
      }
      return newState
    case FILE_RENDER:
      return {
        ...state,
        file: action.file,
      };
    case URL_FILE_RENDER:
      return {
        ...state,
        urlFile: action.urlFile,
      };
    default:
      return state;
  }
};
