import { useSelector } from "react-redux";
import { getCustomerSettingsSelector } from "../selectors/customerSettingsSelector";
import { isEmptyValue } from "../utils/commonUtils";
import { isFeatureManageProjectsRevamp } from "../utils/featureFlagUtils";

const useFeatureFlagHook = () => {
  const customerSettings = useSelector(getCustomerSettingsSelector);

  const isManageProjectsRevampEnabled = (
    isEmptyValue(customerSettings.manageProjectsRevampFeature) && isFeatureManageProjectsRevamp()
  ) || Boolean(customerSettings.manageProjectsRevampFeature) === true;

  return {
    isManageProjectsRevampEnabled,
  }
};

export default useFeatureFlagHook;
