import React from 'react';
import {
  IconButton,
  Tooltip,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
  handleCopyText,
  handleOpenTrackingLink,
  isTrackingNumberValid,
} from '../../utils/shipmentUtils';

import { SHIPMENT_TRACKING_LINKS } from '../../constants/orderReadyConstants';

const ShipmentTrackingIcon = ({
  shipmentInfo,
  iconButtonStyle = { padding: '0', margin: '0' },
  iconStyle = {},
}) => {
  const { shipmentProvider, trackingNumber: trackingNumberSupplier, shipmentTrackingNumber } = shipmentInfo;
  const trackingNumber = trackingNumberSupplier || shipmentTrackingNumber;
  const hasTrackingLink = SHIPMENT_TRACKING_LINKS[shipmentProvider];

  if (!isTrackingNumberValid(trackingNumber)) {
    return null;
  }

  return hasTrackingLink ? (
    <Tooltip title="Track shipment">
      <IconButton
        onClick={(e) => handleOpenTrackingLink(e, shipmentProvider, trackingNumber)}
        style={iconButtonStyle}
      >
        <OpenInNewIcon style={iconStyle} />
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip title="Copy shipment tracking number">
      <IconButton
        onClick={(e) => handleCopyText(e, trackingNumber)}
        style={iconButtonStyle}
      >
        <FileCopyIcon style={iconStyle} />
      </IconButton>
    </Tooltip>
  );
};

export default ShipmentTrackingIcon;
