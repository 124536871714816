import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { MenuItem, Select, FormControl } from '@material-ui/core';

import { colors } from '../../palette';
import clsx from 'clsx';

import { renderSkeletonOverlay } from '../util/skeleton';

// Import constants
import {
  itemColorPalette,
} from "../../constants/itemColorPalette";
import { get, isEmpty } from "lodash";

const useStyles = makeStyles(() => ({
  selectDropdown: {
    borderRadius: 10,
    backgroundColor: colors.fontWhite,
    '& .MuiSelect-select': {
      color: colors.fontGrey,
      fontWeight: 600,
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
    '& .MuiInputBase-root': {
      borderRadius: 10,
    }
  },
  menuItemRoot: {
    borderRadius: '0.75rem',
    whiteSpace: 'break-spaces',
    margin: '0.5rem 0',
    padding: '0.5rem 1rem',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&.Mui-selected': {
      backgroundColor: colors.fontWhite,
    },
  },
  paper: {
    borderRadius: '0.8rem',
    padding: '0.5rem',
  },
  paperWithScrollbar: {
    borderRadius: '0.8rem',
    padding: '0.5rem',
    maxHeight: '400px', // Define the maximum height here
    overflowY: 'auto', // Add overflow-y property to enable vertical scrollbar
    border: '4px white solid',
    '&::-webkit-scrollbar': {
      width: '8px', // Width of the scrollbar
      border: '1px white solid',
    },
    '&::-webkit-scrollbar-track': {
      border: '2px solid #f1f1f1', // Border around the thumb
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D9D9D9', // Color of the thumb
      borderRadius: '6px', // Rounded corners
    },
  },
  colorButton: {
    width: "100%",
    justifyContent: "left",
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: "border-box",
    borderRadius: "5px",
    color: colors.fontGrey,
    marginBottom: "13px",
    textTransform: "none",
    fontSize: "16px"
  },
}));

const PREDEFINED_COLORS_OPTION = 'PREDEFINED_COLORS';
const CUSTOM_COLOR_OPTION = 'CUSTOM_COLOR';

/**
 * Customised Dropdown component
 * 
 * @param {object} props - The properties of the Dropdown component.
 * @param {String} props.id - A unique identifier for the dropdown.
 * @param {String} props.value - The value of the dropdown.
 * @param {Boolean} props.fullWidth - If true, the dropdown will take up the full width of the parent div.
 * @param {Function} props.handleChange - Callback function to be executed when the dropdown value is changed.
 * @param {Array.<{key: String, display: String=}>} props.items - The items to be displayed in the dropdown.
 * 
 * @returns {React.ReactNode} The rendered Dropdown component.
 */
function ColorFtrDropdown(props) {
  const classes = useStyles();

  const [optionMode, setOptionMode] = useState(PREDEFINED_COLORS_OPTION);
  const [colorPaletteMapping, setColorPaletteMapping] = useState({});
  const [selectedColor, setSelectedColor] = useState('');
  const [hasCustomColor, setHasCustomColor] = useState(false);
  const [customColor, setCustomColor] = useState('');

  const {
    id,
    value,
    fullWidth = false,
    handleChange,
    colorPalette = [],
    loading = false,
  } = props;

  const menuProps = {
    classes: {
      paper: classes.paperWithScrollbar
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    getContentAnchorEl: null,
  };

  const renderColorSquare = (colorHex) => {
    const squareStyle = {
      width: "18px", // Adjust width and height as needed
      height: "18px",
      borderRadius: "4px",
      backgroundColor: `${colorHex}`, // Set background color dynamically
      border: `1px solid #DFDEDE`, // Add border if needed
      display: "inline-block", // Ensure inline block display
      marginLeft: "10px", // Adjust spacing between key and square
    };

    return <div style={squareStyle}></div>;
  };

  const renderRainbowSquare = () => {
    const rainbowColors = [
      '#ff0000', // Red
      '#ff7f00', // Orange
      '#ffff00', // Yellow
      '#00ff00', // Green
      '#0000ff', // Blue
      '#4b0082', // Indigo
      '#9400d3'  // Violet
    ];

    const squareStyle = {
      width: '18px', // Adjust width and height as needed
      height: '18px',
      borderRadius: '4px',
      background: `linear-gradient(to bottom right, ${rainbowColors.join(', ')})`, // Diagonal gradient
      display: 'inline-block', // Ensure inline block display
      marginLeft: '10px', // Adjust spacing between key and square
    };

    return <div style={squareStyle}></div>;
  };

  useEffect(() => {
    const _colorPaletteMapping = isEmpty(colorPalette) ? itemColorPalette : { ...colorPalette };
    setHasCustomColor(!!get(_colorPaletteMapping, 'Custom Color'));
    delete _colorPaletteMapping['Custom Color'];
    setColorPaletteMapping(_colorPaletteMapping);
    if (get(_colorPaletteMapping, value)) {
      setOptionMode(PREDEFINED_COLORS_OPTION);
      setSelectedColor(value);
    } else {
      setOptionMode(CUSTOM_COLOR_OPTION);
      setCustomColor(value);
    }
  }, [colorPalette, value]);

  return (
    <FormControl style={{ width: fullWidth && '100%' }}>
      {loading && renderSkeletonOverlay()}
      <Select
        className={clsx(classes.selectDropdown, classes.disabled)}
        id={id}
        variant="outlined"
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        MenuProps={menuProps}
        margin="dense"
        fullWidth
        style={{ width: '100%' }} // Set the width to 100% to match the parent container
        {...props}
      >
        {Object.keys(colorPalette).map((key) => {
          return (
            <MenuItem
              key={key}
              classes={{
                root: classes.menuItemRoot,
              }}
              value={key}
            >
              <div
                key={key}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {key}
                {(key !== "Custom Color") && renderColorSquare(colorPalette[key])}
                {(key === "Custom Color") && renderRainbowSquare()}
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default ColorFtrDropdown;
