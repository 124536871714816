import { isEmptyValue } from "./commonUtils"

export const getFileToGenerate2DImage = (cadPart = []) => {
  if (isEmptyValue(cadPart)) {
    return null;
  }
  const cadFileArr = cadPart.filter(file => !file?.toLowerCase()?.endsWith(".pdf"));
  if (!isEmptyValue(cadFileArr)) {
    return cadFileArr[0];
  }
  return cadPart[0];
}
