import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import InfoIcon from '../icons/InfoIcon';

import OutlinedDiv from './OutlinedDiv';

import { isEmptyValue } from '../../utils/commonUtils';

import { SHIPPING_MODES } from '../../constants/checkoutConstants';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  selected: {
    fontWeight: 'bold',
    color: colors.blue050,
  },
  dateSelected: {
    color: 'green',
    fontStyle: 'italic',
  },
  feeSelected: {
    color: 'green',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
}));

function DeliveryOptionsPanel(props) {
  const classes = useStyles();

  const {
    shippingMode,
    shippingModeList = [],
    onChange,
    disabled = true,
    status = '',
  } = props;

  const [shippingModeOptions, setShippingModeOptions] = useState([]);

  useEffect(() => {
    if (isEmptyValue(shippingModeList)) {
      return;
    }
    const _shippingModeOptions = shippingModeList.filter(shippingMode => {
      return shippingMode.value === SHIPPING_MODES.SAME_DATE
        || isEmptyValue(shippingMode.fee)
        || shippingMode.fee.toLowerCase() !== 'free';
    });
    setShippingModeOptions(_shippingModeOptions);
  }, [shippingModeList]);

  // The handle case value is not available in the list and the list is not empty.
  useEffect(() => {
    if (shippingMode && !isEmptyValue(shippingModeList)) {
      const notFoundMode = !shippingModeList?.find(mode => mode.value === shippingMode)
      if (notFoundMode) {
        onChange(shippingModeList[0].value)
      }
    }
  }, [shippingModeList, shippingMode])

  const renderLabel = (mode) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            className={mode.value === shippingMode ? classes.selected : null}
            variant="body1"
          >
            {mode.label}
          </Typography>
          &nbsp;
          {mode.toolTipText && <InfoIcon toolTipText={mode.toolTipText} />}
        </div>
        <Typography variant="body2">by <span className={mode.value === shippingMode ? classes.dateSelected : null}>{mode.date}</span></Typography>
        <Typography
          className={mode.value === shippingMode ? classes.feeSelected : null}
          variant="caption"
        >
          {mode.fee}
        </Typography>
      </div>
    );
  }

  const renderOptionDetails = () => {
    return (
      <>
        <FormControl component="fieldset">
          <RadioGroup
            value={shippingMode}
            onChange={(evt) => onChange(evt.target.value)}
          >
            {shippingModeOptions.map((mode) => {
              return (
                <FormControlLabel
                  key={mode.value}
                  value={mode.value}
                  control={<Radio color='primary' />}
                  label={renderLabel(mode)}
                  disabled={disabled}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </>
    );
  }

  const renderOptionDetailsSkeleton = () => {
    return (
      <div>
        <Skeleton variant="rect" height={60}></Skeleton>
      </div>
    );
  }

  return (
    <OutlinedDiv label="Delivery Options">
      {status === 'success' && renderOptionDetails()}
      {status === 'loading' && renderOptionDetailsSkeleton()}
    </OutlinedDiv>
  );
}

export default DeliveryOptionsPanel;
