export const PASSWORD_RULES = {
  // name should correspond to name in password-validator
  uppercase: {
    min: 1,
    message: 'Uppercase letter',
  },
  lowercase: {
    min: 1,
    message: 'Lowercase letter',
  },
  digits: {
    min: 1,
    message: 'Number',
  },
  symbols: {
    min: 1,
    message: 'Special character',
  },
  min: {
    min: 8,
    message: '>7 characters',
  },
};
