import libphonenumber from 'google-libphonenumber';

import { isEmptyValue } from '../commonUtils';

import { COUNTRY_LIST } from '../../constants/countryConstants';

export const validateContact = (contact, country) => {
  try {
    if (isEmptyValue(contact) || isEmptyValue(country) || contact.length <= 1) {
      return false;
    }
    const region = COUNTRY_LIST[country]?.code;
    if (!region) {
      return false;
    }
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(contact, region);
    if (phoneUtil.isValidNumber(number)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};
