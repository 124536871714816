import { useEffect, useState } from "react";

import { getMaterialInfoWithCache } from "../apis/configurationApi";

import { isEmptyValue } from "../utils/commonUtils";

export const useMaterialInfo = () => {
  const [params, setParams] = useState();
  const [description, setDescription] = useState(null);
  const [datasheetUrl, setDatasheetUrl] = useState(null);

  useEffect(() => {
    if (isEmptyValue(params)) {
      return;
    }
    getMaterialInfoWithCache(params).then(response => {
      if (isEmptyValue(response)) {
        return;
      }
      setDescription(response.description);
      setDatasheetUrl(response.datasheetUrl);
    });
  }, [params]);

  return [
    {
      description,
      datasheetUrl,
    },
    {
      setParams,
    }
  ]
}

export const getMaterialDesc = () => {
  const [descParams, setDescParams] = useState();
  const [desc, setDesc] = useState(null);

  useEffect(() => {
    if (isEmptyValue(descParams)) {
      return;
    }
    getMaterialInfoWithCache(descParams).then(response => {
      if (isEmptyValue(response)) {
        return;
      }
      setDesc(response.description);
    });
  }, [descParams]);

  return [
    {
      desc,
    },
    {
      setDescParams,
    }
  ]
}
