import { useSelector } from 'react-redux';
import { isAdminOrHigherRole } from '../utils/roleUtils';
import { getUserRoleSelector } from '../selectors/userSelector';

const useIsAdminOrHigherUser = () => {
  const role = useSelector(getUserRoleSelector);
  return isAdminOrHigherRole(role);
};

export default useIsAdminOrHigherUser;
