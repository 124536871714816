import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Box, Paper, Grid, CircularProgress } from '@material-ui/core';

import { colors } from '../../../palette';
import { updatePartsLibrarySingleData } from '../../../actions';

import ItemCard from './ItemCard';
import ConfigurePartsPopup from './popups/ConfigurePartsLibraryPopup';
import { FtrButton } from '../../../components/ftr-components';
import { FlexRowEnd } from '../../../components/layouts/FlexLayouts';
import ProjectNameField from '../../../components/fields/ProjectNameField';

import { updateProjectItemsPartLibrary } from '../../../apis/partsLibraryApi';

import { notifySuccess } from '../../../services/notificationService';

import { sortedItemsLibrary } from '../../../utils/partsLibraryUtils';

import useDeviceCheck from '../../../hooks/useDeviceCheck';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.neutral010,
    borderRadius: 20,
    padding: 24,
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      padding: 12,
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemCardContainer: {
    marginTop: 2,
  },
}));

function ProjectCard(props) {
  const { project, id, isFetching } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const projectRef = useRef(null);
  const [{ isMobile }] = useDeviceCheck();

  const [configurePartsPopupOpen, setConfigurePartsPopupOpen] = useState(false);

  const userID = useSelector((state) => state.auth?.user?.userID);

  const inUseItems = project?.items?.filter(
    (item) => item.inUse && !item.isDeleted
  );
  const sortedItems = sortedItemsLibrary(inUseItems);

  const handleUpdateProjectName = (newProjectName) => {
    updateProjectItemsPartLibrary(project?.projectLibraryID, {
      projectName: newProjectName,
      userID,
    }).then(() => {
      queryClient.invalidateQueries(['partsLibraryProjects', userID]);
      notifySuccess('Project name updated successfully!');
    });
  };

  const renderConfigurePartButton = () => {
    return (
      <FtrButton
        variant='text'
        disabled={isFetching}
        color='blue'
        onClick={(evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          setConfigurePartsPopupOpen(true);
          dispatch(
            updatePartsLibrarySingleData(project?.projectLibraryID, project)
          );
        }}
      >
        {isFetching && <CircularProgress size={16} style={{ marginRight: 8 }}/>}
        Configure parts
      </FtrButton>
    )
  }

  return (
    <>
      <Paper
        className={classes.container}
        elevation={0}
        id={id}
        ref={projectRef}
      >
        <Box className={classes.header}>
          <ProjectNameField
            projectName={project.projectName}
            updateProjectName={handleUpdateProjectName}
            fontSize='1rem'
          />
          {!isMobile && renderConfigurePartButton()}
        </Box>
        <Grid className={classes.itemCardContainer} container spacing={3}>
          {sortedItems?.map((item, index) => (
            <Grid key={index} item md={6} sm={12} style={{ width: '100%'} }>
              <ItemCard item={item} project={project} setConfigurePartsPopupOpen={setConfigurePartsPopupOpen} isFetching={isFetching} />
            </Grid>
          ))}
        </Grid>
        {isMobile && (
          <FlexRowEnd style={{ marginTop: 5 }}>
            {renderConfigurePartButton()}
          </FlexRowEnd>
        )}
      </Paper>
      <ConfigurePartsPopup
        open={configurePartsPopupOpen}
        onClose={() => setConfigurePartsPopupOpen(false)}
      />
    </>
  );
}

export default ProjectCard;
