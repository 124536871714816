import React, { useReducer } from 'react';

import OrderProjectAgainPopupV2 from '../components/popups/OrderProjectAgainPopupV2';

import { useOrderItemsAgainHookV2 } from '../hooks/useOrderItemsAgainHookV2';

import { isEmptyValue } from '../utils/commonUtils';


// --------------------------------------------------------------------------------------------

function withOrderProjectsAgainPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      orderAgainItemList: [],
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    const [
      {
        handleOrderItemsAgain,
      },
    ] = useOrderItemsAgainHookV2();

    return (
      <>
        <WrappedComponent
          {...props}
          updateOrderProjectAgainPopupHOCState={updateLocalState}
        />
        {localState.open && (
          <OrderProjectAgainPopupV2
            open={localState.open}
            items={localState.orderAgainItemList}
            handleCancel={() => updateLocalState({ open: false })}
            handleOk={(selectedItemIDs) => {
              const selectedPartDetailsList = localState.orderAgainItemList.filter(item => selectedItemIDs.includes(item.itemID));
              handleOrderItemsAgain(selectedPartDetailsList);
            }}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withOrderProjectsAgainPopupHOC;
