import React, { Fragment } from "react"
import "./styles.css"
import CanvasScreen from "./CanvasScreen"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import Tooltip from "@material-ui/core/Tooltip"
import FlipCameraIosIcon from "@material-ui/icons/FlipCameraIos"
import FrontView from "./cube-view-icons/cube-front.svg"
import RightView from "./cube-view-icons/cube-right.svg"
import TopView from "./cube-view-icons/cube-top.svg"
import TransparentView from "./cube-view-icons/cube-outline.svg"

import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  buttonGroup: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

function Viewer3D({ stlFile }) {
  const classes = useStyles();

  const visRef = React.useRef()

  const handleResetCamera = () => {
    visRef.current.resetCamera()
  }

  const frontView = () => {
    visRef.current.frontView()
  }

  const topView = () => {
    visRef.current.topView()
  }

  const rightView = () => {
    visRef.current.rightView()
  }

  const setTransparent = () => {
    visRef.current.setTransparent()
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: '#d9dbe0',
      }}
    >
      <CanvasScreen ref={visRef} stlFile={stlFile} />
      <div
        className={classes.buttonGroup}
      >
        <ButtonGroup
          size="small"
          orientation="vertical"
          color="primary"
          aria-label="vertical outlined primary button group"
        >
          <Tooltip title="Reset Camera">
            <IconButton onClick={handleResetCamera}>
              <FlipCameraIosIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Front View">
            <IconButton onClick={frontView}>
              <Icon>
                <img src={FrontView} alt="Front View" height={23} width={23} />
              </Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Right View">
            <IconButton onClick={rightView}>
              <Icon>
                <img src={RightView} alt="Right View" height={23} width={23} />
              </Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Top View">
            <IconButton onClick={topView}>
              <Icon>
                <img src={TopView} alt="Top View" height={23} width={23} />
              </Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Transparent">
            <IconButton onClick={setTransparent}>
              <Icon>
                <img src={TransparentView} alt="Transparent View" height={23} width={23} />
              </Icon>
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default Viewer3D;
