import React, { useEffect, useState, useRef } from 'react';

import {
  ListSubheader,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import SearchInputDropdown from './SearchInputDropdown';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}));

function SuppliersByTechDropDown(props) {
  const classes = useStyles();
  const inputRef = useRef();

  const {
    label = 'Select',
    supplierList,
    handleChange,
    selected,
    searchable = false,
    id = 'select-supplier-dropdown',
  } = props;

  const [displayedItems, setDisplayedItems] = useState([]);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    const newDisplayedItems =
      searchable && !isEmpty(filterText)
        ? supplierList
            .map((category) => {
              const filteredItems = category.value.filter((item) =>
                item.text.toLowerCase().includes(filterText.toLowerCase())
              );
              return {
                ...category,
                value: filteredItems,
              };
            })
            .filter((category) => !isEmpty(category.value))
        : supplierList;
    setDisplayedItems(newDisplayedItems);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [supplierList, filterText]);

  const renderMenuItem = ({ key, text }) => {
    return (
      <MenuItem
        key={key}
        value={key}
        onClick={() => {
          handleChange(key);
        }}
      >
        {text}
      </MenuItem>
    );
  };

  return (
    <div className={classes.formControl}>
      <Typography>{label}</Typography>
      <Select
        labelId='assign-quote-to'
        id='assign-quote-to'
        variant='outlined'
        select
        value={selected}
        renderValue={(selectedKey) => {
          if (!selectedKey) {
            return null;
          }
          const allOptions = displayedItems.flatMap((obj) => obj.value);
          const selectedOption = allOptions.find(
            (option) => option.key === selectedKey
          );
          if (selectedOption) {
            return selectedOption.text;
          }
        }}
        margin='dense'
        fullWidth
        InputLabelProps={{
          shrink: !!selected,
        }}
      >
        {searchable && (
          <SearchInputDropdown
            id={id}
            key={id}
            inputRef={inputRef}
            setFilterText={setFilterText}
            filterText={filterText}
          />
        )}
        {displayedItems.map(({ key: catKey, name, value }) => {
          return (
            <div key={catKey}>
              <ListSubheader>{name}</ListSubheader>
              {value.map(({ key, text }) => renderMenuItem({ key, text }))}
            </div>
          );
        })}
      </Select>
    </div>
  );
}

export default SuppliersByTechDropDown;
