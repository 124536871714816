// Import settings
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { colors } from '../../palette';

// Import material UI components
import {
  // Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import {
  FtrButton,
} from '../../components/ftr-components';

import { makeStyles } from '@material-ui/core/styles/index';

import { completeAnswerQuestion } from '../../actions';

import QuestionAnswerDropdown from '../forms/QuestionAnswerDropdown';
import { HOW_YOU_HEAR_ANSWERS_MAPPING } from '../../constants/signupConstants';

import loudhailerIcon from '../../assets/icons/loudhailer.svg';

// Style the components
const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    display: 'contents',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    padding: '1rem 0',
  },
  text: {
    padding: '0 0 1.5rem 0',
    colors: colors.fontBlack,
  },
}));

function HearAboutUsPopup(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { open, name, email, delay, country, handleSignupComplete } = props;

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [questionAnswer, setQuestionAnswer] = useState({
    question: 'How did you hear about us?',
  });

  useEffect(() => {
    setIsButtonEnabled(questionAnswer.answer !== '');
  }, [questionAnswer]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setIsDialogOpen(true);
      }, delay);
      return;
    }
    setIsDialogOpen(false);
  }, [open]);

  const submitSignupDetails = async () => {
    const userData = {
      name,
      email,
      country,
      questionAnswer,
    };
    await handleSignupComplete(userData);
  }

  return (
    <Dialog
      width='xs'
      open={isDialogOpen}
      PaperProps={{className: classes.dialog}}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '3rem 0 1rem 0' }} src={loudhailerIcon} alt='icon' />
        <span style={{ padding: '1rem 0 2rem 0' }}>How did you hear about us?</span>
      </DialogTitle>
      <DialogContent style={{ fontSize: '14', flex: 'none', padding: !isMobile && '1rem 7rem' }}>
        <Typography className={classes.text}>
          Your answer helps us personalise your experience and improve our services.
        </Typography>
        <QuestionAnswerDropdown
          id='how-did-you-hear-about-us'
          answerOptions={HOW_YOU_HEAR_ANSWERS_MAPPING}
          updateAnswer={setQuestionAnswer}
        />
      </DialogContent>
      <div
        style={{
          justifyContent: 'center',
          padding: '2rem',
          width: '8rem',
        }}
      >
        <FtrButton
          color='blue'
          disabled={!isButtonEnabled}
          fullWidth
          onClick={submitSignupDetails}
        >
          Continue
        </FtrButton>
      </div>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    name: state.createUser.name ?? state.auth.user.name,
    email: state.createUser.email ?? state.auth.user.email,
    country: state.createUser.country ?? state.auth.user.country,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    handleSignupComplete: (userData) => dispatch(completeAnswerQuestion(userData)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(HearAboutUsPopup);
