import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Backdrop, CircularProgress, Typography } from '@material-ui/core';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.fontWhite,
  },
  backdropContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function PpePriceLoadingBackdrop(props) {
  const classes = useStyles();

  const { open } = props;

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <div className={classes.backdropContent}>
        <CircularProgress color='inherit' />
        <Typography
          style={{
            textAlign: 'center',
          }}
        >
          Please wait for a couple of seconds while we generate the best price
          for you. Do not refresh this page or close the browser.
        </Typography>
      </div>
    </Backdrop>
  );
}

export default PpePriceLoadingBackdrop;
