import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const getLatestDfmDefectsByItemID = async (itemID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/${itemID}/extract-dfm-defects`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

// Body can also contain sourceUrl, to specify which file to extract from.
export const extractDfmDefectByItemID = async (itemID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/items/${itemID}/extract-dfm-defects`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((json) => {
        throw new Error(json.error);
      });
    } else {
      const { data } = await response.json();
      return data;
    }
  });
};
