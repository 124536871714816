/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { ceil } from 'lodash';
import { Cookies } from 'react-cookie';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import {
  Button,
  IconButton,
  Link as LinkButton,
  Tooltip,
} from '@material-ui/core';

import { Lens as LensIcon, Replay as ReplayIcon } from '@material-ui/icons';

import { regeneratePpeForItem } from '../../apis/dbMigrationApi';

import CustomToolbar from '../../components/grid-data/CustomToolbar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import TDELogInfoPopup from '../../components/popups/RequestResponseInfoPopup';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import DataGridJsonDisplayCell from '../../components/tables/cells/DataGridJsonDisplayCell';
import JsonInfoDisplayPopup from '../../components/popups/JsonInfoDisplayPopup';
import RequestResponseInfoPopup from '../../components/popups/RequestResponseInfoPopup';

import { colors } from '../../palette';
import { generateCurrentCustomDateTimeString } from '../../util';
import {
  adminItemsTDELogsExportCsv,
  adminSearchItemsTDELogs,
  extractTechnicalDrawing,
  extractTechnicalDrawingByIdAndPdfUrl,
} from '../../apis/technicalDrawingExtractionApi';
import { notifyError } from '../../services/notificationService';
import { TDE_LOCATIONS } from '../../constants/technicalDrawingExtractorConstants';
import { USER_AGENT_INFO_KEY } from '../../constants';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

function TDELogs() {
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );
  const [TDELogInfoPopup, updateTDELogInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );
  const [singleInfoPopupState, updateSingleInfoPopupState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      title: '',
      selectedData: {},
      jsonData: {},
    }
  );

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    adminSearchItemsTDELogs(tableQueryParams)
      .then((data) => {
        setTableData(data.rows);
        updateTableQueryParams({
          totalCount: data.totalCount,
          loading: false,
        });
        updateTableQueryParams({ loading: false });
      })
      .catch(() => {
        updateTableQueryParams({ loading: false });
      });
  };

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    if (!tableQueryParams.search) {
      getTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  const handleRegenerateTDEExtractor = (rowData) => {
    if (!rowData?.request) {
      return notifyError(`TDE ID ${rowData.id}, the request is empty!`);
    }
    const toastId = toast(
      `Regenerating TDE Extractor for itemID=${rowData.itemID}`,
      {
        type: toast.TYPE.INFO,
        autoClose: false,
      }
    );
    const userAgentInfo = cookies.get(USER_AGENT_INFO_KEY);
    const body = {
      pdfUrl: rowData.request?.pdf_url,
      isKeyAccount: rowData.request?.key_account,
      itemID: rowData.itemID,
      userID: rowData.userID,
      tdeLocation: TDE_LOCATIONS.ADMIN_PLATFORM_TDE_LOGS,
      userAgentInfo,
      rom_switch: false
    };
    extractTechnicalDrawing(body)
      .then(getTableData)
      .then(() => {
        toast.update(toastId, {
          render: `Regenerating TDE Extractor for itemID=${rowData.itemID} successfully.`,
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      })
      .catch(() => {
        toast.update(toastId, {
          render: `Regenerating TDE Extractor for itemID=${rowData.itemID} failed.`,
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      });
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleDownloadCsv = async () => {
    setIsGeneratingCsv(true);
    try {
      // Call the API to generate the CSV and get the download URL
      const response = await adminItemsTDELogsExportCsv(tableQueryParams);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      // Download the CSV file using the download URL
      const link = document.createElement('a');
      link.href = downloadUrl;
      const fileName = `TDE Logs ${generateCurrentCustomDateTimeString()}.csv`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } finally {
      setIsGeneratingCsv(false);
    }
  };

  const getCustomerToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
        }}
      >
        <span style={{ flexGrow: '1 auto' }}></span>
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              disabled={isGeneratingCsv === true}
              loading={isGeneratingCsv === true}
            />,
          ]}
        />
      </div>
    );
  };

  const columns = [
    {
      headerName: 'Part ID',
      field: 'itemID',
      renderCell: ({ row: rowData }) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
              <Button
                className={classes.linkButton}
                href={`/item/edit/${rowData.itemID}`}
              >
                {rowData.itemID}
              </Button>
            </Tooltip>
            {rowData.itemID && (
              <Tooltip title='Regenerate TDE Extractor'>
                <IconButton
                  className={classes.iconButton}
                  aria-label='regenerate'
                  size='small'
                  onClick={() => handleRegenerateTDEExtractor(rowData)}
                >
                  <ReplayIcon color='primary' style={{ fontSize: '1.2rem' }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
      width: 120,
    },
    {
      headerName: 'User ID',
      field: 'userID',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.userID} />
      ),
    },
    {
      headerName: 'User Email',
      field: 'userEmail',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.userEmail} />
      ),
      width: 200,
    },
    {
      headerName: 'Request',
      field: 'request',
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.request, null, 2) ?? '';
        const rows = content.split('\n');
        const length = rows.length;
        content = rows.slice(0, 2).join('\n');
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {content}
            {length > 1 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateTDELogInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.request) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'Response',
      field: 'response',
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.response, null, 2) ?? '';
        const rows = content.split('\n');
        const length = rows.length;
        content = rows.slice(0, 2).join('\n');
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {content}
            {length > 2 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateTDELogInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.response) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'TDE Location',
      field: 'tdeLocation',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.tdeLocation} />
      ),
      width: 200,
    },
    {
      headerName: 'User Agent Info',
      field: 'userAgentInfo',
      renderCell: ({ row: rowData }) => {
        const userAgentInfo = rowData.userAgentInfo;
        return (
          <DataGridJsonDisplayCell
            jsonObj={userAgentInfo}
            seeMoreButtonClick={() => {
              updateSingleInfoPopupState({
                open: true,
                title: 'User Agent Info',
                selectedData: rowData,
                jsonData: userAgentInfo,
              });
            }}
          />
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.userAgentInfo) ?? '';
      },
      width: 200,
    },
    {
      headerName: 'Log ID',
      field: 'id',
    },
    {
      headerName: 'Caller ID',
      field: 'callerID',
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      renderCell: ({ row: rowData }) => {
        return (
          <div>{format(new Date(rowData.createdAt), 'yyyy-MM-dd HH:mm')}</div>
        );
      },
      width: 200,
    },
  ];

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        paginationMode='server'
        rows={tableData}
        columns={columns.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={100}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        pageSize={tableQueryParams.pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        page={tableQueryParams.page}
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        loading={tableQueryParams.loading}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
      />
      {TDELogInfoPopup.open && (
        <RequestResponseInfoPopup
          open={TDELogInfoPopup.open}
          handleClose={() => updateTDELogInfoPopup({ open: false })}
          request={JSON.stringify(TDELogInfoPopup.selectedData?.request, null, 2)}
          response={JSON.stringify(TDELogInfoPopup.selectedData?.response, null, 2)}
          title={`TDE ID ${TDELogInfoPopup.selectedData?.id}${
            TDELogInfoPopup.selectedData?.itemID
              ? ` - ITEM ID ${TDELogInfoPopup.selectedData?.itemID}`
              : ''
          }`}
        />
      )}
      {singleInfoPopupState.open && (
        <JsonInfoDisplayPopup
          open={singleInfoPopupState.openUserAgentInfo}
          handleClose={() => updateSingleInfoPopupState({ open: false })}
          rowData={singleInfoPopupState.selectedData}
          title={singleInfoPopupState.title}
          jsonData={singleInfoPopupState.jsonData}
        />
      )}
    </div>
  );
}

export default TDELogs;
