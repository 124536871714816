export const RESET_2D_IMAGES_MAPPING = 'RESET_2D_IMAGES_MAPPING';
export const ADD_2D_IMAGE = 'ADD_2D_IMAGE';
export const UPDATE_2D_IMAGE = 'UPDATE_2D_IMAGE';

export const reset2DImagesMapping = () => {
  return {
    type: RESET_2D_IMAGES_MAPPING,
  };
};

export const add2DImage = ({ key, value }) => {
  return {
    type: ADD_2D_IMAGE,
    payload: {
      key,
      value,
    },
  };
};

export const update2DImage = ({ key, value, errorMessage }) => {
  return {
    type: UPDATE_2D_IMAGE,
    payload: {
      key,
      value,
      errorMessage,
    },
  };
};
