import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';

function SearchInputDropdown(props) {
  const { setFilterText, filterText, id, inputRef } = props;
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        zIndex: 9999,
        backgroundColor: 'white',
      }}
      key={`search-input-${id}`}
    >
      <IconButton
        aria-label='delete'
        size='small'
        onClick={(event) => {
          event.stopPropagation();
          setFilterText('');
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <CloseIcon style={{ fontSize: '15pt' }} />
      </IconButton>
      <TextField
        id={`search-input-${id}`}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onFocus={(event) => {
          event.stopPropagation();
        }}
        onSelect={(event) => {
          event.stopPropagation();
        }}
        onKeyUp={(event) => {
          event.stopPropagation();
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
        }}
        inputRef={inputRef}
        value={filterText}
        onChange={(event) => {
          setFilterText(event.target.value);
          event.stopPropagation();
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
        autoFocus
      />
    </div>
  );
}

export default SearchInputDropdown;
