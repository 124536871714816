import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Box, Button, Container,   InputAdornment, InputBase, IconButton, InputLabel, Paper, Tooltip } from '@material-ui/core';

import {
  Visibility,
  VisibilityOff, 
} from '@material-ui/icons';

import { colors } from '../palette';
import { useState } from 'react';
import { validatePassword } from '../utils/passwordUtils';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { notifyError, notifySuccess } from '../services/notificationService';
import { changeUserPassword } from '../apis/userApi';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WhiteButton from '../components/buttons/WhiteButton';

const useStyles = makeStyles(() => ({
  paper: {
    width: '65%',
    margin: "60px 0",
    display: "flex",
    padding: "35px 45px 30px",
    flexDirection: "column",
    alignItems: "center",
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`
  },
  title: {
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 25,
    fontSize: "15pt",
    color: colors.factoremBlue,
    letterSpacing: "0.6px"
  },
  inputField: {
    padding: '5px 14px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: "border-box",
    borderRadius: 5,
    color: `${colors.fontGrey}`,
    marginBottom: "13px"
  },
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
    width: '100%'
  },
  buttonConfirm: {
    marginTop: 18,
    background: colors.buttonColorBlueGradient
  },
}));

function ChangePassword(props) {
  const classes = useStyles();
  const history = useHistory();

  const { userID } = props;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidated, setPasswordValidated] = useState(true);
  const [passwordSame, setPasswordSame] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  useEffect(() => {
    setPasswordValidated(validatePassword(newPassword));
    setPasswordSame(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    setButtonDisabled(isEmpty(oldPassword) || !(passwordValidated && passwordSame));
  }, [oldPassword, passwordValidated, passwordSame]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleSubmit = () => {
    if (buttonDisabled) {
      return;
    }
    const body = {
      userID,
      oldPassword,
      newPassword,
    }
    changeUserPassword(body)
      .then(() => {
        notifySuccess(`Password changed successfully`);
        history.push('/profile');
      })
      .catch(() => notifyError(`Password change failed`));
  }

  const renderResetPasswordForm = () => {
    return (
      <div>
        <InputLabel className={classes.inputLabel}>Old Password</InputLabel>
        <InputBase
          id="oldPassword"
          type={showPassword ? 'text' : 'password'}
          name="oldPassword"
          className={classes.inputField}
          onChange={({ target }) => setOldPassword(target.value)}
          style={{ width: '100%' }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <InputLabel className={classes.inputLabel}>New Password</InputLabel>
        <Tooltip
          disableHoverListener
          title="Password must have at least 8 characters with at least one capital letter, one number, and one special character !@#$%^&*"
          arrow
          placement="right"
          open={!passwordValidated}
        >
          <InputBase
            style={{ width: '100%' }}
            id="newPassword"
            type={showNewPassword ? 'text' : 'password'}
            name="newPassword"
            className={classes.inputField}
            onChange={({ target }) => setNewPassword(target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Tooltip>
        <InputLabel className={classes.inputLabel}>Confirm your password</InputLabel>
        <Tooltip
          disableHoverListener
          title="Please enter your new password again and ensure that it matches the above"
          arrow
          placement="right"
          open={!passwordSame}
        >
          <InputBase
            id="confirmPassword"
            type={showNewPassword ? 'text' : 'password'}
            name="confirmPassword"
            className={classes.inputField}
            onChange={({ target }) => setConfirmPassword(target.value)}
            style={{ width: '100%' }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Tooltip>
        <Button
          type="button"
          disabled={buttonDisabled}
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.buttonConfirm}
          onClick={handleSubmit}
        >
          Change Password
        </Button>
        <Box style={{ marginTop: '0.5rem' }}></Box>
        <WhiteButton
          onBtnClick={() => history.goBack()}
          btnContent='Go Back'
          size='small'
          fullWidth
        />
      </div>
    );
  }

  return (
    <Container component="main" maxWidth="sm" style={{ display: "flex", justifyContent: "center" }}>
      <Paper className={classes.paper} elevation={1}>
        <div className={classes.title}>
          Change Password
        </div>
        <div style={{ marginTop: "15px" }}>
          {renderResetPasswordForm()}
        </div>
      </Paper>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ChangePassword);
