import React, { useState } from 'react';
import {
  Container,
  Divider,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { get } from 'lodash';

import { colors } from '../../palette';
import BlueButton from '../buttons/BlueButton';
import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { downloadS3File } from '../../utils/fileUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  selectInput: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      maxWidth: '350px',
    },
  },
}));

/**
 *
 * @param {Object} props
 * @param {() => void} props.setCurrentVersion
 */
function UploadPartnerDataHistory(props) {
  const { data } = props;

  const classes = useStyles();

  const [selectedUploadHistory, setSelectedUploadHistory] = useState(null);

  const handleDownloadUploadHistory = () => {
    if (!selectedUploadHistory) return;
    downloadS3File(selectedUploadHistory.fileURL);
  };

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant='h6'>Download Partner Data History</Typography>
        <Divider />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '25px',
        }}
      >
        <TextField
          label='Select Upload History'
          select
          variant='outlined'
          style={{ textAlign: 'left' }}
          value={get(selectedUploadHistory, 'fileURL')}
          margin='dense'
          fullWidth
          className={classes.selectInput}
        >
          {!!data.length &&
            data.map((option) => (
              <MenuItem
                key={option.id}
                value={option.fileURL}
                onClick={() => setSelectedUploadHistory(option)}
              >
                {option.id} - {option.fileName} -{' '}
                {dateTzSingapore(option.createdAt)}
              </MenuItem>
            ))}
        </TextField>
        <BlueButton
          size='small'
          btnContent='Download'
          onBtnClick={handleDownloadUploadHistory}
          disabled={!selectedUploadHistory}
        />
      </div>
    </Container>
  );
}

export default UploadPartnerDataHistory;
