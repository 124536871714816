import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import CarouselImage from './CarouselImage';
import ItalicNote from '../texts/italicNote';

const URLS = [
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
];

const DEFAULT_DISPLAY_AMOUNT = 3;

function HorizontalCarousel({ urls = URLS, displayAmount = DEFAULT_DISPLAY_AMOUNT }) {

  if (isEmpty(urls)) {
    return null;
  }

  const showButton = urls.length > displayAmount;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselUrls, setCarouselUrls] = useState(urls.slice(0, displayAmount));
  const [leftArrowEnable, setLeftArrowEnable] = useState(true);
  const [rightArrowEnable, setRightArrowEnable] = useState(true);
  const [description, setDescription] = useState('');

  useEffect(() => {
    setCarouselUrls(urls.slice(currentIndex, currentIndex + displayAmount));
    setLeftArrowEnable(currentIndex > 0);
    setRightArrowEnable(currentIndex + displayAmount < urls.length);
    const lastIndex = currentIndex + displayAmount > urls.length ? urls.length : currentIndex + displayAmount;
    setDescription(`Display #${currentIndex + 1}-${lastIndex} of ${urls.length}`);
  }, [currentIndex, displayAmount]);

  const handleLeftArrowClick = () => {
    const newCurrentIndex = currentIndex - displayAmount <= 0 ? 0 : currentIndex - displayAmount;
    setCurrentIndex(newCurrentIndex);
  }

  const handleRightArrowClick = () => {
    const newCurrentIndex = currentIndex + displayAmount <= urls.length ? currentIndex + displayAmount : currentIndex;
    setCurrentIndex(newCurrentIndex);
  }

  const renderLeftArrowButton = () => {
    return (
      <IconButton
        style={{
          padding: 4,
        }}
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          handleLeftArrowClick();
        }}
        disabled={!leftArrowEnable}
      >
        <ArrowLeftIcon />
      </IconButton>
    );
  }

  const renderRightArrowButton = () => {
    return (
      <IconButton
        style={{
          padding: 4,
        }}
        color="primary"
        onClick={(event) => {
          event.stopPropagation();
          handleRightArrowClick();
        }}
        disabled={!rightArrowEnable}
      >
        <ArrowRightIcon />
      </IconButton>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showButton === true && renderLeftArrowButton()}
        {carouselUrls.map(url => <CarouselImage key={url} url={url} />)}
        {showButton === true && renderRightArrowButton()}
      </div>
      {showButton === true && <ItalicNote text={description} />}
    </div>
  );
}

export default HorizontalCarousel;
