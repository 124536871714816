import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, TextField, makeStyles } from '@material-ui/core';
import DisplayAddress from '../DisplayAddress';

// Style components
const useStyles = makeStyles(() => ({
  field: {
    '& .MuiInputBase-root': {
      padding: '0.5rem 0.8rem',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '0',
    },
  },
}));

/**
 *
 * @param {object} props
 * @param {() => void} props.handler
 * @param {string} props.id
 * @param {any} props.value
 * @param {{ deliveryInfoID, address }[]} props.data
 * @param {boolean} props.maxWidth
 */
const SelectAddresses = (props) => {
  const {
    handler,
    id = 'shipping-address',
    value,
    data,
    maxWidth = true,
    field = 'Address',
    required = false,
  } = props;
  const classes = useStyles();

  if (!data) {
    return null; // the data cannot be empty
  }

  return (
    <TextField
      select
      id={id}
      key={id}
      value={value}
      fullWidth
      variant='outlined'
      multiline
      className={classes.field}
      error={required && !value}
      helperText={required && !value && `${field} is required`}
    >
      {data?.map((address) => (
        <MenuItem
          style={maxWidth ? { maxWidth: '500px' } : null}
          key={address?.deliveryInfoID}
          value={address?.deliveryInfoID}
          onClick={() => handler(address)}
        >
          <DisplayAddress data={address} textWrap userID={address?.userID} />
        </MenuItem>
      ))}
    </TextField>
  );
};

function mapStateToProps(state) {
  return {
    name: state.auth.user.name,
    contact: state.auth.user.contact,
  };
}

const withConnect = connect(mapStateToProps);

export default withConnect(SelectAddresses);
