import React from 'react';

import BlueButton from '../buttons/BlueButton';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';
import Title from '../Title';
import { watchingStatusInfo } from '../../constants/watchingJob';
import { ORDER_STATUS } from '../../constants';
import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '37.5rem',
    minWidth: '12.5rem',
    maxWidth: '50rem',
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function WatchingJobPopup(props) {
  const classes = useStyles();

  const { open, onClose, data, title } = props;

  if (!open) return null;

  const renderBodyPopupWatching = () => {
    return (
      <ul style={{ textAlign: 'start' }}>
        {data.map((watchingPart) => {
          return (
            <li key={watchingPart.userID}>
              {watchingPart.name} ({watchingPart.userID}) -{' '}
              <span
                style={{
                  color:
                    watchingStatusInfo.color[watchingPart.watchingStatus] ||
                    'black',
                }}
              >
                {watchingStatusInfo.text[watchingPart.watchingStatus] ||
                  watchingPart.watchingStatus}{' '}
                -{' '}
              </span>
              {watchingPart.quoteStatus ? (
                <React.Fragment>
                  <span>Quoted: </span>
                  <span
                    style={{
                      textTransform: 'capitalize',
                      color:
                        watchingPart.quoteStatus === ORDER_STATUS.ACCEPTED
                          ? colors.successGreen
                          : 'black',
                    }}
                  >
                    {watchingPart.quoteStatus}
                  </span>
                </React.Fragment>
              ) : (
                <span>No Quote</span>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='warning-dialog-title'>
        <Title contentTitle={title} size='small' />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          {renderBodyPopupWatching()}
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <BlueButton onBtnClick={onClose} btnContent='OK' />
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default WatchingJobPopup;
