import React from 'react';

import {
  Tooltip,
  Typography,
} from '@material-ui/core';

import FtrInfoIconTooltip from '../../ftr-components/FtrInfoIconTooltip';
import { FlexColumn, FlexRow } from '../../layouts/FlexLayouts';
import { FtrS1, FtrS3 } from '../../ftr-components';

import { handleOpenTrackingLink } from '../../../utils/shipmentUtils';

import { ITEM_STAGE_TYPE } from '../../../constants';

import { colors } from '../../../palette';


// --------------------------------------------------------------------------------------------

function DeliveryDateCellV2(props) {
  const { rowData } = props;

  let estimated = true;
  let reworked = false;
  let revised = false;
  let deliveryDate;
  if (rowData.requiredRework === 1 && rowData.reworkDeliveryDate !== null) { //to account for legacy data
    deliveryDate = rowData.reworkDeliveryDate
    reworked = true
  }
  else if (rowData.revisedDeliveryDate && rowData.status !== ITEM_STAGE_TYPE.DELIVERED) {
    deliveryDate = rowData.revisedDeliveryDate
    revised = true
  }
  else if (rowData.actualDeliveryDate && rowData.status === ITEM_STAGE_TYPE.DELIVERED) {
    deliveryDate = rowData.actualDeliveryDate;
    estimated = false;
  } else if (rowData.deliveryDate) {
    deliveryDate = rowData.deliveryDate;
  }
  if (!deliveryDate) {
    return '';
  }
  const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
  const deliveryDateStr = new Date(deliveryDate).toLocaleDateString('en-GB', dateFormat);

  return (
    <FlexColumn>
      <Tooltip
        title={
          reworked
            ? 'Reworked Delivery Date'
            : estimated
              ? 'Estimated Delivery Date'
              : 'Actual Delivery Date'
        }
        arrow
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 'normal',
            }}
          >
            <FtrS3>
              {deliveryDateStr}
            </FtrS3>
          </div>
          {revised && (
            <Typography
              variant="body2"
            >
              (Revised)
            </Typography>
          )}
        </div>
      </Tooltip>
      {![null, undefined, 'N.A.', ''].includes(rowData?.shipmentProvider) && (
        <FlexRow>
          <FtrS1 style={{ color: colors.neutral060 }}>
            Tracking No.{' '}
            <span
              style={{ color: colors.blue040, cursor: 'pointer' }}
              onClick={(e) => handleOpenTrackingLink(
                e,
                rowData.shipmentProvider,
                rowData.shipmentTrackingNumber,
              )}
            >
              {rowData.shipmentTrackingNumber}
            </span>
          </FtrS1>
          <FtrInfoIconTooltip
            toolTipText={
              `Shipment Provider: ${rowData.shipmentProvider}`
            }
            theme='light'
            onClick={(e) => handleOpenTrackingLink(
              e,
              rowData.shipmentProvider,
              rowData.shipmentTrackingNumber,
            )}
          />
        </FlexRow>
      )}
    </FlexColumn>
  );
}

export default DeliveryDateCellV2;
