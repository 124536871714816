import React from 'react';

import { IconButton } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

import { FlexRow } from './layouts/FlexLayouts';
import FtrTooltip from './ftr-components/FtrTooltip';

import { colors } from '../palette';

function DatePickerWithIcon(props) {
  const {
    label = 'Default Date Picker',
    value = new Date(),
    onChange = () => {},
    endIcon = <DynamicFeedIcon style={{ color: colors.neutral060 }} />,
    onIconClick = () => {},
    tooltipIconText = 'Apply to All Dates',
    ...restProps
  } = props;
  return (
    <FlexRow style={{ gap: '0' }}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        {...restProps}
      />
      {endIcon && (
        <FtrTooltip description={tooltipIconText} arrow>
          <IconButton onClick={() => onIconClick(value)}>{endIcon}</IconButton>
        </FtrTooltip>
      )}
    </FlexRow>
  );
}

export default DatePickerWithIcon;
