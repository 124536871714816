import React, { useEffect, useReducer, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Title from '../Title';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';
import BlueButton from '../buttons/BlueButton';
import { capitalize, startCase } from 'lodash';
import { Link } from 'react-router-dom';

import { Image as ImageIcon } from '@material-ui/icons';

import { createCompany, updateCompany } from '../../apis/companiesApi';
import { notifyError, notifySuccess } from '../../services/notificationService';
import {
  CUSTOMER_CREDIT_TYPES,
  CUSTOMER_TYPES,
  INDUSTRIES,
} from '../../constants/customerConstants';
import AddressAutocomplete from '../AddressAutocomplete';
import {
  convertFormattedAddress,
  formattedAddress,
  isEmailDomainValid,
} from '../../utils/userUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  PROFILEPIC_DIRNAME,
  YES_NO_TEXT_MAPPING,
  YES_NO_VALUE_MAPPING,
} from '../../constants';
import FilesUploadButton from '../FilesUploadButton';
import {
  generatePresignedUrl,
  getCustomerQuotationTermsS3Key,
  uploadFileToS3,
  uploadPublicFileToS3,
} from '../../services/s3Service';
import { PPE_TYPES, TDE_TYPE } from '../../constants/userConstant';
import { FlexRow, FlexRowCenter } from '../layouts/FlexLayouts';
import AvatarWithButton from '../AvatarWithButton';
import {
  downloadS3File,
  extractFileNameWithoutTimestampFromUrl,
} from '../../utils/fileUtils';
import { FIVE_MINUTES_IN_SECOND } from '../../constants/dateTimeConstants';
import { openInNewTab } from '../../utils/navigationUtils';
import useDeviceCheck from '../../hooks/useDeviceCheck';
import { getLogoFromEmail } from '../../apis/clearbitApi';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 800,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  profilePicture: {
    cursor: 'pointer',
    width: 100,
    height: 100,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(3),
  },
}));

function CreateUpdateCompanyPopup(props) {
  const classes = useStyles();

  const {
    open = false,
    onClose,
    onSuccess = () => null,
    data,
    type = 'create',
  } = props;

  const initialCompany = {
    companyName: '',
    address: '',
    financeAddress: '',
    quotationTermsUrl: '',
    emailDomain: '',
    profilePic: '',
    financeEmail: '',
    companyDesc: '',
    paymentTerms: '',
    ppeType: PPE_TYPES.HAS_PPE,
    tdeType: TDE_TYPE.HAS_TDE,
    keyAccount: YES_NO_VALUE_MAPPING.No,
    industrySector: INDUSTRIES.AUTOMATION,
    creditType: CUSTOMER_CREDIT_TYPES.CREDIT,
    customerType: CUSTOMER_TYPES.STARTUP,
    postalCode: '',
    unitNo: '',
    country: '',
    financePostalCode: '',
    financeUnitNo: '',
    financeCountry: '',
  };

  const [companyState, updateCompanyState] = useReducer((prev, next) => {
    return { ...prev, ...next };
  }, initialCompany);
  const [error, setError] = useState({});
  const [{ isIPad, isMobile, isTablet }] = useDeviceCheck();

  useEffect(() => {
    if (type === 'update' && !isEmptyValue(data)) {
      updateCompanyState(data);
    } else {
      updateCompanyState(initialCompany);
    }
  }, [type, data]);

  const params = {
    companyName: companyState.companyName,
    address: formattedAddress({
      address: companyState.address,
      postalCode: companyState.postalCode,
      unitNo: companyState.unitNo,
      country: companyState.country,
    }),
    industrySector: companyState.industrySector,
    creditType: companyState.creditType,
    customerType: companyState.customerType,
    financeAddress: formattedAddress({
      address: companyState.financeAddress,
      postalCode: companyState.financePostalCode,
      unitNo: companyState.financeUnitNo,
      country: companyState.financeCountry,
    }),
    quotationTermsUrl: companyState.quotationTermsUrl,
    emailDomain: companyState.emailDomain,
    profilePic: companyState.profilePic,
    financeEmail: companyState.financeEmail,
    companyDesc: companyState.companyDesc,
    paymentTerms: companyState.paymentTerms,
    ppeType: companyState.ppeType,
    tdeType: companyState.tdeType,
    keyAccount: companyState.keyAccount,
  };

  const isErrorFields = () => {
    let isError = false;
    const listRequires = ['companyName'];
    for (const state in params) {
      if (!params[state] && listRequires.includes(state)) {
        isError = true;
        const message = `${startCase(state)} is required!`;
        setError((prev) => ({
          ...prev,
          [state]: message,
        }));
        notifyError(message);
      }
    }
    return isError;
  };

  const handleCreateCompany = () => {
    if (isErrorFields()) return;
    createCompany(params)
      .then(() => {
        notifySuccess('Created new company successfully');
        updateCompanyState(initialCompany);
        onSuccess();
        onClose();
      })
      .catch(() => {
        notifyError('Failed created new company');
      });
  };

  const handleUpdateCompany = () => {
    if (isErrorFields()) return;
    updateCompany(data.companyID, params)
      .then(() => {
        notifySuccess('Updated the company successfully');
        onSuccess();
        onClose();
      })
      .catch(() => {
        notifyError('Failed updated the company');
      });
  };

  const handleUploadCustomerQuotationFile = async (files) => {
    const file = files[0];
    const fileExtension = file.name.split('.').pop();
    const fileName = `Customer Quotation Terms - Company - ${companyState.companyName}${fileExtension}`;
    const data = await uploadFileToS3(
      file,
      getCustomerQuotationTermsS3Key(file),
      fileName
    );
    const s3ObjectUrl = data.Location;
    updateCompanyState({ quotationTermsUrl: s3ObjectUrl });
  };

  const handleImgChange = (e) => {
    const file = e.target.files[0];
    const s3Key = `${PROFILEPIC_DIRNAME}/${file.name}`;
    uploadPublicFileToS3(file, s3Key)
      .then((data) => {
        updateCompanyState({
          profilePic: data.Location.split(' ').join('%20'),
        });
      })
      .catch((err) => {
        notifyError(err?.message || 'Error uploading profile picture');
      });
  };

  const handleGetLogoFromEmail = () => {
    getLogoFromEmail(companyState.emailDomain)
      .then((profilePic) => {
        if (profilePic) {
          updateCompanyState({ profilePic });
        }
      })
      .catch(() => {
        notifyError('Failed to retrieve Company Logo from Email Domain');
      });
  };

  const renderEmailDomain = () => {
    return (
      <FlexRow>
        <TextField
          label='Email Domain'
          variant='outlined'
          onChange={(evt) => {
            setError((prev) => ({ ...prev, emailDomain: null }));
            updateCompanyState({ emailDomain: evt.target.value });
          }}
          value={companyState.emailDomain}
          margin='dense'
          fullWidth
          placeholder='e.g. @‌factorem.co / @‌dso.org.sg'
          error={error.emailDomain}
          helperText={error.emailDomain}
        />
        <Tooltip title='Generate Image from Email Domain' arrow>
          <IconButton
            disabled={!isEmailDomainValid(companyState.emailDomain)}
            onClick={handleGetLogoFromEmail}
          >
            <ImageIcon />
          </IconButton>
        </Tooltip>
      </FlexRow>
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='create-company'
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle id='create-company'>
        <Title contentTitle={`${capitalize(type)} Company`} size='small' />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 3rem',
            paddingBottom: '2rem',
          }}
        >
          <FlexRowCenter style={{ width: '100%' }}>
            <input
              id='profile-img'
              type='file'
              onChange={handleImgChange}
              onClick={(event) => (event.target.value = null)}
              style={{
                display: 'none',
              }}
              accept='image/*'
            />
            <label htmlFor='profile-img' className={classes.profilePicture}>
              <AvatarWithButton
                onButtonClick={() => updateCompanyState({ profilePic: '' })}
                className={classes.avatar}
                src={companyState.profilePic}
                deleteIconStyle={{ width: '1rem', height: '1rem' }}
              />
            </label>
          </FlexRowCenter>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label='Company Name'
                variant='outlined'
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, companyName: null }));
                  updateCompanyState({ companyName: evt.target.value });
                }}
                value={companyState.companyName}
                margin='dense'
                fullWidth
                required
                error={error.companyName}
                helperText={error.companyName}
              />
              <TextField
                label='Company Description'
                variant='outlined'
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, companyDesc: null }));
                  updateCompanyState({ companyDesc: evt.target.value });
                }}
                value={companyState.companyDesc}
                margin='dense'
                fullWidth
                multiline
                error={error.companyDesc}
                helperText={error.companyDesc}
              />
              <TextField
                label='Industry Sector'
                variant='outlined'
                value={companyState.industrySector}
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, industrySector: null }));
                  updateCompanyState({ industrySector: evt.target.value });
                }}
                error={error.industrySector}
                helperText={error.industrySector}
                fullWidth
                margin='dense'
                select
              >
                {Object.values(INDUSTRIES).map((industry) => (
                  <MenuItem key={industry} value={industry}>
                    {industry}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label='Customer Type'
                variant='outlined'
                value={companyState.customerType}
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, customerType: null }));
                  updateCompanyState({ customerType: evt.target.value });
                }}
                error={error.customerType}
                helperText={error.customerType}
                fullWidth
                margin='dense'
                select
              >
                {Object.values(CUSTOMER_TYPES).map((customerType) => (
                  <MenuItem key={customerType} value={customerType}>
                    {customerType}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label='Key Account'
                variant='outlined'
                value={companyState.keyAccount}
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, keyAccount: null }));
                  updateCompanyState({ keyAccount: evt.target.value });
                }}
                error={error.emailDomain}
                helperText={error.emailDomain}
                fullWidth
                margin='dense'
                select
              >
                {Object.values(YES_NO_TEXT_MAPPING).map((keyAccount) => (
                  <MenuItem
                    key={keyAccount}
                    value={YES_NO_VALUE_MAPPING[keyAccount]}
                  >
                    {keyAccount}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label='Payment Terms'
                variant='outlined'
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, paymentTerms: null }));
                  updateCompanyState({ paymentTerms: evt.target.value });
                }}
                value={companyState.paymentTerms}
                margin='dense'
                fullWidth
                error={error.paymentTerms}
                helperText={error.paymentTerms}
              />
              <AddressAutocomplete
                key='company-address'
                id='company-address'
                margin='dense'
                showCountry
                styleChild={{ marginTop: '8px' }}
                style={{ borderRadius: '5px' }}
                size='small'
                onChange={({ postalCode, address, unitNo, country }) =>
                  updateCompanyState({ postalCode, address, unitNo, country })
                }
                initialValue={convertFormattedAddress(
                  type === 'update' ? data.address : ''
                )}
              />
            </Grid>
            <Grid item xs={6}>
              {renderEmailDomain()}
              <TextField
                label='Finance Email'
                variant='outlined'
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, financeEmail: null }));
                  updateCompanyState({ financeEmail: evt.target.value });
                }}
                value={companyState.financeEmail}
                margin='dense'
                fullWidth
                error={error.financeEmail}
                helperText={error.financeEmail}
              />
              <TextField
                label='PPE Type'
                variant='outlined'
                value={companyState.ppeType}
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, ppeType: null }));
                  updateCompanyState({ ppeType: evt.target.value });
                }}
                error={error.ppeType}
                helperText={error.ppeType}
                fullWidth
                margin='dense'
                select
              >
                {Object.values(PPE_TYPES).map((ppeType) => (
                  <MenuItem key={ppeType} value={ppeType}>
                    {ppeType}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label='TDE Type'
                variant='outlined'
                value={companyState.tdeType}
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, tdeType: null }));
                  updateCompanyState({ tdeType: evt.target.value });
                }}
                error={error.tdeType}
                helperText={error.tdeType}
                fullWidth
                margin='dense'
                select
              >
                {Object.values(TDE_TYPE).map((tdeType) => (
                  <MenuItem key={tdeType} value={tdeType}>
                    {tdeType}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label='Credit Type'
                variant='outlined'
                value={companyState.creditType}
                onChange={(evt) => {
                  setError((prev) => ({ ...prev, creditType: null }));
                  updateCompanyState({ creditType: evt.target.value });
                }}
                error={error.creditType}
                helperText={error.creditType}
                fullWidth
                margin='dense'
                select
              >
                {Object.values(CUSTOMER_CREDIT_TYPES).map(
                  (customerCreditType) => (
                    <MenuItem
                      key={customerCreditType}
                      value={customerCreditType}
                    >
                      {customerCreditType}
                    </MenuItem>
                  )
                )}
              </TextField>
              <AddressAutocomplete
                key='company-address-finance'
                id='company-address-finance'
                margin='dense'
                showCountry
                label='Finance Address'
                styleChild={{ marginTop: '8px', marginBottom: 0 }}
                style={{ borderRadius: '5px' }}
                size='small'
                onChange={({ postalCode, address, unitNo, country }) =>
                  updateCompanyState({
                    financePostalCode: postalCode,
                    financeAddress: address,
                    financeUnitNoFinance: unitNo,
                    financeCountryFinance: country,
                  })
                }
                initialValue={convertFormattedAddress(
                  type === 'update' ? data.financeAddress : ''
                )}
              />
              <FilesUploadButton
                buttonText='Upload Customer Quotation terms'
                handleUploadFiles={handleUploadCustomerQuotationFile}
                multiple={false}
                accept='.pdf'
              />
              {companyState.quotationTermsUrl && (
                <Link
                  style={{ width: '100%' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!isMobile && !isTablet && !isIPad) {
                      generatePresignedUrl(
                        companyState.quotationTermsUrl,
                        FIVE_MINUTES_IN_SECOND,
                        true
                      ).then((presignedUrl) => {
                        openInNewTab(presignedUrl);
                      });
                    } else {
                      downloadS3File(companyState.quotationTermsUrl);
                    }
                  }}
                >
                  {extractFileNameWithoutTimestampFromUrl(
                    companyState.quotationTermsUrl
                  )}
                </Link>
              )}
            </Grid>
          </Grid>

          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {type === 'update' ? (
              <BlueButton
                btnContent='Update Company'
                onBtnClick={handleUpdateCompany}
              />
            ) : (
              <BlueButton
                btnContent='Create Company'
                onBtnClick={handleCreateCompany}
              />
            )}
          </div>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
export default CreateUpdateCompanyPopup;
