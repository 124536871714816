import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';

import { notifyError, notifySuccess } from '../services/notificationService';

import { getAllUsersApi } from '../apis/userApi';


// -------------------------------------------------------------------------------------------------

export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const EDIT_USER_LOADING = 'EDIT_USER_LOADING';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';
export const INSERT_PROPERTIES_SUCCESS = 'INSERT_PROPERTIES_SUCCESS';
export const INSERT_PROPERTIES_FAILURE = 'INSERT_PROPERTIES_FAILURE';
export const DELETE_USER_LOADING = 'DELETE_USER_LOADING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const RESET_USERS = 'RESET_USERS';

const cookies = new Cookies();

const getUsers = () => {
  return {
    type: GET_USERS_LOADING,
  };
};

export const resetUsersAction = () => {
  return {
    type: RESET_USERS,
  };
};

const getUsersSuccess = (users) => {
  return {
    type: GET_USERS_SUCCESS,
    users,
  };
};

const getUsersFailure = () => {
  toast.error('Unable to get users. Please refresh the page.', {
    position: toast.POSITION.TOP_RIGHT,
  });
  return {
    type: GET_USERS_FAILURE,
  };
};

export const editUserSuccess = (editedUser) => {
  toast.success('User successfully edited!', {
    position: toast.POSITION.TOP_RIGHT,
  });
  return {
    type: EDIT_USER_SUCCESS,
    editedUser,
  };
};

export const editUserFailure = () => {
  toast.error('User cannot be edited. Please try again.', {
    position: toast.POSITION.TOP_RIGHT,
  });
  return {
    type: EDIT_USER_FAILURE,
  };
};

const deleteUserSuccess = (deleteUserID) => {
  toast.success('User is deleted!', {
    position: toast.POSITION.TOP_RIGHT,
  });
  return {
    type: DELETE_USER_SUCCESS,
    deleteUserID,
  };
};

const deleteUserFailure = () => {
  toast.error('User cannot be deleted. Please try again.', {
    position: toast.POSITION.TOP_RIGHT,
  });
  return {
    type: DELETE_USER_FAILURE,
  };
};

const insertUserPropertiesSuccess = (newData) => {
  return {
    type: INSERT_PROPERTIES_SUCCESS,
    payload: newData,
  };
}

const insertUserPropertiesFailure = () => {
  return {
    type: INSERT_PROPERTIES_FAILURE,
  };
}

export const getAllUsers = (params = {}) => (dispatch) => {
  dispatch(getUsers());
  getAllUsersApi(params)
    .then(function (users) {
      dispatch(getUsersSuccess(users));
    })
    .catch(function () {
      dispatch(getUsersFailure());
    });
};

export const insertUserIndustryCustomerType = (newData) => (dispatch) => {
  const userID = newData.userID;
  const data = {
    ...newData,
  };
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/user/addIndustryCustomerType/${userID}`;
  fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        dispatch(insertUserPropertiesFailure());
      } else {
        dispatch(insertUserPropertiesSuccess(newData));
      }
    })
    .catch(() => {
      dispatch(insertUserPropertiesFailure());
    });
};

export const editUserData = (newData) => (dispatch) => {
  const userID = newData.userID;
  const data = {
    ...newData,
  };
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/user/edit/${userID}`;
  fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        dispatch(editUserFailure());
      } else {
        dispatch(editUserSuccess(userID));
        dispatch(getAllUsers());
      }
    })
    .catch(() => {
      dispatch(editUserFailure());
    });
};

export const deleteUser = (deleteUserID) => (dispatch) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/user/delete/${deleteUserID}`;
  fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        dispatch(deleteUserFailure());
      } else {
        dispatch(deleteUserSuccess(deleteUserID));
      }
    })
    .catch(() => {
      dispatch(deleteUserFailure());
    });
};

export const recoverPassword = (resetData, token, props) => () => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/recover/${token}`;
  fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(resetData)
  })
    .then((response) => {
      if (!response.ok) {
        notifyError('Password cannot be reset. Please try again.');
      } else {
        props.history.push('/');
        notifySuccess('Password reset successful!');
      }
    })
    .catch(() => {
      notifyError('Password cannot be reset. Please try again.');
    });
};
