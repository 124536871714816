// Import settings
import React from 'react';

import { colors } from '../../palette';

// Import customised components
import WhiteButton from '../buttons/WhiteButton';
import BlueButton from '../buttons/BlueButton';

// Import material UI components
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  firstParagraph: {
    marginBottom: theme.spacing(2),
  },
  lastParagraph: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  space: {
    width: theme.spacing(2),
  },
}));

function PriceTooLowPopup(props) {
  const classes = useStyles();
  const { dialog, handleClose, handleSwitchButtonClick } = props;

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <div style={{ padding: '35px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <React.Fragment>
            <Box>
              <Typography className={classes.firstParagraph}>
                Sorry, our system oversimplified your order and priced it too
                low!
              </Typography>
              <Typography className={classes.lastParagraph}>
                Kindly select{' '}
                <span
                  style={{
                    color: colors.blue060,
                    fontWeight: 'bold',
                  }}
                >
                  SWITCH TO MANUAL RFQ
                </span>{' '}
                for our engineers to take a closer look and price it accurately.
              </Typography>
            </Box>
          </React.Fragment>
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <WhiteButton
            onBtnClick={handleClose}
            btnContent='Back'
            size='small'
          />
          <Box className={classes.space}></Box>
          <BlueButton
            onBtnClick={handleSwitchButtonClick}
            btnContent='Switch to Manual RFQ'
            id='switch-to-manual-rfq-button'
          />
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default PriceTooLowPopup;
