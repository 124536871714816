import { useEffect, useState } from "react";
import { isEmptyValue } from "../utils/commonUtils";
import { sendUserFeedback } from "../apis/userFeedbackApi";

export const useUserFeedbackHook = ({
  questionList = [],
  checkoutID,
}) => {
  const [qAndA, setQAndA] = useState([]);
  const [hasAllQuestionAnswered, setHasAllQuestionAnswered] = useState(false);

  useEffect(() => {
    const qAndA = questionList.map((question) => ({
      ...question,
    }));
    setQAndA(qAndA);
  }, [questionList]);

  const updateAnswer = ({ id, answer }) => {
    const qAndAObj = qAndA.find(answer => answer.id === id);
    if (qAndAObj) {
      qAndAObj.answer = answer;
    }
    setQAndA([...qAndA]);
    setHasAllQuestionAnswered(qAndA.every(question => !isEmptyValue(question.answer)));
  }

  const submitUserFeedback = async (userID, submitLocation = 'First checkout') => {
    const body = {
      qAndA,
      submitLocation,
      checkoutID,
    }
    return sendUserFeedback(userID, body);
  }

  return [
    {
      qAndA,
      hasAllQuestionAnswered,
    },
    {
      updateAnswer,
      submitUserFeedback,
    },
  ];
}
