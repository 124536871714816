import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const getSupplierAdminControl = async (supplierID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/suppliers/${supplierID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateSupplierAdminControl = async (supplierID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/suppliers/${supplierID}`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getViewAllJobsWhitelistAccountsControl = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/all-jobs-whitelist`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getAvailableWhitelistAccountsControl = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/available-whitelist-accounts`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateWhitelistAccountsControl = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/admin-control/all-jobs-whitelist`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
