import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import SubDesc from '../SubDesc';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function DeletePromotionCodePopup(props) {
  const classes = useStyles();

  const {
    dialog,
    handleClose,
    onOk,
    currentId,
    codeName,
    percentOff,
  } = props;

  const handleDeleteButtonClick = () => {
    if (typeof onOk === 'function') {
      onOk(currentId);
    }
  }

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <SubDesc content="Delete Promotion Code" />
      </DialogTitle>
      <div style={{ padding: '10px 20px 20px 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <p style={{ marginTop: '0' }}>
            Are you sure you want to delete this promotion code?
          </p>
          <p>
            Code Name: <strong>{codeName}</strong>
            <br/>
            Percent Off: <strong>{percentOff}</strong>
          </p>
          <p>
            This action cannot be undone.
          </p>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <WhiteButton
              onBtnClick={handleClose}
              btnContent='Cancel'
              size='small'
            />
            <Box className={classes.space}></Box>
            <BlueButton
              onBtnClick={handleDeleteButtonClick}
              btnContent='delete'
            />
          </div>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default DeletePromotionCodePopup;
