// Import settings
import React, { useState } from "react";

// Import color palette
import { colors } from "../../palette";

// Import material UI components
import {
  Divider,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";

import {
  InfoOutlined as InfoOutlinedIcon,
} from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles/index";
import SingleImage from "../images/SingleImage";
import { getItemImageUrl, getMaterialWithColorText, getSurfaceFinishWithCustomizationsText } from "../../utils/itemUtils";
import FileDownloadListDisplay from "./FileDownloadListDisplay";
import DownloadAllFilesButton from "../buttons/DownloadAllFilesButton";
import { TECHNOLOGY_OPTION_TYPE, THREE_D_P_FDM_TECH } from "../../constants/NewPartConstants";
import { get, isEmpty } from "lodash";
import QcReportsDisplay from "../info/QcReportsDisplay";
import { QC_NO_QC_REPORT } from "../../constants/projectConstants";
import { WATCHING_JOB_STATUS } from '../../constants/watchingJob';
import { WatchingJob } from "../WatchingJob";

// Style components
const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  itemImageWrapper: {
    height: "100%",
  },
  itemImage: {
    width: 220,
    height: 220,
    border: `${colors.darkGreyBorder}`,
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "auto",
    height: "auto",
  },
  itemPropertyWrapper: {
    width: "100%",
    marginLeft: 40,
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0 0',
    }
  },
  itemName: {
    fontWeight: "bold",
    margin: "6px 0",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  itemProperty: {
    margin: "15px 40px 0 0",
  },
  itemPropertyKey: {
    fontWeight: "bold",
    margin: 0,
  },
  itemPropertyValue: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  itemFile: {
    paddingTop: "10px",
    display: "flex",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  infoImage: {
    marginTop: '0.7rem',
    fontSize: '0.75rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      wrap: 'no-wrap',
      minWidth: 'max-content'
    }
  },
  watchLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.lightGray,
    zIndex: 1,
    border: `2px solid ${colors.lightGray}`,
    borderRadius: "4px",
    height: '1.8rem',
    width: '7.5rem',
    columnGap: '3px',
    justifyContent: 'center',
    margin: "5px 0",
    textTransform: "uppercase",
    fontWeight: "bold",
    cursor: 'pointer'
  },
  watchLabelActive: {
    borderColor: colors.blue050,
    color: colors.blue050,
    width: '9.5rem',
  },
}));

const ITEM_IMAGE_SIZE = 220;

/**
 * @deprecated
 * @param {*} props
 * @returns
 */
function ItemLabel(props) {
  const classes = useStyles();
  const { item, role } = props;
  const { qcReports, watchingJobStatus, itemID, projectID } = item;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isWatching, setIsWatching] = useState(watchingJobStatus === WATCHING_JOB_STATUS.ACTIVE)

  // success response instantQuotesLogMeta
  let infoInstantLogQuoteMeta = get(item, 'instantQuotesLogMeta.additionalParameters')
  if (!infoInstantLogQuoteMeta) {
    // failed response instantQuotesLogMeta
    infoInstantLogQuoteMeta = get(item, 'instantQuotesLogMeta', {})
  }
  const { boundingBoxX, boundingBoxY, boundingBoxZ, volume, surfaceArea } = infoInstantLogQuoteMeta
  let size = null
  if (boundingBoxX && boundingBoxY && boundingBoxZ) {
    size = `${boundingBoxX.toFixed(2)}mm x ${boundingBoxY.toFixed(2)}mm x ${boundingBoxZ.toFixed(2)}mm`
  }

  const render3DPrintingTechnology = () => {
    return (
      <Grid item xs={6}>
        <div className={classes.itemProperty}>
          <Typography
            gutterBottom
            variant="body1"
            className={classes.itemPropertyKey}
          >
            3D Printing Technology
          </Typography>
          <Typography gutterBottom variant="body2">
            {item.threeDTechnology}
          </Typography>
        </div>
      </Grid>
    );
  }

  const render3DInfill = () => {
    return (
      <Grid item xs={6}>
        <div className={classes.itemProperty}>
          <Typography
            gutterBottom
            variant="body1"
            className={classes.itemPropertyKey}
          >
            3D Infill
          </Typography>
          <Typography gutterBottom variant="body2">
            {`${Number(item.threeDInfill * 100).toFixed(2)}%`}
          </Typography>
        </div>
      </Grid>
    );
  }

  const render3DLayerThickness = () => {
    return (
      <Grid item xs={6}>
        <div className={classes.itemProperty}>
          <Typography
            gutterBottom
            variant="body1"
            className={classes.itemPropertyKey}
          >
            3D Layer Thickness
          </Typography>
          <Typography gutterBottom variant="body2">
            {`${item.threeDLayerThickness}mm`}
          </Typography>
        </div>
      </Grid>
    );
  }

  const renderQcReports = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 15,
          marginBottom: '0.5rem',
        }}
      >
        <Typography style={{ fontWeight: 'bold' }} variant="body1">QC Requirement(s)</Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '1rem',
            marginTop: '0.3rem',
          }}
        >
          <QcReportsDisplay qcReports={qcReports} />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.label}>
      <Grid item className={classes.itemImageWrapper}>
        <SingleImage
          url={getItemImageUrl(item)}
          width={ITEM_IMAGE_SIZE}
          height={ITEM_IMAGE_SIZE}
        />
        <div className={classes.infoImage}>
          {size && (
            <div>
              <strong>Size</strong>
              <span>{size}</span>
            </div>
          )}
          {volume && (
            <div>
              <strong>Volume</strong>
              <span>{`${volume.toFixed(2)} cm3`}</span>
            </div>
          )}
          {surfaceArea && (
            <div>
              <strong>Surface Area</strong>
              <span>{`${surfaceArea.toFixed(2)} cm2`}</span>
            </div>
          )}
        </div>
      </Grid>
      <div className={classes.itemPropertyWrapper}>
        <Grid>
          {item.referenceName ? (
            <Typography gutterBottom variant="h5" className={classes.itemName}>
              {item.referenceName}
            </Typography>
          ) : (
            <div />
          )}
          <Divider />
        </Grid>
        <Grid container display="flex" justifyContent="space-between" direction={isMobile ? 'column' : 'row'}>
          {item.itemID &&
            (role === "admin" || role === "reviewer" || role === "superadmin") ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.itemPropertyKey}
                >
                  Part ID
                </Typography>
                <Typography
                  gutterBottom
                  variant="body2"
                  className={classes.itemPropertyValue}
                >
                  {item.itemID}
                </Typography>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.datePosted &&
            (role === "admin" || role === "reviewer" || role === "superadmin") ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.itemPropertyKey}
                >
                  Date posted
                </Typography>
                <Typography gutterBottom variant="body2">
                  {item.datePosted.substring(0, 10)}
                </Typography>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.description ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.itemPropertyKey}
                >
                  Description
                </Typography>
                <Typography gutterBottom variant="body2">
                  {item.description}
                </Typography>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.expectedPrice && (role === "admin" || role === "superadmin") ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.itemPropertyKey}
                >
                  Customer budget
                </Typography>
                <Typography gutterBottom variant="body2">
                  ${(Math.ceil(item.expectedPrice / 1.2 / 5) * 5).toFixed(2)}
                </Typography>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.tolerance ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Tooltip
                  title="Reflects the smallest of all the tolerances in design requirements. Please check if it can be met this before proceeding."
                  placement="left"
                  arrow
                >
                  <div>
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.itemPropertyKey}
                    >
                      Tightest Tolerance Required
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      {item.tolerance}mm
                    </Typography>
                  </div>
                </Tooltip>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.technology ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.itemPropertyKey}
                >
                  Technology
                </Typography>
                <Typography gutterBottom variant="body2">
                  {item.technology}
                </Typography>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.quantity ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.itemPropertyKey}
                >
                  Quantity
                </Typography>
                <Typography gutterBottom variant="body2">
                  {item.quantity}
                </Typography>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.surfaceFinish ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.itemPropertyKey}
                >
                  Surface Finish
                </Typography>
                <Typography gutterBottom variant="body2">
                  {getSurfaceFinishWithCustomizationsText(item)}
                </Typography>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.material ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.itemPropertyKey}
                >
                  Material
                </Typography>
                <Typography gutterBottom variant="body2">
                  {getMaterialWithColorText(item)}
                </Typography>
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING && render3DPrintingTechnology()}
          {item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING && item.threeDTechnology === THREE_D_P_FDM_TECH && render3DInfill()}
          {item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING && item.threeDTechnology === THREE_D_P_FDM_TECH && render3DLayerThickness()}
          {item.deadline && !item.price && (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    gutterBottom
                    variant="body1"
                    className={classes.itemPropertyKey}
                  >
                    Target Deadline&nbsp;
                  </Typography>
                  <Tooltip
                    title="Use this as a guideline based on client's preference."
                    arrow
                    fontSize="small"
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>
                <Typography gutterBottom variant="body2">
                  {item.deadline.substring(0, 10)}
                </Typography>
              </div>
            </Grid>
          )}
          {item.cadFile ? (
            <Grid item sm={12} md={6}>
              <div className={classes.itemProperty}>
                <FileDownloadListDisplay title="Design/CAD files" urlList={item.cadFile.split(",")} />
                <DownloadAllFilesButton
                  urlList={item.cadFile.split(",")}
                  folderName={`Factorem_${item.referenceName}`}
                />
              </div>
            </Grid>
          ) : (
            <div />
          )}
          {!isEmpty(qcReports) && qcReports && qcReports.main !== QC_NO_QC_REPORT && (
            <Grid item sm={12} md={6}>
              {renderQcReports()}
            </Grid>
          )}
          <Grid item sm={12} md={6}>
            <WatchingJob
              withText
              isWatching={isWatching}
              setIsWatching={setIsWatching}
              itemIDs={[itemID]}
              projectID={projectID}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ItemLabel;
