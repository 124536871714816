import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router-dom";

// Import color palette
import { colors } from "../palette";

// Import material UI components
import { makeStyles } from "@material-ui/core/styles";

// Import customised components
import OrderHistoryButton from "./buttons/OrderHistoryButton";

const useStyles = makeStyles(() => ({
  statusText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "20px",
    flexBasis: "20%"
  },
  media: {
    border: colors.darkBlueBorder,
    maxWidth: "100%",
    maxHeight: "100%"
  },
  mediaBackground: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 140,
    height: 140
  },
  text: {
    paddingLeft: 25,
    paddingRight: 25
  }
}));

function OrderHistoryParent({ item, onClickBtn, type }) {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "spaceBetween",
        alignItems: "center"
      }}
    >
      <div
        className={classes.root}
        style={{
          textAlign: "center"
        }}
      >
        <OrderHistoryButton item={item} onClickBtn={onClickBtn} type={type} />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function matchDispatchToProps(dispatch, props) {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(OrderHistoryParent));
