import React from 'react';

import { FormControlLabel } from '@material-ui/core';

import FtrTypography from './FtrTypography';
import FtrBlueCheckbox from './FtrBlueCheckbox';

function FtrCheckbox(props) {
  const {
    label = '',
    onChange,
    checked,
    disabled = false,
    labelPadding = '0px 10px',
    ...rest
  } = props;

  return (
    <FormControlLabel
      control={
        <FtrBlueCheckbox
          onChange={onChange}
          checked={checked}
          style={{ padding: labelPadding }}
          disabled={disabled}
        />
      }
      style={{
        width: 'fit-content',
      }}
      label={
        <FtrTypography type='body' fontSize='14' style={{ userSelect: 'none' }}>
          {label}
        </FtrTypography>
      }
      {...rest}
    />
  );
}

export default FtrCheckbox;
