import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const updateDeliveryInfo = async (deliveryInfoID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/delivery-info/${deliveryInfoID}`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const createDeliveryInfo = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/delivery-info`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const deleteDeliveryInfo = async (deliveryInfoID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/delivery-info/${deliveryInfoID}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateDefaultAddress = async (deliveryInfoID, type, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/delivery-info/${deliveryInfoID}/default/${type}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
