import queryString from 'query-string';

import {
  fetchWithErrorHandling,
  getAuthorizedHeader,
  witchCacheResponse,
} from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';


// -------------------------------------------------------------------------------

export const calculateQuotationTotalPrice = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/price/quotation-total-price?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
}

let currentController = new AbortController();
export const getShippingPrice = async (body) => {
  if (currentController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    currentController.abort();
  }
  currentController = new AbortController();
  const signal = currentController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/price/shipping-price`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
    signal,
  });
}

export const getShippingPriceWithCache = witchCacheResponse(
  getShippingPrice,
  10 * 60, // 10 mins in seconds
);
