export const OPEN_POPUP = 'OPEN_POPUP';
export const FILE_RENDER = 'FILE_RENDER';
export const URL_FILE_RENDER = 'URL_FILE_RENDER';

export const openPopupCadRenderer = (open) => {
  return {
    type: OPEN_POPUP,
    open,
  };
};

export const filCadRender = (file) => {
  return {
    type: FILE_RENDER,
    file,
  };
};

export const urlFileCadRender = (urlFile) => {
  return {
    type: URL_FILE_RENDER,
    urlFile,
  };
};
