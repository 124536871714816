import { extractAndDecodeFileNameAndExtensionFromUrl } from '../../utils/fileUtils';


// ----------------------------------------------------------------------------------------------

const checkDesignPartExactNameMatched = (pdfPart, designPart) => {
  const [pdfFileName] = extractAndDecodeFileNameAndExtensionFromUrl(pdfPart?.s3ObjectUrl);
  const [designFileName] = extractAndDecodeFileNameAndExtensionFromUrl(designPart?.s3ObjectUrl);
  const match = designFileName
    && pdfFileName
    && designFileName.toLowerCase() === pdfFileName.toLowerCase();

  return match;
}

const checkDesignPartIncludeNameMatch = (pdfPart, designPart) => {
  const [pdfFileName] = extractAndDecodeFileNameAndExtensionFromUrl(pdfPart?.s3ObjectUrl);
  const [designFileName] = extractAndDecodeFileNameAndExtensionFromUrl(designPart?.s3ObjectUrl);
  const match = designFileName
    && pdfFileName
    && (
      designFileName.toLowerCase().includes(pdfFileName.toLowerCase())
      || pdfFileName.toLowerCase().includes(designFileName.toLowerCase())
    );

  return match;
}

/**
 * Finds a matching design part for a given PDF part in an array of design parts.
 * Matching order is:
 * 1. Exact name match
 * 2. Include name match
 *
 * @param {Object} pdfPart - The PDF part to match against the design parts.
 * @param {Array<Object>} designParts - The array of design parts to search for a match.
 * @return {Object|null} The matching design part, or null if no match is found.
 */
export const findMatchDesignPartForPdf = (pdfPart, designParts) => {
  for (const designPart of designParts) {
    const match = checkDesignPartExactNameMatched(pdfPart, designPart);
    if (match) {
      return designPart;
    }
  }

  for (const designPart of designParts) {
    const match = checkDesignPartIncludeNameMatch(pdfPart, designPart);
    if (match) {
      return designPart;
    }
  }

  return null;
}

export const separateZipAndNonZipFiles = (files) => {
  const zipFiles = [];
  const normalFiles = [];
  for (const file of files) {
    if (file.name.endsWith('.zip')) {
      zipFiles.push(file);
    } else {
      normalFiles.push(file);
    }
  }
  return {
    zipFiles,
    normalFiles,
  };
}
