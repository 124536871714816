import React, { useState } from 'react';

import { Button, Menu, MenuItem, Typography } from '@material-ui/core';

import { ArrowDropDown } from '@material-ui/icons';

function RowsPerPageMenu(props) {
  const {
    value,
    optionList = [10, 20, 50],
    onRowsPerPageChange = () => { },
  } = props;

  const [selectedOption, setSelectedOption] = useState(value);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography variant='body2'>
        Rows per page:&nbsp;
      </Typography>
      <Button
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
      >
        {selectedOption}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {optionList.map(option => {
          return (
            <MenuItem
              key={option}
              onClick={() => {
                handleClose();
                onRowsPerPageChange(option);
                setSelectedOption(option);
              }}
              selected={option === selectedOption}
            >
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

export default RowsPerPageMenu;
