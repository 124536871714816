import React, { Fragment, useState } from "react";
import { startCase } from "lodash";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles/index";

import {
  Avatar,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Box,
  InputAdornment,
  IconButton
} from "@material-ui/core";

import {
  Accessibility as AccessibilityIcon,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";

import CountryDropDown from "../components/forms/CountryDropDown";
import AddressAutocomplete from "../components/AddressAutocomplete";

import { signupUser } from "../actions";

import { getContactWithCountryCode, getDefaultProfilePic } from "../utils/userUtils";

import { uploadPublicFileToS3 } from "../services/s3Service";

import { COUNTRY_LIST } from "../constants/countryConstants";
import { CUSTOMER_STATUS_TYPES } from '../constants/customerConstants';
import { GOOGLE_API_KEY } from "../constants/apiConstants";
import { PROFILEPIC_DIRNAME, ROLE_TYPES } from "../constants";

const useStyles = makeStyles({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  paper: {
    marginTop: 100,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057"
  },
  form: {
    marginTop: 1
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    textAlign: "center"
  },
  selectEmpty: {
    marginTop: "2px"
  }
});

function AdminCreateNewUser(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDesc, setCompanyDesc] = useState("");
  const [secondaryName, setSecondaryName] = useState("");
  const [secondaryEmail, setSecondaryEmail] = useState("");
  const [secondaryContact, setSecondaryContact] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState('Singapore');
  const [countryCode, setCountryCode] = useState("+65");
  const [profilePic, setProfilePic] = useState("");
  const [picUploaded, setPicUploaded] = useState(false);
  const [passwordValidated, setPasswordValidated] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [unitNo, setUnitNo] = useState('')

  const classes = useStyles();
  const { adminRole, adminCreateUser } = props;

  function handleProfilePicChange(e) {
    const file = e.target.files[0];
    const s3Key = `${PROFILEPIC_DIRNAME}/${file.name}`;
    uploadPublicFileToS3(file, s3Key)
      .then(data => {
        setProfilePic(data.Location.split(" ").join("%20"));
        setPicUploaded(true);
      })
      .catch(err => {
        console.error(err);
        setPicUploaded(false);
        alert(err);
      });
  }

  const handleCountryCodeChange = (value) => {
    setCountryCode(COUNTRY_LIST[value].phone);
    setCountry(value);
  }

  const handleCountryChange = (value) => {
    setCountry(value);
  }

  const handleSubmit = () => {
    const formattedContact = getContactWithCountryCode(contact, countryCode);

    let userData = {
      email,
      password,
      name,
      role,
      contact: formattedContact,
      companyName,
      companyDesc,
      secondaryName,
      secondaryEmail,
      secondaryContact,
      address,
      profilePic: profilePic ? profilePic : getDefaultProfilePic(name),
      country,
      status: role === ROLE_TYPES.BUYER ? CUSTOMER_STATUS_TYPES.QUALIFIED : CUSTOMER_STATUS_TYPES.NA,
      unitNo,
      postalCode,
    };
    adminCreateUser(userData);
  };

  const validatePassword = password => {
    const re = new RegExp(
      "^(?=.*[A-Z])(?=.*[0-9])(?=.*[ !\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,})"
    );
    if (re.test(password)) {
      setPasswordValidated(true);
    } else {
      setPasswordValidated(false);
    }
  };

  const getRoleMenuItems = () => {
    let allowedCreationRole = [];
    if (adminRole === ROLE_TYPES.SUPER_ADMIN) {
      allowedCreationRole = Object.values(ROLE_TYPES);
    } else if (adminRole === ROLE_TYPES.SALES_CONSULTANT) {
      allowedCreationRole = [ROLE_TYPES.BUYER];
    } else {
      allowedCreationRole = [ROLE_TYPES.BUYER, ROLE_TYPES.SUPPLIER];
    }

    return allowedCreationRole.map(role => (
      <MenuItem key={role} value={role}>{startCase(role.replace(/_/g, ' '))}</MenuItem>
    ));
  }

  const disableSignUpButton = !role || !name || !email || !password || !contact || !companyName || !address || !country

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccessibilityIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          New User
        </Typography>
        <div
          style={{
            marginTop: "30px",
            width: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <input
            accept="image/*"
            className={classes.input}
            id="profile-img-file"
            type="file"
            onChange={handleProfilePicChange}
            onClick={(event) => event.target.value = null}
            style={{
              display: "none"
            }}
          />
          <label htmlFor="profile-img-file">
            {picUploaded ? (
              <Button
                variant="outlined"
                component="span"
                color="primary"
                className={classes.button}
              >
                Upload profile picture
              </Button>
            ) : (
              <Button
                variant="contained"
                component="span"
                color="primary"
                className={classes.button}
              >
                Upload profile picture
              </Button>
            )}
          </label>
        </div>
        <Box style={{ height: '1rem' }} />
        <FormControl
          required
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <TextField
            className={classes.selectEmpty}
            variant="outlined"
            required
            labelId="role-label"
            id="role"
            label="Role"
            select
            value={role}
            onChange={({ target }) => setRole(target.value)}
          >
            {getRoleMenuItems()}
          </TextField>
        </FormControl>
        <TextField
          required
          variant="outlined"
          margin="normal"
          fullWidth
          name="name"
          label="Name"
          type="text"
          id="name"
          onChange={({ target }) => setName(target.value)}
        />
        <TextField
          required
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          onChange={({ target }) => setEmail(target.value)}
        />
        <Tooltip
          disableHoverListener
          title="Password must have at least 8 characters with at least one capital letter, one number, and one special character"
          arrow
          placement="right"
          open={!passwordValidated}
        >
          <TextField
            className={classes.input}
            required
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            // error={!passwordValidated}
            onChange={({ target }) => {
              validatePassword(target.value);
              setPassword(target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
        <Grid
          container
          direction="row"
          alignItems="center"
        >
          <CountryDropDown
            id="countryCode"
            isAdmin
            isCountryCodeDropDown
            onSelect={handleCountryCodeChange}
            valueSelected={country}
          />
          <TextField
            required
            variant="outlined"
            margin="normal"
            name="contact"
            label="Contact"
            type="text"
            id="contact"
            onChange={({ target }) => setContact(target.value)}
            style={{
              width: "70%",
            }}
          />
        </Grid>
        <TextField
          required
          variant="outlined"
          margin="normal"
          fullWidth
          name="companyName"
          label="Company Name"
          type="text"
          id="companyName"
          onChange={({ target }) => setCompanyName(target.value)}
        />
        <TextField
          variant="outlined"
          style={{ width: "100%" }}
          multiline
          minRows={3}
          margin="dense"
          id="description"
          label="Company Description"
          placeholder="Please key in any details about your company's capabilities. (e.g. Machining capabilities/materials/design capability/etc.). "
          type="text"
          fullWidth
          onChange={({ target }) => setCompanyDesc(target.value)}
        />
        {GOOGLE_API_KEY ? (
          <AddressAutocomplete
            showCountry={false}
            style={{ marginBottom: '1rem' }}
            required
            onChange={({ postalCode, unitNo, address, country }) => {
              setAddress(address);
              setPostalCode(postalCode);
              setUnitNo(unitNo);
              setCountry(country);
            }}
          />
        ) : (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="address"
            label="Address"
            type="text"
            id="address"
            onChange={({ target }) => setAddress(target.value)}
          />
        )}
        <CountryDropDown
          id="country"
          isAdmin
          onSelect={handleCountryChange}
          valueSelected={country}
        />
        <FormControl
          required
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          {role === "supplier" && (
            <Fragment>
              <TextField
                className={classes.field}
                variant="outlined"
                margin="normal"
                id="secondaryName"
                label="Secondary POC Name"
                name="secondaryName"
                value={secondaryName}
                onChange={evt => setSecondaryName(evt.target.value)}
              />
              <TextField
                className={classes.field}
                variant="outlined"
                margin="normal"
                id="secondaryEmail"
                label="Secondary POC Email Address"
                name="secondaryEmail"
                value={secondaryEmail}
                onChange={evt => setSecondaryEmail(evt.target.value)}
              />
              <TextField
                className={classes.field}
                variant="outlined"
                margin="normal"
                id="secondaryContact"
                label="Secondary POC Contact Number"
                name="secondaryContact"
                value={secondaryContact}
                onChange={evt => setSecondaryContact(evt.target.value)}
              />
            </Fragment>
          )}
        </FormControl>
        {/*{signupError && (*/}
        {/*<Typography component="p" className={classes.errorText}>*/}
        {/*Incorrect email or password.*/}
        {/*</Typography>*/}
        {/*)}*/}
        <Box style={{ height: '1rem' }} />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={handleSubmit}
          data-cy='signup'
          disabled={disableSignUpButton}
        >
          Create a new user
        </Button>
      </Paper>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    adminRole: state.auth.user.role,
  };
}

function matchDispatchToProps(dispatch, props) {
  const history = useHistory();
  const isAdmin = true;
  return {
    adminCreateUser: userData => dispatch(signupUser(userData, { ...props, history }, isAdmin)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(AdminCreateNewUser);
