import queryString from 'query-string';

import { getAuthorizedHeader, witchCacheResponse } from "../utils/apiUtils";

import { BACKEND_SERVICE_URL } from "../constants";

export const uploadFileToS3 = async (file, s3Key, customFileName = null, isPublic = false) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/s3/upload`;
  const body = new FormData();
  body.append('file', file);
  body.append('s3Key', s3Key);
  if (customFileName) {
    body.append('customFileName', customFileName);
  }
  if (isPublic) {
    body.append('isPublic', isPublic);
  }
  const headers = getAuthorizedHeader();
  delete headers['Content-Type'];
  return fetch(requestUrl, {
    method: "POST",
    headers,
    body,
  }).then(async (response) => {
    if (!response.ok) {
      const json = await response.json();
      if (json.message) {
        throw Error(json.message);
      }
      throw Error(response.statusText);
    }
    const json = await response.json();
    return {
      Location: json.data,
    }
  });
}

export const deleteObject = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/s3/file`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(params),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw new Error(err.detail);
      });
    }
    return response.json();
  });
};

export const generatePresignedUrl = async (s3ObjUrl, expirationTime, inlineView = false) => {
  const params = {
    s3ObjUrl,
    expirationTime,
    inlineView,
  }
  const requestUrl = `${BACKEND_SERVICE_URL}/s3/generate-presigned-url?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw new Error(err.detail);
      });
    }
    return (await response.json()).data;
  });
};

export const generatePresignedUrlForImage = witchCacheResponse(
  generatePresignedUrl,
  1 * 60 * 60, // 1 hour in seconds
);

export const supplierDownloadCADFileAPI = async (s3ObjUrl) => {
  const params = {
    s3ObjUrl,
  }
  const requestUrl = `${BACKEND_SERVICE_URL}/s3/supplier/download-cad-file?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw new Error(err.detail);
      });
    }
    return response.blob();
  });
};
