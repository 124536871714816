// Import settings
import React from "react";
import { connect } from "react-redux";

// Import material UI components
import { makeStyles, withStyles } from "@material-ui/core/styles/index";
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  Typography
} from "@material-ui/core";

// Import color palette
import { colors } from "../palette";

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(3)
  }
}));

const ExpansionPanel = withStyles({
  root: {
    border: colors.expansionPanelBorder,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: colors.expansionBackgroundColor,
    borderBottom: colors.expansionPanelBorder,
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails);

export function CustomerFaq(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className={classes.body}>
      <h1>FAQs</h1>
      <div>
        <ExpansionPanel
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <ExpansionPanelSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              How do I get a quote for my part? How do I see costs and lead times?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            <div>
              The Factorem platform connects you to suppliers that can make your custom part!
              All you need is a 3D model for a start.
              You can upload that on the “Add A Part” page and we will notify you as soon as a quotation is made. You can keep checking the progress of a quotation under the “Quotations” page.
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <ExpansionPanelSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              What if I am unsure of filling up some of the requested fields when uploading a part?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            Please fill in all fields to your best knowledge and include any uncertainties under the “Remarks” field.
            In case of any issues, our team will reach out to you to clarify!
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <ExpansionPanelSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              What happens after I submit my part?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            Upon submission, the Factorem portal will automatically inform all relevant suppliers of the request. The suppliers will then review your order and provide a quotation accordingly.
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <ExpansionPanelSummary
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              Where are the suppliers from? How do you ensure quality?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            We pride ourselves in the quality of our service and ensure that all suppliers go through a rigorous background check.
            We only accept suppliers that have good rapport with our partners and hold them accountable in case of any errors on their part.
            This adds to our mission of #HelpingMakersMake as seamlessly as possible!
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <ExpansionPanelSummary
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              How do I know when a supplier quotes my part?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            We will notify you each time a quotation is submitted by a supplier for any of your parts. Keep checking your email!
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <ExpansionPanelSummary
            aria-controls="panel6d-content"
            id="panel6d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              How do I accept a quotation?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            <div>
              As soon as a supplier quotes for your order, you will be notified via email. You must then go into the “Quotations” page to review and approve the quote if it meets your requirement. Once accepted, the respective supplier will proceed to making your part requested!
            </div>
            <div style={{ height: 10 }} />
            <div>
              Please review and remarks provided by the supplier and address them accordingly as you proceed to complete the order.
            </div>
            <div style={{ height: 10 }} />
            <div>
              You can keep track of the progress of your order under the “Manage My Parts” page.
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <ExpansionPanelSummary
            aria-controls="panel7d-content"
            id="panel7d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              How do I make payment?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            Once a quotation is accepted by you, the team for Factorem will reach out to you via email with the payment details. Please note that for orders above $1000, a 50% deposit is required to process the order.
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <ExpansionPanelSummary
            aria-controls="panel8d-content"
            id="panel8d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              What if the quotation provided is too high or lead time too long?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            If the current provided quotation does not meet your requirements, you may choose to wait longer as other suppliers review and quote for your order. As long as you have not accepted a quotation that is provided, the Factorem Portal will actively keep searching for suppliers that can make your part.
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          square
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
        >
          <ExpansionPanelSummary
            aria-controls="panel9d-content"
            id="panel9d-header"
          >
            <Typography style={{ fontWeight: "bold" }}>
              What if there are mistakes in the order I received?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ flexDirection: "column", margin: "0 10px" }}
          >
            At Factorem, we pride ourselves for the quality of suppliers and the ethics they maintain in their work. If there is an error on the part received, please contact us directly at alexandra@factorem.co and we will get back to your as soon as possible. We strive to provide quality in the custom parts that are ordered through us and will not let any order go unsatisfied!           </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      <div style={{ margin: "20px", display: "flex", fontStyle: "italic" }}>
        For any further clarifications feel free to whatsapp us at
        <div style={{ margin: "0 6px" }}>8839 1796</div>
        or email us at{" "}
        <div style={{ margin: "0 6px" }}>
          <a href="mailto: alexandra@factorem.co">alexandra@factorem.co</a>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(CustomerFaq);
