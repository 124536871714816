import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Divider } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import {
  FtrBanner,
  FtrBoldText,
  FtrHeading,
  FtrNormalText,
} from '../../ftr-components';

import FtrInfoIconTooltip from '../../ftr-components/FtrInfoIconTooltip';
import FtrShowHidePromoCodeField from '../../ftr-components/FtrShowHidePromoCodeField';
import { FlexColumn, FlexRow } from '../../layouts/FlexLayouts';
import { ConfirmOrderPopupContext } from '../ConfirmOrderPopup';

import { useCreditsBalance } from '../../../hooks/useCreditsBalanceHook';
import useCustomerSettingsHook from '../../../hooks/useCustomerSettingsHook';

import { getMultiCheckoutFormSelector } from '../../../selectors/multiCheckoutFormSelector';
import { getExchangeRateSelector } from '../../../selectors/exchangeRatesSelector';
import { getUserCurrencySelector } from '../../../selectors/userSelector';

import { getSummaryLabels } from '../../../utils/checkoutSummaryUtils';
import {
  beautifyNumber,
  convertPriceToCurrency,
  extractPriceFromCurrencyString,
  getCurrencySymbol,
} from '../../../utils/currencyUtils';

import { COUNTRY_NAMES } from '../../../constants/countryConstants';
import { CUSTOMER_CREDIT_TYPES } from '../../../constants/customerConstants';

import { colors } from '../../../palette';


// ------------------------------------------------------------------------------

function MultiCheckoutSummaryPanelV2() {
  const {
    creditType,
    customerID,
  } = useContext(ConfirmOrderPopupContext);

  const { customerSettings } = useCustomerSettingsHook(customerID);

  const exchangeRate = useSelector(getExchangeRateSelector);
  const currency = useSelector(getUserCurrencySelector);
  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);

  const pricingStatus = multiCheckoutForm.status;
  const checkoutPriceSummary = multiCheckoutForm?.checkoutPriceSummary || {};
  const factoremCustomDiscountCode = multiCheckoutForm?.factoremCustomDiscountCode || '';

  const shippingCountry = multiCheckoutForm?.country;

  const showGst = shippingCountry === COUNTRY_NAMES.SINGAPORE;

  const {
    shippingFeeText,
    serviceFeeText,
    gstText,
    totalText,
    qcReportText,
  } = getSummaryLabels({
    showGst,
    gstPercent: checkoutPriceSummary?.gstPercent || 0,
  });

  const noOfSelectedQuoteText = checkoutPriceSummary.numOfSelectedQuotes > 1
    ? `${checkoutPriceSummary.numOfSelectedQuotes} quotes selected`
    : `${checkoutPriceSummary.numOfSelectedQuotes} quote selected`;

  const deliveryFeeComp = checkoutPriceSummary.totalCost !== 0
    && (checkoutPriceSummary.deliveryFee === 0
      || (
        checkoutPriceSummary.deliveryFee
        && checkoutPriceSummary.deliveryFee.split('').length > 1
        && checkoutPriceSummary.deliveryFee.split(' ') === '0.00'
      )
    )
    ? 'Free'
    : beautifyNumber(checkoutPriceSummary.deliveryFee);

  const totalCost = extractPriceFromCurrencyString(checkoutPriceSummary.totalCostInclGst);

  const originalTotal = extractPriceFromCurrencyString(checkoutPriceSummary?.totalCostBeforeDiscountStr) || 0;
  const totalDiscount = extractPriceFromCurrencyString(checkoutPriceSummary?.totalDiscountStr) || 0;
  const totalDiscountStr = checkoutPriceSummary?.totalDiscountStr;
  const totalDiscountText = `- ${totalDiscountStr}`;

  const {
    userCredits,
    creditBalanceAfter,
  } = useCreditsBalance(totalCost);

  const loadingSkeleton = () => (
    <Skeleton
      variant="text"
      width="5rem"
      height="1.5rem"
    />
  );

  const renderPromoCodeField = () => {
    if (creditType !== CUSTOMER_CREDIT_TYPES.NON_CREDIT
      || customerSettings?.seePromoCode !== 1
    ) {
      return;
    }

    return (
      <FtrShowHidePromoCodeField />
    );
  }

  return (
    <FtrBanner
      type='normal'
      style={{
        padding: '2rem 1.5rem',
        boxSizing: 'border-box',
      }}
    >
      <FtrHeading fontSize='18' style={{ color: colors.neutral080 }}>
        Order Summary
      </FtrHeading>
      <FtrBoldText fontSize='12' style={{ color: colors.headerGrey, marginTop: '0.5rem' }}>
        {noOfSelectedQuoteText}
      </FtrBoldText>
      <FlexColumn>
        <FlexRow style={{ justifyContent: 'space-between', marginTop: '1.5rem' }}>
          <FtrNormalText fontSize='12'>
            Subtotal
          </FtrNormalText>
          <FtrNormalText fontSize='12'>
            {pricingStatus === 'loading' ? loadingSkeleton() : beautifyNumber(checkoutPriceSummary.totalCost)}
          </FtrNormalText>
        </FlexRow>
        {checkoutPriceSummary.qcReportFee > 0 && (
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <FtrNormalText fontSize='12'>
              {qcReportText}
            </FtrNormalText>
            <FtrNormalText fontSize='12'>
              {pricingStatus === 'loading' ? loadingSkeleton() : beautifyNumber(checkoutPriceSummary.qcReportFeeStr)}
            </FtrNormalText>
          </FlexRow>
        )}
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <FlexRow>
            <FtrNormalText fontSize='12'>
              {shippingFeeText}
            </FtrNormalText>
            {shippingCountry === COUNTRY_NAMES.SINGAPORE && (
              <FtrInfoIconTooltip
                toolTipText={
                  `Free delivery for orders of more than ${convertPriceToCurrency({
                    price: 1000,
                    currency,
                    exchangeRate,
                  })}`
                }
                wrapText={false}
              />
            )}
          </FlexRow>
          <FtrNormalText fontSize='12'>
            {pricingStatus === 'loading' ? loadingSkeleton() : deliveryFeeComp}
          </FtrNormalText>
        </FlexRow>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <FlexRow>
            <FtrNormalText fontSize='12'>
              {serviceFeeText}
            </FtrNormalText>
            <FtrInfoIconTooltip
              toolTipText={
                `The service fee helps us maintain and enhance the Factorem manufacturing platform, ensuring a seamless experience for users like you. It covers the costs associated with providing support on-demand, server maintenance, software updates, security measures, and continuous improvements to provide you with a reliable and efficient manufacturing service.`
              }
            />
          </FlexRow>
          <FtrNormalText fontSize='12'>
            {pricingStatus === 'loading' ? loadingSkeleton() : beautifyNumber(checkoutPriceSummary.platformFeeStr)}
          </FtrNormalText>
        </FlexRow>
        {showGst && (
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <FtrNormalText fontSize='12'>
              {gstText}
            </FtrNormalText>
            <FtrNormalText fontSize='12'>
              {pricingStatus === 'loading' ? loadingSkeleton() : beautifyNumber(checkoutPriceSummary.gstInclAllFee)}
            </FtrNormalText>
          </FlexRow>
        )}
      </FlexColumn>
      {renderPromoCodeField()}
      {totalDiscount > 0 && (
        <FlexColumn style={{ marginTop: '1rem' }}>
          <Divider />
          <FlexRow style={{ justifyContent: 'space-between', marginTop: '0.5rem' }}>
            <FtrNormalText fontSize='12'>
              {totalText}
            </FtrNormalText>
            <FtrNormalText fontSize='12'>
              {pricingStatus === 'loading'
                ? loadingSkeleton()
                : `${getCurrencySymbol(currency)} ${beautifyNumber(originalTotal)}`
              }
            </FtrNormalText>
          </FlexRow>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <FtrNormalText fontSize='12'>
              Discount
            </FtrNormalText>
            <FtrNormalText fontSize='12'>
              {pricingStatus === 'loading'
                ? loadingSkeleton()
                : totalDiscountText
              }
            </FtrNormalText>
          </FlexRow>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <FtrNormalText fontSize='12'>
              Code
            </FtrNormalText>
            <FtrNormalText fontSize='12'>
              {pricingStatus === 'loading'
                ? loadingSkeleton()
                : factoremCustomDiscountCode
              }
            </FtrNormalText>
          </FlexRow>
        </FlexColumn>
      )}
      <FlexRow style={{ justifyContent: 'space-between', color: colors.headerGrey, marginTop: '1.5rem' }}>
        <FtrBoldText>
          {totalDiscount > 0
            ? 'Total payable.'
            : totalText
          }
        </FtrBoldText>
        <FtrHeading style={{ color: colors.neutral070 }} id='total-cost-value'>
          {pricingStatus === 'loading'
            ? loadingSkeleton()
            : checkoutPriceSummary.totalCostInclGstStr
          }
        </FtrHeading>
      </FlexRow>
      {userCredits > 0 && (
        <FlexColumn style={{ marginTop: '1.5rem', gap: 0 }}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <FtrNormalText fontSize='12'>
              Credit Available:
            </FtrNormalText>
            <FtrBoldText>
              {getCurrencySymbol(currency)} {beautifyNumber(parseFloat(userCredits).toFixed(2))}
            </FtrBoldText>
          </FlexRow>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <FtrNormalText fontSize='12'>
              Credit Balance (after transaction):
            </FtrNormalText>
            <FtrBoldText>
              {pricingStatus === 'loading'
                ? loadingSkeleton()
                : `${getCurrencySymbol(currency)} ${beautifyNumber(parseFloat(creditBalanceAfter).toFixed(2))}`
              }
            </FtrBoldText>
          </FlexRow>
        </FlexColumn>
      )}
    </FtrBanner>
  );
}

export default MultiCheckoutSummaryPanelV2;
