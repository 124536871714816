import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';
import queryString from 'query-string';

export const getListVersions = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configuration-inputs-versions/list-versions`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getVersionData = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configuration-inputs-versions/version-data?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const createNewVersion = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configuration-inputs-versions/create`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateCurrentVersion = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configuration-inputs-versions/version-data`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
export const getCurrentVersion = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configuration-inputs-versions/current-version`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const exportVersion = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configuration-inputs-versions/export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response.blob();
  });
};

export const revertVersion = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/configuration-inputs-versions/revert`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
