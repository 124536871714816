import { get, isEmpty } from "lodash";
import { CsvBuilder } from 'filefy';
import { generateCurrentCustomDateTimeString } from "../util";
import { notifyErrorContent } from "../services/notificationService";

export const transformCsvExportData = (columns, data) => {
  const initExportedData = Array.from(new Array(data.length), () => []);
  return columns.reduce((acc, column) => {
    if (!isEmpty(column.title) && column.export !== false) {
      acc.exportedColumns.push(column.title);
      for (let index = 0; index < data.length; index++) {
        const rowData = data[index];
        if (typeof column.exportData === 'function') {
          acc.exportedData[index].push(column.exportData(rowData));
        } else if (!isEmpty(column.field)) {
          acc.exportedData[index].push(get(rowData, column.field));
        } else if (typeof column.render === 'function') {
          acc.exportedData[index].push(column.render(rowData));
        } else {
          acc.exportedData[index].push('');
        }
      }
    }
    return acc;
  }, {
    exportedColumns: [],
    exportedData: initExportedData,
  });
}

export const transformCsvExportDataGrid = (columns, data) => {
  const initExportedData = Array.from(new Array(data.length), () => []);
  return columns.reduce((acc, column) => {
    if (!isEmpty(column.headerName) && column.export !== false) {
      acc.exportedColumns.push(column.headerName);
      for (let index = 0; index < data.length; index++) {
        const rowData = data[index];
        if (typeof column.valueGetter === 'function') {
          acc.exportedData[index].push(column.valueGetter({ row: rowData}));
        } else if (typeof column.exportData === 'function') {
          acc.exportedData[index].push(column.exportData({ row: rowData}));
        } else if (!isEmpty(column.field)) {
          acc.exportedData[index].push(get(rowData, column.field));
        } else if (typeof column.renderCell === 'function') {
          acc.exportedData[index].push(column.renderCell({ row: rowData}));
        } else {
          acc.exportedData[index].push('');
        }
      }
    }
    return acc;
  }, {
    exportedColumns: [],
    exportedData: initExportedData,
  });
}

/**
 * 
 * @param {*} columns 
 * @param {*} data 
 * @param {String} fileName 
 * @param {'data-grid'|'mui'} type 
 * @returns downloaded a file
 */
export const exportCSV = (columns, data, fileName = 'file', type = 'data-grid') => {
  const transformCsv =  type === 'data-grid' ? transformCsvExportDataGrid : transformCsvExportData;
  const { exportedColumns, exportedData } = transformCsv(
    columns,
    data
  );
  const builder = new CsvBuilder(`${fileName} ${generateCurrentCustomDateTimeString()}.csv`);
  builder
    .setDelimeter(',')
    .setColumns(exportedColumns)
    .addRows(exportedData)
    .exportFile();
};

/**
 * 
 * @param {Promise} funcPromiseApi 
 * @param {String} name 
 * @returns downloaded a file
 */
export const exportCsvApi = async (funcPromiseApi, name = 'file') => {
  try {
    const isPromise = funcPromiseApi instanceof Promise;
    if (!isPromise) {
      throw new Error("Not a Function Promise API!");
    }
    // Call the API to generate the CSV and get the download URL
    const response = await funcPromiseApi;
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    // Download the CSV file using the download URL
    const link = document.createElement('a');
    link.href = downloadUrl;
    const fileName = `${name} ${generateCurrentCustomDateTimeString()}.csv`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch(err) {
    notifyErrorContent("Export csv failed", err?.message || "Something went wrong")
  }
};