import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import DollarSymbolIcon from '../../assets/icons/dollar_symbol.svg';

const useStyles = ({ size }) => makeStyles(() => ({
  bounce: {
    animation: '$bounce 2s',
    animationIterationCount: 'infinite',
    width: size,
    height: size,
  },
  '@keyframes bounce': {
    '0%, 20%, 50%, 80%, 100%': {
      transform: 'translateY(0)',
    },
    '40%': {
      transform: 'translateY(-10px)',
    },
    '60%': {
      transform: 'translateY(-5px)',
    },
  },
}));

function LoadingPriceIcon(props) {
  const { size = 36, id } = props;
  const classes = useStyles({ size })();

  return (
    <img src={DollarSymbolIcon} className={classes.bounce} alt='dollar-symbol-icon' data-cy={id} id={id}/>
  );
}

export default LoadingPriceIcon;
