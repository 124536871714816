import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const supplierDataDashboardUploadCsv = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dashboard/suppliers/upload-csv`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: {
      Authorization: getAuthorizedHeader().Authorization,
    },
    body,
  }).then(async (response) => {
    const res = await response.json();
    if (!response.ok) {
      throw Error(res.message);
    }
    return res.data;
  });
};

export const getSingleSupplierDataDashboard = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dashboard/suppliers/${userID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getSupplierDashboardUploadHistory = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dashboard/suppliers/upload-history`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateInUseSupplierDashboardUploadHistory = async (uploadHistoryID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dashboard/suppliers/in-use/${uploadHistoryID}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateHasSeenSupplierDashboard = (supplierDashboardID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dashboard/suppliers/${supplierDashboardID}/has-seen`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
