import queryString from 'query-string';

import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const adminSearchXeroInvoiceItems = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items/search?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const adminSearchXeroInvoiceItemsAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items/search/csv-export?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const createXeroInvoiceItems = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    const respJson =  await response.json()
    if (!response.ok) {
      throw Error(respJson.message);
    }
    return respJson.data;
  });
};

export const updateXeroInvoiceItemsStatus = async (xeroInvoiceID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items/${xeroInvoiceID}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    const respJson = await response.json();
    if (!response.ok) {
      throw Error(respJson.message);
    }
    return respJson.data;
  });
};

export const regenerateS3PdfInvoice = async (id) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/xero/invoices/items/${id}/regenerate-s3-pdf`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    const respJson = await response.json();
    if (!response.ok) {
      throw Error(respJson.message);
    }
    return respJson.data;
  });
};
