export const getSummaryLabels = ({
  showGst = true,
  gstPercent = 0,
}) => {
  const shippingFeeText = showGst ? 'Shipping fees (excl. GST)' : 'Shipping fees';
  const serviceFeeText = showGst ? 'Service fees (excl. GST)' : 'Service fees';
  const gstText = `GST (${Number(gstPercent * 100).toFixed(2)}%)`;
  const totalText = showGst ? 'Total cost incl. GST' : 'Total cost';
  const qcReportText = showGst ? 'QC Report fee (excl. GST)' : 'QC Report fee';

  return {
    shippingFeeText,
    serviceFeeText,
    gstText,
    totalText,
    qcReportText,
  }
}
