import React from 'react';

import { TextField } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 10,
    '.MuiOutlinedInput-root': {
      borderRadius: 10,
    }
  },
}));

function FtrTextArea(props) {
  const classes = useStyles();

  return (
    <TextField
      classes={{
        root: classes.root,
      }}
      multiline
      minRows={3}
      variant="outlined"
      InputProps={{
        style: {
          borderRadius: 10,
        },
      }}
      {...props}
    />
  );
}

export default FtrTextArea;
