import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';
import queryString from 'query-string';

export const getAllCompanies = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/all-companies`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updateCompany = async (companyID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/update/${companyID}`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const getDifferentValuedCompanyAndUsers = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/different-values-company-and-users?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const createCompany = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/create`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const deleteCompany = async (companyID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/companies/delete/${companyID}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};