import { filter, get, isEmpty, isInteger } from "lodash";

import {
  compareStringsEqualsIgnoreCase,
  capitalizeString,
} from './stringUtils';
import { isEmptyValue } from "./commonUtils";
import { reverseMapObject } from "./objectUtils";
import { getItemHashcodeCad, getItemHashcodePdf, getReverseMaterialFromPpeMaterialCode } from "./ppeUtils";
import {
  compareDate,
  formatDeliveryDate,
} from "./dateTimeUtils";
import {
  isAnodizingSurfaceFinish,
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from "./inputUtils";
import {
  getFileNameFromUrl,
  getFileNameWithoutExtensionFromUrl,
} from './fileUtils';

import { surfaceFinishMapping, techMapping } from "../constants/PPEConstants";

import { FACTOREM_PARTNER } from "../constants/userConstant";
import {
  DEFAULT_LEAD_TIME,
  ITEM_STATUS_MAPPING,
  PPE_DEFAULT_LEAD_TIME,
  ROCKET_QUOTE_ACCEPT_TYPE,
} from '../constants';
import {
  DEFAULT_SHIP_MODE_LIST,
  ITEM_CANCELLATION_REASON,
  QUALITY_ITEM_LABELS,
} from '../constants/itemConstants';
import { PLACEHOLDER_IMAGE_URL } from "../constants/imageConstants";
import {
  ITEM_FILTER_STATUS_ARRAY,
  ITEM_STATUS_TEXT_MAPPING,
  PPE_ITEM_STAGE_STATUS,
  RFQ_ITEM_STATUS,
} from "../constants/itemStatus";
import {
  DEFAULT_SUPPORT_FILES,
  SUPPORT_FILES_TEXT_MAPPING,
  surfaceFinishOptions,
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_TECH_ABBRE_MAPPING,
  THREE_D_SURFACE_OPTIONS,
} from '../constants/NewPartConstants';

import {
  adminAllowedDeniedSuppliersForItem,
  getAllAvailableViewAsAccounts,
} from '../apis/itemApi';


// ------------------------------------------------------------------------------------------------

export const filterReadyForQuoteItems = (items) => {
  const validItems = filter(items, item => {
    if (item.cadFile == null) {
      return false;
    }
    return (item.status === ITEM_STATUS_MAPPING.SOURCING && item.price == null) ||
      (item.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE &&
        (item.price == null || item.rocketQuote)) ||
      (item.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS &&
        !item.rocketQuote &&
        !item.ppeQuoted &&
        item.price !== null &&
        new Date(item.datePosted) > new Date("2021-12-19")) ||
      (item.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS
        && item.rocketQuote && item.price && !item.ppeVerified
        && item.acceptQuoteType === ROCKET_QUOTE_ACCEPT_TYPE.PPE)
      ;
  });
  if (isEmpty(validItems)) {
    return [];
  }
  return validItems.sort((a, b) => {
    if (
      a.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS
      && b.status !== ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS
    ) {
      return -1;
    } else if (
      a.status !== ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS
      && b.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS
    ) {
      return 1;
    } else {
      return compareDate(b.verifiedDate, a.verifiedDate);
    }
  });
}

export const getItemImageUrl = (item) => {
  return item.imageFile && item.imageFile.startsWith('http')
    ? item.imageFile
    : (item.twoDImageUrl && item.twoDImageUrl.startsWith('http')
      ? item.twoDImageUrl
      : PLACEHOLDER_IMAGE_URL
    );
}

export const getItemNameFromCadFile = getFileNameWithoutExtensionFromUrl;

export const getFileNameFromCadFile = getFileNameFromUrl;

export const getPpeItemStageStatus = (status, ppeVerified) => {
  const stageStatus = status === 3
    ? 0
    : status === 1
      ? 1
      : status === 4 && ppeVerified === 0
        ? 1
        : status - 2;
  return stageStatus;
}

export const isPpeItem = (item) => {
  const isPpeItem = !isEmptyValue(item.isPPE)
    ? item.isPPE
    : !!item.price;
  return isPpeItem;
}

export const getItemStageStatusText = (item) => {
  const statusText = isPpeItem(item)
    ? PPE_ITEM_STAGE_STATUS[getPpeItemStageStatus(item.status, item.ppeVerified)]
    : RFQ_ITEM_STATUS[item.status];
  return statusText;
}

export const getProjectItemStatusFilterOrder = (status) => {
  let order = 0;
  for (; order < ITEM_FILTER_STATUS_ARRAY.length; order++) {
    if (ITEM_FILTER_STATUS_ARRAY[order].name === status) {
      return order;
    }
  }
  return order;
}

export const getDeliveryDateStr = (item) => {
  let estimated = true;
  let deliveryDate;
  if (item.actualDeliveryDate) {
    deliveryDate = item.actualDeliveryDate;
    estimated = false;
  } else if (item.deliveryDate) {
    deliveryDate = item.deliveryDate;
  }
  if (!deliveryDate) {
    return '';
  }
  const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
  const deliveryDateStr = new Date(deliveryDate).toLocaleDateString('en-GB', dateFormat);
  return estimated ? `${deliveryDateStr} (est.)` : deliveryDateStr;
}

const RFQ_ITEM_STATUS_PO_UPLOAD = [
  "Order In Progress",
  "Order Ready",
  "Delivered"
];

const PPE_ITEM_STAGE_STATUS_PO_UPLOAD = [
  "Processing",
  "Order In Progress",
  "Order Ready",
  "Delivered"
];

export const checkUploadPoItemStatusSupport = (item) => {
  const isSupport = isPpeItem(item)
    ? PPE_ITEM_STAGE_STATUS_PO_UPLOAD.includes(getItemStageStatusText(item))
    : RFQ_ITEM_STATUS_PO_UPLOAD.includes(getItemStageStatusText(item));
  return isSupport;
}

export const getSurfaceOptionsByTech = (tech) => {
  switch (tech) {
    case TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING:
      return THREE_D_SURFACE_OPTIONS;
    default:
      return surfaceFinishOptions;
  }
}

export const getTechnologyDisplayText = (item) => {
  return isCustomTechnology(item.technology)
    ? item.otherTechnology
    : item.technology;
}

export const getMaterialWithColorText = (item) => {
  const material = isCustomMaterial(item.material)
    ? item.otherMaterial || item.customMaterial
    : item.material || 'No material';
  return item.materialColor
    ? item.materialColor !== 'Custom Color'
      ? `${item.materialColor} ${material}`
      : `${item.customMaterialColor} ${material}`
    : material;
}

export const getSurfaceFinishWithCustomizationsText = (itemOrQuote) => {
  const surfaceFinish = isCustomSurfaceFinish(itemOrQuote.surfaceFinish)
    ? itemOrQuote.otherSurfaceFinish || itemOrQuote.customSurfaceFinish
    : itemOrQuote.surfaceFinish || 'No surface finish';

  // ASK: hide surface finish if it's nil
  // if (surfaceFinish === 'NIL') {
  //   return ''
  // }

  let anodizingTypeStr;

  if (isAnodizingSurfaceFinish(surfaceFinish)) {
    anodizingTypeStr = isEmptyValue(itemOrQuote?.metadata) ? itemOrQuote?.anodizingType : itemOrQuote.metadata['anodizingType'];
  }

  const anodizingType = isAnodizingSurfaceFinish(surfaceFinish)
    ? capitalizeString(anodizingTypeStr || '')
    : '';

  const color = itemOrQuote.color;
  const customColor = itemOrQuote.customColor;

  if (!isEmpty(color) && color !== 'Does not matter') {
    const colorText = color === 'Custom Color' ? customColor : color;
    return isEmpty(anodizingType)
      ? `${colorText} ${surfaceFinish}`
      : `${anodizingType} ${colorText} ${surfaceFinish}`;
  }

  return surfaceFinish;
};

export const is3DPTechnology = (technology) => {
  return technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING;
}

/**
 *
 * @param {*} item
 * @returns the actual material
 */
export const getItemMaterial = item => {
  return isCustomMaterial(item.material)
    ? item.otherMaterial || item.customMaterial
    : item.material;
}

/**
 *
 * @param {*} item
 * @returns the actual surface finish
 */
export const getItemSurfaceFinish = item => {
  return isCustomSurfaceFinish(item.surfaceFinish)
    ? item.otherSurfaceFinish || item.customSurfaceFinish
    : item.surfaceFinish || 'NIL';
}

export const getSupportFileTypes = (technology) => {
  const supportFilesObj = SUPPORT_FILES_TEXT_MAPPING[technology] || DEFAULT_SUPPORT_FILES;
  return supportFilesObj;
}

export const shouldShowInvoice = (item) => {
  if (isEmptyValue(item)) {
    return false;
  }

  const itemStatusText = getItemStageStatusText(item);
  return ![
    ITEM_STATUS_TEXT_MAPPING.CANCELLED,
    ITEM_STATUS_TEXT_MAPPING.VERIFYING,
  ].includes(itemStatusText);
}

export const getPpeUpdateHashcodeStatusText = (item) => {
  const { ppeUpdateHashcodeStatus } = item;
  if (isEmptyValue(ppeUpdateHashcodeStatus)) {
    return 'N.A.';
  }
  if (ppeUpdateHashcodeStatus.status === 200) {
    return 'Success';
  }
  return 'Fail';
}

export const displayLeadTime = (item) => {
  return isInteger(item.leadTime) && isInteger(item.markupLeadTime)
    ? `${Number(item.leadTime) + Number(item.markupLeadTime)} working days`
    : PPE_DEFAULT_LEAD_TIME;
}

export const getMinLeadTime = (itemList) => {
  let minLeadTime = DEFAULT_LEAD_TIME;
  for (const item of itemList) {
    const { leadTime } = item;
    if (Number.isInteger(Number(leadTime)) && Number(leadTime) < minLeadTime) {
      minLeadTime = Number(leadTime);
    }
  }
  return minLeadTime;
}

export const getMaxLeadTime = (itemList) => {
  let maxLeadTime = undefined;
  for (const item of itemList) {
    const { leadTime } = item;
    if (Number.isInteger(Number(leadTime)) &&
      (typeof maxLeadTime === 'undefined' || Number(leadTime) > maxLeadTime)) {
      maxLeadTime = Number(leadTime);
    }
  }
  return maxLeadTime;
}

/**
 * This function calculates and returns the latest collection date from a list of items.
 * It considers both the original 'collectionDate' and the revised 'revisedCollectionDate' if available.
 * If no collection date is found, it returns null.
 *
 * @param {Object[]} itemList - An array of item objects. Each item object should have 'collectionDate' and 'revisedCollectionDate' properties.
 * @returns {Date|null} - The latest collection date from the item list, or null if no collection date is found.
 */
export const getLatestCollectionDate = (itemList) => {
  let latestCollectionDate = undefined;
  for (const item of itemList) {
    const { collectionDate, revisedCollectionDate } = item;
    const _collectionDate = revisedCollectionDate ?? collectionDate;
    if (!isEmptyValue(_collectionDate) &&
      (typeof latestCollectionDate === 'undefined' || compareDate(_collectionDate, latestCollectionDate) > 0)) {
      latestCollectionDate = _collectionDate;
    }
  }
  return isEmptyValue(latestCollectionDate) ? null : latestCollectionDate;
}

/**
 * This function calculates and returns the latest collection date from a list of items.
 * It considers both the original 'collectionDate' and the revised 'revisedCollectionDate' if available.
 * If no collection date is found, it returns null.
 *
 * @param {Object[]} itemList - An array of item objects. Each item object should have 'collectionDate' and 'revisedCollectionDate' properties.
 * @returns {String|null} - The latest collection date from the item list, formatted as a string, or null if no collection date is found.
 */
export const getLatestCollectionDateStr = (itemList) => {
  const latestCollectionDate = getLatestCollectionDate(itemList);
  return isEmptyValue(latestCollectionDate) ? null : formatDeliveryDate(latestCollectionDate);
}

/**
 * This function calculates and returns the latest delivery date from a list of items.
 * It considers both the original 'deliveryDate' and the revised 'revisedDeliveryDate' if available.
 * If no delivery date is found, it returns null.
 *
 * @param {Object[]} itemList - An array of item objects. Each item object should have 'deliveryDate' and 'revisedDeliveryDate' properties.
 * @returns {Date|null} - The latest delivery date from the item list, or null if no delivery date is found.
 */
export const getLatestDeliveryDate = (itemList) => {
  let latestDeliveryDate = undefined;
  for (const item of itemList) {
    const { deliveryDate, revisedDeliveryDate } = item;
    const _deliveryDate = revisedDeliveryDate ?? deliveryDate;
    if (!isEmptyValue(_deliveryDate) &&
      (typeof latestDeliveryDate === 'undefined' || compareDate(_deliveryDate, latestDeliveryDate) > 0)) {
      latestDeliveryDate = _deliveryDate;
    }
  }
  return isEmptyValue(latestDeliveryDate) ? null : latestDeliveryDate;
}

export const getLatestDeliveryDateForMct = (itemList) => {
  let latestDeliveryDate = undefined;
  for (const item of itemList) {
    const { deliveryDate, actualDeliveryDate } = item;
    const _deliveryDate = actualDeliveryDate || deliveryDate;
    if (!isEmptyValue(_deliveryDate) &&
      (typeof latestDeliveryDate === 'undefined' || compareDate(_deliveryDate, latestDeliveryDate) > 0)) {
      latestDeliveryDate = _deliveryDate;
    }
  }
  return isEmptyValue(latestDeliveryDate) ? null : latestDeliveryDate;
}

/**
 * This function calculates and returns the latest delivery date from a list of items.
 * It considers both the original 'deliveryDate' and the revised 'revisedDeliveryDate' if available.
 * If no delivery date is found, it returns null.
 *
 * @param {Object[]} itemList - An array of item objects. Each item object should have 'deliveryDate' and 'revisedDeliveryDate' properties.
 * @returns {String|null} - The latest delivery date from the item list, formatted as a string, or null if no delivery date is found.
 */
export const getLatestDeliveryDateStr = (itemList) => {
  const latestDeliveryDate = getLatestDeliveryDate(itemList);
  return isEmptyValue(latestDeliveryDate) ? null : formatDeliveryDate(latestDeliveryDate);
}

export const getBulkPricingDiscount = (initialPrice, bulkPrice) => {
  return (initialPrice - bulkPrice) / initialPrice * 100;
}

export const getIsRepeatOrder = (instantQuotesLogMeta) => {
  const matchType = get(instantQuotesLogMeta, ['matched', 'match_type'])
    || get(instantQuotesLogMeta, 'match_type')
    || '';
  if (compareStringsEqualsIgnoreCase(matchType, 'ROM')) {
    return true;
  }

  return get(instantQuotesLogMeta, 'rom_order')
    || get(instantQuotesLogMeta, 'bool_repeat_order', false)
    || false;
}

export const getMatchedItemID = (instantQuotesLogMeta) => {
  return get(instantQuotesLogMeta, ['matched', 'part_id']);
}

export const getMatchedQuotationID = (instantQuotesLogMeta) => {
  return get(instantQuotesLogMeta, ['matched', 'quote_id']);
}

export const getIsSimilarOrder = (instantQuotesLogMeta) => {
  const matchType = get(instantQuotesLogMeta, ['matched', 'match_type'])
    || get(instantQuotesLogMeta, 'match_type')
    || '';
  if (compareStringsEqualsIgnoreCase(matchType, 'CSM')) {
    return true;
  }

  return get(instantQuotesLogMeta, 'csm_order', false) || false;
}

export const getDeliveryOptionTooltipText = (deliveryOption) => {
  return get(DEFAULT_SHIP_MODE_LIST, `${deliveryOption}.toolTipText`, '');
}

export const itemProperties = (item) => {
  const is3DPrinting = item?.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
  const technology = item?.technology
  const material = item?.customMaterial || item?.material
  const surfaceFinish = item?.surfaceFinish !== 'NIL' ? item?.customSurfaceFinish || item?.surfaceFinish : ''
  const quantity = `Qty: ${item?.quantity || item?.itemQuantity}`;
  const threeDPrintingTechnology = is3DPrinting ? THREE_D_P_TECH_ABBRE_MAPPING[item?.metadata?.threeDTechnology] : ''
  const str = [technology, material, surfaceFinish, threeDPrintingTechnology, quantity].filter(Boolean).join(', ');
  return str;
}

export const getCancelledItemInfo = (item, initialWords = '') => {
  if (item?.cancelledByName === FACTOREM_PARTNER && item?.cancellationReason === ITEM_CANCELLATION_REASON.EXPIRED) {
    return `Part expired`
  }
  return `${initialWords}${item.cancelledByName} (${item.cancelledBy})`
}

/**
 * To get ppeInfo if success and error
 * @param {*} item
 * @param {string} key
 * @returns Object of PpeInfo
 */
export const getPpeInfo = (item, key = 'instantQuotesLogMeta') => {
  if (!item) {
    return {};
  }
  let ppeInfo = item[key]?.response;
  // if there is no error, use additionalParameters
  if (!ppeInfo?.error) {
    ppeInfo = ppeInfo?.additionalParameters;
  }
  return ppeInfo || {}
}

/**
 *
 * @param {Object} item
 * @param {Object} properties
 * @param {Boolean} allowAll
 * @returns
 */
export const getQualityProperties = (item, properties, allowAll) => {
  if (!item) {
    return {};
  }
  const defaultProperties = properties ?? Object.keys(QUALITY_ITEM_LABELS);
  const qualityProperties = {};
  defaultProperties?.forEach((property) => {
    const isSFQuality = property.includes('SFQuality');
    const hasValidSurfaceFinish = !['NIL', '-', null, undefined].includes(
      item?.surfaceFinish
    );
    if (!isSFQuality || (isSFQuality && hasValidSurfaceFinish) || allowAll) {
      qualityProperties[property] = item[property];
    }
  });
  return qualityProperties;
};

/**
 * If the property owner is customer, it will return property for customer and supplier
 * If the property owner is partner, it will return property only partner
 * @param {String} name
 * @param {Boolean} checked
 * @returns
 */
export const getQualityPropertiesToUpdate = (name, checked) => {
  const properties = {
    [name]: checked ? 1 : 0,
  };
  // update partner properties
  if (name?.includes('customer')) {
    const partnerProp = name?.replace('customer', 'partner');
    properties[partnerProp] = checked ? 1 : 0;
  }
  return properties
}

const getAllowedDeniedSupplierForItemID = async itemID => adminAllowedDeniedSuppliersForItem(itemID);

/**
 * Given a list of itemIDs, returns a list of suppliers who can view any of the itemIDs
 * @param {*[]} itemIDs
 */
export const getAllowedSuppliersByItemIDs = async (itemIDs) => {
  if (isEmptyValue(itemIDs)) {
    return [];
  }
  const allowedSuppliersMap = new Map();
  const allowedDeniedSuppliersList = await Promise.all(itemIDs.map(getAllowedDeniedSupplierForItemID));
  const allowedSuppliersList = allowedDeniedSuppliersList
    .map(allowedDeniedSuppliers => allowedDeniedSuppliers.allowedSuppliers);
  for (const allowedSuppliersByItemID of allowedSuppliersList) {
    const hasAllSuppliersPool = allowedSuppliersByItemID
      .some(allowedSupplier => allowedSupplier === 'All suppliers pool');
    if (hasAllSuppliersPool) {
      return getAllAvailableViewAsAccounts();
    }
    allowedSuppliersByItemID.forEach(allowedSupplierForItem => {
      allowedSuppliersMap.set(allowedSupplierForItem.userID, allowedSupplierForItem);
    });
  }
  return Array.from(allowedSuppliersMap.values());
};

/**
 *
 * @param {{ userID: number }[]}} watchingList
 * @returns
 */
export const getTotalWatchingJobsByUserID = (watchingList) => {
  if (isEmptyValue(watchingList)) {
    return 0;
  }
  return [...new Set(watchingList?.map((watch) => watch.userID))].length;
};

export const isItemEditable = (item) => item.status === 5 || item.status === 6
  || (item.status === 1 && item.price === null);


/**
 * this fn will return the different owner information based on
 * the information of the api response
 * @see BE: /code/services/itemService#getItemWithAdditionalData
 * @param {Object} item
 * @returns {Object}
 */
export const getItemOwner = (item) => {
  let ownerID, ownerName, ownerCompany, ownerEmail = ''
  if (isEmptyValue(item)) {
    return {
      ownerID,
      ownerName,
      ownerEmail,
      ownerCompany,
    };
  }

  ownerID = item.userID;
  ownerCompany = item.cCompanyName || item.companyName || '';

  if (isEmpty(item.owners)) {
    ownerName = item.buyerName;
  } else {
    const owner = item.owners[0];
    ownerName = owner.ownerName;
    ownerEmail = owner.ownerEmail;
  }

  return {
    ownerID, ownerName, ownerEmail, ownerCompany
  }
}

export const buildItemFromPpeRequest = (ppeRequest, ppeResponse) => {
  const {
    process,
    parameters,
  } = ppeRequest || {};

  const { additionalParameters } = ppeResponse || {};

  const techMappingReverse = reverseMapObject(techMapping);
  const technology = techMappingReverse[process] || process;

  const surfaceFinishMappingReverse = reverseMapObject(surfaceFinishMapping);

  const imageFile = get(parameters, 'customer_image_file_location');

  return {
    technology,
    material: getReverseMaterialFromPpeMaterialCode({
      technology,
      material: parameters.material,
    }),
    tolerance: parameters.tolerance,
    quantity: parameters.quantity,
    surfaceFinish: surfaceFinishMappingReverse[parameters.finish] || parameters.finish,
    itemHashcodeCad: getItemHashcodeCad(additionalParameters),
    itemHashcodePdf: getItemHashcodePdf(additionalParameters),
    imageFile,
    quotation: {
      totalPrice: ppeResponse?.customerPrice,
    },
  }
}
