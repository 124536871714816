import { BACKEND_SERVICE_URL } from '../constants';
import { fetchWithErrorHandling, getAuthorizedHeader, witchCacheResponse } from '../utils/apiUtils';

export const getRfqEmailSchedulesOfProject = async (projectID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/projects/${projectID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const upsertScheduleConfig = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/configs`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const deleteScheduleConfig = async (configID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/configs/${configID}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const startScheduleConfig = async (configID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/configs/${configID}/start`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const updateRfqEmailSchedule = async (scheduleID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/schedules/${scheduleID}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const sendRfqEmailByScheduleID = async (scheduleID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/schedules/${scheduleID}/send`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

let currentController = new AbortController();
export const getSuppliersRankForItems = async (body) => {
  if (currentController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    currentController.abort();
  }
  currentController = new AbortController();
  const signal = currentController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/suppliers-rank`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
    signal,
  });
}

export const getSuppliersRankForItemsWithCache = witchCacheResponse(
  getSuppliersRankForItems,
  1 * 60 * 60, // 1 hour in seconds
);

export const abortGetSuppliersRankForItems = () => {
  if (currentController) {
    currentController.abort();
  }
}

let suppliersGroupsApiController = new AbortController();
export const getSuppliersGroupForItems = async (body) => {
  if (currentController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    currentController.abort();
  }
  suppliersGroupsApiController = new AbortController();
  const signal = suppliersGroupsApiController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/suppliers-group`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
    signal,
  });
}

export const submitFeedback = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/oms-tracking-feedback`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body)
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getFeedback = async (omsTrackingID) => {
  
  const requestUrl = `${BACKEND_SERVICE_URL}/rfq-email-schedules/oms-trackings/${omsTrackingID}/feedback`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const getSuppliersGroupForItemsWithCache = witchCacheResponse(
  getSuppliersGroupForItems,
  1 * 60 * 60, // 1 hour in seconds
);
