import React from 'react';

import { Tooltip } from '@material-ui/core';

import FtrSvgImage from '../images/FtrSvgImage';
import { FtrIconButton, FtrTypography } from '../ftr-components';
import { FlexRow } from '../layouts/FlexLayouts';

import UploadIcon from '../../assets/icons/upload_icon.svg';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------------------

/**
 *
 * @param {object} props
 * @param {string} props.inputID
 * @param {string} props.buttonText
 * @param {() => void} props.handleUploadFiles
 * @param {boolean} props.highlightButton
 * @param {boolean} props.disabled
 * @param {boolean} props.multiple
 * @param {string} props.accept
 * @param {string} props.tooltip
 */
function FilesUploadActionButtonV2(props) {
  const {
    inputID = 'file-upload',
    buttonText = 'Upload Files',
    handleUploadFiles = () => { },
    disabled = false,
    multiple = true,
    accept = "*",
    tooltip = '',
  } = props;

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      <input
        style={{
          display: "none",
        }}
        ref={hiddenFileInput}
        id={inputID}
        type="file"
        multiple={multiple}
        onChange={evt => handleUploadFiles(evt.target.files)}
        onClick={(event) => event.target.value = null}
        disabled={disabled}
        accept={accept}
      />
      <Tooltip
        title={tooltip}
      >
        <label
          htmlFor={inputID}
        >
          <FtrIconButton
            onClick={handleClick}
            variant='outlined'
          >
            <FlexRow style={{ marginLeft: 4, marginRight: 4 }}>
              <FtrSvgImage
                src={UploadIcon}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
              <FtrTypography fontSize='16' style={{ fontWeight: 600, color: colors.blue060 }}>
                {buttonText}
              </FtrTypography>
            </FlexRow>
          </FtrIconButton>
        </label>
      </Tooltip>
    </div>
  );
}

export default FilesUploadActionButtonV2;
