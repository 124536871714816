import React from 'react';

import { withStyles } from '@material-ui/core';

import { FtrTypography } from '.';

import { colors } from '../../palette';

const FtrFieldLabel = withStyles({
  root: {
    padding: '0.3rem 0',
    color: colors.neutral070,
  },
})((props) => (
  <FtrTypography
    type='heading'
    fontSize='16'
    {...props}
  />
));

export default FtrFieldLabel;
