// Import settings
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from '@material-ui/icons';

// Import color palette
import { colors } from "../../palette";

// Import material UI components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

import { FtrTypography } from "../ftr-components";
import SelectTechnologies from "../selects/SelectTechnologies";

// Style the components
const useStyles = makeStyles(() => ({
  paper: {
    padding: '1rem 0',
    borderRadius: '30px',
    boxShadow: '0px 10px 40px 0px #00000026',
    backgroundColor: colors.neutral010,
    overflowY: 'hidden',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function ChangeTechnologyPopup(props) {
  const classes = useStyles();
  const { open, onClose, technology, retrieveParams } = props;

  const [params, setParams] = useState({ technology: technology });

  useEffect(() => {
    if (!open) {
      return;
    }
  }, [open]);

  useEffect(() => {
    retrieveParams(params);
  }, [params]);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="change-technology-popup"
      classes={{ paper: classes.paper }}
      BackdropProps={{
        style: {
          backgroundColor: colors.blackTint020
        }
      }}
    >
      <DialogTitle id="change-technology-title">
        <FtrTypography type='heading' fontSize='24'>
          Change Technology
        </FtrTypography>
      </DialogTitle>
      <DialogContent style={{ color: colors.neutral070 }}>
        This change only impacts new uploads. For existing parts, update the technology on the part configuration page.
      </DialogContent>
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
      <DialogActions style={{ padding: '2rem', justifyContent: "space-around" }}>
        <SelectTechnologies
          id="select-technology"
          width='long'
          selectedTechnology={technology}
          onChange={setParams}
          isPopup
        />
      </DialogActions>
    </Dialog>
  );
}

export default ChangeTechnologyPopup;
