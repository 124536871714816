import React from 'react';

import { FtrBoldText, FtrNormalText } from './ftr-components';
import { FlexColumn, FlexRow } from './layouts/FlexLayouts';

import { colors } from '../palette';


// ------------------------------------------------------------------------------

function ContactSupport(props) {
  const {
    text = 'Need help with your quote?',
    onClick = () => { },
    displayIn = 'column', // 'column' | 'row'
  } = props;

  const Layout = displayIn === 'column' ? FlexColumn : FlexRow;

  return (
    <Layout style={{ gap: displayIn === 'row' ? 4 : '0.5rem' }}>
      <FtrNormalText>
        {text}
      </FtrNormalText>
      <FtrBoldText
        style={{
          color: colors.titleBlue,
          cursor: 'pointer',
          width: 'fit-content',
        }}
        onClick={onClick}
      >
        Contact Support
      </FtrBoldText>
    </Layout>
  );
}

export default ContactSupport;
