import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import CADRendererAINC from '../../lib/ai-nc/CADRendererAINC';
import { makeStyles } from '@material-ui/core/styles/index';

import Title from '../Title';
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: '2000 !important',
  },
  paper: {
    width: '50rem',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  dialogContent: {
    padding: '0 3rem',
    paddingBottom: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0.5rem',
    },
  },
}));

function CADRendererPopup(props) {
  const classes = useStyles();
  const { onClose, cadRendererAINC } = props;
  const { isOpen, file, urlFile, loadFirstTime } = cadRendererAINC;

  useEffect(() => {
    const timer = setTimeout(() => {
      let body = document.querySelector('body');
      // only will hidden if the popup is opened
      const value = isOpen ? 'hidden' : 'visible';
      body.style.setProperty('overflow', value);
    }, 500);
    return () => clearTimeout(timer);
  }, [isOpen]);

  if (!loadFirstTime) return null;

  return (
    <Dialog
      maxWidth='xl'
      open={true}
      onClose={onClose}
      aria-labelledby='3d-cad-renderer-popup'
      classes={{ paper: classes.paper, root: classes.root }}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <DialogTitle id='3d-cad-renderer-popup'>
        <Title contentTitle='3D CAD Renderer' size='small' />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <CADRendererAINC disableInput file={file} urlFile={urlFile} />
        </div>
      </DialogContent>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    cadRendererAINC: state.cadRendererAINC,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(CADRendererPopup));
