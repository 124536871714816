import React, { useEffect, useReducer } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { getAllAvailableViewAsAccounts } from '../../apis/itemApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { groupUsersByKeyForDropDown } from '../../utils/selectUserUtils';
import { getAllowedSuppliersByItemIDs } from '../../utils/itemUtils';

import BlueButton from '../buttons/BlueButton';
import MultiSelectChipDropdownWithCategory from '../dropdowns/MultiSelectChipDropdownWithCategory';
import Title from '../Title';
import WhiteButton from '../buttons/WhiteButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 400,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
}));

function ViewAsAccountPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose = () => { },
    handleOk = () => { },
    itemIDs = [],
  } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      userList: [],
      availableAccounts: [],
      selectedAccountIds: [],
    }
  );

  const loadAvailableAccounts = async () => {
    const suppliersList = await (isEmptyValue(itemIDs) ? getAllAvailableViewAsAccounts() : getAllowedSuppliersByItemIDs(itemIDs));
    updateLocalState({
      userList: suppliersList,
      availableAccounts: groupUsersByKeyForDropDown(suppliersList),
    });
  }

  useEffect(() => {
    loadAvailableAccounts();
  }, []);

  const onSupplierSelectChange = (newValues) => {
    updateLocalState({
      selectedAccountIds: newValues,
    });
  }

  const renderBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <MultiSelectChipDropdownWithCategory
          id="multi-select-suppliers"
          label="Select supplier"
          itemList={localState.availableAccounts}
          value={localState.selectedAccountIds}
          onSelect={onSupplierSelectChange}
          searchable
          multiple={false}
        />
      </div>
    );
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Title
          contentTitle="Select account to view"
          size="small"
        />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          <React.Fragment>
            {renderBody()}
          </React.Fragment>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          <WhiteButton
            onBtnClick={handleClose}
            btnContent='Cancel'
            type='cancelEdit'
          />
          <BlueButton
            onBtnClick={() => {
              const accountID = localState.selectedAccountIds[0];
              const user = localState.userList.find(user => user.userID === accountID);
              handleOk({
                ...user,
                displayName: `${user.name} <${user.email}>`,
              });
              handleClose();
            }}
            btnContent="OK"
            disabled={isEmptyValue(localState.selectedAccountIds)}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default ViewAsAccountPopup;
