import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ceil, isEmpty, orderBy } from "lodash";

import { makeStyles } from '@material-ui/core/styles/index';

import { Chip, Tooltip } from "@material-ui/core";

import { DataGrid } from "@mui/x-data-grid";

import { Alert } from "@material-ui/lab";

import BlueButton from "../buttons/BlueButton";
import CustomToolbar from "../grid-data/CustomToolbar";
import DataGridWrapTextCell from "./cells/DataGridWrapTextCell";
import DateFormatMethod from "../DateFormatMethod";
import GreyButton from "../buttons/GreyButton";
import HorizontalCarousel from "../images/HorizontalCarousel";
import ProjectItemStatusInfo from "../info/ProjectItemStatusInfo";
import SearchBar from "../grid-data/buttons/SearchBar";
import GridDataPagination from "../grid-data/GridDataPagination";

import { useDataGridFilterHook } from "../../hooks/useDataGridFilterHook";

import { getItemStageStatusText, getProjectItemStatusFilterOrder } from "../../utils/itemUtils";
import { includesIgnoreCase } from "../../utils/stringUtils";
import { isEmptyValue } from "../../utils/commonUtils";

import { colors } from "../../palette";

const useStyles = makeStyles(() => ({
  body: {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-root': {
      border: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  customerPOChip: {
    backgroundColor: colors.successGreen,
    color: 'white',
  },
}));

function CustomerProjectsDataGrid(props) {
  const classes = useStyles();

  const { data } = props;

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    },
  );
  const [
    filteredData,
    {
      setSearchStr,
      setColumnsDef,
      setSourceData,
    }
  ] = useDataGridFilterHook({
    search: '',
    source: [],
  });

  useEffect(() => {
    setSourceData(data);
  }, [data]);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData.length });
  }, [filteredData]);

  const handleRowClick = rowData => {
    props.history.push({
      pathname: `/manage-projects/${rowData.projectID}`,
      state: { projectID: rowData.projectID }
    });
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const columns = [
    {
      title: "Project ID",
      headerName: "Project ID",
      field: "projectID",
      align: "center",
      cellStyle: {
        width: "10%",
        maxWidth: "50px"
      },
      minWidth: 100,
      flex: 'auto',
    },
    {
      title: "Project Name",
      headerName: "Project Name",
      field: "name",
      align: "center",
      cellStyle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "150px"
      },
      valueGetter: ({ row: rowData }) => {
        if (isEmptyValue(rowData.customerPOs)) {
          return rowData.name;
        }
        return `${rowData.name},${rowData.customerPOs.join(',')}`;
      },
      renderCell: ({ row: rowData }) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '0.5rem',
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            <DataGridWrapTextCell text={rowData.name} />
            {!isEmptyValue(rowData.customerPOs) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '0.5rem',
                  width: '100%',
                  flexWrap: 'wrap',
                }}
              >
                {rowData.customerPOs?.map((po) => {
                  return (
                    <Tooltip
                      title="PO"
                      key={po}
                    >
                      <Chip
                        className={classes.customerPOChip}
                        label={po}
                        size="small"
                      />
                    </Tooltip>
                  );
                })}
              </div>
            )}
          </div>
        );
      },
      minWidth: 180,
      flex: 0.5,
    },
    {
      title: "Parts",
      headerName: "Parts",
      field: 'parts',
      align: "center",
      sorting: false,
      customFilterAndSearch: (term, rowData) => {
        const data = isEmpty(rowData.items) ? '' : rowData.items.map(item => item.itemID).join(' ');
        return includesIgnoreCase(data, term);
      },
      renderCell: ({ row: rowData }) => {
        return <HorizontalCarousel urls={rowData.twoDImageUrls} />
      },
      minWidth: 400,
      flex: 1,
    },
    {
      title: "Part Names",
      headerName: "Part Names",
      field: 'partNames',
      valueGetter: ({ row: rowData }) => {
        const statusText = rowData.items.map(item => item.name).join(', ');
        return statusText;
      },
      hide: true,
    },
    {
      title: "Part IDs",
      headerName: "Part IDs",
      field: 'partIDs',
      valueGetter: ({ row: rowData }) => {
        const statusText = rowData.items.map(item => item.itemID).join(', ');
        return statusText;
      },
      hide: true,
    },
    {
      title: "Date created",
      headerName: "Date created",
      field: "dateCreated",
      align: "center",
      renderCell: ({ row: rowData }) => {
        return rowData.dateCreated ? (
          <DataGridWrapTextCell text={DateFormatMethod({ date: rowData.dateCreated })} />
        ) : (
          ""
        )
      },
      minWidth: 120,
      flex: 'none',
    },
    {
      title: "Progress",
      headerName: "Progress",
      field: "progress",
      align: "center",
      sorting: false,
      valueGetter: ({ row: rowData }) => {
        const statusText = rowData.items.map(item => getItemStageStatusText(item)).join(', ');
        return statusText;
      },
      renderCell: ({ row: rowData }) => {
        const statusMapping = rowData.items.reduce((acc, item) => {
          const statusText = getItemStageStatusText(item);
          const statusCount = acc[statusText] || 0;
          acc[statusText] = statusCount + 1;
          return acc;
        }, {});
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {orderBy(Object.entries(statusMapping), [
              (statusMappingObj) => {
                return getProjectItemStatusFilterOrder(statusMappingObj[0]);
              }
            ]).map(([key, value]) => {
              return (
                <ProjectItemStatusInfo
                  key={key}
                  text={key}
                  numOfItems={value}
                />
              );
            })}
          </div>
        );
      },
      minWidth: 180,
      flex: '1 auto',
    },
    {
      title: "",
      headerName: " ",
      field: "button",
      renderCell: ({ row: rowData }) => {
        const button = ["Open", "Completed"].includes(rowData.status) ? (
          <BlueButton
            onBtnClick={() => handleRowClick(rowData)}
            btnContent="View"
            size="small"
            disabled={false}
          />
        ) : (
          <GreyButton
            onBtnClick={() => handleRowClick(rowData)}
            btnContent="View"
            size="small"
            disabled={true}
          />
        );
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {button}
          </div>
        )
      },
      minWidth: 150,
      flex: 0.5,
    }
  ];

  useEffect(() => {
    setColumnsDef(columns);
  }, []);

  const getCustomerToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          flexWrap: 'wrap',
        }}
      >
        <span style={{ flexGrow: '1 auto' }}></span>
        <CustomToolbar
          buttons={[
            <SearchBar
              key="search"
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
          ]}
        />
      </div>
    );
  }

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        rows={filteredData}
        columns={columns.map(col => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.projectID}
        rowHeight={140}
        headerHeight={80}
        pageSize={tableQueryParams.pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
            />
          ),
          NoRowsOverlay: () => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 'auto',
              }}
            >
              <Alert severity="info" style={{ textAlign: 'center' }}>
                Seems like your Projects are playing hide and seek!
                <br />
                <br />
                No Projects found.
                ‌</Alert>
            </div>
          ),
        }}
        onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
        disableSelectionOnClick
        disableColumnMenu
        onRowClick={({ row: rowData }) => {
          handleRowClick(rowData);
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(CustomerProjectsDataGrid));
