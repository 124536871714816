import React from 'react';

import FtrTooltip from './FtrTooltip';

import { ReactComponent as InfoIcon } from '../../assets/icons/info_icon_blue.svg';

/**
 *
 * @param {Object} props
 * @param {String} props.toolTipText
 * @param {"top" | "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start"} props.placement
 * @param {React.CSSProperties} props.style
 * @returns
 */
function FtrInfoIconTooltip(props) {
  const {
    toolTipText,
    placement = 'top',
    style,
    wrapText,
    ...rest
  } = props;

  return (
    <FtrTooltip
      description={toolTipText}
      placement={placement}
      arrow
      wrapText={wrapText}
      {...rest}
    >
      <InfoIcon style={style} {...rest} />
    </FtrTooltip>
  );
}

export default FtrInfoIconTooltip;
