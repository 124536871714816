import { Cookies } from "react-cookie";
import { toast } from "react-toastify";

export const GET_PDFS_LOADING = "GET_PDFS_LOADING";
export const GET_PDFS_SUCCESS = "GET_PDFS_SUCCESS";
export const GET_PDFS_FAILURE = "GET_PDFS_FAILURE";
export const CREATE_PDF_SUCCESS = "CREATE_PDF_SUCCESS";
export const CREATE_PDF_LOADING = "CREATE_PDF_LOADING";
export const CREATE_PDF_FAILURE = "CREATE_PDF_FAILURE";

const getPdfsLoading = () => {
  return {
    type: GET_PDFS_LOADING
  };
};

const getPdfsSuccess = pdfs => {
  return {
    type: GET_PDFS_SUCCESS,
    pdfs
  };
};

const getPdfsFailure = () => {
  return {
    type: GET_PDFS_FAILURE
  };
};

const createPdfLoading = () => {
  return {
    type: CREATE_PDF_LOADING,
  };
};

const createPdfSuccess = () => {
  return {
    type: CREATE_PDF_SUCCESS,
  };
};

const createPdfFailure = () => {
  toast.error("Unable to create pdf. Please try again later.", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: CREATE_PDF_FAILURE
  };
};

const cookies = new Cookies();

export const getPdfByType = type => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/pdf/?type=${type}`;
  fetch(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${cookies.get("token")}`
    }
  })
    .then(function (response) {
      dispatch(getPdfsLoading());
      response.json().then(response => {
        dispatch(getPdfsSuccess(response.data));
      });
    })
    .catch(function () {
      dispatch(getPdfsFailure());
    });
};

export const createPdf = ({ itemIDs, date, type, refClientPO }) => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/pdf/create`;
  const payload = {
    itemIDs,
    pdfDate: date,
    type,
    refClientPO,
  };
  dispatch(createPdfLoading());
  return fetch(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${cookies.get("token")}`
    },
    body: JSON.stringify(payload)
  })
    .then(function (response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      response.json().then(() => {
        dispatch(createPdfSuccess());
      });
    })
    .catch(function () {
      dispatch(createPdfFailure());
    });
};
