import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import BootstrapInput from './BootstrapInput';
import BootstrapInputSmall from './BootstrapInputSmall';

import { colors } from '../../palette';
import inputsStyles from './inputsStyles';
import { renderSkeletonOverlay } from '../util/skeleton';


const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '30%',
    width: 150,
    minWidth: 150,
    position: 'relative'
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
    position: 'relative'
  },
}));

function ThreeDInfillSelectField(props) {
  const classes = useStyles();
  const inputStyles = inputsStyles()

  // Defining input styles
  classes.emptyField = inputStyles.emptyField
  classes.v2 = inputStyles.v2

  const {
    visible = false,
    ppe3dpInfillOptions = [],
    onSelect,
    value,
    rowDisplay = false,
    bootstrapStyle = true,
    disabled = false,
    disableLabelOnBootstrapStyle = false,
    v2 = false,
    loading = false,
  } = props;

  if (!visible) {
    return null;
  }


  if (bootstrapStyle) {
    return (
      <div
        className={rowDisplay ? classes.rowDisplay : classes.container}
      >
        {loading && renderSkeletonOverlay()}
        {!disableLabelOnBootstrapStyle && (
          <InputLabel
            className={
              rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel
            }
          >
            3D Infill
          </InputLabel>
        )}
        <Select
          id='3d-infill'
          className={`${classes.selectField} ${v2 ? classes.v2 : ''} ${
            !value ? classes.emptyField : ''
          }`}
          input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
          value={value ?? null}
          onChange={(evt) => onSelect(evt.target.value)}
          disabled={disabled}
          renderValue={(value) =>
            value ? `${Math.round(value * 100)}%` : '3D Infill'
          }
          displayEmpty
        >
          {ppe3dpInfillOptions.map((option) => {
            const displayedText = `${Math.round(option * 100)}%`;
            return (
              <MenuItem key={displayedText} value={option}>
                {displayedText}
              </MenuItem>
            )
          })}
        </Select>
      </div>
    );
  }

  return (
    <TextField
      select
      label="3D Infill"
      variant="outlined"
      onChange={(evt) => onSelect(evt.target.value)}
      value={value}
      margin="dense"
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      disabled={disabled}
    >
      {ppe3dpInfillOptions.map((option) => {
        const displayedText = `${Math.round(option * 100)}%`;
        return (
          <MenuItem key={option} value={option}>
            {displayedText}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export default ThreeDInfillSelectField;
