import { useCallback, useEffect, useState } from 'react';
import { debounce, get } from 'lodash';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

export const useDataGridFilterHook = ({
  search,
  source = [],
  columns = [],
}) => {
  const [sourceData, setSourceData] = useState(source);
  const [filteredData, setFilteredData] = useState(source);
  const [searchStr, setSearchStr] = useState(search);
  const [columnsDef, setColumnsDef] = useState(columns);
  const [filtering, setFiltering] = useState(false);

  const triggerSearch = useCallback(
    debounce((searchStr) => {
      if (isEmptyValue(searchStr) || searchStr.length < 3) {
        setFilteredData(sourceData);
        setFiltering(false);
        return;
      }

      const _filteredData = sourceData?.filter(data => {
        for (const col of columnsDef) {
          const valueGetterFunc = col.valueGetter;
          let value = ''
          if (typeof valueGetterFunc === 'function') {
            value = valueGetterFunc({ row: data }) ?? '';
            value = String(value).toLowerCase();
          } else {
            value = `${get(data, col.field ?? '') ?? ''}`.toLowerCase();
          }
          if (value.includes(searchStr.toLowerCase())) {
            return true;
          }
        }
        return false;
      });

      setFilteredData(_filteredData);
      setFiltering(false);
    }, 500), // 500ms delay
    [sourceData]
  );

  useEffect(() => {
    setFiltering(true);
    triggerSearch(searchStr);
  }, [
    searchStr,
    columnsDef,
    sourceData,
    triggerSearch,
  ]);

  return [
    filteredData,
    {
      filtering,
      setSearchStr,
      setColumnsDef,
      setSourceData,
    },
  ]
}
