export const UPDATE_FILTER_STATUS_BROWSE = 'UPDATE_FILTER_STATUS_BROWSE';
export const UPDATE_SEARCH_KEYWORD_BROWSE = 'UPDATE_SEARCH_KEYWORD_BROWSE';
export const UPDATE_ALL_FILE_TYPES_SUPPORT = 'UPDATE_ALL_FILE_TYPES_SUPPORT';

export const updateFilterBrowse = (payload) => {
  return {
    type: UPDATE_FILTER_STATUS_BROWSE,
    payload,
  };
};

export const updateKeywordBrowse = (payload) => {
  return {
    type: UPDATE_SEARCH_KEYWORD_BROWSE,
    payload,
  };
};

export const updateAllFilesTypeSupport = (payload) => {
  return {
    type: UPDATE_ALL_FILE_TYPES_SUPPORT,
    payload,
  };
};
