import useUserDeliveryInfo from './useUserDeliveryInfo';

import { generateDeliveryMessage } from '../utils/multiCheckoutTrackingUtils';
import { addressInfoText } from '../utils/addressUtils';
import { getDefaultShippingDeliveryInfo } from '../utils/userUtils';
import { isEmptyValue } from '../utils/commonUtils';

const useDeliveryMessageConfig = ({
  selectedItemsIDs,
  trackingInfo,
  pickUpUserInfo,
  dropOffUserInfo,
  deliveryMessageToggles,
  isShippingToCustomer,
  invoiceAmountStr,
}) => {
  const {
    packaging: { isChecked: isPackagingRequired },
    isQCRequired: { isChecked: isQCRequired },
    isDHLShipping: { isChecked: isDHLInternationalShipping },
    isDescriptionRequired: { isChecked: isDescriptionRequired },
  } = deliveryMessageToggles;

  const { data: dropOffUserDeliveryInfo, isLoading: isLoadingDropOffUser } =
    useUserDeliveryInfo(dropOffUserInfo?.userID);
  const { data: pickUpUserDeliveryInfo, isLoadingPickUpUser } =
    useUserDeliveryInfo(pickUpUserInfo?.userID);

  if (isLoadingPickUpUser || isLoadingDropOffUser) {
    return {
      deliveryMessage: '',
      errorMessage: '',
    };
  }

  const pickUpUserAddress = addressInfoText(
    getDefaultShippingDeliveryInfo(pickUpUserInfo, pickUpUserDeliveryInfo)
  );

  const dropOffUserAddress = addressInfoText(
    getDefaultShippingDeliveryInfo(dropOffUserInfo, dropOffUserDeliveryInfo)
  );

  const deliveryMessage = generateDeliveryMessage({
    selectedItemsIDs,
    trackingInfo,
    pickUpUserInfo: {
      ...pickUpUserInfo,
      address: pickUpUserAddress,
    },
    dropOffUserInfo: {
      ...dropOffUserInfo,
      address: dropOffUserAddress,
    },
    isPackagingRequired,
    isQCRequired,
    isDHLInternationalShipping,
    isDescriptionRequired,
    isShippingToCustomer,
    invoiceAmountStr,
  });

  const errorMessage = isEmptyValue(pickUpUserAddress) || isEmptyValue(dropOffUserAddress)
    ? 'The pickup/dropoff address was not found, please edit the delivery message.'
    : '';

  return {
    deliveryMessage,
    errorMessage,
  }
};

export default useDeliveryMessageConfig;
