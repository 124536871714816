import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FlexColumn } from '../layouts/FlexLayouts';
import FtrButton from './FtrButton';
import FtrPromoCodeField from './FtrPromoCodeField';
// import FtrAvailablePromoCodes from './FtrAvailablePromoCodes';

import { setPromoCodeAction } from '../../actions/multiCheckoutForm';

import { isEmptyValue } from '../../utils/commonUtils';

import {
  getMultiCheckoutFormPromoCodeSelector,
  getMultiCheckoutFormSelector,
} from '../../selectors/multiCheckoutFormSelector';


// -------------------------------------------------------------------------------------------------

function FtrShowHidePromoCodeField() {
  const dispatch = useDispatch();

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);
  const factoremCustomDiscountCode = useSelector(getMultiCheckoutFormPromoCodeSelector);
  const { status: multiCheckoutFormStatus } = multiCheckoutForm;
  const loading = multiCheckoutFormStatus === 'loading';

  const [show, setShow] = React.useState(false);

  const title = useMemo(() => {
    if (!isEmptyValue(factoremCustomDiscountCode)) {
      return 'Remove promo code';
    }

    if (show) {
      return 'Hide promo code';
    }

    return 'Apply promo code';
  }, [
    factoremCustomDiscountCode,
    show,
  ]);

  useEffect(() => {
    if (!isEmptyValue(factoremCustomDiscountCode)) {
      setShow(true);
    }
  }, [factoremCustomDiscountCode]);

  const handleButtonClick = () => {
    if (!isEmptyValue(factoremCustomDiscountCode)) {
      dispatch(setPromoCodeAction(''));
      setShow(false);
      return;
    }

    setShow(prev => !prev);
  }

  return (
    <FlexColumn style={{ gap: 0, marginTop: '0.5rem' }}>
      <div>
        <FtrButton
          variant='text'
          color='blue'
          style={{ paddingLeft: 0 }}
          onClick={handleButtonClick}
          disabled={loading}
        >
          {title}
        </FtrButton>
      </div>
      {show && <FtrPromoCodeField disabled={loading} />}
      {/* {!loading && <FtrAvailablePromoCodes />} */}
    </FlexColumn>
  );
}

export default FtrShowHidePromoCodeField;
