import { isEmpty } from 'lodash';

export const PROD_ENV = 'prod';
export const STAGING_ENV = 'staging';

export const REACT_APP_ENV = process.env.REACT_APP_ENV;

export const BACKEND_SERVICE_URL = process.env.REACT_APP_BACKEND_SERVICE;
if (isEmpty(BACKEND_SERVICE_URL)) {
  console.warn(`REACT_APP_BACKEND_SERVICE is not defined.`);
}

export const CADPART_DIRNAME = process.env.REACT_APP_CADPART_DIRNAME;
if (isEmpty(CADPART_DIRNAME)) {
  console.warn(`REACT_APP_CADPART_DIRNAME is not defined.`);
}

export const S3_BUCKETNAME = process.env.REACT_APP_BUCKETNAME;
if (isEmpty(S3_BUCKETNAME)) {
  console.warn(`REACT_APP_BUCKETNAME is not defined.`);
}

export const CRISP_CHAT_ID = process.env.REACT_APP_CRISP_CHAT_ID;
if (isEmpty(CRISP_CHAT_ID)) {
  console.warn(`CRISP_CHAT_ID is not defined.`);
}

export const REACT_APP_IMGPART_DIRNAME = process.env.REACT_APP_IMGPART_DIRNAME;
if (isEmpty(REACT_APP_IMGPART_DIRNAME)) {
  console.warn(`REACT_APP_IMGPART_DIRNAME is not defined.`);
}

export const PROFILEPIC_DIRNAME = process.env.REACT_APP_PROFILEPIC_DIRNAME;
if (isEmpty(PROFILEPIC_DIRNAME)) {
  console.warn(`REACT_APP_PROFILEPIC_DIRNAME is not defined.`);
}

export const BE_API_KEY = process.env.REACT_APP_BE_API_KEY;
if (isEmpty(BE_API_KEY)) {
  console.warn(`REACT_APP_BE_API_KEY is not defined.`);
}

export const BOM_DIR_NAME = 'BOMFiles';
export const CUSTOMER_PO_DIR_NAME = 'CustomerPoFiles';
export const CUSTOMER_NDA_DIR_NAME = 'CustomerNDAFiles';
export const SUPPLIER_PO_TERMS_DIR_NAME = 'SupplierPoTermsFiles';
export const SUPPLIER_PARTNERSHIP_AGREEMENT_DIR_NAME = 'SupplierPartnershipAgreementFiles';
export const CUSTOMER_QUOTATION_TERMS_DIR_NAME = 'CustomerQuotationTermsFiles';
export const S3_PUBLIC_FILES_DIR_NAME = 'Public';
export const S3_ORDER_READY_IMG_DIR_NAME = 'OrderReady/Images';
export const SUPPLIER_INVOICE_DIR_NAME = 'SupplierInvoice';
export const TECHNICAL_DRAWING_EXTRACTOR_UPLOADED_PDF_DIR_NAME = 'TechnicalDrawingExtraction/UploadedPdf';
export const PO_FORM_DIR_NAME = 'PurchaseOrderForms';
export const FLATTENED_PDF_DIR_NAME = 'FlattenedPDFs';

// ! This is fixed, carefully when modifying
export const ITEM_STAGES = [
  'Cancelled',
  'Verifying',
  'Sourcing',
  'Quotes Available',
  'Order In Progress',
  'Order Ready',
  'Delivered',
];

export const ITEM_STAGE_TYPE = {
  CANCELLED: 0,
  VERIFYING: 1,
  SOURCING: 2,
  QUOTES_AVAILABLE: 3,
  ORDER_IN_PROGRESS: 4,
  ORDER_READY: 5,
  DELIVERED: 6,
};

export const paymentStatuses = ['', 'Pending', 'Paid'];

export const TWO_D_IMAGE_URLS_KEY = '2DImageUrls';

export const THREE_D_TECH_KEY = 'threeDTechnology';
export const THREE_D_INFILL_KEY = 'threeDInfill';
export const THREE_D_LAYER_THICKNESS_KEY = 'threeDLayerThickness';

export const ROLE_TYPES = {
  ADMIN: 'admin',
  BUYER: 'buyer',
  ONBOARDING_SUPPLIER: 'onboarding_supplier',
  REVIEWER: 'reviewer',
  ROCKET_MAN: 'rocket_man',
  SALES_CONSULTANT: 'sales_consultant',
  SUPER_ADMIN: 'superadmin',
  SUPPLIER: 'supplier',
};

export const ORDER_STATUS = {
  VERIFYING: 'verifying',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  DISABLED: 'disabled',
  EXPIRED: 'expired',
  WAITING_FOR_QUOTE: 'waiting_for_quote',
};

export const ORDER_STATUS_INFO = {
  SUBMITTED: 'submitted',
  CANCELLED: 'cancelled',
};

export const ITEM_STATUS_MAPPING = {
  CANCELLED: 0,
  VERIFYING: 1,
  SOURCING: 2,
  QUOTES_AVAILABLE: 3,
  ORDER_IN_PROGRESS: 4,
  ORDER_READY: 5,
  DELIVERED: 6,
};

export const PPE_DEFAULT_LEAD_TIME = '7 - 16 working days';
export const DEFAULT_LEAD_TIME = 10;
export const DEFAULT_MARKUP_LEAD_TIME = 6;

export const DEFAULT_FACTOREM_GST = 0.09; // 9%
export const DEFAULT_SUPPLIER_GST_PERCENT = 9; // 9%
export const DEFAULT_MARKUP_PERCENT = 0.15; // 15%

export const REFER_CONTACT_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdx2PoYdmCAuD91zmDSajixjgiX98uUykXbT_PGVSdE3jIRug/viewform';

export const SUPPLIER_FEEDBACK_URL =
  'https://form.jotform.com/241161332224442';

export const ROCKET_QUOTE_ACCEPT_TYPE = {
  PPE: 'ppe',
  RFQ: 'rfq',
};

export const EXCLUDE_USER_EMAILS = [
  'contact@sp3d.co',
];

export const CURRENCY = {
  SGD: 'SGD',
  MYR: 'MYR',
};

export const DEFAULT_MYR_EXCHANGE_RATE = 3.3;
export const DEFAULT_USD_EXCHANGE_RATE = 0.75;

export const IMG_PLACE_HOLDER_URL = 'https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder';

export const ACCESS_TOKEN_KEY = 'token';
export const USER_AGENT_INFO_KEY = 'userAgentInfo';

export const QUOTE_STATUS_OPTIONS = [
  "All",
  ORDER_STATUS.VERIFYING,
  ORDER_STATUS.PENDING,
  ORDER_STATUS.ACCEPTED,
  ORDER_STATUS.REJECTED,
  ORDER_STATUS.EXPIRED,
  ORDER_STATUS.DISABLED,
]

export const S3BUCKET_PDF_IMAGES_FOLDER = 'PDF_Images';
export const S3BUCKET_2D_IMG_FILES_FOLDER = '2dImgFiles';

export const YES_NO_VALUE_MAPPING = {
  Yes: 1,
  No: 0,
}

export const YES_NO_TEXT_MAPPING = {
  1: 'Yes',
  0: 'No',
}
