import React from 'react';
import { Container } from '@material-ui/core';
import CADRendererAINC from '../../lib/ai-nc/CADRendererAINC';

export default function CADRenderer() {
  return (
    <Container
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <CADRendererAINC />
    </Container>
  );
}
