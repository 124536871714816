import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

import PaperSummary from './PaperSummary';
import { FtrTypography } from '../../ftr-components';
import { FlexRow } from '../../layouts/FlexLayouts';

import RedWarningSvgIcon from '../../../assets/icons/red_warning.svg';

import useDeviceCheck from '../../../hooks/useDeviceCheck';

import { formatDeliveryDate } from '../../../utils/dateTimeUtils';
import { extractPriceFromCurrencyString } from '../../../utils/currencyUtils';

import { DELIVERY_OPTIONS_DISPLAY_MAPPING } from '../../../constants/itemConstants';
import { SHIPPING_MODES_DISPLAY_ORDER } from '../../../constants/checkoutConstants';

import { colors } from '../../../palette';


// ------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  menuItemSelected: {
    backgroundColor: colors.neutral020,
  },
}));

function DisplayShippingOptions(props) {
  const classes = useStyles();

  const [{ isMobile }] = useDeviceCheck();

  const {
    title,
    subInfo,
    price,
    shippingMode,
    shippingTooltip = '',
    loading = false,
    shippingCostOptions = {},
    selectShippingOption = () => { },
    hidePrice,
  } = props;

  const optionList = Object.entries(shippingCostOptions).sort((a, b) => {
    return SHIPPING_MODES_DISPLAY_ORDER.indexOf(a[0]) - SHIPPING_MODES_DISPLAY_ORDER.indexOf(b[0]);
  });

  const shippingCostContainerRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [showShippingOptionsDropdown, setShowShippingOptionsDropdown] = useState(false);

  const showPopover = () => {
    if (loading) {
      return;
    }

    setAnchorEl(shippingCostContainerRef.current);
    setShowShippingOptionsDropdown(true);
  }

  let displayedPrice = extractPriceFromCurrencyString(price) === 0 ? 'Free' : price;
  if (hidePrice) {
    displayedPrice = <img src={RedWarningSvgIcon} />;
  }

  return (
    <>
      <div
        ref={shippingCostContainerRef}
        onClick={showPopover}
      >
        <PaperSummary
          title={title}
          onEditClick={showPopover}
          subInfo={subInfo}
          loading={loading}
          price={displayedPrice}
          titleTooltip={shippingTooltip}
        />
      </div>
      <Popper
        open={hidePrice ? false : showShippingOptionsDropdown}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        style={{
          minWidth: isMobile ? 0 : 250,
          zIndex: 9999,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={() => setShowShippingOptionsDropdown(false)}
              >
                <MenuList
                  id="menu-list-grow"
                  autoFocusItem={open}
                >
                  {optionList.map(([key, option]) => {
                    return (
                      <MenuItem
                        key={key}
                        className={key === shippingMode
                          ? classes.menuItemSelected
                          : null
                        }
                        onClick={() => {
                          selectShippingOption(key);
                          setShowShippingOptionsDropdown(false);
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8rem',
                            }}
                          >
                            <FtrTypography
                              type='subHeading'
                              fontSize="16"
                              style={{
                                color: colors.neutral070,
                                fontWeight: 600,
                              }}
                            >
                              {DELIVERY_OPTIONS_DISPLAY_MAPPING[key]}
                            </FtrTypography>
                          </div>
                          <FlexRow
                            style={{
                              marginTop: '0.5rem',
                              justifyContent: 'space-between',
                            }}
                          >
                            <FtrTypography
                              type='subHeading'
                              fontSize="14"
                              style={{
                                color: colors.neutral060,
                                fontWeight: 400,
                              }}
                            >
                              {`Receive by ${formatDeliveryDate(option.estimatedDeliveryDate)}`}
                            </FtrTypography>
                            <FtrTypography
                              type='subHeading'
                              fontSize="14"
                              style={{
                                color: colors.neutral070,
                                fontWeight: 600,
                              }}
                            >
                              {option.feeStr}
                            </FtrTypography>
                          </FlexRow>
                        </div>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default DisplayShippingOptions;
