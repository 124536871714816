import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { FtrBoldText, FtrButton, FtrTypography } from '../../ftr-components';
import { FlexColumnCenter } from '../../layouts/FlexLayouts';

import GreenTickIcon from '../../../assets/icons/green_check_circle_icon.svg';

import { colors } from '../../../palette';


// ------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      borderRadius: '1rem',
      width: 'auto',
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    cursor: 'pointer',
  },
}));

function AcceptedMultipleQuotationsPopupV2(props) {
  const classes = useStyles();

  const {
    open,
    onBtnClick = () => { },
  } = props;

  return (
    <Dialog
      maxWidth='xs'
      fullWidth
      open={open}
      onClose={onBtnClick}
      aria-labelledby='share-quote-email-dialog-title'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle
        id='accepted-quotes-dialog-title'
        disableTypography
        className={classes.title}
      >
        <img style={{ padding: '1rem 1rem' }} src={GreenTickIcon} alt='icon' />
      </DialogTitle>
      <Close className={classes.closeIcon} onClick={onBtnClick} />
      <DialogContent
        className={classes.dialogContent}
      >
        <FtrTypography style={{ marginBottom: '1rem', fontWeight: 700, color: colors.neutral080 }} fontSize='28'>
          Quote Accepted
        </FtrTypography>
        <FlexColumnCenter
          style={{ gap: 0 }}
        >
          <FtrTypography
            type='body'
            fontSize='14'
            style={{
              padding: 10,
              color: colors.neutral060,
            }}
          >
            Retrieve your quotation form and upload your PO in
          </FtrTypography>
          <FtrBoldText
            fontSize='16'
            style={{
              color: colors.neutral060,
            }}
          >
            Manage Projects.
          </FtrBoldText>
        </FlexColumnCenter>
      </DialogContent>
      <DialogActions style={{ padding: 30, justifyContent: 'center' }}>
        <FtrButton
          id="check-progress-btn"
          color='blue'
          onClick={onBtnClick}
        >
          View Order
        </FtrButton>
      </DialogActions>
    </Dialog>
  );
}

export default AcceptedMultipleQuotationsPopupV2;
