import React, { useContext } from 'react';

import { CircularProgress } from '@material-ui/core';

import FtrButton from '../ftr-components/FtrButton';
import WarningIcon from '../icons/WarningIcon';
import CheckIcon from '../icons/CheckIcon';
import FailedIcon from '../icons/FailedIcon';

import { defectsStatus } from '../../utils/dfmExtractionUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

import withDfmAnalysisPopupHOC from '../../hocs/withDfmAnalysisPopupHOC';

import AppContext from '../../context/AppContext';

import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../constants/configurations';

/**
 * DefectButton is a React component that displays a button with an optional icon indicating
 * the defect status of a DFM (Design for Manufacturability) analysis.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.dfmDefect - The defect object containing the output of the DFM analysis.
 * @param {boolean} props.isLoading - Flag indicating whether the DFM analysis is currently loading.
 * @param {function} props.onClick - Callback function to handle button click events.
 * @param {boolean} [props.showIcon=false] - Flag to determine whether to display the defect status icon.
 * @param {string} [props.text='Defect'] - The text to display on the button.
 */
function DfmAnalysisButton(props) {
  const {
    dfmDefectOutput,
    isLoading,
    onClick,
    showIcon = false,
    text = 'Defect',
    updateDfmAnalysisPopupState = () => {},
    cadFile = '',
    twoDImageUrl = '',
    ...restProps
  } = props;

  const { featureFlags } = useContext(AppContext);

  if (!featureFlags?.config?.[FE_FEATURE_FLAGS_CONFIGURATION.DFM_AUTOMATION_BUYER]) {
    return null;
  }

  const renderDfmIconStatus = () => {
    if (isLoading) {
      return (
        <CircularProgress
          style={{
            color: colors.neutral040,
          }}
          size={20}
        />
      );
    }
    if (!dfmDefectOutput?.dfm_success) {
      return <FailedIcon toolTipText='DFM Analysis Failed' />;
    }
    const _defectsStatus = defectsStatus(dfmDefectOutput);
    const allDefects = _defectsStatus.filter((defect) => defect.defect);
    if (!isEmptyValue(allDefects)) {
      return (
        <WarningIcon
          toolTipText={`${allDefects?.length} ${
            allDefects?.length > 1 ? 'defects found' : 'defect found'
          }`}
        />
      );
    }
    return <CheckIcon toolTipText='No defects found' />;
  };

  return (
    <FtrButton
      startIcon={(showIcon || isLoading) && renderDfmIconStatus()}
      color='blue'
      variant='text'
      onClick={(event) => {
        if (typeof onClick === 'function') {
          onClick(event);
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        updateDfmAnalysisPopupState({
          open: true,
          cadFile,
          twoDImageUrl,
          dfmDefectOutput,
          isLoading,
        })
      }}
      style={{
        paddingLeft: '5px',
      }}
      {...restProps}
    >
      {text}
    </FtrButton>
  );
}

export default withDfmAnalysisPopupHOC(DfmAnalysisButton);
