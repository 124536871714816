import React, { useState, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { nanoid } from 'nanoid';

import { makeStyles } from '@material-ui/core/styles';

import { DataGrid } from '@mui/x-data-grid';

import { Divider } from '@material-ui/core';

import CustomToolbar from "../grid-data/CustomToolbar";
import DataGridWrapTextCell from "./cells/DataGridWrapTextCell";
import DateFormatMethod from '../DateFormatMethod';
import DeliveryDateCell from './cells/DeliveryDateCell';
import GridDataPagination from "../grid-data/GridDataPagination";
import OrderDetailsPopup from '../popups/OrderDetailsPopup';
import OrderHistoryParent from '../OrderHistoryParent';
import PaymentStatusSimpleLabel from '../labels/PaymentStatusSimpleLabel';
import PpePriceLoadingBackdrop from '../backdrops/PpePriceLoadingBackdrop';
import SearchBar from "../grid-data/buttons/SearchBar";
import ImageWith3DViewer from '../images/ImageWith3DViewer';

import {
  addOrUpdateCadPart,
  getPpePriceForCadPart,
  resetPartSummaryDetails,
} from '../../actions';

import { useItemInputConfig } from '../../hooks/useItemInputConfig';
import { useDataGridFilterHook } from "../../hooks/useDataGridFilterHook";

import { prepareOrderAgainItem } from '../util/orderItemAgainUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { getQuotationExchangeRate } from '../../utils/quotationUtils';
import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';

import { partIDColumn } from '../../constants/itemTableConstants';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  body: {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-root': {
      border: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const CustomerOrderHistoryDataGrid = (props) => {
  const {
    data,
  } = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const currency = useSelector((state) => state.auth.location.currency);
  const exchangeRates = useSelector((state) => state.auth.rates);
  const exchangeRate = exchangeRates[currency];

  // Declare component states
  const [viewPart, setViewPart] = useState(null);
  const [viewPartDialog, setViewPartDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [
    {
      technologyOptions,
    },
    {
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
    }
  ] = useItemInputConfig({
  });

  useEffect(() => {
    loadTechnologyOptions(false);
  }, []);

  useEffect(() => {
    if (viewPart) {
      setViewPartDialog(true);
    }
  }, [data, viewPart]);

  const handlePartDialogOpen = (id) => {
    setViewPart(
      data.find((item) => {
        return item.itemID === id;
      })
    );
  };

  const handleOrderAgain = async (item) => {
    const existingItem = data.find((currItem) => {
      return currItem.itemID === item.itemID;
    });

    if (!existingItem) {
      return;
    }

    setLoading(true);
    const updatedPart = await prepareOrderAgainItem({
      existingItem,
      technologyOptions,
      loadMaterialCategoryOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
    });
    const id = nanoid();
    updatedPart.id = id;
    dispatch(resetPartSummaryDetails());
    dispatch(addOrUpdateCadPart(updatedPart));
    dispatch(getPpePriceForCadPart(id));
    history.push('/summary');

    setLoading(false);
  };

  const handleCloseDialog = () => {
    setViewPartDialog(false);
    setViewPart(null);
  };

  const columns = [
    {
      ...partIDColumn,
      align: "center",
      minWidth: 70,
      flex: "none",
    },
    {
      headerName: "Order date",
      field: "dateOfConfirmation",
      renderCell: (params) =>
        params.row.dateOfConfirmation ? (
          <DataGridWrapTextCell text={DateFormatMethod({ date: params.row.dateOfConfirmation })} />
        ) : (
          ''
        ),
      align: "center",
      minWidth: 90,
      flex: 0.3,
    },
    {
      headerName: " ",
      field: "twoDImageUrl",
      renderCell: (params) => {
        return (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <ImageWith3DViewer
              twoDImageUrl={params.row.twoDImageUrl}
              cadFile={params.row.cadFile || params.row.originalFiles}
              noBorder={false}
              width={80}
              height={80}
              borderRadius={0}
              textRenderStyle={{ fontSize: 10 }}
            />
          </div>
        )
      },
      align: "center",
      minWidth: 100,
      flex: "none",
    },
    {
      headerName: "Name",
      field: "name",
      renderCell: (params) => (
        <DataGridWrapTextCell text={params.row.name} />
      ),
      align: "center",
      minWidth: 130,
      flex: 0.5,
    },
    {
      headerName: "Qty",
      field: "qty",
      valueGetter: (params) => {
        return params.row.quoteQty || params.row.quantity;
      },
      renderCell: (params) => (
        <DataGridWrapTextCell text={params.value} />
      ),
      align: "center",
      width: 70, // BUG: Cannot get minWidth to go below 100 here, using reasonable fixed width
    },
    {
      headerName: "Total price",
      field: "totalPrice",
      renderCell: (params) => {
        if (isEmptyValue(params.row.totalPrice)) {
          return '';
        }
        const { totalPriceStr } = convertPriceWithQuantityToCurrency({
          totalPrice: params.row.totalPrice,
          currency,
          exchangeRate: getQuotationExchangeRate(params.row, currency) || exchangeRate,
          quantity: params.row.quantity
        })
        return (
          <div>{totalPriceStr}</div>
        );
      },
      align: "center",
      minWidth: 100,
      flex: 0.3,
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      renderCell: (params) => (
        <PaymentStatusSimpleLabel paymentStatus={params.row.paymentStatus} />
      ),
      align: "center",
      minWidth: 100,
      flex: "none",
    },
    {
      headerName: "Delivery Date",
      field: "deliveryDate",
      renderCell: (params) => (
        <DeliveryDateCell rowData={params.row} />
      ),
      align: "center",
      minWidth: 80,
      flex: 0.3,
    },
    {
      headerName: " ",
      field: "quotationID",
      renderCell: (params) => (
        <div style={{ display: "flex", lineHeight: "normal" }}>
          <OrderHistoryParent
            item={params.row}
            onClickBtn={() => handlePartDialogOpen(params.row.itemID)}
            type='details'
          />
          <Divider orientation='vertical' flexItem={true} />
          <OrderHistoryParent
            item={params.row}
            onClickBtn={() => handleOrderAgain(params.row)}
            type='order'
          />
        </div>
      ),
      align: "center",
      minWidth: 260,
      flex: 1,
    },
  ];

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    },
  );

  const [
    filteredData,
    {
      setSearchStr,
      setColumnsDef,
      setSourceData,
    }
  ] = useDataGridFilterHook({
    search: '',
    source: [],
  });

  useEffect(() => {
    setSourceData(data);
  }, [data]);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData ? filteredData.length : 0 });
  }, [filteredData]);

  useEffect(() => {
    setColumnsDef(columns);
  }, []);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      // TODO: Reset page to 0 when searching, GridDataPagination props not 
      // working as expected when it is used as controlled component
      page: 0,
      search: searchTerm,
    });
  }

  const getCustomerToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          flexWrap: 'wrap',
        }}
      >
        <span style={{ flexGrow: '1 auto' }}></span>
        <CustomToolbar
          buttons={[
            <SearchBar
              key="search"
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
          ]}
        />
      </div>
    );
  }


  return (
    <div className={classes.body}>
      <DataGrid
        rows={!_.isEmpty(filteredData) ? filteredData : []}
        columns={columns.map(col => ({
          ...col,
          sortable: false,
        }))}
        autoHeight
        rowHeight={100}
        headerHeight={80}
        getRowId={(row) => row.itemID}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
        rowsPerPageOptions={[10, 20, 50]}
        loading={tableQueryParams.loading}
        disableSelectionOnClick
        disableColumnMenu
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={tableQueryParams ? _.ceil(tableQueryParams.totalCount / tableQueryParams.pageSize) : 0}
            />
          ),
        }}
      />
      <OrderDetailsPopup
        dialog={viewPartDialog}
        item={viewPart}
        handleClose={() => handleCloseDialog()}
      />
      <PpePriceLoadingBackdrop open={loading} />
    </div>
  );
}

export default withRouter(CustomerOrderHistoryDataGrid);
