import { ceil } from "lodash";
import { Decimal } from 'decimal.js';

import { isEmptyValue } from "./commonUtils";

import { CURRENCY_SYMBOL_MAPPING } from "../constants/currencyConstants";
import { DEFAULT_FACTOREM_GST } from "../constants";


// -------------------------------------------------------------------------------------------------

export const getCurrencySymbol = (currency, defaultCurrency = null) => {
  const currencySymbol = CURRENCY_SYMBOL_MAPPING[currency];
  if (isEmptyValue(currencySymbol)) {
    if (defaultCurrency == null) {
      throw new Error(`Currency Symbol for currency=${currency} not found`);
    }
    return defaultCurrency;
  }
  return currencySymbol;
}

/**
 * @param rate: exchange rate compare to SGD
 * @return 
 * S$ 1.00 if currency is SGD
 * RM 1.00 if currency is MYR
 * USD$ 1.00 if currency is USD
 */
export const convertPriceToCurrency = ({ price = 0, currency, exchangeRate = 1 }, defaultCurrency = null) => {
  const currencySymbol = getCurrencySymbol(currency, defaultCurrency);
  const convertedPrice = new Decimal(price).times(exchangeRate).toNumber();
  const convertedPriceStr = `${currencySymbol} ${beautifyNumber(convertedPrice.toFixed(2))}`;
  return convertedPriceStr;
}

export const convertPriceToCurrencyBeautified = ({ price = 0, currency, exchangeRate = 1 }, defaultCurrency = null) => {
  const currencySymbol = getCurrencySymbol(currency, defaultCurrency);
  const convertedPrice = new Decimal(price).times(exchangeRate).toNumber();
  return `${currencySymbol} ${beautifyNumber(convertedPrice.toFixed(2))}`;
}

/**
 * Beautifies a number by adding commas as thousands separators.
 *
 * @param {number|string} num - The number to be beautified.
 * @return {string} The beautified number with commas as thousands separators.
 * @example
 * 123.00      --> "123.00"
 * 1234.00     --> "1,234.00"
 * 12345.00    --> "12,345.00"
 * 123456.00   --> "123,456.00"
 * 1234567.00  --> "1,234,567.00"
 * 12345.67    --> "12,345.67"
 */
export const beautifyNumber = (num) => {
  if (isEmptyValue(num)) {
    return '';
  }

  return `${num}`.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

/**
 * correct total price if has quantity > 1 and fix 2 decimal points
 * @param {*} param0 
 * @returns 
 */
export const convertPriceWithQuantityToCurrency = ({
  totalPrice = 0,
  currency,
  quantity = 1,
  exchangeRate = 1,
}) => {
  const unitPriceDec = new Decimal(totalPrice)
    .dividedBy(new Decimal(quantity))
    .times(new Decimal(exchangeRate))
    .toNumber();
  const unitPrice = ceil(unitPriceDec, 2);
  const unitPriceStr = convertPriceToCurrencyBeautified({ price: unitPrice, currency, exchangeRate: 1});
  const updatedTotalPrice = new Decimal(unitPrice).times(new Decimal(quantity)).toNumber();
  const updatedTotalPriceStr = convertPriceToCurrencyBeautified({ price: updatedTotalPrice, currency, exchangeRate: 1});
  return {
    unitPrice: unitPrice,
    unitPriceStr: unitPriceStr,
    totalPrice: updatedTotalPrice,
    totalPriceStr: updatedTotalPriceStr,
  };
}

export const convertPriceToTargetCurrency = ({
  totalPrice = 0,
  currency,
  exchangeRate = 1,
}) => {
  const { totalPrice: result } = convertPriceWithQuantityToCurrency({
    totalPrice,
    quantity: 1,
    currency,
    exchangeRate,
  });
  return result;
}

/**
 * @param rate: exchange rate compare to SGD
 * @return S$1.00 if currency is SGD, RM 1.00 if currency is MYR
 */
export const convertPriceToCurrencyNoPrefix = ({
  price,
  exchangeRate,
}) => {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const convertedPrice = Number(price) * Number(exchangeRate);
  const convertedPriceStr = formatter.format(convertedPrice);
  return convertedPriceStr;
}

/**
 * Extract number from currency string with currency
 * Eg. "USD 1456" would return integer 1456
 */
export const extractPriceFromCurrencyString = (currencyString) => {
  if (typeof currencyString !== 'string') {
    return 0;
  }

  const result = Number(currencyString.replace(/[^0-9.]/g, ""));
  return result;
}

/**
 * round to the nearest 2 decimal points
 * eg: 3.141 -> 3.14, 3.145 -> 3.15
 * 
 * @param {*} cost 
 * @param {*} factoremGst 
 * @returns 
 */
export const getGstFromCost = (cost = 0, factoremGst = DEFAULT_FACTOREM_GST) => {
  if (isNaN(cost) || isNaN(factoremGst)) {
    return 0;
  }

  const gst = new Decimal(cost).times(new Decimal(factoremGst)).toFixed(2);
  return Number(gst);
}
