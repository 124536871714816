import {
  ADD_SELECTED_QUOTE,
  INIT_MULTI_CHECKOUT_FORM_STATE,
  REMOVE_SELECTED_QUOTE,
  REMOVE_SELECTED_QUOTES,
  RESET_CHECKOUT_PRICING_SUMMARY,
  UPDATE_CHECKOUT_PRICING_SUMMARY_FAIL,
  UPDATE_CHECKOUT_PRICING_SUMMARY_START,
  UPDATE_CHECKOUT_PRICING_SUMMARY_SUCCESS,
  UPDATE_STATE,
} from "../actions/multiCheckoutForm";
import { DEFAULT_DELIVERY_TIME } from "../components/popups/confirm-order-popup/DeliveryTimeSelection";
import { CURRENCY } from "../constants";
import { CUSTOMER_CREDIT_TYPES } from "../constants/customerConstants";
import { PAYMENT_TYPE } from "../constants/paymentConstants";

import { convertPriceWithQuantityToCurrency } from "../utils/currencyUtils";
import { getQuotationExchangeRate } from "../utils/quotationUtils";

const defaultCheckoutPriceSummary = {
  numOfSelectedQuotes: 0,
  totalCost: 0,
  gst: 0,
  deliveryFee: 0,
  totalCostInclGst: 0,
  factoremCustomDiscountCode: '',
}

const defaultState = {
  selectedQuotes: [],
  checkoutPriceSummary: defaultCheckoutPriceSummary,
  currency: CURRENCY.SGD,
  paymentType: PAYMENT_TYPE.STRIPE_PAYNOW,
  status: '',
  confirmOrderStatus: 'idle',
  customerRemarks: {},
  addresses: null,
  customerDeliveryInfoList: [],
  deliveryTime: DEFAULT_DELIVERY_TIME,
  creditType: CUSTOMER_CREDIT_TYPES.NON_CREDIT,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case INIT_MULTI_CHECKOUT_FORM_STATE: {
      const currency = action.payload;
      return {
        ...defaultState,
        currency,
        paymentType: currency === CURRENCY.SGD
          ? PAYMENT_TYPE.STRIPE_PAYNOW
          : PAYMENT_TYPE.STRIPE_CREDIT_CARD,
      };
    }
    case ADD_SELECTED_QUOTE: {
      const quotation = action.payload;
      const selectedQuotes = state.selectedQuotes.filter(quote => quote.itemID !== quotation.itemID);
      const newSelectedQuotes = [...selectedQuotes, quotation];
      // update sub total cost
      const totalCost = newSelectedQuotes.reduce((acc, quote) => {
        const { totalPrice } = convertPriceWithQuantityToCurrency({
          totalPrice: quote.totalPrice,
          quantity: quote.quantity,
          currency: state.currency,
          exchangeRate: getQuotationExchangeRate(quote, state.currency),
        });
        return acc += totalPrice;
      }, 0);
      // add currency symbol
      const { totalPriceStr: totalCostStr } = convertPriceWithQuantityToCurrency({
        totalPrice: totalCost,
        quantity: 1,
        currency: state.currency,
        exchangeRate: 1,
      });
      return {
        ...state,
        selectedQuotes: newSelectedQuotes,
        checkoutPriceSummary: {
          ...state.checkoutPriceSummary,
          totalCost: totalCostStr,
        },
      };
    }
    case REMOVE_SELECTED_QUOTE: {
      const quotationID = action.payload;
      const selectedQuotes = state.selectedQuotes.filter(quote => quote.quotationID !== quotationID);
      return {
        ...state,
        selectedQuotes: [...selectedQuotes],
      };
    }
    case REMOVE_SELECTED_QUOTES: {
      const quotationIDList = action.payload;
      const selectedQuotes = state.selectedQuotes.filter(
        quote => !quotationIDList.includes(quote.quotationID)
      );
      return {
        ...state,
        selectedQuotes: [...selectedQuotes],
      };
    }
    case UPDATE_CHECKOUT_PRICING_SUMMARY_START: {
      return {
        ...state,
        status: 'loading',
        ...action.payload,
      };
    }
    case UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_CHECKOUT_PRICING_SUMMARY_SUCCESS: {
      const checkoutPriceSummary = action.payload;
      return {
        ...state,
        checkoutPriceSummary: {
          ...checkoutPriceSummary,
        },
        status: 'success',
      };
    }
    case UPDATE_CHECKOUT_PRICING_SUMMARY_FAIL: {
      return {
        ...state,
        status: 'failed',
      };
    }
    case RESET_CHECKOUT_PRICING_SUMMARY: {
      return {
        ...state,
        selectedQuotes: [],
        checkoutPriceSummary: {
          ...defaultCheckoutPriceSummary,
        },
        status: '',
      };
    }
    default: {
      return state;
    }
  }
}
