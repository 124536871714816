import React, { useContext, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core';

import CompareQuotesMultiCheckoutMuiTable from '../tables/CompareQuotesMultiCheckoutMuiTable';
import PartDetailsDropdown from '../dropdowns/PartDetailsDropdown';
import { FtrButton } from '../ftr-components';

import withRequestMoreQuotesPopupHOC from '../../hocs/withRequestMoreQuotesPopupHOC';

import ProjectItemsContext from '../../context/ProjectItemsContext';

import { isPpeItem } from '../../utils/itemUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { ITEM_STATUS_MAPPING } from '../../constants';

import { colors } from '../../palette';



const useStyles = makeStyles(() => ({
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TABS = [
  'Details',
  'All Quotes',
]

/**
 * This component for displaying expand item row in Customer individual project management
 * path: /manage-projects/:id
 *
 * @param {*} props
 * @returns
 */
function MultiCheckoutDetailsPanel(props) {
  const classes = useStyles();

  const { isAdminView } = useContext(ProjectItemsContext);

  const {
    rowData,
    allParts,
    getItems = () => { },
    updateRequestQuotePopupState = () => { },
  } = props;

  const hasQuotesAvailable = rowData.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE
  const hasAnyQuotes = !isEmptyValue(rowData.quotations);

  const [selectedTab, setSelectedTab] = useState(
    (isAdminView && hasAnyQuotes) || hasQuotesAvailable ? 1 : 0
  );

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div
      style={{
        paddingLeft: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
        >
          {TABS.map(tab => {
            return <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />;
          })}
        </Tabs>
        {(
          [
            ITEM_STATUS_MAPPING.QUOTES_AVAILABLE,
            ITEM_STATUS_MAPPING.SOURCING,
          ].includes(rowData.status)
          || (rowData.status === ITEM_STATUS_MAPPING.VERIFYING
            && !isPpeItem(rowData)
          )
        ) && (
            <Tooltip title="Request more quotes for different quantities">
              <div
                style={{
                  marginLeft: '0.5rem',
                }}
              >
                <FtrButton
                  variant="text"
                  onClick={() => {
                    updateRequestQuotePopupState({
                      open: true,
                      selectedItem: rowData,
                    });
                  }}
                >
                  Request More Quotes
                </FtrButton>
              </div>
            </Tooltip>
          )}
      </div>
      {selectedTab === 0 && <PartDetailsDropdown itemID={rowData.itemID} data={allParts} getItems={getItems} />}
      {selectedTab === 1 && (
        <CompareQuotesMultiCheckoutMuiTable
          item={rowData || {}}
          quotations={rowData ? rowData.quotations : []}
          getItems={getItems}
        />
      )}
    </div>
  );
}

export default withRequestMoreQuotesPopupHOC(MultiCheckoutDetailsPanel);
