import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';
import { colors } from '../../palette';

import {
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';


// ----------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  filterBar: {
    marginRight: theme.spacing(2),
    minWidth: 120,
  },
  radiogroup: {
    "& .MuiRadio-colorSecondary": {
      color: colors.blue050,

      "& .Mui-checked": {
        color: colors.blue060
      }
    }
  }
}));

function FilterBar(props) {
  const classes = useStyles();

  const {
    defaultValue,
    onFilterChange,
    optionList = [],
  } = props;

  const renderList = () => {
    const renderRadioGroup = () => (
      <RadioGroup defaultValue={defaultValue} name={optionList[0].group} className={classes.radiogroup} row>
        {optionList.map(({ name, value }) => (
          <FormControlLabel
            data-cy={name}
            key={name}
            control={
              <Radio onChange={onFilterChange} value={value} />
            }
            label={name}
          />
        ))}
      </RadioGroup>
    );

    const renderCheckboxGroup = () => (
      optionList.map(({ name, value }) => (
        <FormControlLabel
          data-cy={name}
          key={name}
          control={
            <Checkbox onChange={onFilterChange} name={value || name} />
          }
          label={name}
        />
      ))
    );

    return optionList[0]?.group ? renderRadioGroup() : renderCheckboxGroup();
};

  return (
    <FormControl variant="outlined" className={classes.filterBar}>
      <FormGroup row={true}>
        {renderList()}
      </FormGroup>
    </FormControl>
  );
}

export default FilterBar;
