import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import clsx from 'clsx';
import { colors } from '../../palette';

const useStyles = ({ bg }) => makeStyles(() => ({
  ribbon: {
    width: 100,
    height: 100,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: 99,
    "& span": {
      position: 'absolute',
      display: 'block',
      width: 142,
      padding: '0.5rem 0',
      // backgroundColor: colors.quotedLabelBlue,
      backgroundImage: bg || colors.colorBlueGradient,
      boxShadow: `0 5px 10px rgba(0,0,0,.1)`,
      color: '#fff',
      font: `700 12px/1 'Lato', sans-serif`,
      textShadow: `0 1px 1px rgba(0,0,0,.2)`,
      textTransform: 'uppercase',
      textAlign: 'center'
    },
  },
  ribbonBottomRight: {
    bottom: -10,
    right: -10,
    "&, &:before, &:after": {
      borderBottomColor: 'transparent',
      borderRightColor: 'transparent',
    },
    "&, &:before": {
      bottom: 0,
    },
    "&, &:after": {
      right: 0,
    },
    "& span": {
      bottom: 20,
      transform: `rotate(-45deg)`,
    },
  },
}));

function CornerRibbon(props) {
  const { bg } = props;

  const classes = useStyles({ bg })();

  const { text } = props;

  return (
    <div
      className={clsx(classes.ribbon, classes.ribbonBottomRight)}
    >
      <span>{text}</span>
    </div>
  );
}

export default CornerRibbon;
