import React, { useState } from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';

import { FtrTextField } from './ftr-components';

import showPasswordIcon from '../assets/icons/show_password.svg';
import hidePasswordIcon from '../assets/icons/hide_password.svg';


// ----------------------------------------------------------------------

function FtrPasswordField(props) {
  const {
    id = 'ftr-password',
    title = 'Password *',
    showForgotPassword = false,
    error,
    onChange = () => { },
    onFocus = () => { },
    onBlur = () => { },
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <FtrTextField
      title={title}
      id={id}
      name={id}
      showForgotPassword={showForgotPassword}
      error={error}
      onChange={onChange}
      type={showPassword ? 'text' : 'password'}
      onFocus={onFocus}
      onBlur={onBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword((prev) => !prev)}
              style={{ padding: 0 }}
            >
              <img src={showPassword ? showPasswordIcon : hidePasswordIcon} alt='icon' />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default FtrPasswordField;
