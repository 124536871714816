import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import Title from '../Title';
import BlueButton from '../buttons/BlueButton';

import { useUserFeedbackHook } from '../../hooks/useUserFeedbackHook';

import { renderQuestion } from '../../utils/userFeedbackUtils';

import { ROLE_TYPES } from '../../constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
}));

function UserFeedbackPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose = () => { },
    userID,
    questionList = [],
    userRole = ROLE_TYPES.BUYER,
  } = props;

  const [
    {
      hasAllQuestionAnswered,
    },
    {
      updateAnswer,
      submitUserFeedback,
    },
  ] = useUserFeedbackHook({ questionList });

  const renderBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {questionList.map((question => {
          return renderQuestion({
            question,
            handler: updateAnswer,
          });
        }))}
      </div>
    );
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <Title contentTitle="Please spend a minute to provide us some feedback!" size="small" />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          {renderBody()}
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '1rem',
          }}
        >
          <BlueButton
            onBtnClick={() => {
              const submitLocation = userRole === ROLE_TYPES.SUPPLIER
                ? 'First order shipped'
                : 'First checkout';
              submitUserFeedback(userID, submitLocation);
              handleClose();
            }}
            btnContent="Submit"
            disabled={!hasAllQuestionAnswered}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default UserFeedbackPopup;
