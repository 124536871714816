import React, { Fragment, useReducer, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles/index';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Checkbox,
  TableCell as MuiTableCell,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  withStyles,
} from '@material-ui/core';

import BulkPricingPopupV2 from '../../popups/BulkPricingPopupV2';
import ConfigurePartFormV2 from '../ConfigurePartFormV2';
import DrawingDragDrop from '../../DrawingDragDrop';
import FtrSvgImage from '../../images/FtrSvgImage';
import ImageWith3DViewer from '../../images/ImageWith3DViewer';
import QuantityField from './QuantityField';
import Row from '../../Row';
import Skeleton from '@material-ui/lab/Skeleton';
import TechnicalDrawingFileDisplay from './TechnicalDrawingFileDisplay';
import { FlexColumn, FlexColumnCenter, FlexRow, FlexRowCenter } from '../../layouts/FlexLayouts';
import { FtrBanner, FtrButton, FtrTypography } from '../../ftr-components';

import withLoadingBackDropTextHOC from '../../../hocs/withLoadingBackDropTextHOC';
import withPriceFeedbackPopupHOC from '../../../hocs/withPriceFeedbackPopupHOC';

import RedWarningSvgIcon from '../../../assets/icons/red_warning.svg';
import AutoAwesomeIcon from '../../../assets/icons/auto_awesome.svg';


import {
  addTechnicalDrawingAndGetPrice,
  getPpePriceForCadPart,
  updateCadPartIfExists,
} from '../../../actions';

import { getTechnicalDrawingFiles } from '../../../utils/tdeUtils';
import { getDateStrWithMonth } from '../../../utils/dateTimeUtils';
import { isEmptyValue } from '../../../utils/commonUtils';
import { showUpload2DFileText } from '../../../utils/partUploadUtils';
import {
  convertPriceToCurrency,
} from '../../../utils/currencyUtils';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../../utils/inputUtils';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
  is3DPTechnology,
} from '../../../utils/itemUtils';
import {
  displayBoundingBoxValues,
  showUnitValueFromMetric,
} from '../../../utils/userUtils';


import { notifyError } from '../../../services/notificationService';
import { uploadCadFileToS3 } from '../../../services/s3Service';



import { TWO_D_IMAGE_URLS_KEY } from '../../../constants';
import { THREE_D_P_FDM_TECH } from '../../../constants/NewPartConstants';
import { UNIT_TYPES } from '../../../constants/unitConstants';

import { colors } from '../../../palette';

import { DisplayItemPrice } from './DisplayItemPrice'
import DfmAnalysisButton from '../../DfmDefects/DfmAnalysisButton';

// -------------------------------------------------------------------------------------------------

const ColumnHeader = withStyles({
  root: {
    fontWeight: 600,
  },
})(MuiTableCell);

const ColumnCellPaddingNone = withStyles({
  paddingNone: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  tableRowRoot: {
    verticalAlign: 'top',
    backgroundColor: colors.fontWhite,
    position: 'relative',
  },
  autoAwesomeIcon: {
    height: '1rem !important',
    width: '1rem !important',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  inlineContainer: {
    display: 'flex',
    verticalAlign: 'start', // Align to the top
    height: 'fit-content', // Height adjusts to content
    width: 'fit-content', // Width adjusts to content
  },
  noBottomBorder: {
    borderBottom: 'none',
  },
  warningRow: {
    paddingTop: 0,
    paddingBottom: '1.1rem',
  },
  renderImage: {
    width: '160px',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
      '& $render3dCAD': {
        display: 'block',
      },
    },
    [theme.breakpoints.down(1440)]: {
      width: '130px',
    },
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '1.25rem',
  },
  rowHover: {
    backgroundColor: '#F6F6F6',
  },
  dragDrop: {
    [theme.breakpoints.down(1440)]: {
      '& label > div': {
        width: '130px !important',
      },
    },
  },
  specification: {
    paddingBottom: '0.5rem',
    wordBreak: 'break-all',
  },
}));

export function TdeWarning() {
  return (
    <FtrTypography
      type='body'
      fontSize='14'
      style={{ color: colors.warningYellow, paddingBottom: '0.5rem' }}
    >
      No fields extracted. Please configure manually.
    </FtrTypography>
  );
}

function DisplayItemsTable(props) {
  const {
    itemState,
    selectedUnitType = UNIT_TYPES.METRIC,
    handleCheckedAll = () => { },
    handleCheckboxClickItemTableRow = () => { },
    showLoadingPriceScreen,
    updatePriceFeedbackState = () => { },
    setLoadingMessage = () => { },
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();

  const currency = useSelector((state) => state.auth.location.currency);
  const exchangeRates = useSelector((state) => state.auth.rates);
  const exchangeRate = exchangeRates[currency];

  const [
    configurePartPopupState,
    updateConfigurePartPopupState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      itemID: null,
    }
  );

  const [
    bulkPricingDialogState,
    updateBulkPricingDialogState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      item: null,
    }
  );

  const [hoveredRow, setHoveredRow] = useState(null);
  const [draggingRow, setDraggingRow] = useState(null);

  const notHasRepeatOrder = itemState.some((item) => !item.repeatOrder);

  const navigateItem = (value) => {
    const { itemIndex } = configurePartPopupState;
    const newIndex = itemIndex + value;
    updateConfigurePartPopupState({
      itemIndex: newIndex,
    });
  };

  const handleDragOver = (e, partID) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingRow(partID);
  };

  const render2DImage = (item) => {

    const { imageStatus } = item;
    const imageUrl = item[TWO_D_IMAGE_URLS_KEY]
      ? item[TWO_D_IMAGE_URLS_KEY][0]
      : null;

    const technicalDrawingFiles = getTechnicalDrawingFiles(item.cadPart)

    function handleDrawingFilesChange(value, newFiles) {
      const uploadedUrls = [];
      setLoadingMessage('Uploading...');
      Promise.all(
        Array.from(newFiles).map(async (file) => {
          const { s3ObjectUrl } = await uploadCadFileToS3(file);
          if (!isEmptyValue(s3ObjectUrl)) {
            uploadedUrls.push(s3ObjectUrl);
          }
        })
      )
        .then(() => {
          setLoadingMessage('');
          dispatch(addTechnicalDrawingAndGetPrice(item.id, uploadedUrls));
        })
        .catch(() => {
          setLoadingMessage('');
          notifyError('File uploaded failed');
        });
    }

    return (
      <MuiTableCell
        className={showUpload2DFileText(item) && classes.noBottomBorder}
        key={item.id}
        align='left'
        style={{ width: isMobile ? 60 : 160 }}
      >
        <ImageWith3DViewer
          key={item.id}
          cadFile={item?.cadPart?.join(',')}
          twoDImageUrl={imageUrl}
          width={160}
          height={160}
          noBorder
          isLoading={imageStatus === 'loading'}
          borderRadius={12}
        />
        <Box height='0.875rem' />
        {technicalDrawingFiles?.map((fileStr, index) => {
          return (
            <Fragment key={fileStr}>
              <TechnicalDrawingFileDisplay
                item={item}
                index={index}
                fileStr={fileStr}
                customWidth={'137.6px'}
              />
              <Box height='6px' />
            </Fragment>
          );
        })}
        {isEmptyValue(technicalDrawingFiles) && (
          <DrawingDragDrop
            id={item.id?.toString()}
            handleFiles={(files) => {
              handleDrawingFilesChange(item, files);
            }}
            dragging={draggingRow === item.id}
            setDragging={() => setDraggingRow(null)}
            contentStyle={{ width: 160 }}
          />
        )}
      </MuiTableCell>
    );
  };

  const removeTDEGenField = (item, fieldsToRemove) => {
    const generatedFields = new Set(item.generatedFields || []);
    fieldsToRemove.forEach(field => generatedFields.delete(field));
    dispatch(updateCadPartIfExists({
      id: item.id,
      generatedFields: Array.from(generatedFields),
    }));
  }

  const renderConfigurePartButton = (part, index) => {
    return (
      <FtrButton
        key={`configure-part-${part.id}-button`}
        color='blue'
        size='medium'
        onClick={() => {
          updateConfigurePartPopupState({
            open: true,
            itemIndex: index,
          });
          if (part.tdeStatus === 'error') {
            dispatch(
              updateCadPartIfExists({
                id: part.id,
                tdeStatus: null,
              })
            );
          }
        }}
        id={`configure-part-${index}-button`}
        style={{
          minWidth: 140,
        }}
        variant={'text'}
      >
        Configure part
      </FtrButton>
    );
  };

  const renderSkeleton = () => (
    <div>
      <FtrTypography
        type='body'
        fontSize='14'
        style={{ color: colors.neutral060, paddingBottom: '0.5rem' }}
      >
        Extracting information from your technical drawing
      </FtrTypography>
      <FlexColumn>
        <Skeleton variant='text' width='50%' height='1.5%' />
        <Skeleton variant='text' width='10%' height='2.5%' />
        <Skeleton variant='text' width='50%' height='1.5%' />
        <Skeleton variant='text' width='50%' height='1.5%' />
      </FlexColumn>
    </div>
  );

  const renderTDEGenImage = (item, fieldNames, topPosition = '3px') => {
    const generatedFieldsSet = new Set(item.generatedFields || []);
    if (fieldNames.some((fieldName) => generatedFieldsSet.has(fieldName))) {
      return (
        <Tooltip title={'Automatically extracted parameter'} arrow>
          <div style={{ display: 'inline-block', position: 'relative', top: topPosition, left: '3px' }}>
            <FtrSvgImage
              src={AutoAwesomeIcon}
              className={classes.autoAwesomeIcon}
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const renderItemSpecifications = (item) => {
    const {
      name,
      boundingBoxX,
      boundingBoxY,
      boundingBoxZ,
      technology,
      otherTechnology,
      material,
      otherMaterial,
      surfaceFinish,
      otherSurfaceFinish,
      tolerance,
      threeDTechnology,
      threeDInfill,
      threeDLayerThickness,
      remarks,
      targetPrice,
      expectedLeadTime,
      unitType,
      defectStatus,
      defects
    } = item;

    const isSkeleton = item.tdeStatus === 'loading';
    const isTdeError = item.tdeStatus === 'error';

    return (
      <MuiTableCell
        className={showUpload2DFileText(item) && classes.noBottomBorder}
        align='left'
        style={{
          minWidth: '200px',
          maxWidth: '250px',
          width: '32%',
        }}
        id={`specifications-column${
          renderTDEGenImage(item, Array.from(item.generatedFields || []))
            ? '-tde-gen'
            : ''
        }`}
      >
        <div>
          <FtrTypography
            type='subHeading'
            fontSize='16'
            className={classes.specification}
            style={{ paddingBottom: '0.5rem' }}
          >
            {name}
          </FtrTypography>
        </div>
        {isSkeleton ? (
          renderSkeleton()
        ) : (
          <div>
            {isTdeError && <TdeWarning />}
            <FtrTypography
              type='body'
              fontSize='14'
              style={{ color: colors.neutral060, paddingBottom: '0.5rem' }}
            >
              {displayBoundingBoxValues(
                boundingBoxX,
                boundingBoxY,
                boundingBoxZ,
                unitType ?? selectedUnitType
              )}
            </FtrTypography>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, lineHeight: '22px' }}
            >
              {isCustomTechnology(technology) ? otherTechnology : technology}
            </FtrTypography>
            {is3DPTechnology(technology) && (
              <Fragment>
                <FtrTypography
                  type='subHeading'
                  fontSize='14'
                  style={{ color: colors.neutral060, lineHeight: '22px' }}
                >
                  {threeDTechnology}
                </FtrTypography>
                {threeDTechnology === THREE_D_P_FDM_TECH && (
                  <FtrTypography
                    type='subHeading'
                    fontSize='14'
                    style={{ color: colors.neutral060, lineHeight: '22px' }}
                  >
                    3D Infill {Number(threeDInfill * 100).toFixed(0)}%
                  </FtrTypography>
                )}
                {threeDTechnology === THREE_D_P_FDM_TECH && (
                  <FtrTypography
                    type='subHeading'
                    fontSize='14'
                    style={{ color: colors.neutral060, lineHeight: '22px' }}
                  >
                    3D Layer Thickness {threeDLayerThickness}mm
                  </FtrTypography>
                )}
              </Fragment>
            )}
            <FlexRow
              className={classes.inlineContainer}
              style={{ gap: '0.1rem' }}
            >
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral060, lineHeight: '22px' }}
              >
                {isCustomMaterial(material)
                  ? otherMaterial
                  : getMaterialWithColorText(item)}
                {renderTDEGenImage(item, ['material', 'otherMaterial'])}
              </FtrTypography>
            </FlexRow>
            <FlexRow
              className={classes.inlineContainer}
              style={{ gap: '0.1rem' }}
            >
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral060, lineHeight: '22px' }}
              >
                {isCustomSurfaceFinish(surfaceFinish)
                  ? otherSurfaceFinish
                  : getSurfaceFinishWithCustomizationsText(item)}
                {renderTDEGenImage(item, [
                  'surfaceFinish',
                  'otherSurfaceFinish',
                ])}
              </FtrTypography>
            </FlexRow>
            {tolerance && (
              <FlexRow style={{ gap: '0.1rem' }}>
                <FtrTypography
                  type='subHeading'
                  fontSize='14'
                  style={{ color: colors.neutral060, lineHeight: '22px' }}
                >
                  {`Tolerance ${showUnitValueFromMetric(
                    tolerance,
                    unitType ?? selectedUnitType
                  )}`}
                  {renderTDEGenImage(item, ['tolerance'])}
                </FtrTypography>
              </FlexRow>
            )}
            {remarks && (
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral060, lineHeight: '22px' }}
              >
                Remarks: {remarks}
              </FtrTypography>
            )}
            {targetPrice && (
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral060, lineHeight: '22px' }}
              >
                Target price{' '}
                {convertPriceToCurrency({
                  price: Number(targetPrice),
                  currency,
                  exchangeRate,
                })}
                /unit
              </FtrTypography>
            )}
            {expectedLeadTime && (
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral060, lineHeight: '22px' }}
              >
                Target Delivery {getDateStrWithMonth(expectedLeadTime)}
              </FtrTypography>
            )}
            {defectStatus && (
              <DfmAnalysisButton
                showIcon
                dfmDefectOutput={defects || {}}
                isLoading={defectStatus === 'loading'}
                text='View DFM Analysis'
                cadFile={item.cadPart}
                twoDImageUrl={item[TWO_D_IMAGE_URLS_KEY]?.[0]}
              />
            )}
          </div>
        )}
      </MuiTableCell>
    );
  };

  const renderQuantity = (part) => {
    const isSkeleton = part.tdeStatus === 'loading';

    if (isSkeleton) {
      return (
        <FlexColumnCenter>
          <Skeleton variant='text' width='50%' height='1.5%' />
        </FlexColumnCenter>
      );
    }

    return (
      <FlexRowCenter onClick={(e) => { e.stopPropagation() }} style={{ gap: 0 }}>
        <QuantityField
          value={part.qty}
          onBlur={(evt) => {
            let newValue = Number(evt.target.value);
            dispatch(updateCadPartIfExists({ id: part.id, qty: newValue }));
            dispatch(getPpePriceForCadPart(part.id));
            if (Number(part.qty) !== Number(newValue)) {
              removeTDEGenField(part, ["qty"]);
            }
          }}
        />
        {renderTDEGenImage(part, ['qty'], 0)}
      </FlexRowCenter>
    );
  }

  return (
    <Fragment>
      <TableContainer component={Paper} style={{ borderRadius: '16px' }}>
        <Table>
          <TableHead style={{ backgroundColor: colors.neutral030 }}>
            <TableRow>
              <ColumnHeader align='center' padding='checkbox'>
                <Checkbox
                  color='primary'
                  checked={!itemState.some((item) => !item.checked)}
                  onChange={handleCheckedAll}
                />
              </ColumnHeader>
              <ColumnHeader align='left'>
                <FtrTypography type='subHeading' fontSize='16'>
                  Select all
                </FtrTypography>
              </ColumnHeader>
              <ColumnHeader align='left'>
                <FtrTypography type='subHeading' fontSize='16'>
                  Specifications
                </FtrTypography>
              </ColumnHeader>
              <ColumnHeader align='center' padding='none'>
                <FtrTypography type='subHeading' fontSize='16'>
                  Qty
                </FtrTypography>
              </ColumnHeader>
              <ColumnHeader
                align='right'
                style={{ width: !isMobile && notHasRepeatOrder && 155 }}
              >
                <FtrTypography type='subHeading' fontSize='16'>
                  Price
                </FtrTypography>
              </ColumnHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemState.map((part, index) => {
              return (
                <Fragment key={part.id}>
                  <TableRow
                    classes={{
                      root: classes.tableRowRoot,
                    }}
                    className={hoveredRow === part.id ? classes.rowHover : null}
                    onMouseEnter={() => setHoveredRow(part.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                    data-cy={`index-${index}`}
                    style={{ position: 'relative' }}
                    onDragOver={(e) => handleDragOver(e, part.id)}
                  >
                    {/* Checkbox */}
                    <MuiTableCell
                      className={
                        showUpload2DFileText(part) && classes.noBottomBorder
                      }
                      align='center'
                      padding='checkbox'
                    >
                      <Checkbox
                        checked={part.checked}
                        color='primary'
                        onChange={handleCheckboxClickItemTableRow}
                        name={part.id?.toString()}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    </MuiTableCell>
                    {render2DImage(part)}
                    {/* Item spec */}
                    {renderItemSpecifications(part)}
                    {/* Quantity */}
                    <ColumnCellPaddingNone
                      className={
                        showUpload2DFileText(part) && classes.noBottomBorder
                      }
                      align='center'
                      padding='none'
                    >
                      {renderQuantity(part)}
                    </ColumnCellPaddingNone>
                    {/* Price */}
                    <MuiTableCell
                      className={
                        showUpload2DFileText(part) && classes.noBottomBorder
                      }
                      align='right'
                      style={{ position: 'relative' }}
                    >
                      <DisplayItemPrice
                        part={part}
                        showLoadingPriceScreen={showLoadingPriceScreen}
                        updatePriceFeedbackState={updatePriceFeedbackState}
                        updateBulkPricingDialogState={updateBulkPricingDialogState}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 16,
                          right: 16,
                        }}
                      >
                        {!showUpload2DFileText(part) &&
                          renderConfigurePartButton(part, index)}
                      </div>
                    </MuiTableCell>
                  </TableRow>
                  {showUpload2DFileText(part) && (
                    <TableRow
                      classes={{
                        root: classes.tableRowRoot,
                      }}
                      className={
                        hoveredRow === part.id ? classes.rowHover : null
                      }
                      onMouseEnter={() => setHoveredRow(part.id)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <MuiTableCell align='center'></MuiTableCell>
                      <MuiTableCell className={classes.warningRow} colSpan={3}>
                        <FtrBanner type='warning'>
                          <Row>
                            <img src={RedWarningSvgIcon} />
                            <FtrTypography type='body' fontSize='14'>
                              Technical drawing required for precise quotation
                              and DFM feedback.
                            </FtrTypography>
                          </Row>
                        </FtrBanner>
                      </MuiTableCell>
                      <MuiTableCell
                        align='right'
                        style={{ padding: '0.3rem 1rem' }}
                      >
                        {renderConfigurePartButton(part, index)}
                      </MuiTableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {configurePartPopupState.open && (
        <ConfigurePartFormV2
          key={itemState[configurePartPopupState.itemIndex]}
          open={configurePartPopupState.open}
          selectedItemIndex={configurePartPopupState.itemIndex}
          onClose={() => {
            updateConfigurePartPopupState({ open: false });
          }}
          navigateItem={navigateItem}
        />
      )}
      {bulkPricingDialogState.open && (
        <BulkPricingPopupV2
          dialog={bulkPricingDialogState.open}
          item={bulkPricingDialogState.item}
          handleClose={() => updateBulkPricingDialogState({ open: false })}
        />
      )}
    </Fragment>
  );
}

export default withPriceFeedbackPopupHOC(
  withLoadingBackDropTextHOC(DisplayItemsTable)
);
