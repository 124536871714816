import React from 'react';
import clsx from 'clsx';
import { Tooltip, makeStyles } from '@material-ui/core';

import { ReactComponent as ThumbDownIcon } from '../../assets/icons/thumbs_down.svg';
import { ReactComponent as ThumbUpIcon } from '../../assets/icons/thumbs_up.svg';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '4px',
    padding: '8px 5px',
    cursor: 'pointer',
    display: 'grid',
    placeItems: 'center',
    color: colors.neutral020,
  },
  icons: {
    display: 'flex',
    gap: '1rem',
  },
  iconSize: {
    width: '1.3rem',
    height: '1.3rem',
  },

  containerThumbDownActive: {
    backgroundColor: colors.darkRed,
  },
  thumbDownIcon: {
    '& path': {
      stroke: colors.lightRed,
    },
  },
  containerThumbUpActive: {
    backgroundColor: colors.darkGreen,
  },
  thumbUpIcon: {
    '& path': {
      stroke: colors.lightGreen,
    },
  },
  iconActive: {
    '& path': {
      stroke: colors.fontWhite,
    },
  },

  IconGrey: {
    '& path': {
      stroke: colors.blackTint060,
    },
  },
  containerActiveGrey: {
    backgroundColor: colors.bgWhite,
  },
}));

/**
 *
 * @param {Object} props
 * @param {Boolean} props.isActive
 * @param {String} props.className
 * @param {String} props.tooltipText
 * @param {'up'|'down'} props.variant
 * @returns
 */
function Thumb(props) {
  const {
    isActive,
    className,
    tooltipText,
    variant = 'up',
    color = 'default',
    ...restProps
  } = props;

  const classes = useStyles();

  const containerThumbDownColorClass = clsx({
    [classes.containerThumbDownActive]: color === 'default',
    [classes.containerActiveGrey]: color === 'grey',
  });

  const thumbDownIconClass = clsx(classes.iconSize, {
    [classes.thumbDownIcon]: color === 'default',
    [classes.IconGrey]: color === 'grey',
  });

  const containerThumbUpColorClass = clsx({
    [classes.containerThumbUpActive]: color === 'default',
    [classes.containerActiveGrey]: color === 'grey',
  });

  const thumbUpIconClass = clsx(classes.iconSize, {
    [classes.thumbUpIcon]: color === 'default',
    [classes.IconGrey]: color === 'grey',
  });

  const iconActiveClass = clsx({
    [classes.iconActive]: color === 'default',
    [classes.IconGrey]: color === 'grey',
  });

  const renderThumbUp = () => (
    <div
      className={clsx(
        classes.container,
        className,
        isActive && containerThumbUpColorClass
      )}
      {...restProps}
    >
      <ThumbUpIcon
        className={clsx(thumbUpIconClass, isActive && iconActiveClass)}
      />
    </div>
  );

  const renderThumbDown = () => (
    <div
      className={clsx(
        classes.container,
        className,
        isActive && containerThumbDownColorClass
      )}
      {...restProps}
    >
      <ThumbDownIcon
        className={clsx(thumbDownIconClass, isActive && iconActiveClass)}
      />
    </div>
  );

  return (
    <Tooltip title={tooltipText} arrow>
      {variant === 'up' ? renderThumbUp() : renderThumbDown()}
    </Tooltip>
  );
}

export default Thumb;
