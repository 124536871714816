import React, { createContext, useState, useEffect } from 'react';
import { useMediaQuery, createTheme } from '@material-ui/core';

const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [isIPad, setIsIPad] = useState(false);
  const [isIPhone, setIsIPhone] = useState(false);
  const theme = createTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const isiPad =
      typeof window !== 'undefined' &&
      navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1;

    setIsIPad(isiPad);

    const userAgent = window.navigator.userAgent;
    // Test if the device is an iPhone
    const iPhone = /iPhone/i.test(userAgent);
    setIsIPhone(iPhone);
  }, []);

  return (
    <DeviceContext.Provider value={[{ isIPad, isMobile, isTablet, isIPhone }]}>
      {children}
    </DeviceContext.Provider>
  );
};

export { DeviceContext };
