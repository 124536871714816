import React from 'react';

import FtrSvgImage from '../../images/FtrSvgImage';

import DeleteIcon from '../../../assets/icons/delete_icon.svg';


// ----------------------------------------------------------------------------------

function FtrDeleteIconButton(props) {
  const {
    onClick = () => { },
  } = props;

  return (
    <FtrSvgImage
      src={DeleteIcon}
      width={16}
      height={16}
      style={{
        justifyContent: 'end',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

export default FtrDeleteIconButton;
