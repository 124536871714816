import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import MultiSelectChipDropdown from '../../dropdowns/MultiSelectChipDropdown';
import Title from '../../Title';

import { createAlertNotification } from '../../../apis/alertApi';
import {
  getProjectSharedQuoteReceivers,
  createProjectSharedQuoteReceiver,
  shareQuotationForm,
} from '../../../apis/projectApi';

import { validateEmail } from '../../../utils/validators/emailValidator';

import { isEmptyValue } from '../../../utils/commonUtils';

import { notifyError, notifySuccess } from '../../../services/notificationService';

import { colors } from "../../../palette";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  subHeader: {
    fontSize: "1.3rem",
    color: colors.blue060,
    fontWeight: 500,
    paddingLeft: "1vw"
  },
}));

function ShareQuotationFormPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    title = 'Share Quotation Form Via Email',
    userID,
    addresses,
  } = props;

  const multiCheckoutForm = useSelector(state => state.multiCheckoutForm);

  const quotationIDList = multiCheckoutForm?.selectedQuotes?.map(quote => quote.quotationID) || [];
  const itemIDList = multiCheckoutForm?.selectedQuotes?.map(quote => quote.itemID) || [];
  const shippingMode = multiCheckoutForm?.shippingMode;

  const { data: allProjectSharedQuoteReceivers } = useQuery(
    ['allProjectSharedQuoteReceivers', userID],
    () => getProjectSharedQuoteReceivers(userID)
  );

  const [newReceiverName, setNewReceiverName] = useState('');
  const [newReceiverEmail, setNewReceiverEmail] = useState('');
  const [receiverDisplayList, setReceiverDisplayList] = useState([]); // [{key: 0, text: xx}]
  const [newReceiverNameError, setNewReceiverNameError] = useState(null);
  const [newReceiverEmailError, setNewReceiverEmailError] = useState(null);
  const [allReceiversError, setAllReceiversError] = useState(null);
  const [selectedID, setSelectedID] = useState([]);
  const [isShareQuoteLoading, setIsShareQuoteLoading] = useState(false);

  useEffect(() => {
    if (!isEmptyValue(allProjectSharedQuoteReceivers)) {
      setReceiverDisplayList(
        allProjectSharedQuoteReceivers.data.map((receiver, idx) => {
          return {
            key: idx,
            text: receiver.name + ` <${receiver.email}>`,
          };
        })
      );
      setSelectedID(
        allProjectSharedQuoteReceivers.data.map((receiver, idx) => {
          return idx;
        })
      );
    }
  }, [allProjectSharedQuoteReceivers]);

  const handleAddReceiver = () => {
    if (isEmptyValue(newReceiverName)) {
      setNewReceiverNameError('Name is required');
      return;
    }
    if (isEmptyValue(newReceiverEmail)) {
      setNewReceiverEmailError('Email is required');
      return;
    }
    if (!validateEmail(newReceiverEmail)) {
      setNewReceiverEmailError('Email is invalid');
      return;
    }
    // add to display list
    setReceiverDisplayList([
      ...receiverDisplayList,
      { key: receiverDisplayList.length, text: newReceiverName + ` <${newReceiverEmail}>` }
    ]);
    // select the newly added receiver
    setSelectedID([...selectedID, receiverDisplayList.length])

    // update database with new receiver
    const body = { name: newReceiverName, email: newReceiverEmail };
    createProjectSharedQuoteReceiver(userID, body)
      .then(resp => {
        if (resp.message === 'ok') {
          notifySuccess("New receiver has been added!");
        } else {
          notifyError("Unable to add new receiver.");
        }
      }).catch(e => console.log(e));

    // resetting the input fields
    setNewReceiverName('');
    setNewReceiverEmail('');
  }

  const onEmailSelectChange = (receiver) => {
    setSelectedID(receiver);
  }

  const handleShareQuotes = () => {
    if (isEmptyValue(selectedID)) {
      setAllReceiversError('Receivers cannot be empty!');
      return;
    }

    setAllReceiversError(null);
    // disables button and prevents user from clicking it multiple times
    setIsShareQuoteLoading(true);

    // parsing the list to get name and email
    const finalReceivers = selectedID.map(idx => {
      const string = receiverDisplayList[idx].text;
      const name = string.split('<')[0].trim();
      const email = string.split('<')[1].slice(0, -1).trim();
      return { name: name, email: email }
    })
    const body = {
      receiverList: finalReceivers,
      partIdList: itemIDList,
      quotationIDList,
      shippingMode,
      paymentType: multiCheckoutForm.paymentType,
      deliveryInfoID: multiCheckoutForm.deliveryInfoID,
    }

    if (addresses?.shipping?.address) {
      body.addresses = addresses;
    }

    // send the email and CC the sender
    shareQuotationForm(body)
      .then(resp => {
        setIsShareQuoteLoading(false);
        if (resp.message === 'ok') {
          notifySuccess("Quotes shared!");
          handleClose();
        } else {
          notifyError("Unable to share quotes.");
        }
      }).catch((error) => {
        setIsShareQuoteLoading(false);
        notifyError('Unable to share quotes. Please try again later.');
        const alertBody = {
          title: '[FE] Customer share quote failed!',
          errorStack: error.stack,
          additionalInfo: {
            userID,
            body,
          },
        }
        createAlertNotification(alertBody);
      });
  }

  const renderAddNewReceiver = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '16px',
          textAlign: "left"
        }}
      >
        <Typography className={classes.subHeader}>Add New Receivers</Typography>
        <div
          style={{
            padding: '8px',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                variant='outlined'
                margin='dense'
                id='NewReceiverName'
                label='Name'
                placeholder=''
                type='text'
                fullWidth
                error={!!newReceiverNameError}
                helperText={newReceiverNameError}
                onFocus={() => {
                  setNewReceiverNameError(null);
                }}
                onChange={(evt) => setNewReceiverName(evt.target.value)}
                value={newReceiverName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant='outlined'
                margin='dense'
                id='newReceiverEmail'
                label='Email'
                placeholder=''
                type='text'
                fullWidth
                error={!!newReceiverEmailError}
                helperText={newReceiverEmailError}
                onFocus={() => {
                  setNewReceiverEmailError(null);
                }}
                onChange={(evt) => setNewReceiverEmail(evt.target.value)}
                value={newReceiverEmail}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{
                  marginTop: '9px',
                }}
                variant='contained'
                color='primary'
                fullWidth
                onClick={handleAddReceiver}
              >
                Add Receiver
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const renderSharingTo = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '16px',
          textAlign: "left"
        }}
      >
        <Typography className={classes.subHeader}>Sharing To</Typography>
        <div
          style={{
            padding: '8px',
          }}
        >
          <MultiSelectChipDropdown
            id='multi-select-items'
            label='Select Receivers'
            itemList={receiverDisplayList}
            selectedItems={selectedID}
            onSelect={onEmailSelectChange}
            error={!!allReceiversError}
            errorMessage={allReceiversError}
            searchable
          />
        </div>
      </div>
    )
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title contentTitle={title} size='small' />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '2rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          <React.Fragment>
            {renderAddNewReceiver()}
            {renderSharingTo()}
          </React.Fragment>
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box className={classes.space}></Box>
          <Button
            style={{
              marginTop: '9px',
              width: "80%"
            }}
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleShareQuotes}
            disabled={isShareQuoteLoading}
          >
            {isShareQuoteLoading && (
              <div
                style={{
                  width: 'auto',
                  marginRight: '0.5rem',
                }}
              >
                <CircularProgress
                  style={{
                    width: 20,
                    height: 20,
                    fontSize: '0.5rem',
                    color: 'white',
                  }}
                />
              </div>)}
            Share Quotes
          </Button>
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default ShareQuotationFormPopup;
