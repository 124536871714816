import { getUserCurrency } from '../apis/userSettingsApi';

import { isAdminOrHigherRole } from '../utils/roleUtils';

export const SET_VIEW_AS_SUPPLIER = 'SET_VIEW_AS_SUPPLIER';
export const RESET_VIEW_AS_SUPPLIER = 'RESET_VIEW_AS_SUPPLIER';

const setViewAsSupplier = payload => {
  return {
    type: SET_VIEW_AS_SUPPLIER,
    payload,
  };
};

export const resetViewAsSupplier = () => {
  return {
    type: RESET_VIEW_AS_SUPPLIER,
  }
}

export const setViewAsSupplierThunk = viewAsSupplierUser => async (dispatch, getState) => {
  const userRole = getState().auth.user.role;
  const isUserAdminOrHigher = isAdminOrHigherRole(userRole);
  if (!isUserAdminOrHigher) {
    return;
  }
  const { currency } = await getUserCurrency(viewAsSupplierUser.userID);
  dispatch(setViewAsSupplier({ user: viewAsSupplierUser, currency }));
}
