import React from 'react';

import WarningIconMui from '@material-ui/icons/ReportProblemOutlined';

import { colors } from '../../palette';
import FtrTooltip from './FtrTooltip';

function FtrWarningIconTooltip(props) {
  const { toolTipText = '', style, placement = 'top', wrapText = true } = props;
  return (
    <FtrTooltip
      arrow
      disableHoverListener={!toolTipText}
      wrapText={wrapText}
      description={toolTipText}
      placement={placement}
    >
      <WarningIconMui
        style={{
          fontSize: '1.5rem',
          color: colors.warningYellow,
          ...style,
        }}
      />
    </FtrTooltip>
  );
}

export default FtrWarningIconTooltip;
