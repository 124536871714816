import React from 'react';
import clsx from 'clsx';

import { makeStyles, withStyles } from '@material-ui/core/styles/index';

import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from '@material-ui/core';

import { colors } from '../../palette';


// ------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  stepLabel: {
    fontSize: 16,
    fontWeight: `600 !important` || 600,
    color: colors.neutral060,
  },
  stepLabelActive: {
    color: `${colors.blue060} !important` || colors.blue060,
  },
  stepLabelCompleted: {
    color: `${colors.neutral060} !important` || colors.neutral060,
  },
}));

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 15,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  line: {
    borderColor: colors.neutral020,
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const useCustomStepIconStyles = makeStyles({
  root: {
    color: colors.neutral060,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 600,
  },
  circle: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: colors.neutral020,
    textAlign: 'center',
  },
  active: {
    backgroundColor: colors.blue020,
    color: colors.blue060,
  },
  completed: {
    backgroundColor: colors.blue010,
    color: colors.blue030,
    width: 32,
    height: 32,
    zIndex: 1,
    fontSize: 14,
  },
});

function CustomStepIcon(props) {
  const classes = useCustomStepIconStyles();
  const { active, completed, icon } = props;

  return (
    <div
      className={clsx(
        classes.root,
        classes.circle,
        {
          [classes.active]: active,
          [classes.completed]: completed,
        }
      )}
    >
      {icon}
    </div>
  );
}

function FtrStepper(props) {
  const classes = useStyles();

  const { activeStep = 0, steps } = props;

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<CustomConnector />}
    >
      {steps.map((label) => (
        <Step
          key={label}
          classes={{ root: classes.stepRoot }}
        >
          <StepLabel
            classes={{
              label: classes.stepLabel,
              active: classes.stepLabelActive,
              completed: classes.stepLabelCompleted,
            }}
            StepIconComponent={CustomStepIcon}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default FtrStepper;
