import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import CheckMarkSvgIcon from '../../../assets/icons/check_mark.svg';

import FtrButton, { FtrSmallButton } from '../../ftr-components/FtrButton';
import { FlexColumnCenter } from '../../layouts/FlexLayouts';
import { FtrH6, FtrLightText } from '../../ftr-components/FtrTypography';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      borderRadius: '1rem',
    }
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  orderSuccessful: {
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem !important'
    }
  }
}));

function ProjectCreatedCompletePopup(props) {
  const {
    open,
    projectID,
    hasRfq = false,
  } = props;

  const classes = useStyles();

  const history = useHistory();

  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function renderMessage() {
    if (hasRfq) {
      return (
        <FlexColumnCenter style={{ gap: '0.2rem' }}>
          <FtrLightText>
            Your custom quote will be ready within
          </FtrLightText>
          <FtrLightText>
            24 hours in <span style={{ fontWeight: 500 }}>Manage Orders</span> page.
          </FtrLightText>
        </FlexColumnCenter>
      );
    }
    return (
      <FlexColumnCenter style={{ gap: '0.2rem' }}>
        <FtrLightText>
          You may proceed to review and
        </FtrLightText>
        <FtrLightText>
          checkout in <span style={{ fontWeight: 500 }}>Manage Orders</span> page.
        </FtrLightText>
      </FlexColumnCenter>
    );
  }

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={CheckMarkSvgIcon} alt='icon' />
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <FtrH6 className={classes.orderSuccessful}>
          Order Request Successful
        </FtrH6>
        {renderMessage()}
      </DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem 1.5rem',
        }}
      >
        <FtrButton
          color='black'
          size='small'
          variant='text'
          onClick={() => history.push('/')}
        >
          Upload again
        </FtrButton>
        <FtrSmallButton
          onClick={() => history.push(`/manage-projects/${projectID}`)}
          id='go-to-project'
        >
          Manage Orders
        </FtrSmallButton>
      </div>
    </Dialog>
  );
}

export default ProjectCreatedCompletePopup;
