export const SHIPPING_MODES = {
  SAME_DATE: 'SAME_DATE',
  NEXT_DATE: 'NEXT_DATE',
  ONE_THREE_DAYS: 'ONE_THREE_DAYS',
  DHL_PRIORITY: 'DHL_PRIORITY',
  FEDEX_PRIORITY: 'FEDEX_PRIORITY',
  FEDEX_ECONOMY: 'FEDEX_ECONOMY',
}

export const SHIPPING_MODES_DISPLAY_ORDER = Object.values(SHIPPING_MODES);

export const FREE_DELIVERY_FEE_THRESHOLD_IN_SGD = 1000; // SGD
