import { create } from "zustand";

import { getQcOptionPrices } from "../../../apis/qcReportApi";

import { convertPriceToCurrency, extractPriceFromCurrencyString } from "../../../utils/currencyUtils";
import { isEmptyValue } from "../../../utils/commonUtils";

import { QC_FORMAL_CMM_REPORT, QC_NO_QC_REPORT } from "../../../constants/projectConstants";
import { CURRENCY_CODE } from "../../../constants/currencyConstants";

const estimateQcReportCostInfoHook = create((set, get) => ({
  itemList: [],
  qcReports: {
    main: QC_NO_QC_REPORT,
    addOns: [],
    others: [],
  },
  qcOptionPrices: {},
  qcOptionPricesLoading: false,
  mainQcReportCost: () => {
    const qcOptionPrices = get().qcOptionPrices ?? {};
    const qcReports = get().qcReports ?? {};
    if (qcReports.main === QC_FORMAL_CMM_REPORT) {
      return 0;
    }
    const mainPrice = qcOptionPrices[qcReports.main]?.split(' (')[0];
    return mainPrice;
  },
  addOnsQcReportCost: () => {
    const qcOptionPrices = get().qcOptionPrices ?? {};
    const qcReports = get().qcReports ?? {};
    const addOns = qcReports.addOns;
    const addOnsPrice = addOns.reduce((acc, addOn) => {
      const price = extractPriceFromCurrencyString(qcOptionPrices[addOn]?.split(' (')[0]);
      return acc += price;
    }, 0);
    return addOnsPrice;
  },
  totalQcReportCost: () => {
    const qcOptionPrices = get().qcOptionPrices ?? {};
    const qcReports = get().qcReports ?? {};
    let mainPrice = extractPriceFromCurrencyString(qcOptionPrices[qcReports.main]?.split(' (')[0]);
    if (qcReports.main === QC_FORMAL_CMM_REPORT) {
      mainPrice = 0;
    }
    const addOns = qcReports.addOns;
    const addOnsPrice = addOns.reduce((acc, addOn) => {
      const price = extractPriceFromCurrencyString(qcOptionPrices[addOn]?.split(' (')[0] ?? 0);
      return acc += price;
    }, 0);
    const total = mainPrice + addOnsPrice;
    return total;
  },
  setQcReports: (value) => {
    set(() => ({
      qcReports: value,
    }));
  },
  setQcOptionPrices: (value) => {
    set(() => ({
      qcOptionPrices: value,
    }));
  },
  fetchAndUpdateQcOptionPrices: async () => {
    const itemList = get().itemList;
    if (isEmptyValue(itemList)) {
      return;
    }
    const body = itemList.reduce((acc, item) => {
      const totalPriceStr = convertPriceToCurrency({
        price: item.totalPrice ?? 0,
        currency: CURRENCY_CODE.SGD,
        exchangeRate: 1,
      })
      if (!item.price) {
        acc.manualItems.push(item);
      } else {
        acc.ppeItems.push({
          complexity: item.complexity,
          price: extractPriceFromCurrencyString(totalPriceStr),
        });
      }
      return acc;
    }, {
      ppeItems: [],
      manualItems: [],
    });
    set(() => ({
      qcOptionPricesLoading: true,
    }));
    const data = await getQcOptionPrices(body);
    set(() => ({
      qcOptionPricesLoading: false,
      qcOptionPrices: data,
    }));
  },
  updateItemList: (itemList) => {
    set(() => ({
      itemList,
    }));
    get().fetchAndUpdateQcOptionPrices();
  },
  resetQcReport: () => {
    set(() => ({
      itemList: [],
      qcReports: {
        main: QC_NO_QC_REPORT,
        addOns: [],
        others: [],
      },
      qcOptionPrices: {},
      qcOptionPricesLoading: false,
    }));
  },
}));

export default estimateQcReportCostInfoHook;
