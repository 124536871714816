// Import settings
import React, { useEffect } from "react";

// Import color palette
import { colors } from "../palette";

// Import Material UI components
import {
    Button
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

// Import utilities
import clsx from "clsx";

// Style component
const useStyles = makeStyles({
    faqButton: {
        height: 140,
        background: `${colors.fontWhite}`,
        border: `2px solid ${colors.inputLabelGrey}`,
        boxSizing: 'border-box',
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'spaceBetween',
        flexBasis: '100%',
        float: 'left',
        margin: '5px 15px'
    },
    faqText: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.2vw',
        lineHeight: '33px',
        textAlign: 'center',
        letterSpacing: '0.15em',
        color: `${colors.inputLabelGrey}`,
        display: 'block',
        flexWrap: 'wrap'
    },
    faqButtonClicked: {
        boxShadow: `${colors.boxShadowFAQClicked}`,
        border: `2px solid ${colors.inputBorderBlue}`
    },
    faqTextClicked: {
        color: `${colors.blue060}`
    }
})

export function FAQTitle({ faqImage, faqText, isSelected, onClick, changeImage }) {

    // Define styles to use in function
    const classes = useStyles();
    useEffect(changeImage, [isSelected]);
    return (
        <Button className={clsx(classes.faqButton, isSelected && classes.faqButtonClicked)} onClick={onClick} >
            <div style={{ flexDirection: 'column' }}>
                <img src={faqImage} alt="icon" height='50' />
                <div className={clsx(classes.faqText, isSelected && classes.faqTextClicked)}>
                    {faqText}
                </div>
            </div>
        </Button>
    );
}

export default FAQTitle;