import { COUNTRY_NAMES } from './countryConstants';

export const UNIT_TYPES = {
  METRIC: 'metric',
  IMPERIAL: 'imperial',
};

export const UNIT_OPTIONS = {
  [UNIT_TYPES.METRIC]: {
    LENGTH: 'mm',
    WEIGHT: 'kgs',
    VOLUME: 'ml',
  },
  [UNIT_TYPES.IMPERIAL]: {
    LENGTH: 'inches',
    WEIGHT: 'ounces',
    VOLUME: 'pints',
  },
};

export const DISPLAY_UNIT_OPTIONS = {
  [UNIT_TYPES.METRIC]: {
    LENGTH: 'mm',
    WEIGHT: 'kgs',
    VOLUME: 'ml',
  },
  [UNIT_TYPES.IMPERIAL]: {
    LENGTH: '"',
    WEIGHT: 'ounces',
    VOLUME: 'pints',
  },
};

export const MIN_MAX_VALUE_PER_UNIT_TYPE = {
  [UNIT_TYPES.METRIC]: [0.004, 1],
  [UNIT_TYPES.IMPERIAL]: [0.0002, 0.04]
}

export const DISPLAY_UNIT_DROPDOWN = {
  [UNIT_TYPES.METRIC]: 'Metric (mm, kg, ml)',
  [UNIT_TYPES.IMPERIAL]: 'Imperial (inches, ounces, pints)',
}

export const IMPERIAL_LIST_COUNTRIES = [
  COUNTRY_NAMES.AMERICA,
  COUNTRY_NAMES.MYANMAR,
  COUNTRY_NAMES.LIBERIA,
];

/**
 * mm to inch
 */
export const MM_TO_INCH = 0.0393701;

/**
 * inch to mm
 */
export const INC_TO_MM = 25.4;
