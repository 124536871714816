import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { DeleteSweep as DeleteSweepIcon } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles/index';

import { NOT_INTERESTED_STATUS } from '../constants/notInterestedConstants';
import { colors } from '../palette';
import { upsertNotInterestedJob } from '../apis/notInterestedJobApi';
import { notifySuccess } from '../services/notificationService';
import { upsertWatchingJob } from '../apis/watchingJobApi';
import { WATCHING_JOB_STATUS } from '../constants/watchingJob';

const useStyles = makeStyles((theme) => ({
  notInterestedLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.lightGray,
    zIndex: 1,
    border: `2px solid ${colors.lightGray}`,
    borderRadius: '4px',
    minHeight: '1.1rem',
    minWidth: '2rem',
    columnGap: '3px',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '2.5rem',
    height: '1.125rem',
  },
  notInterestedLabelActive: {
    borderColor: colors.errorRed,
    color: colors.errorRed,
  },
  remove: {
    backgroundColor: colors.errorRed,
    color: colors.fontWhite,
    width: '5rem',
    borderRadius: '4px',
    textAlign: 'center',
    height: '1.375rem',
    display: 'grid',
    placeContent: 'center',
    fontSize: '0.875rem',
    cursor: 'pointer',
  },
}));

/**
 *
 * @param {Object} props
 * @param {Boolean} props.isWatching
 * @param {Number[]} props.itemIDs
 * @param {Number} props.projectID
 * @param {() => void} props.callbackSuccess
 * @param {Boolean} props.forAdmin
 * @param {() => void} props.onClick
 * @param {Number} props.totalWatching
 */
export const NotInterestedJob = (props) => {
  const classes = useStyles();
  const {
    itemIDs,
    projectID,
    callbackSuccess = () => null,
    onClick = () => null,
    forAdmin = false,
    totalSuppliers,
    isWatching,
  } = props;

  const [showRemoveButton, setShowRemoveButton] = useState(false);
  const hasSupplier = totalSuppliers > 0


  const handleUpsertNotInterestedJob = (status) => {
    upsertNotInterestedJob({
      itemIDs,
      projectID,
      status,
    }).then(async () => {
      if (isWatching) {
        await upsertWatchingJob({
          itemIDs,
          status: WATCHING_JOB_STATUS.INACTIVE,
        });
      }
      notifySuccess('Project removed');
      callbackSuccess();
    });
  };

  const tooltip = {
    admin: {
      hasSupplier: `${
        totalSuppliers === 1
          ? '1 supplier is'
          : `${totalSuppliers} suppliers are`
      } not interested in this item`,
      hasNoSupplier: 'No suppliers are not interested in this item',
    },
    supplier: 'Click to remove project from your list',
  };

  const renderRemoveButton = () => (
    <Tooltip
      title="Project will be removed from the job board and you won't be able to quote for the project."
      arrow
    >
      <span
        onClick={(evt) => {
          evt.preventDefault();
          handleUpsertNotInterestedJob(NOT_INTERESTED_STATUS.ACTIVE);
        }}
        className={classes.remove}
      >
        REMOVE
      </span>
    </Tooltip>
  );
  const renderIconRemoveButton = () => (
    <Tooltip
      title={
        forAdmin
          ? tooltip.admin[hasSupplier ? 'hasSupplier' : 'hasNoSupplier']
          : tooltip.supplier
      }
      arrow
    >
      <div
        className={clsx(
          classes.notInterestedLabelWrapper,
          totalSuppliers > 0 && classes.notInterestedLabelActive,
        )}
        onClick={(evt) => {
          evt.preventDefault();
          if (!forAdmin) {
            setShowRemoveButton(true);
          }
          onClick(evt);
        }}
      >
        <DeleteSweepIcon />
      </div>
    </Tooltip>
  );

  return (
    <React.Fragment>
      {showRemoveButton ? renderRemoveButton() : renderIconRemoveButton()}
    </React.Fragment>
  );
};
