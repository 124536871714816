import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { EditOrder } from './EditOrder';

import { resetUsersAction } from '../actions';


// -------------------------------------------------------------------------------------------------

const EditQuoteDialog = (props) => {
  const dispatch = useDispatch();

  const { open, setOpen, refreshDataFunc, quotationID, isVerifyQuote = false } = props;

  useEffect(() => {
    return () => {
      dispatch(resetUsersAction());
    }
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <div>
        <IconButton
          onClick={() => setOpen(false)}
          style={{ position: "absolute", right: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <EditOrder
          isDialog={true}
          setOpen={(open) => {
            setTimeout(refreshDataFunc, 1000);
            setOpen(open)
          }}
          quotationID={quotationID}
          isVerifyQuote={isVerifyQuote}
        />
      </div>
    </Dialog>
  );
}

export default EditQuoteDialog;
