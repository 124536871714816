import React, { useEffect, useReducer } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import FtrNumberField from '../../components/ftr-components/FtrNumberField';
import { FtrButton, FtrDropdownV2, FtrTextField, FtrTypography } from '../../components/ftr-components';
import { FlexColumn, FlexRow, FlexRowEnd } from '../../components/layouts/FlexLayouts';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { createFactoremPromotionCodeApi, getFactoremPromotionCodeByIDApi, updateFactoremPromotionCodeApi } from '../../apis/factoremPromotionCodesApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { toUpperCase } from '../../utils/stringUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { PROMO_CODE_PREFIX, PROMO_CODE_TYPE, PROMO_CODE_TYPES_DROPDOWN } from '../../constants/promoCodeConstants';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    maxWidth: 800,
  },
  container: {
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
    },
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const ADD = 'add';
const EDIT = 'edit';

function AddEditFactoremPromotionCodePopup(props) {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const {
    open = false,
    handleClose = () => { },
    mode = ADD,
    codeID,
  } = props;

  const [{ isMobile }] = useDeviceCheck();

  const {
    data: factoremCode,
  } = useQuery(
    ['getFactoremPromotionCodeByIDApi', codeID],
    () => {
      if (isEmptyValue(codeID)) {
        return null;
      }
      return getFactoremPromotionCodeByIDApi(codeID);
    },
  );

  const defaultLocalState = {
    id: '',
    discountPercent: 10,
    discountCap: 0,
    type: PROMO_CODE_TYPE.SINGLE_USE,
  }

  const [
    localState,
    updateLocalState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    },
    defaultLocalState,
  );

  const [formError, setFormError] = React.useState({});
  const [processing, setProcessing] = React.useState(false);

  // ---

  useEffect(() => {
    if (mode === ADD) {
      updateLocalState({});
      return;
    }

    if (mode === EDIT && !isEmptyValue(factoremCode)) {
      let { id, discountPercent, discountCap, type } = factoremCode;

      if (id.startsWith(PROMO_CODE_PREFIX)) {
        id = id.replace(PROMO_CODE_PREFIX, '');
      }

      updateLocalState({
        id,
        discountPercent,
        discountCap,
        type,
      });
    }
  }, [factoremCode, mode]);

  // ---

  const handleSubmit = () => {
    if (isEmptyValue(localState.id)) {
      setFormError({
        id: 'Required field',
      });
      return;
    }

    const submitFunc = mode === ADD
      ? createFactoremPromotionCodeApi
      : updateFactoremPromotionCodeApi;

    setProcessing(true);

    const body = {
      ...localState,
      id: `${PROMO_CODE_PREFIX}${localState.id}`,
      discountPercent: Number(localState.discountPercent),
      discountCap: Number(localState.discountCap),
    }
    submitFunc(body)
      .then(() => {
        const message = mode === ADD
          ? 'Promotion code created successfully'
          : 'Promotion code updated successfully';
        notifySuccess(message);
        handleClose();
        queryClient.invalidateQueries('allFactoremPromotionCodes');
        setProcessing(false);
      })
      .catch((error) => {
        notifyError(error.message || 'Failed to create promotion code');
        setProcessing(false);
      });
  }

  const renderActionButtons = () => {
    return (
      <FlexRowEnd style={{ width: '100%', padding: '1rem' }}>
        <Box className={classes.space}></Box>
        <FtrButton
          variant='text'
          onClick={handleClose}
          disabled={processing}
        >
          Cancel
        </FtrButton>
        <FtrButton
          variant='contained'
          color='blue'
          onClick={handleSubmit}
          disabled={processing}
          loading={processing}
        >
          {mode === ADD ? 'Create' : 'Update'}
        </FtrButton>
      </FlexRowEnd>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      scroll='paper'
      width='md'
      fullWidth
      fullScreen={isMobile}
    >
      <Close className={classes.closeIcon} onClick={handleClose} />
      <FtrTypography type='heading' fontSize='22' style={{ padding: '30px 0px 20px 40px' }}>
        Add New Promotion Code
      </FtrTypography>
      <DialogContent>
        <FlexColumn style={{ padding: '0 2rem', boxSizing: 'border-box' }}>
          <FtrTextField
            title='Code Name'
            variant='outlined'
            margin='dense'
            id='code-name'
            placeholder=''
            type='text'
            fullWidth
            error={!!formError.id}
            onChange={(evt) => {
              setFormError({});
              updateLocalState({ id: toUpperCase(evt.target.value) });
            }}
            value={localState.id}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginTop: 0 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">FCTRM</InputAdornment>,
            }}
            helperText={formError.id || 'FCTRM prefix will be added automatically'}
          />
          <FlexRow style={{ alignItems: 'flex-start' }}>
            <FtrNumberField
              title='Discount Percentage'
              variant='outlined'
              margin='dense'
              id='discount-percentage'
              placeholder=''
              onChange={(evt) => updateLocalState({ discountPercent: evt.target.value })}
              value={localState.discountPercent}
              style={{ marginTop: 0, width: '100%' }}
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>
              }}
            />
            <FtrNumberField
              title='Discount CAP'
              variant='outlined'
              margin='dense'
              id='discount-cap'
              placeholder=''
              onChange={(evt) => updateLocalState({ discountCap: evt.target.value })}
              value={localState.discountCap}
              style={{ marginTop: 0, width: '100%' }}
              helperText='Value in SGD (0 means no CAP)'
              InputProps={{
                startAdornment: <InputAdornment position="start">S$</InputAdornment>,
              }}
            />
          </FlexRow>
          <FtrDropdownV2
            title='Discount Type'
            items={PROMO_CODE_TYPES_DROPDOWN}
            value={localState.type}
            handleChange={(value) => updateLocalState({ type: value })}
            style={{ marginTop: 4 }}
          />
        </FlexColumn>
      </DialogContent>
      <DialogActions>
        {renderActionButtons()}
      </DialogActions>
    </Dialog>
  );
}

export default AddEditFactoremPromotionCodePopup;
