import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Divider,
  Typography,
} from "@material-ui/core";

import PpeControlMaterialsForm from "./ppe-control/PpeControlMaterialsForm";
import { FlexColumn } from "../layouts/FlexLayouts";

import PpeControlSurfaceFinishesForm from "./ppe-control/PpeControlSurfaceFinishesForm";

import { isEmptyValue } from "../../utils/commonUtils";

import { colors } from "../../palette";

const useStyles = makeStyles(() => ({
  container: {
    margin: "25px, 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "25px",
    padding: "25px",
    border: `3px solid ${colors.bgLightGrey}`,
  },
  rowContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "50px",
  },
  inputField: {
    marginTop: "10px",
    fontSize: 15,
    padding: "0px 8px",
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: "border-box",
    borderRadius: "5px",
    color: `${colors.fontGrey}`,
    width: "100%",
  },
}));

function PpeAllowedOrderSelectionForm(props) {
  const classes = useStyles();

  const {
    materialsCheckboxState,
    surfaceFinishesCheckboxState,
  } = props;

  const renderMaterialsCheckboxForm = () => {
    const materialsByTechMapping = materialsCheckboxState.reduce((acc, material) => {
      const mapObj = acc[material.technology] || [];
      mapObj.push(material);
      acc[material.technology] = mapObj;
      return acc;
    }, {
      'CNC': [],
      '3DP': [],
    });

    return (
      <FlexColumn
        style={{
          width: '100%',
          gap: '1rem',
        }}
      >
        {Object.entries(materialsByTechMapping).map(([tech, materials]) => {
          const title = `Material (${tech})`;

          if (isEmptyValue(materials)) {
            return;
          }

          return (
            <PpeControlMaterialsForm
              key={tech}
              title={title}
              materials={materials}
            />
          );
        })
        }
      </FlexColumn>
    );
  }

  const renderSurfaceFinishCheckboxForm = () => {
    const surfacesByTechMapping = surfaceFinishesCheckboxState.reduce((acc, material) => {
      const mapObj = acc[material.technology] || [];
      mapObj.push(material);
      acc[material.technology] = mapObj;
      return acc;
    }, {
      'CNC': [],
      '3DP': [],
    });

    return (
      <FlexColumn
        style={{
          width: '100%',
          gap: '1rem',
        }}
      >
        {Object.entries(surfacesByTechMapping).map(
          ([tech, surfaceFinishes]) => {
            const title = `Surface Finish (${tech})`;

            if (isEmptyValue(surfaceFinishes)) {
              return;
            }

            return (
              <PpeControlSurfaceFinishesForm
                key={tech}
                title={title}
                surfaceFinishes={surfaceFinishes}
              />
            );
          }
        )}
      </FlexColumn>
    );
  }

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant="h6">Allowed Order Selection</Typography>
        <Divider />
      </div>
      <div className={classes.rowContainer}>
        {renderMaterialsCheckboxForm()}
      </div>
      <Divider />
      <div className={classes.rowContainer}>
        {renderSurfaceFinishCheckboxForm()}
      </div>
    </Container>
  );
}

export default PpeAllowedOrderSelectionForm;
