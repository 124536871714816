export const PARTS_LIBRARY_STATUS = {
  PARTS_LIBRARY_ONLY: 'Parts Library Only',
  ORDER_ENQUIRY: 'Order Enquiry',
  PURCHASED: 'Purchased',
};

export const DEFAULT_FILTER_STATUS = Object.values(PARTS_LIBRARY_STATUS).map(
  (value) => ({
    label: value,
    checked: false,
  })
);

export const DATE_CREATED_SORT_VALUES = {
  LATEST_TO_OLDEST: 'Latest to oldest',
  OLDEST_TO_NEWEST: 'Oldest to newest',
};
