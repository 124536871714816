import React, { useEffect, useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Button, Chip, Container, Divider, Typography } from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import { AssignmentTurnedInOutlined, HighlightOff } from '@material-ui/icons';

import {
  getAvailableWhitelistAccountsControl,
  getViewAllJobsWhitelistAccountsControl,
  updateWhitelistAccountsControl,
} from '../../apis/adminControlApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { groupUsersByKeyForDropDown } from '../../utils/selectUserUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import LoadingBackDropText from '../../components/LoadingBackDropText';
import MultiSelectChipDropdownWithCategory from '../../components/dropdowns/MultiSelectChipDropdownWithCategory';
import YesNoPopup from '../../components/popups/YesNoPopup';

import { colors } from '../../palette';
import InfoIcon from '../../components/icons/InfoIcon';

const useStyles = makeStyles(() => ({
  chipDeleteIcon: {
    color: colors.blue060,
    '&:hover': {
      color: colors.blue060,
    },
  },
  chipDeletable: {
    '&:focus': {
      backgroundColor: colors.menuItemSelected,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    backgroundColor: colors.menuItemSelected,
  },
}));

function AllJobsWhiteListAccountsControl() {
  const classes = useStyles();

  const [isProcessing, setIsProcessing] = useState(false);
  const [whitelistAccounts, setWhitelistAccounts] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);
  const [removeAccountPopup, updateRemoveAccountPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedAccount: null,
    }
  );


  const loadData = async () => {
    const allPromises = [];
    setIsProcessing(true);
    allPromises.push(getViewAllJobsWhitelistAccountsControl()
      .then(data => {
        setWhitelistAccounts(data);
      }));
    allPromises.push(getAvailableWhitelistAccountsControl()
      .then(data => {
        setAccountList(groupUsersByKeyForDropDown(data, 'role'));
      }));
    Promise.all(allPromises).then(() => {
      setIsProcessing(false);
    }).catch(() => {
      setIsProcessing(false);
    });
  }
  useEffect(() => {
    loadData();
  }, []);

  const onSupplierSelectChange = (newValues) => {
    setSelectedAccountIds(newValues);
  }

  const handleAddAccounts = () => {
    const body = {
      action: 'ADD',
      userIDList: selectedAccountIds,
    }
    updateWhitelistAccountsControl(body)
      .then(() => {
        loadData();
        const message = selectedAccountIds.length > 1
          ? 'Accounts have been added to the whitelist successfully'
          : 'Account has been added to the whitelist successfully';
        notifySuccess(message);
        setSelectedAccountIds([]);
      })
      .catch(() => {
        notifyError('Updated whitelist failed');
      });
  }

  const handleChipDelete = (account) => {
    updateRemoveAccountPopup({
      open: true,
      selectedAccount: account,
    });
  }

  const handleRemoveWhitelistAccount = () => {
    const body = {
      action: 'REMOVE',
      userIDList: [removeAccountPopup.selectedAccount.userID],
    }
    updateWhitelistAccountsControl(body)
      .then(() => {
        loadData();
        const message = 'Account has been removed successfully';
        notifySuccess(message);
      })
      .catch(() => {
        notifyError('Updated whitelist failed');
      });
  }

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <Alert severity="info">
          Add accounts to this whitelist to allow them to view all jobs
        </Alert>
        <MultiSelectChipDropdownWithCategory
          id="multi-select-suppliers"
          label="Select Account(s)"
          itemList={accountList}
          value={selectedAccountIds}
          onSelect={onSupplierSelectChange}
          searchable
        />
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleAddAccounts}
          disabled={isEmptyValue(selectedAccountIds)}
        >
          Add Account(s)
        </Button>
      </div>
      <div
        style={{
          marginTop: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">
            Whitelist Accounts
          </Typography>
          &nbsp;
          <InfoIcon toolTipText="Accounts in this list can view all jobs regardless any other restriction in other places" />
        </div>
        {isEmptyValue(whitelistAccounts) && (
          <Alert
            color="info"
            icon={<AssignmentTurnedInOutlined fontSize="inherit" />}
          >
            The whitelist is empty
          </Alert>
        )}
        <div
          style={{
            padding: '0 1rem',
          }}
        >
          {!isEmptyValue(whitelistAccounts)
            && whitelistAccounts.map(account => {
              const label = `${account.name} <${account.email}>`;
              return (
                <Chip
                  classes={{
                    deleteIcon: classes.chipDeleteIcon,
                    deletable: classes.chipDeletable,
                  }}
                  className={classes.chip}
                  key={account.userID}
                  label={label}
                  onMouseDown={(event) => event.stopPropagation()}
                  onDelete={() => handleChipDelete(account)}
                  deleteIcon={<HighlightOff />}
                />
              );
            })}
        </div>
      </div>
      <Divider style={{ margin: '2rem 0' }} />
      <div
        style={{
          marginTop: '1rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <Typography variant="h5">
          Categories Description
        </Typography>
        <Alert severity="info">
          <strong>(Category 0) 0hrs:</strong> Every admin account and select Partner accounts should be granted visibility to ALL jobs
        </Alert>
        <Alert severity="info">
          <strong>(Category 1) 0-24hrs:</strong> For the first 24 hours, only suppliers that have been RFQ-ed using &quot;Generate RFQ Email&quot; can have access to the job on Browse Parts
        </Alert>
        <Alert severity="info">
          <strong>(Category 2) 24-72hrs:</strong> From 24 hours till 72 hours, all partners that have qualified for that vertical, based on technology tag user property get access to the job.
        </Alert>
        <Alert severity="info">
          <strong>(Category 3) 72 - ∞ hrs:</strong> After 72 hours, all partners have access to the job
        </Alert>
        <Typography variant="caption" style={{ fontStyle: 'italic' }}>
          * Above hours values (24 & 72) can be adjusted per project via manage project page
        </Typography>
      </div>
      <LoadingBackDropText
        open={isProcessing}
        text="Loading..."
      />
      {removeAccountPopup.open && (
        <YesNoPopup
          open={removeAccountPopup.open}
          handleNo={() => updateRemoveAccountPopup({ open: false })}
          handleYes={() => {
            handleRemoveWhitelistAccount();
            updateRemoveAccountPopup({ open: false });
          }}
          body={`${removeAccountPopup.selectedAccount.name} <${removeAccountPopup.selectedAccount.email}>`}
          title="Are you sure that you want to remove this account?"
        />
      )}
    </Container>
  );
}

export default AllJobsWhiteListAccountsControl;
