import React from 'react';
import HtmlContentFetcher from './HtmlContentDefect';
import {
  findKeyObjectByValue,
  getNotEmptyValueFromObject,
} from '../utils/objectUtils';
import { snakeCaseToTitleCase } from '../utils/stringUtils';
import { FtrH5 } from './ftr-components';
import { FlexColumn } from './layouts/FlexLayouts';

function DefectsVisualization(props) {
  const { defectUrls, selectedDefectUrl, width = 475, height = 475 } = props;

  const _selectedDefectUrl =
    selectedDefectUrl || getNotEmptyValueFromObject(defectUrls);

  if (!_selectedDefectUrl) {
    return null;
  }
  
  return (
    <FlexColumn
      style={{
        border: `1px solid black`,
        margin: '1rem',
        borderRadius: '10px',
        width: `${width}px`,
        gap: 0,
      }}
    >
      <FtrH5 style={{ margin: '1rem' }}>
        Defect:{' '}
        {snakeCaseToTitleCase(
          findKeyObjectByValue(defectUrls, _selectedDefectUrl)
        )}
      </FtrH5>
      <HtmlContentFetcher
        url={_selectedDefectUrl}
        width={`${width}px`}
        height={`${height}px`}
        borderRadius='0 0 10px 10px'
        key={_selectedDefectUrl}
      />
    </FlexColumn>
  );
}

export default DefectsVisualization;
