const queryString = require('query-string');

import { getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';


// --------------------------------------------------------------------------------------------

export const getActionTrackingApi = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/action-tracking?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const trackActionApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/action-tracking`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
