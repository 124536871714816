import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import HorizontalBarChart from './HorizontalBarChart';

/**
 *
 * @param {Object} props
 * @param {String} props.companyName
 * @param {String} props.profilePic
 * @param {*} props.supplierDataDashboard
 * @returns
 */
function FaringFactoremPartnerBars(props) {
  const { companyName, profilePic, supplierDataDashboard } = props;
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <>
      <div
        style={{
          color: '#284AA9',
          fontSize: isSmallScreen ? '0.8rem' : '1.1rem',
          fontWeight: '600',
          marginTop: '0.5rem',
          textAlign: 'center',
          paddingLeft: isSmallScreen ? '0' : '1rem',
        }}
      >
        How&nbsp;<span style={{ color: '#E37512' }}>{companyName}</span>
        &nbsp;is faring to other Factorem Partners
      </div>
      <div>
        <HorizontalBarChart
          type='quality'
          profilePic={profilePic}
          factoremValue={supplierDataDashboard?.avgData?.qualityRate || 0}
          partnerValue={supplierDataDashboard?.data?.qualityRate || 0}
          partnerName={companyName}
        />
        <HorizontalBarChart
          type='delivery'
          profilePic={profilePic}
          factoremValue={
            supplierDataDashboard?.avgData?.onTimeDeliveryRate || 0
          }
          partnerValue={supplierDataDashboard?.data?.onTimeDeliveryRate || 0}
          partnerName={companyName}
        />
        <HorizontalBarChart
          type='rfq-to-quote'
          profilePic={profilePic}
          factoremValue={supplierDataDashboard?.avgData?.rfqToQuote || 0}
          partnerValue={supplierDataDashboard?.data?.rfqToQuote || 0}
          partnerName={companyName}
        />
      </div>
    </>
  );
}

export default FaringFactoremPartnerBars;
