import React from 'react';
import { Link } from 'react-router-dom';

function ResetPasswordNotif({ email }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 0',
        fontSize: '16px',
        textAlign: 'center',
        overflow: 'visible',
      }}
    >
      <img
        style={{
          height: '80px',
        }}
        src='https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/logo-and-words.png'
        alt='factorem'
      />
      <img
        style={{
          width: '70px',
          margin: '20px',
        }}
        src='https://static.hsappstatic.net/ui-images/static-2.451/optimized/successful-send.svg'
        alt='envelope'
      />
      <h1 style={{ padding: '0 15px' }}>Help is on the way</h1>
      <p style={{ maxWidth: '475px', padding: '0 15px' }}>
        We're combing through our records to find the Factorem account for{' '}
        <strong>{email}</strong>
      </p>
      <p style={{ maxWidth: '475px', padding: '0 15px' }}>
        If we find a match, you'll get an email with further instructions. If
        you don't hear from us in the next 15 minutes, please double check that
        you entered the correct email address and check your spam folder.
      </p>
      <p style={{ maxWidth: '475px', padding: '0 15px' }}>
        If you are still having trouble, please contact us via email
        sales@factorem.co or WhatsApp +65 8925 8615.
      </p>
      <p style={{ paddingBottom: '50px' }}>
        Go back to{' '}
        <Link
          to='/login'
          style={{ color: '#62B4FF', textDecoration: 'none' }}
        >
          app.factorem.co/login
        </Link>
      </p>
    </div>
  );
}

export default ResetPasswordNotif;
