import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { capitalize, get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  withStyles,
  TableBody,
  TableCell,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import FtrButton from '../ftr-components/FtrButton';
import FtrTypography, { FtrB2, FtrB3, FtrH3, FtrH6 } from '../ftr-components/FtrTypography';
import ImageWith3DViewer from '../images/ImageWith3DViewer';
import InfoMatchValue from '../info/InfoMatchValue';
import { FlexColumnCenter, FlexRowCenter } from '../layouts/FlexLayouts';

import { RepeatOrderIcon, SimilarOrderIcon } from '../icons/ItemIcon';

import useItemAllQuotationsInfo from '../../hooks/useItemAllQuotationsInfoHook';
import useItemInfo from '../../hooks/useItemInfoHook';
import useQuotationInfo from '../../hooks/useQuotationInfoHook';
import useUserInfo from '../../hooks/useUserInfoHook';

import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import { formatDate } from '../../utils/dateTimeUtils';
import { getBackgroundColorByQuoteStatus, selectQuotationForItem } from '../../utils/quotationUtils';
import { getCadFileTo3DRenderer } from '../../utils/fileUtils';
import { getSurfaceFinishWithCustomizationsText } from '../../utils/itemUtils';
import { getToleranceDisplayText } from '../../utils/toleranceUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { openInNewTab } from '../../utils/navigationUtils';

import { colors } from '../../palette';

import { CURRENCY_CODE } from '../../constants/currencyConstants';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const ColumnHeader = withStyles({
  root: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '0.5rem 2rem',
  },
})(MuiTableCell);

const RowCell = withStyles({
  root: {
    padding: '0.3rem 2rem',
  },
})(MuiTableCell);

const DISPLAY_ROWS = [
  {
    label: 'Item Image',
    render: (item) => {
      const cadFile = getCadFileTo3DRenderer(item?.cadFile || item.originalFiles);
      const imageUrl = item.imageFile || item.twoDImageUrl;

      return (
        <FlexColumnCenter>
          <ImageWith3DViewer
            key={item.itemID}
            cadFile={cadFile}
            twoDImageUrl={imageUrl}
            width={90}
            height={90}
            noBorder
            isLoading={item.imageStatus === 'loading'}
            borderRadius={8}
          />
        </FlexColumnCenter>
      );
    }
  },
  {
    label: 'Item Name',
    render: (item) => {
      return (
        <FtrH3 style={{ fontWeight: 600 }}>
          {item?.name}
        </FtrH3>
      );
    }
  },
  {
    label: 'Customer Name',
    render: (item) => {
      const customer = get(item, 'customer');
      if (isEmptyValue(customer)) {
        return;
      }

      const companyName = customer.cCompanyName || customer.companyName;

      return (
        <FlexColumnCenter style={{ gap: 0 }}>
          <FtrH3 style={{ fontWeight: 600 }}>
            {customer.name} ({customer.userID})
          </FtrH3>
          <FtrB2>{companyName}</FtrB2>
        </FlexColumnCenter>
      );
    }
  },
  {
    label: 'Date Uploaded',
    render: (item) => {
      return (
        <FtrH3 style={{ fontWeight: 600 }}>
          {formatDate(item.datePosted, 'DD-MM-YYYY')}
        </FtrH3>
      );
    }
  },
  {
    label: 'Quote Status',
    render: (item) => {
      if (isEmptyValue(item.itemID)) {
        return <FtrB3>N.A.</FtrB3>
      }

      const quotation = get(item, 'quotation');

      if (isEmptyValue(quotation) || isEmptyValue(quotation.status)) {
        return (
          <FtrH3>Sourcing</FtrH3>
        );
      }

      const bgColor = getBackgroundColorByQuoteStatus(quotation.status);

      return (
        <FlexColumnCenter>
          <FtrH3
            style={{
              backgroundColor: bgColor,
              width: 150,
              textTransform: 'uppercase',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {quotation.status?.split('_').join(' ')}
          </FtrH3>
        </FlexColumnCenter>
      );
    }
  },
  {
    label: 'Quote ID',
    render: (item) => {
      const quotation = get(item, 'quotation');

      if (isEmptyValue(quotation) || isEmptyValue(quotation.quotationID)) {
        return <FtrB3>N.A.</FtrB3>;
      }

      const quotationID = `#${quotation?.quotationID}`;

      return (
        <Link
          onClick={() => openInNewTab(`/order/edit/${quotation?.quotationID}`)}
        >
          <FtrH3 style={{ fontWeight: 600, color: colors.primary }}>
            {quotationID}
          </FtrH3>
        </Link>
      );
    }
  },
  {
    label: 'Customer Price',
    render: (item) => {
      const quotation = get(item, 'quotation');

      if (isEmptyValue(quotation)
        || isEmptyValue(quotation.totalPrice)
        || isNaN(Number(quotation.totalPrice))
      ) {
        return <FtrB3>N.A.</FtrB3>;
      }

      const {
        unitPriceStr,
        totalPriceStr,
      } = convertPriceWithQuantityToCurrency({
        totalPrice: quotation.totalPrice,
        currency: quotation.currency || CURRENCY_CODE.SGD,
        exchangeRate: quotation.exchangeRate || 1,
        quantity: quotation.quantity,
      });

      return (
        <FlexColumnCenter style={{ gap: 0 }}>
          <FtrH3 style={{ fontWeight: 600, color: colors.primary }}>
            {totalPriceStr}
          </FtrH3>
          <FtrB2 style={{ fontWeight: 400, color: colors.primary }}>
            {unitPriceStr} ea
          </FtrB2>
        </FlexColumnCenter>
      );
    }
  },
  {
    label: 'Quantity',
    valueGetter: (item) => {
      const quotation = get(item, 'quotation');

      const quantity = quotation?.quantity || item?.quantity;

      return quantity;
    },
    render: (item) => {
      const quotation = get(item, 'quotation');

      const quantity = quotation?.quantity || item?.quantity;

      return (
        <FtrH3 style={{ fontWeight: 600 }}>
          {quantity}
        </FtrH3>
      );
    },
    check: true,
  },
  {
    label: 'Technology',
    valueGetter: (item) => item.technology,
    render: (item) => {
      return (
        <FtrB3>{item.technology}</FtrB3>
      );
    },
    check: true,
  },
  {
    label: 'Material',
    valueGetter: (item) => {
      const quotation = get(item, 'quotation');

      const material = quotation?.material || item?.material;

      return material;
    },
    render: (item) => {
      const quotation = get(item, 'quotation');

      const material = quotation?.material || item?.material;

      return (
        <FtrB3>{material}</FtrB3>
      );
    },
    check: true,
  },
  {
    label: 'Finishing',
    valueGetter: (item) => {
      const quotation = get(item, 'quotation');

      const finish = getSurfaceFinishWithCustomizationsText(quotation || item);

      return finish;
    },
    render: (item) => {
      const quotation = get(item, 'quotation');

      const finish = getSurfaceFinishWithCustomizationsText(quotation || item);

      return (
        <FtrB3>{finish}</FtrB3>
      );
    },
    check: true,
  },
  {
    label: 'Tolerance',
    valueGetter: (item) => {
      if (isEmptyValue(item.tolerance)) {
        return 'N.A.';
      }

      const tolerance = getToleranceDisplayText({
        tolerance: item.tolerance,
        unitType: item.unitType,
      });

      return tolerance;
    },
    render: (item) => {
      if (isEmptyValue(item.tolerance)) {
        return 'N.A.';
      }

      const tolerance = getToleranceDisplayText({
        tolerance: item.tolerance,
        unitType: item.unitType,
      });

      return (
        <FtrB3>{tolerance}</FtrB3>
      );
    },
    check: true,
  },
  {
    label: 'Supplier Name',
    valueGetter: (item) => {
      const quotation = get(item, 'quotation');

      const text = isEmptyValue(quotation) || isEmptyValue(quotation.userID)
        ? 'N.A.'
        : `${quotation?.supplierName} (${quotation?.userID})`;

      return text;
    },
    render: (item) => {
      const quotation = get(item, 'quotation');

      const text = isEmptyValue(quotation) || isEmptyValue(quotation.userID)
        ? 'N.A.'
        : `${quotation?.supplierName} (${quotation?.userID})`;

      return (
        <FtrB3>{text}</FtrB3>
      );
    },
    check: true,
  },
  {
    label: 'PPE CAD Hash',
    valueGetter: (item) => item.itemHashcodeCad,
    render: (item) => {
      const text = item.itemHashcodeCad || 'N.A.';

      return (
        <FtrB3>{text}</FtrB3>
      );
    },
    check: true,
  },
  {
    label: 'PPE PDF Hash',
    valueGetter: (item) => item.itemHashcodePdf,
    render: (item) => {
      const text = item.itemHashcodePdf || 'N.A.';

      return (
        <FtrB3>{text}</FtrB3>
      );
    },
    check: true,
  },
]

export const ITEM_PPE_TYPE = {
  REPEAT: 'repeat',
  SIMILAR: 'similar',
}

function RepeatSimilarOrderDetailsPopup(props) {
  const classes = useStyles();

  const {
    open,
    onClose = () => { },
    itemID,
    requestItem = {},
    matchedItemID,
    refQuoteID,
    type,
  } = props;
  const { data: item } = useItemInfo(itemID);
  const { data: allItemQuotations } = useItemAllQuotationsInfo(itemID);

  const { data: customer } = useUserInfo(item?.userID);

  const { data: matchedItem } = useItemInfo(matchedItemID);
  const { data: matchedQuotation } = useQuotationInfo(refQuoteID);

  const { data: matchedCustomer } = useUserInfo(matchedItem?.userID);

  const tableData = useMemo(() => {
    const quotation = selectQuotationForItem(allItemQuotations);

    return [
      {
        ...(item || requestItem || {}),
        customer,
        quotation: quotation || requestItem?.quotation,
      },
      {
        ...(matchedItem || {}),
        customer: matchedCustomer,
        quotation: matchedQuotation,
      },
    ];
  }, [
    item?.itemID,
    requestItem,
    matchedItem?.itemID,
    customer?.userID,
    matchedCustomer?.userID,
    matchedQuotation?.quotationID,
    allItemQuotations,
  ]);

  const title = type === ITEM_PPE_TYPE.REPEAT
    ? 'Repeat Order Details'
    : 'Similar Order Details';

  const icon = type === ITEM_PPE_TYPE.REPEAT
    ? <RepeatOrderIcon />
    : <SimilarOrderIcon />

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <Close className={classes.closeIcon} onClick={onClose} />
      <DialogContent
        className={classes.dialogContent}
      >
        <FlexRowCenter style={{ padding: '0.8rem' }}>
          {icon}
          <FtrH6>{title}</FtrH6>
        </FlexRowCenter>
        <TableContainer component={Paper} style={{ borderRadius: '16px' }}>
          <Table size='small' style={{ borderColor: `1px solid ${colors.neutral030}` }}>
            <TableHead style={{ backgroundColor: colors.neutral030 }}>
              <TableRow>
                <ColumnHeader style={{ textAlign: 'left', paddingLeft: '1rem' }}>
                  <FtrH3 style={{ fontWeight: 400 }}>
                    Item ID
                  </FtrH3>
                </ColumnHeader>
                {renderHeaders()}
                <ColumnHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {DISPLAY_ROWS.map((row) => renderRow(row))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      {renderDialogActions()}
    </Dialog>
  );

  function renderHeaders() {
    return tableData.map((item) => {
      return (
        <ColumnHeader align='center' key={item?.itemID}>
          <FlexColumnCenter>
            {renderItemIDLink(item?.itemID)}
          </FlexColumnCenter>
          {!isEmptyValue(item?.itemID) && item?.itemID !== itemID && (
            <FtrB2>
              {`${capitalize(type)} Matched`}
            </FtrB2>
          )}
        </ColumnHeader>
      );
    });
  }

  function renderItemIDLink(itemID) {
    if (isEmptyValue(itemID)) {
      return <FtrB3>N.A.</FtrB3>
    }

    return (
      <Link
        onClick={() => openInNewTab(`/item/edit/${itemID}`)}
      >
        <FtrTypography type='subHeading' fontSize='16'>{itemID}</FtrTypography>
      </Link>
    );
  }

  function renderRow(row) {
    return (
      <TableRow>
        <TableCell>
          <FtrH3 style={{ fontWeight: 400 }}>
            {row.label}
          </FtrH3>
        </TableCell>
        {tableData.map((item) => {
          return (
            <RowCell align='center' key={`${itemID}-${row.label}`}>
              {row.render(item)}
            </RowCell>
          );
        })}
        {!row.check && <RowCell />}
        {row.check && (
          <RowCell>
            <InfoMatchValue
              name={row.label}
              valueCustomer={row.valueGetter(tableData[0])}
              valueSupplier={row.valueGetter(tableData[1])}
            />
          </RowCell>
        )}
      </TableRow>
    );
  }

  function renderDialogActions() {
    return (
      <DialogActions style={{ padding: '1rem 1.5rem' }}>
        <FtrButton
          color='blue'
          variant='contained'
          onClick={onClose}
        >
          OK
        </FtrButton>
      </DialogActions>
    );
  }
}

export default RepeatSimilarOrderDetailsPopup;
