import { useEffect } from 'react';

const DEFAULT_OPTIONS = {
  root: null,
  threshold: 0.3,
  rootMargin: '-100px',
};

export const useIntersectionSelector = (
  selector,
  callback,
  options = DEFAULT_OPTIONS
) => {
  useEffect(() => {
    const element = document.querySelector(selector);
    if (!element) return;

    const observer = new IntersectionObserver((entries) => {
      callback(entries[0]);
    }, options);

    observer.observe(element);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [selector, options, callback]);
};
