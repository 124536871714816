/**
 * Map color names to hex values
 * Color name as key, hex value as value
 */
export const itemColorPalette = {
  "Does not matter": "#ffffff00", // 'Does not matter' option, set color to transparent
  "Yellow C": "#FEDD00",
  "Yellow 012 C": "#FFD700",
  "Orange 021 C": "#FE5000",
  "Warm Red C": "#F9423A",
  "Red 032 C": "#EF3340",
  "Rubine Red C": "#CE0058",
  "Rhodamine Red C": "#E10098",
  "Purple C": "#BB29BB",
  "Violet C": "#440099",
  "Blue 072 C": "#10069F",
  "Reflex Blue C": "#001489",
  "Process Blue C": "#0085CA",
  "Green C": "#00AB84",
  "Black C": "#101820",
  "Cool Gray 11 C": "#53565A",
  "Cool Gray 1 C": "#D9D9D6"
};

/**
 * Save color name and hex value in the
 * string form of 'colorName (hexValue)'
 */
export const mapColorToCode = color => {
  if (color === "Does not matter") {
    return color;
  }
  const colorString = color + " (" + itemColorPalette[color] + ")";
  return colorString;
};

export const getColorHexCode = (palette, color) => {
  if (color === "Does not matter") {
    return color;
  }
  const colorString = color + " (" + palette[color] + ")";
  return colorString;
};
