// Import settings
import React from "react";

// Import color palette
import { colors } from "../palette";

// Import material UI components
import { makeStyles } from "@material-ui/core/styles/index";

// Style components
const useStyles = makeStyles(() => ({
    pageSubDesc: {
        width: '100%',
        fontWeight: 400,
        fontSize: '11pt',
        lineHeight: '18px',
        textAlign: 'center',
        color: colors.fontGrey,
        textTransform: 'uppercase',
        letterSpacing: '1px'
    }
}))

function SubDesc(props) {
    const classes = useStyles();
    const { content } = props;
    return (
        <div className={classes.pageSubDesc}>{content}</div>
    )
}

export default SubDesc;