/**
 * This is proxy to add utils to part upload item
 * which is in formDataAvailable redux
 */
export const reduxStateProxy = {
  // Trap for property getting
  get: function (target, prop, receiver) {
    // this is to get part upload by id in formDataAvailable redux state
    if (prop === 'getPartUploadByID') {
      return function (id) {
        const formDataAvailable = target.item.formDataAvailable;
        const part = formDataAvailable.find(part => part.id === id);
        return part;
      };
    }

    // Use Reflect.get to ensure correct 'this' binding
    return Reflect.get(target, prop, receiver);
  }
};
