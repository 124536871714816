import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';

import {
  AddToPhotos as AddToPhotosIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import FtrFieldLabel from '../ftr-components/FtrFieldLabel';
import { FtrTextField } from '../ftr-components/FtrField';
import Title from '../Title';
import { createXeroInvoiceItems } from '../../apis/xeroApi';
import { isEmptyValue } from '../../utils/commonUtils';
import { notifyError } from '../../services/notificationService';
import { FtrButton, FtrTooltip } from '../ftr-components';
import { stringToNumberArray } from '../../utils/stringUtils';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import FtrInfoIconTooltip from '../ftr-components/FtrInfoIconTooltip';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DEFAULT_ADDITIONAL_NOTES = {
  HSS_CODE: 'HS CODE: 8409.91.9990',
  COUNTRY_OF_ORIGIN: 'Country of Origin: Singapore',
}

/**
 * A React functional component that renders a popup dialog for creating Xero invoice items.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onClose - A callback function to close the popup dialog.
 * @param {Function} [props.onSuccess] - An optional callback function to call after successfully creating Xero invoice items.
 * @param {Boolean} [props.open=false] - A boolean indicating whether the popup dialog is open.
 * @param {Boolean} [props.autoFocus=true] - A boolean indicating whether the input field should autofocus.
 * @return {JSX.Element} The JSX element representing the popup dialog.
 */
function CreateXeroInvoiceItemsPopup(props) {
  const {
    onClose,
    onSuccess = () => {},
    open = false,
  } = props;

  const classes = useStyles();

  const [itemIDs, seItemIDs] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [showDefaultAdditionalNotes, setShowDefaultAdditionalNotes] = useState(true);

  const handleCreateXeroInvoiceItems = () => {
    const itemIDsArray = stringToNumberArray(itemIDs);
    if (isEmptyValue(itemIDsArray)) {
      return;
    }
    setIsLoading(true);
    createXeroInvoiceItems({ itemIDs: itemIDsArray, additionalNotes })
      .then(() => {
        onClose();
        onSuccess();
      })
      .catch((err) => {
        notifyError(err.message || 'Something went wrong!');
      })
      .finally(() => setIsLoading(false));
  };

  const handleAddDefaultOptionalNote = () => {
    setAdditionalNotes(
      [
        DEFAULT_ADDITIONAL_NOTES.HSS_CODE,
        DEFAULT_ADDITIONAL_NOTES.COUNTRY_OF_ORIGIN,
      ].join('\n')
    );
    setShowDefaultAdditionalNotes(false);
  };

  const renderItemIDsField = () => {
    return (
      <div>
        <FtrFieldLabel>Item IDs* (comma separated)</FtrFieldLabel>
        <FtrTextField
          value={itemIDs}
          onChange={(e) => seItemIDs(e.target.value)}
          multiline
          placeholder='e.g. 2300,2301,2302'
          autoFocus={true}
        />
      </div>
    );
  };

  const renderAdditionalNotesField = () => {
    return (
      <div style={{ width: '100%' }}>
        <FtrFieldLabel>
          Notes{' '}
          <FtrInfoIconTooltip toolTipText='Additional Note of Line Item' />
        </FtrFieldLabel>
        <div style={{ position: 'relative' }}>
          <FtrTextField
            value={additionalNotes}
            onChange={(e) => setAdditionalNotes(e.target.value)}
            multiline
            fullWidth
          />
          <div style={{ position: 'absolute', top: -3, right: 0 }}>
            <FtrTooltip description='Clear Text' arrow>
              <IconButton
                aria-label='close'
                onClick={() => {
                  setAdditionalNotes('');
                  setShowDefaultAdditionalNotes(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </FtrTooltip>
          </div>
        </div>
      </div>
    );
  };

  const renderDefaultOptionalNote = () => {
    return (
      <FlexRow>
        <Tooltip
          title={(
            <div>
              <div>{DEFAULT_ADDITIONAL_NOTES.HSS_CODE}</div>
              <div>{DEFAULT_ADDITIONAL_NOTES.COUNTRY_OF_ORIGIN}</div>
            </div>
          )}
          arrow
        >
          <div>
            <FtrButton
              color='blue'
              variant='text'
              startIcon={<AddToPhotosIcon />}
              onClick={handleAddDefaultOptionalNote}
            >
              Add Default Additional Note
            </FtrButton>
          </div>
        </Tooltip>
      </FlexRow>
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <div style={{ padding: '0 1rem' }}>
          <Title contentTitle={'Create Xero Invoice Items'} size='small' />
        </div>
      </DialogTitle>
      <DialogContent>
        <FlexColumn>
          {renderItemIDsField()}
          {renderAdditionalNotesField()}
          {showDefaultAdditionalNotes && renderDefaultOptionalNote()}
        </FlexColumn>
      </DialogContent>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '0.5rem 0 1rem 0',
        }}
      >
        <FtrTooltip description={isEmptyValue(itemIDs) ? 'Item IDs are empty' : ''} arrow>
          <div>
            <FtrButton
              disabled={isEmptyValue(itemIDs)}
              onClick={handleCreateXeroInvoiceItems}
              color='blue'
              loading={isLoading}
            >
              Create
            </FtrButton>
          </div>
        </FtrTooltip>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default CreateXeroInvoiceItemsPopup;
