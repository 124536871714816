import React, { useReducer } from 'react';
import { isEmptyValue } from '../utils/commonUtils';

import FtrSnackbar from '../components/ftr-components/FtrSnackbar';


// ---------------------------------------------------------------------------

function withSnackbarHOC(WrappedComponent) {
  return function WrappedComponentWithLoadingBackDropTextHOC(props) {
    const defaultLocalState = {
      open: false,
      severity: 'info',
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    return (
      <>
        <WrappedComponent
          setSnackbarMessage={(message, additional = {}) => {
            updateLocalState({
              ...defaultLocalState,
              open: true,
              message,
              ...additional,
            });
          }}
          {...props}
        />
        <FtrSnackbar
          {...localState}
          {...props}
          handleClose={() => updateLocalState({ open: false })}
        />
      </>
    );
  };
}

export default withSnackbarHOC;
