import React from 'react';

import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

import ProjectItemDisplayCard from '../cards/ProjectItemDisplayCard';

function ProjectItemGridContainer(props) {
  const { projects } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Grid
        container
        spacing={3}
        style={
          isMobile
            ? { display: 'flex', justifyContent: 'center', marginTop: 0 }
            : undefined
        }
      >
        {projects.map((project, index) => {
          const { items } = project;
          const technology = items[0].technology;
          return (
            <Grid item key={`${project.projectID}_${technology}`} index={index} id={`browse-parts-index-${index}`}>
              <ProjectItemDisplayCard
                project={project}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default ProjectItemGridContainer;
