import React, { useReducer } from 'react';

import MctDeliveryBreakdownPopup from '../components/popups/MctDeliveryBreakdownPopup';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

function withMctDeliveryBreakdownPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      data: {},
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );
    return (
      <>
        <WrappedComponent
          updateMctDeliveryBreakdownPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <MctDeliveryBreakdownPopup
            open={localState.open}
            data={localState.data}
            onClose={() => updateLocalState({ open: false })}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withMctDeliveryBreakdownPopupHOC;
