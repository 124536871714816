/* eslint-disable react/display-name */
import React, { memo } from "react";

import { GetApp } from "@material-ui/icons";

import { CustomGridDataToolbarButton } from "./CustomGridDataToolbarButton";

export const ExportCsvButton = memo((
  ({
    handleClick = () => { },
    disabled = false,
    loading = false,
    iconOnly = false,
    border = true,
    customStyle = {},
    name = 'Export CSV'
  }) => {
    return (
      <CustomGridDataToolbarButton
        icon={<GetApp />}
        handleClick={handleClick}
        disabled={disabled}
        loading={loading}
        name={iconOnly ? '' : name}
        border={border}
        customStyle={customStyle}
      />
    );
  }));
