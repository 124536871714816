import {
  MATERIAL_STR,
  OTHER_MATERIAL_STR,
  OTHER_SURFACE_FINISH_STR,
  QUANTITY_STR,
  SURFACE_FINISH_STR,
  UNIT_QUOTE_STR,
  CMM_PRICE_STR
} from "../../constants/quotationConstants";
import { FORM_ERROR_TYPE } from "../../reducers/projectBulkQuotationsForm";
import { isEmptyValue } from '../commonUtils';
import { isCustomMaterial, isCustomSurfaceFinish } from "../inputUtils";

const VALIDATION_FIELDS = [
  {
    field: QUANTITY_STR,
    message: "Quantity is empty",
  },
  {
    field: UNIT_QUOTE_STR,
    message: "Unit quote is empty",
  },
  {
    field: MATERIAL_STR,
    message: "Material is empty",
  },
]

const validateSingleQuotationFields = (itemID, quotation) => {
  for (const validationField of VALIDATION_FIELDS) {
    const { field, message } = validationField;
    if (isEmptyValue(quotation[field])) {
      return {
        type: FORM_ERROR_TYPE.ITEM,
        itemID,
        quotationID: quotation.id,
        field,
        message,
      };
    }
  }
  if (isCustomMaterial(quotation[MATERIAL_STR]) && isEmptyValue(quotation[OTHER_MATERIAL_STR])) {
    return {
      type: FORM_ERROR_TYPE.ITEM,
      itemID,
      quotationID: quotation.id,
      field: OTHER_MATERIAL_STR,
      message: `Custom material is empty`,
    };
  }
  if (isCustomSurfaceFinish(quotation[SURFACE_FINISH_STR]) && isEmptyValue(quotation[OTHER_SURFACE_FINISH_STR])) {
    return {
      type: FORM_ERROR_TYPE.ITEM,
      itemID,
      quotationID: quotation.id,
      field: OTHER_SURFACE_FINISH_STR,
      message: `Custom surface finishing is empty`,
    };
  }
  if (quotation.isCmmReport && isEmptyValue(quotation[CMM_PRICE_STR])) {
    return {
      type: FORM_ERROR_TYPE.ITEM,
      itemID,
      quotationID: quotation.id,
      field: CMM_PRICE_STR,
      message: `CMM price is empty`,
    };
  }
  return null;
}

const validateItemQuotations = (item) => {
  const { quotations } = item;
  for (const quotation of quotations) {
    const result = validateSingleQuotationFields(item.itemID, quotation);
    if (result) {
      return result;
    }
  }
  return null;
}

export const validateBulkQuotations = (items) => {
  if (items.every(item => item.ignoreItem || item.supplierQuoted)) {
    return {
      type: FORM_ERROR_TYPE.FORM,
      message: `At least one item should be quoted`,
    }
  }
  for (const item of items) {
    if (item.ignoreItem || item.supplierQuoted) {
      continue;
    }
    const result = validateItemQuotations(item);
    if (result) {
      return result;
    }
  }
  return null;
}

export const validateAllowedFileTypesInput = (str) => {
  // allowed empty string
  if (isEmptyValue(str)) {
    return true;
  }

  const regex = new RegExp(/^\.[a-zA-Z0-9]+(,( )*\.([a-zA-Z0-9]+))*$/);
  return regex.test(str);
}
