import { Cookies } from 'react-cookie';

export const GET_ORDERS_LOADING = "GET_ORDERS_LOADING";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";
export const VIEW_ORDER_DETAIL_SUCCESS = "VIEW_ORDER_DETAIL_SUCCESS";
export const OPEN_EDIT_ORDER_PAGE = "OPEN_EDIT_ORDER_PAGE";
export const VIEW_ORDER_ITEM = "VIEW_ORDER_ITEM";

const cookies = new Cookies();

const getOrders = () => {
  return {
    type: GET_ORDERS_LOADING,
  };
};

const getOrdersSuccess = orders => {
  return {
    type: GET_ORDERS_SUCCESS,
    orders
  };
};

const getOrdersFailure = () => {
  return {
    type: GET_ORDERS_FAILURE,
  };
};

const viewOrderDetailsSuccess = (id, props) => {
  props.history.push(`/orders/${id}`)
  return {
    type: VIEW_ORDER_DETAIL_SUCCESS,
    id,
    props
  }
}

const viewOrderItem = (id, props) => {
  props.history.push(`/items/${id}`)
  return {
    type: VIEW_ORDER_ITEM,
    id,
    props
  }
}

const openEditOrderPageSuccess = (id, props) => {
  props.history.push(`/order/edit/${id}`);
  return {
    type: OPEN_EDIT_ORDER_PAGE,
    id,
    props
  }
}

export const getMyOrderDetails = (id, props) => dispatch => {
  dispatch(viewOrderItem(id, props));
}

export const displayEditOrderPage = (id, props) => dispatch => {
  dispatch(openEditOrderPageSuccess(id, props));
}

export const getAllOrders = () => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/quotations`;

  fetch(requestUrl, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.get('token')}`,
    },
  }).then(
    function(response) {
      dispatch(getOrders());
      response.json().then(
        orders => {
          if (orders.success) {
            dispatch(getOrdersSuccess(orders.data));
          } else {
            dispatch(getOrdersFailure());
          }
        }
      )
    }
  ).catch(
    function() {
      dispatch(getOrdersFailure());
    }
  )
};

export const displayOrderDetailsPage = (id, props) => dispatch => {
  dispatch(viewOrderDetailsSuccess(id, props));
};
