import { PDF_IMAGES_EXTENSIONS } from '../constants/fileConstants';

import { updateTechnicalDrawingDB } from '../apis/technicalDrawingExtractionApi';
import { getMaterialsByTechnologyWithCache, getSurfaceFinishingOptionsWithCache } from '../apis/configurationApi';

import { filterFilesByExtension } from './fileUtils';
import { isEmptyValue } from './commonUtils';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
} from './inputUtils';


export const fetchMaterialOptionsForTDE = async (technology) => {
  try {
    const response = await getMaterialsByTechnologyWithCache({ technology });
    const combinedOptions = response.options.flatMap(option => option.options);
    return [].concat(...combinedOptions); // Flatten nested arrays
  } catch (error) {
    return []; // Return empty array on error
  }
};

export const fetchFinishOptionsForTDE = async (technology, material) => {
  try {
    const response = await getSurfaceFinishingOptionsWithCache({ technology, material });
    return {
      options:response.options,
      default:response.default
    };
  } catch (error) {
    return {
      options: [],
      default: null
    }; // Return empty array on error
  }
};

export const isNotFound = (value) => {
  if (isEmptyValue(value)) {
    return true;
  }

  return [
    'not found',
    'not_found',
    'notfound',
    'not-found',
    'not found.',
  ].includes(`${value}`.toLowerCase());
}

export const getTechnicalDrawingFiles = (files) => {
  if (isEmptyValue(files)) {
    return [];
  }

  const filteredFiles = filterFilesByExtension(
    files,
    PDF_IMAGES_EXTENSIONS
  );

  const no3DFile = filteredFiles.length === files.length;
  // If all are PDFs, take from the second file onwards, else return filtered files
  return no3DFile ? filteredFiles.slice(1) : filteredFiles;
};

//
const prepareEditedFields = ({
  editPart,
  mapper,
  baseKey = null
}) => {
  const editedFields = isEmptyValue(baseKey) ? {} : {
    [baseKey]: {}
  }

  Object.entries(mapper).forEach(([key, mappedKey]) => {
    let editedField = editPart?.[key];

    // Handle custom material and surface finish
    if (isCustomMaterial(editedField)) {
      editedField = editPart?.otherMaterial;
    } else if (isCustomSurfaceFinish(editPart[key])) {
      editedField = editPart?.otherSurfaceFinish;
    }

    // Handle color field defaulting to 'materialColor' if not provided
    if (key === 'color') {
      editedField = editedField ?? editPart['materialColor'];
    }

    // Convert 'editTolerance' to a float
    if (key === 'editTolerance') {
      editedField = parseFloat(editedField);
    }

    if (isEmptyValue(baseKey)) {
      editedFields[mappedKey] = editedField;
    } else {
      editedFields[baseKey][mappedKey] = editedField;
    }
  });

  return editedFields;
}

// Reusable function to update the technical drawing DB
export const updateTdeDataFromState = ({
  editPart, tdeState, mapper, baseKey = null
}) => {
  const { pdf_hash: pdfHash } = tdeState.output || {};

  if (isNotFound(pdfHash)) {
    return;
  }

  const editedFields = prepareEditedFields({
    editPart, mapper, baseKey
  });

  updateTechnicalDrawingDB({
    itemID: editPart.itemID,
    pdfHash,
    editedFields,
  });
};
