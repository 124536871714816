import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Badge, Tab, Tabs } from '@material-ui/core';

import AllItemsDataGridPagination from './manage-items/AllItemsDataGridPagination';
import AllRequestQuotesDataGrid from './manage-items/AllRequestQuotesDataGrid';
import AssignPartsPage from './manage-items/AssignPartsPage';
import CancelledItemsDataGrid from './manage-items/CancelledItemsDataGrid';

import { colors } from '../palette';
import { connect } from 'react-redux';
import { isAdminOrHigherRole } from '../utils/roleUtils';
import { ROLE_TYPES } from '../constants';
import AllPartsLibrary from './manage-items/AllPartsLibrary';

const useStyles = makeStyles(() => ({
  body: {
    padding: '0 1rem',
  },
  tabRoot: {
    opacity: 1,
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    opacity: 1,
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  ALL_ITEMS: 'All Items',
  ALL_ITEMS_DATA_GRID: 'All Items Data Grid',
  ALL_ITEMS_YET_TO_RFQ: 'All Items (Yet to RFQ)',
  YET_TO_RFQ: 'Yet to RFQ',
  CANCELLED_ITEMS: 'Cancelled Items',
  ASSIGN_PART: 'Assign Part',
  ALL_REQUEST_QUOTES: 'Rocket Quote Requests',
  PARTS_LIBRARY: 'Parts Library',
};

const TABS = [
  TAB_KEYS.ALL_ITEMS,
  TAB_KEYS.YET_TO_RFQ,
  TAB_KEYS.CANCELLED_ITEMS,
  TAB_KEYS.ASSIGN_PART,
  TAB_KEYS.ALL_REQUEST_QUOTES,
  TAB_KEYS.PARTS_LIBRARY,
];

function ManageItems(props) {
  const classes = useStyles();

  const {
    noRequestQuotes,
  } = props;

  const { role } = props;

  const [viewableTabs, setViewableTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (isAdminOrHigherRole(role)) {
      setViewableTabs(TABS);
      return;
    }
    if ([ROLE_TYPES.SALES_CONSULTANT].includes(role)) {
      setViewableTabs([TAB_KEYS.ALL_ITEMS]);
      return;
    }
    if ([ROLE_TYPES.ROCKET_MAN, ROLE_TYPES.REVIEWER].includes(role)) {
      setViewableTabs([TAB_KEYS.ALL_ITEMS, TAB_KEYS.ALL_REQUEST_QUOTES]);
      return;
    }
  }, [role]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = viewableTabs[selectedTab];
    switch (tabName) {
      case TAB_KEYS.ALL_ITEMS:
        return <AllItemsDataGridPagination />;
      case TAB_KEYS.YET_TO_RFQ:
        return (
          <AllItemsDataGridPagination
            filterByYetToRfq
            csvFileName='Yet to RFQ Items'
            headerText='Only displays parts that are in Sourcing and Yet to RFQ over the past 3 months'
          />
        );
      case TAB_KEYS.ALL_ITEMS_DATA_GRID:
        return <AllItemsDataGridPagination />;
      case TAB_KEYS.CANCELLED_ITEMS:
        return <CancelledItemsDataGrid />;
      case TAB_KEYS.ASSIGN_PART:
        return <AssignPartsPage />;
      case TAB_KEYS.ALL_REQUEST_QUOTES:
        return <AllRequestQuotesDataGrid />;
      case TAB_KEYS.PARTS_LIBRARY:
        return <AllPartsLibrary />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <h1>Manage Items</h1>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {viewableTabs.map((tab) => {
          if (tab === TAB_KEYS.ALL_REQUEST_QUOTES) {
            return (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selectedTab,
                }}
                key={tab}
                label={(
                  <Badge
                    badgeContent={noRequestQuotes}
                    color="error"
                  >
                    {tab}
                  </Badge>
                )}
              />
            )
          }
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
    noRequestQuotes: state.items?.noRequestQuotes,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(ManageItems);
