import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import CustomerProjectsMuiTable from './CustomerProjectsMuiTable';
import FtrOrderAgainButton from '../../components/ftr-components/buttons/FtrOrderAgainButton';
import { FtrB3 } from '../../components/ftr-components';
import { TopRightPositionAbsolute } from '../../components/ftr-components/FixedPosition';

import withOrderProjectsAgainPopupHOC from '../../hocs/withOrderProjectsAgainPopupHOC';
import withProjectsTableLoadMoreHOC from '../../hocs/withProjectsTableLoadMoreHOC';

import useOrderSelectionForOrderAgainZustand from '../../hooks/useOrderSelectionForOrderAgainZustand';

import { isEmptyValue } from '../../utils/commonUtils';

import ManageProjectsOrdersTabContext from '../../context/ManageProjectsOrdersTabContext';

import { getOrdersCompletedTabColumns } from '../../constants/customerProjectsMuiTableConstants';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const OrdersCompleteTable = withProjectsTableLoadMoreHOC(CustomerProjectsMuiTable);

function ManageProjectsOrdersCompleteTabPage(props) {
  const {
    loading,
    projects = [],
    allItemsMapping = {},
    statusFilterList = [],
    createdDateFilter = null, // eg: 'Last 7 days', 'Last 30 days'
    updateOrderProjectAgainPopupHOCState = () => { },
  } = props;

  const history = useHistory();

  const {
    selectedRows,
    resetRows,
    addRow,
    removeRow,
  } = useOrderSelectionForOrderAgainZustand((state) => state);

  const ordersTabColumns = useMemo(() => getOrdersCompletedTabColumns({
    addRow,
    removeRow,
  }), [
    addRow,
    removeRow,
  ]);

  useEffect(() => {
    resetRows();

    return () => {
      resetRows();
    }
  }, []);

  const handleRowClick = (rowData) => {
    history.push({
      pathname: `/customer-orders/projects/${rowData.projectID}`,
      state: { projectID: rowData.projectID }
    });
  };

  const renderLoading = () => {
    return (
      <FtrB3 style={{ color: colors.neutral060 }}>
        Loading...
      </FtrB3>
    );
  }

  return (
    <ManageProjectsOrdersTabContext.Provider
      value={{
        updateOrderProjectAgainPopupHOCState,
        allItemsMapping,
      }}
    >
      {loading && renderLoading()}
      {!loading && (
        <div style={{ position: 'relative' }}>
          <OrdersCompleteTable
            projects={projects}
            onRowClick={handleRowClick}
            columns={ordersTabColumns}
            statusFilterList={statusFilterList}
            createdDateFilter={createdDateFilter}
            createdDateField='createdDate'
            rowKey='id'
          />
          <TopRightPositionAbsolute style={{ top: '-4rem' }}>
            <FtrOrderAgainButton
              disabled={isEmptyValue(selectedRows)}
              onClick={() => {
                const orderAgainItemList = selectedRows.map(rowData => rowData.items).flat()
                  .map(item => allItemsMapping[item.itemID]);
                updateOrderProjectAgainPopupHOCState({
                  open: true,
                  orderAgainItemList: orderAgainItemList,
                });
              }}
            />
          </TopRightPositionAbsolute>
        </div>
      )}
    </ManageProjectsOrdersTabContext.Provider>
  );
}

export default withOrderProjectsAgainPopupHOC(ManageProjectsOrdersCompleteTabPage);
