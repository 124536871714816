import React from 'react';
import { Avatar, Tooltip, makeStyles, useMediaQuery } from '@material-ui/core';
import { TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT } from '../../constants/partnerDataDashboardConstants';
import partnerTierIcon from '../../assets/icons/award.svg';
import deliveryIcon from '../../assets/icons/delivery.svg';
import rfqToQuoteIcon from '../../assets/icons/rfq_to_quote.svg';
import factoremLogo from '../../assets/img/factorem-large-logo.png';

// Style components
const useStyles = makeStyles((theme) => ({
  horizontalBar: {
    display: 'flex',
    width: '100%',
    height: '33px',
    background: 'linear-gradient(to right, #fce3dc, #fffcf2, #d5eade)',
    alignItems: 'center',
    padding: '0 2rem',
    boxSizing: 'border-box',
    borderRight: '2px solid #ceafa6',
    borderLeft: '2px solid #ceafa6',
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem',
    },
  },
  containerBar: {
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  bar: {
    width: '100%',
    height: '5px',
    backgroundColor: 'red',
    background: 'linear-gradient(to right, #ef502b, #fec62e, #2ec57d)',
  },
  divider: {
    position: 'absolute',
    width: '5px',
    height: '33px',
    backgroundColor: 'green',
    transform: 'translate(-50%, -50%)',
    top: '50%',
  },
  containerHorizontalBar: {
    height: 'max-content',
    width: '100%',
    padding: '1rem 0 0 0',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0 0 0',
    },
  },
  containerRenderPersonPin: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
  },
  title: {
    color: '#A95D5C',
    fontSize: '15px',
    fontWeight: 'bold',
    margin: '0',
    textAlign: 'center',
    marginTop: '-5px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  logo: {
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    display: 'grid',
    placeItems: 'center',
    margin: '6px',
    background: '#FBDBCF',
  },
  renderPersonPin: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    height: 'max-content',
  },
  backgroundAvatar: {
    backgroundColor: 'white',
    width: '25px',
    height: '25px',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '15px',
    left: '50%',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    height: '25px',
    maxWidth: '25px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

/**
 * 
 * @param {object} props
 * @param {'quality' | 'delivery' | 'rfq-to-quote'} props.type
 * @param {String|Number} props.factoremValue
 * @param {String|Number} props.partnerValue
 * @param {String} props.partnerName
 * @param {String} props.profilePic
 */
function HorizontalBarChart(props) {
  const {
    type = 'quality',
    factoremValue = '60',
    partnerValue = '20',
    partnerName = 'Partner',
    profilePic,
  } = props;

  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const GRAPH_HORIZONTAL_BAR_CONSTANTS = {
    quality: {
      tooltip: TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.qualityRate,
      imgIcon: partnerTierIcon,
      title: 'Quality',
    },
    delivery: {
      tooltip: TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.onTimeDeliveryRate,
      imgIcon: deliveryIcon,
      title: 'Delivery',
    },
    'rfq-to-quote': {
      tooltip: TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.rfqToQuote,
      imgIcon: rfqToQuoteIcon,
      title: 'RFQ-to-Quote',
    },
  };

  const renderPersonPin = (profilePic) => {
    return (
      <div className={classes.renderPersonPin}>
        <span
          style={{
            backgroundColor: '#f69a5d',
            width: '30px',
            height: '30px',
            display: 'block',
            borderRadius: '50%',
          }}
        />
        <span
          style={{
            borderWidth: '12px 12px 0',
            borderColor: '#f69a5d transparent transparent',
            display: 'block',
            width: '0',
            height: '0',
            borderStyle: 'solid',
            marginTop: '-6px',
          }}
        />
        <div className={classes.backgroundAvatar}>
          <Avatar
            src={profilePic}
            style={{ width: profilePic ? 'max-content' : '25px' }}
            className={classes.avatar}
          />
        </div>
      </div>
    );
  };

  const renderHorizontalBar = () => {
    return (
      <div className={classes.horizontalBar}>
        <div className={classes.containerBar}>
          <span className={classes.bar} />
          <span
            className={classes.divider}
            style={{ left: '0', backgroundColor: '#ef502b' }}
          />
          <span
            className={classes.divider}
            style={{ left: '25%', backgroundColor: '#fd862a' }}
          />
          <span
            className={classes.divider}
            style={{ left: '50%', backgroundColor: '#fec62e' }}
          />
          <span
            className={classes.divider}
            style={{ left: '75%', backgroundColor: '#8dc44b' }}
          />
          <span
            className={classes.divider}
            style={{ left: '100%', backgroundColor: '#2ec57d' }}
          />
          <div
            style={{ left: partnerValue + '%' }}
            className={classes.containerRenderPersonPin}
          >
            <Tooltip title={`${partnerName}: ${partnerValue}%`}>
              {renderPersonPin(profilePic)}
            </Tooltip>
          </div>
          <div
            style={{ left: factoremValue + '%' }}
            className={classes.containerRenderPersonPin}
          >
            <Tooltip title={`Factorem Average: ${factoremValue}%`}>
              {renderPersonPin(factoremLogo)}
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', marginTop: '10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: '75px',
          paddingRight: isSmallScreen ? '5px' : '15px',
        }}
      >
        <Tooltip title={GRAPH_HORIZONTAL_BAR_CONSTANTS[type].tooltip}>
          <div className={classes.logo}>
            <img
              style={{ width: '29px' }}
              src={GRAPH_HORIZONTAL_BAR_CONSTANTS[type].imgIcon}
              alt={`Logo ${type}`}
            />
          </div>
        </Tooltip>
        <span className={classes.title}>
          {GRAPH_HORIZONTAL_BAR_CONSTANTS[type].title}
        </span>
      </div>
      <div className={classes.containerHorizontalBar}>
        {renderHorizontalBar()}
      </div>
    </div>
  );
}

export default HorizontalBarChart;
