/* eslint-disable react/display-name */
import React, { Fragment, useRef, useState } from 'react';

import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  menuItem: {
    borderRadius: 12,
    margin: '0 4px',
    fontWeight: 400,
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
  },
}));

const withUserMenu = (WrappedComponent) => {
  return (props) => {
    const classes = useStyles();

    const history = useHistory();

    const {
      handleLogout = () => { },
    } = props

    const anchorRef = useRef(null);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickProfile = () => {
      history.push('/profile');
      setOpen(false);
    }

    return (
      <Fragment>
        <WrappedComponent
          showUserMenu={() => setOpen(true)}
          userMenuAnchorRef={anchorRef}
          {...props}
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          style={{
            zIndex: 1,
          }}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom'
                  ? 'center top'
                  : 'center bottom',
              }}
            >
              <Paper style={{ borderRadius: 8 }}>
                <ClickAwayListener
                  onClickAway={handleClose}
                >
                  <MenuList
                    id="menu-list-grow"
                  >
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleClickProfile}
                      id='profile-menu'
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleLogout}
                      data-cy='logout-btn'
                    >
                      Log out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Fragment>
    );
  };
};

export default withUserMenu;
