import { useQuery } from 'react-query';

import { getQuotationByID } from '../apis/quotationApi';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

const useQuotationInfo = (quotationID) => {
  const {
    data,
    refetch,
  } = useQuery(
    ['getQuotationByID', quotationID],
    () => {
      if (isEmptyValue(quotationID)) {
        return undefined;
      }

      return getQuotationByID(quotationID);
    },
  );

  return {
    data,
    refetch,
  };
};

export default useQuotationInfo;
