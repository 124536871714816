import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { useQuery } from 'react-query';

import InputPartnerData from '../../components/partnerDataPanel/InputPartnerData';
import UploadPartnerDataHistory from '../../components/partnerDataPanel/UploadPartnerDataHistory';
import CurrentVersionPartnerData from '../../components/partnerDataPanel/CurrentVersionPartnerData';
import { getSupplierDashboardUploadHistory } from '../../apis/supplierDataDashboard';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr  1fr',
    gap: '25px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    padding: '0',
  },
}));

function PartnerDataPanel() {
  const classes = useStyles();
  const { data: listUploadHistories, isSuccess } = useQuery(
    'getSupplierDashboardUploadHistory',
    getSupplierDashboardUploadHistory
  );

  return (
    <div className={classes.body}>
      <Container className={classes.container}>
       {isSuccess && <CurrentVersionPartnerData data={listUploadHistories} />}
        <div className={classes.twoColumns}>
          <InputPartnerData />
          {isSuccess && <UploadPartnerDataHistory data={listUploadHistories} />}
        </div>
      </Container>
    </div>
  );
}

export default PartnerDataPanel;
