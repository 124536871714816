import { getSupplierAdminControl } from '../apis/adminControlApi';

export const GET_SUPPLIER_ADMIN_CONTROL = 'GET_SUPPLIER_ADMIN_CONTROL';

export const getSupplierAdminControlAction = (userId) => (dispatch) => {
  getSupplierAdminControl(userId).then((data) => {
    dispatch({
      type: GET_SUPPLIER_ADMIN_CONTROL,
      payload: data,
    });
  });
};
