import { ITEM_STATUS_TEXT_MAPPING } from "./itemStatus";

export const DEFAULT_ITEM_STATUS_FILTER_LIST = Object.values(ITEM_STATUS_TEXT_MAPPING).map(
  (value) => ({
    label: value,
    checked: false,
  })
);

export const DATE_CREATED_FILTER_LIST = [
  {
    label: 'Last 7 days',
    checked: false,
  },
  {
    label: 'Last 14 days',
    checked: false,
  },
  {
    label: 'This month',
    checked: false,
  },
  {
    label: 'Last month',
    checked: false,
  },
  {
    label: 'Last 3 months',
    checked: false,
  },
  {
    label: 'Last 6 months',
    checked: false,
  },
  {
    label: 'This year',
    checked: false,
  },
  {
    label: 'Last year',
    checked: false,
  },
]
