import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sortBy } from "lodash";

import { makeStyles } from "@material-ui/core/styles";

import CustomerProjectHistoryDataGrid from "../../components/tables/CustomerProjectHistoryDataGrid";

import { getAllPastProjectsByUser } from "../../apis/projectApi";

import { colors } from "../../palette";

const useStyles = makeStyles(() => ({
  body: {
    height: "100%"
  },
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: colors.fontWhite
    }
  },
  formComponent: {
    height: "75%",
    padding: '1rem 2rem',
  },
}));

export function CustomerProjectHistory(props) {
  const classes = useStyles();
  const { userID } = props;

  const { data: allPastProjects } = useQuery(
    ['allPastProjects', userID],
    () => getAllPastProjectsByUser(userID)
      .then(data => sortBy(data, item => item.itemID)
      .reverse())
  );

  return (
    <div className={classes.body}>
      <Fragment>
        <div className={classes.formComponent}>
          <CustomerProjectHistoryDataGrid data={allPastProjects}/>
        </div>
      </Fragment>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID
  };
}

const withConnect = connect(mapStateToProps, null);

export default withRouter(withConnect(CustomerProjectHistory));
