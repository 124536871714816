import React from 'react';
import FeedbackThumbs from './fields/FeedbackThumbs';
import DisplayFeedbackCard from './cards/DisplayFeedbackCard';
import { FlexRowEnd } from './layouts/FlexLayouts';

// -------------------------------------------------------------------------------------------------

export default function CustomerFeedbackButtons(props) {
  const {
    upIsActive = false,
    downIsActive = false,
    handleUpClick = () => { },
    handleDownClick = () => { },
    handleFeedbackSubmit = () => { },
    handleFeedbackClose = () => { },
    showFeedbackInput = false,
    feedbackType = "Price",
    style
  } = props

  return (
    <FlexRowEnd style={style}>
      {showFeedbackInput ? (
        <DisplayFeedbackCard
          onClickAction={handleFeedbackSubmit}
          onCloseAction={handleFeedbackClose}
          text={`Target ${feedbackType}?`}
        />
      ) : (
        <FeedbackThumbs
          color={'grey'}
          upIsActive={upIsActive}
          upTooltip={`${feedbackType} is good`}
          upOnclick={handleUpClick}
          downIsActive={downIsActive}
          downTooltip={`${feedbackType} too high`}
          downOnclick={handleDownClick}
        />
      )}
    </FlexRowEnd>
  )
}
