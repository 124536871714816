import * as ppeApi from '../apis/ppeApi';
import { getSupportedFileTypesWithCache } from '../apis/configurationApi';

import { getPpeMaterialCode } from '../utils/ppeUtils';
import {
  getItemMaterial,
  getItemSurfaceFinish,
  is3DPTechnology,
} from '../utils/itemUtils';

import {
  surfaceFinishMapping,
  techMapping,
  toleranceMapping,
} from '../constants/PPEConstants';
import { isCustomTechnology } from '../utils/inputUtils';

import { TWO_D_IMAGE_URLS_KEY } from '../constants';

// Return a customer price for one item
export const generatePPEPrice = async (item, userAgentInfo = null) => {
  const orderAgain = item.orderAgain === true;
  // Find the first valid 3D design file
  let file_location = null;
  let fileSize = 0;

  const {
    fileLocation: newFileLocation,
    fileSize: newFileSize,
  } = item.onlySingleFile
    ? await geSingleFile()
    : {
        fileLocation: item.cadPart.join(','),
        fileSize: item.fileSize,
      };

  file_location = newFileLocation;
  fileSize = newFileSize;

  async function geSingleFile() {
    const { supportFileTypes } = await getSupportedFileTypesWithCache({
      allSupportedTypes: true,
    });
    let newFileSize = 0;
    let pdfFileSize = 0;
    let newFileLocation = '';
    let newPdfFileLocation = '';
    for (const partObj of item.cadPartObjectList) {
      const fileExtension = partObj.link
        .split('.')
        .pop()
        .toLowerCase();
      if (fileExtension === 'pdf') {
        pdfFileSize = partObj.fileSize;
        newPdfFileLocation = partObj.link;
      }
      if (
        supportFileTypes.includes(fileExtension) &&
        newFileSize < partObj.fileSize &&
        fileExtension !== 'pdf'
      ) {
        newFileSize = partObj.fileSize;
        newFileLocation = partObj.link;
      }
    }
    if (newFileSize === 0 && pdfFileSize) {
      newFileSize = pdfFileSize;
      newFileLocation = newPdfFileLocation;
    }
    return {
      fileSize: newFileSize,
      fileLocation: newFileLocation,
    };
  }

  let { technology, otherTechnology } = item;
  if (isCustomTechnology(technology)) {
    technology = otherTechnology;
  }
  const material = getItemMaterial(item);
  const surfaceFinish = getItemSurfaceFinish(item);
  const materialCode = getPpeMaterialCode({ technology, material });
  const tolerance = item.tolerance;
  const toleranceCheck = toleranceMapping(Number(tolerance));
  const parameters = {
    file_location,
    material: materialCode || material,
    finish: surfaceFinishMapping[surfaceFinish] || surfaceFinish,
    tolerance,
    toleranceCheck,
    quantity: Number(item.qty),
    fileSize,
    itemID: item.itemID,
    specialThreads: item.specialThreads
  };
  if (is3DPTechnology(technology)) {
    parameters.threeDTechnology = item.threeDTechnology;
    parameters.threeDInfill = item.threeDInfill;
    parameters.threeDLayerThickness = item.threeDLayerThickness;
  }
  let payload = {
    id: item.id,
    process: techMapping[technology] || technology,
    parameters,
    orderAgain,
    userAgentInfo,
    asCustomer: true,
    imageUrl:
      (item[TWO_D_IMAGE_URLS_KEY] ? item[TWO_D_IMAGE_URLS_KEY][0] : null) ||
      item.imageFile ||
      item.twoDImageUrl,
    imageConvertingError: item.imageConvertingError,
  };

  return ppeApi.getPpePriceForItemWithCache(payload);
};
