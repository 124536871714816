import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import libphonenumber from 'google-libphonenumber';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Slide,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
  DialogActions,
  Divider
} from '@material-ui/core';

import {
  FtrButton,
  FtrLinkButton,
  FtrNormalText,
} from '../../components/ftr-components';

import CountryDropDown from '../../components/forms/CountryDropDown';
import { FlexColumn, FlexRowCenter } from '../layouts/FlexLayouts';
import FtrPasswordField from '../FtrPasswordField';

import quoteIcon from '../../assets/icons/quote.svg';
import unfilledGreyCheck from '../../assets/icons/unfilled_grey_check.svg';
import filledGreyCheck from '../../assets/icons/filled_grey_check.svg';

import { getUserCountry, isEmailExists } from '../../apis/userApi';
import { createAlertNotification } from '../../apis/alertApi';

import * as emailValidator from '../../utils/validators/emailValidator';

import { loginUser, signupUser } from '../../actions';

import { getContactWithCountryCode, getDefaultProfilePic, getUserAgentInfo } from '../../utils/userUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { validatePassword } from '../../utils/passwordUtils';

import { COUNTRY_NAMES, COUNTRY_LIST } from '../../constants/countryConstants';
import { PASSWORD_RULES } from '../../constants/passwordConstants';
import { GTM_ID_MAP } from '../../constants/GTMConstants';

import { colors } from '../../palette';


// ---------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      borderRadius: 0,
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  inputField: {
    width: '100%',
    borderRadius: '10px',
    marginBottom: '0.95rem',
    background: colors.fontWhite,
    '& .MuiOutlinedInput-root': {
      color: colors.neutral070,
      borderRadius: 10,
    },
    '& .MuiOutlinedInput-root .Mui-disabled': {
      color: colors.neutral050,
      borderRadius: 10,
    },
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: 600,
    paddingBottom: '0.25rem',
    color: colors.neutral070,
  },
  checkbox: {
    width: '1.5rem',
    height: '1.5rem',
    boxSizing: 'border-box',
    marginRight: '0.3rem',
  },
  rules: {
    display: 'flex',
    lineHeight: 1.5,
    color: colors.neutral060,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10pt',
    },
  },
  capitalise: {
    '& .MuiInputBase-input': {
      textTransform: 'capitalize',
    },
  }
}));

const ShortCheckbox = (props) => {
  const classes = useStyles();
  return <Checkbox {...props} className={classes.checkbox} />;
};

const MODES = {
  SIGNUP: 'SIGNUP',
  LOGIN: 'LOGIN',
}

function SignUpForQuote(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    open,
    handleClose
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [mode, setMode] = useState(MODES.SIGNUP);

  // first name, last name fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isNameError, setIsNameError] = useState(false);
  const [nameValidated, setNameValidated] = useState(false);

  // email field
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailValidated, setEmailValidated] = useState(false);

  // contact number fields
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryValue, setCountryValue] = useState(COUNTRY_NAMES.SINGAPORE);
  const [callingCode, setCallingCode] = useState(COUNTRY_NAMES.SINGAPORE);
  const [showCallingCode, setShowCallingCode] = useState(false);
  const [isContactError, setIsContactError] = useState(false);
  const [contactValidated, setContactValidated] = useState(false);

  // password fields
  const [password, setPassword] = useState('');
  const [isShowPasswordRules, setIsShowPasswordRules] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [passwordRulesFailed, setPasswordRulesFailed] = useState(Object.keys(PASSWORD_RULES));

  // checkbox fields
  const [isShowWhatsAppCheck, setIsShowWhatsAppCheck] = useState(false);
  const [isContactableWhatsApp, setIsContactableWhatsApp] = useState(false);
  const [isGDPRSubjected, setIsGDPRSubjected] = useState(false);
  const [isTermsOfUseAgreed, setIsTermsOfUseAgreed] = useState(false);
  const [isEU, setIsEU] = useState(false);

  const [processing, setProcessing] = useState(false);

  const isNextButtonEnabled = isEmptyValue(passwordRulesFailed)
    && nameValidated
    && emailValidated
    && (isEmptyValue(phoneNumber) || contactValidated)
    && isTermsOfUseAgreed
    && (!isEU || isGDPRSubjected);

  const isLoginButtonEnabled = emailValidated && !isEmptyValue(password);


  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const { in_eu, country_name } = await getUserCountry();
        setCountryValue(country_name !== 'United States' ? country_name : COUNTRY_NAMES.AMERICA)  // only america has shortened country name, quick fix
        setCallingCode(country_name !== 'United States' ? country_name : COUNTRY_NAMES.AMERICA)  // only america has shortened country name, quick fix
        if (in_eu) {
          setIsEU(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchCountryCode();
  }, []);

  const validateContact = (contact) => {
    if (isEmptyValue(contact) || contact.length <= 1) {
      setIsContactableWhatsApp(false);
      setIsShowWhatsAppCheck(false);
      setContactValidated(false);
      return false;
    }

    const region = COUNTRY_LIST[callingCode].code;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(contact, region);
      if (phoneUtil.isValidNumber(number)) {
        setContactValidated(true);
        setIsContactableWhatsApp(true);
        setIsShowWhatsAppCheck(true);
        return true;
      }
      setContactValidated(false);
      return false;
    } catch (e) {
      setContactValidated(false);
      return false;
    }
  };

  const validateName = (name) => {
    if (!isEmptyValue(name)) {
      setNameValidated(true);
      return true;
    } else {
      setNameValidated(false);
      return false;
    }
  }

  const validateEmail = async () => {
    if (isEmptyValue(email) || !emailValidator.validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      setEmailValidated(false);
      return;
    }

    if (mode === MODES.LOGIN) {
      setEmailValidated(true);
      return;
    }

    const emailExists = await isEmailExists(email);
    if (emailExists) {
      setEmailError('The email is already registered. Please log in instead.');
      return;
    }

    setEmailValidated(true);
  };

  useEffect(() => {
    validateContact(phoneNumber);
  }, [phoneNumber, callingCode]);

  const handleFirstNameChange = (event) => {
    validateName(event.target.value);
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailError('');
    setEmail(event.target.value.trim());
  };

  const handlePasswordChange = (event) => {
    setPasswordRulesFailed(validatePassword(event.target.value));
    setPassword(event.target.value);
  };

  const switchMode = () => {
    setMode((prevMode) => (prevMode === MODES.SIGNUP ? MODES.LOGIN : MODES.SIGNUP));
  }

  const submitSignupDetails = (event) => {
    event.preventDefault();
    const countryCode = COUNTRY_LIST[callingCode].phone;
    const formattedContact = getContactWithCountryCode(phoneNumber, countryCode);
    const name = [firstName, lastName].filter(Boolean).join(' ');
    const userData = {
      email,
      firstName,
      lastName,
      name,
      role: 'buyer',
      country: callingCode || countryValue, // fallback to use phone number country if ip address service doesn't work
      contact: formattedContact,
      password,
      profilePic: getDefaultProfilePic(firstName),
      isGDPRSubjected,
      isContactableWhatsApp,
      isSignUpV2: true,
    };
    dispatch(signupUser(userData, props))
  };

  const submitLoginDetails = async (event) => {
    event.preventDefault();
    setProcessing(true);
    const userAgentInfo = await getUserAgentInfo(email)
      .catch(error => {
        const body = {
          title: '[FE] Get User Agent Info Failed!',
          message: error?.message,
          errorStack: error?.stack,
          additionalInfo: {
            email,
          },
        }
        createAlertNotification(body);
      });
    dispatch(loginUser(email, password, userAgentInfo, props));
    setTimeout(() => setProcessing(false), 5000);
  }

  const renderNameField = () => {
    return (
      <div style={{ display: !isMobile && 'flex' }}>
        <div style={{ display: 'block', width: !isMobile && '50%' }}>
          <Typography className={classes.inputLabel}>First Name *</Typography>
          <TextField
            size='small'
            id='fname'
            name='fname'
            value={firstName}
            variant='outlined'
            error={isNameError}
            autoComplete='given-name'
            className={`${classes.inputField} ${classes.capitalise}`}
            onChange={handleFirstNameChange}
            onBlur={() => setIsNameError(!nameValidated)}
          />
        </div>
        <Box style={{ width: '2rem' }}></Box>
        <div style={{ display: 'block', width: !isMobile && '50%' }}>
          <Typography className={classes.inputLabel}>Last Name</Typography>
          <TextField
            id='lname'
            name='lname'
            size='small'
            variant='outlined'
            value={lastName}
            autoComplete='family-name'
            className={`${classes.inputField} ${classes.capitalise}`}
            onChange={handleLastNameChange}
          />
        </div>
      </div>
    );
  };

  const renderEmailField = () => {
    const label = mode === MODES.SIGNUP ? 'Work Email *' : 'Email';
    return (
      <div>
        <Typography className={classes.inputLabel}>
          {label}
        </Typography>
        <TextField
          size='small'
          id='email'
          name='email'
          variant='outlined'
          type='email'
          autoComplete='username'
          error={!!emailError}
          helperText={emailError}
          onChange={handleEmailChange}
          className={classes.inputField}
          onBlur={validateEmail}
        />
      </div>
    );
  };

  const renderPasswordField = () => {
    const label = mode === MODES.SIGNUP ? 'Password *' : 'Password';
    const showForgotPassword = mode === MODES.LOGIN;
    return (
      <FtrPasswordField
        title={label}
        error={isPasswordError}
        showForgotPassword={showForgotPassword}
        onChange={handlePasswordChange}
        onFocus={() => setIsShowPasswordRules(true)}
        onBlur={() => setIsPasswordError(!isEmptyValue(passwordRulesFailed))}
      />
    );
  }

  const renderPhoneNumberPasswordField = () => {
    return (
      <div style={{ display: !isMobile && 'flex' }}>
        <div style={{ display: 'block', width: !isMobile && '50%' }}>
          <Typography className={classes.inputLabel}>Contact Number</Typography>
          <div style={{ display: 'flex', overflow: 'hidden' }}>
            <Slide in={showCallingCode} direction='right'>
              <div
                style={{
                  width: '30%',
                  display: showCallingCode ? 'block' : 'none',
                }}
              >
                <CountryDropDown
                  id='countryCode'
                  showFlag={false}
                  isCountryCodeDropDown
                  onSelect={(value) => setCallingCode(value)}
                  valueSelected={callingCode}
                  showDefaultFlag
                  overrideStyle={{ width: '100%' }}
                />
              </div>
            </Slide>
            <TextField
              id='phone'
              name='phone'
              value={phoneNumber}
              error={isContactError}
              className={classes.inputField}
              autoComplete='tel-local'
              onBlur={() => phoneNumber.length > 0 && setIsContactError(!contactValidated)}
              onChange={(event) => setPhoneNumber(event.target.value)}
              onFocus={() => {
                setShowCallingCode(true);
              }}
              helperText={isContactError && 'Please enter a valid contact number.'}
              variant='outlined'
              size='small'
              style={{
                width: !showCallingCode ? '100%' : '70%',
              }}
            />
          </div>
        </div>
        <Box style={{ width: '2rem' }}></Box>
        <div style={{ display: 'block' }}>
          {renderPasswordField()}
          {isShowPasswordRules &&
            Object.entries(PASSWORD_RULES).map(([key, value]) => {
              return (
                <div
                  key={`${key}-rules`}
                  style={{ fontSize: 13 }}
                  className={classes.rules}>
                  {passwordRulesFailed.includes(key)
                    ? <img src={unfilledGreyCheck} alt='icon' style={{ marginRight: '0.5rem' }} />
                    : <img src={filledGreyCheck} alt='icon' style={{ marginRight: '0.5rem' }} />
                  }
                  {value.message}
                </div>
              )
            })
          }
        </div>
      </div>
    );
  };

  const renderCheckboxes = () => {
    return (
      <div style={{ display: 'grid', padding: '0.6rem' }}>
        <FormControlLabel
          control={
            <ShortCheckbox
              onChange={evt => {
                setIsTermsOfUseAgreed(evt.target.checked)
              }}
              checked={isTermsOfUseAgreed}
              name='terms-of-use'
            />}
          label={
            <Typography style={{ color: colors.neutral080, fontSize: 14 }}>
              I agree to the&nbsp;
              <Link
                target='_blank'
                to='/terms'
                style={{ textDecoration: 'none', color: colors.blue060 }}
              >
                Terms of Use
              </Link>
              &nbsp;and&nbsp;
              <Link
                target='_blank'
                to='/privacy'
                style={{ textDecoration: 'none', color: colors.blue060 }}
              >
                Privacy Policy.*
              </Link>
            </Typography>
          }
        />
        {isShowWhatsAppCheck &&
          <FormControlLabel
            control={
              <ShortCheckbox
                name='contactable-via-whatsapp'
                checked={isContactableWhatsApp}
                onChange={evt => {
                  setIsContactableWhatsApp(evt.target.checked)
                }}
              />}
            label={
              <Typography style={{ color: colors.neutral080, fontSize: 14 }}>
                I agree to be contacted by Factorem via WhatsApp.
              </Typography>
            }
          />
        }
        {isEU &&
          <FormControlLabel
            control={
              <ShortCheckbox
                name='check-GDPR'
                checked={isGDPRSubjected}
                onChange={evt => {
                  setIsGDPRSubjected(evt.target.checked)
                }}
              />}
            label={
              <Typography style={{ color: colors.neutral080, fontSize: 14 }}>
                I am subjected to GDPR.*
              </Typography>
            }
          />
        }
      </div>
    );
  };

  const renderSignupForm = () => {
    return (
      <>
        {renderNameField()}
        {renderEmailField()}
        {renderPhoneNumberPasswordField()}
        {renderCheckboxes()}
      </>
    );
  }

  const renderLoginForm = () => {
    return (
      <>
        {renderEmailField()}
        {renderPasswordField()}
      </>
    );
  }

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      fullScreen={isMobile}
      PaperProps={{ className: classes.dialog }}
      data-cy='signup-for-quote-popup'
      onClose={handleClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={quoteIcon} alt='icon' />
        <span>Just a step away from your quote</span>
      </DialogTitle>
      <DialogContent>
        {mode === MODES.SIGNUP && renderSignupForm()}
        {mode === MODES.LOGIN && renderLoginForm()}
      </DialogContent>
      <DialogActions>
        <FlexColumn
          style={{
            padding: '1rem',
          }}
        >
          <FtrButton
            id={mode === MODES.SIGNUP
              ? GTM_ID_MAP.BTN_CONTINUE_TO_QUOTE
              : 'btn-login'
            }
            color='blue'
            disabled={mode === MODES.SIGNUP ? !isNextButtonEnabled : !isLoginButtonEnabled}
            loading={processing}
            fullWidth
            onClick={mode === MODES.SIGNUP
              ? submitSignupDetails
              : submitLoginDetails
            }
          >
            {mode === MODES.SIGNUP ? 'Continue to quote' : 'Login'}
          </FtrButton>
          {mode === MODES.SIGNUP && (
            <FlexRowCenter style={{ width: '100%' }}>
              <FtrNormalText>
                Already have an account?
              </FtrNormalText>
              <FtrLinkButton
                color='blue'
                onClick={switchMode}
              >
                Login
              </FtrLinkButton>
            </FlexRowCenter>
          )}
          {mode === MODES.LOGIN && (
            <FlexColumn>
              <FlexRowCenter
                style={{
                  padding: '1rem',
                }}
              >
                <Divider style={{ width: '47%' }} />
                &nbsp;or&nbsp;
                <Divider style={{ width: '47%' }} />
              </FlexRowCenter>
              <FtrButton
                color='blue'
                variant='outlined'
                fullWidth
                onClick={switchMode}
              >
                Create an Account
              </FtrButton>
            </FlexColumn>
          )}
        </FlexColumn>
      </DialogActions>
    </Dialog>
  );
}


export default withRouter(SignUpForQuote);
