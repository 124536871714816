import { useMemo } from 'react';
import { useSelector } from 'react-redux';
const { Decimal } = require('decimal.js');

import { getUserCreditsSelector } from '../selectors/userSelector';


// ------------------------------------------------------------------------------

export const useCreditsBalance = (totalCost) => {
  const userCredits = useSelector(getUserCreditsSelector);

  const creditBalanceAfter = useMemo(() => {
    let result = userCredits;
    if (userCredits > 0 && typeof totalCost === 'number') {
      result = new Decimal(userCredits).minus(new Decimal(totalCost)).toNumber();
    }
    return result;
  }, [
    userCredits,
    totalCost,
  ]);

  return {
    userCredits,
    creditBalanceAfter,
  };
}
