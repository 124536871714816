import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

import {
  AttachFile as AttachFileIcon,
} from "@material-ui/icons";

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { downloadS3File, isPdfFile } from '../../utils/fileUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import {
  isFileNeedWaterMark,
  supplierDownloadCadFile,
} from '../../utils/supplierCadDownloadUtils';
import { getFileNameFromUrl } from '../../utils/fileUtils';

import { generatePresignedUrl } from '../../services/s3Service';


// ---------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  itemPropertyKey: {
    fontWeight: "bold",
    margin: 0,
  },
  itemFile: {
    display: "flex",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word"
  },
}));


function FileDownloadListDisplay(props) {
  const classes = useStyles();

  const {
    title,
    urlList = [],
    showTitle = true,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [{ isIPad }] = useDeviceCheck();

  const userRole = useSelector(getUserRoleSelector);

  return (
    <div>
      {showTitle && (
        <Typography
          gutterBottom
          variant="body1"
          className={classes.itemPropertyKey}
        >
          {title}
        </Typography>
      )}
      <div>
        {urlList?.map((link) => {
          const isPdf = isPdfFile(link);
          const filenameFromUrl = link.split("/").reverse()[0];
          return (
            <div
              key={link}
              className={classes.itemFile}
            >
              <div>
                <AttachFileIcon color="action" fontSize="small" />
              </div>
              <div>
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isPdf && !isMobile && !isTablet && !isIPad) {
                      generatePresignedUrl(link, 300, true) // 5 minutes expiration
                        .then(presignedUrl => {
                          window.open(presignedUrl);
                        });
                    } else if (isFileNeedWaterMark(link) && !isAdminOrHigherRole(userRole)) {
                      supplierDownloadCadFile(link);
                    } else {
                      downloadS3File(link);
                    }
                  }}
                >
                  {getFileNameFromUrl(filenameFromUrl)}
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FileDownloadListDisplay;
