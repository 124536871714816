import { useQuery } from 'react-query';
import { useMemo } from 'react';

import { getUserInfo } from '../apis/userApi';
import { getConfigByKey } from '../apis/configurationApi';

import { isAdminOrHigherRole } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { isSameOrBefore } from '../utils/dateTimeUtils';

import { ROLE_TYPES } from '../constants';


// --------------------------------------------------------------------------------------------

const useManageProjectRevampLegacyCustomerHook = (userID) => {
  const key = 'MANAGE_PROJECTS_REVAMP_RELEASE_DATE';

  const {
    data: userInfo,
  } = useQuery(
    ['getUserInfo', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }

      return getUserInfo(userID);
    },
  );

  const {
    data: releaseDateConfig,
  } = useQuery(
    ['getConfigByKey', key],
    () => {
      return getConfigByKey(key);
    },
  );

  const isLegacyCustomer = useMemo(() => {
    if (isEmptyValue(userInfo) || isEmptyValue(releaseDateConfig)) {
      return false;
    }

    if (isAdminOrHigherRole(userInfo.role)) {
      return true;
    }

    if (ROLE_TYPES.BUYER !== userInfo.role) {
      return false;
    }

    const { verificationDate } = userInfo;
    return isSameOrBefore(new Date(verificationDate), new Date(releaseDateConfig.value));
  }, [
    userInfo,
    releaseDateConfig,
  ]);

  return {
    isLegacyCustomer,
  }
};

export default useManageProjectRevampLegacyCustomerHook;
