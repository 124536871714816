import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useQueryClient } from 'react-query'

import { makeStyles, Box, Button, Collapse } from "@material-ui/core";
import { colors } from "../../palette";

import { notifySuccess, notifyError } from '../../services/notificationService';

import { createPartsLibraryProject } from '../../apis/partsLibraryApi';

import UploadedFile from "./components/UploadedFile";
import UploadProgressBar from "./components/UploadProgressBar";
import { isEmptyValue } from "../../utils/commonUtils";

const useStyles = makeStyles((theme) => ({
  body: {
    '& .MuiTouchRipple-rippleVisible': {
      animationDuration: '120ms',
    },
  },
  uploadedPartsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 24,
  },
  uploadProgressBar: {
    marginTop: 8,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 16,
    margin: '16px 0',
  },
  saveButton: {
    backgroundColor: colors.blue060,
    color: colors.buttonWhiteText,
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: 'none',
    borderRadius: 10,
    "&:hover": {
      backgroundColor: colors.blue070,
    },
    "&:active": {
      backgroundColor: colors.blue080,
    },
  },
  discardButton: {
    color: colors.neutral070,
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: 'none',
    borderRadius: 10,
  },
  confidential: {
    marginTop: '-2rem',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  }
}));

export const UploadPartsStatus = (props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { userID, saveButtonEnable, numTotalFiles, numSuccessUploadedFiles, data, removeCadPart } = props;

  const handleDiscard = () => {
    data?.forEach((cadPart) => {
      removeCadPart(cadPart.link);
    });
  }

  const handleSave = async () => {
    let payload = {
      items: data?.map((cadPart) => {
        let name = cadPart.file.name;
        const fileNameExtIndex = cadPart.file.name.lastIndexOf('.');
        if (fileNameExtIndex !== -1) {
          name = name.split('.')[0];
        }
        return {
          name,
          cadFile: cadPart.link,
          twoDImageUrl: cadPart['2DImageUrl'],
        }
      }),
      userID: userID,
    };
    createPartsLibraryProject(payload).then((result) => {
      notifySuccess(`Project ${result?.projectLibraryID} has been created successfully!`)
      handleDiscard();
      queryClient.invalidateQueries(['partsLibraryProjects', userID]);
    }).catch(() =>
      notifyError('There was an error. Please contact Factorem support team.')
    )
  }

  const [showProgressBar, setShowProgressBar] = useState(false);
  useEffect(() => {
    if (numSuccessUploadedFiles < numTotalFiles || !saveButtonEnable) {
      setShowProgressBar(true);
    } else {
      setShowProgressBar(false);
    }
  }, [numSuccessUploadedFiles, numTotalFiles, saveButtonEnable])

  if (isEmptyValue(data)) {
    return null;
  }

  return (
    <Box className={classes.body}>
      <Collapse in={showProgressBar} className={classes.uploadProgressBar}>
        <UploadProgressBar
          numSuccessUploadedFiles={numSuccessUploadedFiles}
          numTotalFiles={numTotalFiles}
          key='progress-bar-upload-parts'
          id='progress-bar-upload-parts'
        />
      </Collapse>
      <Box className={classes.uploadedPartsContainer}>
        {data?.map((cadPart) => {
          return (
            <UploadedFile
              key={cadPart.id}
              cadPart={cadPart}
              onDeleteClick={removeCadPart}
            />
          );
        })}
      </Box>
      {data?.length !== 0 && (
        <Box className={classes.buttonContainer}>
          <Button className={classes.discardButton} onClick={handleDiscard}>
            Discard
          </Button>
          <Button
            variant='contained'
            className={classes.saveButton}
            onClick={handleSave}
            disabled={!saveButtonEnable}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withRouter(withConnect(UploadPartsStatus));
