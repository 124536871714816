import React from 'react';

import { withStyles } from '@material-ui/core/styles/index';

import { Checkbox } from '@material-ui/core';

import { colors } from '../../palette';

const FtrBlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: colors.blue060, // Blue color when checked
    },
    '.MuiCheckbox-colorPrimary': {
      color: colors.neutral050
    }
  },
  checked: {},
})((props) => <Checkbox color={'primary'} {...props} />);

export default FtrBlueCheckbox;
