import React from 'react';

import FtrSvgImage from '../../images/FtrSvgImage';

import RightArrowIcon from '../../../assets/icons/right_arrow_icon.svg';
import RightArrowBlueIcon from '../../../assets/icons/right_arrow_blue_icon.svg';


// ----------------------------------------------------------------------------------

function FtrRightArrowButton(props) {
  const {
    onClick = () => { },
  } = props;

  return (
    <FtrSvgImage
      src={RightArrowIcon}
      hoverIcon={RightArrowBlueIcon}
      width={10}
      height={10}
      style={{
        justifyContent: 'end',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

export default FtrRightArrowButton;
