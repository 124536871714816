import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  TextField,
  Typography,
} from '@material-ui/core';

import { FlexColumn, FlexRowSpaceBetween } from '../layouts/FlexLayouts';

import { openInNewTab } from '../../utils/navigationUtils';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------

// Style the components
const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: '16px',
    fontWeight: 600,
    paddingBottom: '0.25rem',
    color: colors.neutral070,
  },
  inputField: {
    width: '100%',
    marginBottom: '0.95rem',
    '& .MuiOutlinedInput-root': {
      color: colors.neutral070,
      borderRadius: 10,
      background: colors.fontWhite
    },
    '& .MuiOutlinedInput-root .Mui-disabled': {
      color: colors.neutral050,
      borderRadius: 10,
    },
  },
  forgotPassword: {
    float: 'right',
    fontSize: '11pt',
    color: colors.buttonBlue,
    textDecoration: 'none',
    '.visited': {
      color: colors.buttonBlue,
    },
    '&:hover': {
      color: colors.blue050,
    },
  },
}));

/**
 * Customised MUI TextField component
 * Props are passed directly to the MUI TextField component
 *
 * @param {object} props - The properties of the FtrTextField component.
 * @param {String} props.title - The label of the component, use this instead of props.label.
 *
 * @returns {React.ReactNode} The rendered FtrTextField component.
 */
function FtrTextField(props) {
  const classes = useStyles();

  const {
    title,
    showForgotPassword = false,
  } = props;

  return (
    <FlexColumn style={{ gap: 0, width: 'auto' }}>
      {(title || showForgotPassword) && (
        <FlexRowSpaceBetween>
          {title && <Typography className={classes.inputLabel}>{title}</Typography>}
          {showForgotPassword && (
            <Typography className={classes.inputLabel}>
              <Link
                className={classes.forgotPassword}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openInNewTab('/reset');
                }}
              >
                Forgot Password?
              </Link>
            </Typography>
          )}
        </FlexRowSpaceBetween>
      )}
      <TextField
        size='small'
        variant='outlined'
        className={classes.inputField}
        {...props}
      />
    </FlexColumn>
  );
}

export default FtrTextField;
