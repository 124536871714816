// Import settings
import React from "react";

// Import color palette
import { colors } from "../../palette";

// Import material UI components
import {
  Button, CircularProgress, Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/index";

// Style components
const useStyles = makeStyles((theme) => ({
  submitBtn: {
    textTransform: 'uppercase',
    background: colors.buttonColorBlueGradient,
    borderRadius: '0.3125rem',
    fontSize: '0.9375rem',
    letterSpacing: '0.15em',
    color: colors.fontWhite,
    padding: '0.75rem 1.6875rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.75rem 1rem',
      fontSize: '0.75rem'
    }
  },
  smallSubmitBtn: {
    textTransform: 'uppercase',
    background: colors.buttonColorBlueGradient,
    borderRadius: '0.3125rem',
    fontSize: '0.5625rem',
    letterSpacing: '0.15em',
    color: colors.fontWhite,
    padding: '0.625rem 0.9375rem',
    textAlign: 'center'
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

function BlueButton(props) {
  const classes = useStyles();
  const {
    id,
    btnContent,
    onBtnClick,
    component,
    to,
    size,
    disabled,
    loading = false,
    className = '',
    tooltip = ''
  } = props;
  if (size === 'small') {
    return (
      <Tooltip title={tooltip}>
        <div>
          <Button
            id={id}
            data-cy={id}
            className={`${classes.smallSubmitBtn} ${className}`}
            onClick={onBtnClick}
            variant='contained'
            component={component ? component : 'span'}
            to={to ? to : null}
            disabled={disabled ? true : false}
          >
            {loading && <CircularProgress className={classes.circularProgress} size={20} />}
            {btnContent}
          </Button>
        </div>
      </Tooltip>
    )
  }
  return (
    <Tooltip title={tooltip}>
      <div>
        <Button
          data-cy={id}
          className={`${classes.submitBtn} ${className}`}
          onClick={onBtnClick}
          variant='contained'
          component={component ? component : 'span'}
          to={to ? to : null}
          disabled={disabled ? true : false}
        >
          {loading && <CircularProgress className={classes.circularProgress} size={20} />}
          {btnContent}
        </Button>
      </div>
    </Tooltip>
  )
}

export default BlueButton;
