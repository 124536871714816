import { PART_UPLOAD_STEP_TWO_TOUR_INFO } from './partUploadStepTwoTourConstant';
import { restartTourStep } from './tourConstants';
import { createStep, getLastStepNumber, openSidebarMobile } from './tourUtils';

export const getPartUploadStepTwoTourSteps = (params = {}) => {
  const lastStepNumber = getLastStepNumber(PART_UPLOAD_STEP_TWO_TOUR_INFO);

  const steps = Object.keys(PART_UPLOAD_STEP_TWO_TOUR_INFO).map((item) => {
    const step = PART_UPLOAD_STEP_TWO_TOUR_INFO[item];
    return createStep(
      params,
      step,
      { element: step.ELEMENT, on: 'top' },
      `${step.STEP} out of ${lastStepNumber}`
    );
  });
  return [
    ...steps,
    {
      ...restartTourStep(params),
      showOn: function() {
        openSidebarMobile();
        if (!params.nextButtonClicked) {
          return false;
        }
        const element = document.querySelector(this.attachTo.element);
        return Boolean(element);
      },
    },
  ];
};
