import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from "lodash";
import { connect, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import clsx from "clsx";

import {
  Badge,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles/index';

import {
  CheckCircle,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@material-ui/icons";

import CompareQuotationsPopup from "../popups/CompareQuotationsPopup";
import DateFormatMethod from "../DateFormatMethod";
import DeleteOrderButton from "../buttons/DeleteOrderButton";
import DeleteWarningPopupV2 from "../popups/DeleteWarningPopupV2";
import DeliveryDateCell from "./cells/DeliveryDateCell";
import EditOrderButton from "../buttons/EditOrderButton";
import EditPartPopup from "../popups/EditPartPopup";
import FilesUploadActionButton from "../buttons/FilesUploadActionButton";
import ItemStatusParent from "../ItemStatusParent";
import MultiCheckoutDetailsPanel from "../panels/MultiCheckoutDetailsPanel";
import PaymentStatusSimpleLabel from "../labels/PaymentStatusSimpleLabel";
import ImageWith3DViewer from '../images/ImageWith3DViewer';

import { addSelectedQuote, removeSelectedQuote } from "../../actions/multiCheckoutForm";

import { addItemCustomerPoFiles, customerDisableItem } from "../../apis/itemApi";

import { checkUploadPoItemStatusSupport } from "../../utils/itemUtils";
import { isSelectableQuote, isUncheckedOutPartWithQuotes, isUnverifiedQuote } from '../../utils/quotationUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';
import { getCustomerPoFileS3Key, uploadFileToS3 } from '../../services/s3Service';

import { getCurrencySymbol } from "../../utils/currencyUtils";
import { isEmptyValue } from '../../utils/commonUtils';

import { partIDColumn } from "../../constants/itemTableConstants";

import { CURRENCY } from '../../constants';
import { FEATURE_FLAG_MULTI_CHECKOUT } from "../../constants/featureFlagConstants";
import { CUSTOMER_CANCEL_ORDER_REASON_MAPPING, ITEM_FILE_UPLOAD_TYPE } from "../../constants/itemConstants";
import { ITEM_STATUS_MAPPING } from "../../constants/itemStatus";

import { colors } from "../../palette";

import { getMultiCheckoutFormSelector } from '../../selectors/multiCheckoutFormSelector';

import ProjectItemsContext from '../../context/ProjectItemsContext';

const useStyles = makeStyles(() => ({
  tableContainer: {
    overflowX: 'auto',
    '& .MuiTableCell-root': {
      padding: '0.8rem',
    },
  },
  iconExpandRow: {
    '& .MuiIconButton-root:not(.MuiCheckbox-root) > .MuiIconButton-label': {
      background: colors.buttonColorBlueGradient,
      borderRadius: '50%',
      '& .MuiSvgIcon-root': {
        fill: colors.fontWhite
      }
    }
  },
  columnName: {
    '& table > tbody > tr > td:nth-child(4)': {
      wordBreak: 'break-all',
      whiteSpace: 'normal !important',
      minWidth: '10rem'
    }
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.tableRowHover,
    },
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#0000000a',
    },
    borderRadius: 4,
    fontSize: '0.6rem',
    lineHeight: '0.8rem',
    whiteSpace: 'nowrap',
  },
  checkIcon: {
    color: colors.uploadPOBorder,
  },
  uploaded: {
    backgroundColor: colors.uploadPOBg,
    '&:hover': {
      backgroundColor: colors.uploadPOBg,
    },
    userSelect: 'none',
  },
}));

function CollapsibleRow(props) {
  const classes = useStyles();

  const { row, columns, allParts, getItems, expand } = props;
  const [open, setOpen] = React.useState(false);

  const { isAdminView } = useContext(ProjectItemsContext);

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector)

  const hasRelevantQuotes = useMemo(() => {
    if (isEmptyValue(row.quotations)) {
      return false;
    }
    return row.quotations.some(quote => isSelectableQuote(quote, isAdminView));
  }, [isAdminView, row]);

  const isSelectedQuote = (quote) => {
    for (const selectedQuote of multiCheckoutForm.selectedQuotes) {
      if (quote.quotationID === selectedQuote.quotationID) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (!hasRelevantQuotes) {
      setOpen(false);
    }
  }, [isAdminView]);

  useEffect(() => {
    if (expand && hasRelevantQuotes) {
      setOpen(true);
    } else if (!expand) {
      const hasItemAnySelectedQuotes = row.quotations.some(isSelectedQuote);
      if (!hasItemAnySelectedQuotes) {
        setOpen(false);
      }
    }
  }, [expand]);

  return (
    <React.Fragment>
      <TableRow
        key={row.name}
        onClick={() => setOpen(!open)}
        className={classes.tableRow}
      >
        <TableCell className={classes.iconExpandRow} style={{ width: 50 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight data-cy="expand-row-btn" />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const renderFunc = column.render;
          if (!renderFunc && !column.field) {
            return null;
          }
          return (
            <TableCell key={row.field} style={{ width: column.width }}>
              {typeof renderFunc === 'function'
                ? renderFunc(row)
                : row[column.field]}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <MultiCheckoutDetailsPanel
              rowData={row}
              allParts={allParts}
              getItems={() => getItems(row.projectID)}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function CustomerItemMuiTable(props) {
  const {
    data,
    getItems,
    projectID,
    currency,
    multiCheckoutForm,
    multipleCheckouts,
    addQuoteToCheckout,
    removeQuoteFromCheckout,
  } = props;

  const classes = useStyles();

  const { isAdminView } = useContext(ProjectItemsContext);

  const [triggerExpand, setTriggerExpand] = useState(false);
  const [editPart, setEditPart] = useState(null);
  const [editPartDialog, setEditPartDialog] = useState(false);
  const [deletePart, setDeletePart] = useState(null);
  const [deletePartDialog, setDeletePartDialog] = useState(false);
  const [quotePartID, setQuotePartID] = useState(null);
  const [quotePartDialog, setQuotePartDialog] = useState(false);
  const [allParts, setAllParts] = useState([]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);

  const currencySymbol = getCurrencySymbol(currency);

  const unCheckedOutParts = useMemo(() => {
    return allParts
      .filter(part => isUncheckedOutPartWithQuotes(part, isAdminView));
  }, [isAdminView, allParts]);

  const firstValidQuoteForEachPart = useMemo(() => {
    return unCheckedOutParts
      .map(part => part.quotations.find(quote => isSelectableQuote(quote, isAdminView)))
      .filter(quote => !isEmpty(quote));
  }, [unCheckedOutParts]);

  const isOneQuoteFromEveryItemSelected = useMemo(() => {
    const uniqueQuotes = selectedQuotes.reduce((acc, quote) => {
      if (!acc.some(existingQuote => existingQuote['itemID'] === quote['itemID'])) {
        acc.push(quote);
      }
      return acc;
    }, [])
    return uniqueQuotes.length === unCheckedOutParts.length;
  }, [selectedQuotes, unCheckedOutParts])

  useEffect(() => {
    setSelectedQuotes(multiCheckoutForm.selectedQuotes || []);
  }, [multiCheckoutForm]);

  const handleOnQuoteDialogOpen = rowData => {
    setQuotePartDialog(true);
    setQuotePartID(rowData.itemID);
  };

  const handleCloseQuoteDialog = () => {
    setQuotePartID(null);
    setQuotePartDialog(false);
  };

  const handleOnEditOrder = rowData => {
    setEditPart(rowData);
    setEditPartDialog(true);
  };

  const handleCloseEditOrder = () => {
    setEditPart(null);
    setEditPartDialog(false);
  };

  const handleOnDeleteOrder = rowData => {
    setDeletePart(rowData);
    setDeletePartDialog(true);
  };

  const handleConfirmDeleteOrder = ({ feedback, additionalRemarks }) => {
    const itemID = deletePart.itemID;
    const body = {
      itemID,
      feedback: CUSTOMER_CANCEL_ORDER_REASON_MAPPING[feedback] || feedback,
      additionalRemarks,
    }
    customerDisableItem(body)
      .then(() => {
        notifySuccess('Your uploaded part has been deleted!');
        getItems(projectID);
      })
      .catch(() => {
        notifyError('Unable to delete part. Please try again later!');
      });
    setDeletePart(null);
    setDeletePartDialog(false);
  };

  const handleCancelDeleteOrder = () => {
    setDeletePart(null);
    setDeletePartDialog(false);
  };

  const handleItemFilesUpload = (files, rowData) => {
    const toastId = toast('Uploading file...', { type: toast.TYPE.INFO, autoClose: false });
    const { itemID } = rowData;
    const uploadFilesBody = [];
    Promise.all(
      Array.from(files).map(async (file) => {
        const data = await uploadFileToS3(file, getCustomerPoFileS3Key(file, itemID));
        const s3ObjectUrl = data.Location;
        const uploadedFileItem = {
          fileName: file.name,
          url: s3ObjectUrl,
          type: ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO,
        };
        let uploadItem = uploadFilesBody.find(item => item.itemID === itemID);
        if (!uploadItem) {
          uploadItem = {
            itemID,
            files: [],
          }
          uploadFilesBody.push(uploadItem);
        }
        uploadItem.files.push(uploadedFileItem);
      })
    ).then(async () => {
      await addItemCustomerPoFiles(uploadFilesBody);
      toast.update(toastId, {
        render: 'File(s) uploaded successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      getItems(projectID);
    }).catch(() => {
      notifyError('Error uploading PO files');
      toast.update(toastId, {
        render: 'Error uploading PO file(s)!',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    });
  }

  useEffect(() => {
    setAllParts(data);
  }, [data]);

  useEffect(() => {
    setTriggerExpand(false);
  }, [isAdminView]);

  const handleSelectAllQuotes = (checked) => {
    if (checked) {
      firstValidQuoteForEachPart.forEach(quote => {
        addQuoteToCheckout(quote);
      });
      setTriggerExpand(true);
    } else {
      selectedQuotes.forEach(quote => {
        removeQuoteFromCheckout(quote);
      });
      setTriggerExpand(false);
    }
  };

  const nameColumn = {
    title: "Name",
    field: "name",
    cellStyle: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      cellStyle: {
        width: "10%",
        maxWidth: 20
      }
    },
    width: 200,
  }

  const imageColumn = {
    title: 'Image',
    render: (rowData) => {
      return (
        <ImageWith3DViewer
          twoDImageUrl={rowData.imageFile || rowData.twoDImageUrl}
          cadFile={rowData.cadFile || rowData.originalFiles}
          borderRadius={0}
          width={80}
          height={80}
        />
      );
    },
    width: 80,
  }

  const quantityColumn = {
    title: "Qty",
    field: "quantity",
    cellStyle: {
      width: "3%",
      maxWidth: 5
    },
    width: 50,
  }

  // eslint-disable-next-line no-unused-vars
  const unitQuoteColumn = {
    title: "Quote (Unit)",
    field: "quote",
    render: rowData => {
      const totalPrice = currency === CURRENCY.SGD
        ? Number(rowData.totalPrice)
        : Number(rowData.totalPrice) * Number(rowData.qMyrExchangeRate);
      return rowData.totalPrice
        ? `${currencySymbol} ${(totalPrice / rowData.quantity).toFixed(2)}`
        : "-";
    },
    cellStyle: {
      width: "5%",
      minWidth: 120,
    },
  }

  // eslint-disable-next-line no-unused-vars
  const totalQuoteColumn = {
    title: "Quote (Total)",
    field: "totalQuote",
    render: rowData => {
      const totalPrice = currency === CURRENCY.SGD
        ? Number(rowData.totalPrice)
        : Number(rowData.totalPrice) * Number(rowData.qMyrExchangeRate);
      return rowData.totalPrice ? `${currencySymbol} ${totalPrice.toFixed(2)}` : "-";
    },
    cellStyle: {
      width: "5%",
      minWidth: 120,
    },
  }

  // eslint-disable-next-line no-unused-vars
  const leadTimeColumn = {
    title: "Lead Time (Working days)",
    field: "leadTime",
    render: rowData => {
      return rowData.leadTime
        ? parseInt(rowData.leadTime, 10) + rowData.markupLeadTime
        : "-";
    },
    cellStyle: {
      width: "7%",
      maxWidth: 5
    }
  }

  const dateUploadedColumn = {
    title: "Date uploaded",
    field: "datePosted",
    render: rowData =>
      rowData.datePosted ? (
        <DateFormatMethod date={rowData.datePosted} />
      ) : (
        ""
      ),
    cellStyle: {
      minWidth: 150,
    },
    width: 120,
  }

  const statusColumn = {
    title: "Status",
    field: "status",
    render: (rowData) => {
      if (FEATURE_FLAG_MULTI_CHECKOUT !== 'true') {
        return (
          <ItemStatusParent
            item={rowData}
            getItems={getItems}
            onClickBtn={() => rowData.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE
              ? handleOnQuoteDialogOpen(rowData)
              : null
            }
          />
        );
      }
      return (
        <Tooltip title={
          rowData.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE
            ? "Click to expand all quotes"
            : "Click to expand item details"
        }>
          <div>
            <ItemStatusParent
              item={rowData}
              getItems={getItems}
              hasQuoteSelected={selectedQuotes.map(q => q.itemID).includes(rowData.itemID)}
            />
          </div>
        </Tooltip>
      );
    },
    width: 100,
  }

  const paymentStatusColumn = {
    title: "Payment Status",
    field: "paymentStatus",
    render: rowData => {
      return (
        <PaymentStatusSimpleLabel paymentStatus={rowData.paymentStatus} />
      );
    },
    cellStyle: {
      width: "5%",
      maxWidth: 10
    },
    width: 100,
  }

  const deliveryDateColumn = {
    title: "Delivery Date",
    field: "deliveryDate",
    render: rowData => (
      <DeliveryDateCell rowData={rowData} />
    ),
    cellStyle: {
      width: "5%",
      minWidth: 150,
    },
    width: 150,
  }

  const actionButtonsColumn = {
    title: "",
    field: "editAndDeleteButtons",
    render: rowData => {
      let isEditable = false;
      let isDeletable = false;
      if (rowData.status === 1 && rowData.price !== null) {
        isDeletable = false;
        isEditable = false;
      } else {
        switch (rowData.status) {
          case 1:
            isDeletable = true;
            isEditable = true;
            break;
          case 2:
            isDeletable = true;
            isEditable = false;
            break;
          case 3:
            isDeletable = true;
            isEditable = false;
            break;
          case 4:
            isDeletable = false;
            isEditable = false;
            break;
          case 5:
            isDeletable = false;
            isEditable = false;
            break;
          case 6:
            isDeletable = false;
            isEditable = false;
            break;
          default:
            isDeletable = false;
            isEditable = false;
        }
      }
      const isUploadButtonEnable = checkUploadPoItemStatusSupport(rowData);
      const checkout = multipleCheckouts.find(ck => {
        const { acceptedItems } = ck;
        return acceptedItems.map(item => item.itemID).includes(rowData.itemID);
      });
      const { customerUploadFiles } = rowData;
      const hasUploaded = !isEmpty(customerUploadFiles);
      const tooltip = checkout
        ? hasUploaded
          ? `View`
          : `Upload PO for part(s): ${checkout.acceptedItems.map(item => `#${item.itemID}`).join(', ')}`
        : 'Accept quotation to enable PO Upload';
      return (
        <div display="flex">
          {isEditable &&
            <EditOrderButton
              btnContent={"Edit Order"}
              onBtnClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleOnEditOrder(rowData)
              }}
            />
          }
          {isDeletable &&
            <DeleteOrderButton
              btnContent={"Delete Order"}
              onBtnClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleOnDeleteOrder(rowData);
              }}
            />
          }
          {!hasUploaded
            ? (
              <FilesUploadActionButton
                inputID={`file-upload-${rowData.itemID}`}
                buttonText={"Upload PO"}
                handleUploadFiles={(files) => handleItemFilesUpload(files, rowData)}
                disabled={!isUploadButtonEnable}
                multiple={false}
                tooltip={tooltip}
              />
            )
            : (
              <Tooltip title='View'>
                <label
                  className={clsx(classes.uploadButton, classes.uploaded)}
                >
                  <CheckCircle className={classes.checkIcon} />
                  <span
                    style={{ marginTop: 3, marginLeft: 4, marginRight: 4 }}
                  >
                    PO: {rowData.customerUploadFiles.map(file => file.fileName).join('; ')}
                  </span>
                </label>
              </Tooltip>
            )
          }
        </div>
      );
    },
    cellStyle: {
      width: "25%",
      maxWidth: 50
    }
  }

  const renderSelectAllQuotes = () => {
    return (
      <Fragment>
        <Typography
          style={{
            fontSize: "10px",
            fontWeight: "bold",
          }}>
          Select All Quotes
        </Typography>
        <Checkbox
          data-cy="select-all-quotes-checkbox"
          checked={isOneQuoteFromEveryItemSelected}
          onClick={(event) => handleSelectAllQuotes(event.target.checked)}
        />
      </Fragment>
    );
  };

  const selectAllQuotesColumn = {
    title: renderSelectAllQuotes(),
    hidden: isEmptyValue(firstValidQuoteForEachPart), //if visible, there are some items with at least one quote that can be selected
    width: 50,
  }

  // Declare columns
  const columns = [
    selectAllQuotesColumn,
    {
      ...partIDColumn,
      render: rowData => {
        const hasUnverifiedQuote = rowData.quotations.some(isUnverifiedQuote);
        return hasUnverifiedQuote && isAdminView
          ? (
            <Tooltip
              title={"There are unverified quotes for this part"}
            >
              <Badge
                badgeContent={"!"}
                color="error"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}>
                {rowData.itemID}
              </Badge>
            </Tooltip>
          )
          : (
            <>
              {rowData.itemID}
            </>)
      }
    },
    nameColumn,
    imageColumn,
    quantityColumn,
    dateUploadedColumn,
    statusColumn,
    paymentStatusColumn,
    deliveryDateColumn,
    actionButtonsColumn,
  ];

  return (
    <div className={classes.tableContainer}>
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            {columns.map(column => {
              if (column.hidden) {
                return <TableCell key={column.title}></TableCell>;
              }
              return (
                <TableCell
                  key={column.title}
                  style={{
                    top: 0,
                    color: colors.blue060,
                    fontSize: "11pt",
                    fontWeight: 600,
                  }}
                >
                  {column.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {allParts.map((row) => {
            return (
              <CollapsibleRow
                key={row.name}
                row={row}
                columns={columns}
                allParts={allParts}
                getItems={getItems}
                expand={triggerExpand}
              />
            );
          })}
        </TableBody>
      </Table>
      <EditPartPopup
        dialog={editPartDialog}
        item={editPart}
        handleClose={() => handleCloseEditOrder()}
        getItems={() => getItems(projectID)}
      />
      {deletePartDialog && (
        <DeleteWarningPopupV2
          open={deletePartDialog}
          onConfirm={handleConfirmDeleteOrder}
          onCancel={handleCancelDeleteOrder}
          onClose={handleCancelDeleteOrder}
        />
      )}
      <CompareQuotationsPopup
        dialog={quotePartDialog}
        handleClose={() => handleCloseQuoteDialog()}
        itemID={quotePartID}
        data={data}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    currency: state.auth.location.currency,
    multiCheckoutForm: state.multiCheckoutForm,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    addQuoteToCheckout: (quote) => dispatch(addSelectedQuote(quote)),
    removeQuoteFromCheckout: (quote) => dispatch(removeSelectedQuote(quote)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(CustomerItemMuiTable));
