import React from 'react';
import { makeStyles } from '@material-ui/core';

import Thumb from './Thumb';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    width: '18rem',
    textAlign: 'left',
  },
  icons: {
    display: 'flex',
    gap: '1rem',
  },
}));

/**
 * Switch Thumb Up and Thumb Down
 * @param {Object} props
 * @param {Object} props.option - Option is a key from Object.keys
 * @param {Boolean} props.isActive
 * @param {() => void} props.onSwitchChange
 * @param {String} props.label
 * @returns
 */
function SwitchThumb(props) {
  const classes = useStyles();
  const { option, isActive, onSwitchChange, label } = props;

  return (
    <div className={classes.container}>
      <span className={classes.label}>{label}</span>
      <div className={classes.icons}>
        <Thumb
          variant='down'
          key='down'
          isActive={!isActive}
          onClick={() => onSwitchChange(option, false)}
          tooltipText='Fail'
        />
        <Thumb
          variant='up'
          key='up'
          tooltipText='Pass'
          isActive={isActive}
          onClick={() => onSwitchChange(option, true)}
        />
      </div>
    </div>
  );
}

export default SwitchThumb;
