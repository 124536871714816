import React, { useReducer } from 'react';

import { isEmptyValue } from '../../utils/commonUtils';
import ShareQuotationFormPopupV2 from '../../components/popups/confirm-order-popup/ShareQuotationFormPopupV2';


// -------------------------------------------------------------------------------------------------

function withShareQuotePopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    return (
      <>
        <WrappedComponent
          updateShareQuotePopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <ShareQuotationFormPopupV2
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            projectID={localState.projectID}
            itemIDList={localState.selectedQuotes.map(quote => quote.itemID)}
            quotationIDList={localState.selectedQuotes.map(quote => quote.quotationID)}
            shippingMode={localState.shippingMode}
            userID={localState.customerID}
            addresses={localState.addresses}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withShareQuotePopupHOC;
