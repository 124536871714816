import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import FtrTabs from '../../ftr-components/FtrTabs';
import QuoteDetailsV2 from './QuoteDetailsV2';
import { FlexColumn } from '../../layouts/FlexLayouts';
import { FtrBoldText } from '../../ftr-components';

import { getItemsByIDList } from '../../../apis/itemApi';

import { updateMultiCheckoutFormState } from '../../../actions/multiCheckoutForm';

import useDeviceCheck from '../../../hooks/useDeviceCheck';

import { getMultiCheckoutFormSelector } from '../../../selectors/multiCheckoutFormSelector';

import { isEmptyValue } from '../../../utils/commonUtils';


// ------------------------------------------------------------------------------

function ConfirmOrderPartDetailsStepPage() {
  const dispatch = useDispatch();

  const [{ isMobile }] = useDeviceCheck();

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);

  const quotes = multiCheckoutForm.selectedQuotes || [];
  const customerRemarks = multiCheckoutForm.customerRemarks || {};

  const itemIDList = useMemo(() => quotes.map(quote => quote.itemID), [quotes]);

  const { data: items } = useQuery('getItemsByIDList', () => getItemsByIDList(itemIDList));

  const [selectedQuoteDetailsIndex, setSelectedQuoteDetailsIndex] = useState(0);

  const quoteInfo = useMemo(() => {
    if (isEmptyValue(quotes)) {
      return {};
    }

    const _quoteInfo = quotes[selectedQuoteDetailsIndex];
    return { ..._quoteInfo };
  }, [
    selectedQuoteDetailsIndex,
    quotes,
  ]);

  const partInfo = useMemo(() => {
    if (isEmptyValue(quoteInfo) || isEmptyValue(items)) {
      return {};
    }

    const itemID = quoteInfo.itemID;
    const item = items.find(it => it.itemID === itemID);

    return { ...item }
  }, [
    selectedQuoteDetailsIndex,
    quoteInfo,
    items,
  ]);

  return (
    <FlexColumn>
      <FtrBoldText fontSize='18'>
        Part Details
      </FtrBoldText>
      <FlexColumn
        style={{
          padding: isMobile ? 0 : '0 1.5rem',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ marginBottom: '1rem' }}>
          <FtrTabs
            itemList={quotes.map(quote => {
              return {
                key: quote.quotationID,
                text: `Part ${quote.itemID}`,
              }
            })}
            value={selectedQuoteDetailsIndex}
            onChange={setSelectedQuoteDetailsIndex}
          />
        </div>
        <QuoteDetailsV2
          partInfo={partInfo}
          quoteInfo={quoteInfo}
          remark={customerRemarks[quoteInfo.quotationID]}
          handleRemarks={evt => {
            const updatedRemarks = {
              ...customerRemarks,
              [quoteInfo.quotationID]: evt.target.value,
            }
            dispatch(updateMultiCheckoutFormState({ customerRemarks: updatedRemarks }));
          }}
        />
      </FlexColumn>
    </FlexColumn>
  );
}

export default ConfirmOrderPartDetailsStepPage;
