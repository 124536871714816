import { GET_PO_UN_ACKNOWLEDGED_COUNT } from "../actions/poAcknowledge";

const defaultState = {
  poUnAcknowledgedCount: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_PO_UN_ACKNOWLEDGED_COUNT: {
      return {
        ...state,
        poUnAcknowledgedCount: action.payload,
      };
    }
    default:
      return {
        ...state,
      }
  }
};
