import React, { useEffect, useState, useReducer } from "react";
import { CsvBuilder } from 'filefy';
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles/index";

import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";

import VerifyItem from "./VerifyItem";
import DataGridWrapTextCell from "../components/tables/cells/DataGridWrapTextCell";

import { ROLE_TYPES } from "../constants";
import { ITEM_VERIFY_STATUS_FILTER_ARRAY } from "../constants/itemStatus"
import { getVerifyingItems } from "../apis/itemApi";
import { useQuery } from "react-query";
import { generateCurrentCustomDateTimeString } from "../util";
import { colors } from "../palette";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from '../components/grid-data/CustomToolbar';
import SearchBar from "../components/grid-data/buttons/SearchBar";
import GridDataPagination from '../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import { FlexColumn, FlexRow } from '../components/layouts/FlexLayouts';
import { FtrTypography } from '../components/ftr-components';
import { NonPPEIcon, PPEIcon, RepeatOrderIcon, SimilarOrderIcon } from '../components/icons/ItemIcon';
import ImageWith3DViewer from "../components/images/ImageWith3DViewer";
import FilterBar from '../components/filters/FilterBar';

import { transformCsvExportDataGrid } from '../utils/csvExportUtils';
import { getIsRepeatOrder, getIsSimilarOrder, getItemOwner } from '../utils/itemUtils';
import { truncateDecimals } from '../utils/numberUtils';
import { dateTzSingapore } from '../utils/dateTimeUtils';
import { isEmptyValue } from "../utils/commonUtils";

const useStyles = makeStyles(() => ({
  body: {
    padding: '2.5rem',
    paddingTop: '1.5rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center'
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer'
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  complexityEasy: {
    color: colors.green030,
  },
  complexityMedium: {
    color: colors.warningYellow,
    fontWeight: 'bold'
  },
  complexityComplex: {
    color: colors.red030,
    fontWeight: 'bold'
  }
}));

function VerifyPartsDataGrid(props) {
  const classes = useStyles();

  const {
    role,
  } = props;

  const [verifyingItems, setVerifyingItems] = useState([]);
  const [itemID, setItemID] = useState("");
  const [openVerifyPartPopup, setOpenVerifyPartPopup] = useState(false);

  const { status: loadingStatus, data: items, refetch } = useQuery('getVerifyingItems', getVerifyingItems);

  useEffect(() => {
    setVerifyingItems(items);
  }, [items]);

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
      status: 'all'
    }
  );

  const [filteredTableData, setFilteredTableData] = useState([]);

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });

    let filteredRows = verifyingItems

    if (tableQueryParams.status === 'ppe') {
      filteredRows = filteredRows.filter(row => row.acceptQuoteType === 'ppe')
    }

    if (tableQueryParams.status === 'non-ppe') {
      filteredRows = filteredRows.filter(row => row.acceptQuoteType !== 'ppe')
    }

    if (!tableQueryParams.search) {
      setFilteredTableData(filteredRows);
      updateTableQueryParams({
        loading: false,
      });
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        filteredRows = filteredRows.filter((row) => {
          const searchFields = [
            row.itemID,
            row.projectID,
            row.name?.toLowerCase(),
            row.technology?.toLowerCase(),
            row.buyerName?.toLowerCase() + "(" + row.userID + ")",
          ];
          return searchFields.map(field => String(field).includes(tableQueryParams.search.toLowerCase())).some(Boolean)
        });
        setFilteredTableData(filteredRows);
        updateTableQueryParams({
          loading: false,
          totalCount: filteredRows.length,
        });
      }, 300);
      return () => {
        clearTimeout(timer);
      };
  }}, [tableQueryParams.search, tableQueryParams.page, tableQueryParams.pageSize, tableQueryParams.status]);

  useEffect(() => {
    if (!verifyingItems) {
      return;
    }
    setFilteredTableData(verifyingItems);
    updateTableQueryParams({
      totalCount: verifyingItems.length,
    });
  }, [verifyingItems]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const complexityComparator = (c1, c2) => {
    // Convert 'N/A' to 0, otherwise use the original value
    const newC1 = c1 === 'N/A' ? 0 : +c1;
    const newC2 = c2 === 'N/A' ? 0 : +c2;

    return newC1 - newC2;
  };

  const columns = [
    {
      headerName: "Item ID",
      field: "itemID"
    },
    {
      title: "Image",
      headerName: "Image",
      field: 'image',
      renderCell: (params) => {
        const rowData = params.row;
        const url = rowData.imageFile || rowData.twoDImageUrl;
        return (
          <div
            key={url}
            id={rowData.itemID}
          >
            <ImageWith3DViewer
              key={url}
              itemID={rowData.itemID}
              twoDImageUrl={url}
              cadFile={rowData.cadFile || rowData.originalFiles}
              isGenerateItemImage
              width={80}
              height={80}
              borderRadius={0}
              noBorder={false}
              textRenderStyle={{ fontSize: 10 }}
            />
          </div>
        );
      },
      valueGetter: params => params.row.imageFile || params.row.twoDImageUrl,
      sortable: false
    },
    {
      headerName: "Project ID",
      field: "projectID",
      renderCell: (params) => (
        <Button
          className={classes.linkButton}
          onClick={(e) => e.stopPropagation()}
          href={`/manage-projects/${params.row.projectID}`}
        >
          {params.row.projectID}
        </Button>
      ),
      minWidth: 115,
    },
    {
      headerName: "Name",
      field: "name",
      renderCell: (params) => (
        <DataGridWrapTextCell
          text={params.row.name}
        />
      ),
      flex: 1,
      minWidth: 170,
      sortable: false
    },
    {
      headerName: "Owner (ID)",
      field: "OwnerID",
      minWidth: 160,
      renderCell: (params) => {
        const { ownerID, ownerName, ownerEmail, ownerCompany } = getItemOwner(params.row)

        return (
          <FlexColumn style={{
            gap: 0,
            wordBreak: 'break-word',
            whiteSpace: 'normal',
            lineHeight: 'normal'
          }}>
            <div>
              {ownerName} ({ownerEmail || ownerID})
            </div>
            <FtrTypography
              type='subHeading'
              fontSize='12'
            >
              {ownerCompany}
            </FtrTypography>
          </FlexColumn>
        )
      },
      valueGetter: (params) => {
        const {
          buyerName,
          userID: ownerID,
          companyName,
          cCompanyName,
          owners
        } = params.row

        let ownerName, ownerCompany, ownerEmail;

        if (isEmpty(owners)) {
          ownerName = buyerName
          ownerCompany = cCompanyName || companyName
        } else {
          const owner = owners[0];
          ownerName = owner.ownerName
          ownerEmail = owner.ownerEmail
        }

        return `${ownerName} (${ownerEmail || ownerID}${ownerCompany ? ` | ${ownerCompany}` : ""})`
      }
    },
    {
      headerName: "Complexity",
      field: "complexity",
      renderCell: ({ row: rowData }) => {
        const complexity = rowData?.meta?.complexity
        const complexityClass = (complexity) => {
          if (isEmptyValue(complexity)) return null;

          if (complexity >= 3.5) return classes.complexityComplex;
          if (complexity >= 2.5) return classes.complexityMedium;

          return classes.complexityEasy;
        }

        return (
          <div className={complexityClass(complexity)}>
            {complexity ? `${truncateDecimals(complexity, 2)} / 5.00` : 'N/A'}
          </div>
        );
      },
      width: 150,
      valueGetter: (params) => params.row?.meta?.complexity ? truncateDecimals(params.row.meta?.complexity, 2) : 'N/A',
      sortComparator: complexityComparator
    },
    {
      headerName: "PPE",
      field: "ppePart",
      valueGetter: (params) => params.row.acceptQuoteType === 'ppe' ? "Yes" : "No",
      hide: true
    },
    {
      title: 'Item Type',
      headerName: 'Item Type',
      field: 'itemType',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <FlexRow>
            {rowData.price ? <PPEIcon /> : <NonPPEIcon />}
            {getIsRepeatOrder(rowData.meta?.additionalParameters) && <RepeatOrderIcon />}
            {getIsSimilarOrder(rowData.meta?.additionalParameters) && <SimilarOrderIcon />}
          </FlexRow>
        );
      },
      width: 145,
      sortable: false
    },
    {
      headerName: "Technology",
      field: "technology",
      minWidth: 150,
      sortable: false
    },
    {
      headerName: "Posted at",
      field: "datePosted",
      minWidth: 150,
      valueGetter: params => dateTzSingapore(params.row.datePosted)
    },
  ];

  const handleRowClick = (rowData) => {
    setItemID(rowData.id);
    setOpenVerifyPartPopup(true);
  };

  const handleRefetch = () => {
    setTimeout(() => refetch(), 1000);
  }

  const renderVerifyItem = () => {
    return (
      <VerifyItem
        isDialog={true}
        setOpen={setOpenVerifyPartPopup}
        itemID={itemID}
        refetch={handleRefetch}
      />
    )
  }

  const handleDownloadCsv = () => {
    const fileName = `Verify Items ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredTableData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getCustomToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
        }}
      >
        <div></div> {/* empty div to align the buttons to the right */}
        <CustomToolbar
          buttons={[
            <SearchBar
              key="search"
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
            />,
          ]}
        />
      </div>
    );
  }

  const handleFilterChange = (event) => {
    const { value: status } = event.target;

    updateTableQueryParams({
      status
    });
  }

  return [ROLE_TYPES.BUYER, ROLE_TYPES.SUPPLIER].includes(role) ? (
    <Redirect
      to={{
        pathname: "/",
        state: { from: props.location }
      }}
    />
  ) : (
    <div className={classes.body}>
      <h1>Verify Items</h1>
      <div style={{ marginBottom: '1rem' }}>
        <FilterBar
          defaultValue={ITEM_VERIFY_STATUS_FILTER_ARRAY[0].value}
          optionList={ITEM_VERIFY_STATUS_FILTER_ARRAY}
          onFilterChange={handleFilterChange}
        />
      </div>
      <DataGrid
        autoHeight
        rowHeight={105}
        headerHeight={90}
        columns={columns}
        rows={isEmpty(filteredTableData) ? [] : filteredTableData}
        getRowId={(row) => row.itemID}
        onRowClick={handleRowClick}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
        rowsPerPageOptions={[10, 20, 50]}
        loading={tableQueryParams.loading || loadingStatus !== 'success'}
        disableSelectionOnClick
        disableRowSelectionOnClick
        components={{
          Toolbar: getCustomToolbar,
          Pagination: () => {
            return <GridDataPagination
              pageCount={Math.ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
            />
          }
        }}
        localeText={{
          toolbarExport: 'Export CSV',
        }}
        disableColumnMenu
      />
      {openVerifyPartPopup && (
        <Dialog
          maxWidth="lg"
          open={openVerifyPartPopup}
          aria-labelledby="form-dialog-title"
          onClose={() => setOpenVerifyPartPopup(false)}
        >
          <div>
            <IconButton
              onClick={() => setOpenVerifyPartPopup(false)}
              style={{ position: "absolute", right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            {openVerifyPartPopup && renderVerifyItem()}
          </div>
        </Dialog>
      )}
  </div>
  )
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role
  };
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(VerifyPartsDataGrid);
