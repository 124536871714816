// Import settings
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import {
  Button,
  Fab,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';

import CustomisableDeletePopup from '../components/popups/CustomisableDeletePopup';
import DownloadAllFilesButton from '../components/buttons/DownloadAllFilesButton';
import DownloadableFileWithActions from '../components/list-items/DownloadableFileWithActions';
import EmailStatusIcon from '../components/icons/EmailStatusIcon';
import FilesUploadActionButton from '../components/buttons/FilesUploadActionButton';
import FilesUploadedActionButton from '../components/buttons/FilesUploadedActionButton';
import InfoIcon from '../components/icons/InfoIcon';
import MultiSelectChipDropdown from '../components/dropdowns/MultiSelectChipDropdown';
import NotInterestedJobPopup from '../components/popups/NotInterestedJobPopup';
import QcReportsDisplay from '../components/info/QcReportsDisplay';
import QualityCertificationsPopup from '../components/popups/QualityCertificationsPopup';
import ViewItemAllowedDeniedSuppliersPopup from '../components/popups/ViewItemAllowedDeniedSuppliersPopup';
import WatchingJobPopup from '../components/popups/WatchingJobPopup';
import YesNoPopup from '../components/popups/YesNoPopup';
import { FlexRow } from '../components/layouts/FlexLayouts';
import { ItemRfqEmailScheduleConfigButton } from '../components/ItemRfqEmailScheduleConfigButton';
import { NotInterestedJob } from '../components/NotInterestedJob';
import { ViewAllowSuppliersButton } from '../components/ViewAllowSuppliersButton';
import { WatchingJob } from '../components/WatchingJob';

import { getAllBuyers } from '../apis/userApi';
import { changeProjectAuthor, deleteProject, updateProjectTimingControl, updateProjectLocationVisibilityControl, editMultipleProjects, getProjectBomFiles } from '../apis/projectApi';
import { adminGetAllQuotationsByParams } from '../apis/quotationApi';
import { addItemCustomerPoFiles, removeItemCustomerPoFiles } from '../apis/itemApi';

import { isAdminOrHigherRole } from '../utils/roleUtils';
import { getTotalWatchingJobsByUserID } from '../utils/itemUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { formatDate } from '../utils/dateTimeUtils';
import { map } from '../utils/arrayUtils';

import { notifyError, notifySuccess } from '../services/notificationService';
import { getCustomerPoFileS3Key, uploadFileToS3 } from '../services/s3Service';

import { ITEM_FILE_UPLOAD_TYPE, ITEM_CANCELLATION_REASON } from '../constants/itemConstants';
import { COUNTRIES } from '../constants/countryConstants';
import { ORDER_STATUS, ROLE_TYPES } from '../constants';
import { LOCATION_VISIBILITY_OPTIONS } from '../constants/projectConstants';

import { colors } from '../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(12),
    paddingTop: theme.spacing(5),
  },
  label: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
}));

export function EditProjectPage(props) {
  const classes = useStyles();

  const { userRole, userID } = props;

  const [editProjectID, setEditProjectID] = useState('');
  const [editProjectName, setEditProjectName] = useState('');
  const [editProjectStatus, setEditProjectStatus] = useState('');
  const [editProjectDateCreated, setEditProjectDateCreated] = useState('');
  const [editProjectItems, setEditProjectItems] = useState([]);
  const [allBuyers, setAllBuyers] = useState([]);
  const [originalProjectOwnerID, setOriginalProjectOwnerID] = useState(null);
  const [projectOwnerID, setProjectOwnerID] = useState(null);
  const [openDeletePoFilePopup, setOpenDeletePoFilePopup] = useState(false);
  const [openDisableProjectPopup, setOpenDisableProjectPopup] = useState(false);
  const [poFileToBeDeleted, setPoFileToBeDeleted] = useState(null);
  const [openQualityPopup, setOpenQualityPopup] = useState(false);
  const [openSupplierQualityPopup, setOpenSupplierQualityPopup] = useState(false);
  const [qcReports, setQcReports] = useState(null);
  const [supplierQcReports, setSupplierQcReports] = useState(null);
  const [originalQcReports, setOriginalQcReports] = useState(null);
  const [originalSupplierQcReports, setOriginalSupplierQcReports] = useState(null);
  const [expectedProjectDeadline, setExpectedProjectDeadline] = useState(null)
  const [overallProjectBudget, setOverallProjectBudget] = useState(null)
  const [showPopupWatchingJob, setShowPopupWatchingJob] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [disabledReason, setDisabledReason] = useState('');
  const [showNotInterestedPopup, setShowNotInterestedPopup] = useState(false)
  const [warningDialog, setWarningDialog] = useState(false);
  const [openDeleteProjectPopup, setOpenDeleteProjectPopup] = useState(false);
  const [itemArray, setItemArray] = useState([]);
  const [adminProjectNotes, setAdminProjectNotes] = useState('');
  const [hasQuotationAccepted, setHasQuotationAccepted] = useState(false);
  const [bomFiles, setBomFiles] = useState([]);
  const [
    projectTimingControlState,
    updateProjectTimingControlState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      category2Timing: 24,
      category3Timing: 72,
    }
  );
  const [
    itemAllowedSuppliersPopupState,
    updateItemAllowedSuppliersPopupState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      itemID: null,
      locationVisibility: null,
    }
  );

  const [locationVisibilityState, updateLocationVisibilityState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      option: LOCATION_VISIBILITY_OPTIONS.WORLD_WIDE,
      selectedCountries: [],
    }
  );

  const projectStatus = ['Disabled', 'Open', 'Completed'];

  const { data: allBuyersData } = useQuery('allBuyers', getAllBuyers);

  const hasAdminPermission = isAdminOrHigherRole(userRole) || userRole === ROLE_TYPES.SALES_CONSULTANT;

  const fileUrlList = useMemo(() => {
    let allProjectDownloadFileUrlsList = [];
    if (!isEmptyValue(editProjectItems)) {
      const factoremFilesList = editProjectItems.reduce((acc, item) => {
        const urlList = map(
          item.cadFile?.split(','),
          (url) => ({
            url,
            subFolder: `factorem-upload`,
          })
        );
        return [...acc, ...urlList];
      }, []);
      allProjectDownloadFileUrlsList = allProjectDownloadFileUrlsList.concat(factoremFilesList);

      const originalFilesList = editProjectItems.reduce((acc, item) => {
        const urlList = map(
          item.originalFiles?.split(','),
          (url) => ({
            url,
            subFolder: `original`,
          })
        );
        return [...acc, ...urlList];
      }, []);
      allProjectDownloadFileUrlsList = allProjectDownloadFileUrlsList.concat(originalFilesList);
    }

    if (!isEmptyValue(bomFiles)) {
      const bomFilesList = bomFiles.reduce((acc, bomFile) => {
        acc.push({
          url: bomFile.s3ObjectUrl,
          subFolder: `boms`,
          fileName: bomFile.fileName,
        })
        return acc;
      }, []);
      allProjectDownloadFileUrlsList = allProjectDownloadFileUrlsList.concat(bomFilesList);
    }

    return allProjectDownloadFileUrlsList;
  }, [editProjectItems, bomFiles]);

  const getProject = async () => {
    const projectIdFromUri = window.location.href.split('/')[4];
    let projectID = projectIdFromUri
      ? projectIdFromUri
      : props.location.state.projectID;
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/${projectID}`;
    const response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${cookies.get('token')}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json()
      throw new Error(message);
    }

    const {
      id,
      userId,
      name,
      status,
      dateCreated,
      items,
      qcReports,
      supplierQcReports,
      adminProjectNotes,
      overallProjectBudget,
      expectedProjectDeadline,
      disabledReason,
      category2Timing,
      category3Timing,
      locationVisibility,
    } = await response.json();

    setEditProjectID(id);
    setOriginalProjectOwnerID(userId);
    setProjectOwnerID(userId);
    setEditProjectName(name);
    setEditProjectStatus(status);
    setEditProjectDateCreated(dateCreated);
    setEditProjectItems(items || []);
    setOriginalQcReports(qcReports)
    setOriginalSupplierQcReports(supplierQcReports)
    setQcReports(qcReports);
    setSupplierQcReports(supplierQcReports);
    setAdminProjectNotes(adminProjectNotes);
    if (overallProjectBudget) {
      setOverallProjectBudget(overallProjectBudget);
    }
    if (expectedProjectDeadline) {
      setExpectedProjectDeadline(expectedProjectDeadline);
    }
    if (disabledReason) {
      setDisabledReason(disabledReason);
    }
    setItemArray(items.map(item => ({ name: item.name, itemID: item.itemID })));
    if (category2Timing && category3Timing) {
      updateProjectTimingControlState({
        category2Timing: category2Timing,
        category3Timing: category3Timing,
      });
    }
    if (locationVisibility) {
      updateLocationVisibilityState({
        option: LOCATION_VISIBILITY_OPTIONS.SELECT_COUNTRIES,
        selectedCountries: locationVisibility.split(','),
      });
      updateItemAllowedSuppliersPopupState({
        locationVisibility: locationVisibility,
      });
    }

    // check has accepted quotes or not
    const itemIDList = items?.map((item) => item.itemID).join(',');
    const acceptedQuotes = await adminGetAllQuotationsByParams({ itemIDList, status: ORDER_STATUS.ACCEPTED })
    setHasQuotationAccepted(!isEmptyValue(acceptedQuotes));
  };

  useEffect(() => {
    getProject().catch((e) => {
      notifyError(e.message);
      props.history.push('/');
    });
  }, []);

  useEffect(() => {
    if (editProjectID) {
      getProjectBomFiles({ projectID: editProjectID }).then(setBomFiles);
    }
  }, [editProjectID]);

  useEffect(() => {
    if (!isEmptyValue(allBuyersData)) {
      setAllBuyers(allBuyersData);
    }
  }, [allBuyersData]);

  const regeneratePO = useMemo(() => {
    const oldQCReportHasChanged = isEmptyValue(originalSupplierQcReports) && !isEqual(originalQcReports, qcReports);
    return (oldQCReportHasChanged || !isEqual(originalSupplierQcReports, supplierQcReports)) && hasQuotationAccepted
  }, [qcReports, supplierQcReports, hasQuotationAccepted])

  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    if (regeneratePO) {
      setWarningDialog(true);
    } else {
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    if (isEmptyValue(disabledReason) && editProjectStatus === 'Disabled') {
      notifyError(`Missing cancellation reason`);
      return;
    }
    const project = {
      userID: originalProjectOwnerID,
      projectID: editProjectID,
      name: editProjectName,
      status: editProjectStatus,
      dateCreated: editProjectDateCreated,
      items: editProjectItems,
      qcReports,
      supplierQcReports,
      expectedProjectDeadline,
      overallProjectBudget,
      disabledReason,
      regeneratePO,
      adminProjectNotes
    }
    const payload = {
      userID,
      projects: [project],
    };
    editMultipleProjects(payload)
      .then(async () => {
        if (originalProjectOwnerID !== projectOwnerID) {
          await changeProjectAuthor({ projectID: editProjectID, authorID: projectOwnerID });
        }
        await updateProjectTimingControl(editProjectID, projectTimingControlState);
        await updateProjectLocationVisibilityControl(editProjectID, locationVisibilityState);
      })
      .then(() => {
        notifySuccess(`Your project has been updated!`);
        regeneratePO ? notifySuccess(`PO regenerated successfully!`) : null;
        props.history.push({
          pathname: `/manage-projects`,
        });
      })
      .catch(() => notifyError(`Your project cannot be updated. Please try again later.`));
  };

  const handleItemFilesUpload = (files, itemID) => {
    const toastId = toast('Uploading file...', { type: toast.TYPE.INFO, autoClose: false });
    const uploadFilesBody = [];
    Promise.all(
      Array.from(files).map(async (file) => {
        const data = await uploadFileToS3(file, getCustomerPoFileS3Key(file, itemID));
        const s3ObjectUrl = data.Location;
        const uploadedFileItem = {
          fileName: file.name,
          url: s3ObjectUrl,
          type: ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO,
        };
        let uploadItem = uploadFilesBody.find(item => item.itemID === itemID);
        if (!uploadItem) {
          uploadItem = {
            itemID,
            files: [],
          }
          uploadFilesBody.push(uploadItem);
        }
        uploadItem.files.push(uploadedFileItem);
      })
    ).then(async () => {
      await addItemCustomerPoFiles(uploadFilesBody);
      toast.update(toastId, {
        render: 'File(s) uploaded successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      getProject();
    }).catch(() => {
      notifyError('Error uploading PO files');
      toast.update(toastId, {
        render: 'Error uploading PO file(s)!',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    });
  }

  const handleDeletePoFile = () => {
    setOpenDeletePoFilePopup(false);
    const body = [{
      itemID: poFileToBeDeleted.itemID,
      files: [poFileToBeDeleted.file],
    }]
    removeItemCustomerPoFiles(body)
      .then(() => {
        notifySuccess('File deleted successfully');
        getProject();
      })
      .catch(() => notifyError('Error deleting file'));
  }

  const handleDeleteProject = () => {
    deleteProject({ projectID: editProjectID })
      .then(() => {
        notifySuccess('Project deleted successfully');
        props.history.push({
          pathname: `/manage-projects`,
        });
      })
      .catch(() => notifyError('Error deleting project'));
  }

  const renderDeletePoFilePopup = () => {
    return (
      <YesNoPopup
        open={openDeletePoFilePopup}
        handleNo={() => setOpenDeletePoFilePopup(false)}
        handleYes={() => {
          handleDeletePoFile();
          setOpenDeletePoFilePopup(false);
        }}
        body={`${poFileToBeDeleted.file.fileName}`}
      />
    );
  };

  const renderDisableProjectPopup = () => {
    return (
      <YesNoPopup
        open={openDisableProjectPopup}
        handleNo={() => {
          setDisabledReason('');
          setOpenDisableProjectPopup(false);
        }}
        handleYes={() => {
          setEditProjectStatus('Disabled');
          setOpenDisableProjectPopup(false);
        }}
        title='Are you sure you want to disable this project?'
        body={`All items within Project ${editProjectID} will be switched to cancelled.`}
        noButtonLabel='Cancel'
        yesButtonLabel='Proceed'
      />
    );
  };

  const renderDeleteProjectPopup = () => {
    return (
      <CustomisableDeletePopup
        open={openDeleteProjectPopup}
        handleNo={() => {
          setOpenDeleteProjectPopup(false);
        }}
        handleYes={() => {
          setOpenDeleteProjectPopup(false);
          handleDeleteProject();
        }}
        title='Are you sure you want to delete this project?'
        body={
          <React.Fragment>
            <Typography>{`All items within Project ${editProjectID} will be deleted as well.`}</Typography>
            {itemArray.map(item => (
              <Typography key={item.itemID}>
                {'Item name: '}
                <strong>{item.name}</strong>
                {', itemID: '}
                <strong>{item.itemID}</strong>
              </Typography>
            ))}
          </React.Fragment>
        }
        // todo list out partID and customer's name
        noButtonLabel='Cancel'
        yesButtonLabel='Confirm deletion'
        deletionConfirmationText={`Delete Project ${editProjectID}`}
      />
    );
  };

  const renderListItem = () => {
    if (!hasAdminPermission) {
      return;
    }

    return (
      <div
        style={{
          marginTop: '1rem',
          width: '100%',
          minWidth: '500px'
        }}
      >
        <FlexRow style={{ gap: '2rem' }}>
          <Typography variant='h6'>List Item</Typography>
          <DownloadAllFilesButton
            buttonText='Download All Project Files'
            urlList={fileUrlList}
            folderName={`Factorem_Project_${editProjectID}`}
          />
        </FlexRow>
        <div style={{ padding: '0.5rem' }}>
          {editProjectItems.map((projectItem) => {
            const customerPoFiles = (projectItem.customerUploadFiles || []).filter(uploadItem => uploadItem.type === ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO);
            const watchingList = projectItem.watchingJobs || []
            const hasWatchingUsers = watchingList.length > 0;
            const notInterestedList = projectItem.notInterestedJobs || [];
            const totalNotInterestedSuppliers = notInterestedList.length;
            return (
              <div
                style={{
                  padding: '0.3rem',
                }}
                key={projectItem.itemID}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 'bold',
                    }}
                    variant='body1'
                  >
                    Item #{projectItem.itemID}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      columnGap: '0.5rem',
                      alignItems: 'center',
                    }}
                  >
                    {projectItem.brevoNewRfqProjectQuotations && (
                      <EmailStatusIcon
                        status={projectItem.brevoNewRfqProjectQuotations?.lastStatus}
                        emailType={projectItem.brevoNewRfqProjectQuotations?.type}
                        date={projectItem.brevoNewRfqProjectQuotations?.updatedAt}
                      />
                    )}
                    {projectItem.brevoExpiringItems && (
                      <EmailStatusIcon
                        status={projectItem.brevoExpiringItems?.lastStatus}
                        emailType={projectItem.brevoExpiringItems?.type}
                        date={projectItem.brevoExpiringItems?.updatedAt}
                      />
                    )}
                    <WatchingJob
                      forAdmin
                      isWatching={hasWatchingUsers}
                      totalWatching={getTotalWatchingJobsByUserID(watchingList)}
                      onClick={() => {
                        if (hasWatchingUsers) {
                          setShowPopupWatchingJob(true);
                          setSelectedItem(projectItem);
                        }
                      }}
                    />
                    <NotInterestedJob
                      forAdmin
                      totalSuppliers={totalNotInterestedSuppliers}
                      onClick={() => {
                        if (totalNotInterestedSuppliers > 0) {
                          setShowNotInterestedPopup(true);
                          setSelectedItem(projectItem);
                        }
                      }}
                    />
                    {hasAdminPermission && (
                      <ViewAllowSuppliersButton
                        onClick={() => {
                          updateItemAllowedSuppliersPopupState({
                            open: true,
                            itemID: projectItem.itemID,
                          });
                        }}
                        disabled={!projectItem.verifiedDate}
                      />
                    )}
                    {hasAdminPermission && (
                      <ItemRfqEmailScheduleConfigButton item={projectItem} />
                    )}
                    {isEmptyValue(customerPoFiles) ? (
                      <FilesUploadActionButton
                        inputID={`file-upload-${projectItem.itemID}`}
                        buttonText='Upload PO'
                        highlightButton
                        handleUploadFiles={(files) =>
                          handleItemFilesUpload(files, projectItem.itemID)
                        }
                        disabled={customerPoFiles.length > 0}
                        multiple={false}
                      />
                    ) : (
                      <FilesUploadedActionButton
                        inputID={`file-upload-${projectItem.itemID}`}
                        buttonText='PO Uploaded'
                        uploaded={true}
                        disabled={customerPoFiles.length > 0}
                      />
                    )}
                  </div>
                </div>
                {customerPoFiles.map((poFileItem) => (
                  <DownloadableFileWithActions
                    key={poFileItem.url.split('/').pop()}
                    fileUrl={poFileItem.url}
                    fileName={poFileItem.fileName}
                    showDeleteButton={ROLE_TYPES.SUPER_ADMIN === userRole}
                    showCopyUrlButton={hasAdminPermission}
                    onDelete={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setOpenDeletePoFilePopup(true);
                      setPoFileToBeDeleted({
                        itemID: projectItem.itemID,
                        file: poFileItem,
                      });
                    }}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    )
  }

  const renderProjectQcReports = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <div>
          <Button
            color='primary'
            size='medium'
            startIcon={<AddIcon />}
            onClick={() => setOpenQualityPopup(true)}
          >
            Quality Certifications
          </Button>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '1rem',
            marginTop: '0.3rem',
          }}
        >
          <QcReportsDisplay qcReports={qcReports} />
        </div>
      </div>
    );
  }

  const renderProjectSupplierQcReports = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <div>
          <Button
            color='primary'
            size='medium'
            startIcon={<AddIcon />}
            onClick={() => setOpenSupplierQualityPopup(true)}
          >
            Supplier Quality Certifications
          </Button>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '1rem',
            marginTop: '0.3rem',
          }}
        >
          <QcReportsDisplay qcReports={supplierQcReports} />
        </div>
        {supplierQcReports?.message && (
          <Typography
            variant='caption'
            style={{
              fontStyle: 'italic',
              color: colors.darkGreen,
              paddingLeft: '1rem'
            }}
          >
            &bull; {supplierQcReports?.message} at {formatDate(supplierQcReports.modifiedAt, 'ddd, DD MMM YYYY HH:mm')}
          </Typography>

        )}
      </div>
    );
  }

  const renderProjectTimingControl = () => {
    if (!hasAdminPermission) {
      return;
    }

    return (
      <div>
        <Typography variant='h6'>Project Timing Control</Typography>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Tooltip
            title='Number of hours beyond which project automatically becomes visible to Category 2 suppliers'
            arrow>
            <TextField
              label='Category 2 timing'
              value={projectTimingControlState.category2Timing}
              onChange={(evt) => updateProjectTimingControlState({ category2Timing: evt.target.valueAsNumber })}
              variant='outlined'
              margin='dense'
              style={{ marginRight: '1.25rem' }}
              fullWidth
              type='number'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>hours</InputAdornment>
                )
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Tooltip>
          <Tooltip
            title='Number of hours beyond which project automatically becomes visible to ALL suppliers'
            arrow>
            <TextField
              label='Category 3 timing'
              value={projectTimingControlState.category3Timing}
              onChange={(evt) => updateProjectTimingControlState({ category3Timing: evt.target.valueAsNumber })}
              variant='outlined'
              margin='dense'
              style={{ marginRight: '1.25rem' }}
              fullWidth
              type='number'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>hours</InputAdornment>
                )
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Tooltip>
        </div>
        {itemAllowedSuppliersPopupState.open && (
          <ViewItemAllowedDeniedSuppliersPopup
            open={itemAllowedSuppliersPopupState.open}
            itemID={itemAllowedSuppliersPopupState.itemID}
            handleClose={() => updateItemAllowedSuppliersPopupState({ open: false })}
            locationVisibility={itemAllowedSuppliersPopupState.locationVisibility}
          />
        )}
      </div>
    );
  }

  const renderProjectLocationVisibilityControl = () => {
    if (!hasAdminPermission) {
      return;
    }

    return (
      <div
        style={{
          marginTop: '1rem',
        }}
      >
        <Typography variant='h6'>Location Visibility</Typography>
        <div>
          <RadioGroup
            aria-labelledby='radio-location-select'
            defaultValue={locationVisibilityState.option}
            name='radio-property-group'
            onChange={(event) => {
              const eValue = event.target.value;
              updateLocationVisibilityState({
                option: eValue,
                selectedCountries: [],
              });
            }}
            row
          >
            <FormControlLabel
              checked={
                locationVisibilityState.option === LOCATION_VISIBILITY_OPTIONS.WORLD_WIDE
              }
              value={LOCATION_VISIBILITY_OPTIONS.WORLD_WIDE}
              control={<Radio color='primary' />}
              label='Worldwide visibility'
            />
            <FormControlLabel
              checked={
                locationVisibilityState.option === LOCATION_VISIBILITY_OPTIONS.SELECT_COUNTRIES
              }
              value={LOCATION_VISIBILITY_OPTIONS.SELECT_COUNTRIES}
              control={<Radio color='primary' />}
              label='Select Countries'
            />
          </RadioGroup>
        </div>
        {locationVisibilityState.option === LOCATION_VISIBILITY_OPTIONS.SELECT_COUNTRIES && (
          <div
            style={{
              padding: '0 1rem',
            }}
          >
            <MultiSelectChipDropdown
              id='multi-select-countries'
              label='Select Countries'
              itemList={COUNTRIES.map(country => ({
                key: country,
                text: country,
              }))}
              selectedItems={locationVisibilityState.selectedCountries}
              onSelect={(selectedCountries) => {
                updateLocationVisibilityState({
                  selectedCountries,
                });
              }}
              searchable
            />
          </div>
        )}
      </div>
    );
  }

  const renderAdminProjectNotes = () => {
    if (!hasAdminPermission) {
      return;
    }

    const toolTipText = "Admin notes will only be visible to internal admins. Customer/Supplier will not be able to see this."
    return (
      <div
        style={{
          marginTop: '1rem',
        }}
      >
        <Typography
          variant='h6'
          style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}
        >
          <span>Admin Project Notes</span>
          <InfoIcon toolTipText={toolTipText} />
        </Typography>
        <Tooltip
          title={toolTipText}
          arrow
        >
          <TextField
            label='Admin Project Notes'
            multiline
            minRows={3}
            value={adminProjectNotes}
            onChange={(evt) => setAdminProjectNotes(evt.target.value)}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            fullWidth
          />
        </Tooltip>
      </div>
    );
  }

  const renderProjectForm = () => (
    <Paper
      elevation={1}
      style={{ padding: '4.375rem' }}
    >
      <div className={classes.container}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={classes.field}>
            Project ID: {editProjectID}
          </div>
          {ROLE_TYPES.SUPER_ADMIN === userRole && (
            <Tooltip title='View as customer'>
              <Fab
                style={{ marginLeft: 'auto', marginRight: '10px' }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`${window.location.pathname}/vac`)
                    return;
                  }
                  props.history.push({
                    pathname: `${window.location.pathname}/vac`,
                  });
                }}
                color='primary'
                size='small'
              >
                <VisibilityIcon style={{ fontSize: '15pt' }} />
              </Fab>
            </Tooltip>
          )}
        </div>
        <div className={classes.field}>
          Date Created: {editProjectDateCreated ? editProjectDateCreated.substring(0, 10) : ''}
        </div>
      </div>
      <TextField
        label='Project Owner'
        select
        value={projectOwnerID}
        onChange={(evt) => setProjectOwnerID(evt.target.value)}
        variant='outlined'
        margin='dense'
        fullWidth
        InputLabelProps={{ shrink: !!projectOwnerID }}
      >
        {allBuyers.map((buyer) => (
          <MenuItem key={buyer.userID} value={buyer.userID}>
            {`${buyer.name} <${buyer.email}>`}
          </MenuItem>
        ))}
      </TextField>
      <div className={classes.label}>
        <TextField
          label='Project Name'
          value={editProjectName}
          onChange={(evt) => setEditProjectName(evt.target.value)}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth={true}
        />
        <TextField
          label='Project Status'
          select
          value={editProjectStatus}
          onChange={(evt) => {
            if (evt.target.value === 'Disabled') {
              setOpenDisableProjectPopup(true);
              return;
            }
            setEditProjectStatus(evt.target.value);
            setDisabledReason('');
          }}
          variant='outlined'
          margin='dense'
          fullWidth={true}
        >
          {projectStatus.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
        {editProjectStatus === 'Disabled' && (
          <TextField
            // 'cancellation' used here instead of 'disabled' to align what is seen on item
            // on backend database known as disabled reason
            label='Cancellation Reason'
            select
            value={disabledReason}
            onChange={(evt) => { setDisabledReason(evt.target.value) }}
            variant='outlined'
            margin='dense'
            helperText={
              isEmptyValue(disabledReason) && 'Please select cancellation reason'
            }
            fullWidth={true}
            error={isEmptyValue(disabledReason)}
            style={{ marginLeft: '1.25rem' }}
          >
            {Object.values(ITEM_CANCELLATION_REASON).sort().map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ flexWrap: 'no-wrap' }}>
              Expected Project Deadline&nbsp;
            </span>
            <Tooltip
              title='Only displayed to suppliers when not empty. Customer will not be able to see this property.'
              arrow
            >
              <div>
                <DatePicker
                  style={{ marginLeft: '5px', flex: '1' }}
                  value={expectedProjectDeadline}
                  onChange={setExpectedProjectDeadline}
                  animateYearScrolling
                  inputVariant='outlined'
                  margin='dense'
                  clearable
                  clearLabel='No Preference'
                  emptyLabel='No Preference'
                />
              </div>
            </Tooltip>
          </span>
        </Grid>
      </Grid>
      {renderListItem()}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {renderProjectQcReports()}
        </Grid>
        <Grid item xs={6}>
          {renderProjectSupplierQcReports()}
        </Grid>
      </Grid>
      {renderAdminProjectNotes()}
      {renderProjectTimingControl()}
      {renderProjectLocationVisibilityControl()}
    </Paper>
  );

  const renderQualityCertificationsPopup = () => {
    return (
      <QualityCertificationsPopup
        dialog={openQualityPopup}
        value={qcReports}
        onClose={() => setOpenQualityPopup(false)}
        onOk={(value) => {
          setQcReports(value);
          setOpenQualityPopup(false);
        }}
        key='qcReports'
      />
    )
  }

  const renderSupplierQualityCertificationsPopup = () => {
    if (!openSupplierQualityPopup) {
      return null
    }
    return (
      <QualityCertificationsPopup
        dialog={openSupplierQualityPopup}
        value={supplierQcReports}
        onClose={() => setOpenSupplierQualityPopup(false)}
        onOk={(value) => {
          setSupplierQcReports(value);
          setOpenSupplierQualityPopup(false);
        }}
        title='Add Supplier Quality Check Report'
        key='supplierQcReports'
      />
    )
  }

  return (
    <div className={classes.body}>
      <div>{renderProjectForm()}</div>
      <div style={{ marginTop: '1.25rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={(e) => handleConfirmSubmit(e)}
        >
          Update Project Detail
        </Button>
      </div>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          startIcon={<DeleteIcon />}
          variant='contained'
          color='secondary'
          className={classes.button}
          onClick={() => setOpenDeleteProjectPopup(true)}
        >
          Delete Project
        </Button>
      </div>
      <Dialog
        open={warningDialog}
        disableEscapeKeyDown
        aria-labelledby='warning-dialog'
      >
        <DialogTitle id='warning-dialog-title'>
          Changing QC Reports
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='warning-dialog-content'>
            {`PO will be regenerated for items with an accepted quotation.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWarningDialog(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      {openDeletePoFilePopup && renderDeletePoFilePopup()}
      {openDisableProjectPopup && renderDisableProjectPopup()}
      {openDeleteProjectPopup && renderDeleteProjectPopup()}
      {renderQualityCertificationsPopup()}
      {renderSupplierQualityCertificationsPopup()}
      <WatchingJobPopup
        open={showPopupWatchingJob}
        onClose={() => setShowPopupWatchingJob(false)}
        title={`Watching Part #${selectedItem && selectedItem.itemID}`}
        data={get(selectedItem, 'watchingJobs', [])}
      />
      <NotInterestedJobPopup
        open={showNotInterestedPopup}
        onClose={() => setShowNotInterestedPopup(false)}
        itemID={selectedItem && selectedItem.itemID}
        notInterestedJobs={get(selectedItem, 'notInterestedJobs', [])}
        onSuccess={() => {
          getProject();
          setShowNotInterestedPopup(false)
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID,
    userRole: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(EditProjectPage);
