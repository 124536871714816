import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Menu,
  Divider,
} from '@material-ui/core';

import CopyToClipboardIcon from './CopyToClipboardIcon';
import { FtrTooltip, FtrTypography, FtrItalicText } from './ftr-components';
import FtrButton from './ftr-components/FtrButton';
import { FlexRowSpaceBetween } from '../components/layouts/FlexLayouts';

import { generateTokenForGuest } from '../apis/jwtApi';

import { formatDateWithTime } from '../utils/dateTimeUtils';

import { notifyError } from '../services/notificationService';

import { getGuestUserInfoSelector } from '../selectors/userSelector';

const GenerateGuestLinkButton = ({ permissions }) => {
  const location = useLocation();
  const guestUserInfo = useSelector(getGuestUserInfoSelector);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const { mutate, data: guestLinkMessage, isLoading, isError, error } = useMutation(
    async () => {
      const { tokenID, accessCode, expiryDate } = await generateTokenForGuest({
        permissions,
        navigateTo: `${location.pathname}${location.search}`
      });
      return {
        link: `${window.location.origin}/guest/${tokenID}`,
        accessCode,
        expiryDate: formatDateWithTime(expiryDate),
      };
    }
  );
  
  const handleButtonClick = (event) => {
    mutate();
    setAnchorEl(event.currentTarget);
  };
 
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const renderMenuContent = () => {
    if (isLoading) {
      return <FtrTypography>Generating your secure link...</FtrTypography>;
    }
   
    if (guestLinkMessage) {
      return (
        <div>
          <FlexRowSpaceBetween>
            <FtrTypography>
              Link:&nbsp;
            </FtrTypography>
            <a href={guestLinkMessage.link} target='_blank'>
              <FtrTypography>{guestLinkMessage.link}</FtrTypography>
            </a>
            <CopyToClipboardIcon
              message={guestLinkMessage.link}
              copyableTooltipTitle="Copy link"
            />
          </FlexRowSpaceBetween>
          <FlexRowSpaceBetween>
            <FtrTypography>{`Access code: ${guestLinkMessage.accessCode}`}</FtrTypography>
            <CopyToClipboardIcon
              message={guestLinkMessage.accessCode}
            copyableTooltipTitle="Copy access code"
            />
          </FlexRowSpaceBetween>
          <Divider />
          <FtrItalicText>
            This link expires on {guestLinkMessage.expiryDate}
          </FtrItalicText>
        </div>
      );
    }
    return null;
  };

  if (isError) {
    notifyError(error.message);
  }
  
  if (guestUserInfo) {
    return null;
  }

  return (
    <>
      <FtrTooltip
        description={'Get a link to allow guest users from your company to access this page'}
      >
        <div>
          <FtrButton 
            color="blue" 
            onClick={handleButtonClick} 
            disabled={isLoading}
          >
            {isLoading ? 'Generating link...' : 'Share this page'}
          </FtrButton>
        </div>
      </FtrTooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            width: '400px',
            maxHeight: '100vh',
            padding: '16px',
            borderRadius: '12px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          <FtrTypography>
            Here's a secure link for your team to access this page. Make sure to share both the link and access code.
          </FtrTypography>

          {!isLoading && renderMenuContent()}
        </div>
      </Menu>
    </>
  );
};

export default GenerateGuestLinkButton;
