import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

import Title from '../Title';
import BlueButton from '../buttons/BlueButton';
import {
  createNewVersion,
  exportVersion,
  getCurrentVersion,
  getListVersions,
  revertVersion,
  updateCurrentVersion,
} from '../../apis/configurationVersionApi';
import { notifyError, notifySuccess } from '../../services/notificationService';
import { downloadBlobUtil } from '../../utils/downloadUtils';
import { dateTzSingapore } from '../../utils/dateTimeUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function VersioningCustomerInputPopup(props) {
  const classes = useStyles();
  const { open, onClose, data } = props;

  const [listVersions, setListVersions] = useState([]);
  const [selectVersionExport, setSelectVersionExport] = useState(null);
  const [selectVersionRevert, setSelectVersionRevert] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);

  useEffect(() => {
    const getApis = async () => {
      const listVersionsApi = await getListVersions();
      setListVersions(listVersionsApi);
      const currentVersionApi = await getCurrentVersion();
      setCurrentVersion(currentVersionApi.version);
    };
    getApis();
  }, []);

  const handleNewVersion = () => {
    createNewVersion({ data })
      .then(() => {
        notifySuccess('Success create new version configuration');
        onClose();
      })
      .catch(() => {
        notifyError('Failed create new version configuration');
      });
  };

  const handleUpdateCurrentVersion = () => {
    updateCurrentVersion({ data, version: currentVersion })
      .then(() => {
        notifySuccess('Success update current version configuration');
        onClose();
      })
      .catch(() => {
        notifyError('Failed update current version configuration');
      });
  };

  const handleExportVersionData = () => {
    if (!selectVersionExport) return;
    exportVersion({ version: selectVersionExport })
      .then((blob) => {
        downloadBlobUtil(blob, `v${selectVersionExport}-configuration.json`);
        onClose();
      })
      .catch(() => {
        notifyError('Failed export version configuration');
      });
  };

  const handleRevertVersion = () => {
    if (!selectVersionRevert) return;
    revertVersion({ version: selectVersionRevert })
      .then(() => {
        notifySuccess(
          `Success revert to v${selectVersionRevert} configuration`
        );
        window.location.reload();
        onClose();
      })
      .catch(() => {
        notifyError('Failed export version configuration');
      });
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='versioning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='versioning-dialog-title'>
        <Title contentTitle='Versioning Customer Inputs' size='small' />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 3rem',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          <React.Fragment>
            <div
              style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                {currentVersion !== null && (
                  <React.Fragment>
                    <TextField
                      label='Current Version'
                      variant='outlined'
                      style={{ width: '15rem' }}
                      value={currentVersion}
                      margin='dense'
                      disabled
                    />
                    <BlueButton
                      size='small'
                      btnContent='Update Current Version'
                      onBtnClick={handleUpdateCurrentVersion}
                    />
                  </React.Fragment>
                )}
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                {!!listVersions.length && (
                  <React.Fragment>
                    <TextField
                      label='Select Export Version'
                      select
                      variant='outlined'
                      style={{ width: '15rem', textAlign: 'left' }}
                      onChange={(evt) =>
                        setSelectVersionExport(evt.target.value)
                      }
                      value={selectVersionExport}
                      margin='dense'
                    >
                      {listVersions.map((option) => (
                        <MenuItem key={option.version} value={option.version}>
                          {option.version} - {dateTzSingapore(option.updatedAt)}
                        </MenuItem>
                      ))}
                    </TextField>
                    <BlueButton
                      size='small'
                      btnContent='EXPORT'
                      onBtnClick={handleExportVersionData}
                    />
                  </React.Fragment>
                )}
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                {!!listVersions.length && (
                  <React.Fragment>
                    <TextField
                      label='Select Revert Version'
                      select
                      variant='outlined'
                      style={{ width: '15rem', textAlign: 'left' }}
                      onChange={(evt) =>
                        setSelectVersionRevert(evt.target.value)
                      }
                      value={selectVersionRevert}
                      margin='dense'
                    >
                      {listVersions.map((option) => (
                        <MenuItem key={option.version} value={option.version}>
                          {option.version} - {dateTzSingapore(option.updatedAt)}
                        </MenuItem>
                      ))}
                    </TextField>
                    <BlueButton
                      size='small'
                      btnContent='REVERT'
                      onBtnClick={handleRevertVersion}
                    />
                  </React.Fragment>
                )}
              </div>
              <BlueButton
                size='small'
                btnContent='Create new Version'
                onBtnClick={handleNewVersion}
              />
            </div>
          </React.Fragment>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default VersioningCustomerInputPopup;
