import React from 'react';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';

import { getExchangeRates } from '../apis/exchangeRatesApi';
import { createAlertNotification } from '../apis/alertApi';
import { getUserInfo, getUserInfoWithCache } from '../apis/userApi';

import { receiveSignupAfterQuote, removeCreateUserPassword } from './createUser';
import { resetPartUploadFormState } from './partUploadActions';

import { fetchWithTimeout, getAuthorizedHeader } from '../utils/apiUtils';
import { isEmptyValue } from '../utils/commonUtils';

import { notifyError, notifySuccess } from '../services/notificationService';
import { removeCookie } from '../services/cookiesService';

import {
  ACCESS_TOKEN_KEY,
  BACKEND_SERVICE_URL,
  CURRENCY,
  DEFAULT_MYR_EXCHANGE_RATE,
  DEFAULT_USD_EXCHANGE_RATE,
  ROLE_TYPES,
  USER_AGENT_INFO_KEY,
} from '../constants';


// --------------------------------------------------------------------------------------------------

const FACTOREM_MAIN_PROD_URL = 'https://app.factorem.co/login';
const FACTOREM_MAIN_STG_URL = 'https://app-staging.factorem.co/login';
const FACTOREM_ONBOARDING_PROD_URL = 'https://onboarding.factorem.co';
const FACTOREM_ONBOARDING_STG_URL = 'https://onboarding-stg.factorem.co'
const FACTOREM_ONBOARDING_LOCAL_URL = 'http://localhost:4000';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_GUEST = 'LOGIN_GUEST';
export const REMOVE_GUEST = 'REMOVE_GUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const RETRIEVE_LOCATION_SUCCESS = 'RETRIEVE_LOCATION_SUCCESS';
export const SET_USER_CREDIT_SUCCESS = 'SET_USER_CREDIT_SUCCESS'
export const SET_LOCATION_SUCCESS = 'SET_LOCATION_SUCCESS';
export const SET_COUNTRY_SUCCESS = 'SET_COUNTRY_SUCCESS';
export const RETRIEVE_EXCHANGE_RATE_SUCCESS = 'RETRIEVE_EXCHANGE_RATE_SUCCESS';
export const SET_EXCHANGE_RATES_SUCCESS = 'SET_EXCHANGE_RATES_SUCCESS';
export const SET_UNIT_TYPE_SUCCESS = 'SET_UNIT_TYPE_SUCCESS';

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST
  };
};

const receiveGuest = (guestUserInfo) => {
  return {
    type: LOGIN_GUEST,
    guestUserInfo,
  }
}

const removeGuest = () => {
  return {
    type: REMOVE_GUEST,
  }
}

export const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

const resetEmailSent = () => {
  return {
    type: RESET_SUCCESS
  };
};

const resetEmailFail = () => {
  toast.error('Incorrect email address. Please try again!', {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: RESET_FAIL
  };
};

const loginError = (message) => {
  toast.error(message || 'Incorrect username or password. Please try again!', {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: LOGIN_FAILURE
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

const retrieveLocation = location => {
  return {
    type: RETRIEVE_LOCATION_SUCCESS,
    location,
  };
};

const setLocation = location => {
  return {
    type: SET_LOCATION_SUCCESS,
    location,
  };
};

const setCountry = payload => {
  return {
    type: SET_COUNTRY_SUCCESS,
    payload,
  };
};

const setExchangeRates = rates => {
  return {
    type: SET_EXCHANGE_RATES_SUCCESS,
    rates,
  };
};

const retrieveExchangeRates = rates => {
  return {
    type: RETRIEVE_EXCHANGE_RATE_SUCCESS,
    rates,
  };
};

const retrieveUserCredit = credits => {
  return {
    type: SET_USER_CREDIT_SUCCESS,
    credits,
  };
};

export const setUnitType = unitType => {
  return {
    type: SET_UNIT_TYPE_SUCCESS,
    unitType,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS
  };
};

const openVerifyEmailPage = (props, email) => {
  const path = isEmptyValue(email)
    ? '/verify-email'
    : `/verify-email?email=${email}`;
  props.history.push(path);
  toast.error('Please verify your email address before logging in!', {
    position: toast.POSITION.TOP_RIGHT
  });
};

const getOnboardingUrl = () => {
  // Direct to the onboarding url based on the env
  switch (window.location.href) {
    case FACTOREM_MAIN_PROD_URL:
      return FACTOREM_ONBOARDING_PROD_URL;
    case FACTOREM_MAIN_STG_URL:
      return FACTOREM_ONBOARDING_STG_URL;
    default:
      return FACTOREM_ONBOARDING_LOCAL_URL;
  }
}

const cookies = new Cookies();

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  try {
    cookies.remove('token', { path: '/' });
    if (!cookies.get('token')) {
      dispatch(receiveLogout());
    }
    dispatch(resetPartUploadFormState());
  } catch (error) {
    dispatch(logoutError());
  }
};

export const resetAccount = (email, props) => dispatch => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/reset`;

  let data = {
    email: email
  };

  fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(function (response) {
    response
      .json()
      .then(res => {
        if (res.success) {
          dispatch(resetEmailSent(props));
        } else {
          dispatch(resetEmailFail());
        }
      })
      .catch(function () {
        dispatch(resetEmailFail());
      });
  });
};

export const retrieveUserLocation = (userID) => dispatch => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}/currency`;
  fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  })
    .then(response => {
      if (!response.ok) {
        const modifiedLocation = {
          currency: CURRENCY.SGD,
        };
        dispatch(retrieveLocation(modifiedLocation));
      } else {
        response.json().then(({ data }) => {
          const modifiedLocation = {
            currency: data.currency || CURRENCY.SGD,
          };
          dispatch(retrieveLocation(modifiedLocation));
        });
      }
    })
    .catch(() => {
      // unable to retrieve location
      const modifiedLocation = {
        currency: CURRENCY.SGD,
      };
      dispatch(retrieveLocation(modifiedLocation));
    });
};

export const retrieveExchangeRate = () => (dispatch) => {
  getExchangeRates()
    .then(function (response) {
      let rates = {
        ...response.exchangeRates['exchange_rates'],
      };
      dispatch(retrieveExchangeRates(rates));
    })
    .catch(() => {
      dispatch(retrieveExchangeRates({
        MYR: DEFAULT_MYR_EXCHANGE_RATE,
        USD: DEFAULT_USD_EXCHANGE_RATE,
        SGD: 1,
      }));
    });
};


export const retrieveUserCredits = (userID) => (dispatch) => {
  getUserInfo(userID)
    .then(user => {
      dispatch(retrieveUserCredit(user.credits));
    })
    .catch(() => {
      notifyError(`Cannot get user credits`);
    });
};

export const retrieveUserUnitType = (userID) => (dispatch) => {
  getUserInfoWithCache(userID)
    .then(user => {
      dispatch(setUnitType(user.unitType));
    })
    .catch(() => {
      notifyError(`Cannot get user unitType`);
    });
};

export const updateUserCurrency = (userID, currency) => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}/currency`;
  fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ currency }),
  })
    .then(response => {
      if (!response.ok) {
        notifyError(`Can't update user currency`);
      } else {
        const location = {
          currency,
        };
        dispatch(setLocation(location));
      }
    })
    .catch(() => {
      notifyError(`Can't update user currency`);
    });
};

export const updateUserCountryAndCurrency = (userID, { country, currency }) => (dispatch) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}/country-and-currency`;
  fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ country, currency }),
  })
    .then(response => {
      if (!response.ok) {
        notifyError(`Updated country and currency failed`);
      } else {
        dispatch(setLocation({ currency }));
        dispatch(setCountry({ country }));
        notifySuccess('Updated country and currency successfully');
      }
    })
    .catch(() => {
      notifyError(`Updated country and currency failed`);
    });
};

export const setExchangeRateManually = (rates) => dispatch => {
  dispatch(setExchangeRates(rates));
};

const updateWithUserDetails = (user, userAgentInfo = {}) => dispatch => {
  dispatch(receiveLogin({
    ...user,
    userAgentInfo,
  }));
  dispatch(retrieveUserUnitType(user.userID));
  dispatch(removeCreateUserPassword());
  if ([ROLE_TYPES.SUPPLIER, ROLE_TYPES.BUYER].includes(user.role)) {
    dispatch(retrieveExchangeRate());
    dispatch(retrieveUserLocation(user.userID));
  }
}

export const loginUser = (email, password, userAgentInfo, props) => (dispatch) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/login`;
  const path = props?.location?.pathname;
  let data = {
    email,
    password,
    userInfo: userAgentInfo,
    path,
  };
  cookies.set(USER_AGENT_INFO_KEY, userAgentInfo, {
    path: '/',
    maxAge: 24 * 60 * 60, // 1 day in seconds
  });
  const timer = setTimeout(() => {
    const body = {
      type: 'LOGIN_TIMEOUT',
      email,
    }
    createAlertNotification(body);
  }, 5000); // wait 5 seconds to send alert
  return fetchWithTimeout(requestUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
    timeout: 30000, // 30 seconds
  })
    .then(function (response) {
      dispatch(requestLogin());
      response.json().then(user => {
        clearTimeout(timer);
        if (response.status === 403) {
          // Unverified email
          if (path === '/summary') {
            // getquotenow, show the verification number verifying popup
            dispatch(receiveSignupAfterQuote(email, email, password));
          } else {
            // navigate to verify email page
            dispatch(openVerifyEmailPage(props, email));
          }
          return;
        }

        if (user.role === ROLE_TYPES.ONBOARDING_SUPPLIER) {
          // Onboarding suppliers should login on the onboarding platform
          const onboardingUrl = getOnboardingUrl();

          // Use JSX to render a clickable link
          dispatch(loginError(
            <p>
              Please login via our Onboarding Platform at{' '}
              <a href={onboardingUrl} rel='noopener noreferrer'>
                {onboardingUrl}
              </a>
            </p>
          ));
        } else if (response.status === 200 && user.accessToken) {
          //Successful response
          cookies.set(ACCESS_TOKEN_KEY, user.accessToken, {
            path: '/',
            maxAge: 24 * 60 * 60, // 1 day in seconds
          });
          dispatch(updateWithUserDetails(user, userAgentInfo));
        } else {
          //Incorrect username or password
          dispatch(loginError());
        }
      });
    })
    .catch(function (err) {
      if (err.name === 'AbortError') {
        dispatch(loginError('Unexpected error occurred. Please contact our admin for details.'));
      } else {
        dispatch(loginError());
      }
      throw err;
    });
};

export const authenticateGuestUser = (decodedToken) => dispatch => {
  const { userID, isGuest, permissions, tokenID, navigateTo, exp } = decodedToken;
  if (!isGuest) {
    return;
  }
  dispatch(verifyRequest());
  getUserInfo(userID)
    .then(user => {
      dispatch(receiveLogin(user));
      dispatch(receiveGuest({ permissions, tokenID, navigateTo, exp }));
      dispatch(updateWithUserDetails(user));
      dispatch(verifySuccess());
    })
    .catch((error) => {
      console.error(error);
      dispatch(receiveLogout());
    })
}

export const removeGuestUser = () => (dispatch, getState) => {
  const guestUserInfo = getState()?.auth?.guestUserInfo;
  if (guestUserInfo) {
    dispatch(removeGuest);
    removeCookie(ACCESS_TOKEN_KEY, { path: '/' });
  }
}

export const verifyAuth = () => dispatch => {
  try {
    const token = cookies.get(ACCESS_TOKEN_KEY);
    if (token) {
      const decodedToken = jwt_decode(token);
      const { userID, isGuest } = decodedToken;
      if (isGuest) {
        return;
      }
      dispatch(verifyRequest());
      // this will check and patch user's data from server, will replace if data has been modify from local browser's storage
      getUserInfo(userID)
        .then(user => {
          dispatch(receiveLogin(user));
          dispatch(verifySuccess());
        })
        .catch((error) => {
          console.error(error);
          dispatch(receiveLogout());
        })
    } else {
      dispatch(receiveLogout());
    }
  } catch (err) {
    // throw err
  }
};
