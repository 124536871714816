import { adminGetSubmittedProjectOrderReady } from "../apis/orderReadyApi";

export const GET_SUBMITTED_PROJECT_ORDER_READY_COUNT = "GET_SUBMITTED_PROJECT_ORDER_READY_COUNT";

export const getSubmittedProjectOrderReadyCountAction = () => (dispatch) => {
  adminGetSubmittedProjectOrderReady()
    .then(data => {
      const count = data.length;
      dispatch({
        type: GET_SUBMITTED_PROJECT_ORDER_READY_COUNT,
        payload: count,
      });
    });
};
