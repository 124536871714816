import React, { useState, useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { ceil } from "lodash";
import { withRouter, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles/index";

import { Button } from "@material-ui/core";

import { DEFAULT_FACTOREM_GST, ORDER_STATUS, ROLE_TYPES } from "../constants";
import { Badge, Tooltip } from "@material-ui/core";
import { colors } from "../palette";
import { materialWithColorCol, surfaceFinishingWithColorCol } from "../constants/itemTableConstants";
import { rocketQuoteInfoCol } from "../constants/quotationTableConstants";
import { QUOTATION_VERIFY_STATUS_FILTER_ARRAY } from "../constants/quotationStatus"

import { adminExportQuotationsAsCsv, adminGetAllQuotations } from "../apis/quotationApi";
import { generateCurrentCustomDateTimeString } from "../util";
import { DataGrid } from "@mui/x-data-grid";

import CustomToolbar from "../components/grid-data/CustomToolbar";
import { ExportCsvButton } from "../components/grid-data/buttons/ExportCsvButton";
import GridDataPagination from "../components/grid-data/GridDataPagination";
import SearchBar from "../components/grid-data/buttons/SearchBar";
import DataGridWrapTextCell from "../components/tables/cells/DataGridWrapTextCell";
import FilterBar from '../components/filters/FilterBar';
import EditQuoteDialog from './EditQuoteDialog';

const useStyles = makeStyles(() => ({
  body: {
    padding: '2.5rem',
    paddingTop: '1.5rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer'
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'scale(0.8) translate(75%, 20%)'
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  tooltipBadgeContainer: {
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    lineHeight: 'normal',
    '& .MuiBadge-badge': {
      top: -10,
    },
  },
}));

export function VerifyQuotesDataGridPagination(props) {
  const classes = useStyles();

  const { role } = props;
  const [quotationID, setQuotationID] = useState("");
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      status: ORDER_STATUS.VERIFYING,
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
      quoteType: 'ppe'
    }
  );

  const columns = [
    {
      title: "Quote ID",
      headerName: "Quote ID",
      field: "quotationID",
      align: "center",
      renderCell: (params) => {
        const quotationID = params.row.quotationID;
        return (
          <DataGridWrapTextCell text={quotationID} data-cy={`verify-quotationID-${quotationID}`} />
        );
      },
    },
    {
      title: "Project ID",
      headerName: "Project ID",
      field: "projectID",
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Button
            className={classes.linkButton}
            href={`/manage-projects/${rowData?.projectID}`}
            onClick={(e) => e.stopPropagation()}
          >
            {rowData?.projectID}
          </Button>
        );
      }
    },
    {
      title: "Part ID",
      headerName: "Part ID",
      field: "itemID",
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Button
            className={classes.linkButton}
            href={`/item/edit/${rowData?.itemID}`}
            onClick={(e) => e.stopPropagation()}
          >
            {rowData?.itemID}
          </Button>
        );
      }
    },
    {
      title: "Name",
      headerName: "Name",
      field: "name",
      align: "center",
      renderCell: (params) => {
        return (
          <DataGridWrapTextCell text={params.row.name} />
        )
      },
      minWidth: 150,
    },
    {
      title: "PPE Quote",
      headerName: "PPE Quote",
      field: "ppeQuote",
      align: "center",
      exportData: (rowData) => {
        const isPpeQuote = rowData.rfqQuote == null
          ? !!rowData.price
          : !rowData.rfqQuote;
        if (!isPpeQuote) {
          return 'No';
        }
        return 'Yes';
      },
      renderCell: (params) => {
        const rowData = params.row;
        const isPpeQuote = rowData?.rfqQuote == null
          ? !!rowData?.price
          : !rowData?.rfqQuote;
        const isRocketQuote = rowData?.rocketQuote;
        if (!isPpeQuote) {
          return renderNo();
        }
        if (!isRocketQuote) {
          const { numOfPpeQuotes } = rowData;
          return numOfPpeQuotes
            ? renderPpeQuoteBadgeTooltip(numOfPpeQuotes)
            : renderYes();
        }
        return renderYes();
      },
    },
    {
      ...rocketQuoteInfoCol,
      align: "center",
    },
    {
      title: "Quantity",
      headerName: "Quantity",
      field: "quantity",
      align: "center",
    },
    {
      ...materialWithColorCol,
      align: "center",
    },
    {
      ...surfaceFinishingWithColorCol,
      align: "center",
    },
    {
      title: "Price from supplier",
      headerName: "Price from supplier",
      field: "priceFromSupplier",
      align: "center",
      renderCell: (params) => "S$" + params.row?.priceBidded,
    },
    {
      title: "Delivery Fee",
      headerName: "Delivery Fee",
      field: "deliveryFee",
      align: "center",
      renderCell: (params) => "S$" + params.row?.deliveryFee,
    },
    {
      title: "Total Price shown to customer (excl. Factorem GST)",
      headerName: "Total Price shown to customer (excl. Factorem GST)",
      field: "totalPriceShownToCustomer",
      align: "center",
      renderCell: function (params) {
        const rowData = params.row;
        return rowData?.totalPrice ? (
          <div
            style={{
              color: colors.blue050,
            }}
          >
            S${(rowData?.totalPrice)}
          </div>
        ) : (
          <div></div>
        );
      }
    },
    {
      title: "Total Price (incl. GST)",
      headerName: "Total Price (incl. GST)",
      field: "totalPrice",
      align: "center",
      exportData: rowData => rowData.totalPrice
        ? `S$${(rowData.totalPrice * (1 + DEFAULT_FACTOREM_GST)).toFixed(2)}`
        : '',
      renderCell: function (params) {
        const rowData = params.row;
        return rowData?.totalPrice ? (
          <div>
            S${(rowData?.totalPrice * (1 + DEFAULT_FACTOREM_GST)).toFixed(2)}
          </div>
        ) : (
          <div></div>
        );
      }
    },
    {
      title: "Supplier name",
      headerName: "Supplier name",
      field: "supplierName",
      align: "center",
      renderCell: (params) => {
        return (
          <DataGridWrapTextCell text={params.row.supplierName} />
        )
      },
      minWidth: 200,
    }
  ];

  const getTableData = () => {
    adminGetAllQuotations(tableQueryParams)
      .then(data => {
        setTableData(data.rows);
        updateTableQueryParams({
          totalCount: data.totalCount,
          loading: false,
        });
      })
  }

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    if (!tableQueryParams.search) {
      getTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [tableQueryParams.search, tableQueryParams.page, tableQueryParams.pageSize, tableQueryParams.quoteType]);

  const handleRowClick = (params) => {
    setQuotationID(params.row?.quotationID);
    setOpen(true);
  };

  const handleDownloadCsv = async () => {
    try {
      // Call the API to generate the CSV and get the download URL
      const response = await adminExportQuotationsAsCsv(tableQueryParams);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      // Download the CSV file using the download URL
      const link = document.createElement('a');
      link.href = downloadUrl;
      const fileName = `All Verify Quotes ${generateCurrentCustomDateTimeString()}.csv`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const getCustomToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
        }}
      >
        <div></div> {/* empty div to align the buttons to the right */}
        <CustomToolbar
          buttons={[
            <SearchBar
              key="search"
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key="export-csv"
              handleClick={handleDownloadCsv}
            />,
          ]}
        />
      </div>
    );
  }

  const renderYes = () => {
    return <span style={{ color: 'green' }}>Yes</span>;
  }

  const renderNo = () => {
    return <span style={{ color: 'orange' }}>No</span>;
  }

  const renderPpeQuoteBadgeTooltip = (numOfPpeQuotes) => {
    const supplierText = numOfPpeQuotes > 1 ? 'Suppliers' : 'Supplier';
    return (
      <div
        className={classes.tooltipBadgeContainer}
      >
        <Tooltip
          title={`${numOfPpeQuotes} ${supplierText} quoted`}
          arrow
          placement='top'
        >
          <Badge badgeContent={numOfPpeQuotes} color="error">
            {renderYes()}
          </Badge>
        </Tooltip>
      </div>
    );
  }

  const handleFilterChange = (event) => {
    const { value: quoteType } = event.target;

    updateTableQueryParams({
      quoteType
    });
  }

  return role === ROLE_TYPES.BUYER || role === ROLE_TYPES.SUPPLIER ? (
    <Redirect
      to={{
        pathname: "/",
        state: { from: props.location }
      }}
    />
  ) : (
    <div className={classes.body}>
      <h1>Verify Quotes</h1>
      <div style={{ marginBottom: '1rem' }}>
        <FilterBar
          defaultValue={QUOTATION_VERIFY_STATUS_FILTER_ARRAY[0].value}
          optionList={QUOTATION_VERIFY_STATUS_FILTER_ARRAY}
          onFilterChange={handleFilterChange}
        />
      </div>
      <DataGrid
        autoHeight
        paginationMode="server"
        rows={tableData}
        columns={columns.map(col => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.quotationID}
        onRowClick={handleRowClick}
        rowHeight={80}
        headerHeight={150}
        components={{
          Toolbar: getCustomToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
            />
          ),
        }}
        pageSize={tableQueryParams.pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
        page={tableQueryParams.page}
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        loading={tableQueryParams.loading}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
      />
      {open && (
        <EditQuoteDialog
          open={open}
          setOpen={setOpen}
          quotationID={quotationID}
          refreshDataFunc={getTableData}
          isVerifyQuote={true}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role
  };
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(VerifyQuotesDataGridPagination));
