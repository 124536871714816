import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from "@material-ui/core/styles";

import StatusButton from './buttons/StatusButton';
import StatusButtonV2 from './buttons/StatusButtonV2';

import ProjectItemsContext from '../context/ProjectItemsContext';

import useFeatureFlagHook from '../hooks/useFeatureFlagHook';
import useManageProjectRevampLegacyCustomerHook from '../hooks/useManageProjectRevampLegacyCustomerHook';

import { getUserIDSelector } from '../selectors/userSelector';

import { colors } from "../palette";


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  statusText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center",
    fontSize: "20px",
    flexBasis: "20%",
  },
  media: {
    border: colors.darkBlueBorder,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  mediaBackground: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 140,
    height: 140,
  },
  text: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  root: {
    padding: 0,
  },
}));

function ItemStatusParent({ item, onClickBtn, hasQuoteSelected = false }) {
  const classes = useStyles();

  const { isAdminView = false } = useContext(ProjectItemsContext);

  const userID = useSelector(getUserIDSelector);

  const { isManageProjectsRevampEnabled } = useFeatureFlagHook();

  const { isLegacyCustomer } = useManageProjectRevampLegacyCustomerHook(userID);

  const ButtonLayout = isManageProjectsRevampEnabled || !isLegacyCustomer
    ? StatusButtonV2
    : StatusButton;

  return (
    <div className={classes.root}>
      <ButtonLayout
        item={item}
        onClickBtn={onClickBtn}
        hasQuoteSelected={hasQuoteSelected}
        allowUnverifiedQuotes={isAdminView}
      />
    </div>
  );
}

export default ItemStatusParent;
