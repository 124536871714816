export const IMAGE_2D_GENERATE_SUPPORT_FILE_TYPES = [
  'step',
  'stp',
  'iges',
  'x_t',
  'x_b',
  'stl',
  'dxf',
  'pdf',
];
