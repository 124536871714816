import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { FormControlLabel, Typography } from '@material-ui/core';

import FtrBlueCheckbox from '../FtrBlueCheckbox';

import { colors } from '../../../palette';

const useStyles = makeStyles(() => ({
  subtitle: {
    fontWeight: 600,
    fontSize: '16px',
    color: '#565656',
  },
}));

export function FtrCheckboxHeader(props) {
  const classes = useStyles();

  const {
    checked,
    onChange,
    title = 'Specifications',
    disabled = false,
  } = props;

  return (
    <FormControlLabel
      key='select-all-fields'
      control={
        <FtrBlueCheckbox
          name='select-all-fields'
          onChange={onChange}
          checked={checked}
          style={{ padding: '0px 10px' }}
          disabled={disabled}
        />
      }
      style={{
        backgroundColor: colors.neutral030,
        width: '100%',
        height: '68px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        margin: '0 0 0 0',
      }}
      label={<Typography className={classes.subtitle}>{title}</Typography>}
    />
  );
}
