const SUPPLIER_FEEDBACK = {
  TAKE_ORDER_WISH_PRICE_TO_REVISE: 'I will take on this order but wish to see price revised next time.',
  TAKE_ORDER_IF_PRICE_IS_REVISED: 'I will take on this order if the price is revised for this order.',
  NO_ACTIONS: 'I just wish to leave feedback. No further action required.',
}

const LEAD_TIME_SUPPLIER_FEEDBACK = {
  TAKE_ORDER_WISH_TO_REVISE: 'I will take on this order but wish to see delivery date revised next time.',
  TAKE_ORDER_IF_REVISED: 'I will take on this order if the delivery date is revised for this order.',
  NO_ACTIONS: 'I just wish to leave feedback. No further action required.',
}

const ADMIN_FEEDBACK = {
  REVISED_PRICE: 'Revised price for this order',
}

module.exports = {
  SUPPLIER_FEEDBACK,
  ADMIN_FEEDBACK,
  LEAD_TIME_SUPPLIER_FEEDBACK,
}
