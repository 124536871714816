import React, { useEffect, useReducer, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import AddressAutocompleteV2 from '../../AddressAutocompleteV2';
import ContactNumberField from '../../fields/ContactNumberField';
import FtrCheckbox from '../../ftr-components/FtrCheckbox';
import { FlexColumn, FlexRow, FlexRowEnd } from '../../layouts/FlexLayouts';
import { FtrBoldText, FtrButton, FtrH5 } from '../../ftr-components';
import { FtrTextField } from '../../ftr-components/FtrField';

import { createDeliveryInfo, updateDeliveryInfo } from '../../../apis/deliveryInfoApi';

import { validateContact } from '../../../utils/validators/contactValidator';

import { isEmptyValue } from '../../../utils/commonUtils';
import { getCountryNameByPhoneCode } from '../../../utils/contactUtils';

import { notifyError, notifySuccess } from '../../../services/notificationService';

import { getUserCountrySelector } from '../../../selectors/userSelector';

import { COUNTRY_LIST } from '../../../constants/countryConstants';

import { colors } from "../../../palette";


// ----------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      borderRadius: '1rem',
      width: 'auto',
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    cursor: 'pointer',
  },
}));

const initialDeliveryInfo = {
  deliveryInfoID: '',
  address: '',
  postalCode: '',
  unitNo: '',
  contactName: '',
  contactNumber: '',
  country: '',
  showDeletePopup: false,
  switchDefaultShipping: false,
  switchDefaultBilling: false,
};

function AddEditCustomerAddressPopup(props) {
  const classes = useStyles();

  const {
    open,
    type = 'shipping',
    userID,
    mode = 'add',
    editAddressObj = {},
    handleClose = () => { },
    onSuccess = () => { },
    customUpdateDeliveryInfo,
    customCreateDeliveryInfo,
    isLoading = false,
    showDefaultAddressCheckbox = true,
  } = props;

  const queryClient = useQueryClient();

  const title = type === 'shipping' ? 'Shipping Address' : 'Billing Address';

  const [
    formErrorState,
    updateFormErrorState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return {}
      }
      return { ...prev, ...next };
    },
    {
    },
  );

  const [
    deliveryInfoState,
    updateDeliveryInfoState,
  ] = useReducer(
    (prev, next) => {
      updateFormErrorState({});
      return { ...prev, ...next };
    },
    initialDeliveryInfo,
  );

  const userCountry = useSelector(getUserCountrySelector);

  const [loading, setLoading] = useState(false);

  // -------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (isEmptyValue(userCountry) || !isEmptyValue(deliveryInfoState.contactNumber)) {
      return;
    }

    const phoneCode = COUNTRY_LIST[userCountry]?.phone;
    updateDeliveryInfoState({
      contactNumber: `${phoneCode} `,
      country: userCountry,
    });
  }, [userCountry]);

  useEffect(() => {
    if (editAddressObj && mode === 'edit') {
      let onlyContactNumber, phoneCountry;
      if (editAddressObj.contactNumber?.split(' ').length > 1) {
        onlyContactNumber = editAddressObj.contactNumber.split(' ')[1];
        phoneCountry = getCountryNameByPhoneCode(editAddressObj.contactNumber.split(' ')[0]);
      } else {
        onlyContactNumber = editAddressObj.contactNumber;
        phoneCountry = editAddressObj.country;
        const phoneCode = COUNTRY_LIST[editAddressObj.country]?.phone;
        if (phoneCode) {
          editAddressObj.contactNumber = `${phoneCode} ${onlyContactNumber}`;
        }
      }
      updateDeliveryInfoState({
        ...editAddressObj,
        onlyContactNumber,
        phoneCountry,
        switchDefaultShipping: Boolean(editAddressObj.defaultShipping),
        switchDefaultBilling: Boolean(editAddressObj.defaultBilling),
      });
    }
  }, [editAddressObj]);

  const validateForm = () => {
    if (!deliveryInfoState?.contactName) {
      updateFormErrorState({ contactName: 'Required field' });
      return false;
    }
    if (!deliveryInfoState?.onlyContactNumber) {
      updateFormErrorState({ contactNumber: 'Required field' });
      return false;
    }
    const isValidContact = validateContact(deliveryInfoState.onlyContactNumber, deliveryInfoState.phoneCountry);
    if (!isValidContact) {
      updateFormErrorState({ contactNumber: 'Invalid contact number' });
      return false;
    }
    if (!deliveryInfoState?.address) {
      updateFormErrorState({ address: 'Required field' });
      return false;
    }
    return true;
  }

  const handleCreateAddress = () => {
    const body = {
      userID,
      contactName: deliveryInfoState?.contactName,
      contactNumber: deliveryInfoState?.contactNumber,
      switchDefaultShipping: deliveryInfoState?.switchDefaultShipping,
      switchDefaultBilling: deliveryInfoState?.switchDefaultBilling,
      country: deliveryInfoState?.country,
      address: deliveryInfoState?.address,
      postalCode: deliveryInfoState?.postalCode,
      unitNo: deliveryInfoState?.unitNo,
    };

    if (typeof customCreateDeliveryInfo === 'function') {
      customCreateDeliveryInfo(body);
      return;
    }

    setLoading(true);

    createDeliveryInfo(body)
      .then((data) => {
        notifySuccess('Added new address successfully');
        updateDeliveryInfoState(initialDeliveryInfo);
        queryClient.invalidateQueries('getUserAddresses');
        queryClient.invalidateQueries('getCustomerAddresses');
        onSuccess({
          result: { ...body, deliveryInfoID: data?.deliveryInfoID },
          addressType: type,
        });
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        notifyError('Failed created new address');
        setLoading(false);
      });
  };

  const handleUpdateAddress = () => {
    const body = {
      userID,
      contactName: deliveryInfoState?.contactName,
      contactNumber: deliveryInfoState?.contactNumber,
      country: deliveryInfoState?.country,
      address: deliveryInfoState?.address,
      postalCode: deliveryInfoState?.postalCode,
      unitNo: deliveryInfoState?.unitNo,
    };

    if (type === 'billing') {
      body.defaultBilling = deliveryInfoState?.switchDefaultBilling;
    } else if (type === 'shipping') {
      body.defaultShipping = deliveryInfoState?.switchDefaultShipping;
    }

    if (typeof customUpdateDeliveryInfo === 'function') {
      customUpdateDeliveryInfo(body, type);
      return;
    }

    setLoading(true);

    updateDeliveryInfo(deliveryInfoState?.deliveryInfoID, body)
      .then(() => {
        notifySuccess('Updated address successfully');
        queryClient.invalidateQueries('getUserAddresses');
        onSuccess({
          result: {
            ...body,
            deliveryInfoID: deliveryInfoState?.deliveryInfoID,
            switchDefaultShipping: deliveryInfoState?.switchDefaultShipping,
            switchDefaultBilling: deliveryInfoState?.switchDefaultBilling,
          },
          addressType: type,
        });
        setLoading(false);
        handleClose();
      })
      .catch(() => {
        notifyError('Failed updated new address');
      });
  }

  const handleSaveButtonClick = () => {
    if (!validateForm()) {
      return;
    }

    if (mode === 'add') {
      handleCreateAddress();
    } else {
      handleUpdateAddress();
    }
  }

  const renderSetDefaultAddressCheckbox = () => {
    if (mode === 'edit'
      && ((type === 'shipping' && Boolean(editAddressObj?.defaultShipping) === true)
        || (type === 'billing' && Boolean(editAddressObj?.defaultBilling) === true))
    ) {
      return null;
    }

    return (
      <div style={{ marginTop: '1rem' }}>
        <FtrCheckbox
          label={type === 'shipping'
            ? 'Set as default shipping address'
            : 'Set as default billing address'
          }
          checked={type === 'shipping'
            ? deliveryInfoState.switchDefaultShipping
            : deliveryInfoState.switchDefaultBilling
          }
          onChange={(e) => {
            const param = type === 'shipping'
              ? {
                switchDefaultShipping: e.target.checked,
              }
              : {
                switchDefaultBilling: e.target.checked,
              }
            updateDeliveryInfoState(param);
          }}
          name='switchDefaultShipping'
        />
      </div>
    )
  }

  const renderBody = () => {
    return (
      <FlexColumn style={{ gap: '1rem', marginTop: '1rem' }}>
        <FlexRow style={{ gap: '1rem', alignItems: 'start' }}>
          <FtrTextField
            title='Full Name'
            variant='outlined'
            required
            onChange={(evt) => {
              updateDeliveryInfoState({ contactName: evt.target.value });
            }}
            value={deliveryInfoState?.contactName}
            error={formErrorState?.contactName}
          />
          <FlexColumn style={{ gap: '0.3rem', flex: '1 1 auto', width: '100%' }}>
            <FtrBoldText
              style={{
                color: colors.neutral070,
              }}
            >
              Contact Number
            </FtrBoldText>
            <ContactNumberField
              initialValue={deliveryInfoState?.contactNumber}
              onChange={({ fullContact, contact, country }) => {
                updateDeliveryInfoState({
                  contactNumber: fullContact,
                  onlyContactNumber: contact,
                  phoneCountry: country,
                });
              }}
              error={formErrorState?.contactNumber}
            />
          </FlexColumn>
        </FlexRow>
        <AddressAutocompleteV2
          id='add-customer-address'
          required
          showCountry
          styleChild={{ marginTop: '0' }}
          size='small'
          showSubFieldTitle
          initialValue={{
            postalCode: deliveryInfoState?.postalCode,
            address: deliveryInfoState?.address,
            unitNo: deliveryInfoState?.unitNo,
            country: deliveryInfoState?.country,
          }}
          onChange={({ postalCode, address, unitNo, country }) =>
            updateDeliveryInfoState({
              postalCode,
              address,
              unitNo,
              country,
            })
          }
          error={formErrorState?.address}
        />
        {showDefaultAddressCheckbox && renderSetDefaultAddressCheckbox()}
      </FlexColumn>
    );
  }

  const renderActionButtons = () => {
    return (
      <FlexRowEnd
        style={{
          width: '100%',
        }}
      >
        <Box className={classes.space}></Box>
        <FtrButton
          style={{
            marginTop: '9px',
          }}
          variant='contained'
          color='blue'
          onClick={handleSaveButtonClick}
          loading={loading || isLoading}
        >
          Save
        </FtrButton>
      </FlexRowEnd>
    );
  }

  return (
    <Dialog
      maxWidth='md'
      width='700px'
      open={open}
      onClose={handleClose}
      aria-labelledby='share-quote-email-dialog-title'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.title}>
        <FtrH5>
          {title}
        </FtrH5>
      </DialogTitle>
      <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      <DialogContent
        style={{
          padding: '0 30px',
          paddingBottom: '2rem',
          textAlign: 'left',
        }}
      >
        {renderBody()}
      </DialogContent>
      <DialogActions>
        {renderActionButtons()}
      </DialogActions>
    </Dialog>
  );
}

export default AddEditCustomerAddressPopup;
