import React, { useReducer } from 'react';

import PriceFeedbackPopup from '../components/popups/PriceFeedbackPopup';



// ------------------------------------------------------------------------------

function withPriceFeedbackPopupHOC(WrappedComponent) {
  return function PriceFeedbackPopupHOC(props) {
    const [localState,
      updateLocalState] = useReducer(
        (prev, next) => {
          return { ...prev, ...next };
        },
        {
          open: false,
          item: null,
        }
      );

    return (
      <>
        <WrappedComponent
          updatePriceFeedbackState={updateLocalState}
          {...props}
        />
        {localState.open &&
          <PriceFeedbackPopup
            dialog={localState.open}
            item={localState.item}
            handleClose={() => updateLocalState({ open: false })}
          />
        }
      </>
    );
  };
}

export default withPriceFeedbackPopupHOC;
