export const UPDATE_PARTS_LIBRARY_ALL_DATA = 'UPDATE_PARTS_LIBRARY_ALL_DATA';
export const UPDATE_PARTS_LIBRARY_SINGLE_DATA = 'UPDATE_PARTS_LIBRARY_SINGLE_DATA';
export const UPDATE_PARTS_LIBRARY_ITEM_DATA = 'UPDATE_PARTS_LIBRARY_ITEM_DATA';
export const DELETE_PARTS_LIBRARY_ITEM_DATA = 'DELETE_PARTS_LIBRARY_ITEM_DATA';
export const ADD_NEW_PARTS_LIBRARY_ITEM_DATA = 'ADD_NEW_PARTS_LIBRARY_ITEM_DATA';
export const VIEW_PARTS_LIBRARY_ITEM_DATA = 'VIEW_PARTS_LIBRARY_ITEM_DATA';

export const updatePartsLibraryAllData = (data) => {
  return {
    type: UPDATE_PARTS_LIBRARY_ALL_DATA,
    payload: data,
  };
};

export const updatePartsLibrarySingleData = (projectLibraryID, data) => {
  return {
    type: UPDATE_PARTS_LIBRARY_SINGLE_DATA,
    payload: { projectLibraryID, data },
  };
};

export const viewPartsLibraryItemData = (item) => {
  return {
    type: VIEW_PARTS_LIBRARY_ITEM_DATA,
    payload: { item },
  };
};

export const updatePartsLibraryItemData = (projectLibraryID, { itemLibraryID, id, version }, data) => {
  return {
    type: UPDATE_PARTS_LIBRARY_ITEM_DATA,
    payload: { projectLibraryID, itemLibraryID, id, version, data },
  };
};

export const deletePartsLibraryItemData = (projectLibraryID, { itemLibraryIDs, ids }) => {
  return {
    type: DELETE_PARTS_LIBRARY_ITEM_DATA,
    payload: { projectLibraryID, itemLibraryIDs, ids },
  };
};

export const addNewPartsLibraryItemData = (projectLibraryID, data) => {
  return {
    type: ADD_NEW_PARTS_LIBRARY_ITEM_DATA,
    payload: { projectLibraryID, data },
  };
};
