import {
  fetchWithErrorHandling,
  getAuthorizedHeader,
} from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';


// -------------------------------------------------------------------------------------------------

export const verifyToken = async ({ token }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/jwt/verify-token`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ token }),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { valid } = await response.json();
    return valid;
  }).catch(() => false);
}

export const generateTokenForGuest = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/jwt/temporary-token/create`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getTokenForGuest = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/jwt/temporary-token/get`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
