import { useState } from 'react';

import * as fileUtils from '../utils/fileUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { useSelector } from 'react-redux';
import { getUserRoleSelector } from '../selectors/userSelector';
import { isAdminOrHigherRole } from '../utils/roleUtils';


// ----------------------------------------------------------------------------------------------

const useDownloadAllFiles = (urlList, folderName) => {

  const userRole = useSelector(getUserRoleSelector);

  const [loading, setLoading] = useState(false);

  const handleDownloadAll = async () => {
    if (isEmptyValue(urlList)) {
      return;
    }

    const downloadAllS3Files = isAdminOrHigherRole(userRole)
      ? fileUtils.downloadAllS3Files
      : fileUtils.downloadAllS3FilesWithWaterMark;

    const downloadAllS3FilesSeparatedBySubFolders = isAdminOrHigherRole(userRole)
      ? fileUtils.downloadAllS3FilesSeparatedBySubFolders
      : fileUtils.downloadAllS3FilesSeparatedBySubFoldersWithWaterMark;

    setLoading(true);
    if (typeof urlList[0] === 'string') {
      await downloadAllS3Files(urlList, folderName);
    } else {
      await downloadAllS3FilesSeparatedBySubFolders(urlList, folderName);
    }
    setLoading(false);
  };

  return {
    loading,
    handleDownloadAll,
  };
};

export default useDownloadAllFiles;
