import { getAuthorizedHeader, witchCacheResponse } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

/**
 * Convert .STEP, STP, .IGES, .IGS Format to .STL Format
 * @param {*} body
 * @param {*} options
 * @returns
 */
export const convertToStl = async (body, options = {}) => {
  const { timeout = 30000 } = options;
  const requestOptions = {
    method: 'POST',
    headers: getAuthorizedHeader(),
    cache: 'no-cache',
    body: JSON.stringify(body),
  };
  const url = `${BACKEND_SERVICE_URL}/images/convert-to-stl`;
  const controller = new window.AbortController();
  const timeoutID = setTimeout(() => {
    controller.abort();
    throw new Error('Timeout');
  }, timeout);
  const response = await fetch(url, {
    ...requestOptions,
    signal: controller.signal,
  });
  clearTimeout(timeoutID);
  const resp = await response.json();
  if (!response.ok) {
    throw new Error(resp?.error);
  }
  return resp;
};

export const convertFileToStl = witchCacheResponse(
  convertToStl,
  30 * 60 // 30 minutes in second
);
