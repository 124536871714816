export const getCreateUserSelector = (state) => {
  return {
    userEmail: state.createUser?.email,
    userName: state.createUser?.name,
    resendEmailSuccess: state.createUser?.resendEmailSuccess,
    isInvalidCode: state.createUser?.verifyEmailFailure,
    isVerifiedSuccess: state.createUser?.verifyEmailSuccess,
    password: state.createUser?.password,
    signupSuccess: state.createUser?.signupSuccess,
    verifyEmailSuccess: state.createUser?.verifyEmailSuccess,
    questionAnswered: state.createUser?.questionAnswered,
    verificationDate: state.createUser?.verificationDate,
  }
};
