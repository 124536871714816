import React, { useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { isEmpty, round, get } from 'lodash';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import { PPE_TECHNOLOGY_OPTIONS  } from '../../constants/PPEConstants'
import { QUOTE_STATUS_OPTIONS } from '../../constants';
import { getSimilarParts } from '../../apis/ppeApi';
import { getItemDetailsApi } from '../../apis/itemApi';
import SingleImage from '../../components/images/SingleImage';
import FilesUploadButton from '../../components/FilesUploadButton';
import IOSSwitch from '../../components/IOSSwitch';
import { FlexColumn } from '../../components/layouts/FlexLayouts';

import { truncateDecimals } from '../../utils/numberUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { getTotalWatchingJobsByUserID } from '../../utils/itemUtils';

import { notifyError } from '../../services/notificationService';
import { getCadPartS3Key, uploadFileToS3 } from '../../services/s3Service';
import { convert2DImage } from '../../services/convert2DImageService';
import { WatchingJob } from '../../components/WatchingJob';
import WatchingJobPopup from '../../components/popups/WatchingJobPopup';
import NotInterestedJobPopup from '../../components/popups/NotInterestedJobPopup';
import { NotInterestedJob } from '../../components/NotInterestedJob';

const useStyles = makeStyles(() => ({
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
}));

const OPTIONS = {
  PART_ID: 'Part ID',
  IMAGE_URL: 'Image Url',
  UPLOAD_FILE: 'Upload File',
};

function GetSimilarParts() {
  const classes = useStyles();

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      option: OPTIONS.PART_ID,
      imageUrl: undefined,
      partID: undefined,
      threshold: 0.93,
      topK: 10,
      processing: false,
      similarParts: [],
      uploadedFile: undefined,
      isFileUploading: false,
      processed: undefined,
      quoteStatusList: ["All"],
      technologyList: ["All"],
      useDimensionSimilarity: false
    }
  );
  const [selectedPart, setSelectedPart] = useState({})
  const [showPopupWatchingJob, setShowPopupWatchingJob] = useState(false)
  const [showNotInterestedPopup, setShowNotInterestedPopup] = useState(false)

  // Convert Option with Uppercase on the first char
  const ALL_QUOTE_STATUS_OPTIONS = QUOTE_STATUS_OPTIONS.map(option => option.charAt(0).toUpperCase() + option.slice(1))

  const handlePartIdChange = async (event) => {
    const partId = event.target.value;
    updateLocalState({ partID: partId });

    if (isEmpty(partId)) {
      updateLocalState({ imageUrl: undefined });
    }

    else {
      getItemDetailsApi(partId)
        .then((itemDetails) => {
            updateLocalState({ imageUrl: itemDetails.imageFile || itemDetails.twoDImageUrl });
        })
        .catch(() => {
          updateLocalState({ imageUrl: undefined });
        });
    }
  };

  const handleGetSimilarParts = async () => {
    const params = {
      image_url: localState.imageUrl,
      threshold: localState.threshold,
      top_k: localState.topK,
      technology_list: localState.technologyList,
      status_list: localState.quoteStatusList
    };

    if ((localState.option === OPTIONS.PART_ID) && localState.useDimensionSimilarity) {
      params.partID = localState.partID
      params.dimension_similarity_switch = true
    } 
    if ((localState.option === OPTIONS.UPLOAD_FILE) && localState.useDimensionSimilarity) {
      params.cadUrl = localState.cadUrl
      params.dimension_similarity_switch = true
    }
    updateLocalState({
      processing: true,
      processed: undefined,
    });
    getSimilarParts(params)
      .then((response) => {
        updateLocalState({
          similarParts: response.data.similar_parts || [],
          processed: 'done',
        });
      })
      .finally(() => updateLocalState({ processing: false }));
  };

  const handleUpload3DFile = (files) => {
    const file = files[0];
    updateLocalState({
      uploadedFile: file,
      isFileUploading: true,
      imageUrl: undefined,
    });
    uploadFileToS3(file, getCadPartS3Key(file))
      .then((data) => {
        const s3ObjectUrl = data.Location.split(' ').join('%20');
        const parameters = {
          file_url: s3ObjectUrl,
          precision: 0.5,
          img_width: 1920,
          img_height: 1920,
          view: 'wireframe',
          angle: 'isometric',
          colorscheme: 'Metal-gray',
        };
        updateLocalState({cadUrl: s3ObjectUrl});
        convert2DImage(parameters).then((data) => {
          const twoDImageUrl = data['s3_file_url'];
          updateLocalState({ imageUrl: twoDImageUrl });
        });
      })
      .catch(() => {
        notifyError(`Can't generate 2D image.`);
      })
      .finally(() => {
        updateLocalState({ isFileUploading: false });
      });
  };

  const renderSimilarParts = () => {
    if (localState.processed === 'done' && isEmpty(localState.similarParts)) {
      return <Typography>No similar parts found</Typography>;
    }
    return localState.similarParts.map((part) => {
      const isWatching = part.watching_job && part.watching_job.length > 0
      const notInterestedList = part.not_interested_job || [];
      const totalNotInterestedSuppliers = notInterestedList.length;
      return (
        <div
          key={part.part_id}
          style={{
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{display: 'flex', alignItems: 'center', columnGap: '0.5rem'}}>
            <div>
              <Typography variant="body2">
                PartID: {part.part_id}
              </Typography>
              {part?.image_similarity_score && (
              <Typography variant="body2">
                Image similarity: {truncateDecimals(Number(part.image_similarity_score), 4)}
              </Typography>
              )}
              {part?.dimension_similarity_score && (
              <Typography variant="body2">
                Dimension similarity: {truncateDecimals(Number(part.dimension_similarity_score), 4)}
              </Typography>
              )}
              <Typography variant="body2">
                Score: {truncateDecimals(Number(part.similarity_score), 4)}
              </Typography>
            </div>
            <WatchingJob
              forAdmin
              totalWatching={getTotalWatchingJobsByUserID(part.watching_job)}
              isWatching={isWatching}
              onClick={() => {
                if (isWatching) {
                  setShowPopupWatchingJob(true);
                  setSelectedPart(part);
                }
              }}
            />
            <NotInterestedJob
              forAdmin
              totalSuppliers={totalNotInterestedSuppliers}
              onClick={() => {
                if (totalNotInterestedSuppliers > 0) {
                  setShowNotInterestedPopup(true);
                  setSelectedPart(part);
                }
              }}
            />
          </div>
          <SingleImage url={part.image_url} width={200} height={200} />
        </div>
      );
    });
  };

  return (
    <Container>
      <FormControl component='fieldset' style={{ marginTop: '2rem' }}>
        <FormLabel id='shipping-mode'>Select Option</FormLabel>
        <RadioGroup
          row
          value={localState.option}
          onChange={(event) => {
            updateLocalState({
              option: event.target.value,
            });
          }}
        >
          {Object.entries(OPTIONS).map(([key, value]) => {
            return (
              <FormControlLabel
                key={key}
                value={value}
                control={<Radio color='primary' />}
                label={value}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          {localState.option === OPTIONS.PART_ID && (
            <TextField
            required
            error={isEmpty(localState.imageUrl) && !isEmpty(localState.partID)}
            helperText={isEmpty(localState.imageUrl) && !isEmpty(localState.partID) ?  "Invalid Part ID or image not found" : "" }
            label='Part ID'
            id='part-id'
            variant='outlined'
            value={localState.partID}
            onChange={handlePartIdChange}
            margin='dense'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          )}
          {localState.option === OPTIONS.IMAGE_URL && (
            <TextField
              required
              label='Url'
              id='image-url'
              variant='outlined'
              value={localState.imageUrl}
              onChange={(event) =>
                updateLocalState({ imageUrl: event.target.value })
              }
              margin='dense'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {localState.option === OPTIONS.UPLOAD_FILE && (
            <div
              style={{
                marginBottom: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FilesUploadButton
                buttonText='Upload 3D file'
                handleUploadFiles={handleUpload3DFile}
                multiple={false}
              />
              <Box style={{ width: '1rem' }} />
              {localState.uploadedFile && (
                <Typography>{localState.uploadedFile.name}</Typography>
              )}
              &nbsp;
              {localState.isFileUploading === true && (
                <CircularProgress
                  className={classes.circularProgress}
                  size={20}
                />
              )}
            </div>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                type='number'
                label='Threshold'
                id='threshold'
                variant='outlined'
                value={localState.threshold}
                onChange={(event) => {
                  const value = event.target.value > 1 ? 1 : event.target.value;
                  updateLocalState({ threshold: value });
                }}
                margin='dense'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: 0.01,
                    max: 1,
                    step: 0.1,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                type='number'
                label='Top K'
                id='topK'
                variant='outlined'
                value={localState.topK}
                onChange={(event) => {
                  let value = event.target.value;
                  updateLocalState({ topK: value });
                }}
                onBlur={(event) => {
                  let value = event.target.value;
                  value = round(value, 0);
                  if (value > 100) {
                    value = 100;
                  }
                  if (value < 1 && !isEmptyValue(value)) {
                    value = 1;
                  }
                  updateLocalState({ topK: value });
                }}
                margin='dense'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 100,
                    step: 1,
                  },
                }}
              />
            </Grid>
          </Grid>
          <FormControl fullWidth margin='dense'>
            <InputLabel>Technology</InputLabel>
            <Select
              multiple
              value={localState.technologyList}
              onChange={({ target }) => updateLocalState({ technologyList: target.value})}
              renderValue={(selected) => {
                return <span style={{ padding: '0 0.8rem'}}>{selected?.join(', ')}</span>
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    marginTop: '2rem',
                    maxHeight: '300px'
                  },
                },
              }}
            >
              {PPE_TECHNOLOGY_OPTIONS.map(option => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={localState.technologyList.includes(option)}
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin='dense'>
            <InputLabel>Quote Status</InputLabel>
            <Select
              multiple
              value={localState.quoteStatusList}
              onChange={({ target }) => updateLocalState({ quoteStatusList: target.value})}
              renderValue={(selected) => {
                return <span style={{ padding: '0 0.8rem'}}>{selected?.join(', ')}</span>
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    marginTop: '2rem',
                    maxHeight: '300px'
                  },
                },
              }}
            >
              {ALL_QUOTE_STATUS_OPTIONS.map(option => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={localState.quoteStatusList.includes(option)}
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ marginTop: '1rem' }}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={handleGetSimilarParts}
              disabled={
                isEmpty(localState.imageUrl)
                || isEmptyValue(localState.threshold)
                || isEmptyValue(localState.topK) 
                || isEmptyValue(localState.technologyList) 
                || isEmptyValue(localState.quoteStatusList) 
                || localState.processing === true
              }
            >
              {localState.processing === true && (
                <CircularProgress
                  className={classes.circularProgress}
                  size={20}
                />
              )}
              &nbsp;Get Similar Parts
            </Button>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FlexColumn>
            <SingleImage url={localState.imageUrl} width={150} height={150} />
            <div className={classes.row}>
              <Typography>
                Use Dimension similarity?
              </Typography>
              <IOSSwitch
                checked={localState.useDimensionSimilarity}
                onChange={() => {
                  updateLocalState({useDimensionSimilarity: !localState.useDimensionSimilarity}); 
                }}
                name="dimension-similarity-switch"
              />
            </div>
          </FlexColumn>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: '2rem',
          marginBottom: '1rem',
        }}
      >
        <Divider />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          flexWrap: 'wrap',
        }} >
        {renderSimilarParts()}
      </div>
      <WatchingJobPopup
        open={showPopupWatchingJob}
        onClose={() => setShowPopupWatchingJob(false)}
        title={`Watching Part #${selectedPart && selectedPart.part_id}`}
        data={get(selectedPart, 'watching_job', [])}
      />
      <NotInterestedJobPopup
        open={showNotInterestedPopup}
        onClose={() => setShowNotInterestedPopup(false)}
        title={`Not Interested Part #${selectedPart && selectedPart.part_id}`}
        itemID={selectedPart && selectedPart.part_id}
        notInterestedJobs={get(selectedPart, 'not_interested_job',[])}
        onSuccess={() => {
          handleGetSimilarParts()
          setShowNotInterestedPopup(false);
        }}
      />
    </Container>
  );
}

export default GetSimilarParts;
