import React, { useEffect, useState, useReducer } from "react";
import { useQuery } from "react-query";

import { makeStyles } from '@material-ui/core/styles/index';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import { Tooltip, IconButton } from '@material-ui/core'

import { colors } from "../../palette";
import CustomToolbar from "../../components/grid-data/CustomToolbar";
import { AddNewButton } from "../../components/grid-data/buttons/AddNewButton";
import SearchBar from "../../components/grid-data/buttons/SearchBar";

import {
  DeleteOutline,
  Edit,
} from "@material-ui/icons";

import { generateCurrentCustomDateTimeString } from "../../util";
import { createPromotionCode, deletePromotionCode, getAllPromotionCodes, updatePromotionCode } from "../../apis/stripeApi";
import { isEmpty } from "lodash";
import AddPromotionCodePopup from "../../components/popups/AddPromotionCodePopup";
import EditPromotionCodePopup from "../../components/popups/EditPromotionCodePopup";
import DeletePromotionCodePopup from "../../components/popups/DeletePromotionCodePopup";
import { notifyError, notifySuccess } from "../../services/notificationService";
import { isAdminOrHigherRole } from "../../utils/roleUtils";
import { getCsvExportColumns } from '../../utils/dataGridUtils';

const useStyles = makeStyles(() => ({
  body: {
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
  },
}));

function StripeDiscountDataGrid() {
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const { data: allPromotionCodes, refetch } = useQuery(
    ['allPromotionCodes'],
    () => getAllPromotionCodes(),
  );

  useEffect(() => {
    if (isEmpty(allPromotionCodes)) {
      return;
    }
    const _tableData = allPromotionCodes.map(pc => ({
      id: pc.id,
      codeName: pc.code,
      percentDiscount: pc.coupon.percent_off,
    }));
    setTableData(_tableData);
  }, [allPromotionCodes]);

  const columns = [
    {
      headerName: 'Actions',
      field: 'actions',
      minWidth: 130,
      hide: !isAdminOrHigherRole,
      export: false,
      align: 'center',
      renderCell: (params) => {
        if (!isAdminOrHigherRole) {
          return;
        }

        const { row } = params;
        
        return [
          <Tooltip title="Edit" key="edit">
            <IconButton
              aria-label="edit"
              onClick={() => handleEditClick(row)}
            >
              <Edit />
            </IconButton>
          </Tooltip>, 
          <Tooltip title="Delete" key="delete">
            <IconButton
              aria-label="delete"
              onClick={() => handleDeleteClick(row)}
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>,
        ];
      }
    },
    {
      headerName: "Code Name",
      field: "codeName",
      flex: 1,
      align: 'center',
    },
    {
      headerName: "Percent Discount",
      field: "percentDiscount",
      flex: 1,
      align: 'center',
    },
  ];

  // Promotion code CRUD, for pop ups
  const [showAddPromotionCodePopup, setShowAddPromotionCodePopup] = useState(false);
  const [showEditPromotionCodePopup, setShowEditPromotionCodePopup] = useState(false);
  const [showDeletePromotionCodePopup, setShowDeletePromotionCodePopup] = useState(false);

  const [currentId, setCurrentId] = useState("")
  const [existingCodeName, setExistingCodeName] = useState("");
  const [existingPercentOff, setExistingPercentOff] = useState(0);

  // Table search
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );
  const [filteredTableData, setFilterTableData] = useState([]);

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    if (!tableQueryParams.search) {
      setFilterTableData(tableData);
      updateTableQueryParams({
        loading: false,
      });
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const filteredRows = tableData.filter((row) => {
          return row.codeName.toLowerCase().includes(tableQueryParams.search.toLowerCase())
        });
        setFilterTableData(filteredRows);
        updateTableQueryParams({
          loading: false,
        });
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [tableQueryParams.search, tableQueryParams.page, tableQueryParams.pageSize]);

  // By default, table is unfiltered
  useEffect(() => {
    setFilterTableData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const handleCreatePromotionCode = (payload => {
    const body = {
      codeName: payload.codeName.toUpperCase(),
      percentDiscount: payload.percentOff,
    }
    createPromotionCode(body)
    .then(() => {
      notifySuccess('Promotion code has been created successfully');
      refetch();
      setShowAddPromotionCodePopup(false);
    });
  });

  const handleEditPromotionCode = (id, payload) => {
    const body = {
      codeName: payload.codeName.toUpperCase(),
      percentDiscount: payload.percentOff,
    }
    if (!(new RegExp(/^[a-z0-9]+$/i)).test(body.codeName)) {
      notifyError('Invalid pattern. Only letters and numbers are allowed.');
      return;
    }
    updatePromotionCode(id, body)
    .then(() => {
      notifySuccess('Promotion code has been edited successfully');
      refetch();
      setShowEditPromotionCodePopup(false);
    })
  }

  const handleDeletePromotionCode = (id) => {
    deletePromotionCode(id)
    .then(() => {
      notifySuccess('Promotion code has been deleted successfully');
      refetch();
      setShowDeletePromotionCodePopup(false);
    });
  }

  const handleEditClick = (row) => {
    setCurrentId(row.id);
    setExistingCodeName(row.codeName);
    setExistingPercentOff(row.percentDiscount);
    setShowEditPromotionCodePopup(true);
  }

  const handleDeleteClick = (row) => {
    setCurrentId(row.id);
    setExistingCodeName(row.codeName);
    setExistingPercentOff(row.percentDiscount);
    setShowDeletePromotionCodePopup(true);
  }

  const getCustomToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
        }}
      >
        <CustomToolbar
          buttons={[
            <AddNewButton
              key="add-new-promotion-code"
              handleClick={() => setShowAddPromotionCodePopup(true)}
              name="Add new promotion code"
            />,
          ]}
        />
        <CustomToolbar
          buttons={[
            <SearchBar
              key="search"
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <GridToolbarExport key="export" 
              csvOptions={{
                fileName: `All Project Order Ready ${generateCurrentCustomDateTimeString()}`,
                delimiter: ';',
                utf8WithBom: true,
                fields: getCsvExportColumns(columns),
              }}
            />,
          ]}
        />
      </div>
    );
  }
  
  return (
    <div className={classes.body}>
      <DataGrid 
        autoHeight
        columns={columns} 
        rows={isEmpty(filteredTableData) ? [] : filteredTableData} 
        editMode="row"
        rowsPerPageOptions={[10, 20, 50]}
        components={{
          Toolbar: getCustomToolbar,
        }}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
        loading={tableQueryParams.loading}
        localeText={{
          toolbarExport: 'Export CSV',
        }}
      />
      <AddPromotionCodePopup
        dialog={showAddPromotionCodePopup}
        handleClose={() => setShowAddPromotionCodePopup(false)}
        onOk={handleCreatePromotionCode}
      />
      <EditPromotionCodePopup
        dialog={showEditPromotionCodePopup}
        handleClose={() => setShowEditPromotionCodePopup(false)}
        currentId={currentId}
        existingCodeName={existingCodeName}
        existingPercentOff={existingPercentOff}
        onOk={handleEditPromotionCode}
      />
      <DeletePromotionCodePopup
        dialog={showDeletePromotionCodePopup}
        handleClose={() => setShowDeletePromotionCodePopup(false)}
        currentId={currentId}
        codeName={existingCodeName}
        percentOff={existingPercentOff}
        onOk={handleDeletePromotionCode}
      />
    </div>
  );
}

export default StripeDiscountDataGrid;
