import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

import SubDesc from '../SubDesc';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';

import {
  QC_COATING_FINISHING_CERTIFICATE,
  QC_MATERIAL_CERTIFICATE,
  QC_NO_QC_REPORT,
  QC_REPORT_ADD_ONS_OPTIONS,
  // QC_STANDARD_QC_REPORT,
  QC_OTHERS,
  QC_REPORT_MAIN_OPTIONS,
  QC_FORMAL_CMM_REPORT,
} from '../../constants/projectConstants';

import InfoIcon from '../icons/InfoIcon';

import { FEATURE_FLAG_CUSTOM_QC_INPUT } from '../../constants/featureFlagConstants';
import { getQcOptionPrices } from '../../apis/qcReportApi';
import { isEmptyValue } from '../../utils/commonUtils';
import Row from '../Row';
import { connect } from 'react-redux';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { convertPriceToCurrency, extractPriceFromCurrencyString } from '../../utils/currencyUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function QualityCertificationsPopup(props) {
  const classes = useStyles();

  const {
    dialog,
    value,
    onClose,
    onOk,
    itemList = [],
    title = 'Add Quality Check Report',
  } = props;

  const [mainQcReport, setMainQcReport] = useState('');
  const [addOnsQcReports, setAddOnsQcReports] = useState({
    [QC_MATERIAL_CERTIFICATE]: false,
    [QC_COATING_FINISHING_CERTIFICATE]: false,
  });
  const [isOthersQcReports, setIsOthersQcReports] = useState(false);
  const [othersQcReports, setOthersQcReports] = useState('');
  const [qcOptionPricesLoading, setQcOptionPricesLoading] = useState(false);
  const [qcOptionPrices, setQcOptionPrices] = useState({});

  useEffect(() => {
    if (isEmpty(itemList)) {
      return;
    }
    const body = itemList.reduce((acc, item) => {
      const totalPriceStr = convertPriceToCurrency({
        price: item.totalPrice,
        currency: CURRENCY_CODE.SGD,
        exchangeRate: 1,
      })
      if (!item.price) {
        acc.manualItems.push(item);
      } else {
        acc.ppeItems.push({
          complexity: item.complexity,
          price: extractPriceFromCurrencyString(totalPriceStr),
        });
      }
      return acc;
    }, {
      ppeItems: [],
      manualItems: [],
    });
    setQcOptionPricesLoading(true);
    getQcOptionPrices(body)
      .then(data => {
        setQcOptionPrices(data);
      })
      .finally(() => {
        setQcOptionPricesLoading(false);
      });
  }, [itemList]);

  useEffect(() => {
    if (isEmpty(value)) {
      setMainQcReport(QC_NO_QC_REPORT);
    } else {
      setMainQcReport(value.main);
      setAddOnsQcReports({
        [QC_MATERIAL_CERTIFICATE]: value.addOns.includes(QC_MATERIAL_CERTIFICATE),
        [QC_COATING_FINISHING_CERTIFICATE]: value.addOns.includes(QC_COATING_FINISHING_CERTIFICATE),
      });
      setIsOthersQcReports(!isEmpty(value.others));
      setOthersQcReports(isEmpty(value.others) ? '' : value.others.join(','));
    }
  }, [value]);

  const handleOkButtonClick = () => {
    const selectedValue = {
      main: mainQcReport,
      addOns: Object.entries(addOnsQcReports).reduce((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, []),
      others: othersQcReports.split(',').map(item => item.trim()).filter(item => !isEmpty(item)),
    }
    onOk(selectedValue);
  }

  const handleMainQcChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setMainQcReport(name);
      if (name === QC_NO_QC_REPORT) {
        setAddOnsQcReports({
          [QC_MATERIAL_CERTIFICATE]: false,
          [QC_COATING_FINISHING_CERTIFICATE]: false,
        });
        setIsOthersQcReports(false);
        setOthersQcReports('');
      }
    }
  }

  const handleChangeAddOns = (event) => {
    const { name, checked } = event.target;
    setAddOnsQcReports({
      ...addOnsQcReports,
      [name]: checked,
    })
  }

  const renderActionButtons = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '1rem',
        }}
      >
        <WhiteButton
          onBtnClick={onClose}
          btnContent='Cancel'
          size='small'
        />
        <Box className={classes.space}></Box>
        <BlueButton
          onBtnClick={handleOkButtonClick}
          btnContent='OK'
        />
      </div>
    );
  }

  const renderCustomQCInput = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            style={{
              marginRight: '0.5rem',
            }}
            control={(
              <Checkbox
                checked={isOthersQcReports}
                onChange={(event) => {
                  setIsOthersQcReports(event.target.checked);
                  setOthersQcReports('');
                }}
                name={QC_OTHERS}
                disabled={mainQcReport === QC_NO_QC_REPORT}
              />
            )}
            label={QC_OTHERS}
          />
        </div>
        <TextField
          variant="outlined"
          onChange={evt => setOthersQcReports(evt.target.value)}
          value={othersQcReports}
          placeholder="Separated by comma"
          margin="dense"
          fullWidth
          disabled={!isOthersQcReports}
        />
      </div>
    );
  }

  const renderQcOptionPrice = (itemName) => {
    if (qcOptionPricesLoading === true) {
      return (
        <CircularProgress
          style={{
            width: '1.5rem',
            height: '1.5rem',
          }}
          color='primary'
        />
      );
    }
    const priceText = isEmptyValue(qcOptionPrices[itemName])
      ? 'N.A'
      : qcOptionPrices[itemName];
    if (itemName === QC_FORMAL_CMM_REPORT) {
      return (
        <Typography
          style={{
            fontWeight: 'bold',
            color: 'brown',
          }}
        >
          RFQ
        </Typography>
      );
    }
    return (
      <Typography
        style={{
          fontWeight: 'bold',
          color: 'green',
        }}
      >
        {priceText}
      </Typography>
    );
  }

  const renderAddOnsOptions = () => {
    return (
      <div>
        {
          QC_REPORT_ADD_ONS_OPTIONS.map(item => {
            return (
              <Grid
                key={item.name}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                container
              >
                <Grid item xs={8}>
                  <Row>
                    <FormControlLabel
                      style={{
                        marginRight: '0.5rem',
                      }}
                      control={(
                        <Checkbox
                          checked={addOnsQcReports[item.name]}
                          onChange={handleChangeAddOns}
                          name={item.name}
                          disabled={mainQcReport === QC_NO_QC_REPORT}
                        />
                      )}
                      label={item.name}
                    />
                    {item.tooltip && <InfoIcon toolTipText={item.tooltip} />}
                  </Row>
                </Grid>
                <Grid item xs={4}>
                  {renderQcOptionPrice(item.name)}
                </Grid>
              </Grid>
            );
          })
        }
        {FEATURE_FLAG_CUSTOM_QC_INPUT === 'true' && renderCustomQCInput()}
      </div>
    );
  }

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={onClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <SubDesc content={title} />
      </DialogTitle>
      <div style={{ padding: '35px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <DialogContentText>
            Please select required certificates below. Certificates will be applied to across this project.
          </DialogContentText>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControl component="fieldset">
              <RadioGroup
                value={mainQcReport}
                onChange={handleMainQcChange}
              >
                {QC_REPORT_MAIN_OPTIONS.map(item => {
                  return (
                    <Grid
                      container
                      key={item.name}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Grid item xs={8}>
                        <Row>
                          <FormControlLabel
                            name={item.name}
                            value={item.name}
                            control={<Radio color='primary' />}
                            label={item.name}
                          />
                          {item.tooltip && <InfoIcon toolTipText={item.tooltip} />}
                        </Row>
                      </Grid>
                      <Grid item xs={4}>
                        {renderQcOptionPrice(item.name)}
                      </Grid>
                    </Grid>
                  );
                })}
              </RadioGroup>
            </FormControl>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '0.3rem',
                width: '100%',
              }}
            >
              <Typography
                style={{
                  width: 100,
                  color: 'gray',
                }}
                variant='body1'
              >
                Add Ons
              </Typography>
              <div style={{ width: '100%' }}>
                <Divider />
              </div>
            </div>
            {renderAddOnsOptions()}
          </div>
          {renderActionButtons()}
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(QualityCertificationsPopup);
