import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ManageProjectItems from '../../containers/Item/ManageProjectItems';
import ManageProjectItemsV2 from '../../containers/Item/ManageProjectItemsV2';
import ManageProjectItemsV3 from './ManageProjectItemsV3';

import useCustomerProjectInfo from './useCustomerProjectInfoHook';
import useManageProjectRevampLegacyCustomerHook from '../../hooks/useManageProjectRevampLegacyCustomerHook';

import { getCustomerSettingsSelector } from '../../selectors/customerSettingsSelector';

import { FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP } from '../../constants/featureFlagConstants';


// --------------------------------------------------------------------------------------------

function ManageProjectItemsFactory(props) {
  const { projectID } = useParams();

  const [
    {
      customerID,
    }
  ] = useCustomerProjectInfo(projectID);

  const customerSettings = useSelector(getCustomerSettingsSelector);

  const { isLegacyCustomer } = useManageProjectRevampLegacyCustomerHook(customerID);

  const isManageProjectsRevampEnabled = customerSettings?.manageProjectsRevampFeature ?? true;

  if (isManageProjectsRevampEnabled || !isLegacyCustomer) {
    return <ManageProjectItemsV3 {...props} />;
  }

  if (FEATURE_FLAG_CUSTOMER_CHECKOUT_UI_REVAMP === 'true') {
    return <ManageProjectItemsV2 {...props} />;
  }

  return <ManageProjectItems {...props} />;
}

export default ManageProjectItemsFactory;
