import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

import TitleDataDashboard from '../components/PartnerDataDashboard/TitleDataDashboard';
import BoxDataDashboard from '../components/PartnerDataDashboard/BoxDataDashboard';
import { getSingleSupplierDataDashboard } from '../apis/supplierDataDashboard';
import { convertPriceWithQuantityToCurrency } from '../utils/currencyUtils';
import { isEmptyWithAdditionalValue } from '../utils/stringUtils';
import { hasReviewPermission } from '../utils/roleUtils';
import PieCustomChart from '../components/PartnerDataDashboard/PieCustomChart';

import { getUserInfo } from '../apis/userApi';
import FaringFactoremPartnerBars from '../components/PartnerDataDashboard/FaringFactoremPartnerBars';
import { TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT } from '../constants/partnerDataDashboardConstants';

function PartnerDataDashboard(props) {
  const { userID: supplierID, exchangeRates, role, allowViewDashboard } = props;
  const queryParams = useParams();
  const history = useHistory();
  const isLargeScreen = useMediaQuery('(max-width:1320px)');
  const isMediumScreen = useMediaQuery('(max-width:960px)');

  const [userInfo, setUserInfo] = useState(null);

  const { userID } = queryParams;

  const {
    data: supplierDataDashboard,
    isLoading,
  } = useQuery('getSingleSupplierDataDashboard', () =>
    getSingleSupplierDataDashboard(userID)
  );

  useEffect(() => {
    if (
      !supplierDataDashboard?.profilePic ||
      !supplierDataDashboard?.data?.companyName
    ) {
      getUserInfo(userID).then((data) => {
        setUserInfo(data);
      });
    }
  }, [supplierDataDashboard]);

  if (isLoading) {
    return null;
  }

  // Only supplier that have access can open it and only for their data. Admin can access it all
  if (
    (allowViewDashboard === 0 || supplierID !== Number(userID)) &&
    !hasReviewPermission(role)
  ) {
    history.push('/');
  }

  const currency = get(supplierDataDashboard, 'currency') || 'SGD';
  const splitRevenueEarned =
    supplierDataDashboard?.data?.revenueEarned?.split(' ') || '0';
  // revenued earned if with currency or not
  const revenueEarned = splitRevenueEarned[1] || splitRevenueEarned[0];
  const salesTillDate = convertPriceWithQuantityToCurrency({
    totalPrice: isEmptyWithAdditionalValue(revenueEarned)
      ? 0
      : revenueEarned?.replace(/,/, ''),
    currency,
    exchangeRate: exchangeRates[currency],
  });
  const profilePic = supplierDataDashboard?.profilePic || userInfo?.profilePic;
  const companyName =
    supplierDataDashboard?.data?.companyName || userInfo?.companyName;

  return (
    <div style={{ padding: '1rem' }}>
      <TitleDataDashboard
        profilePic={profilePic}
        companyName={companyName}
        createdAt={supplierDataDashboard?.createdAt}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '1rem',
        }}
      >
        <BoxDataDashboard
          type='partner-tier'
          value={'#' + (supplierDataDashboard?.data?.partnerTier || 0)}
        />
      </div>
      <div
        style={{
          padding: isMediumScreen ? '1rem 0' : '1rem 0',
          display: 'grid',
          gap: '2rem',
          gridTemplateColumns: `repeat(${isLargeScreen ? 1 : 2}, 1fr)`,
        }}
      >
        <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
          <BoxDataDashboard
            type='sales-till-date'
            value={salesTillDate.totalPriceStr}
          />
          <div
            style={{
              display: 'grid',
              gap: '1rem',
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            <BoxDataDashboard
              type='orders-fulfilled'
              value={Number(
                get(supplierDataDashboard, 'data.numberOfOrdersTillDate') || 0
              ).toLocaleString()}
            />
            <BoxDataDashboard
              type='individual-parts-made'
              value={Number(
                get(supplierDataDashboard, 'data.numberOfPartsProduced') || 0
              ).toLocaleString()}
            />
          </div>
          <FaringFactoremPartnerBars
            companyName={companyName}
            profilePic={profilePic}
            supplierDataDashboard={supplierDataDashboard}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <PieCustomChart
            title="Average Cycle Time"
            tooltipTitle={TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.averageCycleTimeWorking}
            tooltipChart={TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.cycleTimeRating}
            averageCycleTime={(
              Number(supplierDataDashboard?.data?.averageCycleTimeWorking) || 0
            ).toFixed(2) + " days"}
            cycleTimeRating={supplierDataDashboard?.data?.cycleTimeRating || 0}
          />
          <PieCustomChart
            title="Average Quote Response Time"
            tooltipTitle={TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.averageSpeedTimeToQuote}
            tooltipChart={TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.speedToQuoteTimeRating}
            averageCycleTime={(
              Number(supplierDataDashboard?.data?.averageSpeedTimeToQuote) || 0
            ).toFixed(2) + " hours"}
            cycleTimeRating={supplierDataDashboard?.data?.speedToQuoteTimeRating || 0}
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
    userID: state.auth.user.userID,
    exchangeRates: state.auth.rates,
    allowViewDashboard: state.supplierControlSettings.allowViewDashboard,
  };
}

const withConnect = connect(mapStateToProps);

export default withConnect(PartnerDataDashboard);
