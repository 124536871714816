import React from 'react';

import { colors } from '../palette';

import FileIcon from '../assets/img/file.png';

import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import {
  DescriptionOutlined as DescriptionOutlinedIcon,
  MonetizationOn as MonetizationOnIcon,
} from '@material-ui/icons';

import DateFormatMethod from '../components/DateFormatMethod';
import { getFileNameFromCadFile, getMaterialWithColorText, getSurfaceFinishWithCustomizationsText, shouldShowInvoice } from '../utils/itemUtils';
import DownloadIconButton from './buttons/DownloadIconButton';
import InfoIcon from '../components/icons/InfoIcon';
import { connect } from 'react-redux';
import { convertPriceWithQuantityToCurrency } from '../utils/currencyUtils';
import { downloadS3File } from '../utils/fileUtils';
import { DELIVERY_OPTIONS_DISPLAY_MAPPING } from '../constants/itemConstants';
import { showUnitValueFromMetric } from '../utils/userUtils';

const useStyles = makeStyles(() => ({
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  rowDetail: {
    color: colors.fontGrey,
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    marginBottom: 9,
  },
  rowTitle: {
    fontWeight: 600,
  },
  rowTitleLink: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    color: colors.solidBlue,
  },
  rowContent: {
    marginLeft: 5,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
}));

function OrderDetails(props) {

  const classes = useStyles();

  const { partInfo, currency, exchangeRate } = props;

  const renderInvoiceUrl = (partInfo) => {
    if (shouldShowInvoice(partInfo) && partInfo.invoiceUrl) {
      return (
        <DownloadIconButton
          tooltip="Click to download"
          url={partInfo.invoiceUrl}
          icon={MonetizationOnIcon}
          label="Invoice"
          navigateUrl={true}
        />
      );
    }
    return null;
  }

  const { totalPriceStr } = convertPriceWithQuantityToCurrency({
    totalPrice: partInfo.totalPrice || 0,
    quantity: partInfo.quoteQty || 1,
    currency,
    exchangeRate,
  });

  return (
    <Grid container spacing={1} style={{ maxWidth: 800 }}>
      <Grid item xs={6}>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Order Date:</div>
          <div className={classes.rowContent}>
            {partInfo.dateOfOrder ? (
              <DateFormatMethod date={partInfo.dateOfOrder} />
            ) : partInfo.dateOfConfirmation ? (
              <DateFormatMethod date={partInfo.dateOfConfirmation} />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Quantity:</div>
          <div className={classes.rowContent}>
            {partInfo.quoteQty ? partInfo.quoteQty : 'NIL'}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Total Price:</div>
          <div className={classes.rowContent}>
            {totalPriceStr}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Technology:</div>
          <div className={classes.rowContent}>
            {partInfo.technology ? partInfo.technology : 'NIL'}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Material:</div>
          <div className={classes.rowContent}>
            {getMaterialWithColorText({
              material: partInfo.quoteMaterial,
              customMaterial: partInfo.quoteCustomMaterial,
              materialColor: partInfo.quoteMaterialColor,
            })}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Surface Finish:</div>
          <div className={classes.rowContent}>
            {getSurfaceFinishWithCustomizationsText({
              surfaceFinish: partInfo.quoteSurfaceFinish,
              customSurfaceFinish: partInfo.quoteCustomSurfaceFinish,
              color: partInfo.quoteColor,
            })}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Tolerance:</div>
          <div className={classes.rowContent}>
            +/- {showUnitValueFromMetric(partInfo.tolerance, partInfo.unitType)}&nbsp;
          </div>
          <InfoIcon toolTipText="This is the tightest tolerance indicated in your design" />
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Lead Time:</div>
          <div className={classes.rowContent}>
            {partInfo.leadTime && partInfo.markupLeadTime
              ? parseInt(partInfo.leadTime, 10) +
              partInfo.markupLeadTime +
              ' working day(s)'
              : partInfo.leadTime
                ? partInfo.leadTime + ' working day(s)'
                : 'NIL'}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Delivery Option:</div>
          <div className={classes.rowContent}>
            {DELIVERY_OPTIONS_DISPLAY_MAPPING[partInfo.deliveryOption]}
          </div>
        </div>
        <div className={classes.rowDetail}>
          <div className={classes.rowTitle}>Supplier Remarks:</div>
          <div className={classes.rowContent}>
            {partInfo.remarks ? partInfo.remarks : 'NIL'}
          </div>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.rowTitle}>Download Documents:</div>
        <div>
          <div
            style={{
              display: 'flex',
              marginBottom: 10,
            }}
          >
            {partInfo.quotationForm && (
              <DownloadIconButton
                tooltip="Click to download"
                url={partInfo.quotationForm}
                icon={DescriptionOutlinedIcon}
                label="Quotation"
              />
            )}
            {renderInvoiceUrl(partInfo)}
          </div>
          <div className={classes.rowTitle}>Design/CAD Files:</div>
          <div className={classes.rowContent}>
            {partInfo.originalFiles ? (
              <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                {partInfo.originalFiles.split(',').map((link, index) => {
                  const fileName = getFileNameFromCadFile(link);
                  return (
                    <ListItem
                      key={index}
                      style={{
                        backgroundColor: colors.uploadFileBg,
                        width: 260,
                        margin: 5,
                        border: `solid 1px ${colors.uploadFileBorder}`,
                        borderRadius: '5px',
                        height: '62px',
                        color: 'inherit',
                        cursor: 'pointer',
                      }}
                      component='a'
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadS3File(link);
                      }}
                    >
                      <ListItemAvatar
                        style={{ display: 'flex', minWidth: '45px' }}
                      >
                        <img
                          src={FileIcon}
                          alt='icon'
                          style={{ height: '30px' }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        style={{
                          width: 130,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: 12,
                        }}
                        primary={fileName}
                        className={classes.itemText}
                      />
                    </ListItem>
                  );
                })}
              </List>
            ) : partInfo.cadPart ? (
              <List style={{ display: 'flex', flexWrap: 'wrap' }}>
                {partInfo.cadPart.map((link, index) => {
                  const fileName = getFileNameFromCadFile(link);
                  return (
                    <ListItem
                      key={index}
                      style={{
                        backgroundColor: colors.uploadFileBg,
                        width: 260,
                        margin: 5,
                        border: `solid 1px ${colors.uploadFileBorder}`,
                        borderRadius: '5px',
                        height: '62px',
                        color: 'inherit',
                        cursor: 'pointer',
                      }}
                      component='a'
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadS3File(link);
                      }}
                    >
                      <ListItemAvatar
                        style={{ display: 'flex', minWidth: '45px' }}
                      >
                        <img
                          src={FileIcon}
                          alt='icon'
                          style={{ height: '30px' }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        style={{
                          width: 130,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: 12,
                        }}
                        primary={fileName}
                        className={classes.itemText}
                      />
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <div>NIL</div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
function mapStateToProps(state) {
  return {
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(OrderDetails);
