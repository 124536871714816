import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';

import AddEditCustomerAddressPopup from './AddEditCustomerAddressPopup';

import { isEmptyValue } from '../../../utils/commonUtils';
import { getCustomerDeliveryInfoList, updateMultiCheckoutFormState, updateMulticheckoutFormAddress } from '../../../actions/multiCheckoutForm';
import FtrDeletePopup from '../../ftr-components/FtrDeletePopup';
import { deleteDeliveryInfo } from '../../../apis/deliveryInfoApi';
import { notifyError, notifySuccess } from '../../../services/notificationService';


// --------------------------------------------------------------------------------

function withAddEditCustomerAddressHOC(WrappedComponent) {
  return function AddEditCustomerAddressHOC(props) {
    const dispatch = useDispatch();

    const defaultLocalState = {
      showAddAddressPopup: false,
      showDeleteAddressPopup: false,
      customerID: null,
      mode: null, // add or edit
      addressType: null, // shipping or billing
      addressObj: {},
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    const handleDeleteDeliveryInfo = () => {
      deleteDeliveryInfo(localState.addressObj.deliveryInfoID)
        .then(() => {
          notifySuccess('Successfully deleted a address');
          dispatch(getCustomerDeliveryInfoList(localState.customerID));
          updateLocalState({ showDeleteAddressPopup: false })
        })
        .catch(() => {
          notifyError('Failed deleted a address');
        });
    };

    return (
      <>
        <WrappedComponent
          updateAddEditCustomerAddressHOCState={updateLocalState}
          {...props}
        />
        {localState.showAddAddressPopup && (
          <AddEditCustomerAddressPopup
            open={localState.showAddAddressPopup}
            userID={localState.customerID}
            type={localState.addressType}
            mode={localState.mode}
            editAddressObj={localState.addressObj}
            handleClose={() => updateLocalState({ showAddAddressPopup: false })}
            onSuccess={(data) => {
              dispatch(getCustomerDeliveryInfoList(localState.customerID));
              const { result, addressType } = data;
              const { deliveryInfoID, country } = result;
              if (localState.mode === 'add') {
                if (!isEmptyValue(deliveryInfoID)) {
                  if (addressType === 'shipping') {
                    dispatch(updateMulticheckoutFormAddress({
                      country,
                      deliveryInfoID,
                    }));
                  } else {
                    dispatch(updateMultiCheckoutFormState({
                      billingAddressID: deliveryInfoID,
                    }));
                  }
                }
              } else if (localState.mode === 'edit') {
                const {
                  switchDefaultShipping,
                  switchDefaultBilling,
                } = result;
                if (addressType === 'shipping' && switchDefaultShipping) {
                  dispatch(updateMulticheckoutFormAddress({
                    country,
                    deliveryInfoID,
                  }));
                }
                if (addressType === 'billing' && switchDefaultBilling) {
                  dispatch(updateMultiCheckoutFormState({
                    billingAddressID: deliveryInfoID,
                  }));
                }
              }
              updateLocalState({ showAddAddressPopup: false });
            }}
          />
        )}
        {localState.showDeleteAddressPopup && (
          <FtrDeletePopup
            open={localState.showDeleteAddressPopup}
            title="Delete address"
            subtitle="Are you sure you want to delete this address?"
            type="blue-button"
            onClose={() => updateLocalState({ showDeleteAddressPopup: false })}
            onConfirm={handleDeleteDeliveryInfo}
          />
        )}
      </>
    );
  };
}

export default withAddEditCustomerAddressHOC;
