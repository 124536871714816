import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { connect } from 'react-redux';

import {
  Tab,
  Tabs,
} from '@material-ui/core';

import CADRenderer from './generation-tools/CADRenderer';
import FlattenPDFs from './generation-tools/FlattenPDFs';
import GenerateCombinedPO from './generation-tools/GenerateCombinedPO';
import GenerateCombinedQuotes from './generation-tools/GenerateCombinedQuotes';
import GeneratePartImage from './generation-tools/GeneratePartImage';
import GenerateTitleplate from './generation-tools/GenerateTitleplate';
import GetSimilarParts from './generation-tools/GetSimilarParts';
import IndividualPOGenerator from './generation-tools/IndividualPOGenerator';
import InternationalShippingCalculatorMultipleItems from './generation-tools/InternationalShippingCalculatorMultipleItems';
import S3Tools from './generation-tools/S3Tools';
import TechnicalDrawingExtractor from './generation-tools/TechnicalDrawingExtractor';
import ThreeDViewer from './generation-tools/ThreeDViewer';
import StlConverter from './generation-tools/StlConverter';
import ExtractDefects from './generation-tools/ExtractDefects';

import { isAdminOrHigherRole } from '../utils/roleUtils';

import { ROLE_TYPES } from '../constants';
import { colors } from '../palette';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
    overflowY: 'scroll',
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  GET_SIMILAR_PARTS: 'Get Similar Parts',
  GENERATE_COMBINED_QUOTES: 'Generate Combined Quotes',
  FLATTEN_PDFS: 'Flatten PDFs',
  GENERATE_COMBINED_PO: 'Generate Combined PO',
  GENERATE_TITLEPLATE: 'Generate Titleplate',
  GENERATE_PART_IMAGE: 'Generate Part Image',
  CAD_RENDERER: 'CAD Renderer',
  S3_TOOLS: 'S3 Tools',
  INDIVIDUAL_PO_GENERATOR: 'Generate Individual PO',
  INTERNATIONAL_SHIPPING_CALCULATOR: 'International Shipping Calculator',
  TECHNICAL_DRAWING_EXTRACTOR: 'Technical Drawing Extractor',
  THREE_D_VIEWER: '3D Viewer',
  STL_CONVERTER: 'STL Converter',
  EXTRACT_DEFECTS: 'Extract Defects',
}

const ALL_TABS = [
  TAB_KEYS.GET_SIMILAR_PARTS,
  TAB_KEYS.EXTRACT_DEFECTS,
  TAB_KEYS.GENERATE_COMBINED_QUOTES,
  TAB_KEYS.FLATTEN_PDFS,
  TAB_KEYS.GENERATE_TITLEPLATE,
  TAB_KEYS.GENERATE_PART_IMAGE,
  TAB_KEYS.CAD_RENDERER,
  TAB_KEYS.THREE_D_VIEWER,
  TAB_KEYS.S3_TOOLS,
  TAB_KEYS.GENERATE_COMBINED_PO,
  TAB_KEYS.INDIVIDUAL_PO_GENERATOR,
  TAB_KEYS.INTERNATIONAL_SHIPPING_CALCULATOR,
  TAB_KEYS.TECHNICAL_DRAWING_EXTRACTOR,
  TAB_KEYS.STL_CONVERTER,
]

// Will revert back this
const ORIGIN_REVIEWER_TABS = [
  TAB_KEYS.GENERATE_TITLEPLATE,
  TAB_KEYS.EXTRACT_DEFECTS,
  TAB_KEYS.FLATTEN_PDFS,
  TAB_KEYS.TECHNICAL_DRAWING_EXTRACTOR,
  TAB_KEYS.GENERATE_PART_IMAGE,
  TAB_KEYS.GET_SIMILAR_PARTS,
  TAB_KEYS.THREE_D_VIEWER,
]

const REVIEWER_TABS = [
  TAB_KEYS.GET_SIMILAR_PARTS,
  TAB_KEYS.EXTRACT_DEFECTS,
  TAB_KEYS.TECHNICAL_DRAWING_EXTRACTOR,
  TAB_KEYS.THREE_D_VIEWER,
  TAB_KEYS.GENERATE_PART_IMAGE,
  TAB_KEYS.INTERNATIONAL_SHIPPING_CALCULATOR,
  TAB_KEYS.GENERATE_TITLEPLATE,
  TAB_KEYS.FLATTEN_PDFS,
  TAB_KEYS.STL_CONVERTER,
]

function GenerationTools(props) {
  const classes = useStyles();

  const { role } = props;

  const title = role !== ROLE_TYPES.REVIEWER ? 'Generation Tools' : 'Factorem Technologies';

  const [viewableTabs, setViewableTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (isAdminOrHigherRole(role)) {
      setViewableTabs(ALL_TABS);
    } else if (role === ROLE_TYPES.REVIEWER) {
      setViewableTabs(REVIEWER_TABS);
    } else {
      setViewableTabs([]);
    }
  }, [role]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = viewableTabs[selectedTab];
    switch (tabName) {
      case TAB_KEYS.GET_SIMILAR_PARTS:
        return <GetSimilarParts />;
      case TAB_KEYS.GENERATE_COMBINED_QUOTES:
        return <GenerateCombinedQuotes />;
      case TAB_KEYS.FLATTEN_PDFS:
        return <FlattenPDFs />;
      case TAB_KEYS.GENERATE_COMBINED_PO:
        return <GenerateCombinedPO />;
      case TAB_KEYS.GENERATE_TITLEPLATE:
        return <GenerateTitleplate />;
      case TAB_KEYS.GENERATE_PART_IMAGE:
        return <GeneratePartImage />;
      case TAB_KEYS.CAD_RENDERER:
        return <CADRenderer />;
      case TAB_KEYS.S3_TOOLS:
        return <S3Tools />;
      case TAB_KEYS.INDIVIDUAL_PO_GENERATOR:
        return <IndividualPOGenerator />;
      case TAB_KEYS.INTERNATIONAL_SHIPPING_CALCULATOR:
        return <InternationalShippingCalculatorMultipleItems />
      case TAB_KEYS.TECHNICAL_DRAWING_EXTRACTOR:
        return <TechnicalDrawingExtractor />
      case TAB_KEYS.THREE_D_VIEWER:
        return <ThreeDViewer />
      case TAB_KEYS.STL_CONVERTER:
        return <StlConverter />
      case TAB_KEYS.EXTRACT_DEFECTS:
        return <ExtractDefects />
      default:
        return null;
    }
  }

  return (
    <div className={classes.body}>
      <h1>{title}</h1>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          style={{ maxWidth: '75vw' }}
        >
          {viewableTabs.map(tab => {
            return <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />;
          })}
        </Tabs>
      </div>
      {renderTab()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(GenerationTools);
