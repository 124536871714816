import React, { useReducer } from 'react';

import ShipmentInfoPopup from '../components/popups/ShipmentInfoPopup';


// -------------------------------------------------------------------------------------------------

function withShipmentInfoPopupHOC(WrappedComponent) {
  return function ShipmentInfoPopupHOC(props) {
    const defaultLocalState = {
      selectedData: null,
      open: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateShipmentInfoPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <ShipmentInfoPopup
            open={localState.open}
            handleClose={() =>
              updateLocalState({ open: false, selectedData: null })
            }
            data={localState.selectedData}
          />
        )}
      </>
    );
  }
}

export default withShipmentInfoPopupHOC;
