import React from 'react';

import { withStyles } from "@material-ui/core/styles";

import { Tooltip } from '@material-ui/core';

import { FtrBanner, FtrTypography } from '.';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';


// ----------------------------------------------------------------------------

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: "transparent",
  }
})(Tooltip);

/**
 * FtrTooltip component displays a tooltip with a header and description.
 * It uses Material-UI's Tooltip component and custom FtrBanner and FtrTypography components.
 *
 * Props:
 * - header: The header text to be displayed in the tooltip.
 * - description: The description text to be displayed in the tooltip.
 *
 * @param {Object} props - The component props.
 * @param {string} props.header - The header text to be displayed in the tooltip.
 * @param {string} props.description - The description text to be displayed in the tooltip.
 * @returns {JSX.Element} The rendered FtrTooltip component.
 */
function FtrTooltip(props) {
  const {
    header,
    description,
    wrapText = true,
    theme = 'dark',
  } = props;

  if (
    (typeof header !== 'string' && typeof description !== 'string')
    || (isEmptyValue(description) && isEmptyValue(header))
  ) {
    return (
      <>
        {props.children}
      </>
    );
  }

  return (
    <StyledTooltip
      title={
        <FtrBanner
          type='info'
          style={{
            backgroundColor: theme === 'dark' ? colors.neutral080 : 'white',
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            color: theme === 'dark' ? colors.neutral010 : colors.neutral080,
            width: 'fit-content',
            padding: '9px, 13px, 9px, 13px',
          }}
        >
          {header && (
            <FtrTypography type='heading' fontSize='16'>
              {header}
            </FtrTypography>
          )}
          <FtrTypography type='body' fontSize='14' style={{ whiteSpace: wrapText ? 'normal' : 'nowrap' }}>
            {description}
          </FtrTypography>
        </FtrBanner>
      }
      {...props}
    />
  );
}

export default FtrTooltip;
