import { useEffect, useState } from "react";

import {
  generatePresignedUrlForImage,
} from "../apis/s3Api";

import { isEmptyValue } from "../utils/commonUtils";

import { IMG_PLACE_HOLDER_URL, S3BUCKET_2D_IMG_FILES_FOLDER } from "../constants";

export function usePresignedUrl(sourceUrlParam) {
  const [sourceUrl, setSourceUrl] = useState(sourceUrlParam);
  const [imageUrl, setImageUrl] = useState(sourceUrlParam);

  useEffect(() => {
    setSourceUrl(sourceUrlParam);
  }, [sourceUrlParam]);

  useEffect(() => {
    if (isEmptyValue(sourceUrl)) {
      setImageUrl(null);
      return;
    }
    if (!sourceUrl.startsWith('http')) {
      setImageUrl(null);
      return;
    }
    // If the sourceUrl contains the S3 bucket 2dImgFiles or it's an image placeholder
    // Then use the URL directly
    if (sourceUrl.includes(S3BUCKET_2D_IMG_FILES_FOLDER) || sourceUrl === IMG_PLACE_HOLDER_URL) {
      setImageUrl(sourceUrl);
      return;
    }
    generatePresignedUrlForImage(sourceUrl, 30 * 60) // 30 minutes in second
      .then(url => {
        setImageUrl(url);
      });
  }, [sourceUrl]);


  return [
    imageUrl,
    {
      setSourceUrl,
    },
  ]
}
