import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  quoteLabelWrapper: {
    width: "fit-content",
    backgroundColor: colors.secondaryBlue,
    padding: "6px 11px",
    marginBottom: 14,
    fontSize: "11pt",
    borderRadius: 5,
    display: "flex"
  },
  quoteLabelTextWrapper: {
    display: "flex",
    alignItems: "center"
  },
}));

function PpeQuoteNoticeLabel() {
  const classes = useStyles();

  return (
    <div className={classes.quoteLabelWrapper}>
      <div className={classes.quoteLabelTextWrapper}>
        This job pays the given price shown below. Kindly accept job if
        you can fulfill all requirements.
      </div>
    </div>
  );
}

export default PpeQuoteNoticeLabel;
