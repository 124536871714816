import queryString from 'query-string';

import { getAuthorizedHeader } from "../utils/apiUtils";
import { BACKEND_SERVICE_URL } from "../constants";

/**
 * Get the completion status of a guided tour for a user by userID and tourKey.
 * If there are no rows for the userID and tourKey, create a row with completed = false.
 * 
 * params: userID, tourKey
 */
export const getGuidedTourCompletionStatus = async (userID, tourKey) => {
  const params = {
    userID,
    tourKey,
  }
  const requestUrl = `${BACKEND_SERVICE_URL}/tour/get-completion-status?${queryString.stringify(params)}`;

  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}

/**
 * Update the completion status of a guided tour for a user by userID and tourKey.
 * If there are no rows for the userID and tourKey, a 400 will be returned.
 * 
 * params: userID, tourKey, completed
 */
export const updateGuidedTourCompletionStatus = async (userID, tourKey, completed, additionalParams = { }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/tour/update-completion-status`;

  const body = {
    userID,
    tourKey,
    completed,
    ...additionalParams,
  };

  return fetch(requestUrl, {
    method: "PUT",
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}
