import React from "react";

import { colors } from "../../palette";

import { makeStyles } from "@material-ui/core/styles/index";
import { FlexRowCenter } from "../layouts/FlexLayouts";

const useStyles = makeStyles(() => ({
  labelWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "normal",
  },
  labelSuccess: {
    background: colors.successLightGreen,
    borderRadius: "12px",
    fontSize: "9pt",
    padding: "3px 14px",
    width: "fit-content"
  },
  labelPending: {
    background: colors.pendingLightYellow,
    borderRadius: "12px",
    fontSize: "9pt",
    padding: "3px 14px",
    width: "fit-content"
  },
  defaultLabel: {
    borderRadius: "12px",
    fontSize: "9pt",
    padding: "3px 14px",
    width: "fit-content"
  }
}));

function PaymentStatusSimpleLabelV2(props) {
  const classes = useStyles();
  const { paymentStatus } = props;
  if (paymentStatus === "paid") {
    return (
      <div className={classes.labelWrapper}>
        <div className={classes.labelSuccess}>Completed</div>
      </div>
    );
  } else if (paymentStatus === "pending") {
    return (
      <div className={classes.labelWrapper}>
        <div className={classes.labelPending}>Pending</div>
      </div>
    );
  }

  return (
    <FlexRowCenter>
      N/A
    </FlexRowCenter>
  );
}

export default PaymentStatusSimpleLabelV2;
