import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';


// --------------------------------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function withQueryClientProviderHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    return (
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <WrappedComponent {...props} />
      </QueryClientProvider>
    );
  }

  return WrappedComponentWithHOC;
}

export default withQueryClientProviderHOC;
