import React from 'react';

import { FtrBanner, FtrTypography } from '../../ftr-components';

function ManualQuotationRequiredBanner() {
  return (
    <FtrBanner type='info'>
      <FtrTypography type='heading' fontSize='16'>
        Manual Quotation Required
      </FtrTypography>
      <FtrTypography type='body' fontSize='14'>
        Our team will contact you within 24 hours with a finalised quote after you submit order for review.
      </FtrTypography>
    </FtrBanner>
  );
}

export default ManualQuotationRequiredBanner;
