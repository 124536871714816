import { isEmpty } from "lodash";

export default function DateFormatMethod(props) {
  const {
    date,
    monthType = 'long',
  } = props;

  if (isEmpty(date)) {
    return '';
  }

  const dateFormat = { year: 'numeric', month: monthType, day: 'numeric' };
  return new Date(date).toLocaleDateString('en-GB', dateFormat);
}
