import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import Decimal from 'decimal.js';
import dayjs from 'dayjs';
import { get, set } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import { DatePicker } from "@material-ui/pickers";
import { Link } from 'react-router-dom';

import {
  Container,
  Divider,
  TextField,
  CircularProgress,
  Button,
  InputAdornment,
  MenuItem,
  Paper,
  Typography,
  Grid,
  Collapse,
  Tooltip,
  Box,
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import { Block, Cancel, Done, Edit } from '@material-ui/icons';

import SingleImage from '../../components/images/SingleImage';
import AddItemsOrQuotesPopup from '../../components/popups/AddItemsOrQuotesPopup';
import Row from '../../components/Row';

import { getFedExRates, getDhlRates } from '../../apis/shipmentApi';
import { getUserInfo } from '../../apis/userApi';
import {
  getItemAndQuotesInfo,
  getPackageOptimizationInfo,
} from '../../apis/internationalShippingApi';

import { getUserCurrencySelector } from '../../selectors/userSelector';
import { getExchangeRateSelector, getExchangeRatesSelector } from '../../selectors/exchangeRatesSelector';

import { addressInfoText } from '../../utils/addressUtils';
import { formatSimpleDate } from '../../utils/dateTimeUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { itemProperties } from '../../utils/itemUtils';
import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';

import { notifyError, notifyErrorContent, notifySuccess } from '../../services/notificationService';

import { COUNTRY_LIST, COUNTRIES } from '../../constants/countryConstants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';

import { colors } from '../../palette';
import { reciprocal } from '../../utils/numberUtils';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import { FtrBoldText, FtrTypography } from '../../components/ftr-components';


// --------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  buttonUpdate: {
    padding: '6px 16px',
    fontSize: '0.8rem',
  },
  editIcon: {
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: colors.blue050,
      color: 'white',
      cursor: 'pointer',
      borderRadius: '50%',
    },
    padding: 2,
  },
}));

function ItemInfoDisplay(props) {
  const classes = useStyles();

  const {
    name = '',
    value: valueParam = '',
    postFix = '',
    editable = false,
    onSave = () => { },
  } = props;

  const VIEW = 'view';
  const EDIT = 'edit';

  const [mode, setMode] = useState(VIEW);
  const [value, setValue] = useState(valueParam);

  const handleSave = () => {
    onSave(value);
  }

  return (
    <Row>
      {mode !== EDIT && (
        <Typography variant='caption'>
          <strong>{name}:</strong> <span style={{ color: colors.blue050 }}>{value}</span>{postFix}
        </Typography>
      )}
      {mode === EDIT && (
        <Row>
          <Typography variant='caption'>
            <strong>{name}:</strong>
          </Typography>
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{ width: '4rem' }}
          />
          <Typography variant='caption'>
            {postFix}
          </Typography>
        </Row>
      )}
      {mode === VIEW && editable && (
        <Tooltip title="Edit">
          <Edit
            onClick={() => setMode(EDIT)}
            className={classes.editIcon}
          />
        </Tooltip>
      )}
      {mode === EDIT && (
        <Row>
          <Box style={{ width: '0.5rem' }} />
          <Tooltip title="Cancel">
            <Block
              onClick={() => setMode(VIEW)}
              className={classes.editIcon}
            />
          </Tooltip>
          <Tooltip title="Save">
            <Done
              onClick={() => {
                handleSave();
                setMode(VIEW);
              }}
              className={classes.editIcon}
            />
          </Tooltip>
        </Row>
      )}
    </Row>
  );
}

const ShipmentInfo = ({ price, estimatedDeliveryDateAndTime, title = 'Shipment Info', isLoading, isSuccess }) => {
  const classes = useStyles();
  
  if (isLoading) {
    return <CircularProgress className={classes.circularProgress} size={20} />
  }
  if (!isSuccess) {
    return null;
  }
  return (
    <FlexColumn>
      <FtrBoldText fontSize='16' >{title}</FtrBoldText>
      <FtrTypography>
        Price: {price}
      </FtrTypography>
      <FtrTypography>
        Estimate Delivery Date:{' '}
        {estimatedDeliveryDateAndTime.replace('T', ' ')}
      </FtrTypography>
    </FlexColumn>
  )
}

/**
 * this will support multiple items and quotes
 * 
 * @returns 
 */
export default function InternationalShippingCalculatorMultipleItems() {
  const classes = useStyles();

  const currency = useSelector(getUserCurrencySelector);
  const exchangeRate = useSelector(getExchangeRateSelector);
  const exchangeRates = useSelector(getExchangeRatesSelector);

  const [selectedItemsAndQuotes, setSelectedItemsAndQuotes] = useState([]);
  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      itemDetail: null,
      itemID: null,
      destinationCountry: 'America',
      destinationPostalCode: null,
      destinationCityName: null,
      originCountry: 'Singapore',
      originPostalCode: '139967',
      originCityName: 'Singapore',
      weight: null,
      length: null,
      width: null,
      height: null,
      plannedShippingDate: dayjs().add(2, 'day').format('YYYY-MM-DD'),
      isCustomsDeclarable: false,
      isItemProcessing: false,
      processed: undefined,
      price: '',
      estimatedDeliveryDateAndTime: '',
      isSuccessGetDhlRates: false,
      isGetDhlRatesProcessing: false,
      isSuccessGetFedExRates: false,
      isGetFedExRatesProcessing: false,
      fedexRateDetails: [],
      imageUrl: '',
      quantity: null,
      totalWeight: null,
      address: null,

      // when admin update the shipping info manually then set this to true,
      // and use this to not update when items/quotes are added or updated
      retainShippingAddress: false,
    },
  );
  const [openAddItemsOrQuotesPopup, setOpenAddItemsOrQuotesPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const isGetRatesProcessing = localState.isGetDhlRatesProcessing === true ||
    localState.isGetFedExRatesProcessing === true

  const paramsRates = {
    originCountryCode: COUNTRY_LIST[localState.originCountry].code,
    originCityName: localState.originCityName,
    originPostalCode: localState.originPostalCode,
    destinationCountryCode: COUNTRY_LIST[localState.destinationCountry].code,
    destinationCityName: localState.destinationCityName,
    destinationPostalCode: localState.destinationPostalCode,
    weight: localState.totalWeight,
    length: localState.length,
    width: localState.width,
    height: localState.height,
    plannedShippingDate: formatSimpleDate(localState.plannedShippingDate),
  };

  const reflectShippingAddressToForm = async (item) => {
    const userInfo = await getUserInfo(item?.userID);
    const {
      shippingAddress: defaultShipping,
      shippingCountry,
      shippingPostalCode,
    } = item;
    let splitAddress = defaultShipping?.address?.split(', ');
    let city = '';
    if (splitAddress?.length >= 2) { // for the address and city
      city = splitAddress.at(-1); // city on the last split
    }
    updateLocalState({
      address: addressInfoText(defaultShipping),
      destinationCountry: shippingCountry ?? userInfo?.country,
      destinationPostalCode: shippingPostalCode,
      destinationCityName: city,
    });
  }

  useEffect(() => {
    if (isEmptyValue(selectedItemsAndQuotes)) {
      return;
    }

    const body = {
      ppeInfoList: selectedItemsAndQuotes.map(item => ({
        ...item.ppeInfo,
        quantity: item.quantity,
      })),
    }
    const firstItemHasAddress = selectedItemsAndQuotes.find(item => !!item.shippingAddress);
    if (!isEmptyValue(firstItemHasAddress) && !localState.retainShippingAddress) {
      reflectShippingAddressToForm(firstItemHasAddress);
    }
    const totalWeight = selectedItemsAndQuotes.reduce((acc, item) => {
      // Default 1kg if item.ppeInfo.weight is 0, null, or undefined
      const weight = parseFloat(get(item, 'ppeInfo.weight') || 1);
      if (!isEmptyValue(item.quotationID) && item.itemQuantity !== item.quantity) {
        return acc += new Decimal(weight)
          .dividedBy(item.itemQuantity)
          .times(item.quantity)
          .toNumber();
      }
      return acc += weight;
    }, 0);

    getPackageOptimizationInfo(body)
      .then(data => {
        updateLocalState({
          length: new Decimal(data.length).toFixed(2), // cm
          width: new Decimal(data.width).toFixed(2), // cm
          height: new Decimal(data.height).toFixed(2), // cm
          totalWeight: new Decimal(totalWeight).toFixed(2), // in kg
          quantity: 1,
        });
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 2000);
      });
  }, [selectedItemsAndQuotes]);

  const isAllRequiresFilled = () => {
    let isFilled = true;
    const negativeValue = ["Invalid Date", null, 0, undefined, '-', 'N.A.', '']
    const allowedEmptyFields = ['destinationCityName'];
    for (const state in paramsRates) {
      if (
        negativeValue.includes(paramsRates[state])
        && !allowedEmptyFields.includes(state)
      ) {
        isFilled = false;
      }
    }
    return isFilled;
  };

  const handleAddItemsOrQuotes = async (body) => {
    return getItemAndQuotesInfo(body)
      .then(data => {
        if (isEmptyValue(data)) {
          notifyError('No data found');
          return;
        }
        setSelectedItemsAndQuotes([
          ...selectedItemsAndQuotes,
          ...data,
        ]);
        setOpenAddItemsOrQuotesPopup(false);
      });
  }

  const handleGetDHLShipmentFee = async () => {
    updateLocalState({
      isGetDhlRatesProcessing: true,
      processed: undefined,
    });
    getDhlRates(paramsRates)
      .then((data) => {
        let price = `${data.priceCurrency} ${data.price}`;
        const estimatedDeliveryDateAndTime = data.estimatedDeliveryDateAndTime;
        if (currency !== CURRENCY_CODE.SGD) {
          const { totalPriceStr: convertedPrice } = convertPriceWithQuantityToCurrency({
            totalPrice: Number(data.price),
            currency,
            exchangeRate,
          });
          price = `${price} (${convertedPrice})`;
        }
        updateLocalState({
          price,
          estimatedDeliveryDateAndTime,
          isSuccessGetDhlRates: true,
        });
        notifySuccess('DHL Shipment fee has been updated');
      })
      .catch((err) => {
        notifyErrorContent('Failed to get DHL Shipment Fee', err.message)
        updateLocalState({
          isSuccessGetDhlRates: false,
        });
      })
      .finally(() => updateLocalState({ isGetDhlRatesProcessing: false }));
  };

  const handleGetFedExShipmentFee = async () => {
    const paramsFedExRates = {
      shipper: {
        address: {
          postalCode: localState.originPostalCode,
          countryCode: COUNTRY_LIST[localState.originCountry].code,
        },
      },
      recipient: {
        address: {
          postalCode: localState.destinationPostalCode,
          countryCode: COUNTRY_LIST[localState.destinationCountry].code,
        },
      },
      shipDateStamp: formatSimpleDate(localState.plannedShippingDate),
      requestedPackageLineItems: [
        {
          weight: {
            units: 'KG',
            value: Number(localState.totalWeight),
          },
          dimensions: {
            length: Number(localState.length),
            width: Number(localState.width),
            height: Number(localState.height),
            units: 'CM',
          }
        },
      ],
      customsClearanceDetail: {
        commodities: [
          {
            name: 'NON_DOCUMENTS',
            numberOfPieces: 1,
            description: '',
            countryOfManufacture: '',
            harmonizedCode: '',
            harmonizedCodeDescription: '',
            itemDescriptionForClearance: '',
            weight: {
              units: 'KG',
              value: Number(localState.totalWeight),
            },
            quantity: 1,
            quantityUnits: '',
            unitPrice: {
              currency: 'SID',
              amount: null,
              currencySymbol: '',
            },
            customsValue: {
              currency: 'SID',
              amount: null,
              currencySymbol: '',
            },
            exportLicenseNumber: '',
            partNumber: '',
            exportLicenseExpirationDate: '',
            getcIMarksAndNumbers: '',
          },
        ],
      },
    };
    updateLocalState({
      isGetFedExRatesProcessing: true,
    });
    getFedExRates(paramsFedExRates)
      .then((data) => {
        updateLocalState({
          fedexRateDetails: data?.output?.rateReplyDetails,
          isSuccessGetFedExRates: true,
        });
        notifySuccess('FedEx Shipment fee has been updated');
      })
      .catch((err) => {
        notifyErrorContent('Failed to get FedEx Shipment Fee', err.message)
        updateLocalState({
          isSuccessGetFedExRates: false,
        });
      })
      .finally(() => updateLocalState({ isGetFedExRatesProcessing: false }));
  };

  const removeItemOrQuote = (index) => {
    setSelectedItemsAndQuotes((previous) => {
      previous.splice(index, 1);
      return [...previous];
    });
  }

  const updateItemOrQuoteProperty = (item, property, value) => {
    set(item, property, value);
    setSelectedItemsAndQuotes([
      ...selectedItemsAndQuotes,
    ]);
  }

  const renderFedExShipment = () => {
    if (localState.isGetFedExRatesProcessing) {
      return <CircularProgress className={classes.circularProgress} size={20} />
    }
    if (!localState.isSuccessGetFedExRates || isEmptyValue(localState.fedexRateDetails)) {
      return null;
    }
    return (
      <Fragment>
        <FtrBoldText fontSize='16' >FedEx Shipment</FtrBoldText>
        {localState.fedexRateDetails?.map((rate, index) => {
          const dataPrice = rate?.ratedShipmentDetails[0];
          const estimatedDeliveryDateAndTime = rate?.operationalDetail?.deliveryDate || 'N/A';
          let price = `${dataPrice?.currency} ${dataPrice?.totalNetCharge}`;
          if (dataPrice?.currency !== CURRENCY_CODE.SGD) {
            const { totalPriceStr: convertedPrice } = convertPriceWithQuantityToCurrency({
              totalPrice: Number(dataPrice?.totalNetCharge),
              currency,
              exchangeRate: reciprocal(exchangeRates[dataPrice?.currency]),
            });
            const convertedPriceValue = convertedPrice.split(' ')[1];
            price = `${price} (SGD ${convertedPriceValue})`;
          }
          return (
            <ShipmentInfo
              price={price}
              estimatedDeliveryDateAndTime={estimatedDeliveryDateAndTime}
              title={rate.serviceName}
              isLoading={localState.isGetFedExRatesProcessing}
              isSuccess={localState.isSuccessGetFedExRates}
              key={index}
            />
          )
        })}
      </Fragment>
    )
  }

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem 0',
            gap: '1rem',
          }}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setOpenAddItemsOrQuotesPopup(true);
            }}
          >
            Add Projects, Items or Quotes
          </Button>
          {!isEmptyValue(selectedItemsAndQuotes) && (
            <Button
              variant='contained'
              style={{
                color: 'white',
                backgroundColor: colors.errorRed,
              }}
              onClick={() => {
                setSelectedItemsAndQuotes([]);
                updateLocalState({
                  retainShippingAddress: false,
                });
              }}
            >
              Clear All
            </Button>
          )}
        </div>
        <Grid container spacing={1}>
          {selectedItemsAndQuotes.map((item, index) => {
            return (
              <Grid
                key={item.itemID}
                item
                xs={12}
                md={4}
                style={{
                  display: 'flex',
                  alignItems: 'stretch',
                }}
              >
                <Paper
                  style={{
                    width: '100%',
                    padding: '0.5rem',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      padding: '0.5rem',
                      display: 'flex',
                      gap: '1rem',
                      flexWrap: 'nowrap',
                      width: '100%',
                    }}
                  >
                    <SingleImage url={item.imageFile ?? item.twoDImageUrl} width={100} height={100} />
                    <div
                      style={{
                        width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                        ItemID: <Link target="_blank" to={{ pathname: `/item/edit/${item.itemID}` }}>
                          {item.itemID}
                        </Link>
                      </Typography>
                      <Typography variant='subtitle2'>
                        ProjectID: <Link target="_blank" to={{ pathname: `/manage-projects/${item.projectID}` }}>
                          {item.projectID}
                        </Link>
                      </Typography>
                      {item.quotationID && (
                        <Typography variant='subtitle2'>
                          QuotationID: <Link target="_blank" to={{ pathname: `/order/edit/${item.quotationID}` }}>
                            {item.quotationID}
                          </Link>
                        </Typography>
                      )}
                      <Typography variant='subtitle2' style={{ wordWrap: 'normal' }}>
                        {itemProperties(item)}
                      </Typography>
                      <ItemInfoDisplay
                        name="Height"
                        value={item.ppeInfo?.boundingBoxX ?? 0}
                        postFix="mm"
                        editable
                        onSave={(value) => updateItemOrQuoteProperty(item, 'ppeInfo.boundingBoxX', value)}
                      />
                      <ItemInfoDisplay
                        name="Width"
                        value={item.ppeInfo?.boundingBoxY ?? 0}
                        postFix="mm"
                        editable
                        onSave={(value) => updateItemOrQuoteProperty(item, 'ppeInfo.boundingBoxY', value)}
                      />
                      <ItemInfoDisplay
                        name="Length"
                        value={item.ppeInfo?.boundingBoxZ ?? 0}
                        postFix="mm"
                        editable
                        onSave={(value) => updateItemOrQuoteProperty(item, 'ppeInfo.boundingBoxZ', value)}
                      />
                      <ItemInfoDisplay
                        name="Weight"
                        value={
                          item.quantity
                            && item.itemQuantity
                            && item.quantity !== item.itemQuantity
                            ? new Decimal(item.ppeInfo?.weight ?? 0)
                              .dividedBy(item.itemQuantity)
                              .times(item.quantity)
                              .toNumber()
                            : item.ppeInfo?.weight ?? 0
                        }
                        postFix="kg"
                        editable
                        onSave={(value) => updateItemOrQuoteProperty(item, 'ppeInfo.weight', value)}
                      />
                    </div>
                  </div>
                  <Box style={{ flex: '1 1 auto' }} />
                  {item.shippingAddress && (
                    <ItemInfoDisplay
                      name="Shipping Address"
                      value={`${item.shippingAddress}, ${item.shippingCountry}, ${item.shippingPostalCode}`}
                    />
                  )}
                  <Tooltip title="Remove">
                    <Cancel
                      onClick={() => removeItemOrQuote(index)}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                        color: 'red',
                        position: 'absolute',
                        top: 2,
                        right: 2,
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
        <Divider style={{ marginTop: '1rem' }} />
        <Collapse in={showNotification}>
          <Paper elevation={4} className={classes.paper}>
            <Alert>Package dimension has been updated.</Alert>
          </Paper>
        </Collapse>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            columnGap: '1rem',
            placeItems: 'baseline',
            marginTop: '1rem',
          }}
        >
          <TextField
            label='Origin Country'
            value={localState.originCountry}
            variant='outlined'
            margin='dense'
            select
            style={{ marginRight: '1.25rem' }}
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(evt) => {
              updateLocalState({
                originCountry: evt.target.value,
              });
            }}
          >
            {COUNTRIES.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label='Origin Postal Code'
            value={localState.originPostalCode}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(evt) => {
              updateLocalState({
                originPostalCode: evt.target.value,
              });
            }}
          />
          <TextField
            label='Origin City Name'
            value={localState.originCityName}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(evt) => {
              updateLocalState({
                originCityName: evt.target.value,
              });
            }}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            columnGap: '1rem',
            placeItems: 'baseline',
            marginTop: '1rem',
          }}
        >
          <TextField
            label='Destination Country'
            value={localState.destinationCountry}
            onChange={(evt) => {
              updateLocalState({
                destinationCountry: evt.target.value,
                retainShippingAddress: true,
              });
            }}
            variant='outlined'
            margin='dense'
            select
            style={{ marginRight: '1.25rem' }}
            type='number'
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            {COUNTRIES.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label='Destination Postal Code'
            value={localState.destinationPostalCode}
            onChange={(evt) => {
              updateLocalState({
                destinationPostalCode: evt.target.value,
                retainShippingAddress: true,
              });
            }}
            variant='outlined'
            margin='dense'
            required
            style={{ marginRight: '1.25rem' }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label='Destination City Name'
            value={localState.destinationCityName}
            onChange={(evt) => {
              updateLocalState({
                destinationCityName: evt.target.value,
                retainShippingAddress: true,
              });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            columnGap: '1rem',
            placeItems: 'baseline',
            marginTop: '1rem',
          }}
        >
          <TextField
            label='Height'
            value={localState.height}
            onChange={(evt) => {
              updateLocalState({ height: evt.target.value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            type='number'
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
            }}
          />
          <TextField
            label='Width'
            value={localState.width}
            onChange={(evt) => {
              updateLocalState({ width: evt.target.value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            type='number'
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
            }}
          />
          <TextField
            label='Length'
            value={localState.length}
            onChange={(evt) => {
              updateLocalState({ length: evt.target.value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            type='number'
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>cm</InputAdornment>,
            }}
          />
          <TextField
            label='Weight (Total)'
            value={localState.totalWeight}
            onChange={(evt) => updateLocalState({ totalWeight: evt.target.value })}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            type='number'
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>kg</InputAdornment>,
            }}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            columnGap: '1rem',
            placeItems: 'baseline',
            marginTop: '1rem',
          }}
        >
          <DatePicker
            label={'Planned Shipping Date'}
            value={localState.plannedShippingDate}
            onChange={(date) => updateLocalState({ plannedShippingDate: date })}
            animateYearScrolling
            inputVariant='outlined'
            margin='dense'
            clearable
            clearLabel='No Preference'
            required
            fullWidth
          />
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => {
            handleGetDHLShipmentFee();
            handleGetFedExShipmentFee();
          }}
          disabled={
            isGetRatesProcessing ||
            !isAllRequiresFilled()
          }
        >
          {isGetRatesProcessing && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          &nbsp;Get International shipment fee
        </Button>
      </div>
      <Grid container spacing={1} style={{ paddingTop: '1.5rem' }}>
        <Grid item xs={6}>
          <ShipmentInfo
            price={localState.price}
            estimatedDeliveryDateAndTime={localState.estimatedDeliveryDateAndTime}
            title='DHL Shipment'
            isLoading={localState.isGetDhlRatesProcessing}
            isSuccess={localState.isSuccessGetDhlRates}
          />
        </Grid>
        <Grid item xs={6}>
          <FlexColumn style={{ gap: '1rem' }}>
            {renderFedExShipment()}
          </FlexColumn>
        </Grid>
      </Grid>
      {openAddItemsOrQuotesPopup && (
        <AddItemsOrQuotesPopup
          open={openAddItemsOrQuotesPopup}
          handleClose={() => setOpenAddItemsOrQuotesPopup(false)}
          handleAdd={handleAddItemsOrQuotes}
        />
      )}
    </Container>
  );
}
