export const CUSTOMER_CREDIT_TYPES = {
  CREDIT: 'credit',
  NON_CREDIT: 'non-credit',
}

export const CUSTOMER_STATUS_TYPES = {
  QUALIFIED: 'Qualified',
  FORMAL_ENQUIRY: 'Formal Enquiry',
  QUALIFIED_WITH_SAMPLE: 'Qualified With Sample',
  NA: 'N.A.',
}

export const INDUSTRIES = {
  AUTOMATION: 'Automation/Robotics',
  AUTOMOTIVE: 'Automotive/Mobility',
  AVIATION: 'Aviation/Aerospace',
  BIOTECH: 'Biotechnology/Biomedical',
  CONSUMER_PRODUCTS: 'Consumer Products',
  DEFENSE: 'Defense',
  DESIGN_AGENCY: 'Design Agency',
  ENERGY: 'Energy',
  ENGINEERING: 'Engineering',
  IOT: 'IoT',
  OPTICS: 'Optics',
  SEMICONDUCTOR: 'Semiconductor',
  TELECOMMUNICATION: 'Telecommunication',
  OTHERS: 'Others'
}

export const CUSTOMER_TYPES = {
  STARTUP: "Startup",
  EDUCATIONAL_INSTITUTION: "Educational Institution",
  SME: "SME",
  MANUFACTURER: "Contract Manufacturer",
  ENTERPRISE: "Enterprise/MNC",
  GOVT_AGENCY: "Govt. Agency",
  HOBBYIST: "Hobbyist",
}
