import React from 'react';

import { Tooltip } from '@material-ui/core';

import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import { colors } from '../../palette';

function CheckIcon(props) {
  const { toolTipText = '', className, style } = props;
  return (
    <Tooltip
      title={toolTipText}
      placement='top'
      arrow
      className={className}
      style={style}
      disableHoverListener={!toolTipText}
    >
      <CheckCircleOutlineOutlinedIcon
        style={{
          fontSize: '1.5rem',
          color: colors.greenTagColor,
        }}
      />
    </Tooltip>
  );
}

export default CheckIcon;
