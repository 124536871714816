import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';

import { colors } from '../palette';
import GeneratePoAutomationTab from './GeneratePoAutomationTab';
import GeneratePoAutomationTrackingTabDataGrid from './GeneratePoAutomationTrackingTabDataGrid';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TABS = ['Generate PO Automation', 'Tracking'];

function GeneratePoAutomation() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0); // 'Generate PO Automation' tab

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.body}>
      <h1>Generate PO Automation Email</h1>
      <Tabs value={selectedTab} onChange={handleChange}>
        {TABS.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {selectedTab === 0 && <GeneratePoAutomationTab />}
      {selectedTab === 1 && <GeneratePoAutomationTrackingTabDataGrid />}
    </div>
  );
}

export default GeneratePoAutomation;
