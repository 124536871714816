import React, { useCallback, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import { Select, FormControl } from '@material-ui/core';

import { Cancel } from '@material-ui/icons';

import FtrCheckboxBoldText from './FtrCheckboxBoldText';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import { FtrS3 } from './FtrTypography';
import { TopRightPositionAbsolute } from './FixedPosition';
import { FtrTooltip } from '.';

import { renderSkeletonOverlay } from '../util/skeleton';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------------------

const useStyles = (props) =>
  makeStyles(() => ({
    selectDropdown: {
      borderRadius: 10,
      '& .MuiSelect-select': {
        color: '#4F4F4F',
        fontWeight: 600,
        textAlign: 'left',
        whiteSpace: 'break-spaces',
      },
      '& legend': {
        width: 0,
      },
    },
    paper: {
      borderRadius: '0.8rem',
      padding: '0.5rem',
      ...props.menuStyle,
    },
    clearIcon: {
      color: colors.neutral050,
      '&:hover': {
        color: colors.neutral060,
        cursor: 'pointer',
      },
    },
    marginRight: {
      '& .MuiSelect-select': {
        paddingRight: '3.8rem',
      }
    },
  }));

/**
 * Customised Checkbox Dropdown component.
 *
 * @param {Object} props - The component's properties.
 * @param {string} props.id - The id of the select element.
 * @param {any} props.value - The current value of the select element.
 * @param {boolean} [props.fullWidth=false] - If true, the select will take up the full width of its container.
 * @param {function} [props.handleChange=()=>{}] - The function to be called when the select value changes.
 * @param {Array} [props.items=[{key: 'key1', label: 'Checkbox 1', checked: true}]] - The items to be displayed in the dropdown.
 * @param {boolean} [props.loading=false] - If true, a loading overlay will be displayed.
 * @param {string} [props.placeholder=''] - The placeholder text to be displayed when no value is selected.
 * @param {Object} [props.menuStyle={}] - The style to be applied to the dropdown menu.
 * @param {string} [props.label='Checkbox List'] - The label to be displayed in the select element.
 * @param {boolean} [props.showCheckbox=true] - If true, show checkbox and allow multiple selection, otherwise hide checkbox and only allow single selection.
 *
 * @returns {JSX.Element} - The FtrCheckboxDropdown component.
 */
function FtrCheckboxDropdown(props) {
  const {
    id,
    fullWidth = false,
    handleChange = () => { },
    items: initialItems = [
      {
        key: 'key1',
        label: 'Checkbox 1',
        checked: true,
      },
      {
        key: 'key2',
        label: 'Checkbox 2',
        checked: false,
      },
    ],
    loading = false,
    menuStyle = {},
    label = 'Checkbox List',
    showCheckbox = true,
  } = props;

  const classes = useStyles({ menuStyle })();

  const menuProps = {
    classes: {
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  const [items, setItems] = useState(initialItems);

  const selectedItems = items.filter(i => i.checked);

  const handleOnChange = (key) => {
    const newItems = items.map((item) => {
      if (key && (item.key === key || item.label === key)) {
        return {
          ...item,
          checked: showCheckbox ? !item.checked : true,
        };
      }

      return showCheckbox
        ? item
        : {
          ...item,
          checked: false,
        };
    });
    setItems(newItems);
    handleChange(newItems);
  };

  const handleClear = () => {
    const newItems = items.map((item) => ({
      ...item,
      checked: false,
    }));
    setItems(newItems);
    handleChange(newItems);
  }

  const renderClearFilterIcon = () => {
    if (isEmptyValue(selectedItems)) {
      return;
    }

    return (
      <TopRightPositionAbsolute style={{ top: 8, right: 30 }}>
        <FtrTooltip description='Clear'>
          <Cancel
            className={classes.clearIcon}
            onClick={(e) => {
              e.stopPropagation();
              handleClear();
            }}
          />
        </FtrTooltip>
      </TopRightPositionAbsolute>
    );
  }

  const renderValue = useCallback(() => {
    if (isEmptyValue(selectedItems)) {
      return label;
    }

    return (
      <FlexRow>
        {selectedItems.map(i => i.label).join(', ')}
      </FlexRow>
    );
  }, [
    selectedItems,
  ]);

  return (
    <FormControl style={{ width: fullWidth && '100%' }}>
      <FlexRow style={{ position: 'relative' }}>
        {loading && renderSkeletonOverlay()}
        <Select
          className={clsx(
            classes.selectDropdown,
            classes.disabled,
            !isEmptyValue(selectedItems) && classes.marginRight,
          )}
          id={id}
          variant='outlined'
          value={label}
          MenuProps={menuProps}
          margin='dense'
          renderValue={renderValue}
          fullWidth
          {...props}
        >
          <FlexColumn style={{ padding: '0.5rem 0.8rem' }}>
            {items.map((item) => {
              const key = item.key ?? item.label;
              return showCheckbox
                ? (
                  <FtrCheckboxBoldText
                    key={key}
                    label={item.label}
                    checked={item.checked}
                    onChange={(e) => {
                      e.preventDefault();
                      handleOnChange(key);
                    }}
                    style={{ margin: '0.2rem', boxSizing: 'border-box' }}
                  />
                )
                : (
                  <FtrS3
                    key={key}
                    style={{
                      userSelect: 'none',
                      margin: '0.2rem',
                      boxSizing: 'border-box',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleOnChange(key);
                    }}
                  >
                    {item.label}
                  </FtrS3>
                );
            })}
          </FlexColumn>
        </Select>
        {renderClearFilterIcon()}
      </FlexRow>
    </FormControl>
  );
}

export default FtrCheckboxDropdown;
