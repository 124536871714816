import { create } from 'zustand';

// eslint-disable-next-line no-unused-vars
const useOrderSelectionForOrderAgainZustand = create((set, get) => ({
  selectedRows: [],
  addRow: (row) => {
    set((state) => ({
      ...state,
      selectedRows: [...state.selectedRows, row],
    }));
  },
  removeRow: (row) => {
    set((state) => ({
      ...state,
      selectedRows: state.selectedRows.filter((r) => r.id !== row.id),
    }));
  },
  resetRows: () => {
    set((state) => ({
      ...state,
      selectedRows: [],
    }));
  },
}));

export default useOrderSelectionForOrderAgainZustand;
