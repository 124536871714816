import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { CardMedia } from '@material-ui/core';

import { usePresignedUrl } from '../../hooks/usePresignedUrl';

import { getItemImageUrl } from '../../utils/itemUtils';

const useStyles = makeStyles(() => ({
  media: {
    backgroundSize: "contain",
    margin: 2,
  },
}));

const IMAGE_AREA_HEIGHT = 250;
const MAX_IMAGES = 9;

function DisplayCardMedia(props) {
  const { title, url, imageHeight, id } = props;

  const classes = useStyles();

  const [displayImageUrl] = usePresignedUrl(url);

  return (
    <CardMedia
      className={classes.media}
      style={{
        minWidth: imageHeight,
        minHeight: imageHeight,
        maxWidth: imageHeight,
        maxHeight: imageHeight,
      }}
      image={displayImageUrl}
      title={title}
      data-cy={id}
    />
  );
}

function ProjectImageCollection(props) {
  const { items } = props;
  const itemImages = items
    .map((item) => {
      return {
        itemID: item.itemID,
        imageUrl: getItemImageUrl(item),
      }
    })
    .filter(item => item.imageUrl
      && item.imageUrl.startsWith('http')
      && !item.imageUrl.endsWith('ImagePlaceholder'))
    .slice(0, MAX_IMAGES);
  const numOfImages = itemImages.length;
  const numOfRows = Math.ceil(Math.sqrt(numOfImages));
  const imageHeight = Math.floor(IMAGE_AREA_HEIGHT / numOfRows);
  return (
    <div
      style={{
        display: 'flex',
        height: IMAGE_AREA_HEIGHT + 16,
        width: IMAGE_AREA_HEIGHT + 16,
        alignItems: 'top',
        justifyContent: 'start',
        flexWrap: 'wrap',
      }}
    >
      {itemImages.map(item => {
        return (
          <DisplayCardMedia
            key={item.itemID}
            url={item.imageUrl}
            imageHeight={imageHeight}
            title={item.referenceName}
            id={`card-media-item-${item.itemID}`}
          />
        )
      })}
    </div>
  );
}

export default ProjectImageCollection;
