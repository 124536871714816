//import components
import SearchBar from '../components/grid-data/buttons/SearchBar';
import CustomToolbar from '../components/grid-data/CustomToolbar';

//import util
import convertUTCtoLocaleDate from '../components/util/ConvertUTCToLocalDate';
import { generateCurrentCustomDateTimeString } from '../util';
import { downloadS3File } from '../utils/fileUtils';
import { isJsonString } from '../utils/commonUtils';

// Import settings
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// Import material UI components
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';

// Import constants;
import { colors } from '../palette';
import { deletePPEFeedbackByID, getAllPPEFeedback } from '../apis/ppeApi';
import { notifyError, notifySuccess } from '../services/notificationService';
import YesNoPopup from '../components/popups/YesNoPopup';
import GridDataPagination from "../components/grid-data/GridDataPagination";
import { ceil } from "lodash";
import { useDataGridFilterHook } from "../hooks/useDataGridFilterHook";

const useStyles = makeStyles(() => ({
  body: {
    padding: '0 1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      whiteSpace: 'normal',
    },
  },
  feedback: {
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  feedbackText: {
    fontWeight: 'bold',
    display: 'inline-block',
  },
}));

function AllPPEFeedbackDataGrid() {
  const classes = useStyles();
  const [ppeFeedback, setPPEFeedback] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedPPE, setSelectedPPE] = useState(null);


  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    {
      setSearchStr,
      setColumnsDef,
      setSourceData,
    }
  ] = useDataGridFilterHook({
    search: '',
    source: ppeFeedback,
  });

  function retrieveAllPPEFeedback() {
    updateTableQueryParams({ loading: true })
    getAllPPEFeedback()
      .then((res) => {
        const data = res.data;
        let tempPPEFeedback = [];
        for (const ppeData of data) {
          if (
            !isJsonString(ppeData.userInputs) ||
            !isJsonString(ppeData.PPEGeneratedParams)
          ) {
            continue;
          }
          const userInputs = JSON.parse(ppeData.userInputs);
          const ppeGeneratedParams = JSON.parse(ppeData.PPEGeneratedParams);
          const parameters = userInputs.parameters;
          let feedback = {
            PPEFeedbackID: ppeData.PPEFeedbackID,
            process: userInputs.process,
            material: parameters?.material,
            finish: parameters?.finish,
            tolerance: parameters?.tolerance,
            quantity: parameters?.quantity,
            ppe_price: ppeGeneratedParams?.price,
            ppe_price_supplier: ppeData.supplierPrice,
            ppe_price_supplier_markup: ppeData.supplierMarkup,
            static_cost: ppeGeneratedParams?.static_cost,
            material_cost: ppeGeneratedParams?.material_cost,
            process_cost: ppeGeneratedParams?.process_cost,
            finish_cost: ppeGeneratedParams?.finish_cost,
            volume: ppeGeneratedParams?.volume,
            weight: ppeGeneratedParams?.weight,
            surfaceArea: ppeGeneratedParams?.surfaceArea,
            boundingBoxX: ppeGeneratedParams?.boundingBoxX,
            boundingBoxY: ppeGeneratedParams?.boundingBoxY,
            boundingBoxZ: ppeGeneratedParams?.boundingBoxZ,
            bounding_box_vol: ppeGeneratedParams?.bounding_box_vol,
            complexity: ppeGeneratedParams?.complexity,
            status: ppeData.status,
            expectedPrice: ppeData.expectedPrice,
            feedback: ppeData.feedback,
            userID: ppeData.userID,
            supplierName: ppeData.name,
            companyName: ppeData.companyName,
            cadFile: ppeData.cadFile,
            date: ppeData.dateOfFeedback,
          };
          tempPPEFeedback.push(feedback);
        }
        setPPEFeedback(tempPPEFeedback);
      })
      .catch((e) => console.log(e))
      .finally(() => updateTableQueryParams({ loading: false }))
  }

  const defaultColumns = [
    {
      title: 'PPE FeedBack ID',
      headerName: 'PPE FeedBack ID',
      field: 'PPEFeedbackID',
      width: 145,
    },
    {
      title: 'Cad File',
      headerName: 'Cad File',
      field: 'cadFile',
      renderCell: function (rowData) {
        return (
          <Link
            onClick={(e) => {
              e.stopPropagation();
              downloadS3File(rowData.cadFile);
            }}
          >
            Download
          </Link>
        );
      },
    },
    {
      title: 'Status',
      headerName: 'Status',
      field: 'status',
    },
    {
      title: 'Quantity',
      headerName: 'Quantity',
      field: 'quantity',
    },
    {
      title: 'PPE Price',
      headerName: 'PPE Price',
      field: 'ppe_price',
      width: 165,
    },
    {
      title: 'PPE Price Markup % (Supplier)',
      headerName: 'PPE Price Markup % (Supplier)',
      field: 'ppe_price_supplier_markup',
      width: 165,
    },
    {
      title: 'PPE Price (Supplier)',
      headerName: 'PPE Price (Supplier)',
      field: 'ppe_price_supplier',
    },
    {
      title: 'Supplier Expected Price',
      headerName: 'Supplier Expected Price',
      field: 'expectedPrice',
      width: 140,
    },
    {
      title: 'Material',
      headerName: 'Material',
      field: 'material',
    },
    {
      title: 'Finish',
      headerName: 'Finish',
      field: 'finish',
    },
    {
      title: 'Tolerance',
      headerName: 'Tolerance',
      field: 'tolerance',
      width: 110,
    },
    {
      title: 'Volume',
      headerName: 'Volume',
      field: 'volume',
      width: 160,
    },
    {
      title: 'Weight (kg)',
      headerName: 'Weight (kg)',
      field: 'weight',
      width: 160,
    },
    {
      title: 'Surface Area',
      headerName: 'Surface Area',
      field: 'surfaceArea',
      width: 160,
    },
    {
      title: 'Complexity',
      headerName: 'Complexity',
      field: 'complexity',
      width: 160,
    },
    {
      title: 'Bounding Box X',
      headerName: 'Bounding Box X',
      field: 'boundingBoxX',
      width: 160,
    },
    {
      title: 'Bounding Box Y',
      headerName: 'Bounding Box Y',
      field: 'boundingBoxY',
      width: 160,
    },
    {
      title: 'Bounding Box Z',
      headerName: 'Bounding Box Z',
      field: 'boundingBoxZ',
      width: 160,
    },
    {
      title: 'Bounding Box Volume',
      headerName: 'Bounding Box Volume',
      field: 'bounding_box_vol',
      width: 160,
    },
    {
      title: 'Static Cost',
      headerName: 'Static Cost',
      field: 'static_cost',
      width: 170,
    },
    {
      title: 'Material Cost',
      headerName: 'Material Cost',
      field: 'material_cost',
      width: 170,
    },
    {
      title: 'Process Cost',
      headerName: 'Process Cost',
      field: 'process_cost',
      width: 170,
    },
    {
      title: 'Finishing Cost',
      headerName: 'Finishing Cost',
      field: 'finish_cost',
      width: 170,
    },
    {
      title: 'User Id',
      headerName: 'User Id',
      field: 'userID',
    },
    {
      title: 'Supplier Name',
      headerName: 'Supplier Name',
      field: 'supplierName',
      width: 160,
    },
    {
      title: 'Company Name',
      headerName: 'Company Name',
      field: 'companyName',
    },
    {
      title: 'Date',
      headerName: 'Date',
      field: 'date',
      renderCell: ({ row }) => {
        return <div>{convertUTCtoLocaleDate(row.date)}</div>;
      },
    },
    {
      title: 'Delete',
      headerName: 'Delete',
      field: 'delete',
      renderCell: ({ row }) => {
        return (
          <IconButton
            aria-label='delete'
            onClick={() => {
              setShowDeletePopup(true)
              setSelectedPPE(row)
            }}
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        );
      },
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  //Retrieve all ppe feedback when page renders
  useEffect(() => {
    retrieveAllPPEFeedback();
    const _columns = defaultColumns.filter(col => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(ppeFeedback);
  }, [ppeFeedback]);

  const handleDelete = (feedbackID) => {
    deletePPEFeedbackByID(feedbackID)
      .then(() => {
        notifySuccess('Feedback deleted');
        retrieveAllPPEFeedback();
      })
      .catch(() =>
        notifyError('Unable to delete feedback. Please try again later!')
      )
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const getToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
          gap: '1rem'
        }}
      >
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
          ]}
        />
        <GridToolbarExport
          csvOptions={{
            fileName: 'PPE Feedback ' + generateCurrentCustomDateTimeString(),
          }}
        />
      </div>
    );
  };

  return (
    <div className={classes.body}>
      <h1>Manage PPE Feedback</h1>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.PPEFeedbackID}
          rowHeight={80}
          headerHeight={80}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 50]}
          loading={tableQueryParams.loading}
          disableColumnMenu
        />
      </div>
      <YesNoPopup
        title='Are you sure that you want to delete this feedback?'
        open={showDeletePopup}
        handleNo={() => setShowDeletePopup(false)}
        handleYes={() => {
          handleDelete(selectedPPE.PPEFeedbackID);
          setShowDeletePopup(false);
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
    users: state.users.users,
  };
}

const withConnect = connect(mapStateToProps);
export default withRouter(withConnect(AllPPEFeedbackDataGrid));
