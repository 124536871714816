import React, { useReducer } from 'react';

import AddEditFactoremPromotionCodePopup from '../pages/control-panels/AddEditFactoremPromotionCodePopup';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

function withFactoremPromotionCodePopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      mode: 'add',
      codeID: null,
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    return (
      <>
        <WrappedComponent
          updateFactoremPromotionCodePopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <AddEditFactoremPromotionCodePopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            mode={localState.mode}
            codeID={localState.codeID}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withFactoremPromotionCodePopupHOC;
