import React, { useContext, useEffect, useMemo, useState } from 'react';

import { makeStyles } from '@material-ui/core';

import { colors } from '../../palette';

import SingleImage from './SingleImage';
import ThreeDViewerPopup from '../popups/ThreeDViewerPopup';
import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../constants/configurations';
import { FtrButton } from '../ftr-components';

import { generateItemImage } from '../../services/convert2DImageService';

import { getCadFileTo3DRenderer } from '../../utils/fileUtils';

import { IMG_PLACE_HOLDER_URL } from '../../constants';
import AppContext from '../../context/AppContext';

const useStyles = makeStyles(() => ({
  render3dCAD: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
  },
  renderImage: {
    cursor: 'pointer',
    position: 'relative',
    width: 'fit-content',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)',
      },
    },
  },
  label: {
    backgroundColor: colors.blackTint060,
    padding: '2px 8px',
    position: 'absolute',
    top: '12px',
    right: '12px',
    color: 'white',
    fontSize: '12px',
    textAlign: 'center',
    borderRadius: '10px',
  },
}));

/**
 *
 * @param {Object} props
 * @param {String} props.twoDImageUrl
 * @param {String} props.cadFile
 * @param {React.CSSProperties} props.style
 * @param {Boolean} props.showCadViewer
 * @param {Boolean} props.isLoading
 * @param {Number} props.width
 * @param {Number} props.height
 * @param {Number} props.borderRadius
 * @param {Boolean} [props.noBorder=true]
 * @param {Number} props.version
 * @param {React.CSSProperties} props.textRenderStyle
 * @param {Number} props.itemID
 * @param {Boolean} props.isGenerateItemImage
 * @returns
 */
function ImageWith3DViewer(props) {
  const {
    twoDImageUrl,
    cadFile,
    style,
    showCadViewer = true,
    isLoading,
    width = 160,
    height = 160,
    borderRadius = 20,
    noBorder = true,
    version,
    textRenderStyle,
    itemID,
    isGenerateItemImage = false,
  } = props;
  const classes = useStyles();

  const { featureFlags } = useContext(AppContext);

  const [openPopupCadViewer, setOpenPopupCadViewer] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState(twoDImageUrl);
  const [mouseEnter, setMouseEnter] = useState(false);
  const [hasImage, setHasImage] = useState(twoDImageUrl?.startsWith('http') && currentImageUrl !== IMG_PLACE_HOLDER_URL);
  const [generatingStatus, setGeneratingStatus] = useState(null);

  const cadFileTo3dViewer = useMemo(() => {
    return getCadFileTo3DRenderer(cadFile);
  }, [cadFile]);

  useEffect(() => {
    setCurrentImageUrl(
      twoDImageUrl && twoDImageUrl.startsWith('http') ? twoDImageUrl : null
    );
  }, [twoDImageUrl]);

  const showRender3DCAD =
    showCadViewer &&
    cadFileTo3dViewer &&
    featureFlags?.config[FE_FEATURE_FLAGS_CONFIGURATION.CAD_RENDERER_NATIVE];

  const handleRenderImage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (showRender3DCAD) {
      setOpenPopupCadViewer(true);
    }
  };

  const handleClose = () => {
    setOpenPopupCadViewer(false);
  };

  useEffect(() => {
    if (currentImageUrl?.startsWith('http') && currentImageUrl !== IMG_PLACE_HOLDER_URL) {
      setHasImage(true);
      setGeneratingStatus('success');
    }
  }, [currentImageUrl]);

  const renderGenerateImage = () => {
    if (
      hasImage ||
      !mouseEnter ||
      generatingStatus === 'loading' ||
      !isGenerateItemImage
    ) {
      return null;
    }
    const handleGenerateImageClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setGeneratingStatus('loading');
      generateItemImage(itemID).then(({ s3_file_url: imageUrl }) =>
        setCurrentImageUrl(imageUrl)
      );
    };

    return (
      <div className={classes.render3dCAD}>
        <FtrButton
          variant='contained'
          color='blue'

          style={{
            fontSize: '0.6rem',
            minWidth: 100,
          }}
          onClick={handleGenerateImageClick}
        >
          Generate Image
        </FtrButton>
      </div>
    );
  };

  return (
    <>
      <div
        className={classes.renderImage}
        onClick={handleRenderImage}
        style={style}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
      >
        <SingleImage
          url={currentImageUrl}
          width={width}
          height={height}
          noBorder={noBorder}
          loading={isLoading || generatingStatus === 'loading'}
          borderRadius={borderRadius}
        />
        {showRender3DCAD && mouseEnter && (
          <span className={classes.render3dCAD} style={textRenderStyle}>
            Render 3D CAD
          </span>
        )}
        {renderGenerateImage()}
        {version > 1 && <div className={classes.label}>V{version}</div>}
      </div>
      {openPopupCadViewer && (
        <ThreeDViewerPopup fileUrl={cadFileTo3dViewer} onClose={handleClose} />
      )}
    </>
  );
}

export default ImageWith3DViewer;
