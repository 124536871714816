import { makeStyles } from '@material-ui/core/styles/index';
import { colors } from '../../palette';

const inputsStyles = makeStyles(() => ({
  v2: {
    '& .MuiSelect-root': {
      minWidth: 150,
      fontSize: 14,
      fontWeight: 600,
      color: colors.neutral080,
      padding: '2px 16px',
      borderRadius: 10,
      border: '0.8px solid #CCC',
    },
  },
  emptyField: {
    '& .MuiInputBase-input': {
      color: `${colors.neutral040} !important`,
    },
  },
}));

export default inputsStyles;
