import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty, sortBy } from "lodash";
import { makeStyles } from '@material-ui/core/styles/index';
import { withRouter } from 'react-router-dom';

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { colors } from "../../palette";

import BlueButton from "../buttons/BlueButton";
import GreyButton from "../buttons/GreyButton";
import DateFormatMethod from "../DateFormatMethod";
import CompareQuotesMultiCheckoutRemarksPopup from "../popups/CompareQuotesMultiCheckoutRemarksPopup";
import { FtrButton } from '../ftr-components';
import EditQuoteDialog from '../../pages/EditQuoteDialog';
import { FlexColumn } from '../layouts/FlexLayouts';

import { convertPriceToCurrency, convertPriceWithQuantityToCurrency } from "../../utils/currencyUtils";
import { isEmptyValue } from "../../utils/commonUtils";
import { isDateInThePast } from "../../utils/dateTimeUtils";
import { isPpeItem } from "../../utils/itemUtils";
import { compareStringsEqualsIgnoreCase } from '../../utils/stringUtils';
import { getQuotationExchangeRate, isUncheckedOutPartWithQuotes, isUnverifiedQuote } from '../../utils/quotationUtils';

import { addSelectedQuote, removeSelectedQuote } from "../../actions/multiCheckoutForm";

import { materialWithColorCol, surfaceFinishingWithColorCol } from "../../constants/itemTableConstants";
import { COUNTRY_NAMES } from '../../constants/countryConstants';
import { TECHNOLOGY_OPTION_TYPE } from "../../constants/NewPartConstants";
import { ORDER_STATUS } from "../../constants";
import { QC_FORMAL_CMM_REPORT } from '../../constants/projectConstants';

import { getUserCurrencySelector } from '../../selectors/userSelector';
import { getMultiCheckoutFormSelector } from '../../selectors/multiCheckoutFormSelector';

import ProjectItemsContext from '../../context/ProjectItemsContext';

const useStyles = makeStyles(() => ({
  compareQuoteTableContainer: {
    '& tbody>.MuiTableRow-root.Mui-selected': {
      background: `${colors.green050}33 !important`,
    },
    '& tbody>.MuiTableRow-root:hover': {
      background: `${colors.secondaryBlue} !important`,
    },
  },
  unverifiedQuote: {
    background: `${colors.bgUnverifiedQuote}33 !important`,
  },
  textInfo: {
    fontSize: "10pt",
    color: colors.fontGrey
  }
}));

const isRowSelectionDisabled = ({ expiredQuoteIDList, rowData, isAdminView }) => {
  const selectableOrderStatuses = [ORDER_STATUS.PENDING];
  if (isAdminView) {
    selectableOrderStatuses.push(ORDER_STATUS.VERIFYING);
  }
  return expiredQuoteIDList.includes(rowData.quotationID)
    || rowData.totalPrice === 0
    || isEmptyValue(rowData.totalPrice)
    || !selectableOrderStatuses.includes(rowData.status);
}

function CompareQuotesMultiCheckoutMuiTable(props) {
  const classes = useStyles();

  const { isAdminView, customerCountry, qcReports } = useContext(ProjectItemsContext);

  const dispatch = useDispatch();

  const {
    quotations,
    item,
    getItems,
  } = props;

  const [selectedQuoteIDList, setSelectedQuoteIDList] = useState([]);
  const [openEditQuoteDialog, setOpenEditQuoteDialog] = useState(false);
  const [quotationID, setQuotationID] = useState(null);
  const [isVerifyQuote, setIsVerifyQuote] = useState(false);

  const [showRemarksPopup, setShowRemarksPopup] = useState(false);
  const [remarksPopupContent, setRemarksPopupContent] = useState("");
  
  const isUncheckedOutPart = useMemo(() => isUncheckedOutPartWithQuotes(item, isAdminView), [item, isAdminView])

  const currency = useSelector(getUserCurrencySelector);
  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);

  const cmmPrice = quotations.reduce((acc, quote) =>
    acc + (get(quote, 'metadata.cmmPriceCustomer') || get(quote, 'metadata.cmmPrice') || 0), 0);

  useEffect(() => {
    const selectedQuoteIDList = multiCheckoutForm.selectedQuotes.map(quote => quote.quotationID);
    setSelectedQuoteIDList(selectedQuoteIDList);
  }, [multiCheckoutForm.selectedQuotes]);

  const handleSelectQuote = (event, rowData) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(addSelectedQuote(rowData));
    } else {
      dispatch(removeSelectedQuote(rowData));
    }
  }

  const handleQuotationRowClick = (rowData) => {
    if (isRowSelectionDisabled({ rowData, expiredQuoteIDList, isAdminView })) {
      return;
    }
    if (selectedQuoteIDList.includes(rowData.quotationID)) {
      dispatch(removeSelectedQuote(rowData));
    } else {
      dispatch(addSelectedQuote(rowData));
    }
  }

  const tableData = useMemo(() => {
    return quotations
      ? sortBy(
        quotations.filter(
          quote => quote.status !== ORDER_STATUS.DISABLED
        ),
        quote => quote.quantity
      )
      : [];
    }, [quotations]);

  const expiredQuoteIDList = useMemo(() => {
    return tableData
      .filter(
        data => data.status === ORDER_STATUS.EXPIRED
          || (data.status !== ORDER_STATUS.ACCEPTED
            && data.dateOfExpiry
            && isDateInThePast(data.dateOfExpiry)
          )
      )
      .map(data => data.quotationID);
  }, [tableData])

  const renderQuotationPrices = (rowData, price, dataCyStr) => {
    if (
      rowData.status === ORDER_STATUS.WAITING_FOR_QUOTE
      || !price
    ) {
      return '-';
    }
    const qExchangeRate = getQuotationExchangeRate(rowData, currency);
    const { unitPriceStr, totalPriceStr } = convertPriceWithQuantityToCurrency({
      totalPrice: price,
      quantity: rowData.quantity,
      currency,
      exchangeRate: qExchangeRate,
    });
    return (
      <div data-cy={`${dataCyStr}-${rowData.itemID}`}>
        {totalPriceStr}
        <div className={classes.textInfo}>
          ({unitPriceStr}ea)
        </div>
      </div>
    );
  }

  const renderActionButton = (label, onClickHandler) => (
    <FtrButton
      color='blue'
      variant='contained'
      onClick={onClickHandler}
      autoFocus
      disabled={false}
    >
      {label}
    </FtrButton>
  );

  const renderExcludesShipping = () => {
    if (!compareStringsEqualsIgnoreCase(customerCountry, COUNTRY_NAMES.SINGAPORE)) {
      return null;
    }
    return (
      <span className={classes.textInfo}>(excludes shipping)</span>
    );
  }

  const unfilteredColumns = [
    isUncheckedOutPart
      ? {
        title: '',
        render: rowData => {
          return isRowSelectionDisabled({ rowData, expiredQuoteIDList, isAdminView })
            ? null
            : (
              <Checkbox
                checked={selectedQuoteIDList.includes(rowData.quotationID)}
                onChange={(event) => handleSelectQuote(event, rowData)}
              />
            );
        }
      } : null,
    isAdminView && isUncheckedOutPart
      ? {
        title: 'Action',
        render: rowData => {
          return renderActionButton(
            isUnverifiedQuote(rowData) ? 'Verify' : 'Edit',
            () => {
              setOpenEditQuoteDialog(true);
              setQuotationID(rowData.quotationID);
              setIsVerifyQuote(isUnverifiedQuote(rowData));
            });
        }
      } : null,
    isUncheckedOutPart && isEmpty(expiredQuoteIDList)
      ? null
      : {
        title: null,
        render: rowData => {
          if (rowData.status === ORDER_STATUS.ACCEPTED || (isPpeItem(item) && rowData.status === ORDER_STATUS.VERIFYING)) {
            return (
              <BlueButton btnContent="Accepted" size="small" disabled={true} />
            );
          } else if (rowData.status === ORDER_STATUS.REJECTED) {
            return (
              <GreyButton btnContent="Rejected" size="small" disabled={true} />
            );
          } else if (rowData.status === ORDER_STATUS.EXPIRED || (rowData.dateOfExpiry && isDateInThePast(rowData.dateOfExpiry))) {
            return (
              <GreyButton btnContent="Expired" size="small" disabled={true} />
            );
          } else {
            return '';
          }
        }
      },
    { title: "Quote ID", field: "quotationID", hidden: !isAdminView },
    { title: "Quantity", field: "quantity" },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    item.technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
      ? null
      : {
        title: "Technology",
        render: rowData => {
          const metadata = rowData.metadata || {};
          return !isEmpty(metadata.threeDTechnology) ? metadata.threeDTechnology : 'N.A.';
        }
      },
    item.technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
      ? null
      : {
        title: "Infill",
        render: rowData => {
          const metadata = rowData.metadata || {};
          return isEmptyValue(metadata.threeDInfill)
            ? 'N.A.'
            : `${(metadata.threeDInfill * 100).toFixed(0)}%`;
        },
      },
    item.technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
      ? null
      : {
        title: "Layer Height",
        render: rowData => {
          const metadata = rowData.metadata || {};
          return isEmptyValue(metadata.threeDLayerThickness)
            ? 'N.A.'
            : `${metadata.threeDLayerThickness}mm`;
        },
      },
    {title: "Supplier name", field: "supplierName", hidden: !isAdminView},
    {
      title: "Price from supplier",
      hidden: !isAdminView,
      render: rowData => {
        return renderQuotationPrices(rowData, rowData.priceBidded, 'supplier-price-itemID')
      },
    },
    {
      title: (
        <div style={{ minWidth: "120px" }}>
          <div>
            Total Price
          </div>
        </div>
      ),
      render: rowData => {
        return renderQuotationPrices(rowData, rowData.totalPrice, 'total-price-itemID')
      },
    },
    {
      title: `Lead Time`,
      field: "leadTime",
      render: rowData => {
        if (
          rowData.status === ORDER_STATUS.WAITING_FOR_QUOTE
          || !rowData.totalPrice
        ) {
          return '-';
        }
        const customerLeadTimeStr = rowData.leadTime && rowData.markupLeadTime
          ? parseInt(rowData.leadTime, 10) +
          rowData.markupLeadTime +
          " working days"
          : rowData.leadTime
            ? rowData.leadTime + " working days"
            : ""
        if (isAdminView) {
          return (
            <FlexColumn style={{ gap: 0 }}>
              <span>{customerLeadTimeStr}</span>
              <span>{`(${rowData.leadTime}${rowData.markupLeadTime ? ' + ' + rowData.markupLeadTime : ''})`}</span>
              {renderExcludesShipping()}
            </FlexColumn>
          )
        }
        return (
          <FlexColumn style={{ gap: 0 }}>
            <span>{customerLeadTimeStr}</span>
            {renderExcludesShipping()}
          </FlexColumn>
        )
      },
      cellStyle: {
        minWidth: 150,
      },
    },
    {
      title: "CMM Price",
      render: rowData => {
        return convertPriceToCurrency({
          price: get(rowData, 'metadata.cmmPriceCustomer') || get(rowData, 'metadata.cmmPrice') || 0,
          currency: currency,
          exchangeRate: getQuotationExchangeRate(rowData, currency)
        });
      },
      cellStyle: {
        minWidth: 150,
      },
      hidden: cmmPrice === 0 || qcReports?.main !== QC_FORMAL_CMM_REPORT
    },
    {
      title: "Remarks",
      field: "remarks",
      cellStyle: {
        maxWidth: "280px"
      },
      render: rowData => {
        if (
          rowData.status !== ORDER_STATUS.WAITING_FOR_QUOTE
          && !rowData.totalPrice
        ) {
          return '-';
        }
        if (rowData.remarks?.length > 180) {
          return (
            <>
              {rowData.remarks.slice(0, 180) + '... '}
              <span
                style={{
                  color: colors.buttonBlue,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setRemarksPopupContent(rowData.remarks);
                  setShowRemarksPopup(true);
                }}
              >See more
              </span>
            </>
          )
        }
        return rowData.remarks;
      }
    },
    {
      title: "Validity",
      field: "dateOfExpiry",
      render: rowData =>
        rowData.dateOfExpiry ? (
          <DateFormatMethod date={rowData.dateOfExpiry} />
        ) : (
          ""
        ),
      cellStyle: {
        minWidth: 150,
      },
    },
    // TODO: Temporary disabled
    // {
    //   title: "Quotation Form",
    //   field: "quotationForm",
    //   render: rowData =>
    //     rowData.quotationForm ? (
    //       <IconButton
    //         aria-label="pdf"
    //         onClick={() => window.open(rowData.quotationForm)}
    //       >
    //         <GetApp />
    //       </IconButton>
    //     ) : (
    //       <div>N.A.</div>
    //     )
    // },
  ];

  const columns = unfilteredColumns.filter(col => !!col);

  return (
    <div
      className={classes.compareQuoteTableContainer}
    >
      <Table aria-label="table">
        <TableHead>
          <TableRow>
            {columns.map(column => {
              if (column.hidden) {
                return null;
              }
              return (
                <TableCell
                  key={column.title}
                  style={{
                    position: "sticky",
                    top: 0,
                    color: colors.inputLabelGrey,
                    fontSize: "13pt",
                    fontWeight: 600,
                  }}
                >
                  {column.title}
                </TableCell>
              );
            })}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => {
            if (!isAdminView && isUnverifiedQuote(row)) {
              return null;
            }
            return (
              <TableRow
                selected={selectedQuoteIDList.includes(row.quotationID)}
                className={isUnverifiedQuote(row) ? classes.unverifiedQuote : ''}
                key={row.quotationID}
                onClick={() => handleQuotationRowClick(row)}
              >
                {columns.map((column) => {
                  if (column.hidden) {
                    return null;
                  }
                  const renderFunc = column.render;
                  return (
                    <TableCell key={column.field}>
                      {typeof renderFunc === 'function'
                        ? renderFunc(row)
                        : row[column.field]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <CompareQuotesMultiCheckoutRemarksPopup
        open={showRemarksPopup}
        handleClose={() => setShowRemarksPopup(false)}
        dialogContent={remarksPopupContent}
      />
      <EditQuoteDialog open={openEditQuoteDialog} setOpen={setOpenEditQuoteDialog} quotationID={quotationID} refreshDataFunc={getItems} isVerifyQuote={isVerifyQuote} />
    </div>
  );
}

export default withRouter(CompareQuotesMultiCheckoutMuiTable);
