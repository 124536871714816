import { isEmptyValue } from "./commonUtils";

import { generatePresignedUrlForImage } from "../apis/s3Api";

import { IMG_PLACE_HOLDER_URL } from "../constants";

export const getPresignedUrl = async (sourceUrl, expiration = 1800) => { // 30 minutes in second
  if (isEmptyValue(sourceUrl) || !sourceUrl.startsWith('http') || sourceUrl === IMG_PLACE_HOLDER_URL) {
    return IMG_PLACE_HOLDER_URL;
  }

  if (sourceUrl.includes('2dImgFiles')) {
    return sourceUrl;
  }

  return generatePresignedUrlForImage(sourceUrl, expiration); // 30 minutes in second
}
