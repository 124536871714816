import React, { useContext, useReducer } from 'react';
import { useHistory } from 'react-router-dom';

import DeleteWarningPopupV2 from '../../components/popups/DeleteWarningPopupV2';

import { customerDisableProject } from '../../apis/projectApi';

import { isEmptyValue } from '../../utils/commonUtils';

import AppContext from '../../context/AppContext';

import { CUSTOMER_CANCEL_ORDER_REASON_MAPPING } from '../../constants/itemConstants';


// -------------------------------------------------------------------------------------------------

function withDeleteProjectFeedbackPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const history = useHistory();

    const {
      setSnackbarMessage,
    } = useContext(AppContext);

    const defaultLocalState = {
      open: false,
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    const handleConfirmDeleteProject = ({ feedback, additionalRemarks }) => {
      const body = {
        projectIDs: [localState.projectID],
        feedback: CUSTOMER_CANCEL_ORDER_REASON_MAPPING[feedback] || feedback,
        additionalRemarks,
      };
      customerDisableProject(body)
        .then(() => {
          setSnackbarMessage('Your project has been deleted!');
          history.push('/manage-projects');
        })
        .catch(() => {
          setSnackbarMessage('Your project name cannot be changed. Please try again later.', {
            severity: 'error',
          });
        });
      updateLocalState({
        open: false,
        projectID: null,
      });
    };

    const handleCancelDeleteProject = () => {
      updateLocalState({
        open: false,
        projectID: null,
      });
    };

    return (
      <>
        <WrappedComponent
          updateDeleteProjectFeedbackPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <DeleteWarningPopupV2
            open={localState.open}
            onConfirm={handleConfirmDeleteProject}
            onCancel={handleCancelDeleteProject}
            onClose={handleCancelDeleteProject}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withDeleteProjectFeedbackPopupHOC;
