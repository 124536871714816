import { InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';

import { colors } from '../../palette';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 5,
    position: 'relative',
    width: '100%',
    backgroundColor: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    padding: '5px 14px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: colors.inputBorderBlue,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default BootstrapInput;
