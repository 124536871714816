import React from 'react';

import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import SubDesc from '../SubDesc';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '1rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function CompareQuotesMultiCheckoutRemarksPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    dialogContent
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='remarks-popup-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <SubDesc content="Remarks" />
      </DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
