const cacheObject = {};

export const cache = (
  key,
  value,
  ttlInMs = 1000, // default time to live is 1 second
) => {
  cacheObject[key] = value;
  setTimeout(() => {
    delete cacheObject[key];
  }, ttlInMs);
}

export const getCacheValue = (key) => {
  return cacheObject[key];
}

export const deleteCacheForKeyStartsWith = (keyPrefix) => {
  Object.keys(cacheObject).forEach(key => {
    if (key.startsWith(keyPrefix)) {
      delete cacheObject[key];
    }
  });
}
