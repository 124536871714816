import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { exportCSV } from '../../utils/csvExportUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import LoadingBackDropText from '../../components/LoadingBackDropText';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { usePrevious } from '../../hooks/usePrevious';
import { useMuiTableSearchAndPagination } from '../../hooks/useMuiTablePagination';

import formulas from '../../formulas/formulas';
import { colors } from '../../palette';
import { createPdf, getAllItemsAvailableForInvoice } from '../../actions';

const useStyles = makeStyles(() => ({
  tableContainer: {
    '& tbody>.MuiTableRow-root.Mui-selected': {
      background: `${colors.green050}33 !important`,
    },
    '& tbody>.MuiTableRow-root:hover': {
      background: `${colors.secondaryBlue} !important`,
      cursor: 'pointer',
    },
  },
  customExportSvg: {
    margin: '0.3rem',
  },
  createDeliveryOrder: {
    margin: '0.3rem',
  },
}));

function CreateReceiptMuiTable(props) {
  const classes = useStyles();

  const {
    itemsAvailableForInvoice,
    onPageLoad,
    submitCreatePdf,
    createPdfStatus,
  } = props;

  const previousCreatePdfStatus = usePrevious(createPdfStatus);

  const [selectedBuyerID, setSelectedBuyerID] = useState();
  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    if (isEmptyValue(selectedOrders)) {
      setSelectedBuyerID(null);
    }
  }, [selectedOrders]);

  useEffect(() => {
    if (previousCreatePdfStatus !== 'loading') {
      return;
    }
    if (createPdfStatus === 'success') {
      notifySuccess('Receipt has been created successfully');
      setSelectedOrders([]);
      setSelectedBuyerID(null);
    } else if (createPdfStatus === 'failure') {
      notifyError('Receipt creation failed');
    }
  }, [createPdfStatus]);

  const handleQuotationRowClick = (event, rowData) => {
    if (selectedBuyerID && selectedBuyerID !== rowData.buyerID) {
      notifyError('Multiple customer selection is not allowed');
      return;
    }
    if (!selectedOrders.includes(rowData.itemID)) {
      setSelectedOrders([...selectedOrders, rowData.itemID]);
      setSelectedBuyerID(rowData.buyerID);
    } else {
      const _selectedOrders = selectedOrders.filter(
        (selected) => selected !== rowData.itemID
      );
      setSelectedOrders(_selectedOrders);
    }
  };

  const columns = [
    {
      title: '',
      width: 30,
      render: (rowData) => {
        return <Checkbox checked={selectedOrders.includes(rowData.itemID)} />;
      },
    },
    { title: 'Part ID', field: 'itemID' },
    {
      title: 'Customer',
      field: 'buyerName',
      exportData: (rowData) => {
        return `${rowData.buyerName} <${rowData.buyerEmail}> (${rowData.buyerID})`;
      },
      render: (rowData) => {
        const text = `${rowData.buyerName} <${rowData.buyerEmail}> (${rowData.buyerID})`;
        return <DataGridWrapTextCell text={text} />;
      },
    },
    {
      title: 'Date Posted',
      field: 'datePosted',
      render: (rowData) =>
        rowData.datePosted ? rowData.datePosted.substring(0, 10) : '',
      exportData: (rowData) =>
        rowData.datePosted ? rowData.datePosted.substring(0, 10) : '',
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        overflow: 'hidden',
        whiteSpace: 'break-space',
        maxWidth: '8rem',
      },
    },
    { title: 'Supplier', field: 'supplierName' },
    { title: 'Quote ID', field: 'quotationID' },
    { title: 'Qty', field: 'quantity' },
    {
      title: 'Accepted Quote Price (excl. GST)',
      field: 'priceBidded',
      width: 100,
      render: (rowData) => '$' + rowData.priceBidded,
    },
    {
      title: 'Delivery Fee',
      field: 'deliveryFee',
      render: (rowData) => '$' + rowData.deliveryFee,
    },
    {
      title: 'Subtotal',
      render: (rowData) => '$' + formulas.calculateSubTotal(rowData),
    },
    {
      title: 'Total Price shown to customer',
      filed: 'totalPrice',
      render: (rowData) => (rowData.totalPrice ? '$' + rowData.totalPrice : ''),
    },
    {
      title: 'Deadline',
      field: 'deadline',
      render: (rowData) =>
        rowData.deadline ? rowData.deadline.substring(0, 10) : '',
      exportData: (rowData) =>
        rowData.deadline ? rowData.deadline.substring(0, 10) : '',
    },
  ];

  const [
    { displayRows, filteredRows },
    { setAllRows, setSearchTerm },
  ] = useMuiTableSearchAndPagination({
    initialColumns: columns,
    showAll: true,
  });

  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    setAllRows(itemsAvailableForInvoice);
  }, [itemsAvailableForInvoice]);

  const handleCreatePdf = () => {
    submitCreatePdf(selectedOrders, new Date(), 2);
    setSelectedOrders([]);
  };

  return (
    <Paper
      className={classes.tableContainer}
      style={{
        marginTop: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          onClick={handleCreatePdf}
          variant='contained'
          color='primary'
          className={classes.createDeliveryOrder}
          disabled={isEmptyValue(selectedOrders)}
        >
          Create Receipt
        </Button>
        <SearchBar onSearch={setSearchTerm} useDebounceSearch />
        <ExportCsvButton
          iconOnly
          border={false}
          customStyle={classes.customExportSvg}
          handleClick={() =>
            exportCSV(columns, filteredRows, 'All Create Receipt', 'mui')
          }
        />
      </div>
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              if (column.hidden) {
                return <TableCell key={column.title}></TableCell>;
              }
              return (
                <TableCell
                  key={column.title}
                  style={{
                    top: 0,
                    color: colors.blue060,
                    fontSize: '11pt',
                    fontWeight: 600,
                    padding: '10px',
                  }}
                >
                  {column.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayRows.map((row) => {
            return (
              <TableRow
                selected={selectedOrders.includes(row.itemID)}
                key={`${row.itemID}-${row.quotationID}`}
                onClick={(e) => handleQuotationRowClick(e, row)}
                className={classes.tableRow}
              >
                {columns.map((column) => {
                  const renderFunc = column.render;
                  if (!renderFunc && !column.field) {
                    return null;
                  }
                  return (
                    <TableCell
                      key={row.field}
                      style={{
                        ...column.cellStyle,
                        width: column.width,
                        padding: '10px',
                      }}
                    >
                      {typeof renderFunc === 'function'
                        ? renderFunc(row)
                        : row[column.field]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {createPdfStatus === 'loading' && (
        <LoadingBackDropText
          open={createPdfStatus}
          text='Creating Receipt...'
        />
      )}
    </Paper>
  );
}

function mapStateToProps(state) {
  return {
    itemsAvailableForInvoice: state.items.itemsAvailableForInvoice,
    role: state.auth.user.role,
    createPdfStatus: state.pdf.createPdfStatus,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => {
      dispatch(getAllItemsAvailableForInvoice());
    },
    submitCreatePdf: (data, date, type) =>
      dispatch(createPdf({ itemIDs: data, date, type })),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(CreateReceiptMuiTable));
