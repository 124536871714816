import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import { colors } from '../../palette';
import SubDesc from '../SubDesc';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';
import { DISABLED_REASON_QUOTES } from '../../constants/quotationConstants';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  otherInput: {
    display: 'flex',
    marginLeft: '35px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: '10px',
    columnGap: '20px',
  },
  content: {
    display: 'flex',
    padding: '5px 0',
  },
  nameContent: {
    color: colors.projectJob,
    fontWeight: '600',
  },
  containerCheckbox: {
    backgroundColor: colors.lightGray,
    padding: '5px 0 5px 20px',
    borderRadius: '5px',
  },
}));

/**
 *
 * @param {object} props
 * @param {boolean} props.dialog
 * @param {() => void} props.handleClose
 * @param {() => void} props.onOk
 * @param {object} props.rowData
 */
function DisabledReasonPopup(props) {
  const classes = useStyles();

  const { dialog, handleClose, onOk, rowData } = props;

  const [selectedValues, setSelectedValues] = useState(
    rowData.disabledReason || []
  );
  const [otherValue, setOtherValue] = useState('');

  useEffect(() => {
    const checkOtherValue = selectedValues.find((value) =>
      value.includes('Others:')
    );
    if (checkOtherValue) {
      setOtherValue(checkOtherValue.substring(7));
    }
  }, []);

  const checkStatus = (item) => {
    const mapSelectedValues = selectedValues.map((value) => {
      if (value.includes('Others:')) {
        return 'Others';
      }
      return value;
    });
    return mapSelectedValues.find((value) => value === item);
  };

  const handleChangeCheckbox = (event) => {
    let item = event.target.name;
    let otherValueTemp = '';
    const mapSelectedValues = selectedValues.map((value) => {
      if (value.includes('Others:')) {
        otherValueTemp = value;
        return 'Others';
      }
      return value;
    });
    if (mapSelectedValues.includes(item)) {
      const newSelectedValue = mapSelectedValues
        .filter((value) => value !== item)
        .map((value) => {
          if (value === 'Others') {
            return otherValueTemp;
          }
          return value;
        });
      setSelectedValues(newSelectedValue);
    } else {
      if (item === 'Others') {
        item = `Others:${otherValue}`;
      }
      setSelectedValues([...selectedValues, item]);
    }
  };

  const handleChangeOtherValue = (inputValue) => {
    setOtherValue(inputValue);
    const checkOtherValue = selectedValues.find((value) =>
      value.includes('Others:')
    );
    if (checkOtherValue) {
      const filterSelectedValue = selectedValues.filter(
        (value) => !value.includes('Others:')
      );
      const newSelectedValue = [...filterSelectedValue, `Others:${inputValue}`];
      setSelectedValues(newSelectedValue);
    }
  };

  const handleSubmitButtonClick = () => {
    if (typeof onOk === 'function') {
      const filterEmptyOthersValue = selectedValues.filter(
        (value) => value !== 'Others:'
      );
      onOk({
        disabledReason: filterEmptyOthersValue,
        quotationIDs: rowData.quotationID,
      });
    }
  };

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <SubDesc content='Disabled Reason' />
      </DialogTitle>
      <div style={{ padding: '10px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <FormGroup style={{ paddingBottom: '20px' }}>
            {DISABLED_REASON_QUOTES.map((item, index) => (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkStatus(item)}
                      onChange={handleChangeCheckbox}
                      name={item}
                    />
                  }
                  label={item}
                />
                {item === 'Others' && checkStatus(item) && (
                  <Input
                    autoFocus
                    className={classes.otherInput}
                    value={otherValue}
                    onChange={({ target }) =>
                      handleChangeOtherValue(target.value)
                    }
                  />
                )}
              </div>
            ))}
          </FormGroup>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <WhiteButton
              onBtnClick={handleClose}
              btnContent='Cancel'
              size='small'
            />
            <Box className={classes.space}></Box>
            <BlueButton
              onBtnClick={handleSubmitButtonClick}
              btnContent='Submit'
            />
          </div>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default DisabledReasonPopup;
