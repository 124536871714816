import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { TextField, Typography } from '@material-ui/core';

import {
  FtrDropdown,
} from '../ftr-components';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    fontWeight: 600,
    color: colors.neutral040,
    width: "100%",
  },
  textfield: {
    fontWeight: 600,
    borderRadius: 10,
    color: '#4F4F4F',
    backgroundColor: '#F7F7F7',
  }
}));

function QuestionAnswerDropdown(props) {
  const classes = useStyles();

  const {
    id = 'question-answer-dropdown',
    question = '',
    answerOptions = [],
    updateAnswer,
  } = props;

  const [answer, setAnswer] = useState("");
  const [label, setLabel] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState("");

  const handleUpdateAnswer = () => {
    updateAnswer({
      question,
      answer: answer.key || "",
      details: details || "",
    });
  }

  useEffect(() => {
    setShowDetails(answer && answer.details);
  }, [answer]);

  useEffect(() => {
    handleUpdateAnswer();
    setLabel(answer.label);
  }, [answer, details]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography className={classes.inputLabel}>
        {question}
      </Typography>
      <FtrDropdown
        id={id}
        value={answer}
        handleChange={setAnswer}
        items={answerOptions}
        renderValue={(value) => value.display}
      />
      {showDetails && (
        <TextField
          placeholder={label}
          id={`${id}-details`}
          InputProps={{
            className: classes.textfield,
          }}
          value={details}
          variant="outlined"
          onChange={(event) => setDetails(event.target.value)}
          margin="dense"
          fullWidth
        />
      )}
    </div>
  );
}

export default QuestionAnswerDropdown;
