import React, { useEffect, useState, useReducer, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { isDate } from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  useMediaQuery,
} from '@material-ui/core';

import {
  FtrSmallButton,
  FtrTypography,
  FtrDropdownWithSearch,
  FtrTooltip
} from '../../components/ftr-components';

import { FormGroup } from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { FtrCheckboxHeader } from '../../components/ftr-components/table/FtrCheckboxHeader';
import FtrCheckboxRow from '../../components/ftr-components/table/FtrCheckboxRow';
import SingleImage from '../../components/images/SingleImage';
import { FlexRow } from '../../components/layouts/FlexLayouts';

import { getAllProjectsByUser, assignItemsToProject } from '../../apis/projectApi';

import usePageNavigation from '../../hooks/usePageNavigationHooks';

import { getUserIDSelector } from '../../selectors/userSelector';

import { isEmptyValue } from '../../utils/commonUtils';
import { isCustomMaterial } from '../../utils/inputUtils';
import { getSurfaceFinishWithCustomizationsText, is3DPTechnology, isPpeItem } from '../../utils/itemUtils';

import AppContext from '../../context/AppContext';

import { ITEM_STATUS_MAPPING } from '../../constants/itemStatus';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    maxWidth: '1000px',
  },
  container: {
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
    },
  },
  summaryItemName: {
    width: '100%',
    fontSize: '14px',
    display: 'block',
    fontWeight: 600,
    lineHeight: '19.07px',
    paddingLeft: '0.75rem',
    paddingBottom: '5px',
    color: '#565656',
  },
  summaryText: {
    paddingLeft: '0.75rem',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.07px',
    color: colors.neutral060,
    alignItems: 'left',
    justifyContent: 'left',
    wordBreak: 'break-word',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

export function PopupItemInfo({ item }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent='flex-start' alignItems='flex-start' border='2px solid grey' style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div className={classes.summaryItemName}>{item.name}</div>
      <Grid item xs={12} sm={5} md="auto" className={classes.summaryText}>
        {`${item.technology}`}
        {is3DPTechnology(item.technology) && `, ${item.threeDTechnology}`}
        {`, ${item.material}`}
        {isCustomMaterial(item.material) && `, ${item.otherMaterial}`}
        {!isEmptyValue(item.materialColor) && `, ${item.materialColor}`}
        {is3DPTechnology(item.technology) && !isEmptyValue(item.threeDInfill) && `, ${Number(item.threeDInfill * 100).toFixed(0)}%`}
        {is3DPTechnology(item.technology) && !isEmptyValue(item.threeDLayerThickness) && `, ${item.threeDLayerThickness.toFixed(2)}mm`}
        {(getSurfaceFinishWithCustomizationsText(item) !== 'NIL') && `, ${getSurfaceFinishWithCustomizationsText(item)}`}
        {`, Qty: ${item.quantity}`}
        {`, +/-${item.tolerance}mm`}
        {!isEmptyValue(item.remarks) && `, ${item.remarks}`}
        {`, ${isDate(item.expectedLeadTime) ? item.expectedLeadTime.toDateString() : "No Preference"}`}
        {!isEmptyValue(item.partApplication) && `, ${item.partApplication}`}
      </Grid>
    </Grid>
  );
}

function MovePartsPopup(props) {
  const classes = useStyles();

  const {
    setSnackbarMessage,
  } = useContext(AppContext);

  const {
    open,
    items = [],
    handleClose = () => { },
  } = props;

  const currentProjectID = items[0]?.projectID;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const userID = useSelector(getUserIDSelector);

  const {
    goToCustomerProject,
  } = usePageNavigation();

  const { data: allProjects } = useQuery('allProjects', () => getAllProjectsByUser(userID));
  const allProjectsDisplay = allProjects
    ?.filter((project) => project.projectID !== currentProjectID)
    .map((project) => ({
      key: project.projectID,
      display: project.name || project.projectID,
    }));

  const [
    localItemsState,
    updateLocalItemsState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      availableItems: [],
      unavailableItems: [],
    },
  );

  const [allFieldsSelected, setAllFieldsSelected] = useState(false);
  const [selectedParts, setSelectedParts] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    // separate available and unavailable items
    const { availableItems, unavailableItems } = items.reduce((acc, item) => {
      if (
        [
          ITEM_STATUS_MAPPING.QUOTES_AVAILABLE,
          ITEM_STATUS_MAPPING.SOURCING,
        ].includes(item.status)
        || (item.status === ITEM_STATUS_MAPPING.VERIFYING && !isPpeItem(item))
      ) {
        acc.availableItems.push(item);
      } else {
        acc.unavailableItems.push(item);
      }
      return acc;
    }, {
      availableItems: [],
      unavailableItems: [],
    });
    updateLocalItemsState({ availableItems, unavailableItems });
  }, [items]);

  const handleAllFieldsCheck = () => {
    const newValue = !allFieldsSelected;
    setAllFieldsSelected(newValue);
    if (newValue) {
      setSelectedParts(localItemsState.availableItems.map(item => item.itemID));
    } else {
      setSelectedParts([]);
    }
  }

  const handleItemCheck = (itemID) => {
    const newSelectedParts = [...selectedParts];
    if (newSelectedParts.includes(itemID)) {
      newSelectedParts.splice(newSelectedParts.indexOf(itemID), 1);
    } else {
      newSelectedParts.push(itemID);
    }
    setSelectedParts(newSelectedParts);
    setAllFieldsSelected(
      newSelectedParts.length === localItemsState.availableItems.length
      && localItemsState.availableItems.length > 0
    );
  }

  const handleMoveParts = () => {
    // move selected parts to selected project
    setWaiting(true);
    const assignPartBody = {
      itemIDs: selectedParts,
      projectID: selectedProject,
    };
    assignItemsToProject(assignPartBody)
      .then(() => {
        const message = selectedParts.length > 1
          ? 'Items have been moved successfully!'
          : 'Item has been moved successfully!';
        setSnackbarMessage(message);
        setWaiting(false);
        handleClose();
        goToCustomerProject(selectedProject);
      })
      .catch((err) => {
        const message = selectedParts.length > 1
          ? `Unable to move items! ${err.message}`
          : `Unable to move item! ${err.message}`;
        setSnackbarMessage(message);
        setWaiting(false);
      });
  }

  const render2DImage = (item) => {
    const imageUrl = item.imageFile || item.twoDImageUrl;
    return (
      <SingleImage
        url={imageUrl}
        width={70}
        height={70}
        noBorder
      />
    );
  };

  const renderItemInfo = (item) => {
    return (
      <PopupItemInfo item={item} />
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      scroll="paper"
      width="md"
      fullWidth
      fullScreen={isMobile}
    >
      <Close className={classes.closeIcon} onClick={handleClose} />
      <FtrTypography type='heading' fontSize='22' style={{ padding: '30px 0px 20px 40px' }}>
        Move Parts
      </FtrTypography>
      <DialogContent>
        <Grid
          container
          direction="row"
          spacing={1}
          style={{
            display: 'flex',
            borderRadius: '30px',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Grid
            item xs={12}
            sm={6}
            style={{
              borderRadius: '20px',
              marginRight: isMobile ? '0rem' : '2.5rem',
              marginBottom: isMobile ? '1.9rem' : '0rem',
            }}
          >
            <FtrCheckboxHeader
              checked={allFieldsSelected}
              onChange={handleAllFieldsCheck}
              title='Select Parts'
              disabled={localItemsState.availableItems.length === 0}
            />
            <FormGroup>
              {localItemsState.availableItems.map((item, index) => {
                const isLastItem = index === items.length - 1;
                const checked = selectedParts.includes(item.itemID);
                const key = `${item.itemID}-${checked}`;

                return (
                  <FtrCheckboxRow
                    key={key}
                    onChange={() => handleItemCheck(item.itemID)}
                    checked={checked}
                    isLastItem={isLastItem}
                  >
                    <FlexRow
                      style={{ width: '100%', padding: '0.5rem 0' }}
                    >
                      {render2DImage(item)}
                      {renderItemInfo(item)}
                    </FlexRow>
                  </FtrCheckboxRow>
                );
              })}
              {localItemsState.unavailableItems.map((item, index) => {
                const isLastItem =
                  index === localItemsState.unavailableItems.length - 1;
                const checked = selectedParts.includes(item.itemID);
                const key = `${item.itemID}-${checked}`;

                return (
                  <FtrTooltip
                    key={key}
                    description="This item cannot be moved as it is in a different stage."
                  >
                    <div>
                      <FtrCheckboxRow
                        onChange={() => handleItemCheck(item.itemID)}
                        isLastItem={isLastItem}
                        disabled
                      >
                        <FlexRow
                          style={{ width: '100%', padding: '0.5rem 0' }}
                        >
                          {render2DImage(item)}
                          {renderItemInfo(item)}
                        </FlexRow>
                      </FtrCheckboxRow>
                    </div>
                  </FtrTooltip>
                );
              })}
            </FormGroup>
          </Grid>
          <Grid
            item xs={12} sm={5}
            style={{
              borderRadius: '20px',
            }}>
            <FtrTypography>
              Select a project you want to move the item(s) to:
            </FtrTypography>
            <FtrDropdownWithSearch
              key='technology-dropdown'
              fullWidth
              value={selectedProject}
              handleChange={setSelectedProject}
              items={allProjectsDisplay}
              disabled={waiting}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FtrSmallButton
          color='blue'
          onClick={handleMoveParts}
          disabled={isEmptyValue(selectedParts) || isEmptyValue(selectedProject) || waiting}
          loading={waiting}
          style={{ margin: 16 }}
        >
          {/* {loading && <CircularProgress size={20} />} */}
          &nbsp;Proceed
        </FtrSmallButton>
      </DialogActions>
    </Dialog>
  );
}

export default MovePartsPopup;
