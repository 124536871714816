import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone'

import { FtrTypography } from './ftr-components';

import useDeviceCheck from '../hooks/useDeviceCheck';

import { isPdfFile } from '../utils/fileUtils';

import { colors } from '../palette';


// ----------------------------------------------------------------------------------

function DrawingDragDropStepOne(props) {
  const {
    id,
    contentStyle,
    handleFiles = () => { },
    ...rest
  } = props;

  const [isFilesValid, setIsFilesValid] = useState(true);

  const onDrop = useCallback((files) => {
    const notValid = files.some(file => !isPdfFile(file.name));
    if (notValid) {
      setIsFilesValid(false);
      setTimeout(() => setIsFilesValid(true), 2000);
      return;
    }
    handleFiles(files);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    onDrop,
  });

  const [{ isMobile }] = useDeviceCheck();

  const renderErrorText = () => {
    return (
      <FtrTypography
        type='body'
        fontSize='14'
        style={{ padding: '0.3rem 0', color: colors.neutral080 }}
      >
        Only PDF files accepted
      </FtrTypography>
    )
  }

  const renderDefaultText = () => {
    return (
      <FtrTypography
        type='body'
        fontSize='14'
        style={{ padding: '0.3rem 0', color: colors.neutral080 }}
      >
        Drag or{' '}
        <span style={{ color: colors.blue060, fontWeight: 700 }}>
          upload
        </span>
        {' '}technical drawing
      </FtrTypography>
    );
  }

  const renderDefaultComponent = () => {
    return (
      <div
        data-cy={`upload-technical-drawing-step-one-${id}`}
        style={{
          borderRadius: 20,
          width: 160,
          height: isMobile ? 80 : 120,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          verticalAlign: 'middle',
          alignItems: 'center',
          cursor: 'pointer',
          backgroundColor: isFilesValid
            ? 'inherit'
            : colors.neutral030,
          ...contentStyle,
        }}
      >
        <div
          style={{
            lineHeight: '18px',
            textAlign: 'center',
            width: 120,
          }}
        >
          {isFilesValid && renderDefaultText()}
          {!isFilesValid && renderErrorText()}
        </div>
      </div>
    );
  }

  return (
    <div
      {...getRootProps()}
      id={`upload-technical-drawing-step-one-${id}`}
      style={{
        borderRadius: 20,
        backgroundColor: isDragActive
          ? colors.blue010
          : colors.neutral010,
        boxShadow: '0px 2px 6px 0px #00000026',
      }}
      {...rest}
    >
      <input {...getInputProps()} />
      {renderDefaultComponent()}
    </div>
  )
}

export default DrawingDragDropStepOne;
