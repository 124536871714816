export const KEY_CONFIGURATION = {
    '3DP_SURFACE_FINISH_OPTIONS_CONFIGURATION': '3DP_SURFACE_FINISH_OPTIONS_CONFIGURATION',
    ADMIN_NOTIFICATION_MAIL_LIST: 'ADMIN_NOTIFICATION_MAIL_LIST',
    ADMIN_SUPPLIER_PPE_PARAMETERS: 'ADMIN_SUPPLIER_PPE_PARAMETERS',
    ERROR_NOTIFICATION_MAIL_LIST: 'ERROR_NOTIFICATION_MAIL_LIST',
    EXPIRING_ITEMS_START_DATE: 'EXPIRING_ITEMS_START_DATE',
    ITEM_USER_INPUT_CONFIGURATION: 'ITEM_USER_INPUT_CONFIGURATION',
    SUPPORTED_CURRENCY: 'SUPPORTED_CURRENCY',
    SURFACE_FINISH_COLORS_CONFIGURATION: 'SURFACE_FINISH_COLORS_CONFIGURATION',
    SURFACE_FINISH_OPTIONS_BY_TECHNOLOGY_CONFIGURATION: 'SURFACE_FINISH_OPTIONS_BY_TECHNOLOGY_CONFIGURATION',
    XERO_AUTHORIZATION_TOKEN: 'XERO_AUTHORIZATION_TOKEN',
    XERO_PAYMENT_ACCOUNT: 'XERO_PAYMENT_ACCOUNT',
    XERO_TAX_TYPE: 'XERO_TAX_TYPE',
    FILE_TYPES_CONFIGURATION: 'FILE_TYPES_CONFIGURATION',
    FE_FEATURE_FLAGS_CONFIGURATION: 'FE_FEATURE_FLAGS_CONFIGURATION',
    VERIFY_ITEMS_CHECKLIST_CONFIGURATION: 'VERIFY_ITEMS_CHECKLIST_CONFIGURATION',
    CAP_PLATFORM_FEE_CONFIGURATION: 'CAP_PLATFORM_FEE_CONFIGURATION',
    DEFAULT_NON_SGP_DELIVERY_FEE: 'DEFAULT_NON_SGP_DELIVERY_FEE',
    DELIVERY_SERVICE_FEE_MULTIPLIER: 'DELIVERY_SERVICE_FEE_MULTIPLIER',
}

export const FE_FEATURE_FLAGS_CONFIGURATION = {
  GLOBAL_TDE_SWITCH: 'GLOBAL_TDE_SWITCH',
  CAD_RENDERER_AINC: 'CAD_RENDERER_AINC',
  CAD_RENDERER_NATIVE: 'CAD_RENDERER_NATIVE',
  DFM_AUTOMATION_BUYER: 'DFM_AUTOMATION_BUYER',
};

