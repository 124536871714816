import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Add as AddIcon } from '@material-ui/icons';

import { ReactComponent as BackIcon } from '../../../../assets/icons/back_icon.svg';

import { colors } from '../../../../palette';

import { formatDate } from '../../../../utils/dateTimeUtils';
import {
  extractAndDecodeFileNameAndExtensionFromUrl,
  is2DGenerationSupport,
} from '../../../../utils/fileUtils';
import {
  isIDSame,
  showLabelPartsLibraryInImage,
} from '../../../../utils/partsLibraryUtils';

import {
  getCadPartS3Key,
  uploadFileToS3,
} from '../../../../services/s3Service';
import { convert2DImage } from '../../../../services/convert2DImageService';

import { updatePartsLibrarySingleData } from '../../../../actions';

import ImageWith3DViewer from '../../../../components/images/ImageWith3DViewer';
import WhiteDragDropV2 from '../../../../components/WhiteDragDropV2';
import {
  FlexRow,
  FlexRowEnd,
} from '../../../../components/layouts/FlexLayouts';
import { FtrBoldText, FtrButton } from '../../../../components/ftr-components';
import UploadProgressBar from '../UploadProgressBar';
import DfmAnalysis from '../../../../components/DfmDefects/DfmAnalysis';

import withLabelHoC from '../../../../components/images/withLabelHOC';

import useDeviceCheck from '../../../../hooks/useDeviceCheck';

import { TECHNOLOGY_OPTION_TYPE } from '../../../../constants/NewPartConstants';
import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../../../constants/configurations';
import AppContext from '../../../../context/AppContext';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    color: colors.neutral080,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  container: {
    display: 'flex',
    gap: 20,
  },
  name: {
    fontSize: '1rem',
  },
  detail: {
    fontSize: '0.9rem',
  },
  actionContainer: {
    [theme.breakpoints.down('md')]: {
      margin: '16px',
    },
  },
}));

const ImageWithLabel = withLabelHoC(ImageWith3DViewer);

function ManagePartsLibraryVersionsPopup(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [{ isTablet }] = useDeviceCheck();

  const { open, onClose, item, dfmDefect } = props;

  const { featureFlags } = useContext(AppContext);

  const project = useSelector((state) => state.partsLibrary?.data);
  const items = project?.items.filter((i) => isIDSame(i, item));

  const latestVersion = Math.max(...items.map((i) => i.version));
  const sortedItemVersion = items?.sort((a, b) => a.version - b.version);

  const [currentIndex, setCurrentIndex] = useState(0);

  // Progress bar
  const [numSuccessUploadedFiles, setNumSuccessUploadedFiles] = useState(0);
  const [numTotalFiles, setNumTotalFiles] = useState(0);

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [localItems, setLocalItems] = useState(sortedItemVersion);

  const hasEmptyData = localItems.some(
    (item) => !(item.id || item.itemLibraryID)
  );

  useEffect(() => {
    const items = project?.items.filter((i) => isIDSame(i, item));
    const sortedItemVersion = items?.sort((a, b) => a.version - b.version);
    setLocalItems(sortedItemVersion);
  }, [project?.items]);

  useEffect(() => {
    if (numSuccessUploadedFiles < numTotalFiles) {
      setShowProgressBar(true);
    } else {
      setShowProgressBar(false);
    }
  }, [numSuccessUploadedFiles, numTotalFiles]);

  const handleAddVersion = () => {
    setLocalItems((prev) => [...prev, { version: Number(latestVersion) + 1 }]);
    setCurrentIndex(items.length);
  };

  const handleDone = () => {
    onClose();
  };

  const handleFiles = async (newFiles) => {
    setNumSuccessUploadedFiles(0);
    setNumTotalFiles(0);

    Promise.all(
      Array.from(newFiles).map(async (file) => {
        const splitFilename = file.name.split('.');
        const fileExtension = splitFilename[splitFilename.length - 1];
        const newVersion = latestVersion + 1;
        const param = {
          ...item,
          twoDImageUrl: null,
          inUse: true,
          version: latestVersion + 1,
          isNewlyItem: true,
          itemLibraryID: item.itemLibraryID ? item.itemLibraryID : null,
          id: item.id ? item.id : null,
          status: null,
        };
        setNumTotalFiles((numTotalFiles) => numTotalFiles + 1);
        return uploadFileToS3(file, getCadPartS3Key(file)).then(
          async (data) => {
            setNumSuccessUploadedFiles(
              (numUploadedSuccessS3Files) => numUploadedSuccessS3Files + 1
            );

            const s3ObjectUrl = data.Location.split(' ').join('%20');
            const [fileName] = extractAndDecodeFileNameAndExtensionFromUrl(
              s3ObjectUrl
            );
            let newItems = project.items.map((newItem) => {
              if (isIDSame(newItem, param)) {
                return { ...newItem, inUse: false };
              }
              return newItem;
            });
            newItems.push({
              ...param,
              cadFile: s3ObjectUrl,
              name: fileName,
              is2DImageLoading: true,
            });
            dispatch(
              updatePartsLibrarySingleData(project.projectLibraryID, {
                items: newItems,
              })
            );
            if (is2DGenerationSupport(fileExtension)) {
              await convert2DImage({ file_url: s3ObjectUrl })
                .then((res) => {
                  const twoDImageUrl = res['s3_file_url'];
                  newItems = newItems.map((newItem) => {
                    if (
                      isIDSame(newItem, param) &&
                      newItem.version === newVersion
                    ) {
                      return { ...newItem, twoDImageUrl };
                    }
                    return newItem;
                  });
                  dispatch(
                    updatePartsLibrarySingleData(project.projectLibraryID, {
                      items: newItems,
                    })
                  );
                })
                .finally(() => {
                  newItems = newItems.map((newItem) => {
                    if (
                      isIDSame(newItem, param) &&
                      newItem.version === newVersion
                    ) {
                      return { ...newItem, is2DImageLoading: false };
                    }
                    return newItem;
                  });
                  dispatch(
                    updatePartsLibrarySingleData(project.projectLibraryID, {
                      items: newItems,
                    })
                  );
                });
            }
          }
        );
      })
    );
  };

  const renderVersionData = () => {
    return (
      <Box className={classes.container}>
        <ImageWithLabel
          isLoading={localItems[currentIndex]?.is2DImageLoading}
          cadFile={localItems[currentIndex]?.cadFile}
          twoDImageUrl={localItems[currentIndex]?.twoDImageUrl}
          version={localItems[currentIndex]?.version}
          label={showLabelPartsLibraryInImage(localItems[currentIndex]?.status)}
        />
        <Box>
          <Typography className={classes.name} variant='h6'>
            {localItems[currentIndex]?.name}
          </Typography>
          <Typography variant='h6' className={classes.detail}>
            Uploaded on{' '}
            {formatDate(localItems[currentIndex]?.dateCreated, 'DD/MM/YYYY')}
          </Typography>
          {localItems[currentIndex]?.ppeInformation && (
            <Typography variant='h6' className={classes.detail}>
              {localItems[currentIndex]?.ppeInformation?.boundingBoxX} x{' '}
              {localItems[currentIndex]?.ppeInformation?.boundingBoxY} x{' '}
              {localItems[currentIndex]?.ppeInformation?.boundingBoxZ} mm
            </Typography>
          )}
          <Typography variant='h6' className={classes.detail}>
            {localItems[currentIndex]?.technology}
          </Typography>
          <Typography variant='h6' className={classes.detail}>
            {localItems[currentIndex]?.material}
          </Typography>
          <Typography variant='h6' className={classes.detail}>
            {['NIL', null, undefined].includes(
              localItems[currentIndex]?.surfaceFinish
            )
              ? 'No Surface Finish'
              : localItems[currentIndex]?.surfaceFinish}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderNewVersion = () => {
    return (
      <>
        <WhiteDragDropV2
          fullWidth
          technology={TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE} // to allow all file types
          handleFiles={handleFiles}
          overrideStyle={{ width: 'auto' }}
          id='drag-manage-version-id'
          multiple={false}
        />
        <Collapse in={showProgressBar} className={classes.uploadProgressBar}>
          <UploadProgressBar
            numSuccessUploadedFiles={numSuccessUploadedFiles}
            numTotalFiles={numTotalFiles}
            key='progress-bar-manage-versions'
            id='progress-bar-manage-versions'
          />
        </Collapse>
        <Box className={classes.uploadedPartsContainer}></Box>
      </>
    );
  };

  const renderDfmFeedback = () => {
    if (!featureFlags?.config?.[FE_FEATURE_FLAGS_CONFIGURATION.DFM_AUTOMATION_BUYER] || dfmDefect?.output === undefined) {
      return null;
    }

    return (
      <>
        <FtrBoldText
          fontSize='16'
          style={{ fontWeight: 'bold', marginBottom: '10px' }}
        >
          DFM Analysis
        </FtrBoldText>
        <DfmAnalysis
          dfmDefectOutput={dfmDefect?.output?.defects}
          twoDImageUrl={localItems[currentIndex]?.twoDImageUrl}
          cadFile={localItems[currentIndex]?.cadFile}
          isLoading={dfmDefect?.output === 'loading'}
        />
      </>
    );
  };

  return (
    <Dialog
      open={open}
      width='lg'
      PaperProps={{
        style: {
          borderRadius: isTablet ? 0 : 30,
          padding: isTablet ? 0 : 16,
          minWidth: isTablet ? 'auto' : 700,
          minHeight: isTablet ? '100%' : 650,
          overflowY: 'clip',
        },
      }}
      fullScreen={isTablet}
    >
      <DialogTitle>
        {isTablet ? (
          <FlexRow>
            <IconButton
              aria-label='close'
              onClick={onClose}
              style={{ marginLeft: '-5px' }}
            >
              <BackIcon />
            </IconButton>
            <Typography className={classes.title} variant='h5'>
              Manage Versions
            </Typography>
          </FlexRow>
        ) : (
          <>
            <Typography className={classes.title} variant='h5'>
              Manage Versions
            </Typography>
            <IconButton
              aria-label='close'
              onClick={onClose}
              style={{
                position: 'absolute',
                right: 8,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginBottom: 20,
          }}
        >
          <Tabs
            value={currentIndex}
            onChange={(event, newIndex) => setCurrentIndex(newIndex)}
            variant='scrollable'
          >
            {localItems.map((item) => {
              return (
                <Tab
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.selectedTab,
                  }}
                  key={item?.version}
                  label={`Version ${item?.version}`}
                />
              );
            })}
          </Tabs>
          {!hasEmptyData && (
            <AddIcon
              style={{ cursor: 'pointer', color: colors.blue050 }}
              onClick={handleAddVersion}
            />
          )}
        </div>
        {localItems[currentIndex]?.name
          ? renderVersionData()
          : renderNewVersion()}
        <Box style={{ marginTop: '1rem' }}>{renderDfmFeedback()}</Box>
      </DialogContent>
      <FlexRowEnd className={classes.actionContainer}>
        <FtrButton color='blue' onClick={handleDone}>
          Done
        </FtrButton>
      </FlexRowEnd>
    </Dialog>
  );
}

export default ManagePartsLibraryVersionsPopup;
