import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { startCase } from 'lodash';

// Import material UI components
import {
  Container,
  Divider,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Import color palette
import { colors } from '../../palette';

// Import services and api
import {
  getFeatureFlags,
  updateConfiguration,
} from '../../apis/configurationApi';
import { FE_FEATURE_FLAGS_CONFIGURATION, KEY_CONFIGURATION } from '../../constants/configurations';
import { notifyError, notifySuccess } from '../../services/notificationService';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  clearPPECache: {
    width: '160px',
    background: colors.buttonColorBlueGradient,
    color: colors.fontWhite,
  },
}));

function FeatureFlagsSwitchForm() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { data: featureFlags, isSuccess } = useQuery('getAllFeatureFlags', () =>
    getFeatureFlags()
  );
  const { mutate } = useMutation((payloads) => updateConfiguration(payloads), {
    onSuccess: () => {
      notifySuccess('Data FE_FEATURE_FLAGS_CONFIGURATION updated successfully');
      queryClient.invalidateQueries('getAllFeatureFlags');
    },
    onError: () => {
      notifyError("Data FE_FEATURE_FLAGS_CONFIGURATION updated failed'");
    },
  });

  const onSwitchChange = (event) => {
    const { name, checked } = event.target;
    const newFeatureFlags = { ...featureFlags.config, [name]: checked };
    mutate({
      key: KEY_CONFIGURATION.FE_FEATURE_FLAGS_CONFIGURATION,
      value: newFeatureFlags,
    });
  };
  
  const HIDE_FLAGS = [FE_FEATURE_FLAGS_CONFIGURATION.CAD_RENDERER_AINC]

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant='h6'>Feature Flags On/Off Switch</Typography>
        <Divider />
      </div>
      <FormGroup row>
        {isSuccess &&
          Object.keys(featureFlags.config)
            .filter(flag => !HIDE_FLAGS.includes(flag))
            .map((option, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      checked={featureFlags.config[option]}
                      onChange={onSwitchChange}
                      name={option}
                      color='primary'
                    />
                  }
                  label={startCase(option)}
                />
              );
          })}
      </FormGroup>
    </Container>
  );
}

export default FeatureFlagsSwitchForm;
