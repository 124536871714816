import { lowerCase } from 'lodash';

import { REACT_APP_ENV } from '../constants';


// ------------------------------------------------------------------------------

const ENV_LOWER = lowerCase(REACT_APP_ENV);

/**
 * Google Tag Manager IDs
 */
export const GTM_ID_MAP = {
  BTN_CONTINUE_TO_QUOTE: `btn-continue-to-quote-${ENV_LOWER}`,
  BTN_ACCEPT_QUOTE: `btn-accept-quote-${ENV_LOWER}`,
  BTN_PROCEED_CREATE_PROJECT: `btn-proceed-create-project-${ENV_LOWER}`,
}
