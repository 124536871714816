import React, { useMemo, useReducer } from 'react';
import { lowerCase } from 'lodash';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import GreyButton from '../../components/buttons/GreyButton';
import BlueButton from '../../components/buttons/BlueButton';
import { FlexColumn, FlexRow } from '../../components/layouts/FlexLayouts';
import { FtrBoldText, FtrErrorText, FtrItalicText } from '../../components/ftr-components';

import { adminAddItemOrderReadyRequiredMaterials, adminUpdateItemOrderReadyRequiredMaterials } from '../../apis/orderReadyApi';

import { isEmptyValue } from '../../utils/commonUtils';

import { notifyOngoing, updateNotification } from '../../services/notificationService';

import { ORDER_READY_RESOURCE_TYPES } from '../../constants/orderReadyConstants';
import { validateAllowedFileTypesInput } from '../../utils/validators/formValidators';


// ------------------------------------------------------------------------------------------------

function AdminAddEditRequiredQCMaterialsPopup(props) {
  const SELECT_OPTIONS = [
    ORDER_READY_RESOURCE_TYPES.IMAGE,
    ORDER_READY_RESOURCE_TYPES.VIDEO,
    ORDER_READY_RESOURCE_TYPES.DOCUMENT,
  ]

  const DOCUMENT_FILE_TYPES = [
    '.pdf',
    '.step',
    '.stp',
    '.x_t',
    '.sldprt',
    '.3dxml',
    '.catpart',
    '.ipt',
    '.dxf',
    '.dwg',
    '.stl',
    '.obj',
    '.aml',
    '.3mf',
  ];

  const DEFAULT_LOCAL_STATE = {
    selectedOption: ORDER_READY_RESOURCE_TYPES.IMAGE,
    addPredefinedRequiredImages: [],
    selectedDocumentFileTypes: [...DOCUMENT_FILE_TYPES],
    formError: {},
  }

  const {
    open,
    onClose,
    loadOrderReadyInfo,
    selectedItemOrderReady,
    editMode,
    resource,
  } = props;

  const availablePredefinedImages = useMemo(() => {
    const imageResources = selectedItemOrderReady.resources?.filter(
      resource => lowerCase(resource.type).includes('image')
    ) || [];
    const existingImageResourceTypes = imageResources?.map(resource => resource.type);

    return Object.values(ORDER_READY_RESOURCE_TYPES).filter(
      type => lowerCase(type).includes('image')
        && !existingImageResourceTypes.includes(type)
        && lowerCase(type) !== 'image'
    );
  }, [selectedItemOrderReady?.resources]);

  const [
    localState,
    updateLocalState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return {
          ...DEFAULT_LOCAL_STATE,
        }
      }
      return { ...prev, ...next };
    },
    editMode !== true
      ? DEFAULT_LOCAL_STATE
      : {
        ...DEFAULT_LOCAL_STATE,
        selectedOption: resource.type,
        newVideoTitle: resource.type === ORDER_READY_RESOURCE_TYPES.VIDEO ? resource.title : null,
        newVideoDescription: resource.type === ORDER_READY_RESOURCE_TYPES.VIDEO ? resource.description : null,
        newDocumentTitle: resource.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT ? resource.title : null,
        newDocumentDescription: resource.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT ? resource.description : null,
        selectedDocumentFileTypes: resource.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT
          ? (resource.allowedFileTypes?.selectedFileTypes || [])
          : [...DOCUMENT_FILE_TYPES],
        customDocumentFileTypes: resource.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT
          ? (resource.allowedFileTypes?.customFileTypes || '')
          : '',
      },
  );

  const [
    formErrorState,
    updateFormErrorState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return {}
      }
      return { ...prev, ...next };
    },
    {
    },
  );

  const selectAllFileTypes = useMemo(() => {
    return DOCUMENT_FILE_TYPES.every(type => localState.selectedDocumentFileTypes.includes(type));
  }, [localState.selectedDocumentFileTypes]);

  const validateForm = () => {
    const {
      addPredefinedRequiredImages,
      newImageTitle,
      newImageDescription,
      newVideoTitle,
      newDocumentTitle,
      selectedDocumentFileTypes,
      customDocumentFileTypes,
    } = localState;

    if (
      localState.selectedOption === ORDER_READY_RESOURCE_TYPES.IMAGE
      && isEmptyValue(addPredefinedRequiredImages)
      && isEmptyValue(newImageTitle)
      && isEmptyValue(newImageDescription)
    ) {
      return false;
    }

    if (
      localState.selectedOption === ORDER_READY_RESOURCE_TYPES.VIDEO
      && isEmptyValue(newVideoTitle)
    ) {
      return false;
    }

    if (
      localState.selectedOption === ORDER_READY_RESOURCE_TYPES.DOCUMENT
      && (
        isEmptyValue(newDocumentTitle)
        || (isEmptyValue(selectedDocumentFileTypes) && isEmptyValue(customDocumentFileTypes))
      )
    ) {
      if (isEmptyValue(selectedDocumentFileTypes) && isEmptyValue(customDocumentFileTypes)) {
        updateFormErrorState({ allowedFileTypesRequired: true });
      }
      return false;
    }

    if (
      !isEmptyValue(customDocumentFileTypes)
      && !validateAllowedFileTypesInput(customDocumentFileTypes)
    ) {
      updateFormErrorState({ customDocumentFileTypes: true });
      return false;
    }

    return true;
  }

  const getRequestBody = () => {
    const {
      addPredefinedRequiredImages,
      newImageTitle,
      newImageDescription,
      newVideoTitle,
      newVideoDescription,
      newDocumentTitle,
      newDocumentDescription,
      selectedDocumentFileTypes,
      customDocumentFileTypes,
    } = localState;

    let body = {
      type: localState.selectedOption,
    };

    switch (localState.selectedOption) {
      case ORDER_READY_RESOURCE_TYPES.IMAGE:
        body = {
          ...body,
          addPredefinedRequiredImages,
          title: newImageTitle,
          description: newImageDescription,
        }
        break;
      case ORDER_READY_RESOURCE_TYPES.VIDEO:
        body = {
          ...body,
          title: newVideoTitle,
          description: newVideoDescription,
        }
        break;
      case ORDER_READY_RESOURCE_TYPES.DOCUMENT:
        body = {
          ...body,
          title: newDocumentTitle,
          description: newDocumentDescription,
          allowedFileTypes: {
            selectedFileTypes: selectedDocumentFileTypes,
            customFileTypes: customDocumentFileTypes,
          }
        }
        break;
    }

    return body;
  }

  const handleAdminAddRequiredMaterial = () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    const body = getRequestBody();

    const { itemOrderReadyID } = selectedItemOrderReady.info;

    const toastId = notifyOngoing(`Uploading...`);
    adminAddItemOrderReadyRequiredMaterials(itemOrderReadyID, body)
      .then(() => {
        loadOrderReadyInfo().then(() => {
          updateNotification(toastId, `Updated successfully.`, 'success');
        });
      })
      .catch(() => {
        updateNotification(toastId, `Updated failed.`, 'error');
      })
      .finally(() => {
        updateLocalState({
          ...DEFAULT_LOCAL_STATE,
        });
      });
    onClose();
  }

  const handleAdminUpdateRequiredMaterial = () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    const body = getRequestBody();
    body.resourceID = resource.resourceID;

    const { itemOrderReadyID } = selectedItemOrderReady.info;

    const toastId = notifyOngoing(`Uploading...`);
    adminUpdateItemOrderReadyRequiredMaterials(itemOrderReadyID, body)
      .then(() => {
        loadOrderReadyInfo().then(() => {
          updateNotification(toastId, `Updated successfully.`, 'success');
        });
      })
      .catch(() => {
        updateNotification(toastId, `Updated failed.`, 'error');
      })
      .finally(() => {
        updateLocalState({
          ...DEFAULT_LOCAL_STATE,
        });
      });
    onClose();
  }

  const renderOptions = () => {
    return (
      <FlexColumn style={{ marginBottom: '1.5rem', gap: 0 }}>
        <FtrBoldText fontSize='16'>
          Material Type
        </FtrBoldText>
        <RadioGroup
          row
          value={localState.selectedOption}
          onChange={(event) => {
            updateLocalState({
              selectedOption: event.target.value,
            });
          }}
        >
          {SELECT_OPTIONS.map(option => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio color='primary' />}
              label={option}
              disabled={editMode}
            />
          ))}
        </RadioGroup>
      </FlexColumn>
    );
  }

  const renderAddRequiredImages = () => {
    return (
      <>
        <FlexColumn>
          <FtrBoldText fontSize='16'>
            Select Image(s)
          </FtrBoldText>
          <FlexRow>
            {availablePredefinedImages.map(imageType => {
              return (
                <FormControlLabel
                  key={imageType}
                  control={
                    <Checkbox
                      name="required"
                      onChange={(event) => {
                        const { checked } = event.target;
                        if (checked) {
                          updateLocalState({
                            addPredefinedRequiredImages: [
                              ...localState.addPredefinedRequiredImages,
                              imageType,
                            ]
                          });
                        } else {
                          updateLocalState({
                            addPredefinedRequiredImages: [
                              ...localState.addPredefinedRequiredImages
                                .filter(type => type !== imageType),
                            ]
                          });
                        }
                      }}
                    />
                  }
                  label={imageType}
                />
              );
            })}
          </FlexRow>
          <FormControlLabel
            control={
              <Checkbox
                name="required"
                onChange={(event) => {
                  const { checked } = event.target;
                  updateLocalState({ addAnotherRequiredImage: checked });
                }}
                checked={localState.addAnotherRequiredImage}
              />
            }
            label="Add another required image"
          />
          <FlexColumn
            style={{
              paddingLeft: '0.5rem',
            }}
          >
            <TextField
              id="new-image-title"
              label="Image Title"
              variant="outlined"
              fullWidth
              size='small'
              disabled={!localState.addAnotherRequiredImage}
              onChange={(e) => updateLocalState({ newImageTitle: e.target.value })}
            />
            <TextField
              id="new-image-description"
              label="Image Description"
              multiline
              minRows={3}
              variant="outlined"
              fullWidth
              size='small'
              disabled={!localState.addAnotherRequiredImage}
              onChange={(e) => updateLocalState({ newImageDescription: e.target.value })}
            />
          </FlexColumn>
        </FlexColumn>
        <FlexRow style={{ marginTop: '1rem' }}>
          <FtrItalicText>
            You can upload placeholder image after click OK button
          </FtrItalicText>
        </FlexRow>
      </>
    );
  }

  const renderAddRequiredVideo = () => {
    return (
      <FlexColumn
        style={{
          paddingLeft: '0.5rem',
          gap: '1rem',
        }}
      >
        <TextField
          id="new-video-title"
          label="Video Title*"
          variant="outlined"
          fullWidth
          size='small'
          InputLabelProps={{
            shrink: true,
          }}
          value={localState.newVideoTitle}
          onChange={(e) => updateLocalState({ newVideoTitle: e.target.value })}
        />
        <TextField
          id="new-video-description"
          label="Video Description"
          multiline
          minRows={3}
          variant="outlined"
          fullWidth
          size='small'
          InputLabelProps={{
            shrink: true,
          }}
          value={localState.newVideoDescription}
          onChange={(e) => updateLocalState({ newVideoDescription: e.target.value })}
        />
      </FlexColumn>
    );
  }

  const renderAddRequiredDocument = () => {
    return (
      <FlexColumn
        style={{
          paddingLeft: '0.5rem',
          gap: '1rem',
        }}
      >
        <TextField
          id="new-document-title"
          label="Document Title*"
          variant="outlined"
          fullWidth
          size='small'
          InputLabelProps={{
            shrink: true,
          }}
          value={localState.newDocumentTitle}
          onChange={(e) => updateLocalState({ newDocumentTitle: e.target.value })}
        />
        <TextField
          id="new-document-description"
          label="Document Description"
          multiline
          minRows={3}
          variant="outlined"
          fullWidth
          size='small'
          InputLabelProps={{
            shrink: true,
          }}
          value={localState.newDocumentDescription}
          onChange={(e) => updateLocalState({ newDocumentDescription: e.target.value })}
        />
        <FlexColumn style={{ gap: 0 }}>
          <FlexRow>
            <FtrBoldText fontSize='16'>
              Allowed File Types:*
            </FtrBoldText>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAllFileTypes}
                  onChange={() => {
                    if (selectAllFileTypes) {
                      updateLocalState({ selectedDocumentFileTypes: [] });
                    } else {
                      updateLocalState({ selectedDocumentFileTypes: [...DOCUMENT_FILE_TYPES] });
                    }
                  }}
                />
              }
              label="Select All"
            />
          </FlexRow>
          <FlexRow
            style={{
              flexWrap: 'wrap',
              gap: 0,
              padding: '0 0.5rem',
            }}
          >
            {DOCUMENT_FILE_TYPES.map((fileType) => {
              return (
                <FormControlLabel
                  key={fileType}
                  control={
                    <Checkbox
                      checked={localState.selectedDocumentFileTypes.includes(fileType)}
                      onChange={(e) => {
                        const { checked } = e.target;
                        updateFormErrorState({});
                        if (checked) {
                          const selectedDocumentFileTypes = [...localState.selectedDocumentFileTypes, fileType];
                          updateLocalState({ selectedDocumentFileTypes });
                        } else {
                          const selectedDocumentFileTypes = localState.selectedDocumentFileTypes.filter(type => type !== fileType);
                          updateLocalState({ selectedDocumentFileTypes });
                        }
                      }}
                    />
                  }
                  label={fileType}
                />
              );
            })}
          </FlexRow>
          {formErrorState.allowedFileTypesRequired && (
            <FtrErrorText>Allowed File Types is required</FtrErrorText>
          )}
        </FlexColumn>
        <TextField
          id="custom-document-file-types"
          label="Custom Document File Types"
          variant="outlined"
          fullWidth
          size='small'
          placeholder='eg: .pdf,.doc,.docx,.xlsx'
          InputLabelProps={{
            shrink: true,
          }}
          value={localState.customDocumentFileTypes}
          onChange={(e) => {
            updateLocalState({ customDocumentFileTypes: e.target.value });
            updateFormErrorState({});
          }}
          error={formErrorState.customDocumentFileTypes}
          helperText={formErrorState.customDocumentFileTypes && 'Please enter valid format, eg: .pdf,.doc,.docx,.xlsx'}
        />
      </FlexColumn>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        Add additional QC material requirements
      </DialogTitle>
      <DialogContent dividers>
        {renderOptions()}
        {localState.selectedOption === ORDER_READY_RESOURCE_TYPES.IMAGE && renderAddRequiredImages()}
        {localState.selectedOption === ORDER_READY_RESOURCE_TYPES.VIDEO && renderAddRequiredVideo()}
        {localState.selectedOption === ORDER_READY_RESOURCE_TYPES.DOCUMENT && renderAddRequiredDocument()}
      </DialogContent>
      <DialogActions>
        <GreyButton
          btnContent="Cancel"
          onBtnClick={onClose}
        />
        <BlueButton
          btnContent={editMode ? 'UPDATE' : 'OK'}
          onBtnClick={editMode
            ? handleAdminUpdateRequiredMaterial
            : handleAdminAddRequiredMaterial
          }
        />
      </DialogActions>
    </Dialog>
  );
}

export default AdminAddEditRequiredQCMaterialsPopup;
