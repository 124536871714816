import React from 'react';

import FtrSvgImage from '../../images/FtrSvgImage';

import DownArrowIcon from '../../../assets/icons/down_arrow_icon.svg';


// ----------------------------------------------------------------------------------

function FtrDownArrowButton(props) {
  const {
    onClick = () => { },
  } = props;

  return (
    <FtrSvgImage
      src={DownArrowIcon}
      width={10}
      height={10}
      style={{
        justifyContent: 'end',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
}

export default FtrDownArrowButton;
