import React, { useEffect, useReducer, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles/index';
import { capitalize, get, lowerCase } from 'lodash';

import ColoredWordsSentence from '../components/ColoredWordsSentence';
import DeliveryInstructions from '../assets/img/order-ready-management-delivery-instructions.png';
import TruckIcon from "../assets/icons/truck.svg";
import DeliveryInstructionsVertical from '../assets/img/order-ready-management-delivery-instructions-vertical.png';
import {
  Edit as EditIcon,
} from "@material-ui/icons";
import "react-image-gallery/styles/css/image-gallery.css";

import {
  ArtTrack,
  Delete,
  ExpandMore,
  LocalShipping,
  Warning,
  AttachFile,
} from '@material-ui/icons';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import AdminAddEditRequiredQCMaterialsPopup from './project-order-ready/AdminAddEditRequiredQCMaterialsPopup';
import AdminUploadFilesTab from './project-order-ready/AdminUploadFilesTab';
import ManageSupplierInvoice from './project-order-ready/ManageSupplierInvoice';
import BlueButton from '../components/buttons/BlueButton';
import DownloadAllFilesButton from '../components/buttons/DownloadAllFilesButton';
import DownloadableFileWithActions from '../components/list-items/DownloadableFileWithActions';
import EmailStatusIcon from '../components/icons/EmailStatusIcon';
import ErrorLabel from '../components/labels/ErrorLabel';
import FileDownloadListDisplay from '../components/labels/FileDownloadListDisplay';
import FilesUploadButton from '../components/FilesUploadButton';
import ImageUpload from './orderReady/ImageUpload';
import OrderReadyStatusDisplay from './orderReady/OrderReadyStatusDisplay';
import OrderReadyTimeline from '../components/OrderReadyTimeline';
import OrmMaterialFileUploader from './project-order-ready/OrmMaterialFileUploader';
import ThankYouFeedbackPopup from '../components/popups/ThankYouFeedbackPopup';
import UserFeedbackPopup from '../components/popups/UserFeedbackPopup';
import WhiteButton from '../components/buttons/WhiteButton';
import YesNoPopup from '../components/popups/YesNoPopup';
import { FlexColumn, FlexRow } from '../components/layouts/FlexLayouts';
import { FtrBanner, FtrButton } from '../components/ftr-components';
import ItemCollectionNoticePopup from '../components/popups/ItemCollectionNoticePopup';
import MultiCheckoutDeliverPopup from '../components/popups/MultiCheckoutDeliverPopup';
import CollectedInfoPopup from '../components/popups/CollectedInfoPopup';
import { MCTIssueDOButton, MCTItemCollectedButton } from '../components/buttons/actionButtons/MCTActionButton';
import ImageWith3DViewer from '../components/images/ImageWith3DViewer';
import GuestLinkToPage from '../components/GuestLinkToPage';
import ShipmentTrackingIcon from '../components/icons/ShipmentTrackingIcon';

import { getUserInfo } from '../apis/userApi';
import { getFeedbackOfUsers } from '../apis/userFeedbackApi';
import { getItemsByIDList } from '../apis/itemApi';
import { generatePresignedUrlForImage } from '../apis/s3Api';
import {
  deleteOrderReadyResource,
  getProjectOrderReadyHistory,
  getProjectOrderReadyInfo,
  trackOrderReadyResourceDownload,
  updateItemOrderReadyStatus,
  updateOrderShipped,
  updateProjectOrderReadyInfo,
  upsertOrderReadyResource,
} from '../apis/orderReadyApi';
import { deleteItemCollectionNotice } from '../apis/itemCollectionNoticeApi';
import { generateItemCollectionNotice } from '../apis/pdfApi';
import { getSupplierTrackingsByItemIDs, getTrackingsByItemIDs } from '../apis/multiCheckoutApi';

import { useQueryParams } from '../hooks/useQueryParamsHook';

import { getSubmittedProjectOrderReadyCountAction } from '../actions/orderReady';

import { getUserSelector } from '../selectors/userSelector';

import { formatDateWithTime } from '../utils/dateTimeUtils';
import { isAdminOrHigherRole } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { itemProperties } from '../utils/itemUtils';
import { asyncMap } from '../utils/arrayUtils';
import {
  sortOrderReadyResource,
  uploadOrderReadyResource,
} from '../utils/orderReadyUtils';
import {
  extractFileNameFromUrlFull,
  extractFileNameWithoutTimestampFromUrl,
  isPdfFile,
  getFileExtension,
} from '../utils/fileUtils';
import { uncollectedItemsFilter, undeliveredItemsFilter } from '../utils/multiCheckoutTrackingUtils';

import {
  notifyError,
  notifyOngoing,
  notifySuccess,
  notifyWarning,
  updateNotification,
} from '../services/notificationService';

import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';
import { COUNTRY_NAMES } from '../constants/countryConstants';
import { USER_FEEDBACK_QUESTION_LIST } from '../constants/userFeedbackConstants';
import {
  DENIED_ADDITIONAL_RESOURCE_TYPES,
} from '../constants/orderReadyConstants';
import { FIFTY_MB_IN_BYTES } from '../constants/fileConstants';
import {
  ORDER_READY_RESOURCE_TYPES,
  ORDER_READY_REVIEW_COMMENTS,
  ORDER_READY_STATUS_MAPPING,
  ORDER_READY_STATUS,
  DELIVERY_STATUSES,
  SHIPMENT_PROVIDERS,
} from '../constants/orderReadyConstants';
import {
  QC_NO_QC_REPORT,
  QC_REPORT_TEMPLATE_URL,
} from '../constants/projectConstants';
import {
  IMG_PLACE_HOLDER_URL,
  ROLE_TYPES,
} from '../constants';
import { GUEST_PERMISSIONS } from '../constants/authConstants';

import { colors } from '../palette';

// --------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: '1.6rem',
    paddingTop: '1.2rem',
  },
  heading: {
    fontWeight: 'bold',
  },
  tabRoot: {
    opacity: 1,
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
  disabledTab: {
    color: colors.lightGray,
  },
  iconLabelWrapper: {
    flexDirection: "row",
    gap: 10,
    '& :nth-child(1)': {
      marginTop: 6,
    }
  },
  labelContainer: {
    width: "auto",
    padding: 0
  },
  labelIcon: {
    minHeight: 0,
    paddingTop: 4,
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
    color: 'inherit',
  },
  container: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    gap: '0.5rem',
  },
  accordionSummaryContent: {
    margin: '12px 0 !important',
  },
  editShipmentNotes: {
    marginRight: '10px',
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const TAB_KEYS = {
  REVIEW: 'Review',
  DELIVER: 'Deliver',
  ADMIN_UPLOAD_FILES: 'Admin Upload Files',
}

const TABS = [
  TAB_KEYS.REVIEW,
  TAB_KEYS.DELIVER,
  TAB_KEYS.ADMIN_UPLOAD_FILES,
];

function CloneProps(props) {
  const { children, ...other } = props;
  return children(other);
}

function ProjectOrderReady(props) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const queryParams = useQueryParams();
  const dispatch = useDispatch();

  const {
    userID,
    role: userRole,
  } = useSelector(getUserSelector);

  const {
    match,
  } = props;
  const { projectID } = match.params;

  const supplierID = queryParams.get('supplierID') || userID;
  const poAcknowledgedVersion = queryParams.get('version') || 1;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      addAnotherRequiredImage: false,
      addPredefinedRequiredImages: [],
      additionalComment: {},
      adminEditStatus: '',
      comments: {},
      deleteItemCollectionNotice: null,
      deleteResource: null,
      errors: {},
      existingShipmentNotes: null,
      hasQcReportsRequired: false,
      imageUrlList: [],
      isNonSgpSupplier: false,
      itemOrderReadyList: [],
      itemCollectionNotices: [],
      itemsData: [],
      multipleCheckoutTrackings: [],
      newImageDescription: '',
      newImageTitle: '',
      orderReadyHistory: [],
      projectOrderReadyID: null,
      projectOrderReadyInfo: {},
      selectedItemOrderReady: null,
      selectedTab: 0,
      persistSelectedTab: false,
      shipmentNotes: null,
      showAdminEditStatusPopup: false,
      showDeleteFilePopup: false,
      showEditShippingNotesContainer: false,
      showImageIndex: 0,
      showImageView: false,
      supplierInvoicesInfo: [],
      updatingItemOrderReadyStatus: false,
    }
  );

  const [
    requiredQcMaterialState,
    updateRequiredQcMaterialState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return {
          open: false,
        }
      }
      return { ...prev, ...next };
    },
    {
      showPopup: false,
      selectedItemOrderReady: null,
      editMode: false,
      resource: null,
    },
  );

  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [showThankYouFeedbackPopup, setShowThankYouFeedbackPopup] = useState(false);
  const [showItemCollectionPopup, setShowItemCollectionPopup] = useState(false);
  const [showDeliverPopup, setShowDeliverPopup] = useState(false);
  const [showCollectedInfoPopup, setShowCollectedInfoPopup] = useState(false);
  const [fileUrlList, setFileUrlList] = useState([]);
  const [itemFilesMap, setItemFilesMap] = useState({});
  const [orderReadyLoading, setOrderReadyLoading] = useState(false);
  const [selectedMultipleCheckoutTracking, setSelectedMultipleCheckoutTracking] = useState({});

  const areSomeItemsDelivered = localState.itemOrderReadyList.some(orderReady => ORDER_READY_STATUS.DELIVERED === orderReady.info.status);

  const loadOrderReadyInfo = async () => {
    const allProjectOrderReadyInfo = await getProjectOrderReadyInfo({
      projectID,
      supplierID,
      poAcknowledgedVersion
    });
    const projectOrderReadyInfo = allProjectOrderReadyInfo.projectOrderReady;
    const projectOrderReadyID = get(projectOrderReadyInfo, ['info', 'projectOrderReadyID']);
    const orderReadyHistory = await getProjectOrderReadyHistory({
      projectOrderReadyID,
    });
    const projectOrderReadyStatus = get(projectOrderReadyInfo, ['info', 'status']);
    const shipmentNotes = localState.shipmentNotes || get(projectOrderReadyInfo, ['info', 'shipmentNotes']);
    const existingShipmentNotes = shipmentNotes;
    const itemOrderReadyList = allProjectOrderReadyInfo.itemOrderReady;

    const projectQcReports = get(projectOrderReadyInfo, ['info', 'qcReports', 'main']);
    const projectQcReportsAddOns = get(projectOrderReadyInfo, ['info', 'qcReports', 'addOns']);
    const hasQcReportsRequired = !isEmptyValue(projectQcReports) && projectQcReports !== QC_NO_QC_REPORT;
    const allItemsVerified = itemOrderReadyList.some(ior => ORDER_READY_STATUS.VERIFIED === ior.info.status)
      && itemOrderReadyList.every(ior => [ORDER_READY_STATUS.VERIFIED, ...DELIVERY_STATUSES].includes(ior.info.status));
    const hasItemNotStarted = itemOrderReadyList.some(ior => [ORDER_READY_STATUS.NOT_STARTED, ORDER_READY_STATUS.NEED_UPDATE].includes(ior.info.status));
    const deliverFiles = (projectOrderReadyInfo.resources || []).filter(resource => [ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER, ORDER_READY_RESOURCE_TYPES.WAYBILL].includes(resource.type));
    const hasDownloadDeliverFiles = !isEmptyValue(deliverFiles) && deliverFiles.every(resource => resource.downloadCount > 0);
    const noDeliveryDocRequired = Boolean(get(projectOrderReadyInfo, ['info', 'noDeliveryDocRequired']));
    const hasItemVerified = itemOrderReadyList.some(ior => ![ORDER_READY_STATUS.NOT_STARTED, ORDER_READY_STATUS.NEED_UPDATE, ORDER_READY_STATUS.SUBMITTED].includes(ior.info.status));
    const deliverTabWarning = hasItemVerified === true && noDeliveryDocRequired === false && deliverFiles.length <= 0 && isAdminOrHigherRole(userRole);
    const itemCollectionNotices = allProjectOrderReadyInfo.itemCollectionNotices;
    const supplierInvoicesInfo = allProjectOrderReadyInfo.supplierInvoicesInfo;

    const areAllItemsInDeliveryState = itemOrderReadyList.every(orderReady => DELIVERY_STATUSES.includes(orderReady.info.status));
    const isProjectInDeliveryState = DELIVERY_STATUSES.includes(projectOrderReadyStatus);
    /**
     * disable delivery tab if
     * - it's supplier platform and
     * - no itemOrderReady has status ready_to_ship or in_transit
     * - there is no delivery documents uploaded and the noDeliveryDocRequired is still set false value
     * - or project status is not ready_to_ship or in_transit
     */
    const disableDeliverTab = !isAdminOrHigherRole(userRole)
      && (!areAllItemsInDeliveryState || !isProjectInDeliveryState
        || (noDeliveryDocRequired === false && deliverFiles.length <= 0)
      );

    updateLocalState({
      projectOrderReadyID,
      projectOrderReadyInfo,
      projectOrderReadyStatus,
      itemOrderReadyList,
      itemCollectionNotices,
      orderShipmentInfo: allProjectOrderReadyInfo.orderShipmentInfo,
      noDeliveryDocRequired,
      orderReadyHistory,
      allItemsVerified,
      disableDeliverTab,
      projectQcReports,
      projectQcReportsAddOns,
      hasQcReportsRequired,
      hasDownloadDeliverFiles,
      deliverTabWarning,
      hasItemNotStarted,
      errors: {},
      // auto switch to deliver tab if projectOrderReadyStatus and itemOrderReadyStatus are delivery statuses for the first render,
      // persist for subsequent renders
      selectedTab: localState.persistSelectedTab ? localState.selectedTab : Number(isProjectInDeliveryState && areAllItemsInDeliveryState),
      persistSelectedTab: true,
      shipmentNotes,
      showEditShippingNotesContainer: false,
      supplierInvoicesInfo,
      existingShipmentNotes,
    });
  }

  useEffect(() => {
    if (!isEmptyValue(projectID)) {
      setOrderReadyLoading(true);
      loadOrderReadyInfo()
        .then(() => setOrderReadyLoading(false));
    }
  }, [projectID]);

  useEffect(() => {
    if (isEmptyValue(supplierID)) {
      return;
    }
    getUserInfo(supplierID)
      .then(data => {
        const isNonSgpSupplier = data.country !== COUNTRY_NAMES.SINGAPORE;
        updateLocalState({
          isNonSgpSupplier,
        });

        // set default shipment notes
        if (localState.projectOrderReadyStatus === ORDER_READY_STATUS.VERIFIED) {
          const notes = isNonSgpSupplier
            ? 'Ship to 81 Ayer Rajah Crescent, #01-54, Singapore 139967'
            : 'Self-collection by courier on (date), (time range)';
          updateLocalState({
            shipmentNotes: localState.shipmentNotes ?? notes,
          });
        }
      });
  }, [supplierID, localState.projectOrderReadyStatus]);

  // Items from same project can be spread apart multiple MCTs
  // and be spread across multiple ORMs too. Thus, items in the same MCT can belong to different suppliers
  useEffect(() => {
    if (isEmptyValue(localState.itemOrderReadyList)) {
      return;
    }
    const itemIDList = localState.itemOrderReadyList.map(or => or.info.itemID);

    const isMctItemInOrm = (item) => itemIDList.includes(item.itemID);

    const findMatchingItemOrderReady = itemID => {
      return localState.itemOrderReadyList.find(
        itemOrderReady => itemOrderReady.info.itemID === itemID
      );
    };

    const isAcceptedItemVerified = acceptedItem => {
      const matchingItemOrderReady = findMatchingItemOrderReady(acceptedItem.itemID);
      return {
        ...acceptedItem,
        isVerified: matchingItemOrderReady.info.status === ORDER_READY_STATUS.VERIFIED,
      };
    };

    const updateAcceptedItems = tracking => ({
      ...tracking,
      acceptedItems: tracking.acceptedItems
        .filter(isMctItemInOrm)
        .map(isAcceptedItemVerified),
    });

    const trackingsPromise = isAdminOrHigherRole(userRole)
      ? getTrackingsByItemIDs(itemIDList)
      : getSupplierTrackingsByItemIDs(itemIDList);

    trackingsPromise
      .then(trackings => {
        const trackingsWithInfo = trackings.map(updateAcceptedItems);
        updateLocalState({ multipleCheckoutTrackings: trackingsWithInfo });
      })
  }, [userRole, localState.itemOrderReadyList]);

  useEffect(() => {
    // get items info and prepare download all files url if admin or higher role
    if (!isAdminOrHigherRole(userRole) || isEmptyValue(localState.itemOrderReadyList)) {
      return;
    }
    const itemIDList = localState.itemOrderReadyList.map(or => or.info.itemID);
    getItemsByIDList(itemIDList)
      .then(async items => {
        let allProjectDownloadFileUrlsList = [];
        if (!isEmptyValue(items)) {
          const {
            itemFilesList,
            itemFilesMap,
          } = items.reduce((acc, item) => {
            const urlList = item.cadFile?.split(",").map((url) => ({
              url,
              subFolder: `part-${item.itemID}`,
            })) ?? [];
            acc.itemFilesList = [...acc.itemFilesList, ...urlList];
            acc.itemFilesMap[item.itemID] = item.cadFile?.split(",").filter(url => isPdfFile(url));
            return acc;
          }, {
            itemFilesList: [],
            itemFilesMap: {},
          });
          allProjectDownloadFileUrlsList = itemFilesList;
          setFileUrlList(allProjectDownloadFileUrlsList);
          setItemFilesMap(itemFilesMap);
          updateLocalState({
            itemsData: items.map(item => {
              const quotationID = localState.itemOrderReadyList.find(itemOrderReady =>
                itemOrderReady.info.itemID === item.itemID).info.quotationID;
              return {
                ...item,
                quotationID,
              }
            })
          })
        }
      });
  }, [userRole, localState.itemOrderReadyList]);

  const handleUploadOrderReadyResource = (
    {
      itemOrderReadyID,
      resourceID,
      file,
      resourceType,
    }
  ) => {
    const porID = localState.projectOrderReadyID;
    uploadOrderReadyResource({ itemOrderReadyID, resourceID, file, resourceType, porID })
      .then(loadOrderReadyInfo);
  }

  const handleItemOrderReadySubmit = (itemOrderReadyID) => {
    const itemOrderReady = localState.itemOrderReadyList.find(itemOrderReady => {
      return itemOrderReady.info.itemOrderReadyID === itemOrderReadyID;
    });

    const requiredImageResources = itemOrderReady.resources.filter(resource => lowerCase(resource.type).includes('image') && Boolean(resource.required) === true);
    for (const imageResource of requiredImageResources) {
      if (imageResource.fileURL === IMG_PLACE_HOLDER_URL || imageResource.fileURL.includes('2dImgFiles')) {
        const message = `${capitalize(imageResource.type.replace(/_/g, ' '))} is required`;
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              requiredImages: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    const requiredVideoResources = itemOrderReady.resources.filter(resource =>
      resource.type === ORDER_READY_RESOURCE_TYPES.VIDEO
      && Boolean(resource.required) === true
    );
    for (const videoResource of requiredVideoResources) {
      if (typeof videoResource?.fileURL === 'undefined' || videoResource?.fileURL === IMG_PLACE_HOLDER_URL) {
        const message = `${capitalize(videoResource.type.replace(/_/g, ' '))} is required`;
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              requiredImages: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    const requiredDocumentResources = itemOrderReady.resources.filter(resource =>
      resource.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT
      && Boolean(resource.required) === true
    );
    for (const documentResource of requiredDocumentResources) {
      if (typeof documentResource?.fileURL === 'undefined' || documentResource?.fileURL === IMG_PLACE_HOLDER_URL) {
        const message = `${capitalize(documentResource.type.replace(/_/g, ' '))} is required`;
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              requiredImages: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    if (localState.hasQcReportsRequired) {
      const hasQcReportUploaded = itemOrderReady.resources.some(resource => resource.type === ORDER_READY_RESOURCE_TYPES.QC_REPORT);
      if (!hasQcReportUploaded) {
        const message = 'QC Report is required';
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              qcReports: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    if (itemOrderReady.info.requireDesignFiles === 1) {
      const hasDesignFileUploaded = itemOrderReady.resources.some(resource => resource.type === ORDER_READY_RESOURCE_TYPES.DESIGN_FILE);
      if (!hasDesignFileUploaded) {
        const message = 'Design Files required';
        updateLocalState({
          errors: {
            [itemOrderReadyID]: {
              designFiles: message,
            },
          },
        });
        notifyError(message);
        return;
      }
    }

    const body = {
      status: ORDER_READY_STATUS.SUBMITTED,
    }
    updateLocalState({ updatingItemOrderReadyStatus: true });

    const toastId = toast('Submitting item order ready for review...', { type: toast.TYPE.INFO, autoClose: false });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            toast.update(toastId, {
              render: 'Item order ready submitted successfully.',
              type: toast.TYPE.SUCCESS,
              autoClose: 3000,
            });
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        toast.update(toastId, {
          render: 'Item order ready submitted failed.',
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  }

  const handleVerifyItemOrderReady = (itemOrderReadyID) => {
    const body = {
      status: ORDER_READY_STATUS.VERIFIED,
    }
    updateLocalState({ updatingItemOrderReadyStatus: true });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Item order ready is verified');
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        notifyError('Verifying Item order ready failed');
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  }

  const handleItemOrderReadyNeedUpdate = (itemOrderReadyID) => {
    updateLocalState({ updatingItemOrderReadyStatus: true });
    const body = {
      status: ORDER_READY_STATUS.NEED_UPDATE,
      comments: [...(localState.comments[itemOrderReadyID] || []), localState.additionalComment[itemOrderReadyID]],
    }
    updateLocalState({ updatingItemOrderReadyStatus: true });
    updateItemOrderReadyStatus(itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Item order ready status is updated');
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        notifyError('Update Item order ready status failed');
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  }

  const handleAdminEditStatus = () => {
    const body = {
      status: localState.adminEditStatus.status,
      adminManuallySwitchStatus: true,
    }
    updateItemOrderReadyStatus(localState.adminEditStatus.itemOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Successfully edited item status');
            updateLocalState({ updatingItemOrderReadyStatus: false });
          });
          if (isAdminOrHigherRole(userRole)) {
            dispatch(getSubmittedProjectOrderReadyCountAction());
          }
        }, 2000);
      })
      .catch(() => {
        notifyError('Edit item status failed');
        updateLocalState({ updatingItemOrderReadyStatus: false });
      });
  }

  const handleDeleteResourceFile = () => {
    deleteOrderReadyResource(
      localState.projectOrderReadyID,
      localState.deleteResource.resourceID
    ).then(() => {
      loadOrderReadyInfo();
      notifySuccess(`${localState.deleteResource.fileName} file is removed successfully`);
    }).catch(() => notifyError(`Failed to delete file ${localState.deleteResource.fileName}`));
  }

  const handleDeleteItemCollectionNotice = () => {
    deleteItemCollectionNotice(
      localState.deleteItemCollectionNotice.itemCollectionNotice.itemCollectionNoticeID
    ).then(() => {
      loadOrderReadyInfo();
      notifySuccess('Item collection notice is removed successfully');
    }).catch(() => notifyError('Failed to delete item collection notice.'));
  }

  const handleAddComment = (event, itemOrderReadyID) => {
    const { name, checked } = event.target;
    if (checked) {
      updateLocalState({
        comments: {
          ...localState.comments,
          [itemOrderReadyID]: [...(localState.comments[itemOrderReadyID] || []), name],
        }
      });
    } else {
      updateLocalState({
        comments: {
          ...localState.comments,
          [itemOrderReadyID]: (localState.comments[itemOrderReadyID] || []).filter(comment => comment !== name),
        }
      });
    }
  }

  const handleUpdateImageRequired = (event, resource) => {
    const { checked } = event.target;
    const body = {
      ...resource,
      required: checked,
    }
    const { projectOrderReadyID: porID } = resource;
    upsertOrderReadyResource(porID, body)
      .then(() => {
        loadOrderReadyInfo();
        notifySuccess('Updated successfully');
      }).catch(() => notifyError('Failed to updated.'));
  }

  const handleOrderShipped = () => {
    if (localState.isNonSgpSupplier) {
      if (isEmptyValue(localState.shipmentProvider)) {
        updateLocalState({
          errors: {
            shipmentProvider: 'Required',
          }
        });
        return;
      }
      if (localState.shipmentProvider === 'Other' && isEmptyValue(localState.otherShipmentProvider)) {
        updateLocalState({
          errors: {
            otherShipmentProvider: 'Required',
          }
        });
        return;
      }
      if (localState.shipmentTrackingNA !== true && isEmptyValue(localState.shipmentTrackingNumber)) {
        updateLocalState({
          errors: {
            shipmentTrackingNumber: 'Required',
          }
        });
        return;
      }
    }
    const projectOrderReadyID = localState.projectOrderReadyID;
    const itemOrderReadyList = localState.itemOrderReadyList || [];
    const itemOrderReadyIDList = itemOrderReadyList.map((itemOrderReady => itemOrderReady.info.itemOrderReadyID));
    let body = {
      itemOrderReadyIDList,
    }
    if (localState.isNonSgpSupplier) {
      body = {
        ...body,
        shipmentProvider: localState.shipmentProvider === 'Other'
          ? localState.otherShipmentProvider
          : localState.shipmentProvider,
        shipmentTrackingNA: localState.shipmentTrackingNA,
        shipmentTrackingNumber: localState.shipmentTrackingNumber,
      }
    }
    updateLocalState({ orderShippedProcessing: true });
    updateOrderShipped(projectOrderReadyID, body)
      .then(() => {
        setTimeout(() => {
          loadOrderReadyInfo().then(() => {
            notifySuccess('Updated successfully');
            updateLocalState({ orderShippedProcessing: false });
            if (userRole === ROLE_TYPES.SUPPLIER) {
              getFeedbackOfUsers(userID)
                .then(data => {
                  if (isEmptyValue(data)) {
                    setShowFeedbackPopup(true);
                  }
                });
            }
          });
        }, 2000);
      })
      .catch(() => {
        notifyError('Failed to updated.');
        updateLocalState({ orderShippedProcessing: false });
      });
  }

  const handleReadyToShipClick = () => {
    updateLocalState({ readyToShipProcessing: true });
    const toastId = toast('Ready to ship is processing...', { type: toast.TYPE.INFO, autoClose: false });
    updateProjectOrderReadyInfo(localState.projectOrderReadyID, {
      status: ORDER_READY_STATUS.READY_TO_SHIP,
      shipmentNotes: localState.shipmentNotes,
    }).then(() => {
      setTimeout(() => {
        loadOrderReadyInfo().then(() => {
          toast.update(toastId, {
            render: 'The partner has been notified successfully.',
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
          updateLocalState({ readyToShipProcessing: false });
        });
        if (isAdminOrHigherRole(userRole)) {
          dispatch(getSubmittedProjectOrderReadyCountAction());
        }
      }, 2000);
    }).catch(() => {
      toast.update(toastId, {
        render: 'Ready to ship processing failed.',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      updateLocalState({ readyToShipProcessing: false });
    });
  }

  const showImageListOfItemOrderReady = async (resources, resourceType) => {
    const imageResources = resources
      .filter(resource => isAdminOrHigherRole(userRole) || Boolean(resource.required) === true)
      .filter(resource => lowerCase(resource.type).includes('image'))
      .sort(sortOrderReadyResource);
    const imageUrlList = await asyncMap(imageResources, async (resource) => {
      const presignedUrl = await generatePresignedUrlForImage(resource.fileURL, 30 * 60); // 30 minutes in second
      return {
        original: presignedUrl,
        thumbnail: presignedUrl,
      }
    });
    const showImageIndex = imageResources.findIndex(resource => resource.type === resourceType);
    updateLocalState({
      imageUrlList,
      showImageView: true,
      showImageIndex,
    });
  }

  const renderStatusDropDown = (status, itemOrderReadyID, itemID) => {
    const handler = (newStatus) => {
      updateLocalState({
        adminEditStatus: {
          itemOrderReadyID,
          status: newStatus,
          itemID
        },
        showAdminEditStatusPopup: true
      });
    }
    return (
      <OrderReadyStatusDisplay status={status} onClick={handler} isAdminOrHigher={true} />
    )
  }

  const renderStatus = (status) => {
    return (
      <OrderReadyStatusDisplay status={status} />
    )
  }

  const renderProjectOrderReadyStatus = () => {
    return renderStatus(localState.projectOrderReadyStatus);
  }

  const renderResourceFileWithActions = (file, showDeleteButton = true) => {
    return (
      <DownloadableFileWithActions
        key={`${file.resourceID}-${file.downloadCount}`}
        fileUrl={file.fileURL}
        showDeleteButton={showDeleteButton}
        onDelete={(event) => {
          event.preventDefault();
          event.stopPropagation();
          updateLocalState({
            showDeleteFilePopup: true,
            deleteResource: {
              resourceID: file.resourceID,
              fileName: extractFileNameWithoutTimestampFromUrl(file.fileURL),
            },
          });
        }}
      />
    );
  }

  const renderTrackingDownloadResourceFileWithActions = (file, showDeleteButton = true, iconImage) => {
    return (
      <div
        key={`${file.resourceID}-${file.downloadCount}`}
      >
        <DownloadableFileWithActions
          iconImage={iconImage}
          fileUrl={file.fileURL}
          fileName={extractFileNameFromUrlFull(file.fileURL)}
          downloadCount={file.downloadCount}
          showDeleteButton={showDeleteButton}
          onDelete={(event) => {
            event.preventDefault();
            event.stopPropagation();
            updateLocalState({
              showDeleteFilePopup: true,
              deleteResource: {
                resourceID: file.resourceID,
                fileName: extractFileNameFromUrlFull(file.fileURL),
              },
            });
          }}
          postDownloadFunction={() => {
            if (!isAdminOrHigherRole(userRole)) {
              trackOrderReadyResourceDownload({ resourceID: file.resourceID })
                .then(() => {
                  setTimeout(() => {
                    loadOrderReadyInfo();
                  }, 1000);
                });
            }
          }}
        />
        {isAdminOrHigherRole(userRole) && (
          <Tooltip title="Number of times this document was downloaded by partner">
            <Typography
              style={{
                fontStyle: 'italic',
                marginLeft: '0.5rem',
              }}
              variant='caption'
            >
              Total download: {file.downloadCount}
            </Typography>
          </Tooltip>
        )}
      </div>
    );
  }

  const renderQcReportFiles = (resources) => {
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.QC_REPORT);
    if (isEmptyValue(fileList)) {
      return null;
    }
    return fileList.map((file) => renderResourceFileWithActions(file, true));
  }

  const renderDesignFiles = (resources) => {
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.DESIGN_FILE);
    if (isEmptyValue(fileList)) {
      return null;
    }
    return fileList.map((file) => renderResourceFileWithActions(file, true));
  }

  const renderDeliveryOrderFiles = () => {
    const resources = get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER);
    if (isEmptyValue(fileList)) {
      return null;
    }
    const showDeleteButton = isAdminOrHigherRole(userRole);
    return fileList.map((file) => renderTrackingDownloadResourceFileWithActions(file, showDeleteButton));
  }

  const renderItemCollectionNoticesFiles = () => {
    if (isEmptyValue(localState.itemCollectionNotices)) {
      return <div>No item collection notices have been generated yet.</div>;
    }
    return (<FlexColumn style={{ width: 'fit-content' }}>
      {localState.itemCollectionNotices.map((itemCollectionNotice) => {
        const fileName = `Factorem_ICN_Item${itemCollectionNotice.itemIDsStr.includes(',') ? 's ' : ' '}${itemCollectionNotice.itemIDsStr}`;
        return (
          <DownloadableFileWithActions
            key={itemCollectionNotice.itemCollectionNoticeID}
            showDeleteButton={isAdminOrHigherRole(userRole)}
            fullWidth={true}
            onDelete={(event) => {
              event.preventDefault();
              event.stopPropagation();
              updateLocalState({
                showDeleteFilePopup: true,
                deleteItemCollectionNotice: {
                  itemCollectionNotice,
                  fileName
                },
              });
            }}
            fileUrl={itemCollectionNotice.itemCollectionNoticeUrl}
            fileName={fileName}
          />
        )
      },
      )}
    </FlexColumn>);
  }

  const renderWaybillFiles = () => {
    const resources = get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const fileList = resources.filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.WAYBILL);
    const showDeleteButton = isAdminOrHigherRole(userRole);

    if (!isAdminOrHigherRole(userRole) && isEmptyValue(fileList)) {
      return null;
    }

    return (
      <div>
        <Typography className={classes.heading}>
          {isAdminOrHigherRole(userRole) ? 'Upload Waybill' : 'Waybill'}
        </Typography>
        {isAdminOrHigherRole(userRole) && (
          <div style={{ marginTop: '1rem' }}>
            <FilesUploadButton
              id="upload-way-bill"
              buttonText="Upload Waybill"
              handleUploadFiles={(files) => handleUploadOrderReadyResource({
                file: files[0],
                resourceType: ORDER_READY_RESOURCE_TYPES.WAYBILL,
              })}
              multiple={false}
            />
          </div>
        )}
        <div style={{ marginTop: '1rem' }}>
          {fileList.map((file) => renderTrackingDownloadResourceFileWithActions(file, showDeleteButton, TruckIcon))}
        </div>
      </div>
    );
  }

  const changeStatusWordColor = (note, status) => {
    switch (status) {
      case ORDER_READY_STATUS.NEED_UPDATE:
        return (
          <ColoredWordsSentence
            sentence={note}
            highlightWords={['Update', 'required']}
            color={colors.bgOrderNeedUpdate}
          />
        );
      default:
        return note;
    }
  }

  const renderOrderReadyHistoryUser = (history) => {
    if (!isAdminOrHigherRole(history.userRole)) {   // supplier logged history
      return history.companyName || capitalize(history.userRole);
    }

    // admin logged history
    return isAdminOrHigherRole(userRole)
      ? `Factorem (${history.userID})` // admin view
      : 'Factorem'; // supplier view
  }

  const renderItemOrderReadyList = () => {
    const itemOrderReadyList = localState.itemOrderReadyList || [];

    return (
      <div>
        {itemOrderReadyList.map((itemOrderReady, index) => {
          const itemOrderReadyID = itemOrderReady.info.itemOrderReadyID;
          const itemID = itemOrderReady.info.itemID;
          const itemOrderReadyStatus = itemOrderReady.info.status;

          const showVerifyButton = isEmptyValue(localState.comments[itemOrderReadyID])
            && isEmptyValue(localState.additionalComment[itemOrderReadyID]);

          const filteredImageResources = itemOrderReady.resources
            .filter(resource => lowerCase(resource.type).includes('image')
              && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole))
            )
            .sort(sortOrderReadyResource);

          const filteredVideoResources = itemOrderReady.resources
            .filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.VIDEO
              && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole))
            );

          const filteredDocumentResources = itemOrderReady.resources
            .filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.DOCUMENT
              && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole))
            );

          const additionalResources = itemOrderReady.resources
            .filter(resource => resource.type === ORDER_READY_RESOURCE_TYPES.ADDITIONAL_RESOURCE
              && (Boolean(resource.required) === true || isAdminOrHigherRole(userRole))
            );

          const noDocRequired = isEmptyValue(filteredImageResources)
            && localState.hasQcReportsRequired === false
            && itemOrderReady.info.requireDesignFiles === 0;

          const handleUploadAdditionalResource = (file) => {
            const fileExtension = getFileExtension(file.name);

            if (DENIED_ADDITIONAL_RESOURCE_TYPES.includes(fileExtension.toLocaleLowerCase())) {
              notifyError(`File type ${fileExtension} is not allowed`);
              return;
            }

            if (file.size > FIFTY_MB_IN_BYTES) {
              notifyError('File size should be less than 50 MB');
              return;
            }

            uploadOrderReadyResource({
              itemOrderReadyID,
              file,
              resourceType: ORDER_READY_RESOURCE_TYPES.ADDITIONAL_RESOURCE,
              porID: itemOrderReady.info.projectOrderReadyID,
            }).then(loadOrderReadyInfo);
          }

          const renderRequireUpdateButton = () => {
            if (showVerifyButton) {
              return null;
            }
            return (
              <Button
                style={{
                  backgroundColor: colors.warningYellow,
                }}
                variant="contained"
                component="span"
                onClick={() => handleItemOrderReadyNeedUpdate(itemOrderReadyID)}
                disabled={localState.updatingItemOrderReadyStatus === true}
              >
                {localState.updatingItemOrderReadyStatus === true && (
                  <CircularProgress className={classes.circularProgress} size={20} />
                )}
                Require Update
              </Button>
            );
          }

          const renderLatestUpdateNotes = () => {
            let filteredArray = localState.orderReadyHistory.filter(
              item => item.notes.includes(itemOrderReady.info.itemID)
                && (item.notes.includes("Update required")
                  || item.status === ORDER_READY_STATUS.NEED_UPDATE
                )
            );
            let history = filteredArray[filteredArray.length - 1];
            const name = renderOrderReadyHistoryUser(history)
            return (
              <Paper
                style={{
                  padding: '0.6rem',
                  margin: '0.3rem',
                }}
                key={`${history.notes} latest update`}
              >
                <Typography
                  className={classes.heading}
                  style={{
                    textAlign: 'left'
                  }}
                >
                  {formatDateWithTime(history.createdDate)}, {name}
                </Typography>
                {history.notes.split('\n').map((note, index) => {
                  return (
                    <Typography
                      key={note}
                      style={{
                        textAlign: 'left'
                      }}
                    >
                      {index === 0 ? changeStatusWordColor(note, history.status) : note}
                    </Typography>
                  );
                })}
              </Paper>)
          }

          const renderVerifyButton = () => {
            if (!showVerifyButton) {
              return null;
            }

            return (
              <Button
                style={{
                  backgroundColor: 'green',
                  color: 'white',
                }}
                variant="contained"
                component="span"
                onClick={() => handleVerifyItemOrderReady(itemOrderReadyID)}
                disabled={localState.updatingItemOrderReadyStatus === true}
              >
                {localState.updatingItemOrderReadyStatus === true && (
                  <CircularProgress className={classes.circularProgress} size={20} />
                )}
                Verify
              </Button>
            );
          }

          const renderAddRequiredMaterialsButton = () => {
            return isAdminOrHigherRole(userRole) &&
              (<div>
                <Button
                  style={{
                    marginTop: '0.5rem',
                  }}
                  color='primary'
                  variant='contained'
                  onClick={() => updateRequiredQcMaterialState({
                    showPopup: true,
                    selectedItemOrderReady: itemOrderReady,
                    editMode: false,
                    resource: null,
                  })}
                >
                  ADD ADDITIONAL QC MATERIAL
                </Button>
              </div>)
          }

          const renderPartImageUploadArea = () => {
            if (isEmptyValue(filteredImageResources)) {
              return null;
            }

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.3rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <Typography className={classes.heading}>Part Media</Typography>
                    <Typography>
                      Please upload mandatory media for <span className={classes.heading}>Part #{itemOrderReady.info.itemID}</span> as shown below.
                    </Typography>
                  </div>
                  {isAdminOrHigherRole(userRole) && (
                    <FileDownloadListDisplay
                      title="Design files"
                      urlList={itemFilesMap[itemID] ? itemFilesMap[itemID].slice(0, 1) : []}
                      showTitle={false}
                    />
                  )}
                </div>
                {!isEmptyValue(get(localState, ['errors', itemOrderReadyID, 'requiredImages'])) && (
                  <ErrorLabel message={get(localState, ['errors', itemOrderReadyID, 'requiredImages'])} />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    fontSize: '0.8rem',
                    flexDirection: isMobile ? 'column' : 'row',
                  }}
                >
                  {filteredImageResources.map(resource => {
                    const {
                      type: resourceType,
                      itemID,
                    } = resource;
                    const key = `itemID-${itemID}-resourceID-${resource.resourceID}-${resourceType}-image-upload-${resource.fileURL}`;
                    const imageURL = resource.fileURL;
                    const uploadAllowed = ![
                      ORDER_READY_STATUS.VERIFIED,
                      ORDER_READY_STATUS.READY_TO_SHIP,
                      ORDER_READY_STATUS.IN_TRANSIT,
                      ORDER_READY_STATUS.DELIVERED].includes(itemOrderReadyStatus)
                      || isAdminOrHigherRole(userRole);
                    return (
                      <div
                        key={key}
                        style={{
                          margin: '1rem',
                        }}
                      >
                        <Tooltip title={isEmptyValue(resource.description) ? '' : resource.description}>
                          <div
                            style={{
                              cursor: uploadAllowed ? 'pointer' : "cursor"
                            }}
                          >
                            <ImageUpload
                              type={resourceType}
                              id={key}
                              uploadAllowed={uploadAllowed}
                              onUpload={(file) => handleUploadOrderReadyResource({
                                resourceID: resource.resourceID,
                                itemOrderReadyID,
                                file,
                                resourceType,
                              })}
                              imageURL={imageURL}
                              placeHolderUrl={resource.placeHolderUrl}
                              onViewClick={() => showImageListOfItemOrderReady(itemOrderReady.resources, resourceType)}
                              showAlarm={imageURL === IMG_PLACE_HOLDER_URL || imageURL.includes('2dImgFiles')}
                            />
                          </div>
                        </Tooltip>
                        {
                          isAdminOrHigherRole(userRole) && (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="required"
                                    onChange={(event) => handleUpdateImageRequired(event, resource)}
                                    checked={resource.required}
                                  />
                                }
                                label="Required"
                              />
                              <Tooltip title="Delete required image that shows to supplier">
                                <IconButton
                                  edge='end'
                                  aria-label='delete'
                                  onClick={(event) => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    updateLocalState({
                                      showDeleteFilePopup: true,
                                      deleteResource: {
                                        resourceID: resource.resourceID,
                                        fileName: resource.type,
                                      },
                                    });
                                  }}
                                >
                                  <Delete color='error' style={{ fontSize: '15pt' }} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )
                        }
                      </div>
                    );
                  })}
                </div>
                {renderAddRequiredMaterialsButton()}
              </div>
            );
          }

          const renderRequiredQcReportTexts = (qcReports) => {
            return qcReports.map((qcReport, index) => {
              const templateUrl = QC_REPORT_TEMPLATE_URL[qcReport];
              let qcReportText;
              if (templateUrl) {
                qcReportText = (
                  <a href={templateUrl} target='_blank' rel="noreferrer">
                    {qcReport}
                  </a>
                );
              } else {
                qcReportText = qcReport;
              }
              return (
                <span key={templateUrl}>
                  {qcReportText}
                  {index === qcReports.length - 1 ? '.' : ', '}
                </span>
              );
            })
          }

          const renderQcReportUploadArea = () => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1rem',
                }}
              >
                <Typography className={classes.heading}>
                  QC Report{localState.hasQcReportsRequired === true && (
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  )}
                </Typography>
                {localState.hasQcReportsRequired === true && (
                  <div>
                    <Typography>
                      Please upload the Quality Check (QC) {localState.projectQcReportsAddOns.length > 0 ? 'Reports' : 'Report'} for <b>Part #{itemOrderReady.info.itemID}</b>
                    </Typography>
                    <Typography>
                      QC {localState.projectQcReportsAddOns.length > 0 ? 'Reports' : 'Report'} required: <b>{renderRequiredQcReportTexts([localState.projectQcReports, ...localState.projectQcReportsAddOns])}</b>
                    </Typography>
                    <div
                      style={{
                        padding: '0.5rem',
                      }}
                    >
                      <FilesUploadButton
                        id={`upload - qc - report - ${itemOrderReadyID}`}
                        buttonText="Upload QC Report"
                        handleUploadFiles={(files) => handleUploadOrderReadyResource({
                          itemOrderReadyID,
                          file: files[0],
                          resourceType: ORDER_READY_RESOURCE_TYPES.QC_REPORT,
                        })}
                        multiple={false}
                      />
                    </div>
                    {!isEmptyValue(get(localState, ['errors', itemOrderReadyID, 'qcReports'])) && (
                      <ErrorLabel message={get(localState, ['errors', itemOrderReadyID, 'qcReports'])} />
                    )}
                  </div>
                )}
                {localState.hasQcReportsRequired === false && (
                  <Typography>
                    No QC Report required.
                  </Typography>
                )}
                <div
                  style={{
                    padding: '0.5rem',
                  }}
                >
                  {renderQcReportFiles(itemOrderReady.resources)}
                </div>
              </div>
            );
          }

          const renderUploadAdditionalResourceButton = () => {
            return (
              <FlexColumn style={{ marginTop: '1rem' }}>
                <Typography className={classes.heading}>
                  Additional QC Material
                </Typography>
                <FilesUploadButton
                  buttonText="Upload Additional QC Material"
                  multiple={false}
                  handleUploadFiles={(files) => handleUploadAdditionalResource(files[0])}
                  note="Optional. Upload any other material that will help in verifying this order. Maximum file size 50MB. No .zip files."
                />
              </FlexColumn>
            );
          }

          const renderRequireDesignFilesButton = () => {
            return isAdminOrHigherRole(userRole) && (
              <FormControlLabel
                control={
                  <Checkbox
                    name="require-design-files"
                    onChange={(event) => {
                      const { checked } = event.target;
                      updateItemOrderReadyStatus(itemOrderReadyID, {
                        requireDesignFiles: checked
                      }).then(() => {
                        notifySuccess(`Updated successfully`);
                        loadOrderReadyInfo();
                      }).catch(() => {
                        notifyError(`Updated failed`);
                      });
                    }}
                    checked={Boolean(get(itemOrderReady, ['info', 'requireDesignFiles']))}
                  />
                }
                label="Require Design Files"
              />
            )
          }

          const renderDesignFileUploadArea = () => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '1rem',
                }}
              >
                <Typography className={classes.heading}>
                  Design Files{itemOrderReady.info.requireDesignFiles === 1 && (
                    <span style={{ color: 'red' }}>&nbsp;*</span>
                  )}
                </Typography>
                {itemOrderReady.info.requireDesignFiles === 1 && (
                  <div>
                    <Typography>
                      Please upload the Design Files for <b>Part #{itemOrderReady.info.itemID}</b>
                    </Typography>
                    <div
                      style={{
                        padding: '0.5rem',
                      }}
                    >
                      <FilesUploadButton
                        id={`upload - design - file - ${itemOrderReadyID}`}
                        buttonText="Upload Design Files"
                        handleUploadFiles={(files) => handleUploadOrderReadyResource({
                          itemOrderReadyID,
                          file: files[0],
                          resourceType: ORDER_READY_RESOURCE_TYPES.DESIGN_FILE,
                        })}
                        multiple={false}
                      />
                    </div>
                    {!isEmptyValue(get(localState, ['errors', itemOrderReadyID, 'qcReports'])) && (
                      <ErrorLabel message={get(localState, ['errors', itemOrderReadyID, 'qcReports'])} />
                    )}
                  </div>
                )}
                {itemOrderReady.info.requireDesignFiles === 0 && (
                  <Typography>
                    No design files required.
                  </Typography>
                )}
                <div
                  style={{
                    padding: '0.5rem',
                  }}
                >
                  {renderDesignFiles(itemOrderReady.resources)}
                </div>
              </div>
            );
          }

          const renderReviewArea = () => {
            const { itemOrderReadyID } = itemOrderReady.info;
            return itemOrderReadyStatus === ORDER_READY_STATUS.SUBMITTED
              && [ROLE_TYPES.ADMIN, ROLE_TYPES.SUPER_ADMIN].includes(userRole)
              && (
                <div
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  <Typography className={classes.heading}>Review</Typography>
                  <Typography>Please select or add comments for the part if there is any issue, otherwise click the Verify button to accept the order ready.</Typography>
                  <Box style={{ height: '0.3rem' }} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={ORDER_READY_REVIEW_COMMENTS.UPDATE_QC_REPORT}
                        onChange={(event) => handleAddComment(event, itemOrderReadyID)}
                      />
                    }
                    label={ORDER_READY_REVIEW_COMMENTS.UPDATE_QC_REPORT}
                    style={{
                      display: 'flex',
                      margin: '0 0.5rem 0.5rem 0',
                      backgroundColor: colors.paleBlue,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={ORDER_READY_REVIEW_COMMENTS.UPDATE_PART_IMAGES}
                        onChange={(event) => handleAddComment(event, itemOrderReadyID)}
                      />
                    }
                    label={ORDER_READY_REVIEW_COMMENTS.UPDATE_PART_IMAGES}
                    style={{
                      display: 'flex',
                      margin: '0 0.5rem 0.5rem 0',
                      backgroundColor: colors.paleBlue,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={ORDER_READY_REVIEW_COMMENTS.REWORK}
                        onChange={(event) => handleAddComment(event, itemOrderReadyID)}
                      />
                    }
                    label={ORDER_READY_REVIEW_COMMENTS.REWORK}
                    style={{
                      display: 'flex',
                      margin: '0 0.5rem 0.5rem 0',
                      backgroundColor: colors.paleBlue,
                    }}
                  />
                  <Box style={{ height: '0.5rem' }} />
                  <TextField
                    id="outlined-multiline-static"
                    label="Additional comment"
                    multiline
                    minRows={3}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateLocalState({
                      additionalComment: {
                        ...localState.additionalComment,
                        [itemOrderReadyID]: e.target.value,
                      },
                    })}
                  />
                </div>
              );
          }

          const renderPendingReviewPanel = () => {
            if (itemOrderReadyStatus !== ORDER_READY_STATUS.SUBMITTED
              || isAdminOrHigherRole(userRole)) {
              return;
            }

            return (
              <FtrBanner>
                <b>Pending review:</b> The order is being reviewed for shipment. You will be notified via email upon approval for the next steps.
              </FtrBanner>
            );
          }

          const renderVideoUploadArea = () => {
            return (
              <FlexColumn style={{ maxWidth: 500 }}>
                <Typography className={classes.heading}>Required Video(s)</Typography>
                {filteredVideoResources.map((resource) => {
                  return (
                    <OrmMaterialFileUploader
                      key={resource?.resourceID}
                      resource={resource}
                      loadOrderReadyInfo={loadOrderReadyInfo}
                      onEdit={() => {
                        updateRequiredQcMaterialState({
                          showPopup: true,
                          selectedItemOrderReady: itemOrderReady,
                          editMode: true,
                          resource,
                        });
                      }}
                    />
                  );
                })}
              </FlexColumn>
            );
          }

          const renderDocumentUploadArea = () => {
            return (
              <FlexColumn style={{ maxWidth: 500, marginTop: '0.5rem' }}>
                <Typography className={classes.heading}>Required Document(s)</Typography>
                {filteredDocumentResources.map((resource) => {
                  return (
                    <OrmMaterialFileUploader
                      key={resource?.resourceID}
                      resource={resource}
                      loadOrderReadyInfo={loadOrderReadyInfo}
                      onEdit={() => {
                        updateRequiredQcMaterialState({
                          showPopup: true,
                          selectedItemOrderReady: itemOrderReady,
                          editMode: true,
                          resource,
                        });
                      }}
                    />
                  );
                })}
              </FlexColumn>
            );
          }

          const renderAdditionalResources = () => {
            return (
              <FlexColumn style={{ maxWidth: 500, marginTop: '0.5rem' }}>
                {additionalResources.map((resource) => {
                  return (
                    <OrmMaterialFileUploader
                      key={resource?.resourceID}
                      resource={resource}
                      loadOrderReadyInfo={loadOrderReadyInfo}
                      onEdit={() => {
                        updateRequiredQcMaterialState({
                          showPopup: true,
                          selectedItemOrderReady: itemOrderReady,
                          editMode: true,
                          resource,
                        });
                      }}
                    />
                  );
                })}
              </FlexColumn>
            );
          }

          return (
            <Accordion
              key={itemOrderReady.info.itemID}
              defaultExpanded={index === 0 && itemOrderReadyStatus === ORDER_READY_STATUS.NOT_STARTED}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{
                  content: classes.accordionSummaryContent,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ImageWith3DViewer
                    twoDImageUrl={itemOrderReady.info.imageFile || itemOrderReady.info.twoDImageUrl}
                    cadFile={itemOrderReady.info.cadFile || itemOrderReady.info.originalFiles}
                    height={80}
                    width={80}
                    borderRadius={0}
                    noBorder={false}
                  />
                  <Box style={{ width: '1rem' }} />
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className={classes.heading}>Part #{itemOrderReady.info.itemID}</Typography>
                      <Box style={{ width: '1rem' }} />
                      {isAdminOrHigherRole(userRole)
                        ? (
                          <>
                            {renderStatusDropDown(itemOrderReadyStatus, itemOrderReady.info.itemOrderReadyID, itemOrderReady.info.itemID)}
                            <FlexRow style={{ marginLeft: '1rem' }}>
                              <EmailStatusIcon
                                date={itemOrderReady.brevoOrderReadyNeedUpdate?.updatedAt}
                                emailType={itemOrderReady.brevoOrderReadyNeedUpdate?.type}
                                status={itemOrderReady.brevoOrderReadyNeedUpdate?.lastStatus}
                              />
                              <EmailStatusIcon
                                date={itemOrderReady.brevoOrderReadyReadyToShip?.updatedAt}
                                emailType={itemOrderReady.brevoOrderReadyReadyToShip?.type}
                                status={itemOrderReady.brevoOrderReadyReadyToShip?.lastStatus}
                              />
                            </FlexRow>
                          </>
                        )
                        : renderStatus(itemOrderReadyStatus)}
                    </div>
                    <div style={{ marginTop: '3px' }}>{itemProperties(itemOrderReady.info)}</div>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Divider />
                <FlexColumn
                  style={{
                    marginTop: '1rem',
                  }}
                >
                  {ORDER_READY_STATUS.NEED_UPDATE === itemOrderReadyStatus && renderLatestUpdateNotes()}
                  {noDocRequired === true && (
                    <div>
                      <Typography>
                        There are no order ready documents required for this order.
                      </Typography>
                      {ORDER_READY_STATUS.NOT_STARTED === itemOrderReadyStatus && (
                        <Typography>
                          Please click <b>SUBMIT</b> to get shipment approval.
                        </Typography>
                      )}
                    </div>
                  )}
                  {noDocRequired !== true && renderPartImageUploadArea()}
                  {
                    //Even if there are no images, Admin should still be able to upload image
                    isEmptyValue(filteredImageResources) && renderAddRequiredMaterialsButton()
                  }
                  {filteredVideoResources.length > 0 && renderVideoUploadArea()}
                  {filteredDocumentResources.length > 0 && renderDocumentUploadArea()}
                </FlexColumn>
                {noDocRequired !== true && renderQcReportUploadArea()}
                {renderUploadAdditionalResourceButton()}
                {additionalResources.length > 0 && renderAdditionalResources()}
                {noDocRequired !== true && itemOrderReady.info.technology === TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE && renderDesignFileUploadArea()}
                {isAdminOrHigherRole(userRole) && itemOrderReady.info.technology === TECHNOLOGY_OPTION_TYPE.DESIGN_SERVICE && renderRequireDesignFilesButton()}
                {renderReviewArea()}
                {renderPendingReviewPanel()}
                <FlexRow
                  style={{
                    marginTop: '1rem',
                    padding: '0.5rem',
                    flexDirection: 'row-reverse',
                  }}
                >
                  {[ORDER_READY_STATUS.NOT_STARTED, ORDER_READY_STATUS.NEED_UPDATE].includes(itemOrderReadyStatus) && (
                    <Tooltip
                      title={Boolean(itemOrderReady.info.poAcknowledged) !== true
                        ? 'Upon clicking, your order will be reviewed for shipment. PO needs to be acknowledged to enable this button.'
                        : 'Upon clicking, your order will be reviewed for shipment.'
                      }
                    >
                      <div>
                        <Button
                          id={`submit-itemOrderReadyID-${itemOrderReadyID}`}
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={() => handleItemOrderReadySubmit(itemOrderReadyID)}
                          disabled={localState.updatingItemOrderReadyStatus === true || Boolean(itemOrderReady.info.poAcknowledged) !== true}
                        >
                          {localState.updatingItemOrderReadyStatus === true && (
                            <CircularProgress className={classes.circularProgress} size={20} />
                          )}
                          {ORDER_READY_STATUS.NEED_UPDATE === itemOrderReadyStatus
                            ? 'Re-Submit'
                            : 'Submit'
                          }
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                  {itemOrderReadyStatus === ORDER_READY_STATUS.SUBMITTED
                    && isAdminOrHigherRole(userRole)
                    && (
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        {renderRequireUpdateButton(itemOrderReady)}
                        {renderVerifyButton(itemOrderReady)}
                      </div>
                    )}
                </FlexRow>
              </AccordionDetails>
            </Accordion>
          );
        })
        }
      </div >
    );
  }

  const renderOrderReadyHistory = () => {
    return (
      <div>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          History
        </Typography>
        <Typography>Your upload history for this project is shown below</Typography>
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            marginTop: '0.5rem',
          }}
        >
          {localState.orderReadyHistory.map((history, index) => {
            const name = renderOrderReadyHistoryUser(history)
            return (
              <Paper
                style={{
                  padding: '0.6rem',
                  margin: '0.3rem',
                }}
                key={history.notes + index}
              >
                <Typography
                  className={classes.heading}
                  style={{
                    textAlign: history.userRole === ROLE_TYPES.SUPPLIER ? 'left' : 'right',
                  }}
                >
                  {formatDateWithTime(history.createdDate)}, {name}
                </Typography>
                {history.notes.split('\n').map((note, index) => {
                  return (
                    <Typography
                      key={note}
                      style={{
                        textAlign: history.userRole === ROLE_TYPES.SUPPLIER ? 'left' : 'right',
                      }}
                    >
                      {index === 0 ? changeStatusWordColor(note, history.status) : note}
                    </Typography>
                  );
                })}
              </Paper>
            );
          })}
        </Paper>
      </div>
    );
  }

  const renderOrderShippedButton = () => {
    const itemOrderReadyList = localState.itemOrderReadyList || [];
    const readyToShipItems = itemOrderReadyList.filter((itemOrderReady) => itemOrderReady.info.status === ORDER_READY_STATUS.READY_TO_SHIP);

    const projectOrderReadyStatus = get(localState.projectOrderReadyInfo, ['info', 'status']);

    if (readyToShipItems.length === 0 || projectOrderReadyStatus !== ORDER_READY_STATUS.READY_TO_SHIP) {
      return null;
    }

    const deliveryDocs = (get(localState.projectOrderReadyInfo, ['info', 'resources']) || []).filter(resource => [ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER, ORDER_READY_RESOURCE_TYPES.WAYBILL].includes(resource.type));
    const buttonTooltip = deliveryDocs.length > 1
      ? 'Please download delivery documents above before shipping'
      : 'Please download delivery document above before shipping';

    let text = 'All items have been verified';
    if (itemOrderReadyList.length > 1) {
      if (readyToShipItems.length === 1) {
        text = '1 item has been verified';
      } else if (readyToShipItems.length < itemOrderReadyList.length) {
        text = 'Some items have been verified';
      }
    } else {
      text = 'Item has been verified';
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '0.5rem',
          flexWrap: 'wrap',
          gap: '0.5rem',
          width: 'fit-content',
        }}
      >
        <Typography>
          {text}. Please click this button once the order has been shipped.&nbsp;
        </Typography>
        <BlueButton
          size={isMobile ? "small" : "large"}
          onBtnClick={handleOrderShipped}
          btnContent='Order Shipped'
          disabled={(localState.noDeliveryDocRequired !== true && localState.hasDownloadDeliverFiles !== true && !isAdminOrHigherRole(userRole)) || localState.orderShippedProcessing}
          tooltip={localState.noDeliveryDocRequired !== true && localState.hasDownloadDeliverFiles !== true ? buttonTooltip : ''}
          loading={localState.orderShippedProcessing}
        />
      </div>
    );
  }

  const renderReviewTab = () => {
    return (
      <Grid container spacing={3}>
        {localState.allItemsVerified === true
          && (localState.disableDeliverTab === false || isAdminOrHigherRole(userRole))
          && (
            <Grid item xs={12}>
              <Typography>
                All orders have been verified, please proceed to&nbsp;
                <Link
                  onClick={() => updateLocalState({ selectedTab: 1 })}
                  style={{ cursor: 'pointer' }}
                >
                  Deliver
                </Link>&nbsp;tab.
              </Typography>
            </Grid>
          )}
        {localState.hasItemNotStarted === true && (
          <Grid item xs={12}>
            <Typography>
              Upload delivery documents for each item and submit for review.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          {orderReadyLoading && <CircularProgress color='primary' size={30} />}
          {!orderReadyLoading && renderItemOrderReadyList()}
        </Grid>
        <Grid item xs={12} md={4}>
          {!isEmptyValue(localState.orderReadyHistory) && isAdminOrHigherRole(userRole)
            ? renderOrderReadyHistory()
            : <OrderReadyTimeline projectStatus={localState.projectOrderReadyStatus} />
          }
        </Grid>
      </Grid>
    );
  }

  const renderEditShipmentNotesArea = () => {
    const toRender = localState.showEditShippingNotesContainer
      && DELIVERY_STATUSES.includes(localState.projectOrderReadyStatus);

    if (toRender) {
      return (
        <div>
          <TextField
            style={{ width: '50%' }}
            label="Shipping Notes (if any)"
            variant="outlined"
            multiline={true}
            minRows={6}
            maxRows={6}
            onChange={(e) => updateLocalState({
              shipmentNotes: e.target.value,
              errors: {},
            })}
            value={localState.shipmentNotes}
            margin="dense"
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: '10px',
            }}
          >
            <BlueButton
              size="small"
              onBtnClick={() => {
                updateProjectOrderReadyInfo(localState.projectOrderReadyID, {
                  shipmentNotes: localState.shipmentNotes
                }).then(() => {
                  notifySuccess(`Updated successfully`);
                  loadOrderReadyInfo();
                }).catch(() => {
                  notifyError(`Updated failed`);
                });
              }}
              btnContent="Save Edit"
              className={classes.editShipmentNotes}
            />
            <WhiteButton
              size="small"
              onBtnClick={() => {
                updateLocalState({
                  showEditShippingNotesContainer: false,
                  shipmentNotes: localState.existingShipmentNotes,
                });
              }}
              btnContent="Cancel"
              type='cancelEdit' />
          </div>
        </div>)
    }
  }

  const renderReadyToShipArea = () => {
    const hasVerifiedItemOrderReady = localState.itemOrderReadyList.some(itemOrderReady => itemOrderReady.info.status === ORDER_READY_STATUS.VERIFIED);
    const resources = get(localState, ['projectOrderReadyInfo', 'resources']) || [];
    const hasWaybillOrDO = resources.some(resource => [ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER, ORDER_READY_RESOURCE_TYPES.WAYBILL].includes(resource.type));
    const disabledButton = localState.deliverTabWarning;

    return (
      <div>
        {!hasWaybillOrDO && <div>
          <FormControlLabel
            control={
              <Checkbox
                name="no-delivery-docs-required"
                onChange={(event) => {
                  const { checked } = event.target;
                  updateProjectOrderReadyInfo(localState.projectOrderReadyID, {
                    noDeliveryDocRequired: checked
                  }).then(() => {
                    notifySuccess(`Updated successfully`);
                    loadOrderReadyInfo();
                  }).catch(() => {
                    notifyError(`Updated failed`);
                  });
                }}
                checked={Boolean(get(localState.projectOrderReadyInfo, ['info', 'noDeliveryDocRequired']))}
              />
            }
            label="No delivery documents necessary."
          />
        </div>}
        {localState.projectOrderReadyStatus === ORDER_READY_STATUS.VERIFIED &&
          (<div>
            <TextField
              style={{ width: '50%' }}
              label="Shipping Notes (if any)"
              variant="outlined"
              multiline={true}
              minRows={6}
              maxRows={6}
              onChange={(e) => updateLocalState({
                shipmentNotes: e.target.value,
                errors: {},
              })}
              value={localState.shipmentNotes}
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </div>)}
        {renderEditShipmentNotesArea()}
        {hasVerifiedItemOrderReady !== false && (
          <div>
            <Tooltip title="Clicking this will notify partner to download documents (if any) and ship the order.">
              <span>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleReadyToShipClick}
                  disabled={disabledButton || localState.readyToShipProcessing === true}
                >
                  {localState.readyToShipProcessing === true && (
                    <CircularProgress className={classes.circularProgress} size={20} />
                  )}
                  Ready to ship
                </Button>
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }

  const renderItemsShippedSentence = () => {
    const allItemsShipped = localState.itemOrderReadyList.every(itemOrderReady => itemOrderReady.info.status === ORDER_READY_STATUS.IN_TRANSIT);
    if (!allItemsShipped) {
      return null;
    }
    return (
      <Typography>
        {localState.itemOrderReadyList.length > 1
          ? "All items have shipped."
          : "Item has shipped."
        }
      </Typography>
    )
  }

  const renderShipmentNotes = () => {
    const toRender = DELIVERY_STATUSES.includes(localState.projectOrderReadyStatus)
      && !localState.showEditShippingNotesContainer;

    return toRender &&
      (<div style={{ marginTop: '15px', marginBottom: '25px', }}>
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <div style={{ fontWeight: 'bold' }}>Shipment Notes</div>
          {isAdminOrHigherRole(userRole) &&
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => updateLocalState({ showEditShippingNotesContainer: true })}
            >
              <EditIcon />
            </IconButton>}
        </div>
        <div
          style={{
            maxWidth: '600px',
            wordWrap: 'break-word',
            marginTop: '5px',
          }}>
          {localState.shipmentNotes ?? 'N.A.'}
        </div>
      </div>)
  }

  const renderItemCollectionDisplay = () => {
    if (!isAdminOrHigherRole(userRole) && !areSomeItemsDelivered) {
      return (
        <>
          <div style={{ marginTop: '15px', marginBottom: '25px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
              <div style={{ fontWeight: 'bold' }}>Item Collection</div>
            </div>
            <div>
              No items have been delivered yet.
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <div style={{ marginTop: '15px', marginBottom: '25px' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
            <div style={{ fontWeight: 'bold' }}>Item Collection</div>
          </div>
          <div style={{ marginBottom: '15px' }}>
            {isAdminOrHigherRole(userRole) && renderItemCollectedButtons}
          </div>
          {isAdminOrHigherRole(userRole) && (
            <FtrButton
              color='blue'
              size='large'
              variant='contained'
              onClick={() => {
                setShowItemCollectionPopup(true);
              }}>
              Generate Item Collection Notice
            </FtrButton>
          )}
          {renderItemCollectionNoticesFiles()}
        </div>
      </>
    )
  }

  const renderShipmentInfo = () => {
    const orderShipmentInfo = localState.orderShipmentInfo || [];
    if (isEmptyValue(orderShipmentInfo)) {
      return null;
    }
    return (
      <div
        style={{
          margin: '1rem 0',
        }}
      >
        <Typography className={classes.heading}>
          Shipment Info
        </Typography>
        {orderShipmentInfo.map(shipmentInfo => {
          return (
            <div
              key={shipmentInfo.id}
              style={{
                marginLeft: '0.5rem',
              }}
            >
              <Typography>Shipment provider: <b>{shipmentInfo.shipmentProvider}</b></Typography>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Typography>Shipment Tracking Number: <b>{shipmentInfo.trackingNumber}</b></Typography>
                <ShipmentTrackingIcon
                  shipmentInfo={shipmentInfo}
                  iconButtonStyle={{ padding: '0rem 0rem 0rem 0.5rem' }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const renderManageSupplierInvoice = () => {
    return (
      <ManageSupplierInvoice
        supplierID={supplierID}
        projectID={projectID}
        poAcknowledgedVersion={poAcknowledgedVersion}
        refreshFunc={loadOrderReadyInfo}
        classes={classes}
        itemOrderReadyList={localState?.itemOrderReadyList || []}
        invoiceSentToHubdoc={localState?.projectOrderReadyInfo?.info?.invoiceSentToHubdoc}
        supplierInvoicesInfo={localState?.supplierInvoicesInfo || []}
        isOrderDelivered={localState.projectOrderReadyStatus === ORDER_READY_STATUS.DELIVERED}
      />
    )
  }

  const issueDOButtons = React.useMemo(() => {
    return localState.multipleCheckoutTrackings.map(
      (multipleCheckoutTracking) => {
        const undeliveredItems = multipleCheckoutTracking.acceptedItems.filter(undeliveredItemsFilter);
        return (
          <div
            key={multipleCheckoutTracking.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <div>
              <MCTIssueDOButton
                onClick={() => {
                  setShowDeliverPopup(true);
                  setSelectedMultipleCheckoutTracking(multipleCheckoutTracking);
                }}
                acceptedItems={undeliveredItems}
              />
            </div>
            {undeliveredItems.length > 0 && (
              <div>{`For Tracking ID: ${multipleCheckoutTracking.id}, Reference Name: ${multipleCheckoutTracking.referenceName}`}</div>
            )}
          </div>
        );
      }
    );
  }, [localState.multipleCheckoutTrackings]);

  const renderItemCollectedButtons = React.useMemo(() => {
    return localState.multipleCheckoutTrackings.map(
      (multipleCheckoutTracking) => {
        const uncollectedItems = multipleCheckoutTracking.acceptedItems.filter(uncollectedItemsFilter);
        return (
          <div
            key={multipleCheckoutTracking.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '16px',
              marginBottom: '10px'
            }}
          >
            <div>
              <MCTItemCollectedButton
                onClick={() => {
                  setShowCollectedInfoPopup(true);
                  setSelectedMultipleCheckoutTracking(multipleCheckoutTracking);
                }}
                acceptedItems={uncollectedItems}
              />
            </div>
            {uncollectedItems.length > 0 && (
              <div>{`For Tracking ID: ${multipleCheckoutTracking.id}, Reference Name: ${multipleCheckoutTracking.referenceName}`}</div>
            )}
          </div>
        );
      }
    );
  }, [localState.multipleCheckoutTrackings]);

  const renderDeliverTab = () => {
    return (
      <div>
        {localState.noDeliveryDocRequired === false && (
          <Typography>
            Please download following document(s) to proceed with shipping of
            the order.
          </Typography>
        )}
        {localState.isNonSgpSupplier && localState.projectOrderReadyStatus === ORDER_READY_STATUS.READY_TO_SHIP && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              maxWidth: 800,
            }}
          >
            <Typography>
              Key in a shipment tracking number, along with shipment provider and click &#39;Order Shipped&#39;
            </Typography>
            <Grid container spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    labelId="shipment-provider"
                    label="Shipment Provider"
                    id="shipment-provider"
                    variant="outlined"
                    select
                    onChange={(e) => updateLocalState({
                      shipmentProvider: e.target.value,
                      errors: {},
                    })}
                    margin="dense"
                    fullWidth
                    error={!!localState.errors.shipmentProvider}
                    helperText={localState.errors.shipmentProvider}
                  >
                    {SHIPMENT_PROVIDERS.map(shipment => {
                      return (
                        <MenuItem key={shipment} value={shipment}>{shipment}</MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                {localState.shipmentProvider === 'Other' && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="other-shipment-provider"
                      label="Other Shipment Provider"
                      variant="outlined"
                      fullWidth
                      size='small'
                      margin="dense"
                      onChange={(e) => updateLocalState({
                        otherShipmentProvider: e.target.value,
                        errors: {},
                      })}
                      error={!!localState.errors.otherShipmentProvider}
                      helperText={localState.errors.otherShipmentProvider}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="tracking-number-na"
                        onChange={(event) => updateLocalState({
                          shipmentTrackingNA: event.target.checked,
                          errors: {},
                        })}
                      />
                    }
                    label="Shipment number not available"
                  />
                </Grid>
                {localState.shipmentTrackingNA !== true && (
                  <Grid item xs={12}>
                    <TextField
                      id="shipment-tracking-number"
                      label="Shipment Tracking Number"
                      variant="outlined"
                      fullWidth
                      size='small'
                      margin="dense"
                      onChange={(e) => updateLocalState({
                        shipmentTrackingNumber: e.target.value,
                        errors: {},
                      })}
                      error={!!localState.errors.shipmentTrackingNumber}
                      helperText={localState.errors.shipmentTrackingNumber}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        )}
        <Box style={{ height: '0.5rem' }} />
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            {localState.noDeliveryDocRequired === false && !isAdminOrHigherRole(userRole) && (
              <Typography className={classes.heading}>
                Delivery Order
              </Typography>
            )}
            {isAdminOrHigherRole(userRole) && (
              <Typography className={classes.heading}>
                Issue Delivery Order
              </Typography>
            )}
            {isAdminOrHigherRole(userRole) &&
              !get(localState.projectOrderReadyInfo, [
                'info',
                'noDeliveryDocRequired',
              ]) &&
              issueDOButtons}
            {isAdminOrHigherRole(userRole) && (
              <div style={{ marginTop: '1rem' }}>
                <FilesUploadButton
                  id='upload-delivery-order'
                  buttonText='Upload Delivery Order'
                  handleUploadFiles={(files) =>
                    handleUploadOrderReadyResource({
                      file: files[0],
                      resourceType: ORDER_READY_RESOURCE_TYPES.DELIVERY_ORDER,
                    })
                  }
                  multiple={false}
                />
              </div>
            )}
            <div style={{ marginTop: '1rem' }}>
              {renderDeliveryOrderFiles()}
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            {renderWaybillFiles()}
          </Grid>
        </Grid>
        {isAdminOrHigherRole(userRole) && renderReadyToShipArea()}
        {renderShipmentNotes()}
        {renderOrderShippedButton()}
        {renderItemsShippedSentence()}
        {renderItemCollectionDisplay()}
        {renderShipmentInfo()}
        {renderManageSupplierInvoice()}
        <Divider style={{ marginTop: '1rem' }} />
        <div>
          <div style={{ marginTop: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>Shipment Instruction</div>
          <p>Please follow these simple rules for a smooth shipment of your order</p>
          {isMobile
            ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img src={DeliveryInstructionsVertical} style={{ width: '60%', height: '60%' }} alt="deliver-process" />
              </div>
            )
            : <img src={DeliveryInstructions} style={{ width: '60%', height: '60%' }} alt="deliver-process" />}
        </div>
      </div>
    );
  }

  const renderTab = () => {
    const tabName = TABS[localState.selectedTab];
    switch (tabName) {
      case TAB_KEYS.REVIEW:
        return renderReviewTab();
      case TAB_KEYS.DELIVER:
        return renderDeliverTab();
      case TAB_KEYS.ADMIN_UPLOAD_FILES:
        return (
          <AdminUploadFilesTab
            itemOrderReadyList={localState.itemOrderReadyList}
            loadOrderReadyInfo={loadOrderReadyInfo}
          />
        );
      default:
        return null;
    }
  }

  const renderDeleteFilePopup = () => {
    return (
      <YesNoPopup
        open={localState.showDeleteFilePopup}
        handleNo={() => updateLocalState({ showDeleteFilePopup: false })}
        handleYes={() => {
          isEmptyValue(localState.deleteItemCollectionNotice) ? handleDeleteResourceFile() : handleDeleteItemCollectionNotice();
          updateLocalState({ showDeleteFilePopup: false });
        }}
        body={`${localState.deleteResource?.fileName || localState.deleteItemCollectionNotice?.fileName || "Filename not detected"}`}
      />
    );
  }

  const renderAdminAddEditRequiredMaterialsPopup = () => {
    return (
      <AdminAddEditRequiredQCMaterialsPopup
        open={requiredQcMaterialState.showPopup}
        onClose={() => updateRequiredQcMaterialState({ showPopup: false })}
        loadOrderReadyInfo={loadOrderReadyInfo}
        selectedItemOrderReady={requiredQcMaterialState.selectedItemOrderReady}
        editMode={requiredQcMaterialState.editMode}
        resource={requiredQcMaterialState.resource}
      />
    );
  }

  const renderAdminEditStatusPopup = () => {
    const closePopup = () => updateLocalState({ showAdminEditStatusPopup: false });
    const itemOrderReadyInfo = localState.itemOrderReadyList?.find(ior => ior.info.itemOrderReadyID === localState.adminEditStatus.itemOrderReadyID);
    const currentIorStatus = itemOrderReadyInfo.info.status;
    if (localState.adminEditStatus.status === currentIorStatus) {
      notifyWarning(`Please select a different status!`);
      closePopup();
      return null;
    }

    return (
      <YesNoPopup
        open={localState.showAdminEditStatusPopup}
        handleNo={closePopup}
        handleYes={() => {
          handleAdminEditStatus();
          updateLocalState({ showAdminEditStatusPopup: false });
        }}
        title={`Are you sure that you want to change status of Part #${localState.adminEditStatus.itemID} 
          to ${ORDER_READY_STATUS_MAPPING[localState.adminEditStatus.status]}?`}
      />
    );
  }

  return (
    <div className={classes.body}>
      <FlexRow>
        <h1>Project {projectID}</h1>
        <div style={{ flexGrow: 1 }}></div>
        <GuestLinkToPage permissions={[GUEST_PERMISSIONS.ORM]} />
      </FlexRow>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Typography>Status:</Typography>
        {renderProjectOrderReadyStatus()}
        {isAdminOrHigherRole(userRole) && (
          <DownloadAllFilesButton
            buttonText="Download All Project Files"
            urlList={fileUrlList}
            folderName={`Factorem_Project_${projectID}`}
          />
        )}
      </div>
      <div
        style={{
          marginTop: '1rem',
        }}
      >
        <Tabs
          value={localState.selectedTab}
          onChange={(event, newValue) => updateLocalState({ selectedTab: newValue })}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            classes={{
              root: classes.tabRoot,
              selected: classes.selectedTab,
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIcon,
            }}
            key={TAB_KEYS.REVIEW}
            label={TAB_KEYS.REVIEW}
            icon={<ArtTrack />}
          />
          <CloneProps>
            {tabProps => (
              <Tooltip title={localState.disableDeliverTab ? 'Complete order ready review before proceeding to Deliver' : ''}>
                <div>
                  <Tab
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.selectedTab,
                      disabled: classes.disabledTab,
                      wrapper: classes.iconLabelWrapper,
                      labelIcon: classes.labelIcon,
                    }}
                    icon={<LocalShipping />}
                    {...tabProps}
                    key={TAB_KEYS.DELIVER}
                    label={(
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        {TAB_KEYS.DELIVER}
                        {localState.deliverTabWarning === true && (
                          <Tooltip title="Delivery documents have not been uploaded">
                            <Warning style={{ marginTop: 0, color: 'red', opacity: '1 important' }} />
                          </Tooltip>

                        )}
                      </div>
                    )}
                    disabled={localState.disableDeliverTab}
                  />
                </div>
              </Tooltip>
            )}
          </CloneProps>
          {isAdminOrHigherRole(userRole) && (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
                wrapper: classes.iconLabelWrapper,
                labelIcon: classes.labelIcon,
              }}
              key={TAB_KEYS.ADMIN_UPLOAD_FILES}
              label={TAB_KEYS.ADMIN_UPLOAD_FILES}
              icon={<AttachFile />}
            />
          )}
        </Tabs>
        <div
          style={{
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          {renderTab()}
        </div>
      </div>
      {localState.showImageView === true && (
        <Dialog
          open={localState.showImageView}
          onClose={() => updateLocalState({ showImageView: false })}
          maxWidth="md"
        >
          <ImageGallery
            items={localState.imageUrlList}
            startIndex={localState.showImageIndex}
          />
        </Dialog>
      )}
      {localState.showDeleteFilePopup === true && renderDeleteFilePopup()}
      {requiredQcMaterialState.showPopup === true && renderAdminAddEditRequiredMaterialsPopup()}
      <UserFeedbackPopup
        open={showFeedbackPopup}
        questionList={USER_FEEDBACK_QUESTION_LIST}
        userID={userID}
        userRole={userRole}
        handleClose={() => {
          setShowFeedbackPopup(false);
          setShowThankYouFeedbackPopup(true);
        }}
      />
      <ThankYouFeedbackPopup
        open={showThankYouFeedbackPopup}
        handleClose={() => setShowThankYouFeedbackPopup(false)}
      />
      {showItemCollectionPopup && <ItemCollectionNoticePopup
        open={showItemCollectionPopup}
        handleClose={() => setShowItemCollectionPopup(false)}
        itemsData={localState.itemsData}
        projectID={projectID}
        handleSubmit={async (payload) => {
          const toastID = notifyOngoing("Generating item collection notice")
          await generateItemCollectionNotice(payload)
            .then(() => updateNotification(toastID, "Generated item collection notice", 'success'))
            .catch(err => updateNotification(toastID, `Failed to generate.` + err, 'error'));
          await loadOrderReadyInfo();
        }}
        projectOrderReadyInfo={localState.projectOrderReadyInfo.info}
      />}
      {showDeliverPopup && (
        <MultiCheckoutDeliverPopup
          open={showDeliverPopup}
          handleCancel={() => setShowDeliverPopup(false)}
          checkoutID={selectedMultipleCheckoutTracking.id}
          setShowDeliverPopup={setShowDeliverPopup}
          dataRefetchFunc={loadOrderReadyInfo}
          acceptedItems={selectedMultipleCheckoutTracking.acceptedItems}
          initialEstimateDeliveryDate={selectedMultipleCheckoutTracking.estimateDeliveryDate}
        />
      )}
      {showCollectedInfoPopup && (
        <CollectedInfoPopup
          open={showCollectedInfoPopup}
          handleClose={() => setShowCollectedInfoPopup(false)}
          rowData={selectedMultipleCheckoutTracking}
          dataRefetchFunc={loadOrderReadyInfo}
        />
      )}
      {localState.showAdminEditStatusPopup === true && renderAdminEditStatusPopup()}
    </div>
  );
}

export default ProjectOrderReady;
