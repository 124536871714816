import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { AppBar, Toolbar } from '@material-ui/core';

import useAdminViewToggleHook from './useAdminViewToggleHook';

import { colors } from '../../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  adminViewWarningBar: {
    backgroundColor: colors.red030,
  }
}));

function withAdminViewRedAlertBannerHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const classes = useStyles();

    const [isAdminView, toggleAdminView] = useAdminViewToggleHook();

    return (
      <>
        {isAdminView && (
          <AppBar position="sticky" className={classes.adminViewWarningBar} style={{ height: 64 }}>
            <Toolbar style={{ height: 64 }}>You are currently in admin view for &quot;Manage Projects&quot;, do not screenshot this page</Toolbar>
          </AppBar>
        )}
        <WrappedComponent
          isAdminView={isAdminView}
          toggleAdminView={toggleAdminView}
          {...props}
        />
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withAdminViewRedAlertBannerHOC;
