import React, { useContext, useReducer } from 'react';

import ConfigureEditRfqPartForm from '../components/forms/ConfigureEditRfqPartForm';
import ProjectItemsContext from '../context/ProjectItemsContext';

// ------------------------------------------------------------------------------

function withConfigureEditRfqPartFormHOC(WrappedComponent) {
  return function ConfigureEditRfqPartFormOC(props) {
    const { refetchProjectInfoByID } = useContext(ProjectItemsContext);
    
    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        item: null,
      }
    );

    return (
      <>
        <WrappedComponent
          updateConfigureEditRfqPartState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <ConfigureEditRfqPartForm
            open={localState.open}
            item={localState.item}
            onClose={() => updateLocalState({ open: false, item: null })}
            getItems={() => refetchProjectInfoByID()}
            {...props}
          />
        )}
      </>
    );
  };
}

export default withConfigureEditRfqPartFormHOC;
