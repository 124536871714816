import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import jwt_decode from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { getTokenForGuest } from '../apis/jwtApi';

import { renderSkeletonOverlay } from '../components/util/skeleton';

import { setCookie } from '../services/cookiesService';

import withCodeNumberVerificationPopupHOC from '../hocs/withCodeNumberVerificationPopupHOC';

import { getUserSelector, getGuestUserInfoSelector } from '../selectors/userSelector';

import { ACCESS_TOKEN_KEY } from '../constants';

import { authenticateGuestUser } from '../actions/index';

import { isEmptyValue } from '../utils/commonUtils';

import { colors } from "../palette";

const useStyles = makeStyles(() => ({
  container: {
    margin: '1rem',
  },
  title: {
    fontWeight: '600',
    color: colors.fontDarkGrey,
  },
  subtitle: {
    display: 'flex',
    color: colors.fontGrey,
    alignItems: 'end',
  },
}));

const GuestUserAccessPage = ({ updateCodeNumberVerificationPopup, updateCodeNumberVerification }) => {
  const classes = useStyles();
  const { accessCode } = updateCodeNumberVerification;

  const { tokenID } = useParams();
  const history = useHistory();
  const guestUserInfo = useSelector(getGuestUserInfoSelector);
  const { userID: loggedInUserID } = useSelector(getUserSelector);
  const dispatch = useDispatch();

  const hasExistingGuestSession = !isEmptyValue(guestUserInfo);

  const {
    data,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useQuery(
    ['getTokenForGuest', tokenID, accessCode],
    () => getTokenForGuest({ tokenID, accessCode }),
    {
      enabled: !!(tokenID && accessCode),
    }
  );

  useEffect(() => {
    if (isError) {
      updateCodeNumberVerificationPopup({ errorMessage: error?.message || 'Could not validate this guest link' });
      updateCodeNumberVerificationPopup({ accessCode: null })
    }
  }, [isError, error, history, updateCodeNumberVerificationPopup]);

  useEffect(() => {
    if (isSuccess) {
      updateCodeNumberVerificationPopup({ isAccessCodeCorrect: true, open: false });

      const decodedToken = jwt_decode(data.token);

      const { navigateTo = '/' } = decodedToken;

      if (!loggedInUserID) {
        setCookie(ACCESS_TOKEN_KEY, data.token, {
          path: '/',
        });
       
        dispatch(authenticateGuestUser(decodedToken));
      }

      history.push(navigateTo);
    }
  }, [isSuccess, data, dispatch, history, updateCodeNumberVerificationPopup]);

  useEffect(() => {
    if (!isSuccess && !isLoading && !isError && !hasExistingGuestSession) {
      updateCodeNumberVerificationPopup({ open: true });
    }
  }, [isSuccess, isLoading, isError, updateCodeNumberVerificationPopup]);

  if (isLoading) {
    return renderSkeletonOverlay();
  }

  if (hasExistingGuestSession) {
    return (
      <Alert severity='error' className={classes.container}>
        <AlertTitle className={classes.title}>
          You have an existing guest user session open in another tab in your browser. 
        </AlertTitle>
        <div className={classes.subtitle}>
          Please close that tab first and then refresh this page in your browser.
        </div>
        <div className={classes.subtitle}>
          If you believe this error is a mistake, you can restart your browser or clear cookies for app.factorem.co.
        </div>
      </Alert>
    )
  }
  
  return null;
}

export default withCodeNumberVerificationPopupHOC(GuestUserAccessPage);
