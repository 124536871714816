// Import settings
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

// Import color palette
import { colors } from "../palette";

// Import data
import { RFQ_ITEM_STATUS } from "../constants/itemStatus";

// Import material UI components
import { Step, Stepper, StepConnector, StepLabel } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles/index";
import {
  BeenhereOutlined as BeenhereOutlinedIcon,
  DoneOutlineOutlined as DoneOutlineOutlinedIcon,
  HourglassEmptyOutlined as HourglassEmptyOutlinedIcon,
  LocalShippingOutlined as LocalShippingOutlinedIcon,
  MonetizationOnOutlined as MonetizationOnOutlinedIcon,
  Search as SearchIcon
} from "@material-ui/icons";

// Style components
const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 20
  }
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundImage: colors.buttonColorBlueGradient
    }
  },
  completed: {
    "& $line": {
      backgroundImage: colors.buttonColorBlueGradient
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: colors.inputLabelGrey,
    zIndex: 1,
    color: colors.fontWhite,
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    backgroundImage: colors.buttonColorBlueGradient,
    boxShadow: colors.inputBorderBlue
  },
  completed: {
    backgroundImage: colors.colorDarkBlueGradient
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <BeenhereOutlinedIcon />,
    2: <SearchIcon />,
    3: <MonetizationOnOutlinedIcon />,
    4: <HourglassEmptyOutlinedIcon />,
    5: <DoneOutlineOutlinedIcon />,
    6: <LocalShippingOutlinedIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

function ItemTracker(props) {
  const classes = useStyles();
  const { status } = props;
  return (
    <div className={classes.container}>
      <Stepper
        alternativeLabel
        activeStep={status - 1}
        connector={<ColorlibConnector />}
      >
        {RFQ_ITEM_STATUS
          .filter(itemStatusDetail => itemStatusDetail !== "Cancelled")
          .map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </div>
  );
}

export default ItemTracker;
