import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useQuery } from 'react-query';
import { CsvBuilder } from 'filefy';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import FlightIcon from '@material-ui/icons/Flight';

import { DataGrid } from '@mui/x-data-grid';

import CustomToolbar from '../components/grid-data/CustomToolbar';
import DataGridWrapTextCell from "../components/tables/cells/DataGridWrapTextCell";
import EmailStatusIcon from '../components/icons/EmailStatusIcon';
import FilterBar from '../components/filters/FilterBar';
import FtrDateRangePicker from '../components/ftr-components/FtrDateRangePicker';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import OrderReadyStatusDisplay from './orderReady/OrderReadyStatusDisplay';
import SearchBar from "../components/grid-data/buttons/SearchBar";
import { FlexRow, FlexRowCenter } from '../components/layouts/FlexLayouts';
import { FtrBoldText, FtrButton, FtrTooltip, FtrTypography } from '../components/ftr-components';
import { PageTitle } from '../components/PageTitle';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import NotePopup from '../components/popups/NotePopup';
import { InvoiceIcon } from '../components/icons/InvoiceIcon';

import withShipmentInfoPopupHOC from '../hocs/withShipmentInfoPopupHOC';

import { getAllProjectOrderReady, updateProjectOrderReadyInfo } from '../apis/orderReadyApi';

import { addBusinessDays, isSameOrAfterDate } from '../utils/dateTimeUtils';
import { generateCurrentCustomDateTimeString } from "../util";
import { getLatestCollectionDate, getLatestCollectionDateStr, getLatestDeliveryDate, getLatestDeliveryDateStr } from '../utils/itemUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { openInNewTab } from '../utils/navigationUtils';
import { getPoNumber, getPurchaseOrderID } from '../utils/quotationUtils';
import { downloadS3File } from '../utils/fileUtils';
import { transformCsvExportDataGrid } from '../utils/csvExportUtils';
import { isTrackingNumberValid } from '../utils/shipmentUtils';

import {
  ORDER_READY_STATUS,
  ORDER_READY_STATUS_FILTER_ARRAY,
} from '../constants/orderReadyConstants';
import { FIVE_MINUTES_IN_SECOND } from '../constants/dateTimeConstants';

import { colors } from "../palette";

import useDeviceCheck from '../hooks/useDeviceCheck';

import { generatePresignedUrl } from '../services/s3Service';
import { notifySuccess } from '../services/notificationService';


// ----------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '0 1rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
  },
  internalNote: {
    overflowY: 'auto',
    maxHeight: 51,
    cursor: 'pointer',
    minWidth: 160,
    whiteSpace: 'normal',
    lineHeight: 'normal',
    verticalAlign: 'middle',
  },
}));

function AllOrderReadyManagementDataGrid({ updateShipmentInfoPopupState }) {
  const classes = useStyles();

  const [{ isIPad, isMobile, isTablet }] = useDeviceCheck();

  const { data: allProjectOrderReady, refetch } = useQuery(
    'getAllProjectOrderReady',
    () => getAllProjectOrderReady(),
  );

  const [noteState, updateNoteState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedData: null,
      openPopup: false,
    }
  );

  const getAllOrmsWithShipmentNumber = (currentPor) => {
    if (isEmptyValue(currentPor)) {
      return [];
    }
    const { trackingNumber } = currentPor;
    // return an array consisting only of the current ORM row if shipment number is empty
    if (!isTrackingNumberValid(trackingNumber)) {
      return [currentPor];
    }
    return allProjectOrderReady.filter(orm => orm.trackingNumber === trackingNumber);
  }

  const getValuePoNumber = (rowData) => {
    if (isEmptyValue(rowData)) {
      return null;
    }
    if (rowData.itemOrderReadyList[0].combinedPOsForm) {
      return '#' + getPurchaseOrderID(rowData.itemOrderReadyList[0].combinedPOsForm)
    }
    return getPoNumber(rowData.supplierID, rowData.projectID, rowData.poAcknowledgedVersion)
  }

  const handleOpenNotePopup = (e, rowData) => {
    e.stopPropagation();
    e.preventDefault();
    updateNoteState({ selectedData: rowData, openPopup: true });
  }

  const handleOpenShipmentInfoPopup = (e, currentPor) => {
    e.stopPropagation();
    e.preventDefault();
    updateShipmentInfoPopupState({ selectedData: { porList: getAllOrmsWithShipmentNumber(currentPor) }, open: true })
  }
  
  const columns = [
    {
      headerName: "PO Number",
      field: "poNumber",
      renderCell: ({ row: rowData }) => {
        const combinedPOsForm = rowData.itemOrderReadyList[0].combinedPOsForm;
        if (combinedPOsForm) {
          return (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (combinedPOsForm) {
                  if (!isMobile && !isTablet && !isIPad) {
                    generatePresignedUrl(
                      combinedPOsForm,
                      FIVE_MINUTES_IN_SECOND,
                      true
                    ).then((presignedUrl) => {
                      openInNewTab(presignedUrl);
                    });
                  } else {
                    downloadS3File(combinedPOsForm);
                  }
                }
              }}
            >
              #{getPurchaseOrderID(rowData.itemOrderReadyList[0].combinedPOsForm)}
            </Link>
          )
        }
        return getPoNumber(rowData.supplierID, rowData.projectID, rowData.poAcknowledgedVersion)
      },
      valueGetter: ({ row: rowData }) => getValuePoNumber(rowData),
      minWidth: 110,
    },
    {
      headerName: "Project ID",
      field: "projectID",
      align: 'center',
      minWidth: 100,
    },
    {
      headerName: "Part ID(s)",
      field: "partIDs",
      renderCell: (params) => {
        return (
          <DataGridWrapTextCell text={params.row.itemOrderReadyList.map(item => item.itemID).join(', ')} />
        )
      },
      valueGetter: (params) => {
        return params.row.itemOrderReadyList.map(item => item.itemID).join(', ');
      },
      align: 'center',
      minWidth: 120,
      flex: 0.5,
    },
    {
      headerName: "Customer",
      field: "customer",
      renderCell: ({ row: rowData }) => {
        const item = rowData.itemOrderReadyList[0];
        const customerText = `${item.customerName} (${item.customerID})`;
        return (
          <DataGridWrapTextCell text={customerText} />
        )
      },
      valueGetter: ({ row: rowData }) => {
        const item = rowData.itemOrderReadyList[0];
        const customerText = `${item.customerName} (${item.customerID})`;
        return customerText;
      },
      align: 'center',
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Supplier",
      field: "supplier",
      renderCell: ({ row: rowData }) => {
        const supplierText = `${rowData.supplierName} (${rowData.supplierID})`;
        return (
          <DataGridWrapTextCell text={supplierText} />
        )
      },
      valueGetter: ({ row: rowData }) => {
        const supplierText = `${rowData.supplierName} (${rowData.supplierID})`;
        return supplierText;
      },
      align: 'center',
      minWidth: 140,
      flex: 1,
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (param) => {
        const projectOrderReadyStatus = param.row.status;

        const renderShipmentInfo = () => {
          return (
          <Tooltip title="View shipment info and customs tax details">
            <IconButton color="primary" onClick={(e) => handleOpenShipmentInfoPopup(e, param.row)}>
              <FlightIcon />
            </IconButton>
          </Tooltip>
          );
        }

        if (projectOrderReadyStatus === ORDER_READY_STATUS.DELIVERED) {
          const hasSupplierInvoices = !isEmptyValue(param.row.itemOrderReadyList[0].supplierInvoiceUrl);
          return (
            <FlexRowCenter style={{ width: '100%', gap: 0 }}>
              <IconButton color="primary">
                <InvoiceIcon
                  hasSupplierInvoices={hasSupplierInvoices}
                  invoiceSentToHubdoc={param.row.invoiceSentToHubdoc}
                  porCreatedDate={param.row.createdDate}
                />
              </IconButton>
              <div style={{ marginRight: '5px' }}>
                <EmailStatusIcon
                  emailType={param.row.scheduleReminderSubmitInvoice?.brevoType}
                  status={param.row.scheduleReminderSubmitInvoice?.brevoStatus}
                  date={param.row.scheduleReminderSubmitInvoice?.scheduledAt}
                />
                <EmailStatusIcon
                  emailType={param.row.brevoReminderSubmitInvoice?.type}
                  status={param.row.brevoReminderSubmitInvoice?.lastStatus}
                  date={param.row.brevoReminderSubmitInvoice?.updatedAt}
                />
              </div>
              <OrderReadyStatusDisplay status={projectOrderReadyStatus} />
            </FlexRowCenter>
          )
        } else { // order is not delivered and no invoice info should be displayed yet
          return (
            <FlexRowCenter style={{ width: '100%', gap: 0 }}>
              {projectOrderReadyStatus === ORDER_READY_STATUS.IN_TRANSIT && param.row.shipmentProvider && renderShipmentInfo()}
              <OrderReadyStatusDisplay status={projectOrderReadyStatus} />
            </FlexRowCenter>
          );
        }
      },
      minWidth: 245,
    },
    {
      headerName: "Collection Date",
      field: "collectionDate",
      valueGetter: (params) => {
        return getLatestCollectionDateStr(params.row.itemOrderReadyList);
      },
      renderCell: ({ row: rowData }) => {
        const collectionDate = getLatestCollectionDate(rowData.itemOrderReadyList);
        const collectionDateStr = getLatestCollectionDateStr(rowData.itemOrderReadyList);
        let color = 'inherit';
        const projectOrderReadyStatus = rowData.status;

        if (projectOrderReadyStatus !== ORDER_READY_STATUS.DELIVERED) {
          if (isSameOrAfterDate(new Date(), new Date(collectionDate))) {
            color = 'red';
          } else if (isSameOrAfterDate(addBusinessDays(new Date(), 5), new Date(collectionDate))) {
            color = '#ff9800';
          }
        }

        return (
          <FlexRow>
            {rowData.status === ORDER_READY_STATUS.NOT_STARTED && (
              <>
                <EmailStatusIcon
                  status={rowData.scheduleOrderDeliveryDueSoon?.status}
                  emailType={rowData.scheduleOrderDeliveryDueSoon?.type}
                  date={rowData.scheduleOrderDeliveryDueSoon?.date}
                />
                <EmailStatusIcon
                  status={rowData.brevoReminderOrderDeliveryDueSoon?.lastStatus}
                  emailType={rowData.brevoReminderOrderDeliveryDueSoon?.type}
                  date={rowData.brevoReminderOrderDeliveryDueSoon?.updatedAt}
                />
              </>
            )}
            <div style={{ color }}>{collectionDateStr}</div>
          </FlexRow>
        )
      },
      align: 'center',
      minWidth: 160,
    },
    {
      headerName: "Internal Note",
      field: "notes",
      align: 'center',
      renderCell: ({ row: rowData }) => {
        if (rowData.notes) {
          return (
            <FtrTooltip description='Click to view or edit'>
              <div
                className={classes.internalNote}
                onClick={(e) => handleOpenNotePopup(e, rowData)}
              >
                {rowData.notes}
              </div>
            </FtrTooltip>
          )
        }
        return (
          <FtrButton
            variant='text'
            color='blue'
            onClick={(e) => handleOpenNotePopup(e, rowData)}
          >
           + Add note
          </FtrButton>
        )
      },
      minWidth: 160,
    },
    {
      headerName: "Delivery Date",
      field: "deliveryDate",
      valueGetter: (params) => {
        return getLatestDeliveryDate(params.row.itemOrderReadyList);
      },
      renderCell: ({ row: rowData }) => {
        const deliveryDate = getLatestDeliveryDate(rowData.itemOrderReadyList);
        const deliveryDateStr = getLatestDeliveryDateStr(rowData.itemOrderReadyList);
        let color = 'inherit';
        const projectOrderReadyStatus = rowData.status;

        if (projectOrderReadyStatus !== ORDER_READY_STATUS.DELIVERED) {
          if (isSameOrAfterDate(new Date(), new Date(deliveryDate))) {
            color = 'red';
          } else if (isSameOrAfterDate(addBusinessDays(new Date(), 5), new Date(deliveryDate))) {
            color = '#ff9800';
          }
        }

        return (
          <div style={{ color }}>{deliveryDateStr}</div>
        );
      },
      align: 'center',
      minWidth: 120,
    },
  ]


  const handleRowClick = (params) => {
    const url = `/projects/${params.row.projectID}/order-ready?supplierID=${params.row.supplierID}&version=${params.row.poAcknowledgedVersion}`;
    openInNewTab(url);
  }

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      status: [],
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredTableData, setFilterTableData] = useState([]);

  const handleDownloadCsv = () => {
    const fileName = `All Project Order Ready ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredTableData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };


  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    let filteredData = allProjectOrderReady || [];
    if (!isEmptyValue(tableQueryParams.status)) {
      filteredData = filteredData.filter((row) => {
        const included = tableQueryParams.status.includes(row.status);
        if (included) {
          return true;
        }

        for (const itemOrderReady of row.itemOrderReadyList) {
          if (tableQueryParams.status.includes(itemOrderReady.status)) {
            return true;
          }
        }

        return false;
      });
    }

    if (!isEmptyValue(tableQueryParams.collectionDateFilter)) {
      const { startDate, endDate } = tableQueryParams.collectionDateFilter;
      filteredData = filteredData.filter((row) => {
        const collectionDate = getLatestCollectionDate(row.itemOrderReadyList);
        return isSameOrAfterDate(collectionDate, startDate) && isSameOrAfterDate(endDate, collectionDate);
      });
    }

    if (!isEmptyValue(tableQueryParams.deliveryDateFilter)) {
      const { startDate, endDate } = tableQueryParams.deliveryDateFilter;
      filteredData = filteredData.filter((row) => {
        const deliveryDate = getLatestDeliveryDate(row.itemOrderReadyList);
        return isSameOrAfterDate(deliveryDate, startDate) && isSameOrAfterDate(endDate, deliveryDate);
      });
    }

    if (!tableQueryParams.search) {
      setFilterTableData(filteredData);
      updateTableQueryParams({
        loading: false,
        totalCount: filteredData?.length || 0,
      });
      return;
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      filteredData = filteredData.filter((row) => {
        const item = row.itemOrderReadyList[0];
        const searchFields = [
          getPoNumber(row.supplierID, row.projectID, row.poAcknowledgedVersion),
          row.projectID,
          row.itemOrderReadyList.map(item => item.itemID).join(', '),
          row.supplierEmail.toLowerCase(),
          row.supplierName.toLowerCase(),
          item.customerName.toLowerCase(),
          // Search by trackingNumber if search query is at least 5 characters long
          tableQueryParams.search && tableQueryParams.search?.length >= 5 ? row.trackingNumber : null,
        ].filter(Boolean); // Remove null values if trackingNumber is excluded
        return searchFields.map(field => String(field).includes(tableQueryParams.search.toLowerCase())).some(Boolean)
      });
      setFilterTableData(filteredData);
      updateTableQueryParams({
        loading: false,
        totalCount: filteredData.length,
      });
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
    tableQueryParams.status,
    tableQueryParams.collectionDateFilter,
    tableQueryParams.deliveryDateFilter,
  ]);

  useEffect(() => {
    if (!allProjectOrderReady) {
      return;
    }

    setFilterTableData(allProjectOrderReady);
    updateTableQueryParams({
      totalCount: allProjectOrderReady.length,
    });
  }, [allProjectOrderReady]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const getCustomToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
        }}
      >
        <FlexRow>
          <FtrTypography>
            Filter by
          </FtrTypography>
          <FtrBoldText style={{ color: colors.blue060 }}>
            Collection Date:
          </FtrBoldText>
          <FtrDateRangePicker
            onOk={useCallback((value) => {
              updateTableQueryParams({
                collectionDateFilter: value
              });
            }, [updateTableQueryParams])}
            onClear={() => {
              updateTableQueryParams({
                collectionDateFilter: null
              });
            }}
            dateRange={tableQueryParams.collectionDateFilter}
          />
          <Box style={{ width: '0.5rem' }} />
          <FtrBoldText style={{ color: colors.blue060 }}>
            Delivery Date:
          </FtrBoldText>
          <FtrDateRangePicker
            onOk={useCallback((value) => {
              updateTableQueryParams({
                deliveryDateFilter: value
              });
            }, [updateTableQueryParams])}
            onClear={() => {
              updateTableQueryParams({
                deliveryDateFilter: null
              });
            }}
            dateRange={tableQueryParams.deliveryDateFilter}
          />
        </FlexRow>
        <CustomToolbar
          buttons={[
            <SearchBar
              key="search"
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={noteState.openPopup !== true}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
            />,
          ]}
        />
      </div>
    );
  }

  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      updateTableQueryParams({
        status: [...tableQueryParams.status, name]
      }
      );
    } else {
      updateTableQueryParams({
        status: tableQueryParams.status.filter(status => status !== name)
      });
    }
  }

  const handleUpdateNote = (newNotes) => {
    updateProjectOrderReadyInfo(noteState.selectedData?.projectOrderReadyID, {
      notes: newNotes,
    }).then(() => {
      updateNoteState({ openPopup: false })
      notifySuccess(`Project Order Ready with PO Number ${getValuePoNumber(noteState.selectedData)} has been updated successfully.`)
      refetch();
    })
  }

  return (
    <>
      <PageTitle title="All Project Order Ready" />
      <div className={classes.body}>
        <div style={{ marginBottom: '1rem' }}>
          <FilterBar
            optionList={ORDER_READY_STATUS_FILTER_ARRAY}
            onFilterChange={handleFilterChange}
          />
        </div>
        <DataGrid
          autoHeight
          headerHeight={90}
          columns={columns}
          rows={isEmptyValue(filteredTableData) ? [] : filteredTableData}
          getRowId={(row) => row.projectOrderReadyID}
          onRowClick={handleRowClick}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 50]}
          loading={tableQueryParams.loading}
          disableSelectionOnClick
          disableRowSelectionOnClick
          components={{
            Toolbar: getCustomToolbar,
            Pagination: () => {
              return <GridDataPagination
                pageCount={Math.ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
              />
            }
          }}
          localeText={{
            toolbarExport: 'Export CSV',
          }}
          disableColumnMenu
        />
      </div>
      {noteState.openPopup && (
        <NotePopup
          title={`Note for ORM for PO ${getValuePoNumber(noteState.selectedData)}`}
          key={noteState.selectedData?.projectOrderReadyID}
          open={noteState.openPopup}
          value={noteState.selectedData?.notes}
          onClose={() => updateNoteState({ openPopup: false })}
          updateHandler={handleUpdateNote}
          label='Internal note'
        />
      )}
    </>
  )
}

export default withShipmentInfoPopupHOC(AllOrderReadyManagementDataGrid);
