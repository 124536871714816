import React, { useReducer, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { getUserIDSelector } from '../selectors/userSelector';
import { getCreateUserSelector } from '../selectors/createUserSelector'

import SignUpForQuotePopup from '../components/popups/SignUpForQuotePopup';
import VerifyEmailPopup from '../components/popups/VerifyEmailPopup';
import HearAboutUsPopup from '../components/popups/HearAboutUsPopup';

// ------------------------------------------------------------------------------

function withSignUpPopupHOC(WrappedComponent) {
  const SignUpPopupHOC = forwardRef((props, ref) => {
    const userID = useSelector(getUserIDSelector);
    const { signupSuccess, verifyEmailSuccess, questionAnswered } = useSelector(getCreateUserSelector);

    const [localState, updateLocalState] = useReducer(
      (prev, next) => ({ ...prev, ...next }),
      { open: false }
    );

    return (
      <>
        <WrappedComponent
          ref={ref} // Forward the ref to the WrappedComponent
          onSignUpPopup={() => updateLocalState({ open: true })}
          {...props}
        />
        {!signupSuccess && !userID && (
          <SignUpForQuotePopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
          />
        )}
        <VerifyEmailPopup
          open={signupSuccess && !verifyEmailSuccess && !userID}
        />
        <HearAboutUsPopup
          open={signupSuccess && verifyEmailSuccess && (!userID || !questionAnswered)}
        />
      </>
    );
  });

  // Set the displayName for the HOC
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  SignUpPopupHOC.displayName = `withSignUpPopup(${wrappedComponentName})`;

  return SignUpPopupHOC;
}

export default withSignUpPopupHOC;
