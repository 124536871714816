import React from 'react'
import { FtrTooltip } from './ftr-components';

function DfmDefinitionTooltip(props) {
  const { defectKey, children } = props;
  
  const EXTRACT_DEFECT_DEFINITIONS_DISPLAY = {
    deep_holes: 'Hole depth more than 3 times the diameter (for diameters less than 2mm) is not acceptable. Hole depth more than 5 times the diameter (for diameters greater than 2mm) is not acceptable.',
    small_holes: 'Holes with a diameter less than 0.5mm is not acceptable.',
    thin_walls: 'Walls with a thickness of 0.4mm or less is not acceptable.',
    sharp_edges: 'Internal sharp edges with a 90-degree angle are not acceptable',
    holes_close_to_edge: 'Holes with a distance to the edge less than 0.4mm are not acceptable.',
    part_sizes_too_big: 'Part sizes greater than 300mm are not acceptable.',
  };
  
  return (
    <FtrTooltip description={EXTRACT_DEFECT_DEFINITIONS_DISPLAY[defectKey] ?? ''} arrow>
      {children}
    </FtrTooltip>
  )
}

export default DfmDefinitionTooltip