import React from 'react';

import { MenuItem, TextField } from '@material-ui/core';

import { ppe3dpLayerThicknessOptions } from '../../../constants/PPEConstants';
import { THREE_D_LAYER_THICKNESS } from '../../../constants/quotationConstants';

/**
 * @deprecated
 */
function ThreeDLayerThicknessFieldSelect(props) {
  const {
    value,
    onChange,
    error,
  } = props;

  return (
    <TextField
      name={THREE_D_LAYER_THICKNESS}
      select
      label="3D Layer Thickness"
      variant="outlined"
      onChange={onChange}
      value={value}
      error={error}
      helperText={error}
      margin="dense"
      fullWidth
    >
      {ppe3dpLayerThicknessOptions['FDM'].map((option) => {
        const displayedText = `${option.toFixed(2)} mm`;
        return (
          <MenuItem key={displayedText} value={option}>
            {displayedText}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export default ThreeDLayerThicknessFieldSelect;
