import {
  RESET_PART_UPLOAD_FORM_STATE,
  UPDATE_PART_UPLOAD_FORM_STATE,
} from "../actions";


// -------------------------------------------------------------------------------------------------

export const AUTO_MATCHING_PDF_STATUS = {
  IDLE: 'idle',
  PROCESSING: 'processing',
  SUCCESS_MATCHED: 'success_matched',
  SUCCESS_NO_MATCHED_FOUND: 'success_no_matched_found',
  FAILURE: 'failure',
}

const defaultState = {
  autoMatchingPdfStatus: AUTO_MATCHING_PDF_STATUS.IDLE,
  supportedFileTypes: [],
  totalUploadCountGuestUser: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_PART_UPLOAD_FORM_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case RESET_PART_UPLOAD_FORM_STATE: {
      return {
        ...defaultState,
      };
    }
    default: {
      return state;
    }
  }
}
