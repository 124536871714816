import React, { useEffect, useState } from 'react';

import BlueButton from '../buttons/BlueButton';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';
import Decimal from 'decimal.js';

import Title from '../Title';
import { FtrTextField, FtrDropdown, FtrTypography } from '../ftr-components';

import { CURRENCY_SYMBOL_MAPPING } from '../../constants/currencyConstants';
import { getPurchaseOrderID } from '../../utils/quotationUtils';
import FtrFieldLabel from '../ftr-components/FtrFieldLabel';
import { extractPriceFromCurrencyString } from '../../utils/currencyUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

/**
 *
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {() => void} props.onClose
 * @param {() => void} props.onSubmit
 * @param {Object} props.rowData
 * @returns
 */
function PoAmountPopup(props) {
  const { open, onClose, rowData, onSubmit } = props;

  const classes = useStyles();
  const [poAmountValue, setPoAmountValue] = useState('');
  const [currency, setCurrency] = useState(
    CURRENCY_SYMBOL_MAPPING[rowData?.currency] ?? null
  );

  useEffect(() => {
    if (rowData?.poAmount) {
      const [currencyValue] = rowData.poAmount.split(' ');
      const poAmountNumber = extractPriceFromCurrencyString(rowData.poAmount);
      setPoAmountValue(poAmountNumber);
      setCurrency(currencyValue);
      return;
    }
    if (rowData?.currency) {
      setCurrency(CURRENCY_SYMBOL_MAPPING[rowData?.currency]);
    }
  }, [rowData?.currency, rowData?.poAmount]);

  if (!rowData) {
    return null;
  }

  const pdfUrl = rowData?.combinedPOsForm || rowData?.purchaseOrderForm;

  const updateHandler = () => {
    if (typeof onSubmit === 'function') {
      const poAmount = new Decimal(poAmountValue).toFixed(2);
      onSubmit(`${currency} ${poAmount}`);
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='warning-dialog-title'>
        <Title
          contentTitle={`Update PO Amount #${getPurchaseOrderID(pdfUrl)}`}
          size='small'
        />
      </DialogTitle>
      <DialogContent>
        <FtrTypography>
          Please key in the final PO amount and currency to proceed with the
          manual PO update
        </FtrTypography>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 5fr',
            gap: '0.5rem',
            padding: '1rem 0',
          }}
        >
          <div>
            <FtrFieldLabel>Currency</FtrFieldLabel>
            <FtrDropdown
              items={Object.values(CURRENCY_SYMBOL_MAPPING)}
              title='Currency'
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            />
          </div>
          <div>
            <FtrFieldLabel>PO Amount</FtrFieldLabel>
            <FtrTextField
              type='number'
              key={'po-amount'}
              id={'po-amount'}
              value={poAmountValue}
              onChange={(e) => setPoAmountValue(e.target.value)}
            />
          </div>
        </div>
      </DialogContent>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '1rem',
        }}
      >
        <BlueButton onBtnClick={updateHandler} btnContent='Update' />
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default PoAmountPopup;
