import React, { useEffect, useState } from 'react';
import CountryDropDown from '../forms/CountryDropDown';
import { validateContact } from '../../utils/validators/contactValidator';
import { TextField, makeStyles } from '@material-ui/core';
import { colors } from '../../palette';
import { COUNTRY_LIST } from '../../constants/countryConstants';

const useStyles = makeStyles((theme) => ({
  contactField: {
    display: 'flex',
    alignItems: 'top',
    width: '100%',
    '& .MuiAutocomplete-hasPopupIcon': {
      minWidth: '85px',
    },
    '& .MuiFormControl-fullWidth': {
      marginBottom: 0,
    },
    '& .MuiIconButton-label': {
      display: 'none'
    }
  },
  inputField: {
    width: '100%',
    textTransform: 'none',
    color: colors.fontGrey,
    marginLeft: '0.5rem',
    '& .MuiInputBase-root': {
      borderRadius: 10,
    }
  },
}));

/**
 *
 * @param {object} props
 * @param {({ contact, country, code, fullContact, isContactValidated }) => void} props.onChange
 * @param {boolean} props.required
 */
function ContactField(props) {
  const { onChange = () => null, initialValue = '(+65) ', required = false } = props;
  const classes = useStyles();
  const [country, setCountry] = useState('Singapore');
  const [contact, setContact] = useState('');

  const isContactValidated = validateContact(contact, country);

  const getCountryNameByPhoneCode = (phoneCode) => {
    const cleanedPhoneCode = phoneCode.replace(/\(|\)/g, ''); // Remove "(" and ")"
    const [countryName] =
      Object.entries(COUNTRY_LIST).find(
        ([_, country]) => country.phone === cleanedPhoneCode
      ) || [];
    return countryName;
  };

  useEffect(() => {
    if (initialValue) {
      const [phoneCode, contactNumber] = initialValue.split(' ');
      setContact(contactNumber || '');
      if (phoneCode && typeof phoneCode === 'string') {
        const countryName = getCountryNameByPhoneCode(phoneCode)
        if (countryName) {
          setCountry(countryName);
        }
      }
    }
  }, []);

  useEffect(() => {
    const formattedContact = contact.replace(/\s/g, ''); // remove empty space
    const fullContact = `(${COUNTRY_LIST[country].phone}) ${formattedContact}`;
    onChange({
      contact: formattedContact,
      country,
      code: COUNTRY_LIST[country].phone,
      fullContact,
      isContactValidated,
    });
  }, [country, contact]);

  return (
    <div className={classes.contactField}>
      <CountryDropDown
        id='countryCode'
        isCountryCodeDropDown
        showDefaultFlag
        onSelect={(value) => setCountry(value)}
        valueSelected={country}
      />
      <TextField
        id='contact'
        name='contact'
        label='Contact Number'
        placeholder='Please Enter Your Contact Number!'
        className={classes.inputField}
        value={contact}
        onChange={({ target }) => setContact(target.value)}
        error={!isContactValidated}
        helperText={
          !isContactValidated ? 'Invalid contact number' : ''
        }
        variant='outlined'
        size='small'
        fullWidth
        required={required}
      />
    </div>
  );
}

export default ContactField;
