import { isEmpty } from "lodash";

/**
 * Generate a custom current dateTime string
 */
export function generateCurrentCustomDateTimeString() {
  const myDate = new Date();
  // Format "03-51 PM, 23-8-2021"
  let myMonth = myDate.getMonth() + 1;
  let dateString =
    myDate
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replaceAll(":", "-") +
    ", " +
    myDate.getDate() +
    "-" +
    myMonth +
    "-" +
    myDate.getFullYear();
  return dateString;
}

export const cutString = (str, length) => {
  if (isEmpty(str)) {
    return '';
  }
  if (str.length < length) {
    return str;
  }
  return `${str.substring(0, length)}...`;
}
