import React, { useEffect, useReducer } from 'react';
import { get, isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  CircularProgress,
  Container,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { notifyError } from '../../services/notificationService';
import { combinedPOs } from '../../apis/pdfApi';
import { listSupplierInProject } from '../../apis/projectApi';
import { Link } from 'react-router-dom';
import { downloadS3File } from '../../utils/fileUtils';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
}));

export default function GenerateCombinedPO() {
  const classes = useStyles();

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      projectID: '',
      supplierID: '',
      suppliers: [],
      downloadUrl: '',
      processing: false,
    }
  );

  useEffect(() => {
    const getData = setTimeout(() => {
      if (localState.projectID) {
        listSupplierInProject(localState.projectID)
          .then(({ data }) => {
            const supplierID = get(data[0], ['userID'], '');
            updateLocalState({ suppliers: data, supplierID });
          })
          .catch(() => notifyError('Unable to get suppliers'));
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [localState.projectID]);

  const handleGenerateCombinedPO = () => {
    const body = {
      projectID: localState.projectID,
      supplierID: localState.supplierID,
    };
    updateLocalState({ downloadUrl: '', processing: true });
    combinedPOs(body)
      .then(({ pdfUrl }) => updateLocalState({ downloadUrl: pdfUrl }))
      .catch(() => notifyError('Unable to generate combined PO'))
      .finally(() => updateLocalState({ processing: false }));
  };

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <TextField
          label='Project ID'
          value={localState.projectID}
          onChange={(evt) => {
            updateLocalState({ projectID: evt.target.value });
          }}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          type='number'
          placeholder='eg: 1234'
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label='Supplier'
          select
          required
          disabled={isEmpty(localState.suppliers)}
          value={localState.supplierID}
          onChange={(evt) => updateLocalState({ supplierID: evt.target.value })}
          variant='outlined'
          margin='dense'
          placeholder='eg: 1234'
          fullWidth={true}
        >
          {localState.suppliers.map((supplier) => (
            <MenuItem key={supplier.userID} value={supplier.userID}>
              {supplier.name}
            </MenuItem>
          ))}
        </TextField>

        <div style={{ marginTop: '1rem' }}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleGenerateCombinedPO}
            disabled={
              !localState.projectID ||
              !localState.supplierID ||
              localState.processing
            }
          >
            {localState.processing && (
              <CircularProgress
                className={classes.circularProgress}
                size={20}
              />
            )}
            Generate Combined PO
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '1rem',
            flexDirection: 'column',
          }}
        >
          <span style={{ minWidth: 'max-content' }}>Download Link:&nbsp;</span>
          {localState.processing && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          <Link
            onClick={(e) => {
              e.stopPropagation();
              downloadS3File(localState.downloadUrl);
            }}
            to="#"
          >
            {localState.downloadUrl}
          </Link>
        </div>
      </div>
    </Container>
  );
}
