import React from 'react';

import { FtrMintBox } from './ftr-components/FtrBox';

import withCustomerPOActionsMenuListHOC from '../hocs/withCustomerPOActionsMenuListHOC';


// --------------------------------------------------------------------------------------------

function CustomerPODisplay(props) {
  const {
    children,
  } = props;

  return (
    <FtrMintBox {...props}>
      {children}
    </FtrMintBox>
  );
}

export default withCustomerPOActionsMenuListHOC(CustomerPODisplay);
