import React from "react";

import { makeStyles, Box, IconButton } from "@material-ui/core";
import CancelIcon from "../assets/cancel_icon.svg";
import { colors } from "../../../palette";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    boxSizing: "border-box",
    borderRadius: 10,
    border: `1px solid ${colors.neutral030}`,
    height: 50,
    width: "100%",
    backgroundColor: colors.neutral010,
    color: colors.neutral070,
    paddingLeft: 16,
  },
}));

export default function UploadedFile(props) {
  const { cadPart, onDeleteClick } = props;

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {cadPart.file.name}
      <IconButton onClick={() => {onDeleteClick(cadPart.link)}}>
        <img
          src={CancelIcon}
          color="primary"
          alt=""
        />
      </IconButton>
    </Box>
  )
}
