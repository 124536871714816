import React from 'react';

import SingleImage from './SingleImage';

function CarouselImage({ url, width = 100, height = 100, margin = 1 }) {
  return (
    <div
      style={{
        margin,
      }}
    >
      <SingleImage
        width={width}
        height={height}
        url={url}
        noBorder
      />
    </div>
  );
}

export default CarouselImage;
