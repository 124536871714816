import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  line: {
    position: 'absolute',
    left: 0,
    top: '50%',
    height: 2,
    background: colors.blue050,
    zIndex: 1,
    width: 150,
    margin: 0,
    border: 'none',
    borderRadius: '1px',
    transform: 'translateY(-50%)',
  },
  divider: {
    position: 'absolute',
    left: 0,
    top: '50%',
    width: '100%',
    transform: 'translateY(-50%)',
  },
}));

/**
 *
 * @param {Object} props
 * @param {React.CSSProperties} props.lineStyle
 * @returns
 */
function FtrDivider(props) {
  const { lineStyle, ...rest } = props;
  const classes = useStyles();
  return (
    <div className={classes.container} {...rest}>
      <hr className={classes.line} style={lineStyle} />
      <Divider className={classes.divider} />
    </div>
  );
}

export default FtrDivider;
