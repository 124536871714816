import React from 'react';

const Row = (props) => {
  const { children, style, ...rest } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Row;
