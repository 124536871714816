import React from 'react';

import { PageTitle } from '../PageTitle';
import { Sidebar } from '../Sidebar';
import { SidebarV2 } from './SidebarV2';
import { SupplierSidebar } from '../SupplierSidebar';

import { isAdminOrHigherRole } from '../../utils/roleUtils';

import withSignUpPopupHOC from '../../hocs/withSignUpPopupHOC';

import { ROLE_TYPES } from '../../constants';


// -------------------------------------------------------------------------------------------------

function SidebarContainer(props) {
  const {
    allowAll,
    role,
    title,
    children,
  } = props;

  if ((role === ROLE_TYPES.BUYER || allowAll) && !isAdminOrHigherRole(role)) {
    return (
      <SidebarV2 {...props}>
        <div style={{ width: '100%', height: '100%' }}>
          {title && <PageTitle title={title} />}
          {children}
        </div>
      </SidebarV2>
    );
  }

  if (role === ROLE_TYPES.SUPPLIER) {
    return (
      <SupplierSidebar {...props}>
        <div style={{
          height: '100%',
          // overflow: 'clip',  // overflow: clip overwrites parent overflow for position: sticky to work
          marginBottom: '1.5rem',
          flex: '1 auto',
        }}>
          {children}
        </div>
      </SupplierSidebar>
    );
  }

  return (
    <Sidebar {...props}>
      <div
        style={{
          height: '100%',
          // overflow: 'clip',  // overflow: clip overwrites parent overflow for position: sticky to work
          marginBottom: '1.5rem',
          flex: '1 auto',
        }}
      >
        {children}
      </div>
    </Sidebar>
  );
}

export default withSignUpPopupHOC(SidebarContainer);
