import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
const dayjs = require('dayjs');

import {
  Grid,
  LinearProgress,
  List,
  makeStyles
} from '@material-ui/core';

import { CUSTOMER_STATUS_TYPES } from '../../constants/customerConstants';

import DrawingDragDropStepOne from '../DrawingDragDropStepOne';
import PartUploadStepOneTour from '../tour/PartUploadStepOneTour';
import SelectTechnologies from '../selects/SelectTechnologies';
import UploadFilesField from './form-fields/UploadFilesField';
import UploadedFileListItem from '../list-items/UploadedFileListItem';
import { FlexRow } from '../layouts/FlexLayouts';
import { FtrButton, FtrTypography } from '../ftr-components';
import { FtrHeading } from '../ftr-components/FtrTypography';
import GetSampleUpload from '../GetSampleUpload';

import withSnackbarHOC from '../../hocs/withSnackbarHOC';

import { getFormDataAvailableSelector } from '../../selectors/formDataAvailableSelector';
import { getPartUploadFormSelector } from '../../selectors/partUploadFormSelector';
import { getUserStatusSelector } from '../../selectors/userSelector';


import {
  CACHE_PARTS_UPLOAD_FORM_KEY,
  CACHE_PARTS_UPLOAD_TIME_KEY,
  MAX_CACHE_TIME,
  addTechnicalDrawing,
  cachePartsUploadFormAction,
  resetPartSummaryDetails,
  resetPartUploadPdfMatchingStatus,
  restoreLastPartsAction,
} from '../../actions/';

import useBottomCheck from '../../hooks/useButtonCheck';
import useCadUpload from '../../hooks/useCadUpload';

import { isEmptyValue } from '../../utils/commonUtils';

import { getCache } from '../../services/localStorageCacheService';
import { notifyError } from '../../services/notificationService';
import { uploadCadFileToS3 } from '../../services/s3Service';

import { AUTO_MATCHING_PDF_STATUS } from '../../reducers/partUploadFormReducer';

import { colors } from '../../palette';


// ------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100%',
    padding: '2rem 3rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem',
    }
  },
  containerUploadCad: {
    width: '100%',
    position: 'relative',
    backgroundColor: colors.neutral020,
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
    }
  },
}));

function PartUploadStepOneV2(props) {
  const classes = useStyles();

  const {
    setSnackbarMessage = () => { }
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const formDataAvailable = useSelector(getFormDataAvailableSelector);
  const partUploadForm = useSelector(getPartUploadFormSelector);
  const customerStatus = useSelector(getUserStatusSelector);

  const [
    cadPartListState,
    {
      uploadCadFilesStep1,
      removeCadPart,
      updateTechnologyParamsInfoForAllParts,
    }
  ] = useCadUpload();

  const hasFileUploaded = cadPartListState.data.length > 0;
  const hasPartUploading = cadPartListState.data.some(part => part.uploadStatus === 'loading');

  const continueButtonRef = useRef(null);

  const [params, setParams] = useState({ technology: 'CNC Machining' });
  const [numOfUploadedFiles, setNumOfUploadedFiles] = useState(0);

  const isBottom = useBottomCheck(continueButtonRef);

  const cachePartsUploadForm = getCache(CACHE_PARTS_UPLOAD_FORM_KEY);
  const cachePartsUploadFormTime = getCache(CACHE_PARTS_UPLOAD_TIME_KEY);

  const cachedTime = new Date(cachePartsUploadFormTime);

  const cacheDuration = dayjs(new Date()).diff(dayjs(cachedTime));
  const showRestorePartsButton = !isEmptyValue(JSON.parse(cachePartsUploadForm)) && cacheDuration <= MAX_CACHE_TIME;

  if (hasFileUploaded && window.PBrain) {
    window.PBrain.config({ showhide: 'hide' });
  }

  useEffect(() => {
    dispatch(resetPartSummaryDetails());
    dispatch(resetPartUploadPdfMatchingStatus());

    return () => {
      // reset state when unmount
      dispatch(cachePartsUploadFormAction());
      dispatch(resetPartUploadPdfMatchingStatus());

      setParams({ technology: 'CNC Machining' });
      setNumOfUploadedFiles(0);
    };
  }, []);

  useEffect(() => {
    if (partUploadForm.autoMatchingPdfStatus === AUTO_MATCHING_PDF_STATUS.SUCCESS_MATCHED) {
      const message = 'Your technical drawing has been auto-matched. Please review and verify for accuracy.';
      setSnackbarMessage(message);
    }
  }, [partUploadForm.autoMatchingPdfStatus]);

  useEffect(() => {
    updateTechnologyParamsInfoForAllParts(params);
  }, [params]);

  useEffect(() => {
    if (!isEmptyValue(cadPartListState.data)) {
      const numOfUploaded = cadPartListState.data.reduce((acc, item) => {
        return item.uploadStatus === 'success' ? acc + 1 : acc;
      }, 0);
      setNumOfUploadedFiles(numOfUploaded);
    }
  }, [cadPartListState]);

  async function handleCadFilesChange(files) {
    uploadCadFilesStep1(files);
  }

  async function handleSampleCadFilesChange(files) {
    uploadCadFilesStep1(files);
    history.push('/summary');
  }

  const handleContinueButtonClick = async () => {
    history.push('/summary', { from: '/' });
  };

  const handleRestoreCachePartsUpload = () => {
    dispatch(restoreLastPartsAction());
  }

  const handleClearAllParts = () => {
    dispatch(resetPartSummaryDetails());
  }

  const renderUploadStatusInfo = () => {
    const totalFiles = cadPartListState.data.length;
    if (isEmptyValue(cadPartListState.data) || numOfUploadedFiles === totalFiles) {
      return null;
    }
    let progress = numOfUploadedFiles / totalFiles * 100;
    return (
      <div>
        <FtrTypography type='body' fontSize='12' style={{ padding: '0.3rem 0' }}>
          Uploading {numOfUploadedFiles} of {cadPartListState.data.length} files
        </FtrTypography>
        <LinearProgress variant='determinate' value={progress} style={{ borderRadius: '6px', colors: colors.blue060 }} />
      </div>
    );
  }

  const renderContinueButton = () => {
    return (
      <div
        ref={continueButtonRef}
        style={{
          display: 'flex',
          justifyContent: 'end',
          position: 'sticky',
          bottom: 0,
          backgroundColor: colors.neutral020,
          padding: isBottom ? '1rem 3.5rem 1rem 0' : '1rem 0 1rem 0',
          marginRight: isBottom ? '-1rem' : 0,
          marginLeft: -5,
        }}
      >
        <FtrButton
          color='blue'
          onClick={() => handleContinueButtonClick()}
          disabled={hasPartUploading}
          id='step-1-continue-btn'
        >
          Continue
        </FtrButton>
      </div>
    );
  }

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      wrap='nowrap'
      className={classes.container}
    >
      <Grid
        id='select-technology-area'
        item
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <FtrHeading style={{ width: '100%', textAlign: 'left', color: colors.neutral070 }}>
          Select Technology
        </FtrHeading>
        <div style={{ width: '100%', padding: '1rem 0' }}>
          <SelectTechnologies
            id='select-technology'
            width='long'
            selectedTechnology={params.technology}
            onChange={setParams}
          />
        </div>
      </Grid>
      <Grid
        id='upload-part-box-step-one-area'
        className={classes.containerUploadCad}
      >
        <FtrHeading style={{ color: colors.neutral070 }}>
          Upload CAD File
        </FtrHeading>
        <div style={{ padding: '1rem 0' }}>
          <UploadFilesField
            fullWidth
            isAdditionalInfoShown
            technology={params.technology}
            handleFiles={handleCadFilesChange}
            data={formDataAvailable}
            handleRestoreCachePartsUpload={handleRestoreCachePartsUpload}
            handleClearAllParts={handleClearAllParts}
            showRestorePartsButton={showRestorePartsButton}
          />
        </div>
        <div>
          {renderUploadStatusInfo()}
          <List
            style={{
              padding: 0,
            }}
          >
            {cadPartListState.data
              .filter(part => part.uploadStatus == 'success')
              .map((part) => (
                <FlexRow
                  key={part.id}
                  style={{
                    gap: '1rem',
                    width: '100%',
                  }}
                >
                  <UploadedFileListItem
                    data={part}
                    onDeleteClick={removeCadPart}
                    show3dCAD
                  />
                  <DrawingDragDropStepOne
                    id={part.id}
                    handleFiles={(files) => {
                      const uploadedUrls = [];
                      Promise.all(
                        Array.from(files).map(async (file) => {
                          const { s3ObjectUrl } = await uploadCadFileToS3(file);
                          if (!isEmptyValue(s3ObjectUrl)) {
                            uploadedUrls.push(s3ObjectUrl);
                          }
                        })
                      ).then(() => {
                        dispatch(addTechnicalDrawing(part.id, uploadedUrls));
                      }).catch(() => {
                        notifyError('File uploaded failed');
                      });
                    }}
                  />
                </FlexRow>
              ))}
          </List>
        </div>
        {hasFileUploaded && renderContinueButton()}
        {!hasFileUploaded && customerStatus !== CUSTOMER_STATUS_TYPES.FORMAL_ENQUIRY && (
          <GetSampleUpload handleCadFilesChange={handleSampleCadFilesChange} />
        )}
      </Grid>
      <PartUploadStepOneTour />
    </Grid>
  );
}

export default withSnackbarHOC(PartUploadStepOneV2);
