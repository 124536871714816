import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import Thumb from '../fields/Thumb';
import QualityChecks from '../fields/QualityCheck';

import { QUALITY_ITEM_LABELS } from '../../constants/itemConstants';

const useStyles = makeStyles(() => ({
  accordion: {
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      gap: '1rem',
      margin: '5px 0',
      minHeight: 'auto',
    },
  },
}));

/**
 *
 * @param {Object} props
 * @param {Object} props.properties
 * @param {() => void} props.updateQualityInformation
 * @param {Number} props.itemID
 * @param {String} props.titleText
 * @returns
 */
function QualityAccordion(props) {
  const {
    properties,
    updateQualityInformation,
    itemID,
    titleText = 'Partner Quality Metrics',
    displayOneColumn = false,
    ...restProps
  } = props;

  const classes = useStyles();

  const [expandAccordion, setExpandAccordion] = useState({});

  const handleChangeAccordion = (keyItemID) => (event, isExpanded) => {
    setExpandAccordion((prevValue) => ({
      ...prevValue,
      [keyItemID]: isExpanded,
    }));
  };

  const renderThumbs = () => {
    return (
      <div style={{ display: 'flex', gap: '5px' }}>
        {Object.entries(properties)?.map(([key, value], index) => {
          if (value) {
            return (
              <Thumb
                key={index}
                isActive
                variant='up'
                tooltipText={QUALITY_ITEM_LABELS[key] ?? key}
                onClick={(e) => {
                  updateQualityInformation(key, false, itemID);
                  e.stopPropagation();
                }}
              />
            );
          }
          return (
            <Thumb
              key={index}
              variant='down'
              isActive
              tooltipText={QUALITY_ITEM_LABELS[key] ?? key}
              onClick={(e) => {
                updateQualityInformation(key, true, itemID);
                e.stopPropagation();
              }}
            />
          );
        })}
      </div>
    );
  };

  const expanded = expandAccordion[`quality-${itemID}`];

  return (
    <Accordion
      expanded={expanded}
      style={{ margin: '0' }}
      onChange={handleChangeAccordion(`quality-${itemID}`)}
      className={classes.accordion}
      {...restProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          style={{
            fontWeight: 'bold',
            marginBottom: '0.5rem',
          }}
        >
          {titleText}
        </Typography>
        {!expanded && renderThumbs()}
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 1rem 0.5rem',
        }}
      >
        <QualityChecks
          switchState={properties}
          onSwitchChange={(name, checked) =>
            updateQualityInformation(name, checked, itemID)
          }
          displayOneColumn={displayOneColumn}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default QualityAccordion;
