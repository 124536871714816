import { useState } from "react";

const useAdminViewToggleHook = () => {
  const [isAdminView, setIsAdminView] = useState(false);

  const toggleAdminView = () => {
    setIsAdminView(!isAdminView);
  };

  return [
    isAdminView,
    toggleAdminView,
  ]
};

export default useAdminViewToggleHook;
