import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import { Tab, Tabs } from '@material-ui/core';

import AllCompaniesDataGrid from './manage-users/AllCompaniesDataGrid';
import AllUsersDataGrid from './manage-users/AllUsersDataGrid';
import LoginHistoryInfo from './manage-users/LoginHistoryInfo';
import UserFeedbackDataGrid from './manage-users/UserFeedbackDataGrid';

import { isAdminOrHigherRole } from '../utils/roleUtils';

import { ROLE_TYPES } from '../constants';

import { colors } from '../palette';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  ALL_USERS: 'All Users',
  ALL_USERS_DATA_GRID: 'All Users Data Grid',
  ALL_COMPANIES: 'All Companies',
  LOGIN_HISTORY_INFO: 'Login History Info',
  USER_FEEDBACK: 'User Feedback',
};

function ManageUsers(props) {
  const { role } = props;
  const classes = useStyles();

  const TABS = [
    TAB_KEYS.ALL_USERS,
    TAB_KEYS.ALL_COMPANIES,
    TAB_KEYS.USER_FEEDBACK,
  ];

  const [viewableTabs, setViewableTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (role === ROLE_TYPES.SUPER_ADMIN) {
      TABS.push(TAB_KEYS.LOGIN_HISTORY_INFO);
      setViewableTabs(TABS);
      return;
    }
    if (isAdminOrHigherRole(role)) {
      setViewableTabs(TABS);
      return;
    }
    if ([ROLE_TYPES.SALES_CONSULTANT].includes(role)) {
      setViewableTabs([TAB_KEYS.ALL_USERS, TAB_KEYS.ALL_COMPANIES]);
      return;
    }
  }, [role]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = TABS[selectedTab];
    switch (tabName) {
      case TAB_KEYS.ALL_USERS:
        return <AllUsersDataGrid />;
      case TAB_KEYS.ALL_COMPANIES:
        return <AllCompaniesDataGrid />;
      case TAB_KEYS.USER_FEEDBACK:
        return <UserFeedbackDataGrid />;
      case TAB_KEYS.LOGIN_HISTORY_INFO:
        return <LoginHistoryInfo />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <h1>Manage Users</h1>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {viewableTabs.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(ManageUsers));
