import React from 'react';

import { CircularProgress } from '@material-ui/core';

import ProjectCard from './components/ProjectCard';
import { FtrTypography } from '../../components/ftr-components';

import robotIcon from '../../assets/icons/robot-vector.svg';
import { isEmptyValue } from '../../utils/commonUtils';
import { colors } from '../../palette';

/**
 *
 * @param {*} props
 * @param {React.CSSProperties} props.style
 * @returns
 */
const EmptyProject = (props) => {
  const { style } = props;
  return (
    <div style={{ textAlign: 'center', ...style }}>
      <img src={robotIcon} style={{ padding: '0 0.3rem' }} alt='logo' />
      <FtrTypography fontSize='14'>Ready to get started?</FtrTypography>
      <FtrTypography fontSize='14'>
        Use this workspace to store your parts for ongoing projects.
      </FtrTypography>
    </div>
  );
};

function ProjectsView(props) {
  const { data, isLoading, isFetching } = props;

  if (isLoading) {
    return (
      <div style={{ color: colors.blue060 }}>
        <CircularProgress color='inherit' />
      </div>
    );
  }

  if (data?.length === 0) {
    return <EmptyProject style={{ marginTop: '4rem' }} />;
  }

  return (
    <>
      {data?.map((project, index) => {
        if (isEmptyValue(project.items)) {
          return null;
        }
        return (
          <ProjectCard
            key={`project-library-index-${index}`}
            project={project}
            id={`project-library-index-${index}`}
            isFetching={isFetching}
          />
        );
      })}
    </>
  );
}

export default ProjectsView;
