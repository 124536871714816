import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import { notifyError } from '../services/notificationService';

import { generatePresignedUrl } from '../apis/s3Api';

import { ONE_DAY_IN_SECONDS } from '../utils/dateTimeUtils';
import { witchCacheResponse } from '../utils/apiUtils';

function HtmlContentDefect({
  url,
  width = '100%',
  height = '100%',
  borderRadius = '0 0 10px 10px',
}) {
  const [presignedUrl, setPresignedUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const generatePresignedWithCache = witchCacheResponse(
    generatePresignedUrl,
    ONE_DAY_IN_SECONDS,
  );

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        setIsLoading(true);
        let _presignedUrl = await generatePresignedWithCache(url, ONE_DAY_IN_SECONDS);
        setPresignedUrl(_presignedUrl);
        setIsLoading(false);
      } catch (error) {
        notifyError(`Failed preparing HTML content: ${error.message ?? error}`);
        setPresignedUrl('');
        setIsLoading(false);
      }
    };

    fetchHtmlContent();
  }, [url]);

  if (isLoading) {
    return <CircularProgress size={24} style={{ margin: '2rem' }} />;
  }

  return (
    <iframe
      style={{ width, height, border: 'none', borderRadius }}
      src={presignedUrl}
    />
  );
}

export default HtmlContentDefect;
