// Import settings
import React from "react";

// Import color palette
import { colors } from "../../palette";

// Import material UI components
import {
    Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/index";

// Style components
const useStyles = makeStyles((theme) => ({
    submitBtn: {
        textTransform: 'uppercase',
        background: colors.inputLabelGrey,
        borderRadius: '0.3125rem',
        fontSize: '0.9375rem',
        letterSpacing: '0.15em',
        color: colors.fontWhite,
        padding: '0.75rem 1.6875rem',
        [theme.breakpoints.down('sm')]: {
            padding: '0.75rem 1rem',
            fontSize: '0.75rem'
          }
    },
    smallSubmitBtn: {
        textTransform: 'uppercase',
        background: colors.inputLabelGrey,
        borderRadius: '0.3125rem',
        fontSize: '0.5625rem',
        letterSpacing: '0.15em',
        color: colors.fontWhite,
        padding: '0.625rem 0.9375rem'
    }
}))

function GreyButton(props) {
    const classes = useStyles();
    const { id, btnContent, onBtnClick, component, to, size, disabled, className = '' } = props;
    if (size === 'small') {
        return (
            <Button
                data-cy={id}
                className={`${classes.smallSubmitBtn} ${className}`}
                onClick={onBtnClick}
                variant='contained'
                component={component ? component : 'span'}
                to={to ? to : null}
                disabled={disabled}
            >
                {btnContent}
            </Button>
        )
    }
    return (
        <Button
            data-cy={id}
            className={`${classes.submitBtn} ${className}`}
            onClick={onBtnClick}
            variant='contained'
            component={component ? component : 'span'}
            to={to ? to : null}
            disabled={disabled}
        >
            {btnContent}
        </Button>
    )
}

export default GreyButton;