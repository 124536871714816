import React, { useEffect, useState } from 'react';
import {
  Container,
  Divider,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { colors } from '../../palette';
import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { get } from 'lodash';
import { updateInUseSupplierDashboardUploadHistory } from '../../apis/supplierDataDashboard';
import { notifySuccess } from '../../services/notificationService';
import BlueButton from '../buttons/BlueButton';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  input: {
    width: '250px !important',
  },
}));

/**
 *
 * @param {object} props
 * @param {object} props.value
 */
function CurrentVersionPartnerData(props) {
  const { data } = props;
  const classes = useStyles();

  const currentVersion = data.find((item) => item.inUse === 1);
  const [selectedVersion, setSelectedVersion] = useState(currentVersion);

  const handleChangeInUseVersion = () => {
    if (!selectedVersion) return;
    updateInUseSupplierDashboardUploadHistory(selectedVersion.id).then(() => {
      notifySuccess('Successfully updated supplier data dashboard version');
    });
  };

  useEffect(() => {
    const newCurrentVersion = data.find((item) => item.inUse === 1);
    setSelectedVersion(newCurrentVersion)
  }, [data])

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant='h6'>Select Current Version</Typography>
        <Divider />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '25px',
        }}
      >
        <TextField
          label='Current Version'
          select
          variant='outlined'
          style={{ textAlign: 'left' }}
          value={get(selectedVersion, 'id')}
          margin='dense'
          fullWidth
          className={classes.selectInput}
        >
          {!!data.length &&
            data.map((option) => (
              <MenuItem
                key={option.id}
                value={option.id}
                onClick={() => setSelectedVersion(option)}
              >
                {option.id} - {option.fileName} -{' '}
                {dateTzSingapore(option.createdAt)}
              </MenuItem>
            ))}
        </TextField>
        <BlueButton
          size='small'
          btnContent='Change'
          onBtnClick={handleChangeInUseVersion}
        />
      </div>
    </Container>
  );
}

export default CurrentVersionPartnerData;
