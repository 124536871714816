import React from 'react';

import { IconButton } from '@material-ui/core';

function CircleButton(props) {
  const {
    size,
    children,
    onClick = () => { },
    style = {},
    ...rest
  } = props;

  return (
    <IconButton
      aria-label="upload picture"
      component="span"
      size={size}
      style={{
        ...style,
      }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </IconButton>
  );
}

export default CircleButton;
