import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { colors } from '../palette';

const useStyles = makeStyles(() => ({
  rowDetail: {
    color: colors.fontGrey,
    display: 'flex',
    fontSize: '15px',
    marginTop: 5,
    marginLeft: 20
  },
  rowTitle: {
    fontWeight: 600,
  },
  rowContent: {
    marginLeft: 5,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    color: colors.blue050,
  },
}));

function DetailRow({ title, content, id }) {
  const classes = useStyles();

  return (
    <div className={classes.rowDetail}>
      <div className={classes.rowTitle}>{title}: </div>
      <div className={classes.rowContent} data-cy={id}>{content}</div>
    </div>
  );
}

export default DetailRow;
