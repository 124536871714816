import { getAuthorizedHeader } from "../utils/apiUtils";

import { BACKEND_SERVICE_URL } from "../constants";

export const getCustomerSettings = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  })
    .then(async (response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return (await response.json()).data;
    });
};

export const updateCustomerSettingsApi = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/user-settings/${userID}`;
  return fetch(requestUrl, {
    method: "PATCH",
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    });
}
