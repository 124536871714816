import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import { Icon } from '@material-ui/core';

function FtrSvgImage(props) {
  const {
    src,
    hoverIcon,
    disabledIcon,
    className,
    width = 20,
    height = 20,
    style = {},
    onClick = () => { },
    disabled = false,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  const icon = useMemo(() => {
    if (disabled) {
      return disabledIcon || src;
    }

    if (!isHovered) {
      return src;
    }

    return hoverIcon || src;
  }, [
    isHovered,
    disabled,
    src,
    hoverIcon,
    disabledIcon,
  ]);

  return (
    <Icon
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width,
        height,
        ...style,
      }}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={icon}
        className={clsx(className)}
        style={{
          display: 'block',
          width: width,
          height: height,
          fill: 'blue',
        }}
      />
    </Icon>
  );
}

export default FtrSvgImage;
