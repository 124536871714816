import React from 'react';
import { Container, Divider, Typography, makeStyles } from '@material-ui/core';

import { colors } from '../../palette';
import PpeSwitchForm from '../../components/forms/PpeSwitchForm';

const useStyles = makeStyles(() => ({
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
}));

function PpeSwitchFormContainer(props) {
  const { handlers, states } = props;
  const {
    handleTechnologySwitchChange,
    handleParametersSwitchChange,
  } = handlers;
  const { technologySwitchState, parametersState } = states;

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant='h6'>PPE On/Off switch</Typography>
        <Divider />
      </div>
      <PpeSwitchForm
        onSwitchChange={handleTechnologySwitchChange}
        switchState={technologySwitchState}
      />
      <PpeSwitchForm
        onSwitchChange={handleParametersSwitchChange}
        switchState={parametersState}
      />
    </Container>
  );
}

export default PpeSwitchFormContainer;
