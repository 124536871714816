import React from 'react';

import FilterBar from './FilterBar';

import { ITEM_FILTER_STATUS_ARRAY } from '../../constants/itemStatus';

function ProjectStatusFiltersBar(props) {
  return (
    <FilterBar
      optionList={ITEM_FILTER_STATUS_ARRAY}
      {...props}
    />
  );
}

export default ProjectStatusFiltersBar;
