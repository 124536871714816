import { useQuery } from 'react-query';

import { isEmptyValue } from '../utils/commonUtils';

import { getUserAddresses } from '../apis/userApi';

const useUserDeliveryInfo = (userID) => {
  return useQuery(
    ['getUserAddresses', userID],
    () => {
      if (isEmptyValue(userID)) {
        return null;
      }
      return getUserAddresses(userID);
    },
    {
      enabled: !isEmptyValue(userID),
    }
  );
};

export default useUserDeliveryInfo;
