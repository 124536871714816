import { BACKEND_SERVICE_URL } from "../constants";
import { getAuthorizedHeader, witchCacheResponse } from "../utils/apiUtils";

const convert2DImage = async (body) => {
  const requestOptions = {
    method: 'POST',
    headers: getAuthorizedHeader(),
    // cache: 'no-cache',
    body: JSON.stringify(body),
  };
  const url = `${BACKEND_SERVICE_URL}/images/convert`;
  const controller = new window.AbortController();
  const timeoutID = setTimeout(() => {
    controller.abort();
    throw new Error('Timeout');
  }, body.timeout);
  const response = await fetch(url, {
    ...requestOptions,
    signal: controller.signal,
  });
  clearTimeout(timeoutID);
  const resp = await response.json();
  if (!response.ok) {
    throw new Error(resp?.error);
  }
  return resp;
}

export const convert2DImageWitchCache = witchCacheResponse(
  convert2DImage,
  24 * 60 * 60, // 24 hour in seconds
);
