import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import { ITEM_STAGE_TYPE, ROCKET_QUOTE_ACCEPT_TYPE } from '../../constants';
import { getDaysElapsed } from '../../utils/dateTimeUtils';

import DaysElapsed from '../labels/DaysElapsed';
import { getItemImageUrl } from '../../utils/itemUtils';
import { colors } from '../../palette';
import DisplayItemName from './DisplayItemName';
import DisplayItemTech from './DisplayItemTech';
import { convertPriceToCurrency } from '../../utils/currencyUtils';
import { WATCHING_JOB_STATUS } from '../../constants/watchingJob';
import { WatchingJob } from '../WatchingJob';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: '21.6rem',
    maxWidth: '21.6rem',
    minHeight: '26.2rem',
    maxHeight: '26.2rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '18.8rem',
      maxWidth: '18.8rem',
      minHeight: '25.3rem',
      maxHeight: '25.3rem',
    }
  },
  media: {
    height: 250,
    backgroundSize: "contain",
    marginBottom: 16,
  },
  quotedTextWrapper: {
    zIndex: 10,
    position: "absolute",
    top: 30,
    right: 0,
    backgroundColor: colors.quotedLabelBlue,
    padding: "10px 20px 10px 20px",
    fontSize: 16,
    color: colors.fontWhite,
    boxShadow: `5px 4px 8px ${colors.bgGrey}`,
    fontWeight: "bold",
    backgroundImage: colors.colorBlueGradient,
    borderRadius: "8px 0px 0px 8px"
  },
  newLabelWrapper: {
    margin: "5px 10px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${colors.hotRed}`,
    borderRadius: "4px"
  },
  newTextWrapper: {
    textTransform: "uppercase",
    fontWeight: "bold",
    color: colors.hotRed
  },
  cardItemNameWrapper: {
    width: "265px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: 'flex',
    alignItems: 'center',
    columnGap: '2px'
  },
  cardItemContainer: {
    maxWidth: "340px"
  },
  cardDisplay: {
    display: "flex"
  },
  noSuggestedQuoteWrapper: {
    backgroundColor: colors.manualJob,
    padding: "5px 0px 5px 0px",
    fontSize: "10pt",
    color: 'black',
    borderRadius: 8,
    marginTop: 10,
    display: "flex",
    justifyContent: "center"
  },
  suggestedQuoteWrapper: {
    backgroundColor: colors.successLightGreen,
    padding: "5px 0px 5px 0px",
    fontSize: "10pt",
    color: colors.fontBlackGrey,
    borderRadius: 8,
    marginTop: 10,
    display: "flex",
    justifyContent: "center"
  },
  suggestedQuoteText: {
    marginRight: 5
  },
  suggestedQuoteCurrency: {
    fontWeight: "bold"
  },
  watchLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.lightGray,
    zIndex: 1,
    border: `2px solid ${colors.lightGray}`,
    borderRadius: "4px",
    width: "40px",
    height: '18px',
    justifyContent: 'center',
    margin: "5px 0",
  },
  watchLabelActive: {
    borderColor: colors.blue050,
  },
  containerLabel: {
    display: 'flex', 
    alignItems: 'center', 
    columnGap: '5px', 
    justifyContent: 'end', 
    width: '100%',
    flex: 1
  }
}));

function ItemDisplay(props) {
  const classes = useStyles();

  const { item, currency, exchangeRate } = props;
  const { verifiedDate, referenceName, watchingJobStatus, itemID, projectID } = item || {};

  const [isWatching, setIsWatching] = useState(watchingJobStatus === WATCHING_JOB_STATUS.ACTIVE)

  const daysElapsed = getDaysElapsed(verifiedDate);

  const ppePriceDisplay = () => {
    const showConfirmedJob = item.price &&
      (!item.rocketQuote || item.status === ITEM_STAGE_TYPE.ORDER_IN_PROGRESS);
    let priceStr;
    if (showConfirmedJob) {
      priceStr = convertPriceToCurrency({ price: item.price, currency, exchangeRate });
    }
    return (
      <div>
        {showConfirmedJob ? (
          <div className={classes.suggestedQuoteWrapper}>
            <div className={classes.suggestedQuoteText}>Confirmed Job:</div>
            <div className={classes.suggestedQuoteCurrency}>
              {priceStr}
            </div>
          </div>
        ) : (
          <div className={classes.noSuggestedQuoteWrapper}>RFQ</div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.cardItemContainer}>
      <div className={classes.cardDisplay}>
        <div className={classes.cardItemNameWrapper}>
          <DisplayItemName text={referenceName} />
          {daysElapsed <= 3 && (
            <div className={classes.newLabelWrapper}>
              <div className={classes.newTextWrapper}>new</div>
            </div>
          )}
        </div>
        <div className={classes.containerLabel}>
          <WatchingJob
            isWatching={isWatching} 
            setIsWatching={setIsWatching} 
            itemIDs={[itemID]} 
            projectID={projectID}
          />
          </div>
      </div>
      <DisplayItemTech text={item.technology} />
      <DaysElapsed daysElapsed={daysElapsed} />
      {ppePriceDisplay()}
    </div>
  );
}

function ItemDisplayCard(props) {
  const classes = useStyles();

  const { item, supplierOrders, currency, exchangeRate } = props;

  const renderQuotedLabel = (item) => {
    /**
     * if item is rocket quote and quote accepted type is ppe then show quoted label
     * when there is ppe quotation for that item
     */
    let shouldRender = false;
    if (item.rocketQuote && item.acceptQuoteType === ROCKET_QUOTE_ACCEPT_TYPE.PPE) {
      const ppeQuotes = supplierOrders.filter(order => order.itemID === item.itemID && !!order.ppeQuoteStatus);
      shouldRender = ppeQuotes.length > 0;
    } else {
      const itemOrders = supplierOrders.filter(order => order.itemID === item.itemID);
      shouldRender = itemOrders.length > 0;
    }
    if (shouldRender) {
      return (
        <div className={classes.quotedTextWrapper}>
          Quoted
        </div>
      );
    }
    return null;
  }

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <Link
          to={{ pathname: `/items/${item.itemID}` }}
          style={{ textDecoration: "none" }}
        >
          <div>
            <CardMedia
              className={classes.media}
              image={getItemImageUrl(item)}
              title={item.referenceName}
            />
            {renderQuotedLabel(item)}
          </div>
          <CardContent>
            <ItemDisplay
              item={item}
              currency={currency}
              exchangeRate={exchangeRate}
            />
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}
function mapStateToProps(state) {
  return {
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ItemDisplayCard);
