// Import settings
import React, { Fragment } from "react";

// Import material UI components
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/index";
import { Add as AddIcon } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";

// Import color palette
import { colors } from "../../palette";
import RfqSingleQuotationFormSingleView from "./RfqSingleQuotationFormSingleView";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  addFieldButtonWrapper: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  containerTitle: {
    margin: "6px 0",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.fontWhite,
  },
}));

function QuotationForm(props) {
  const classes = useStyles();

  const {
    inputFields,
    isLoading,
    userID,
    currency,
    remarks,
    role,
    suppliers,
    errors,
    dateOfExpiry,
    onAddFields,
    onRemoveFields,
    onInputFieldsChange,
    onRemarksChange,
    onQuoteDateChange,
    onQuoteUserIdChange,
    onSubmitQuote,
    isCmmReport
  } = props;

  const renderRfqSingleRowInput = (inputField, index) => {
    return (
      <Fragment key={`${inputField}~${index}`}>
        <RfqSingleQuotationFormSingleView
          index={index}
          currency={currency}
          inputField={inputField}
          errors={errors}
          isCmmReport={isCmmReport}
          onInputFieldsChange={(event) => onInputFieldsChange(index, event)}
          showDeleteButton={inputFields.length > 1}
          onRemoveFields={(event) => onRemoveFields(index, event)}
        />
      </Fragment>
    );
  }

  return (
    <Grid container fluid="true" spacing={3} direction="column">
      <Grid item className={classes.container}>
        <Grid item>
          <div>
            <Typography
              gutterBottom
              variant="body1"
              className={classes.containerTitle}
            >
              Enter quote(s):
            </Typography>
          </div>
          <div>
            {inputFields.map((inputField, index) => {
              return renderRfqSingleRowInput(inputField, index);
            })}
          </div>
          <div className={classes.addFieldButtonWrapper}>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => onAddFields()}
            >
              Add more quotes
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid item className={classes.container}>
        <Typography
          gutterBottom
          variant="body1"
          className={classes.containerTitle}
        >
          Remarks / Clarifications
        </Typography>
        <TextField
          required
          placeholder="Please enter any remarks (type NIL for no remarks)"
          multiline={true}
          variant="outlined"
          minRows={6}
          maxRows={6}
          fullWidth
          name="remarks"
          onChange={(event) => {
            onRemarksChange(event.target.value);
          }}
          value={remarks}
          inputProps={{
            maxLength: 8000,
          }}
        />
      </Grid>
      {role === "admin" || role === "reviewer" || role === "superadmin" ? (
        <Grid item className={classes.container}>
          <Typography
            gutterBottom
            variant="body1"
            className={classes.containerTitle}
          >
            Assign Quote to
          </Typography>
          <TextField
            placeholder="Supplier name"
            variant="outlined"
            id="userID"
            select
            fullWidth
            onChange={(event) => onQuoteUserIdChange(event.target.value)}
            value={userID}
            defaultValue=""
          >
            {suppliers.filter((supplier) => !["Factorem Instant Quote", "Factorem Rocket Quote"].includes(supplier.name))
              .map((supplier) => (
              <MenuItem key={supplier.userID} value={supplier.userID}>
                {supplier.name} ({supplier.email})
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      ) : (
        <div />
      )}
      <Grid item className={classes.container}>
        <Tooltip
          title="Quotation will automatically be removed from system beyond selected date"
          placement="right"
          arrow
        >
          <Typography gutterBottom variant="body1" className={classes.containerTitle}>
            Quote valid till:
          </Typography>
        </Tooltip>
        <DatePicker
          value={dateOfExpiry}
          onChange={(newDate) => onQuoteDateChange(newDate)}
          animateYearScrolling
          inputVariant="outlined"
          clearable
          clearLabel="No Preference"
        />
      </Grid>
      <Grid item className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          fullWidth
          onClick={(e) => onSubmitQuote(e)}
          disabled={isLoading}
          data-cy="submit-quote-btn"
        >
          Submit my quote(s)
        </Button>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </Grid>
  );
}

export default QuotationForm;
