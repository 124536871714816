import React from 'react';
import { Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import salesTillDateIcon from '../../assets/icons/sales_till_date.svg';
import orderFulfilledIcon from '../../assets/icons/order_fulfilled.svg';
import individualPartsMadeIcon from '../../assets/icons/individual_parts_made.svg';
import partnerTierIcon from '../../assets/icons/award.svg';
import { TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT } from '../../constants/partnerDataDashboardConstants';

/**
 *
 * @param {Object} props
 * @param {'sales-till-date' | 'orders-fulfilled' | 'individual-parts-made' | 'partner-tier'} props.type
 * @param {*} props.value
 */
function BoxDataDashboard(props) {
  const { type = 'sales-till-date', value = 'S$714,000' } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dataDashboardConstants = {
    'sales-till-date': {
      name: 'Sales Till Date',
      backgroundColor: '#d1e9fc',
      linearGradient:
        'linear-gradient(180deg, rgba(183, 209, 238, 0) 0%, #B7D1EE 51%)',
      img: salesTillDateIcon,
      colorForValue: '#051C64',
      colorForName: '#52679D',
      tooltip: TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.revenueEarned,
      height: '150px',
      imgSize: isMobile ? '35px' : '40px',
      imgContainerSize: isMobile ? '50px' : '65px',
      nameSize: '15px',
      valueSize: isMobile ? '35px' : '50px',
      marginName: '0 0 0 calc(50% - 125px)',
    },
    'orders-fulfilled': {
      name: 'Orders Fulfilled',
      backgroundColor: '#D0F2FE',
      linearGradient: 'linear-gradient(180deg, #B3D9F4 0%, #BCE0F7 51%)',
      img: orderFulfilledIcon,
      colorForValue: '#042979',
      colorForName: '#52679D',
      tooltip: TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.numberOfOrdersTillDate,
      height: '121px',
      imgSize: isMobile ? '25px' : '36px',
      imgContainerSize: isMobile ? '40px' : '65px',
      nameSize: '12px',
      valueSize: isMobile ? '25px' : ' 35px',
      marginName: isMobile ? '0' : '0 0 0 15px',
    },
    'individual-parts-made': {
      name: 'Individual Parts Made',
      backgroundColor: '#FFF7CC',
      linearGradient: 'linear-gradient(180deg, #FAEDC0 0%, #F5E5B3 51%)',
      img: individualPartsMadeIcon,
      colorForValue: '#7A4F01',
      colorForName: '#9F7D44',
      tooltip: TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.numberOfPartsProduced,
      height: '121px',
      imgSize: isMobile ? '25px' : '36px',
      imgContainerSize: isMobile ? '40px' : '65px',
      nameSize: '12px',
      valueSize: isMobile ? '25px' : ' 35px',
      marginName: isMobile ? '0' : '0 0 0 15px',
    },
    'partner-tier': {
      name: 'Partner Tier',
      backgroundColor: '#FFE7D9',
      linearGradient: 'linear-gradient(180deg, #FBDBCF 0%, #F9D7CB 51%)',
      img: partnerTierIcon,
      colorForValue: '#7A0B2E',
      colorForName: '#A95D5C',
      tooltip: TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.partnerTier,
      height: '46px',
      imgSize: '19px',
      imgContainerSize: '35px',
      borderRadius: '76px',
      nameSize: '12px',
      valueSize: isMobile ? '24px' : ' 28px',
      width: '180px',
      minWidth: 'auto',
      marginName: '0 10px 0 0',
      marginValue: '0',
      textAlign: 'left',
      padding: '0',
      customStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    },
  };
  return (
    <div
      style={{
        backgroundColor: dataDashboardConstants[type].backgroundColor,
        maxWidth: dataDashboardConstants[type].width || '100%',
        flex: '1 1 auto',
        paddingBottom: dataDashboardConstants[type].padding || '0.9rem',
        borderRadius: dataDashboardConstants[type].borderRadius || '12px',
        boxSizing: 'border-box',
        textAlign: 'center',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        border: '0.50px rgba(255, 255, 255, 0) solid',
        ...(dataDashboardConstants[type].customStyle || {}),
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={dataDashboardConstants[type].tooltip}>
          <div
            style={{
              borderRadius: '50%',
              minWidth: dataDashboardConstants[type].imgContainerSize,
              minHeight: dataDashboardConstants[type].imgContainerSize,
              display: 'grid',
              placeItems: 'center',
              margin: '6px',
              background: dataDashboardConstants[type].linearGradient,
            }}
          >
            <img
              style={{ height: dataDashboardConstants[type].imgSize }}
              src={dataDashboardConstants[type].img}
              alt={`Logo ${type}`}
            />
          </div>
        </Tooltip>
        <span
          style={{
            color: dataDashboardConstants[type].colorForName,
            fontSize: dataDashboardConstants[type].nameSize,
            fontWeight: 'bold',
            margin: dataDashboardConstants[type].marginName || '0',
            textAlign: dataDashboardConstants[type].textAlign || 'center',
          }}
        >
          {dataDashboardConstants[type].name}
        </span>
      </div>
      <h1
        style={{
          fontSize: dataDashboardConstants[type].valueSize,
          fontWeight: 'bold',
          margin: dataDashboardConstants[type].marginValue || '-15px 0 0',
          color: dataDashboardConstants[type].colorForValue,
        }}
      >
        {value}
      </h1>
    </div>
  );
}

export default BoxDataDashboard;
