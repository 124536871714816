export const HOW_YOU_HEAR_ANSWERS_MAPPING = [
  {
    display: 'Google',
    key: 'Google',
    details: false,
  },
  {
    display: 'Referred by a friend or colleague',
    key: 'Referred by a friend or colleague',
    label: 'Name of Referral',
    details: true,
  },
  {
    display: 'Startup Ecosystem',
    key: 'Startup Ecosystem (Incubators, Startup program)',
    label: 'Name of Referral',
    details: true,
  },
  {
    display: 'Educational Institution',
    key: 'Educational Institution (Universities, Polytechnics, ITE, etc.)',
    label: 'Name of Referral',
    details: true,
  },
  {
    display: 'Social media',
    key: 'Social media (Linkedin, Facebook, etc.)',
    label: 'Name of Referral',
    details: true,
  },
  {
    display: 'Others',
    key: 'Others',
    label: 'Tell us more',
    details: true,
  },
];
