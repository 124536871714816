import React, { useReducer } from 'react';

import ContactSupportPopup from '../components/forms/part-upload-step-two/ContactSupportPopup';

import { isEmptyValue } from '../utils/commonUtils';


// ---------------------------------------------------------------------------------

function withContactSupportPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
    }

    const [
      localState,
      updateLocalState,
    ] = useReducer(
      (prev, next) => {
        if (isEmptyValue(next)) {
          return defaultLocalState;
        }
        return { ...prev, ...next };
      },
      defaultLocalState,
    );

    return (
      <>
        <WrappedComponent
          updateContactSupportPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <ContactSupportPopup
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withContactSupportPopupHOC;
