export const CURRENCY_CODE = {
  SGD: 'SGD',
  MYR: 'MYR',
  USD: 'USD',
  AUD: 'AUD',
  EUR: 'EUR',
  GBP: 'GBP',
}

export const CURRENCY_SYMBOL_MAPPING = {
  [CURRENCY_CODE.SGD]: 'S$',
  [CURRENCY_CODE.MYR]: 'RM',
  [CURRENCY_CODE.USD]: 'US$',
  [CURRENCY_CODE.AUD]: 'A$',
  [CURRENCY_CODE.EUR]: '€',
  [CURRENCY_CODE.GBP]: '£',
}

export const DEFAULT_MYR_EXCHANGE_RATE = 3.3;
