import React, { useReducer } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

import { isEmptyValue } from '../../utils/commonUtils';


// ------------------------------------------------------------------


function Header(props) {
  return (
    <Typography
      style={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        ...(props.style || {}),
      }}
    >
      {props.children}
    </Typography>
  );
}

function FtrAccordion(props) {
  const {
    header = 'Default Header',
    details,
  } = props;

  const defaultLocalState = {
    expandedAccordion: false,
  }

  const [
    localState,
    updateLocalState,
  ] = useReducer(
    (prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    },
    defaultLocalState,
  );

  const renderHeader = () => {
    if (typeof header === 'string') {
      return (
        <Header>
          {header}
        </Header>
      );
    }

    return header;
  };

  return (
    <Accordion
      key={'dfm-defects-extraction'}
      expanded={localState.expandedAccordion}
      onChange={() => updateLocalState({ expandedAccordion: !localState.expandedAccordion })}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        {renderHeader()}
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {details}
      </AccordionDetails>
    </Accordion>
  );
}

FtrAccordion.Header = Header;

export default FtrAccordion;
