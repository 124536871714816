// Import settings
import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
// import _ from "lodash";
// import { Cookies } from "react-cookie";
// const cookies = new Cookies();

// Import customized components
// import CustomerQuoteTable from "../../components/tables/CustomerQuoteTable";

// // Import material UI components
// import { makeStyles } from "@material-ui/core/styles";

// // Style component
// const useStyles = makeStyles(theme => ({
//   formComponent: {
//     height: "75%",
//     padding: "0px 45px 25px"
//   },
//   body: {
//     height: "100%"
//   },
//   paper: {
//     paddingLeft: theme.spacing(8),
//     paddingRight: theme.spacing(8),
//     paddingTop: theme.spacing(5),
//     paddingBottom: theme.spacing(6)
//   }
// }));

// Main program
function ViewCustomerQuotes(props) {
  // const classes = useStyles();
  // const { userID } = props;

  // // Declare component states
  // const [compareDialog, setCompareDialog] = useState(false);
  // const [items, setAllItems] = useState([]);

  // // Function that will run when the page is loaded to get quotes
  // const getBuyerItemsAndQuotes = id => {
  //   const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/buyer/${id}`;
  //   return fetch(requestUrl, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json; charset=utf-8",
  //       Authorization: `Bearer ${cookies.get("token")}`
  //     }
  //   })
  //     .then(resp => {
  //       resp.json().then(response =>
  //         setAllItems(
  //           _.sortBy(
  //             response.data.filter(object => {
  //               return object.status > 1 && object.status < 4;
  //             }),
  //             item => item.itemID
  //           ).reverse()
  //         )
  //       );
  //     })
  //     .catch(e => console.log(e));
  // };

  // useEffect(() => {
  //   getBuyerItemsAndQuotes(userID);
  // }, []);

  return (
    <Redirect
      to={{
        pathname: "/manage-projects",
        state: { from: props.location }
      }}
    />
    // <div className={classes.body}>
    //   <Fragment>
    //     <div className={classes.formComponent}>
    //       <CustomerQuoteTable
    //         handleClose={() => setCompareDialog(false)}
    //         dialog={compareDialog}
    //         data={items}
    //       />
    //     </div>
    //   </Fragment>
    // </div>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth.user.userID
  };
}

function matchDispatchToProps(dispatch, props) {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);
export default withRouter(withConnect(ViewCustomerQuotes));
