import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { setUnitType } from "./auth";

export const GET_PROFILE_LOADING = "GET_PROFILE_LOADING";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_SUCCESS";
export const EDIT_PROFILE_LOADING = "EDIT_PROFILE_LOADING";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILURE = "EDIT_PROFILE_FAILURE";

const editProfileSuccess = profile => {
  toast.success("Your profile has been updated!", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: EDIT_PROFILE_SUCCESS,
    profile
  };
};

const editProfileFailure = () => {
  toast.error("Unable to edit profile. Please try again later.", {
    position: toast.POSITION.TOP_RIGHT
  });
  return {
    type: EDIT_PROFILE_FAILURE
  };
};

const getProfile = () => {
  return {
    type: GET_PROFILE_LOADING
  };
};

const getProfileSuccess = profile => {
  return {
    type: GET_PROFILE_SUCCESS,
    profile
  };
};

const getProfileFailure = () => {
  return {
    type: GET_PROFILE_FAILURE
  };
};

const cookies = new Cookies();

export const editUserProfile = newProfile => (dispatch, getState) => {
  const userId = getState().auth.user.userID;
  const role = getState().auth.user.role;
  const creditType = getState().auth.user.creditType;
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/user/edit/${userId}`;

  const editedProfile = {
    name: newProfile.editProfileName,
    role: role,
    email: role === "buyer" ? newProfile.email : newProfile.editProfileEmail,
    companyName: newProfile.editCompanyName,
    companyDesc: newProfile.editCompanyDesc,
    secondaryName: newProfile.editSecondaryName,
    secondaryEmail: newProfile.editSecondaryEmail,
    secondaryContact: newProfile.editSecondaryContact,
    address: newProfile.editProfileAddress,
    contact: newProfile.editProfileContact,
    profilePic: newProfile.editProfileImg,
    creditType: creditType,
    unitType: newProfile.userUnits,
  };

  fetch(requestUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.get("token")}`
    },
    body: JSON.stringify(editedProfile)
  })
    .then(response => {
      if (!response.ok) {
        dispatch(editProfileFailure());
      } else {
        dispatch(editProfileSuccess(editedProfile));
        dispatch(setUnitType(newProfile.userUnits))
      }
    })
    .catch(() => {
      dispatch(editProfileFailure());
    });
};

export const getUserProfile = () => (dispatch, getState) => {
  const userId = getState().auth.user.userID;
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/user/${userId}`;
  fetch(requestUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.get("token")}`
    }
  })
    .then(function(response) {
      dispatch(getProfile());
      response.json().then(profile => {
        if (profile.success) {
          dispatch(getProfileSuccess(profile.data));
        } else {
          dispatch(getProfileFailure());
        }
      });
    })
    .catch(function() {
      dispatch(getProfileFailure());
    });
};
