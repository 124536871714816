// Import settings
import React, { useState, useRef } from "react";

// Import material UI components
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";

function DragDrop(props) {
  const {
    id = 'cad-files',
    text,
    handleFiles,
    multiple = true,
  } = props;
  const cadFilesInput = useRef(null);
  const handleFilesInput = files => {
    handleFiles(files);
    cadFilesInput.current.value = "";
  };

  const [dragging, setDragging] = useState(false);
  const [isEnter, setIsEnter] = useState(false);

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(true);
  };
  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(false);
  };
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    setIsEnter(false);
    handleFilesInput(e.dataTransfer.files);
  };

  return (
    <div style={{ marginTop: 10 }}>
      <input
        id={id}
        ref={cadFilesInput}
        type="file"
        multiple={multiple}
        onChange={evt => handleFilesInput(evt.target.files)}
        onClick={(event) => event.target.value = null}
        style={{
          display: "none"
        }}
      />
      <label
        htmlFor={id}
        data-cy={id}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div
          style={{
            outline: "dashed grey 2px",
            outlineOffset: isEnter ? "-20px" : "-10px",
            backgroundColor: isEnter ? "#f6f6f6" : "#ececec",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          }}
        >
          <CloudUploadIcon style={{ margin: "5px" }} />
          {dragging ? "Drop design files here" : text}
        </div>
      </label>
    </div>
  );
}

export default DragDrop;
