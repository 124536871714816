import { getAuthorizedHeader } from "../utils/apiUtils";

import { BACKEND_SERVICE_URL } from "../constants";

export const getAllPpeControlConfigs = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppeSettings`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updatePpeControlConfigs = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppeSettings/update`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const updatePpeControlConfigsV2 = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/ppeSettings`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};
