import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import {
  Edit as EditIcon,
  DeleteOutline as DeleteIcon,
  Check as CheckIcon,
  DragIndicator as DragIndicatorIcon,
} from '@material-ui/icons';

import { ColorPicker } from 'material-ui-color';

import MenuList from './MenuList';

import { convertCamelCaseToTitle } from '../utils/stringUtils';

import { ROLE_TYPES } from '../constants';

import { colors } from '../palette';

const useStyles = makeStyles(() => ({
  item: {
    border: '1px solid #000',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: colors.factoremBlue,
      color: 'white !important',
    },
    padding: '8px 16px',
    color: '#000 !important',
    cursor: 'default',
  },
  active: {
    backgroundColor: colors.factoremBlue,
    color: 'white !important',
  },
  itemMenu: {
    textDecoration: 'none',
    color: colors.fontGrey,
    fontSize: '0.9rem',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
    '&:is(:hover, :active)': {
      backgroundColor: colors.factoremBlue,
      color: colors.fontWhite,
    },
  },
  containerInput: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:hover $containerIcons': {
      display: 'block',
    },
    '&:hover $checkIcon': {
      display: 'block',
    },
  },
  isInputActive: {
    border: `1px solid ${colors.hotRed}`,
  },
  icons: {
    position: 'absolute',
    right: '5px',
    top: '50%',
    transform: 'translateY(calc(-50%))',
    zIndex: 1,
    height: '23px',
  },
  containerIcons: {
    display: 'none',
  },
  icon: {
    borderRadius: '50%',
    width: '23px',
    height: '23px',
    cursor: 'pointer',
  },
  editIcon: {
    backgroundColor: colors.secondaryBlue,
    '&:hover': {
      opacity: '80%',
    },
  },
  deleteIcon: {
    backgroundColor: colors.hotRed,
    fill: colors.fontWhite,
    marginLeft: '5px',
    '&:hover': {
      opacity: '80%',
    },
  },
  checkIcon: {
    backgroundColor: colors.successGreen,
    display: 'none',
    '&:hover': {
      opacity: '80%',
    },
  },
  detailMaterial: {
    backgroundColor: colors.secondaryBlue,
    '&:hover': {
      color: 'black !important',
      backgroundColor: colors.secondaryBlue,
    },
    '& .MuiInput-root': {
      color: 'black !important',
    },
  },
  containerMenu: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
  },
  colorPicker: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      width: 'auto',
      alignItems: 'center',
    },
  },
  addInput: {
    border: '1px solid #000',
    borderRadius: '5px',
    color: '#000 !important',
    cursor: 'default',
    width: 'auto !important',
    padding: '0',
  },
  addInputPart: {
    width: '100%',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '8px 16px',
    '& .MuiCheckbox-colorSecondary': {
      color: 'black',
      paddingLeft: '14px',
    },
    '&:hover': {
      color: 'white !important',
      '& .MuiCheckbox-colorSecondary': {
        color: 'white',
      },
    },
  },
}));

/**
 * A field to handle customer input, with base, text-field, select and color-options input types
 * @param {Object} props
 * @param {String} props.className
 * @param {() => void} props.onClick
 * @param {String} props.value
 * @param {'base' | 'text-field' | 'select' | 'color-options'} props.type
 * @param {String} props.keyName
 * @param {Array} props.listMenu
 * @param {() => void} props.onOk
 * @param {String} props.defaultColor
 * @param {() => void} props.onDelete
 * @param {Boolean} props.disabledDelete
 * @param {Boolean} props.disabledEdit
 */
export const CustomerInput = (props) => {
  const {
    value,
    className,
    onClick,
    type = 'base',
    keyName = '',
    listMenu,
    onOk,
    defaultColor,
    onDelete,
    disabledDelete = false,
    disabledEdit = false,
    providedDrag = {},
  } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);
  const [isInputActive, setIsInputActive] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [colorVal, setColorVal] = useState(defaultColor);

  const userRole = useSelector(state => state.auth?.user?.role);

  const inputElement = useRef(null);
  const label = convertCamelCaseToTitle(keyName);

  useEffect(() => {
    setInputValue(value);
    setColorVal(defaultColor);
  }, [value, listMenu, defaultColor]);

  const renderInputType = () => {
    switch (type) {
      case 'base':
        return (
          <InputBase
            inputRef={inputElement}
            multiline
            fullWidth
            disabled
            className={className}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={
              isInputActive
                ? {
                    border: `1px solid ${colors.hotRed}`,
                  }
                : { zIndex: '-1' }
            }
          />
        );
      case 'text-field':
        return (
          <TextField
            disabled
            inputRef={inputElement}
            label={convertCamelCaseToTitle(keyName)}
            value={inputValue}
            className={classes}
            multiline
            fullWidth
            style={{
              outline: 'none',
            }}
            onChange={(e) => setInputValue(e.target.value)}
          />
        );
      case 'select':
        return (
          <FormControl fullWidth>
            <InputLabel id='select-default-surface-finish'>{label}</InputLabel>
            <Select
              labelId='select-default-surface-finish'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              disabled={isDisable}
            >
              {listMenu &&
                listMenu.map((el, i) => (
                  <MenuItem key={i} value={el}>
                    {el}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        );
      case 'color-options':
        return (
          <div className={classes.colorPicker}>
            <InputBase
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              disabled={isDisable}
            />
            {inputValue !== 'Custom Color' && (
              <ColorPicker
                value={colorVal}
                onChange={(color) => !isDisable && setColorVal(color)}
                disablePlainColor
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', width: '100%' }}
    >
      {providedDrag.dragHandleProps && userRole === ROLE_TYPES.SUPER_ADMIN && (
        <div style={{ marginLeft: '-5px' }} {...providedDrag.dragHandleProps}>
          <DragIndicatorIcon />
        </div>
      )}
      <div
        onClick={onClick}
        className={clsx(
          classes.containerInput,
          ['text-field', 'select', 'color-options'].includes(type) && [
            classes.item,
            classes.detailMaterial,
          ]
        )}
        style={
          (['text-field', 'select', 'color-options'].includes(type) &&
            isInputActive && {
              border: `1px solid ${colors.hotRed}`,
            }) ||
          {}
        }
        ref={providedDrag.innerRef}
        {...providedDrag.draggableProps}
      >
        {renderInputType()}
        <div className={clsx(classes.icons)}>
          {isInputActive ? (
            <CheckIcon
              className={`${classes.icon} ${classes.checkIcon}`}
              onClick={() => {
                setIsInputActive(false);
                setIsDisable(true);
                if (inputElement.current) {
                  inputElement.current.disabled = true;
                }
                onOk(inputValue, { colorVal });
              }}
            />
          ) : (
            <div className={classes.containerIcons}>
              {!disabledEdit && userRole === ROLE_TYPES.SUPER_ADMIN && (
                <EditIcon
                  className={`${classes.icon} ${classes.editIcon}`}
                  onClick={() => {
                    setIsInputActive(true);
                    setIsDisable(false);
                    if (inputElement.current) {
                      inputElement.current.disabled = false;
                      inputElement.current.focus();
                    }
                  }}
                />
              )}

              {!disabledDelete && userRole === ROLE_TYPES.SUPER_ADMIN && (
                <DeleteIcon
                  className={`${classes.icon} ${classes.deleteIcon}`}
                  onClick={() => onDelete(inputValue)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {Object} props
 * @param {String} props.className
 * @param {() => void} props.onClick
 */
export const AddNewInput = (props) => {
  const { onClick, className } = props;
  const userRole = useSelector(state => state.auth?.user?.role);
  if (userRole !== ROLE_TYPES.SUPER_ADMIN) {
    return null;
  }
  return (
    <ListItem
      button
      className={className}
      style={{
        textAlign: 'center',
        background: colors.buttonColorBlueGradient,
      }}
      onClick={onClick}
    >
      <ListItemText primary='+' />
    </ListItem>
  );
};

/**
 * A new input field "+" with checkbox
 * @param {Object} props
 * @param {String} props.className
 * @param {String} props.checkboxName
 * @param {String} props.checkboxLabel
 * @param {Boolean} props.checked
 * @param {() => void} props.onAdd
 * @param {() => void} props.onChangeCheckbox
 * @param {boolean} props.disablePlusButton
 * @param {boolean} props.disabledCheckbox
 */
export const AddNewInputCheckbox = (props) => {
  const {
    onAdd = () => null,
    checkboxName = 'newCheckbox',
    checkboxLabel = 'Checkbox',
    checked = false,
    onChangeCheckbox = () => null,
    disablePlusButton = false,
    disabledCheckbox = false
  } = props;
  const userRole = useSelector(state => state.auth?.user?.role);
  if (userRole !== ROLE_TYPES.SUPER_ADMIN) {
    return null;
  }

  const classes = useStyles();
  return (
    <ListItem
      button
      className={clsx(classes.addInput)}
      style={{
        textAlign: 'center',
        background: colors.buttonColorBlueGradient,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!disablePlusButton && (
        <>
          <ListItemText
            onClick={onAdd}
            primary='+'
            className={classes.addInputPart}
          />
          <Divider style={{ width: '100%', backgroundColor: colors.lightGray }} />
        </>
      )}
      {!disabledCheckbox && (
        <FormControlLabel
          className={classes.addInputPart}
          style={{ margin: '0' }}
          control={
            <Checkbox
              checked={checked}
              onChange={onChangeCheckbox}
              name={checkboxName}
            />
          }
          label={checkboxLabel}
        />
      )}
    </ListItem>
  );
};


/**
 * A new input field "+", if you click it then appear 2 menu options
 * @param {Object} props
 * @param {String} props.label1
 * @param {String} props.label2
 * @param {() => void} props.onClick1
 * @param {() => void} props.onClick2
 * @param {boolean} props.disableLabel1
 * @param {boolean} props.disableLabel2
 */
export const AddNewInput2Options = (props) => {
  const {
    onClick1,
    onClick2,
    label1 = 'Label 1',
    label2 = 'Label 2',
    disableLabel1 = false,
    disableLabel2 = false
  } = props;
  const classes = useStyles();
  const userRole = useSelector(state => state.auth?.user?.role);
  if (userRole !== ROLE_TYPES.SUPER_ADMIN) {
    return null;
  }
  return (
    <MenuList
      parent={
        <ListItem
          button
          className={`${classes.item}`}
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            background: colors.buttonColorBlueGradient,
          }}
        >
          <ListItemText primary='+' />
        </ListItem>
      }
    >
      <div className={classes.containerMenu}>
        {!disableLabel1 && (
          <div onClick={onClick1} className={classes.itemMenu}>
            {label1}
          </div>
        )}
        {!disableLabel2 && (
          <div onClick={onClick2} className={classes.itemMenu}>
            {label2}
          </div>
        )}
      </div>
    </MenuList>
  );
};
