import React from 'react';

import {FtrButton} from '../ftr-components';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';
import Title from '../Title';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function WarningPopup(props) {
  const classes = useStyles();

  const { open, onClose, title, body } = props;

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="warning-dialog-title">
        <Title contentTitle={title} size="small" />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '1rem',
            textAlign: 'center',
          }}
        >
          <React.Fragment>
            <Typography>{body}</Typography>
          </React.Fragment>
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FtrButton
            color='blue'
            onClick={onClose}
          >
            OK
          </FtrButton>
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default WarningPopup;
