import React from 'react';

function DataGridWrapTextCell({ text, style = {}, ...props }) {
  return (
    <div
      style={{
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        lineHeight: 'normal',
        overflowY: 'auto',
        maxHeight: '100%',
        ...style,
      }}
      {...props}
    >
      {text}
    </div>
  );
}

export default DataGridWrapTextCell;
