import JSZip from "jszip";
import { asyncReduce } from "./arrayUtils";

export const extractFilesFromZipFile = async (file) => {
  const zip = new JSZip();
  const content = await file.arrayBuffer();
  const unzippedFiles = await zip.loadAsync(content);

  const extractedFiles = [];
  for (const [relativePath, file] of Object.entries(unzippedFiles.files)) {
    // Skip unwanted files
    if (
      relativePath.startsWith('__MACOSX/')
      || relativePath.startsWith('._')
      || relativePath.startsWith('.')
      || relativePath.endsWith('.DS_Store')
      || relativePath.endsWith('Thumbs.db')
      || relativePath.endsWith('Desktop.ini')
      || file.dir // Skip folders
    ) {
      continue;
    }
    const fileContent = await file.async("blob"); // Read as Blob for upload
    const newFile = new File([fileContent], file.name, { type: file.type });
    extractedFiles.push(newFile);
  }

  return extractedFiles;
}


export const extractAllZipFiles = async (zipFileList = []) => {
  return asyncReduce(
    zipFileList,
    async (acc, zipFile) => {
      const listExtractedFiles = await extractFilesFromZipFile(zipFile);
      acc = acc.concat(listExtractedFiles);
      return acc;
    },
    [],
  );
}
