import React, { useReducer } from 'react';

import { Button, Tooltip } from '@material-ui/core';

import { PriorityHigh } from '@material-ui/icons';

function DeleteWithConfirmButton(props) {
  const {
    label = 'Delete',
    onClick = () => { },
    toolTip = '',
    width = 80,
  } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      isClicked: false,
      toolTip,
    }
  );

  return (
    <Tooltip
      title={localState.toolTip}
    >
      <Button
        variant="contained"
        onClick={() => {
          if (localState.isClicked) {
            onClick();
            updateLocalState({
              isClicked: false,
              toolTip,
            });
          } else {
            updateLocalState({
              isClicked: true,
              toolTip: 'Click one more time to confirm deletion',
            });
            setTimeout(() => {
              // reset state
              updateLocalState({
                isClicked: false,
                toolTip,
              });
            }, 3000);
          }
        }}
        style={{
          backgroundColor: 'red',
          color: 'white',
          width,
        }}
      >
        {localState.isClicked ? <PriorityHigh /> : label}
      </Button>
    </Tooltip>
  );
}

export default DeleteWithConfirmButton;
