export const getLogoFromEmail = async (email) => {
  const domain = email.split('@')[1];
  const response = await fetch(`https://logo.clearbit.com/${domain}`);

  if (!response.ok) {
    throw new Error('Error fetching logo');
  }

  const logoUrl = response.url; // Fetch gives the final URL of the resource
  return logoUrl;
};
