import { 
  BUYER_UPLOAD_PARTS_TOUR,
  BUYER_UPLOAD_PARTS_SUMMARY_TOUR,
  CLEAR_TOUR
} from "../components/tour/guidedTours/tourConstants";

export const buyerUploadPartsTour = (tour) => {
  return {
    type: BUYER_UPLOAD_PARTS_TOUR,
    tour,
  };
};

export const buyerUploadPartsSummaryTour = (tour) => {
  return {
    type: BUYER_UPLOAD_PARTS_SUMMARY_TOUR,
    tour,
  }
}

export const clearTour = () => {
  return {
    type: CLEAR_TOUR
  }
}
