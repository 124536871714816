import React from 'react';
import { Switch, FormControlLabel, FormGroup } from '@material-ui/core';

const ToggleColumn = ({ toggles, onToggleChange }) => {
  return (
    <FormGroup>
      {Object.keys(toggles).map((key) => (
        <FormControlLabel
          key={key}
          control={
            <Switch
              checked={toggles[key].isChecked}
              onChange={onToggleChange}
              name={toggles[key].name}
              color="primary"
              disabled={toggles[key].isDisabled || false}
            />
          }
          label={toggles[key].name}
        />
      ))}
    </FormGroup>
  );
};

export default ToggleColumn;
