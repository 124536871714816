import React from 'react';

import { Chip, Tooltip, Avatar } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles/index";
import {
  ORDER_READY_STATUS_CHIPS,
} from '../constants/orderReadyConstants';

import { MenuItem, Menu } from '@material-ui/core';

function StatusDisplayAdmin(props) {
  const {
    handleAdminEditStatus,
    chipDetails
  } = props;

  const useStyles = makeStyles({
    chip: {
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0px 3px 5px 2px rgba(0, 0, 0, 0.3)',
      },
      minWidth: '150px',
    },
  });
  const classes = useStyles();

  const [openDropDown, setOpenDropDown] = React.useState(false);

  const handleClick = (event) => {
    setOpenDropDown(event.currentTarget);
  };

  const handleClose = () => {
    setOpenDropDown(false);
  };

  const handleMenuItemClick = (status) => {
    handleAdminEditStatus(status);
    handleClose();
  };
  
  const renderChip = (chipDetails) => {
    return (
      <Chip
        avatar={
          <Avatar style={{ backgroundColor: 'transparent' }}>
            {chipDetails.config.icon}
          </Avatar>}
        label={chipDetails.config.statusStr}
        onClick={handleClick}
        className={`${classes.chip}`}
        style={{backgroundColor: chipDetails.config.bgColor, color: chipDetails.config.textColor}}
      />)
  }

  return (
    <div>
      <Tooltip title={chipDetails.config.toolTip}>
        {renderChip(chipDetails)}
      </Tooltip>
      <Menu
        id="status-menu"
        anchorEl={openDropDown}
        keepMounted
        open={Boolean(openDropDown)}
        onClose={handleClose}
      >
        {ORDER_READY_STATUS_CHIPS
          .map((chipDetails) => (
          <MenuItem key={chipDetails.status} onClick={() => handleMenuItemClick(chipDetails.status)}>
            {renderChip(chipDetails)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default StatusDisplayAdmin;