import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Container,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useQuery } from 'react-query';
import {
  createPlatformFeeRules,
  getPlatformFeeRulesLatest,
} from '../../apis/platformFeeApi';
import { ROLE_TYPES } from '../../constants';
import { cloneDeep } from 'lodash';
import {
  getConfigByKey,
  updateConfiguration,
} from '../../apis/configurationApi';
import { KEY_CONFIGURATION } from '../../constants/configurations';
import { notifySuccess } from '../../services/notificationService';
import { colors } from '../../palette';
import BlueButton from '../../components/buttons/BlueButton';
import { PLATFORM_FEE_RULE_KEY } from '../../constants/platformFeeConstants';
import PriceFee from '../../components/PriceFee';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  rowInput: { display: 'flex', gap: '1rem', alignItems: 'center' },
  priceInput: {
    width: '430px',
  },
  input: {
    width: '150px !important',
  },
  priceText: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    textAlign: 'center',
    width: '100px !important',
    '& > span:nth-child(1)': {
      textAlign: 'start',
    },
    '& > span:nth-child(2)': {
      textAlign: 'center',
      paddingLeft: '0.5rem',
    },
    '& > span:nth-child(3)': {
      textAlign: 'end',
    },
  },
}));

function PlatformFee(props) {
  const classes = useStyles();
  const { role } = props;
  const { data: platformFeeLatest, isLoading } = useQuery(
    'getPlatformFeeRulesLatest',
    getPlatformFeeRulesLatest
  );
  const { data: capPlatformFeeApi } = useQuery('getCapPlatformFee', () =>
    getConfigByKey(KEY_CONFIGURATION.CAP_PLATFORM_FEE_CONFIGURATION)
  );
  const [platformFeeLatestRules, setPlatformFeeLatestRules] = useState([]);
  const [capPlatformFee, setCapPlatformFee] = useState(0);

  useEffect(() => {
    if (platformFeeLatest) {
      setPlatformFeeLatestRules(platformFeeLatest.rules);
    }
  }, [platformFeeLatest]);

  useEffect(() => {
    if (capPlatformFeeApi) {
      setCapPlatformFee(capPlatformFeeApi.value);
    }
  }, [capPlatformFeeApi]);

  if (isLoading) return null;

  const handleChangeValue = ({ value, key, index }) => {
    if (Number(value) < 0) return;
    const newPlatformFeeLatestRules = cloneDeep(platformFeeLatestRules);
    if (key === PLATFORM_FEE_RULE_KEY.LOWER_THAN) {
      newPlatformFeeLatestRules[index + 1][
        PLATFORM_FEE_RULE_KEY.GREATER_THAN_EQUAL
      ] = Number(value);
    }
    newPlatformFeeLatestRules[index] = {
      ...newPlatformFeeLatestRules[index],
      [key]: Number(value),
    };

    setPlatformFeeLatestRules(newPlatformFeeLatestRules);
  };

  const handleSubmitButtonClick = () => {
    updateConfiguration({
      key: KEY_CONFIGURATION.CAP_PLATFORM_FEE_CONFIGURATION,
      value: Number(capPlatformFee),
    }).then(() => {
      createPlatformFeeRules({
        rules: platformFeeLatestRules,
      }).then(() => {
        notifySuccess('Successfully updated service fee');
      });
    });
  };

  const arrangeRowRules = (newPlatformFeeLatestRules) => {
    const platformFeeRulesValues = Object.values(newPlatformFeeLatestRules);
    platformFeeRulesValues.forEach((rule, index) => {
      if (
        rule[PLATFORM_FEE_RULE_KEY.LOWER_THAN] >= 0 &&
        !!rule[[PLATFORM_FEE_RULE_KEY.GREATER_THAN_EQUAL]]
      ) {
        newPlatformFeeLatestRules[index + 1][
          PLATFORM_FEE_RULE_KEY.GREATER_THAN_EQUAL
        ] = Number(rule[PLATFORM_FEE_RULE_KEY.LOWER_THAN]);
      }
    });
    return newPlatformFeeLatestRules;
  };

  const handleAddNewRowRules = (index) => {
    if (role !== ROLE_TYPES.SUPER_ADMIN) return;
    const newPlatformFeeLatestRules = cloneDeep(platformFeeLatestRules);
    const DEFAULT_RULE = { lt: 0, gte: 0, fee: 0 };
    newPlatformFeeLatestRules.splice(index, 0, DEFAULT_RULE);
    const newPlatformFeeRules = arrangeRowRules(newPlatformFeeLatestRules);
    setPlatformFeeLatestRules(newPlatformFeeRules);
  };

  const handleRemoveRowRules = (index) => {
    if (role !== ROLE_TYPES.SUPER_ADMIN) return;
    const newPlatformFeeLatestRules = cloneDeep(platformFeeLatestRules);
    newPlatformFeeLatestRules.splice(index, 1);
    const newPlatformFeeRules = arrangeRowRules(newPlatformFeeLatestRules);
    setPlatformFeeLatestRules(newPlatformFeeRules);
  };

  return (
    <div className={classes.body}>
      <Container className={classes.container}>
        <div>
          <Typography variant='h6'>Service Fee Rules</Typography>
          <Divider />
        </div>
        <div style={{ width: '800px' }}>
          {platformFeeLatestRules.map((rule, index) => (
            <PriceFee
              key={index}
              rule={rule}
              index={index}
              handleChangeValue={handleChangeValue}
              handleAddNewRowRules={handleAddNewRowRules}
              handleRemoveRowRules={handleRemoveRowRules}
              totalRules={platformFeeLatestRules.length}
            />
          ))}
        </div>
      </Container>
      <Container className={classes.container}>
        <div>
          <Typography variant='h6'>CAP Service Fee</Typography>
          <Divider />
        </div>
        <TextField
          disabled={role !== ROLE_TYPES.SUPER_ADMIN}
          label='CAP Service Fee'
          variant='outlined'
          type='number'
          onChange={(evt) => setCapPlatformFee(evt.target.valueAsNumber)}
          value={capPlatformFee}
          className={classes.input}
          margin='dense'
          InputProps={{
            endAdornment: <InputAdornment position='end'>%</InputAdornment>,
          }}
        />
      </Container>
      <div className={classes.saveButtonWrapper}>
        <BlueButton
          btnContent={'Save Changes'}
          onBtnClick={() => handleSubmitButtonClick()}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(PlatformFee));
