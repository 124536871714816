import React from 'react';

import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { FtrH5, FtrLightText } from '../../../ftr-components/FtrTypography';
import UploadFilesField from '../../form-fields/UploadFilesField';
import { colors } from '../../../../palette';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      borderRadius: '1rem',
      width: 'auto',
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    cursor: 'pointer',
  },
}));

function ReplacePartPopup(props) {
  const {
    open,
    technology,
    onClose = () => { },
    handleCadFilesChange = () => { },
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <FtrH5>
          Replace part
        </FtrH5>
      </DialogTitle>
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
      <DialogContent
        className={classes.dialogContent}
      >
        <FtrLightText>
          New part upload will replace existing file while keeping all configurations.
        </FtrLightText>
        <UploadFilesField
          style={{ marginTop: '2rem' }}
          id="replace-cad-part"
          fullWidth
          technology={technology}
          handleFiles={(files) => {
            handleCadFilesChange(files);
            onClose();
          }}
          multiple={false}
          deniedFileTypes={'zip'}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ReplacePartPopup;
