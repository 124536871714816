import { useMemo } from "react";
import { get } from "lodash";

import { isEmptyValue } from "../utils/commonUtils";


// --------------------------------------------------------------------------------------------

export const useMuiTableFilterData = ({
  data = [],
  searchStr = '',
  columns = [],
}) => {
  const filteredData = useMemo(() => {
    if (isEmptyValue(searchStr)) {
      return data;
    }

    const _filteredData = data?.filter(data => {
      for (const col of columns) {
        const valueGetterFunc = col.valueGetter;
        let value = '';
        if (typeof valueGetterFunc === 'function') {
          value = valueGetterFunc({ row: data }) ?? '';
          value = String(value).toLowerCase();
        } else {
          value = `${get(data, col.field ?? '') ?? ''}`.toLowerCase();
        }
        if (value.includes(searchStr.toLowerCase())) {
          return true;
        }
      }
      return false;
    });

    return _filteredData;
  }, [
    data,
    searchStr,
    columns,
  ]);

  return { filteredData };
}
