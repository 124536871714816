import React, { useCallback, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Badge,
  Breadcrumbs,
  Button,
  ButtonBase,
  Divider,
  Fab,
  Grid,
  IconButton,
  Link,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  Close as CloseIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DoneOutlined as DoneOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@material-ui/icons';

import CustomerItemMuiTable from '../../components/tables/CustomerItemMuiTable';
import DeliveryOptionsPanel from '../../components/panels/DeliveryOptionsPanel';
import MoreActionsButton from '../../components/ftr-components/MoreActionsButton';
import MultiCheckoutSummaryPanel from '../../components/panels/MultiCheckoutSummaryPanel';
import QcReportsDisplay from '../../components/info/QcReportsDisplay';
import SupportMessage from './SupportMessage';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import { RightBottomPositionAbsolute, TopRightPositionFixed } from '../../components/ftr-components/FixedPosition';

import ForwardEmailIcon from '../../assets/icons/forward_to_inbox_white.svg';

import { initMultiCheckoutFormState, updateShippingMode } from '../../actions/multiCheckoutForm';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import ProjectItemsContext from '../../context/ProjectItemsContext';

import { isEmptyValue } from '../../utils/commonUtils';

import { COUNTRY_NAMES } from '../../constants/countryConstants';

import { colors } from '../../palette';


// ---------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    height: '100%',
    width: '100%',
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: colors.fontWhite,
    }
  },
  formComponent: {
    padding: '1rem 2rem',
    position: 'relative',
  },
  projectLabel: {
    margin: '30px 0 0',
    display: 'flex',
  },
  projectHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    color: colors.fontBlack,
  },
  headerStyle: {
    color: colors.blue060,
    fontSize: '12pt',
    fontWeight: 600,
    marginRight: '10px',
  },
  textStyle: {
    fontSize: '12pt',
  },
  breadcrumbLink: {
    color: colors.tertiaryBlue,
    fontSize: '11pt',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  breadcrumbLinkBold: {
    fontSize: '11pt',
  },
  chip: {
    marginRight: 8,
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: colors.menuItemSelected,
  },
  quoteNumberBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'white',
      color: 'green',
      border: '1px solid green',
    },
  },
  cartLoading: {
    fontSize: 10,
  },
  menuItem: {
    borderRadius: 12,
    margin: '0 4px',
    fontWeight: 400,
    paddingLeft: '0.8rem',
    paddingRight: '0.8rem',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
  },
}));

function ProjectBreadcrumb(props) {
  const classes = useStyles();

  const { currentProjectName } = props;

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      <Link
        className={classes.breadcrumbLink}
        onClick={() => props.history.goBack()}
        aria-current='page'
      >
        All projects
      </Link>
      <Typography className={classes.breadcrumbLinkBold}>
        {currentProjectName}
      </Typography>
    </Breadcrumbs>
  );
}

function ProjectInfo() {
  const classes = useStyles();

  const {
    projectID,
    projectOwner,
    qcReports,
    currentProjectName,
    onEditProject,
    currentProjectDetail,
    creatorEmail,
    customerID,
    isProjectDeletable,
    MoreProjectActionsMenuList,
    handleDoneButtonOnClick,
    handleProjectNameOnChange,
    handleDeleteProject,
    updateEditProjectOwnerPopupState,
    updateProjectOwnerDisplay,
    handleEditButtonOnClick,
  } = useContext(ProjectItemsContext);

  const renderProjectOwner = () => {
    if (isEmptyValue(projectOwner)) {
      return null;
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography className={classes.headerStyle}>
          Project Owner:
        </Typography>
        <Typography>
          {projectOwner.ownerName} {`<${projectOwner.ownerEmail}>`}
        </Typography>
        <IconButton
          aria-label='edit'
          onClick={() => {
            updateEditProjectOwnerPopupState({
              open: true,
              projectID,
              projectOwner,
              currentProjectDetail,
              creatorEmail,
              customerID,
              updateProjectOwnerDisplay,
            });
          }}>
          <EditOutlinedIcon />
        </IconButton>
      </div>
    );
  }

  const renderProjectQcReports = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {/* TODO: temporarily removed */}
        {/* <div>
          <Button
            color='primary'
            size='medium'
            startIcon={<AddIcon />}
            onClick={() => updateQualityCertificationsPopupState({
              open: true,
              qcReports,
              projectID,
              setQcReports,
            })}
            disabled
          >
            Quality Certifications
          </Button>
        </div> */}
        <Typography className={classes.headerStyle}>
          Selected QC Report(s):
        </Typography>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '1rem',
            marginTop: '0.3rem',
          }}
        >
          <QcReportsDisplay qcReports={qcReports} />
        </div>
      </div>
    );
  }

  const renderProjectActionButton = () => {
    return (
      <RightBottomPositionAbsolute>
        <MoreActionsButton
          menuList={MoreProjectActionsMenuList}
        />
      </RightBottomPositionAbsolute>
    );
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <div className={classes.projectHeader}>
            <Typography className={classes.headerStyle}>
              Project Name:
            </Typography>
            <TextField
              size='small'
              value={currentProjectName}
              disabled={!onEditProject}
              onChange={handleProjectNameOnChange}
              InputProps={{ classes: { disabled: classes.textField } }}
            />
            {onEditProject ? (
              <IconButton
                aria-label='edit-success'
                onClick={handleDoneButtonOnClick}
              >
                <DoneOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton aria-label='edit' onClick={handleEditButtonOnClick}>
                <EditOutlinedIcon />
              </IconButton>
            )}
            {isProjectDeletable &&
              <Tooltip title='Delete Project'>
                <span>
                  <Button
                    onClick={() => handleDeleteProject(currentProjectDetail)}
                  >
                    <DeleteOutlinedIcon />
                  </Button>
                </span>
              </Tooltip>
            }
          </div>
          <div className={classes.projectHeader}>
            <Typography className={classes.headerStyle}>
              Project ID:
            </Typography>
            <TextField style={{ paddingTop: 3 }}
              disabled={true}
              InputProps={{ classes: { disabled: classes.textField } }}
              inputProps={{
                'data-cy': 'projectIDField',
              }}
              size='small'
              value={projectID}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          {renderProjectOwner()}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} style={{ position: 'relative' }}>
        {renderProjectQcReports()}
        {renderProjectActionButton()}
      </Grid>
    </>
  );
}

function ShippingModes(props) {
  const {
    multiCheckoutFormStatus,
    shippingMode,
    shippingModeList = [],
    disableShippingMode,
    onChange = () => { },
  } = props;

  return (
    <div style={{ marginBottom: '1rem' }}>
      {['success', 'loading'].includes(multiCheckoutFormStatus) && (
        <DeliveryOptionsPanel
          shippingMode={shippingMode}
          onChange={onChange}
          shippingModeList={shippingModeList}
          disabled={disableShippingMode}
          status={multiCheckoutFormStatus}
        />
      )}
    </div>
  );
}

function CheckoutSummary(props) {
  const dispatch = useDispatch();

  const {
    addresses,
    projectID,
    currentProjectDetail,
    customerID,
    multiCheckoutForm,
    shippingModeList,
    disableShippingMode,
    multiCheckoutFormStatus,
    selectedQuotes,
    shippingMode,
    currency,
    isShareQuotationForm,
    setShowCheckoutSummaryFullPanel,
    refetchProjectInfoByID,
    setAddresses,
    setShippingMode,
    setUserSwitchShippingMode,
    updateShareQuotePopupState,
    updateConfirmOrderPopupState,
  } = useContext(ProjectItemsContext);

  return (
    <FlexColumn
      style={{
        position: 'sticky',
        top: '2rem',
        width: '100%',
      }}
    >
      <ShippingModes
        shippingMode={multiCheckoutForm.shippingMode}
        onChange={(value) => {
          setShippingMode(value);
          dispatch(updateShippingMode(value));
          setUserSwitchShippingMode(true);
        }}
        shippingModeList={shippingModeList}
        disableShippingMode={disableShippingMode}
        multiCheckoutFormStatus={multiCheckoutFormStatus}
      />
      <MultiCheckoutSummaryPanel
        onCheckOutClick={() => {
          if (isShareQuotationForm) {
            setShowCheckoutSummaryFullPanel(false);
            updateShareQuotePopupState({
              open: true,
              projectID,
              selectedQuotes,
              shippingMode,
              customerID,
              addresses,
            });
          } else {
            updateConfirmOrderPopupState({
              open: true,
              customerID,
              selectedQuotes,
              shippingMode,
              shippingModeList,
              addresses,
              currency,
              currentProjectDetail,
              onClose: () => {
                setShowCheckoutSummaryFullPanel(true);
              },
              onShippingModeChange: (value) => {
                setShippingMode(value);
                setUserSwitchShippingMode(true);
              },
              setAddresses,
              refreshProject: () => {
                refetchProjectInfoByID();
                dispatch(initMultiCheckoutFormState());
              },
            });
            setShowCheckoutSummaryFullPanel(false);
          }
        }}
        showGst={addresses?.shipping?.country === COUNTRY_NAMES.SINGAPORE}
        buttonText={isShareQuotationForm ? 'Share Quote' : 'Checkout'}
        shippingCountry={addresses?.shipping?.country}
      />
      <span ref={props.checkoutPanelRef} />
    </FlexColumn>
  );
}

function ManageProjectItemsPresentationalV2(props) {
  const classes = useStyles();

  const [{ isMobile }] = useDeviceCheck();

  const {
    isAdminView,
    projectID,
    currentProjectName,
    currentProjectDetail,
    multiCheckoutForm,
    multiCheckoutFormStatus,
    selectedQuotes,
    showCheckoutSummaryFullPanel,
    filteredParts,
    isItemEditable,
    allParts,
    setShowCheckoutSummaryFullPanel,
    refetchProjectInfoByID,
    setIsShareQuotationForm,
  } = useContext(ProjectItemsContext);

  const checkoutPanelRef = useRef(null);

  const renderCheckoutSummarySlide = () => {
    return (
      <TopRightPositionFixed
        style={{
          zIndex: 1000,
          overflowY: 'auto',
          overflowX: 'auto',
          height: '100%',
          paddingLeft: 25,
          marginTop: isAdminView ? 64 : 0,
        }}
      >
        <Slide direction='left' in={showCheckoutSummaryFullPanel} mountOnEnter unmountOnExit>
          <div
            style={{
              backgroundColor: 'white',
              padding: '0.5rem',
              paddingTop: '1rem',
              position: 'relative',
            }}
          >
            <Fab
              style={{
                position: 'absolute',
                top: 0,
                left: -15,
                zIndex: 1999,
              }}
              aria-label='close'
              onClick={() => {
                setShowCheckoutSummaryFullPanel(false);
                setIsShareQuotationForm(false);
              }}
              size='small'
            >
              <CloseIcon />
            </Fab>
            <ManageProjectItemsPresentationalV2.CheckoutSummary
              checkoutPanelRef={checkoutPanelRef}
            />
          </div>
        </Slide>
      </TopRightPositionFixed>
    );
  }

  const renderCustomerItemTable = useCallback(() => {
    return (
      <CustomerItemMuiTable
        data={filteredParts}
        getItems={refetchProjectInfoByID}
        projectID={projectID}
        multipleCheckouts={currentProjectDetail
          ? currentProjectDetail.multipleCheckouts
          : []
        }
      />
    );
  }, [filteredParts, projectID]);

  const renderCheckoutFabButton = () => {
    return (
      <ButtonBase
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'fit-content',
          borderRadius: '5rem',
          backgroundColor: colors.successGreen,
          margin: 'auto',
          marginRight: '1.5vw',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (isMobile) {
            checkoutPanelRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsShareQuotationForm(false);
          } else {
            setShowCheckoutSummaryFullPanel(!showCheckoutSummaryFullPanel);
            setIsShareQuotationForm(false);
          }
        }}
        focusRipple
      >
        <Fab
          data-cy='shopping-cart-button'
          size={isMobile ? 'small' : 'medium'}
          color='primary'
          aria-label='checkout'
          disableRipple // Use ripple for the entire div instead
        >
          <Badge
            className={classes.quoteNumberBadge}
            badgeContent={selectedQuotes.length}
          >
            <ShoppingCartIcon />
          </Badge>
        </Fab>
        <Typography
          style={{
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          {get(multiCheckoutForm, ['checkoutPriceSummary', 'totalCost'])}
        </Typography>
      </ButtonBase>
    );
  }

  const renderShareQuotationFabButton = () => {
    return (
      <Tooltip
        title='Share quotation form'
        arrow
        placement='bottom'
      >
        <Fab
          data-cy='share-quotation-button'
          size={isMobile ? 'small' : 'medium'}
          aria-label='shareQuotation'
          style={{
            backgroundColor: multiCheckoutFormStatus !== 'success'
              ? colors.lightGray
              : colors.tertiaryBlue
          }}
          onClick={() => {
            if (isMobile) {
              checkoutPanelRef.current.scrollIntoView({ behavior: 'smooth' });
              setIsShareQuotationForm(true);
            } else {
              setShowCheckoutSummaryFullPanel(!showCheckoutSummaryFullPanel);
              setIsShareQuotationForm(true);
            }
          }}
          disabled={multiCheckoutFormStatus !== 'success'}
        >
          <Badge
            className={classes.quoteNumberBadge}
          >
            <img
              style={{ width: '30px', height: '30px' }}
              className={classes.imageIcon}
              src={ForwardEmailIcon} alt='quotation-sharing-icon' />
          </Badge>
        </Fab>
      </Tooltip>
    );
  }

  const renderSimpleCheckoutInfoRow = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          zIndex: 99,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'fit-content',
            borderRadius: '5rem',
            margin: 'auto',
          }}
        >
          {renderCheckoutFabButton()}
          {renderShareQuotationFabButton()}
        </div>
      </Grid>
    );
  }

  return (
    <div className={classes.formComponent}>
      <ManageProjectItemsPresentationalV2.ProjectBreadcrumb
        currentProjectName={currentProjectName}
        history={props.history}
      />
      {!isMobile && renderCheckoutSummarySlide()}
      <div className={classes.projectLabel}>
        {/* overflow: clip overwrites parent overflow for position: sticky to work */}
        <Grid container spacing={2} style={{ overflow: 'clip' }}>
          <Grid container row xs={12}>
            <ManageProjectItemsPresentationalV2.ProjectInfo />
            <Grid item xs={12}
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            >
              <Divider />
            </Grid>
            {/* TODO: temporary remove */}
            {/* <div
                style={{
                  marginTop: '1rem',
                }}
              >
                <ProjectStatusFiltersBar onFilterChange={handleFilterChange} />
              </div> */}
            <div className={classes.body}>
              {renderCustomerItemTable()}
              {!allParts.every(isItemEditable) && <SupportMessage />}
            </div>
          </Grid>
          {['success', 'loading'].includes(multiCheckoutFormStatus)
            && !isEmptyValue(selectedQuotes)
            && renderSimpleCheckoutInfoRow()
          }
          {isMobile && selectedQuotes.length > 0 && (
            <div style={{ marginTop: '2rem', width: '100%' }}>
              <ManageProjectItemsPresentationalV2.CheckoutSummary
                checkoutPanelRef={checkoutPanelRef}
              />
            </div>
          )
          }
        </Grid>
      </div>
    </div>
  );
}

ManageProjectItemsPresentationalV2.ProjectBreadcrumb = ProjectBreadcrumb;
ManageProjectItemsPresentationalV2.ProjectInfo = ProjectInfo;
ManageProjectItemsPresentationalV2.ShippingModes = ShippingModes;
ManageProjectItemsPresentationalV2.CheckoutSummary = CheckoutSummary;

export default ManageProjectItemsPresentationalV2;
