// Import settings
import React from 'react';

// Import material UI components
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

// Import images
import EditOrderIcon from '../../assets/img/edit-order.png';

// Style components
const useStyles = makeStyles(() => ({
  buttonStyle: {
    textTransform: 'uppercase',
    fontSize: '9px',
    lineHeight: '13px',
    whiteSpace: 'nowrap',
  },
}));

function EditOrderButton(props) {
  const classes = useStyles();
  const { btnContent, onBtnClick } = props;
  return (
    <Button
      classes={{ text: classes.text }}
      aria-label='edit'
      startIcon={
        <img
          src={EditOrderIcon}
          style={{
            maxWidth: 20,
            maxHeight: 20,
            marginRight: -2,
            marginLeft: 4,
          }}
          alt='factorem'
        />
      }
      className={classes.buttonStyle}
      onClick={onBtnClick}
    >
      {btnContent}
    </Button>
  );
}

export default EditOrderButton;
