/* eslint-disable react/display-name */
import React, { useContext, useMemo, memo } from "react";
import { cloneElement } from "react";

import {
  GridApiContext,
  GridToolbarContainer,
} from "@mui/x-data-grid";


const CustomToolbar = memo(({ buttons }) => {
  const gridContext = useContext(GridApiContext);
  const state = gridContext?.current.getState();

  const rows = Object.entries(state?.selection ?? []).map(
    (row) => state?.rows.idRowsLookup[row[0]] ?? {}
  );

  const renderedButtons = useMemo(() => buttons.map((button) => {
    return cloneElement(
      button,
      { rows: rows, totalRows: state?.rows.totalRowCount, key: button.props.name }
    );
  }), [buttons, rows, state?.rows.totalRowCount]);

  return (
    <GridToolbarContainer
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        padding: 0,
        flexWrap: 'wrap',
      }}
    >
      {renderedButtons}
    </GridToolbarContainer>
  );
});

export default CustomToolbar;
