import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Divider,
  Typography,
  FormControl,
  FormLabel,
  Input,
  InputAdornment,
} from "@material-ui/core";

import { colors } from "../../palette";
import InfoIcon from "../icons/InfoIcon";

const useStyles = makeStyles(() => ({
  container: {
    margin: "25px, 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "25px",
    padding: "25px",
    border: `3px solid ${colors.bgLightGrey}`,
  },
  rowContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '1rem',
  },
  inputField: {
    marginTop: "10px",
    fontSize: 15,
    padding: "0px 8px",
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: "border-box",
    borderRadius: "5px",
    color: `${colors.fontGrey}`,
    width: "100%",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
}));

function PpeMarkupForm3DP(props) {
  const { markupState, onMarkupChange } = props;

  const markupKeyList = Object.keys(markupState).filter(key => key.includes('3DP -'));
  const default3DPMarkupKeyList = Object.keys(markupState).filter(key => key.includes('3DP') && !markupKeyList.includes(key));

  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <div>
        <Typography variant="h6">3DP Markup Settings</Typography>
        <Divider />
      </div>
      <div className={classes.rowContainer}>
        {default3DPMarkupKeyList.map((markup) => {
          return (
            <FormControl component="fieldset" key={markup}>
              <FormLabel component="legend">{markup} <InfoIcon toolTipText="This is configuration for 3DP technologies other than FDM, SLA, SLS, MJF" /></FormLabel>
              <Input
                id={"number"}
                className={classes.inputField}
                disableUnderline={true}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 100000,
                  type: "number",
                }}
                value={markupState[markup]}
                onChange={(evt) => {
                  onMarkupChange(markup, evt.target.value);
                }}
                endAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
              />

            </FormControl>
          );
        })}
      </div>
      <div className={classes.rowContainer}>
        {markupKeyList.map((markup) => {
          return (
            <FormControl component="fieldset" key={markup}>
              <FormLabel component="legend">{markup}</FormLabel>
              <Input
                id={"number"}
                className={classes.inputField}
                disableUnderline={true}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 100000,
                  type: "number",
                }}
                value={markupState[markup]}
                onChange={(evt) => {
                  onMarkupChange(markup, evt.target.value);
                }}
                endAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
              />
            </FormControl>
          );
        })}
      </div>
    </Container>
  );
}

export default PpeMarkupForm3DP;
