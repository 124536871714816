import React, { useState } from 'react';

import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import BlueButton from '../buttons/BlueButton';
import Title from '../Title';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function ConfirmEditMultipleCheckoutPopup(props) {
  const { onClose, open = false, updateHandler = () => null } = props;

  const classes = useStyles();

  const [regenerateQuotationForm, setRegenerateQuotationForm] = useState(false);

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id={'confirm-edit-multiple-checkout-popup'}>
        <div style={{ padding: '0 1rem' }}>
          <Title contentTitle='Confirm to Update Checkout' size='small' />
        </div>
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={regenerateQuotationForm}
              onChange={() => setRegenerateQuotationForm(!regenerateQuotationForm)}
              name='checkedSheetMetal'
            />
          }
          label='Regenerate Quotation Form'
        />
      </DialogContent>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '0.5rem 0 1rem 0',
        }}
      >
        <BlueButton
          onBtnClick={() => updateHandler({ regenerateQuotationForm })}
          btnContent='Update'
        />
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default ConfirmEditMultipleCheckoutPopup;
