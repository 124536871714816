import React from 'react';
import { createRoot } from 'react-dom/client';

import Root from './Root';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const theme = createTheme({
  typography: {
    fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#0C5DBC',
    },
    secondary: {
      main: '#7a161a',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '$labelcolor',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#62B4FF',
        '&$checked': {
          color: '#0C5DBC',
        },
      },
    },
  },
});

if (module.hot) {
  module.hot.accept();
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Root />
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>
);

serviceWorker.unregister();
