import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { ceil } from 'lodash';
import { CsvBuilder } from 'filefy';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { getAllUserFeedback } from '../../apis/userFeedbackApi';

import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { fourDigitUtil } from '../../utils/numberUtils';
import { generateCurrentCustomDateTimeString } from '../../util';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import CustomToolbar from '../../components/grid-data/CustomToolbar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';

import { colors } from '../../palette';

import { USER_FEEDBACK_QUESTION_LIST, USER_FEEDBACK_RATING_QUESTION_LIST } from '../../constants/userFeedbackConstants';

import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { isEmptyValue } from '../../utils/commonUtils';

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    marginBottom: '2rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: "11pt",
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

function UserFeedbackDataGrid() {
  const classes = useStyles();

  const {
    data: allFeedback,
    isLoading,
    refetch,
  } = useQuery('getAllUserFeedback', getAllUserFeedback);

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    {
      setSearchStr,
      setColumnsDef,
      setSourceData,
    }
  ] = useDataGridFilterHook({
    search: '',
    source: allFeedback,
  });

  const getQuestionHeader = (questionObject) => {
    return `Q${questionObject.id} Response: ${questionObject.question}`;
  }

  const renderRateFeedback = (rowData) => {
    const qa = rowData.qAndA?.find(q => q.id === 6);
    if (isEmptyValue(qa?.answer)) {
      return 'N.A.'
    }
    return Array(qa?.answer).fill('⭐️').join('')
  }

  const defaultColumns = [
    {
      headerName: "Feedback ID",
      field: "feedbackID",
      valueGetter: ({ row: rowData }) => {
        return `${fourDigitUtil(rowData.userID)}${fourDigitUtil(rowData.feedbackNumber)}`;
      },
      width: 120,
    },
    {
      headerName: "User ID",
      field: "userID",
      width: 90,
    },
    {
      headerName: "Order Delivery ID",
      field: "referenceName",
      width: 120,
      valueGetter: ({ row: rowData }) => {
        return rowData?.referenceName ?? 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell text={rowData?.referenceName ?? 'N.A.'} />
        );
      },
    },
    {
      headerName: "Name",
      field: "name",
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.name} />,
      width: 150,
    },
    {
      headerName: "Role",
      field: "role",
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.role} />,
    },
    {
      headerName: "Email",
      field: "email",
      renderCell: ({ row: rowData }) => <DataGridWrapTextCell text={rowData.email} />,
      width: 180,
    },
    {
      headerName: getQuestionHeader(USER_FEEDBACK_QUESTION_LIST.find(q => q.id === 1)),
      csvHeader: 'Q1 Response',
      field: "q1Response",
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 1);
        return qa?.answer ?? 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 1);
        return (
          <DataGridWrapTextCell text={qa?.answer ?? 'N.A.'} />
        );
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(USER_FEEDBACK_QUESTION_LIST.find(q => q.id === 2)),
      field: "q2Response",
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 2);
        return qa?.answer ?? 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 2);
        return (
          <DataGridWrapTextCell text={qa?.answer ?? 'N.A.'} />
        );
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(USER_FEEDBACK_QUESTION_LIST.find(q => q.id === 3)),
      field: "q3Response",
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 3);
        return qa?.answer ?? 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 3);
        return (
          <DataGridWrapTextCell text={qa?.answer ?? 'N.A.'} />
        );
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(USER_FEEDBACK_QUESTION_LIST.find(q => q.id === 4)),
      field: "q4Response",
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 4);
        return qa?.answer ?? 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 4);
        return (
          <DataGridWrapTextCell text={qa?.answer ?? 'N.A.'} />
        );
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(USER_FEEDBACK_QUESTION_LIST.find(q => q.id === 5)),
      field: "q5Response",
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 5);
        return qa?.answer ?? 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 5);
        return (
          <DataGridWrapTextCell text={qa?.answer ?? 'N.A.'} />
        );
      },
      width: 180,
    },
    {
      headerName: getQuestionHeader(USER_FEEDBACK_RATING_QUESTION_LIST.find(q => q.id === 6)),
      field: "q6Response",
      valueGetter: ({ row: rowData }) => renderRateFeedback(rowData),
      renderCell: ({ row: rowData }) => renderRateFeedback(rowData),
      width: 180,
    },
    {
      headerName: getQuestionHeader(USER_FEEDBACK_RATING_QUESTION_LIST.find(q => q.id === 7)),
      field: "q7Response",
      valueGetter: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 7);
        return qa?.answer ?? 'N.A.';
      },
      renderCell: ({ row: rowData }) => {
        const qa = rowData.qAndA?.find(q => q.id === 7);
        return (
          <DataGridWrapTextCell text={qa?.answer ?? 'N.A.'} />
        );
      },
      width: 180,
    },
    {
      headerName: "Date and time",
      field: "createdAt",
      valueGetter: ({ row: rowData }) => {
        return dateTzSingapore(rowData.createdAt);
      },
      width: 150,
    },
    {
      headerName: "Location of response submission",
      field: "submitLocation",
      renderCell: ({ row: rowData }) => {
        <DataGridWrapTextCell text={rowData.submitLocation ?? 'N.A.'} />
      },
      width: 180,
    },
  ]

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(allFeedback);
  }, [allFeedback]);

  useEffect(() => {
    updateTableQueryParams({ loading: isLoading ?? false });
  }, [isLoading]);

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  }

  const handleDownloadCsv = () => {
    const fileName = `All User Feedback ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getCustomerToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
        }}
      >
        <span style={{ flexGrow: '1 auto' }}></span>
        <CustomToolbar
          buttons={[
            <SearchBar
              key="search"
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
            />,
          ]}
        />
      </div>
    );
  }

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns.map(col => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={80}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(tableQueryParams.totalCount / tableQueryParams.pageSize)}
            />
          ),
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) => updateTableQueryParams({ pageSize: newPageSize })}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
        loading={tableQueryParams.loading}
      />
    </div>
  );
}

export default UserFeedbackDataGrid;
