import React, { useEffect, useRef } from 'react';

import { TextField } from '@material-ui/core';
import { OTHER_MATERIAL_STR } from '../../../constants/quotationConstants';
import { isEmpty } from 'lodash';

function OtherMaterialField(props) {

  const customMaterialRef = useRef();

  const {
    value,
    error,
    onChange,
    onFocus,
  } = props;

  useEffect(() => {
    if (!isEmpty(error)) {
      customMaterialRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
  }, [error]);

  return (
    <TextField
      id="custom-material"
      ref={customMaterialRef}
      variant="outlined"
      required
      fullWidth
      margin="dense"
      label="Custom Material"
      name={OTHER_MATERIAL_STR}
      value={value}
      error={error}
      helperText={error}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
}

export default OtherMaterialField;
