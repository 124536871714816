import React from 'react';
import { Button } from '@material-ui/core';
import FtrTypography from '../ftr-components/FtrTypography';
import closeIcon from '../../assets/icons/close.svg';
import { colors } from '../../palette';


function DisplayFeedbackCard({ onClickAction, onCloseAction, text }) {
    return (
      <Button
        disableRipple
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '10px',
          padding: '0.5rem 0.7rem',
          justifyContent: 'space-between',
          backgroundColor: colors.neutral020,
          border: `0.5px solid ${colors.neutral030}`,
          textTransform: 'none'
        }}
        onClick={onClickAction}
      >
        <FtrTypography
          type='subHeading'
          fontSize='12'
          style={{
            whiteSpace: 'nowrap',
            color: colors.neutral070,
            marginRight: '0.5rem',
          }}
        >
          {text}
        </FtrTypography>
        <img
          src={closeIcon}
          alt='delete'
          style={{
            cursor: 'pointer',
            width: '1rem', // Adjust width as needed
            height: '1rem', // Adjust height as needed
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCloseAction();
          }}
          draggable={false}
        />

      </Button>
    );
  }

export default DisplayFeedbackCard;
