import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles/index';

import ImageWith3DViewer from '../images/ImageWith3DViewer';

import { colors } from '../../palette';

import { getFileNameFromCadFile } from '../../utils/itemUtils';

const useStyles = makeStyles((theme) => ({
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  twoDImageContainer: {
    width: 100,
    height: 100,
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightGray,
    [theme.breakpoints.down('sm')]: {
      width: 70,
      height: 70,
    },
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '0.875rem'
  },
  renderImage: {
    cursor: 'pointer',
    marginRight: '1rem',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)'
      },
      '& $render3dCAD': {
        display: 'block',
      }
    },
  },
}));

function FileUploadWithImageItem(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data,
    onDeleteClick,
    showDownloadImage = false,
  } = props;
  const {
    link,
    '2DImageUrl': twoDImageUrl,
    imageLoading,
    uploadStatus,
    error,
  } = data;


  if (uploadStatus === 'ongoing') {
    return (
      <Skeleton
        style={{
          width: '100%',
          height: isMobile ? 80 : 120,
          margin: '1rem',
        }}
        variant='rect'
      />
    );
  }

  const fileName = getFileNameFromCadFile(link);

  return (
    <ListItem
      style={{
        backgroundColor: colors.uploadFileBg,
        margin: 5,
        border: `solid 1px ${colors.uploadFileBorder}`,
        borderRadius: 5,
        height: isMobile ? 80 : 120,
      }}
    >
      <ListItemAvatar style={{ display: 'flex', minWidth: '45px' }}>
        <ImageWith3DViewer
          twoDImageUrl={error === true ? null : twoDImageUrl}
          cadFile={link}
          width={isMobile ? 70 : 100}
          height={isMobile ? 70 : 100}
          noBorder
          borderRadius={0}
          isLoading={imageLoading}
          style={{
            marginRight: '1rem',
          }}
        />
      </ListItemAvatar>
      <div>
        <ListItemText
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '1rem',
          }}
          primary={fileName}
          className={classes.itemText}
        />
        {showDownloadImage && (
          <a
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '0.75rem',
            }}
            href={twoDImageUrl}
            className={classes.itemText}
          >
            Download Image
          </a>
        )}
      </div>
      <ListItemSecondaryAction>
        <IconButton
          edge='end'
          aria-label='delete'
          onClick={() => onDeleteClick(link)}
        >
          <DeleteIcon color='primary' style={{ fontSize: '15pt' }} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default FileUploadWithImageItem;
