import React, { useReducer, useState } from 'react';

import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@material-ui/core';

import { FlexRowSpaceBetween } from '../../layouts/FlexLayouts';

function MaterialWithMarkupField(props) {
  const {
    material,
    onSelectionFormChange,
  } = props;

  const [
    localState,
    updateLocalState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      value: !!+material.value,
      materialMarkup: material.materialMarkup,
    },
  );
  const [timer, setTimer] = useState(null);

  const notifyParent = (params) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    const _timer = setTimeout(() => {
      onSelectionFormChange(
        material.id,
        {
          ...params,
        }
      );
    }, 300);
    setTimer(_timer);
  }

  return (
    <FlexRowSpaceBetween>
      <FormControlLabel
        key={material.id}
        control={
          <Checkbox
            checked={localState.value}
            onChange={(event) => {
              const params = {
                value: event.target.checked,
                materialMarkup: 0,
              }
              updateLocalState(params);
              notifyParent(params);
            }}
            name={material.name}
          />
        }
        label={material.name}
        style={{
          width: 'max-content',
        }}
      />
      <TextField
        variant='outlined'
        margin='dense'
        label='Markup'
        type='number'
        fullWidth
        style={{
          width: 100,
          visibility: !localState.value ? 'hidden' : 'visible',
        }}
        onChange={(event) => {
          const params = {
            materialMarkup: event.target.value,
          }
          updateLocalState(params);
          notifyParent(params);
        }}
        value={localState.materialMarkup}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              %
            </InputAdornment>
          ),
        }}
        disabled={!localState.value}
      />
    </FlexRowSpaceBetween>
  );
}

export default MaterialWithMarkupField;
