import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notifyError = (message) =>
  toast.error(message, { position: toast.POSITION.TOP_RIGHT });

export const notifyInfo = (message) =>
  toast.info(message, { position: toast.POSITION.TOP_RIGHT });

export const notifyWarning = (message) =>
  toast.warning(message, { position: toast.POSITION.TOP_RIGHT });

export const notifySuccess = (message) =>
  toast.success(message, { position: toast.POSITION.TOP_RIGHT });

export const notifyOngoing = (message) =>
  toast(message, { type: toast.TYPE.INFO, autoClose: false });

export const updateNotification = (toastID, message, type = 'success') => {
  const toastTypeMap = {
    success: toast.TYPE.SUCCESS,
    info: toast.TYPE.INFO,
    warning: toast.TYPE.WARNING,
    error: toast.TYPE.ERROR,
    default: toast.TYPE.DEFAULT,
  };

  const toastType = toastTypeMap[type] || toastTypeMap.default;

  toast.update(toastID, {
    render: message,
    type: toastType,
    autoClose: 3000,
  });
};

export const dismissNotification = (toastID) => {
  toast.dismiss(toastID);
};

// Create a custom toast component with title and content
const CustomToast = ({
  title,
  content,
  cancellable = false,
  onCancel = () => {},
  cancelBtnText = 'Cancel',
}) => (
  <div>
    <strong>{title}</strong>
    <div style={{ fontSize: 12 }}>{content}</div>
    {cancellable && (
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <button onClick={onCancel}>{cancelBtnText}</button>
      </div>
    )}
  </div>
);

export const notifyErrorContent = (title = '', content = '') => {
  toast.error(<CustomToast title={title} content={content} />, {
    position: toast.POSITION.TOP_RIGHT, // Set the position to top right
  });
};

export const notifyInfoContent = (title = '', content = '') => {
  return toast.info(<CustomToast title={title} content={content} />, {
    position: toast.POSITION.TOP_RIGHT, // Set the position to top right
  });
};

export const notifyCancellableInfoContent = ({
  title = '',
  content = '',
  cancelBtnText = 'Cancel',
  onCancel = () => {},
}) => {
  const toastID = toast.info('', {
    position: toast.POSITION.TOP_RIGHT, // Set the position to top right
    autoClose: false,
  });

  const comp = (
    <CustomToast
      title={title}
      content={content}
      cancellable
      cancelBtnText={cancelBtnText}
      onCancel={() => {
        onCancel();
        toast.dismiss(toastID);
      }}
    />
  );

  toast.update(toastID, {
    render: comp,
  });

  return toastID;
};

export const notifySuccessContent = (title = '', content = '') => {
  toast.success(<CustomToast title={title} content={content} />, {
    position: toast.POSITION.TOP_RIGHT, // Set the position to top right
  });
};
