import queryString from "query-string";
import { getAuthorizedHeader } from "../utils/apiUtils";
import { notifyError } from "../services/notificationService";

import { BACKEND_SERVICE_URL } from "../constants";

export const getESignFileForSupplier = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/get-partnership-agreement?${queryString.stringify(params)}`;
  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
}

export const signNowAgreementIsSignedBy = async (docID, userID) => {
  const params = {
    userId: `${userID}`,
    docId: `${docID}`
  }
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/get-esign-file-signed-status?${queryString.stringify(params)}`;

  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
    }).then(async (response) => {
    if (!response.ok) {
        throw Error(response.statusText);
    }

    const responseBody = await response.json();
    return (responseBody.signingStatus === "fulfilled");
  });
}

export const downloadSignNowPartnershipAgreement = async (docID, docName) => {
  const params = {
    docId: `${docID}`,
    docName: `${encodeURIComponent(docName)}`
  };
  const requestUrl = `${BACKEND_SERVICE_URL}/onboarding/download-signnow-partnership-agreement?${queryString.stringify(params)}`;

  return fetch(requestUrl, {
    method: "GET",
    headers: getAuthorizedHeader(),
  })
  .then(response => response.blob())
  .then(blob => {
    // Create a temporary URL for the blob object
    const fileURL = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = fileURL;
    link.target = "_blank";

    const fileName = decodeURIComponent(params.docName).replace(/\+/g, ' '); // Decode and replace '+' with space
    link.download = fileName;

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up the temporary resources
    URL.revokeObjectURL(fileURL);
    document.body.removeChild(link);
  })
  .catch(() => {
      notifyError(`Unable to download partnership agreement from SignNow`);
    }
  );
};