import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { CircularProgress, Container, makeStyles } from '@material-ui/core';

import Viewer3D from '../../components/3dViewer/Viewer3D';
import { generatePresignedUrlForImage, uploadFileToS3 } from '../../apis/s3Api';
import { convertFileToStl } from '../../apis/convertFileApi';
import { notifyError } from '../../services/notificationService';
import { getFileExtension } from '../../utils/fileUtils';
import { getCadPartS3Key } from '../../services/s3Service';
import { NATIVE_3D_RENDERER_TYPES } from '../../constants/cadRendererConstants';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '35rem',
    position: 'relative',
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: 'none',
    zIndex: '1',
  },
}));

/**
 *
 * @param {Object} props
 * @param {String} props.fileUrl
 * @param {Boolean} props.hideUploadFile
 * @returns
 */
function ThreeDViewer(props) {
  const { fileUrl, hideUploadFile } = props;

  const classes = useStyles();

  const [stlFile, setStlFile] = React.useState(
    'https://3d-stl-files.s3.us-east-2.amazonaws.com/slanted-bottom-34b925bfc5fae84640fe59741b8fc1c3.stl'
  );
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConvertFileToStl = (url) => {
    setStlFile(null);
    setIsLoading(true);
    const fileExtension = getFileExtension(url);
    if (!NATIVE_3D_RENDERER_TYPES.includes(fileExtension)) {
      notifyError('File extension is not supported!');
      return;
    }
    if (fileExtension === 'stl') {
      generatePresignedUrlForImage(url)
        .then((urlWithPresignedUrl) => {
          setStlFile(urlWithPresignedUrl);
        })
        .catch((err) => {
          notifyError(err?.message || 'File upload failed')
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    convertFileToStl({
      file_urls: [url],
    })
      .then((data) => {
        const fileUrl = data?.s3_file_urls[0];
        return generatePresignedUrlForImage(fileUrl);
      })
      .then((data) => {
        setStlFile(data);
        return data;
      })
      .catch((err) => {
        notifyError(err?.message || 'File upload failed')
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (fileUrl) {
      handleConvertFileToStl(fileUrl);
    }
  }, [fileUrl]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      notifyError('File is required!');
      return;
    }
    const fileExtension = getFileExtension(file.name);
    if (!NATIVE_3D_RENDERER_TYPES.includes(fileExtension)) {
      notifyError('File extension is not supported!');
      return;
    }
    const toastId = toast('Uploading file...', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    if (fileExtension === 'stl') {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setStlFile(reader.result);
        toast.update(toastId, {
          render: 'File uploaded successfully',
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      };
    } else {
      const s3ObjectKey = getCadPartS3Key(file)
      return uploadFileToS3(file, s3ObjectKey)
        .then((data) => {
          const fileUrl = data.Location.split(' ').join('%20');
          return handleConvertFileToStl(fileUrl);
        })
        .then(() => {
          toast.update(toastId, {
            render: 'File uploaded successfully',
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });
        })
        .catch((err) => {
          toast.update(toastId, {
            render: err?.message || 'File upload failed',
            type: toast.TYPE.ERROR,
            autoClose: 3000,
          });
        });
    }
  };

  return (
    <Container className={classes.container}>
      {!hideUploadFile && (
        <input
          type='file'
          accept={NATIVE_3D_RENDERER_TYPES.map((ext) => '.' + ext)}
          onChange={handleFileUpload}
        />
      )}
      {stlFile && <Viewer3D stlFile={stlFile} />}
      {isLoading && <CircularProgress className={classes.circularProgress} size={30} />}
    </Container>
  );
}

export default ThreeDViewer;
