/* eslint-disable react/prop-types */
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import {
  Close as CloseIcon,
} from '@material-ui/icons';

import ImageWith3DViewer from '../images/ImageWith3DViewer';
import TechnicalDrawingFileDisplay from '../forms/part-upload-step-two/TechnicalDrawingFileDisplay';
import { FtrTypography } from '../ftr-components';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';

import { getFileNameFromCadFile } from '../../utils/itemUtils';
import { getFileToGenerate2DImage } from '../../utils/imageConverterUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { filterFilesByExtension } from '../../utils/fileUtils';

import { TWO_D_IMAGE_URLS_KEY } from '../../constants';
import { PDF_IMAGES_EXTENSIONS } from '../../constants/fileConstants';

import { colors } from '../../palette';


// --------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  itemText: {
    '& span, & svg': {
      fontSize: 12,
    },
  },
  twoDImageContainer: {
    width: 100,
    height: 100,
    marginRight: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.lightGray,
    [theme.breakpoints.down('sm')]: {
      width: 70,
      height: 70,
    },
  },
  render3dCAD: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '0.875rem'
  },
  renderImage: {
    cursor: 'pointer',
    marginRight: '1rem',
    position: 'relative',
    '&:hover': {
      '& img': {
        filter: 'brightness(0.8)'
      },
      '& $render3dCAD': {
        display: 'block',
      }
    },
  },
  actionRoot: {
    top: 45,
  },
  itemContainer: {
    width: '100%',
  },
}));

function UploadedFileListItem(props) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data,
    onDeleteClick,
  } = props;

  const {
    id,
    [TWO_D_IMAGE_URLS_KEY]: twoDImageUrls,
    imageStatus,
    uploadStatus,
    imageError: error,
  } = data;

  const twoDImageUrl = twoDImageUrls ? twoDImageUrls[0] : null;

  if (uploadStatus === 'ongoing') {
    return (
      <Skeleton
        style={{
          width: '100%',
          height: isMobile ? 80 : 120,
          margin: '1rem',
        }}
        variant='rect'
      />
    );
  }

  const link = getFileToGenerate2DImage(data.cadPart);
  const fileName = getFileNameFromCadFile(link);

  const technicalDrawingFiles = isEmptyValue(data.cadPart)
    ? []
    : filterFilesByExtension(data.cadPart, PDF_IMAGES_EXTENSIONS);
  const has3DCAD = technicalDrawingFiles.length !== data.cadPart?.length;

  const render2DImage = (twoDImageUrl) => {
    return (
      <div style={{ marginRight: '1rem' }}>
        <ImageWith3DViewer
          cadFile={link}
          twoDImageUrl={error === true ? null : twoDImageUrl}
          width={isMobile ? 80 : 120}
          height={isMobile ? 80 : 120}
          noBorder
          isLoading={imageStatus === 'loading'}
          borderRadius={20}
        />
      </div>
    )
  };

  const renderCloseButton = () => {
    return (
      <IconButton
        edge='end'
        aria-label='delete'
        onClick={() => onDeleteClick(id)}
      >
        <CloseIcon style={{ fontSize: '15pt' }} />
      </IconButton>
    )
  }

  return (
    <ListItem
      classes={{
        container: classes.itemContainer,
      }}
      style={{
        borderRadius: 20,
        height: isMobile ? 80 : 120,
        backgroundColor: colors.neutral010,
        boxShadow: '0px 2px 6px 0px #00000026',
        padding: 0,
        margin: '1rem 0',
      }}
    >
      <ListItemAvatar
        style={{
          display: 'flex',
          minWidth: '45px',
        }}>
        {render2DImage(twoDImageUrl)}
      </ListItemAvatar>
      <div style={{ marginLeft: '1rem' }}>
        <FlexColumn>
          <FtrTypography type='subHeading' fontSize='16'>
            {fileName}
          </FtrTypography>
          <FlexRow>
            {has3DCAD && technicalDrawingFiles?.map((fileStr, index) => {
              return (
                <TechnicalDrawingFileDisplay
                  key={fileStr}
                  item={data}
                  index={index}
                  fileStr={fileStr}
                />
              );
            })}
          </FlexRow>
        </FlexColumn>
      </div>
      <ListItemSecondaryAction
        classes={{
          root: classes.actionRoot,
        }}
      >
        {renderCloseButton()}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default UploadedFileListItem;
