import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import GetSampleUpload from '../components/GetSampleUpload';
import PartUploadDragAndDropV2 from '../components/PartUploadDragAndDrop/PartUploadDragAndDropV2';
import SelectTechnologies from '../components/selects/SelectTechnologies';
import WhiteDragDropV2 from '../components/WhiteDragDropV2';
import { FtrTypography } from '../components/ftr-components';

import astarLogo from '../assets/icons/astar_logo.svg';
import bigFactoremLogo from '../assets/img/big_factorem_logo.svg';
import blueCheckIcon from '../assets/icons/blue_check.svg';
import dsoLogo from '../assets/icons/dso_logo.svg';
import factoremLogo from '../assets/icons/factorem_logo.svg';
import factoremLogoWithName from '../assets/icons/factorem_logo_with_name.svg';
import gikenLogo from '../assets/icons/giken_logo.svg';
import micronLogo from '../assets/icons/micron_logo.svg';
import panasonicLogo from '../assets/icons/panasonic_logo.svg';
import secureUploadIcon from '../assets/icons/secure_upload.svg';

import {
  openPartSummaryPage,
  resetPartSummaryDetails,
  resetStatus,
} from "../actions";

import useCadUpload from '../hooks/useCadUpload';

import { isEmptyValue } from '../utils/commonUtils';

import { notifyError } from '../services/notificationService';
import { getCache } from '../services/localStorageCacheService';
import { clarity } from "../services/pluginService";

import { TECH_URL_MAPPING_OPTIONS } from '../constants/projectConstants';
import { FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING } from '../constants/featureFlagConstants';
import {
  MAX_UPLOAD_FILES,
  MAX_UPLOAD_FILES_ERROR_MESSAGE,
} from '../constants/NewPartConstants';

import { colors } from '../palette';


// --------------------------------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'left',
    paddingBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  topSidebar: {
    fontWeight: 400,
    padding: '1.5rem',
    top: 0,
    color: colors.blue010,
    position: 'relative',
  },
  btmSidebar: {
    padding: '1.2rem',
    position: 'absolute',
    color: colors.neutral030,
    bottom: 0,
  },
  container: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    backgroundImage: colors.sidebarBackground,
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      background: colors.fontWhite,
    },
  },
  form: {
    height: '100%',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    background: '#FAFAFA',
    [theme.breakpoints.down('sm')]: {
      background: colors.fontWhite,
    },
  },
  backdrop: {
    display: 'flex',
    fontWeight: 600,
    position: 'absolute',
    flexDirection: 'column',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: `#66000000`,
  }
}));

function GetQuoteNowV2(props) {
  const classes = useStyles();

  const location = useLocation();
  const techParams = new URLSearchParams(location.search);

  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    userID,
    resetStatus,
  } = props;

  const technology = techParams.get('tech') in TECH_URL_MAPPING_OPTIONS
    ? TECH_URL_MAPPING_OPTIONS[techParams.get('tech')]
    : 'CNC Machining';
  const [params, setParams] = useState({ technology: technology });
  const [isLoading, setIsLoading] = useState(false);

  const [
    cadPartListState,
    {
      uploadCadFiles,
      uploadCadFilesStep2,
      updateTechnologyParamsInfoForAllParts,
    }
  ] = useCadUpload();

  if (!isEmptyValue(userID)) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  useEffect(() => {
    resetStatus();
    dispatch(resetPartSummaryDetails());

    // record user tracking in clarity for get quote now page
    const clarityInfo = getCache("clarityInfo");
    const clarityInfoJson = JSON.parse(clarityInfo);
    clarity(window, document, "clarity", "script", clarityInfoJson?.CLARITY_BUYER_ID);
  }, []);

  useEffect(() => {
    updateTechnologyParamsInfoForAllParts(params);
  }, [params]);

  const renderBackdrop = () => {
    return (
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress style={{ padding: '1rem' }} />
        Just a moment
      </Backdrop>
    );
  }

  const renderTrustedBrands = () => {
    return (
      <div>
        <div style={{ display: 'block' }}>
          <img src={panasonicLogo} alt='logo' />
          <img src={astarLogo} alt='logo' />
        </div>
        <div style={{ display: 'block' }}>
          <img src={dsoLogo} alt='logo' />
          <img src={gikenLogo} alt='logo' />
          <img src={micronLogo} alt='logo' />
        </div>
      </div>
    );
  }

  function handleCadFilesChange(files) {
    setIsLoading(true)

    if (files.length + cadPartListState.data.length > MAX_UPLOAD_FILES) {
      notifyError(MAX_UPLOAD_FILES_ERROR_MESSAGE);
      return;
    }
    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true') {
      uploadCadFiles(files);
    } else {
      uploadCadFilesStep2(files);
    }
    history.push('/summary');
  }

  const renderDragAndDropFileUploadComp = () => {
    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true') {
      return (
        <WhiteDragDropV2
          fullWidth
          technology={params.technology}
          handleFiles={handleCadFilesChange}
        />
      );
    }

    return (
      <PartUploadDragAndDropV2
        fullWidth
        technology={params.technology}
        handleFiles={handleCadFilesChange}
      />
    )
  }

  const renderUploadCADForm = () => {
    return (
      <div style={{ padding: !isMobile && '0 8rem' }}>
        {!isMobile &&
          <img src={bigFactoremLogo} alt='logo' style={{ position: 'absolute', bottom: 0, right: 0, padding: '1rem' }} />
        }
        <FtrTypography type='heading' fontSize={isMobile ? '22' : '24'} className={classes.title}>
          Upload CAD File to get instant quote
        </FtrTypography>
        <div style={{ width: '100%' }}>
          <SelectTechnologies
            id="select-technology"
            selectedTechnology={params.technology}
            onChange={setParams}
          />
          <div style={{ paddingTop: '0.8rem' }}>
            {renderDragAndDropFileUploadComp()}
            <div style={{
              paddingTop: '1.3rem',
              display: 'flex',
              justifyContent: 'center',
            }}>
              <img src={secureUploadIcon} style={{ padding: '0 0.3rem' }} alt='logo' />
              <FtrTypography type='body' fontSize='14'>
                All your files are secure and confidential.
              </FtrTypography>
            </div>
          </div>
        </div>
        <GetSampleUpload handleCadFilesChange={handleCadFilesChange} />
        <FtrTypography type='body' fontSize='14'>
          Already have an account?{' '}
          <Link style={{ textDecoration: 'none', fontWeight: 'bold', color: colors.blue060 }} to='/login'>
            Log in{' '}
          </Link>
          instead.
        </FtrTypography>
      </div>
    );
  }

  return (
    <Grid container wrap="nowrap" className={classes.container}>
      {!isMobile && <Grid item sm={4} style={{ height: '100%' }}>
        <Fragment>
          <div className={classes.topSidebar}>
            <a href='https://www.factorem.co' target='_blank' rel="noreferrer">
              <img src={factoremLogo} alt='logo' style={{ display: 'block', padding: '0.5rem 0 1rem 0' }} />
            </a>
            Empowering Customers Worldwide with Quality & On-Time Deliveries
            <Box style={{ height: '2rem' }}></Box>
            Experience seamless sourcing powered by&nbsp;
            <span style={{ fontWeight: 'bold' }}>Instant Pricing</span>&nbsp;and&nbsp;
            <span style={{ fontWeight: 'bold' }}>Design Feedback</span>&nbsp;in one dynamic platform.
            <Box style={{ height: '1rem' }}></Box>
            <div style={{ display: 'flex' }}>
              <img src={blueCheckIcon} alt='checkmark' style={{ marginRight: '0.5rem', paddingBottom: '0.4rem' }} />
              10 Manufacturing Verticals
            </div>
            <div style={{ display: 'flex' }}>
              <img src={blueCheckIcon} alt='checkmark' style={{ marginRight: '0.5rem', paddingBottom: '0.4rem' }} />
              200 Materials
            </div>
            <div style={{ display: 'flex' }}>
              <img src={blueCheckIcon} alt='checkmark' style={{ marginRight: '0.5rem', paddingBottom: '0.4rem' }} />
              30 Surface Finish Options
            </div>
          </div>
          <div className={classes.btmSidebar}>
            &nbsp;Trusted by
            {renderTrustedBrands()}
          </div>
        </Fragment>
      </Grid>}
      <Grid container component="main" justifyContent='center' className={classes.form}>
        {renderBackdrop()}
        <div style={{
          padding: isMobile && '4rem 5% 5rem 5%',
          filter: isLoading && 'blur(3px)'
        }}>
          {renderUploadCADForm()}
          {isMobile &&
            <img src={factoremLogoWithName} alt='logo' style={{ paddingTop: '2rem' }} />
          }
        </div>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    userID: state.auth?.user?.userID,
    userName: state.auth?.user?.name,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    resetStatus: () => dispatch(resetStatus()),
    openPartSummaryPage: dataArray => dispatch(openPartSummaryPage(dataArray, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(GetQuoteNowV2);
