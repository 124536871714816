import { clearApiCacheResponse } from "../utils/apiUtils";

import { SERVER_EVENT_TYPES } from "../constants/serverEventConstants";

export const handleServerEvent = (serverEvent) => {
  if (serverEvent?.type === SERVER_EVENT_TYPES.CLEAR_PRICE_CACHE) {
    //  clear price cache
    clearApiCacheResponse('getCheckoutPricingSummary');
  }
}
