export const BUYER_UPLOAD_PARTS_TOUR = "BUYER_UPLOAD_PARTS_TOUR";
export const BUYER_UPLOAD_PARTS_SUMMARY_TOUR = "BUYER_UPLOAD_PARTS_SUMMARY_TOUR";
export const CLEAR_TOUR = "CLEAR_TOUR";
export const PART_UPLOAD_STEP_ONE_TOUR = "PART_UPLOAD_STEP_ONE_TOUR";
export const PART_UPLOAD_STEP_TWO_TOUR = "PART_UPLOAD_STEP_TWO_TOUR";
export const PARTS_LIBRARY_FIRST_TIME = "PARTS_LIBRARY_FIRST_TIME";

export const COMPLETED_VALUE = 1;

export const backAction = {
  classes: 'shepherd-button-secondary',
  text: 'Back',
  action() {
    this.back();
  }
}

export const continueAction = {
  classes: 'shepherd-button-primary',
  text: 'Continue',
  action() {
    this.next();
  }
}

export const restartAction = {
  classes: 'shepherd-button-yellow',
  text: 'Restart',
  action() {
    if (this.isActive()) {
      this.cancel(); // Cancel the current tour
    }
    this.start();
  }
}

export const stepNumberButton = {
  classes: 'step-number',
  text: '',
};

export const restartTourStep = (params = {}) => {
  const { isMobile } = params;
  return {
    attachTo: {
      element: '#getting-started-tab',
      on: isMobile ? 'bottom' : 'right',
    },
    buttons: [
      backAction,
      restartAction,
      {
        ...continueAction,
        text: 'Got it',
      },
    ],
    title: 'Restart Tour',
    text: [
      'Want to review the steps again? Let’s start the tour from the beginning! ',
    ],
  }
}