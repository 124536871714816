import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import DownloadableFileWithActions from './list-items/DownloadableFileWithActions';
import InvoiceReconciledInfo from './info/InvoiceReconciledInfo';
import FtrTooltip from './ftr-components/FtrTooltip';
import { ReactComponent as InfoIcon } from '../assets/icons/info_icon_blue.svg';
import { FlexRow } from './layouts/FlexLayouts';
import { FtrButton } from './ftr-components';

import withSendSupplierInvoiceToHubdocPopupHOC from '../hocs/withSendSupplierInvoiceToHubdocPopupHOC';

import {
  extractFileNameFromUrlFull,
  extractFileNameWithoutTimestampFromUrl,
  extractTimestampStringFromUrl,
  isFileNameWithTimestamp,
} from '../utils/fileUtils';
import { dateTzSingapore } from '../utils/dateTimeUtils';
import { isAdminOrHigherRole } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';

import { getUserSelector } from '../selectors/userSelector';

import { colors } from '../palette';

function SupplierInvoiceDisplay({
  supplierInvoiceInfo,
  updateSupplierInvoiceInfoState,
  handleDeleteSupplierInvoice = () => {},
  showDeleteButton = false,
  isLoading = false,
  isInvoiceSentToHubdoc = false,
  isAnyInvoiceSentToHubdoc = false,
  allowSendToHubdoc = false,
  isOrderDelivered = false,
}) {
  const {
    role: userRole,
  } = useSelector(getUserSelector);

  const {
    supplierInvoiceInfoID,
    supplierInvoiceUrl,
    apiResponse,
    responseError,
    invoiceExtractionApiCallID,
    isInvoiceReconciled,
  } = supplierInvoiceInfo;
  const hasValidTimestamp = isFileNameWithTimestamp(supplierInvoiceUrl);

  const apiResponseString = apiResponse && JSON.stringify((apiResponse.matchingInvoiceText || apiResponse), null, 2);
  const responseErrorString = responseError && JSON.stringify(responseError, null, 2);
  const apiReturnStr = responseError
    ? `Could not automatically extract: ${responseErrorString}`
    : `Result of extraction service: ${apiResponseString || "No call to the invoice extraction service was made."}`

  const renderDownloadableInvoice = () => {
    return (
      <FlexRow>
        <DownloadableFileWithActions
          key={`${supplierInvoiceUrl}`}
          fileUrl={supplierInvoiceUrl}
          fileName={
            hasValidTimestamp
              ? extractFileNameWithoutTimestampFromUrl(supplierInvoiceUrl)
              : extractFileNameFromUrlFull(supplierInvoiceUrl)
          }
          showDeleteButton={showDeleteButton}
          onDelete={() => handleDeleteSupplierInvoice(supplierInvoiceUrl)}
          noteMessage={`Upload Date: ${
            hasValidTimestamp
              ? dateTzSingapore(
                Number(extractTimestampStringFromUrl(supplierInvoiceUrl))
              )
              : 'No upload date found'
          }`}
        />
      </FlexRow>
    )
  }
  
  const renderWithInvoiceExtractionInfo = () => {
    return (
      <InvoiceReconciledInfo
        isInvoiceSentToHubdoc={isInvoiceSentToHubdoc}
        showCheckIconOnly={isAnyInvoiceSentToHubdoc}
        isOrderDelivered={isOrderDelivered}
      >
      {renderDownloadableInvoice()}
      {isAdminOrHigherRole(userRole) && (
        <FtrTooltip description={apiReturnStr} theme={'light'}>
          <InfoIcon style={{ color: colors.neutral040 }} />
        </FtrTooltip>
      )}
      {allowSendToHubdoc && (
        <FtrButton
          color='blue'
          variant='contained'
          onClick={() => {
            updateSupplierInvoiceInfoState({
              open: true,
              supplierInvoiceInfoID,
              isInvoiceReconciled,
            })
          }}
          autoFocus
          disabled={false}
        >
          Send to Finance
        </FtrButton>
      )}
      </InvoiceReconciledInfo>
    )
  }

  if (isLoading) {
    return (
      <FlexRow>
        {renderDownloadableInvoice()}
        <CircularProgress size={35} />
      </FlexRow>
    )
  }

  return (
    isEmptyValue(invoiceExtractionApiCallID) && !isInvoiceSentToHubdoc
      ? renderDownloadableInvoice()
      : renderWithInvoiceExtractionInfo()
  );
}

export default withSendSupplierInvoiceToHubdocPopupHOC(SupplierInvoiceDisplay);
