import React, { useState } from 'react';
import { isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import SubDesc from '../SubDesc';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function AddPromotionCodePopup(props) {
  const classes = useStyles();

  const {
    dialog,
    handleClose,
    onOk,
  } = props;

  const [codeName, setCodeName] = useState(null);
  const [percentOff, setPercentOff] = useState(0);
  const [formError, setFormError] = useState({});

  const validateForm = () => {
    if (isEmpty(codeName)) {
      setFormError({
        codeName: 'Required field',
      });
      return false;
    }
    if (!(new RegExp(/^[a-z0-9]+$/i)).test(codeName)) {
      setFormError({
        codeName: 'Invalid pattern. Only letters and numbers are allowed.',
      });
      return false;
    }
    if (percentOff <= 0) {
      setFormError({
        percentOff: 'Value must be greater than 0',
      });
      return false;
    }
    return true;
  }

  const handleCreateButtonClick = () => {
    setFormError({});
    const valid = validateForm();
    if (!valid) {
      return;
    }
    if (typeof onOk === 'function') {
      const payload = {
        codeName,
        percentOff,
      }
      onOk(payload);
    }
  }

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="confirmation-dialog-title">
        <SubDesc content="Add New Promotion Code" />
      </DialogTitle>
      <div style={{ padding: '35px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <React.Fragment>
            <TextField
              label="Code Name"
              variant="outlined"
              onFocus={() => setFormError({ ...formError, codeName: undefined })}
              onChange={evt => setCodeName(evt.target.value)}
              value={codeName}
              error={!!formError.codeName}
              helperText={formError.codeName}
              inputProps={{ style: { textTransform: "uppercase" } }}
              margin="dense"
              fullWidth
              autoFocus
            />
            <TextField
              type='number'
              label="Percent Off"
              variant="outlined"
              onFocus={() => setFormError({ ...formError, percentOff: undefined })}
              onChange={evt => setPercentOff(evt.target.value)}
              value={percentOff}
              error={!!formError.percentOff}
              helperText={formError.percentOff}
              margin="dense"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">%</InputAdornment>
                )
              }}
            />
          </React.Fragment>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <WhiteButton
              onBtnClick={handleClose}
              btnContent='Cancel'
              size='small'
            />
            <Box className={classes.space}></Box>
            <BlueButton
              onBtnClick={handleCreateButtonClick}
              btnContent='Add'
            />
          </div>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default AddPromotionCodePopup;
