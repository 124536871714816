import {
  UPDATE_ALL_FILE_TYPES_SUPPORT,
  UPDATE_FILTER_STATUS_BROWSE,
  UPDATE_SEARCH_KEYWORD_BROWSE,
} from '../actions/pageSettings';

const defaultState = {
  filterStatusBrowse: {
    checkedCNC: false,
    checked3d: false,
    checkedSheetMetal: false,
    checkedDesignService: false,
    checkedOthers: false,
  },
  searchKeywordBrowse: '',
  allFileTypesSupport: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_FILTER_STATUS_BROWSE: {
      return {
        ...state,
        filterStatusBrowse: action.payload,
      };
    }
    case UPDATE_SEARCH_KEYWORD_BROWSE: {
      return {
        ...state,
        searchKeywordBrowse: action.payload,
      };
    }
    case UPDATE_ALL_FILE_TYPES_SUPPORT: {
      return {
        ...state,
        allFileTypesSupport: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
