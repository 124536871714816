import { isEmpty } from "lodash";
import { isEmptyValue } from '../utils/commonUtils';

export const getQcReportsList = (qcReports) => {
  if (isEmpty(qcReports)) {
    return [];
  }
  let qcReportList = [];
  if (!isEmpty(qcReports.main)) {
    qcReportList.push(qcReports.main);
  }
  if (!isEmpty(qcReports.addOns)) {
    qcReportList = [...qcReportList, ...qcReports.addOns];
  }
  if (!isEmpty(qcReports.others)) {
    qcReportList = [...qcReportList, ...qcReports.others];
  }
  return qcReportList;
}

export const getOwnerNameAndEmail = (data) => {
    if (isEmptyValue(data.owners)) {
        return `${data.userName} <${data.userEmail}>`
    }
    const owner = data.owners[0];
    return `${owner.ownerName} <${owner.ownerEmail}>`;
}