import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent } from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { FlexColumn } from '../layouts/FlexLayouts';
import { FtrB3, FtrH6 } from '../ftr-components';

import { convertPriceToCurrencyBeautified } from '../../utils/currencyUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';
import { SHIPPING_MODES } from '../../constants/checkoutConstants';


// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    minWidth: 350,
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function MctDeliveryBreakdownPopup(props) {
  const classes = useStyles();

  const {
    data = {},
    onClose = () => { },
  } = props;

  const { priceDetails = {} } = data;

  const {
    dhlShippingFee = 0,
    nonDhlShippingFee = 0,
    markupDhlShippingFee = 0,
    dhlMultiplier = 0,
  } = priceDetails?.dhlResult || {};

  const {
    fedExPriorityShippingFee = 0,
    fedExEconomyShippingFee = 0,
    nonFedExShippingFee = 0,
    markupFedExShippingFee = 0,
    dhlDeliveryFee = 0,
    fedExPriorityMultiplier = 0,
    fedExEconomyMultiplier = 0,
    fedExPriorityServiceName,
    fedExEconomyServiceName,
  } = priceDetails?.fedExResult || {};

  const title = `Delivery cost breakdown${data.referenceName ? ` for MCT ${data.referenceName}` : ''}`;

  const renderDhlContent = (deliveryOption, options) => {
    const { showTotalCost = true } = options || {};
    // SHIPPING_MODES.SAME_DATE for legacy DHL shipping
    if (![SHIPPING_MODES.DHL_PRIORITY, SHIPPING_MODES.SAME_DATE].includes(deliveryOption)) {
      return null;
    }
    const dhlShippingFeeStr = convertPriceToCurrencyBeautified({
      price: dhlShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const nonDhlShippingFeeStr = convertPriceToCurrencyBeautified({
      price: nonDhlShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const markupDhlShippingFeeStr = convertPriceToCurrencyBeautified({
      price: markupDhlShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const originalDeliveryFeeStr = convertPriceToCurrencyBeautified({
      price: priceDetails?.dhlResult?.originalDeliveryFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    return (
      <FlexColumn>
        {showTotalCost && (
          <FtrB3>Total cost: {data.deliveryCost}</FtrB3>
        )}
        {dhlShippingFee > 0 && (
          <FtrB3>DHL fee: {dhlShippingFeeStr}</FtrB3>
        )}
        {nonDhlShippingFee > 0 && (
          <FtrB3>Non DHL fee: {nonDhlShippingFeeStr}</FtrB3>
        )}
        {markupDhlShippingFee > 0 && (
          <FtrB3>Markup: {markupDhlShippingFeeStr}</FtrB3>
        )}
        {dhlMultiplier > 0 && (
          <FtrB3>Multiplier: {dhlMultiplier}</FtrB3>
        )}
        {priceDetails?.dhlResult?.originalDeliveryFee > 0 && (
          <FtrB3>Original fee: {originalDeliveryFeeStr}</FtrB3>
        )}
      </FlexColumn>
    );
  }

  const renderFedExContent = (deliveryOption, options) => {
    const { showTotalCost = true } = options || {};
    if (![SHIPPING_MODES.FEDEX_PRIORITY, SHIPPING_MODES.FEDEX_ECONOMY].includes(deliveryOption)) {
      return null;
    }
    const fedExPriorityShippingFeeStr = convertPriceToCurrencyBeautified({
      price: fedExPriorityShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });
    const fedExEconomyShippingFeeStr = convertPriceToCurrencyBeautified({
      price: fedExEconomyShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const nonFedExShippingFeeStr = convertPriceToCurrencyBeautified({
      price: nonFedExShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const markupFedExShippingFeeStr = convertPriceToCurrencyBeautified({
      price: markupFedExShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });
    
    const dhlDeliveryFeeStr = convertPriceToCurrencyBeautified({
      price: dhlDeliveryFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const originalDeliveryFeeStr = convertPriceToCurrencyBeautified({
      price: priceDetails?.fedExResult?.originalDeliveryFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    return (
      <FlexColumn>
        {showTotalCost && (
          <FtrB3>Total cost: {data.deliveryCost}</FtrB3>
        )}
        {data.deliveryOption === SHIPPING_MODES.FEDEX_PRIORITY && (
          <>
            {!isEmptyValue(fedExPriorityShippingFee) && (
              <FtrB3>FedEx fee: {fedExPriorityShippingFeeStr}</FtrB3>
            )}
            {fedExPriorityMultiplier > 0 && (
              <FtrB3>Multiplier: {fedExPriorityMultiplier}</FtrB3>
            )}
            {!isEmptyValue(fedExPriorityServiceName) && (
              <FtrB3>Service: {fedExPriorityServiceName}</FtrB3>
            )}
          </>
        )}
        {data.deliveryOption === SHIPPING_MODES.FEDEX_ECONOMY && (
          <>
            {!isEmptyValue(fedExEconomyShippingFee) && (
              <FtrB3>FedEx fee: {fedExEconomyShippingFeeStr}</FtrB3>
            )}
            {fedExEconomyMultiplier > 0 && (
              <FtrB3>Multiplier: {fedExEconomyMultiplier}</FtrB3>
            )}
            {!isEmptyValue(fedExEconomyServiceName) && (
              <FtrB3>Service: {fedExEconomyServiceName}</FtrB3>
            )}
          </>
        )}
        {dhlDeliveryFee > 0 && ( // This is for using DHL delivery cost as a fallback when the FedEx API fails
          <>
            <FtrB3>DHL delivery cost: {dhlDeliveryFeeStr}</FtrB3>
            <div style={{ paddingLeft: '1rem' }}>
              {renderDhlContent(SHIPPING_MODES.DHL_PRIORITY, { showTotalCost: false })}
            </div>
          </>
        )}
        {priceDetails?.fedExResult?.originalDeliveryFee > 0 && (
          <FtrB3>Original fee: {originalDeliveryFeeStr}</FtrB3>
        )}
        {nonFedExShippingFee > 0 && (
          <FtrB3>Non FedEx fee: {nonFedExShippingFeeStr}</FtrB3>
        )}
        {markupFedExShippingFee > 0 && (
          <FtrB3>Markup: {markupFedExShippingFeeStr}</FtrB3>
        )}
      </FlexColumn>
    );
  }

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <Close className={classes.closeIcon} onClick={onClose} />
      <DialogContent
        className={classes.dialogContent}
      >
        <FtrH6 style={{ marginBottom: '1rem' }}>
          {title}
        </FtrH6>
        {renderDhlContent(data.deliveryOption)}
        {renderFedExContent(data.deliveryOption)}
      </DialogContent>
    </Dialog>
  );
}

export default MctDeliveryBreakdownPopup;
