import React from 'react';

import { trackActionApi } from '../apis/actionTrackingApi';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

function withActionTrackingHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const {
      userID,
      actionTrackingKey = '',
      onClick = () => { },
    } = props;

    return (
      <>
        <WrappedComponent
          {...props}
          onClick={() => {
            const shouldTrackAction = !isEmptyValue(actionTrackingKey) && !isEmptyValue(userID);
            if (shouldTrackAction) {
              trackActionApi({
                actionTrackingKey,
                userID,
              });
            }

            onClick();
          }}
        />
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withActionTrackingHOC;
