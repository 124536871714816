import React from 'react';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import ProjectItemGridContainer from './grids/ProjectItemGridContainer';
import { FtrTypography } from './ftr-components';
import FtrDivider from './ftr-components/FtrDivider';
import FtrInfoIconTooltip from './ftr-components/FtrInfoIconTooltip';

import { ReactComponent as LinkIcon } from '../assets/icons/link_icon.svg';
import { colors } from '../palette';
import { isEmptyValue } from '../utils/commonUtils';

const useStyles = makeStyles((theme) => ({
  browseOption: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: colors.blue050,
    fontWeight: 700,
    padding: '10px',
  },
  link: {
    textDecoration: 'underline',
  },
  linkIcon: {
    verticalAlign: 'middle',
    height: '1rem',
    width: '1rem',
    cursor: 'pointer',
    mixBlendMode: 'multiply',
  },
}));

export const HeaderProject = (props) => {
  const { title, dividerStyle, titleStyle, lineStyle } = props;
  const classes = useStyles();
  return (
    <>
      <FtrTypography className={classes.title} style={titleStyle}>
        {title}
      </FtrTypography>
      <FtrDivider
        style={{ marginBottom: '1.5rem', ...dividerStyle }}
        lineStyle={lineStyle}
      />
    </>
  );
};

const TitleProject = (props) => {
  const { title, toolTipText } = props;
  return (
    <div>
      {title}
      <FtrInfoIconTooltip
        toolTipText={toolTipText}
        style={{ verticalAlign: 'middle', marginLeft: '5px' }}
      />
    </div>
  );
};

export const CuratedProjects = (props) => {
  const { data } = props;
  const classes = useStyles();

  if (isEmptyValue(data)) {
    return (
      <>
        <HeaderProject
          title={
            <TitleProject
              title='Curated For You'
              toolTipText='Projects that are curated for you based on your capabilities and historical performance with Factorem.'
            />
          }
          dividerStyle={{ marginBottom: '10px' }}
        />
        <Alert severity='info'>
          No curated jobs available right now. Factorem will notify you as soon
          as more opportunities become available. In case you have new
          capabilities, fill out the Partner Capabilities Form{' '}
          <a
            href='https://form.jotform.com/Factorem/PartnerCapability'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
          <a
            href='https://form.jotform.com/Factorem/PartnerCapability'
            target='_blank'
            rel='noreferrer'
          >
            <LinkIcon className={classes.linkIcon} />
          </a>
          <br />
          <br />
          In the meantime, you may check the status of your&nbsp;
          <Link to='/orders' className={classes.link}>
            Quotes
          </Link>
          &nbsp;and&nbsp;
          <Link to='/accepted-orders' className={classes.link}>
            Accepted Orders.
          </Link>
          ‌
        </Alert>
      </>
    );
  }
  return (
    <div>
      <HeaderProject
        title={
          <TitleProject
            title='Curated For You'
            toolTipText='Projects that are curated for you based on your capabilities and historical performance with Factorem.'
          />
        }
        dividerStyle={{ marginBottom: '10px' }}
      />
      <ProjectItemGridContainer projects={data} />
    </div>
  );
};

export const OtherProjects = (props) => {
  const { data, style } = props;
  const classes = useStyles();

  if (isEmptyValue(data)) {
    return (
      <div style={style}>
        <HeaderProject
          title={
            <TitleProject
              title='Other Projects'
              toolTipText='Projects that are open for consideration. These may be beyond your declared capabilities. You may quote for them if you are confident of meeting the requirements.'
            />
          }
          dividerStyle={{ marginBottom: '10px' }}
          lineStyle={{ width: '135px' }}
        />
        <Alert severity='info'>
          No other jobs available right now. Factorem will notify you as soon as
          more opportunities become available.
          <br />
          <br />
          In the meantime, you may check the status of your&nbsp;
          <Link to='/orders' className={classes.link}>
            Quotes
          </Link>
          &nbsp;and&nbsp;
          <Link to='/accepted-orders' className={classes.link}>
            Accepted Orders.
          </Link>
          ‌
        </Alert>
      </div>
    );
  }
  return (
    <div style={style}>
      <HeaderProject
        title={
          <TitleProject
            title='Other Projects'
            toolTipText='Projects that are open for consideration. These may be beyond your declared capabilities. You may quote for them if you are confident of meeting the requirements.'
          />
        }
        dividerStyle={{ marginBottom: '10px' }}
        lineStyle={{ width: '135px' }}
      />
      <ProjectItemGridContainer projects={data} />
    </div>
  );
};
