import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles/index';

import { IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';

import { Delete as DeleteIcon } from "@material-ui/icons";

import { useItemInputConfig } from '../../hooks/useItemInputConfig';

import OtherMaterialField from './form-fields/OtherMaterialField';
import OtherSurfaceFinishingField from './form-fields/OtherSurfaceFinishingField';
import ThreeDInfillFieldSelect from './form-fields/ThreeDInfillFieldSelect';
import ThreeDLayerThicknessFieldSelect from './form-fields/ThreeDLayerThicknessFieldSelect';
import { is3DPTechnology } from '../../utils/itemUtils';
import { THREE_D_P_FDM_TECH } from '../../constants/NewPartConstants';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import { CMM_PRICE_STR, COLOR_STR, MATERIAL_COLOR_STR, MATERIAL_STR, SURFACE_FINISH_STR, THREE_D_TECHNOLOGY } from '../../constants/quotationConstants';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import { isCustomMaterial, isCustomSurfaceFinish } from '../../utils/inputUtils';
import SurfaceFinishingField from '../inputs/SurfaceFinishingField';
import ColorInputField from '../inputs/ColorInputField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import { getCurrencySymbol } from '../../utils/currencyUtils';

const useStyles = makeStyles(() => ({
  quoteWrapper: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    display: "flex",
    justifyContent: "space-between",
  },
  quantityWrapper: {
    width: "7%",
    minWidth: "80px",
    marginRight: "4px",
  },
  unitPriceWrapper: {
    width: "14%",
    minWidth: "100px",
    marginRight: "4px",
  },
  totalPriceWrapper: {
    width: "12%",
    minWidth: "120px",
    marginRight: "4px",
  },
  materialWrapper: {
    width: "auto",
    minWidth: "180px",
    marginRight: "4px",
  },
  surfaceFinishWrapper: {
    width: "auto",
    minWidth: "180px",
    marginRight: "4px",
    marginLeft: 2,
  },
  leadTimeWrapper: {
    width: 200,
    minWidth: 200,
    marginLeft: 2,
  },
  multiplySymbolWrapper: {
    margin: "0 4px 0 2px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  equalSymbolWrapper: {
    margin: "0 4px 0 2px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
}));

function RfqSingleQuotationFormSingleView(props) {
  const classes = useStyles();

  const {
    index,
    currency,
    inputField,
    errors,
    userCountry,
    onInputFieldsChange,
    showDeleteButton = false,
    onRemoveFields,
    isCmmReport
  } = props;

  const [
    {
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
    }
  ] = useItemInputConfig({
    setMaterial: (value) => {
      const event = { target: { value, name: MATERIAL_STR } }
      onInputFieldsChange(event);
    },
    setSurfaceFinish: (value) => {
      const event = { target: { value, name: SURFACE_FINISH_STR } }
      onInputFieldsChange(event);
    },
    setMaterialColor: (value) => {
      const event = { target: { value, name: MATERIAL_COLOR_STR } }
      onInputFieldsChange(event);
    },
    setColor: (value) => {
      const event = { target: { value, name: COLOR_STR } }
      onInputFieldsChange(event);
    }
  });

  useEffect(() => {
    if (isEmpty(inputField)) {
      return;
    }
    const {
      threeDTechnology,
      material,
      materialColor,
      surfaceFinish,
      color,
      technology,
    } = inputField;
    loadSelectColorSurfaces({ technology });
    loadMaterialCategoryOptions({ technology }, false);
    if (is3DPTechnology(inputField.technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions({
        technology,
        threeDTechnology,
      }, false);
    }
    const params = {
      technology,
      threeDTechnology,
      material,
    }
    loadSurfaceFinishOptions(params, false);
    if (materialColor) {
      loadMaterialColorOptions(params, false);
    }
    if (color) {
      loadSurfaceFinishColorOptions({
        technology,
        surfaceFinish,
      }, false);
    }
  }, [inputField]);

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions)}
        value={inputField.color}
        colorInputLabel={"Color"}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => {
          const event = { target: { value: selectedColor, name: COLOR_STR } }
          onInputFieldsChange(event);
        }}
        bootstrapStyle={false}
        quotationField
      />
    );
  }

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions)}
        value={inputField.materialColor}
        colorInputLabel="Material Color"
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) => {
          const event = { target: { value: selectedColor, name: MATERIAL_COLOR_STR } }
          onInputFieldsChange(event);
        }}
        bootstrapStyle={false}
        quotationField
      />
    );
  }

  return (
    <div key={index} className={classes.quoteWrapper}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <div className={classes.quantityWrapper}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            required
            margin="dense"
            label="Quantity"
            name="quantity"
            type="number"
            value={inputField.quantity}
            InputLabelProps={{ shrink: true }}
            error={get(errors, [index, 'quantity']) !== undefined}
            helperText={errors[index].quantity}
            onChange={onInputFieldsChange}
            disabled={index === 0 ? true : false}
            style={{ width: "100%" }}
          />
        </div>
        <div className={classes.multiplySymbolWrapper}>x</div>
        <div className={classes.unitPriceWrapper}>
          <Tooltip
            title="Include all charges (fabrication, shipping, etc.) except for GST"
            arrow
          >
            <TextField
              id="outlined-basic"
              data-cy="unit-price-rfq"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currency === "MYR" ? (
                      <div>RM</div>
                    ) : (
                      <div>S$</div>
                    )}
                  </InputAdornment>
                ),
              }}
              required
              margin="dense"
              label="Unit Price"
              name="unitQuote"
              type="number"
              value={inputField.unitQuote}
              placeholder="00.00"
              error={errors[index].unitQuote !== undefined}
              helperText={errors[index].unitQuote}
              onChange={onInputFieldsChange}
              style={{ width: "100%" }}
            />
          </Tooltip>
        </div>
        <div className={classes.equalSymbolWrapper}>=</div>
        <div className={classes.totalPriceWrapper}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currency === "MYR" ? <div>RM</div> : <div>S$</div>}
                </InputAdornment>
              ),
            }}
            disabled
            fullWidth
            margin="dense"
            label="Total (excl. GST)"
            name="quote"
            type="number"
            value={inputField.quote}
            placeholder="00.00"
          />
        </div>
        {is3DPTechnology(inputField.technology) && (
          <div style={{ width: 'auto', minWidth: 220 }}>
            {/* <ThreeDPrintingTechSelectField
                value={inputField.threeDTechnology}
                onChange={onInputFieldsChange}
              /> */}
            <ThreeDPrintingTechnologyInputField
              visible={is3DPTechnology(inputField.technology)}
              value={inputField.threeDTechnology}
              onChange={(newValue) => {
                const event = { target: { value: newValue, name: THREE_D_TECHNOLOGY } };
                onInputFieldsChange(event);
                const params = {
                  technology: inputField.technology,
                  threeDTechnology: newValue,
                }
                threeDTechnologyHasChanged(params);
              }}
              threeDTechnologyOptions={threeDTechnologyOptions}
              bootstrapStyle={false}
            />
          </div>
        )}
        <div className={classes.materialWrapper}>
          <MaterialCategoriesInputField
            visible={!is3DPTechnology(inputField.technology)}
            value={inputField.material}
            onSelect={(value) => {
              const event = { target: { value, name: MATERIAL_STR } }
              onInputFieldsChange(event);
              const {
                threeDTechnology,
              } = inputField;
              const params = {
                technology: inputField.technology,
                threeDTechnology,
                material: value,
              }
              materialHasChanged(params);
            }}
            materialCategoryOptions={materialCategoryOptions}
            bootstrapStyle={false}
          />
          <ThreeDPrintingMaterialField
            visible={is3DPTechnology(inputField.technology)}
            value={inputField.material}
            onSelect={(_material) => {
              const event = { target: { value: _material, name: MATERIAL_STR } }
              onInputFieldsChange(event);
              const {
                threeDTechnology,
              } = inputField;
              const params = {
                technology: inputField.technology,
                threeDTechnology,
                material: _material,
              }
              materialHasChanged(params);
            }}
            threeDMaterialOptions={threeDMaterialOptions}
            defaultThreeDMaterial={defaultThreeDMaterial}
            bootstrapStyle={false}
          />
        </div>
        {isCustomMaterial(inputField.material) && (
          <div style={{ width: 'auto', minWidth: 150, marginLeft: 2 }}>
            <OtherMaterialField
              value={inputField.otherMaterial}
              error={errors[index].otherMaterial}
              onChange={onInputFieldsChange}
            />
          </div>
        )}
        {inputField.materialColor && renderMaterialColorInputField()}
        {((!isEmpty(surfaceFinishOptions) && inputField.surfaceFinish) || isCustomSurfaceFinish(inputField.surfaceFinish)) && (
          <div className={classes.surfaceFinishWrapper}>
            <SurfaceFinishingField
              visible={!isEmpty(surfaceFinishOptions) || isCustomSurfaceFinish(inputField.surfaceFinish)}
              value={inputField.surfaceFinish}
              onSelect={(newValue) => {
                const event = { target: { value: newValue, name: SURFACE_FINISH_STR } }
                onInputFieldsChange(event);
                const {
                  material,
                } = inputField;
                const params = {
                  technology: inputField.technology,
                  material,
                  surfaceFinish: newValue,
                }
                surfaceFinishHasChanged(params);
              }}
              surfaceFinishOptions={surfaceFinishOptions}
              selectColorSurfaces={selectColorSurfaces}
              bootstrapStyle={false}
            />
          </div>
        )}
        {isCustomSurfaceFinish(inputField.surfaceFinish) && (
          <div style={{ width: 'auto' }}>
            <OtherSurfaceFinishingField
              value={inputField.otherSurfaceFinish}
              error={errors[index].otherSurfaceFinish}
              onChange={onInputFieldsChange}
            />
          </div>
        )}
        {renderSurfaceFinishColor()}
        {inputField.threeDTechnology === THREE_D_P_FDM_TECH && (
          <div style={{ width: 'auto', minWidth: 150, marginLeft: 2 }}>
            <ThreeDInfillFieldSelect
              value={inputField.threeDInfill}
              onChange={onInputFieldsChange}
              error={errors[index].threeDInfill}
            />
          </div>
        )}
        {inputField.threeDTechnology === THREE_D_P_FDM_TECH && (
          <div style={{ width: 'auto', minWidth: 200, marginLeft: 2 }}>
            <ThreeDLayerThicknessFieldSelect
              value={inputField.threeDLayerThickness}
              onChange={onInputFieldsChange}
              error={errors[index].threeDLayerThickness}
            />
          </div>
        )}
        <div className={classes.leadTimeWrapper}>
          <Tooltip
            title={
              userCountry === "Singapore" 
                ? "State lead time required upon order acceptance (not including shipping)"
                : "State lead time required upon order acceptance (including shipping)"
            }
            placement="bottom"
            arrow
          >
            <TextField
              variant="outlined"
              required
              fullWidth
              margin="dense"
              label="Lead Time"
              name="leadTime"
              type="number"
              placeholder="E.g. 7"
              value={inputField.leadTime}
              error={errors[index].leadTime !== undefined}
              helperText={errors[index].leadTime}
              InputLabelProps={{ shrink: true }}
              onChange={onInputFieldsChange}
              InputProps={{
                inputProps: {
                  min: 0,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    working day(s)
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </div>
        {isCmmReport && (
          <div className={classes.cmmReportWrapper}>
            <Tooltip
              title='Enter CMM report price. Insert "0" if no additional charge for CMM report'
              arrow
            >
              <TextField
                style={{ width: "100%", marginLeft: 2 }}
                id="cmm-report-single"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div>{getCurrencySymbol(currency)}</div>
                    </InputAdornment>
                  ),
                }}
                required
                margin="dense"
                label="CMM Price"
                name={CMM_PRICE_STR}
                type="number"
                value={inputField.cmmPrice}
                placeholder="00.00"
                error={errors[index].cmmPrice !== undefined}
                helperText={errors[index].cmmPrice}
                onChange={(event) => onInputFieldsChange(event, inputField.id, CMM_PRICE_STR)}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <div>
        {showDeleteButton ? (
          <IconButton
            aria-label="delete"
            onClick={onRemoveFields}
            disabled={index === 0}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userCountry: state.auth.user.country,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(RfqSingleQuotationFormSingleView);