import React, { Fragment, useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles/index";

import {
  Button,
  Chip,
  Container,
  TextField,
  Tooltip,
} from "@material-ui/core";

import {
  PersonOutline as PersonOutlineIcon,
} from "@material-ui/icons";

import AvatarWithButton from "../components/AvatarWithButton";
import EditorAddressesPopup from "../components/popups/EditorAddressesPopup";

import { getUserProfile, editUserProfile } from "../actions";

import { uploadPublicFileToS3 } from "../services/s3Service";
import { downloadS3File } from "../utils/fileUtils";

import {
  getESignFileForSupplier,
  downloadSignNowPartnershipAgreement,
} from "../apis/signNowApi"
import { getS3PartnershipAgreementInfo } from "../apis/partnershipAgreementApi"

import { PROFILEPIC_DIRNAME } from "../constants";

import { colors } from "../palette";

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(12),
    paddingTop: theme.spacing(5)
  },
  container: {
    padding: 0,
    display: "flex",
    flexDirection: "column"
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    marginBottom: theme.spacing(3)
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  profilePicture: {
    cursor: "pointer"
  },
  inputField: {
    padding: '5px 14px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    marginBottom: '13px',
  },
}));

export function Profile(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    onPageLoad,
    name,
    country,
    role,
    email,
    companyName,
    companyDesc,
    secondaryName,
    secondaryEmail,
    secondaryContact,
    address,
    contact,
    profilePic,
    // onChangeRole,
    userID,
    submitEditProfile
  } = props;

  const [editProfileName, setEditName] = useState(name);
  const [editProfileContact, setEditContact] = useState(contact ? contact : "");
  const [editProfileEmail, setEditEmail] = useState(email);
  const [editCompanyName, setEditCompanyName] = useState(
    companyName ? companyName : ""
  );
  const [editCompanyDesc, setEditCompanyDesc] = useState(
    companyDesc ? companyDesc : ""
  );
  const [editSecondaryName, setEditSecondaryName] = useState(
    secondaryName ? secondaryName : ""
  );
  const [editSecondaryEmail, setEditSecondaryEmail] = useState(
    secondaryEmail ? secondaryEmail : ""
  );
  const [editSecondaryContact, setEditSecondaryContact] = useState(
    secondaryContact ? secondaryContact : ""
  );
  const [editProfileAddress, setEditAddress] = useState(address ? address : "");
  const [editProfileImg, setEditImg] = useState(profilePic ? profilePic : "");
  const [showEditorAddresses, setShowEditorAddresses] = useState(false);

  const [
    paDocState,
    updatePADocState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      s3PAUrl: null,
      signNowPADocID: null,
      signNowPADocName: null,
    },
  );

  useEffect(() => {
    onPageLoad();

    setEditName(name);
    setEditContact(contact ? contact : "");
    setEditEmail(email);
    setEditCompanyName(companyName ? companyName : "");
    setEditCompanyDesc(companyDesc ? companyDesc : "");
    setEditSecondaryName(secondaryName ? secondaryName : "");
    setEditSecondaryEmail(secondaryEmail ? secondaryEmail : "");
    setEditSecondaryContact(secondaryContact ? secondaryContact : "");
    setEditAddress(address ? address : "");
    setEditImg(profilePic ? profilePic : "");
  }, [name, contact, email, companyName, companyDesc, secondaryName, secondaryEmail, secondaryContact, address, profilePic]);

  async function isPartnershipAgreementAvailable(userID) {
    const s3PAInfo = await getS3PartnershipAgreementInfo({ supplierID: userID });
    const signNowPAInfo = await getESignFileForSupplier({ supplierID: userID, docType: 'Partnership Agreement' });

    if (s3PAInfo && s3PAInfo.partnershipAgreementUrl) {
      updatePADocState({
        s3PAUrl: s3PAInfo.partnershipAgreementUrl,
      });
    } else if (s3PAInfo && s3PAInfo.onboardingCounterSignedPartnershipAgreementUrl) {
      updatePADocState({
        s3PAUrl: s3PAInfo.onboardingCounterSignedPartnershipAgreementUrl,
      });
    } else if (s3PAInfo && s3PAInfo.onboardingPartnershipAgreementUrl) {
      updatePADocState({
        s3PAUrl: s3PAInfo.onboardingPartnershipAgreementUrl,
      });
    } else if (signNowPAInfo && signNowPAInfo.docID && signNowPAInfo.docName) {
      updatePADocState({
        signNowPADocID: signNowPAInfo.docID,
        signNowPADocName: signNowPAInfo.docName,
      });
    }
  }

  useEffect(() => {
    isPartnershipAgreementAvailable(userID);
  }, [userID]);

  function handleImgChange(e) {
    const file = e.target.files[0];
    const s3Key = `${PROFILEPIC_DIRNAME}/${file.name}`;
    uploadPublicFileToS3(file, s3Key)
      .then(data => {
        setEditImg(data.Location.split(" ").join("%20"));
      })
      .catch(err => {
        console.error(err);
        alert(err);
      });
  }

  const handleSubmit = e => {
    e.preventDefault();
    submitEditProfile({
      editProfileName,
      editProfileContact,
      editProfileEmail,
      editCompanyName,
      editCompanyDesc,
      editSecondaryName,
      editSecondaryContact,
      editSecondaryEmail,
      editProfileAddress,
      editProfileImg
    });
  };

  function downloadPartnershipAgreement() {
    if (paDocState.s3PAUrl) {
      downloadS3File(paDocState.s3PAUrl);
    } else if (paDocState.signNowPADocID && paDocState.signNowPADocName) {
      downloadSignNowPartnershipAgreement(paDocState.signNowPADocID, paDocState.signNowPADocName);
    }
  }

  return (
    <div className={classes.body}>
      <Container component="main" className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <input
            id="profile-img"
            type="file"
            onChange={handleImgChange}
            onClick={(event) => event.target.value = null}
            style={{
              display: "none"
            }}
          />
          <label htmlFor="profile-img" className={classes.profilePicture}>
            <AvatarWithButton onButtonClick={() => setEditImg("")} className={classes.avatar} src={editProfileImg} />
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px"
          }}
        >
          <Chip
            color="primary"
            size="small"
            icon={<PersonOutlineIcon />}
            label={role === "supplier" ? "Partner" : role}
            style={{
              textTransform: "capitalize"
            }}
          ></Chip>
        </div>
        <TextField
          className={classes.field}
          margin="normal"
          id="name"
          label="Name"
          name="name"
          value={editProfileName}
          onChange={evt => setEditName(evt.target.value)}
        />
        <TextField
          className={classes.field}
          margin="normal"
          id="country"
          label="Country"
          name="country"
          value={country || ''}
          disabled
        />
        <TextField
          className={classes.field}
          margin="normal"
          id="email"
          label="Email"
          name="email"
          autoComplete="off"
          disabled
          value={editProfileEmail}
        />
        <TextField
          className={classes.field}
          margin="normal"
          id="companyName"
          label="Company Name"
          name="companyName"
          value={editCompanyName}
          onChange={evt => setEditCompanyName(evt.target.value)}
        />
        <TextField
          style={{ width: "100%" }}
          multiline
          rows={6}
          margin="dense"
          id="description"
          label="Company Description"
          placeholder="Please key in any details about your company's capabilities. (e.g. Machining capabilities/materials/design capability/etc.). "
          type="text"
          fullWidth
          onChange={evt => {
            setEditCompanyDesc(evt.target.value);
          }}
          value={editCompanyDesc}
          InputLabelProps={{
            shrink: true
          }}
        />
        <TextField
          className={classes.field}
          margin="normal"
          id="contact"
          label="Contact Number"
          name="contact"
          value={editProfileContact}
          onChange={evt => setEditContact(evt.target.value)}
        />
        {role === "supplier" && (
          <Fragment>
            <Tooltip
              title='Please contact us if you wish to change POC particulars.'
              arrow
            >
              <TextField
                className={classes.field}
                margin="normal"
                id="secondaryName"
                label="Secondary POC Name"
                name="secondaryName"
                value={editSecondaryName}
                onChange={evt => setEditSecondaryName(evt.target.value)}
                disabled
              />
            </Tooltip>
            <Tooltip
              title='Please contact us if you wish to change POC particulars.'
              arrow
            >
              <TextField
                className={classes.field}
                margin="normal"
                id="secondaryEmail"
                label="Secondary POC Email Address"
                name="secondaryEmail"
                value={editSecondaryEmail}
                onChange={evt => setEditSecondaryEmail(evt.target.value)}
                disabled
              />
            </Tooltip>
            <Tooltip
              title='Please contact us if you wish to change POC particulars.'
              arrow
            >
              <TextField
                className={classes.field}
                margin="normal"
                id="secondaryContact"
                label="Secondary POC Contact Number"
                name="secondaryContact"
                value={editSecondaryContact}
                onChange={evt => setEditSecondaryContact(evt.target.value)}
                disabled
              />
            </Tooltip>
          </Fragment>
        )}
        <div style={{ display: 'flex',  margin: '0.5rem 0 0 0'}}>
          <Button
            variant="outlined"
            component="span"
            color="primary"
            className={classes.inputField}
            onClick={() => setShowEditorAddresses(true)}
            style={{ marginRight: '0.8rem' }}
          >
            View Addresses
          </Button>
          {(paDocState.s3PAUrl || (paDocState.signNowPADocID && paDocState.signNowPADocName)) 
            ? (
                <Button
                  variant="outlined"
                  component="span"
                  color="primary"
                  className={classes.inputField}
                  onClick={() => downloadPartnershipAgreement()}
                >
                  Download Partnership Agreement
                </Button>
            )
            : null
          }
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={e => handleSubmit(e)}
          >
            Submit new changes
          </Button>
        </div>
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
          <Button
            variant='contained'
            color='secondary'
            fullWidth
            onClick={() => history.push('/change-password')}
          >
            Change Password
          </Button>
        </div>
      </Container>
      <EditorAddressesPopup
        open={showEditorAddresses}
        onClose={() => setShowEditorAddresses(false)}
        userID={userID}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.editProfile.profile,
    name: state.editProfile.name,
    country: state.editProfile.country,
    role: state.editProfile.role,
    email: state.editProfile.email,
    companyName: state.editProfile.companyName,
    companyDesc: state.editProfile.companyDesc,
    secondaryName: state.editProfile.secondaryName,
    secondaryEmail: state.editProfile.secondaryEmail,
    secondaryContact: state.editProfile.secondaryContact,
    address: state.editProfile.address,
    contact: state.editProfile.contact,
    profilePic: state.editProfile.profilePic,
    userID: state.auth.user.userID
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => dispatch(getUserProfile()),
    submitEditProfile: editedProfile => dispatch(editUserProfile(editedProfile))
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(Profile);
