import React from 'react';

import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

import ItemDisplayCard from '../cards/ItemDisplayCard';

function ItemGridContainer(props) {
  const { items, supplierOrders } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Grid
        container
        spacing={3}
        style={
          isMobile
            ? { display: 'flex', justifyContent: 'center', marginTop: 0 }
            : undefined
        }
      >
        {items.map((item) => (
          <Grid item key={item.itemID} >
            <ItemDisplayCard item={item} supplierOrders={supplierOrders} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default ItemGridContainer;
