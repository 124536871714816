export const QUANTITY_STR = "quantity";
export const UNIT_QUOTE_STR = "unitQuote";
export const MATERIAL_STR = "material";
export const OTHER_MATERIAL_STR = "otherMaterial";
export const SURFACE_FINISH_STR = "surfaceFinish";
export const OTHER_SURFACE_FINISH_STR = "otherSurfaceFinish";
export const THREE_D_TECHNOLOGY = "threeDTechnology";
export const THREE_D_INFILL = "threeDInfill";
export const THREE_D_LAYER_THICKNESS = "threeDLayerThickness";
export const MATERIAL_COLOR_STR = "materialColor";
export const COLOR_STR = "color";
export const REMARKS_STR = "remarks";
export const CMM_PRICE_STR = "cmmPrice";

export const DISABLED_REASON_QUOTES = [
  'Client Design Change / Issue',
  'Chose Existing Supplier',
  'Quote too high',
  'Lead time too long',
  'Unsuitable quote specification(s)',
  'Repeat Order',
  'Order Cancelled',
  'Others',
];
