import React, { useReducer } from 'react';
import DfmAnalysisPopup from '../components/popups/DfmAnalysisPopup';

function withDfmAnalysisPopupHOC(WrappedComponent) {
  return function DfmAnalysisPopupHOC(props) {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        cadFile: '',
        twoDImageUrl: '',
        dfmDefect: {},
        isLoading: false,
      }
    );

    return (
      <>
        <WrappedComponent
          updateDfmAnalysisPopupState={(params) => {
            updateLocalState(params);
          }}
          {...props}
        />
        {localState.open && (
          <DfmAnalysisPopup
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
            cadFile={localState.cadFile}
            twoDImageUrl={localState.twoDImageUrl}
            dfmDefectOutput={localState.dfmDefectOutput}
            isLoading={localState.isLoading}
            {...props}
          />
        )}
      </>
    );
  };
}

export default withDfmAnalysisPopupHOC;
