import React from 'react';
import { Tooltip, Icon } from '@material-ui/core';
import { Publish } from '@material-ui/icons';

import { ReactComponent as LongReceiptIconGreen } from "../../assets/icons/long_receipt_green.svg";

import { isInvoiceProcessed } from '../../utils/orderReadyUtils';

export const InvoiceIcon = ({ hasSupplierInvoices, invoiceSentToHubdoc, porCreatedDate }) => {
  const hasProcessedInvoice = isInvoiceProcessed(porCreatedDate, invoiceSentToHubdoc);

  const tooltipTitle = hasSupplierInvoices
    ? hasProcessedInvoice 
      ? "Invoice uploaded and is processed"
      : "Please check the uploaded invoice"
    : "Please upload your invoice" 

  return (
    <Tooltip title={tooltipTitle}>
      {hasSupplierInvoices
        ? <Icon>
          {hasProcessedInvoice ? <LongReceiptIconGreen /> : <LongReceiptIconGreen fill={"orange"} />}
        </Icon>
        : <Publish color="error" />
      }
    </Tooltip>
  );
};
