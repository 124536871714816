import React, { useEffect, useReducer, useState } from 'react';
import startCase from 'lodash/startCase';
import { useQueryClient } from 'react-query';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

import Title from '../Title';
import AddressAutocomplete from '../AddressAutocomplete';
import BlueButton from '../buttons/BlueButton';
import GreyButton from '../buttons/GreyButton';
import ContactField from '../fields/ContactField';
import { colors } from '../../palette';
import YesNoPopup from './YesNoPopup';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { ADDRESS_TYPES } from '../../constants/deliveryInfoConstants';

import { getUserInfoWithCache } from '../../apis/userApi';
import {
  createDeliveryInfo,
  deleteDeliveryInfo,
  updateDefaultAddress,
  updateDeliveryInfo,
} from '../../apis/deliveryInfoApi';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 1000,
    borderRadius: 0,
    margin: '1rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    // width: theme.spacing(2),
  },
  title: {
    textTransform: 'capitalize',
  },
  fullNameField: {
    '& .MuiInputBase-root': {
      borderRadius: '10px',
    },
  },
}));

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {'create'|'update'} props.type
 * @param {'billing'|'shipping'} props.addressType
 * @param {() => void} props.onClose
 * @param {() => void} props.onSuccess
 */
function CreateUpdateDeliveryInfoPopup(props) {
  const classes = useStyles();

  const {
    open = false,
    onClose,
    onSuccess = () => null,
    type = 'create',
    data = null,
    userID,
    addressType
  } = props;

  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initialDeliveryInfo = {
    deliveryInfoID: '',
    address: '',
    postalCode: '',
    unitNo: '',
    contactName: '',
    contactNumber: '',
    country: '',
    showDeletePopup: false,
    switchDefaultShipping: false,
    switchDefaultBilling: false,
  };

  const [deliveryInfoState, updateDeliveryInfoState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    initialDeliveryInfo
  );
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (type === 'update' && data) {
      updateDeliveryInfoState({
        ...data,
        switchDefaultShipping: data?.defaultShipping,
        switchDefaultBilling: data?.defaultBilling
      });
    } else if (type === 'create' && userInfo) {
      updateDeliveryInfoState({
        ...initialDeliveryInfo,
        contactName: userInfo?.name,
        contactNumber: userInfo?.contact
      });
    }
  }, [type, data, userInfo]);

  useEffect(() => {
    if (userID) {
      getUserInfoWithCache(userID)
        .then(user => {
          updateDeliveryInfoState({ contactName: user?.name, contactNumber: user?.contact })
          setUserInfo(user);
        });
    }
  }, [userID])

  const checkIsError = () => {
    const listRequires = {
      address: deliveryInfoState?.address,
      fullName: deliveryInfoState?.contactName,
      contactNumber: deliveryInfoState?.onlyContactNumber,
    };
    let isError = false;
    for (const state in listRequires) {
      if (!listRequires[state]) {
        isError = true;
        notifyError(`${startCase(state)} is required!`);
      }
    }
    return isError;
  };

  const handleCreateAddress = () => {
    if (checkIsError()) return;
    const body = {
      userID,
      contactName: deliveryInfoState?.contactName,
      contactNumber: deliveryInfoState?.contactNumber,
      switchDefaultShipping: deliveryInfoState?.switchDefaultShipping,
      switchDefaultBilling: deliveryInfoState?.switchDefaultBilling,
      country: deliveryInfoState?.country,
      address: deliveryInfoState?.address,
      postalCode: deliveryInfoState?.postalCode,
      unitNo: deliveryInfoState?.unitNo,
    };
    createDeliveryInfo(body)
      .then((data) => {
        notifySuccess('Added new address successfully');
        updateDeliveryInfoState(initialDeliveryInfo);
        queryClient.invalidateQueries('getUserAddresses');
        onSuccess({
          result: { ...body, deliveryInfoID: data?.deliveryInfoID },
          addressType
        });
        onClose();
      })
      .catch(() => {
        notifyError('Failed created new address');
      });
  };

  const handleUpdateDeliveryInfo = () => {
    if (checkIsError()) return;
    const body = {
      userID,
      contactName: deliveryInfoState?.contactName,
      contactNumber: deliveryInfoState?.contactNumber,
      country: deliveryInfoState?.country,
      address: deliveryInfoState?.address,
      postalCode: deliveryInfoState?.postalCode,
      unitNo: deliveryInfoState?.unitNo,
    };
    updateDeliveryInfo(deliveryInfoState?.deliveryInfoID, body)
      .then(() => {
        notifySuccess('Updated address successfully');
        queryClient.invalidateQueries('getUserAddresses');
        onSuccess();
        onClose();
      })
      .catch(() => {
        notifyError('Failed updated new address');
      });
  };

  const handleDeleteDeliveryInfo = () => {
    deleteDeliveryInfo(deliveryInfoState?.deliveryInfoID)
      .then(() => {
        notifySuccess('Successfully deleted a address');
        queryClient.invalidateQueries('getUserAddresses');
        onClose();
      })
      .catch(() => {
        notifyError('Failed deleted a address');
      });
  };

  const handleUpdateDefaultAddress = (deliveryInfoID, type) => {
    updateDefaultAddress(deliveryInfoID, type, { userID })
      .then(() => {
        notifySuccess('Successfully updated default address');
        queryClient.invalidateQueries('getUserAddresses');
        onSuccess();
      })
      .catch(() => {
        notifyError('Failed updated default address');
      });
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={onClose}
        aria-labelledby='create-update-address'
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle id='create-update-address'>
          <Title
            className={classes.title}
            contentTitle={`${type === 'create' ? 'New' : 'Update'} address`}
            size='small'
          />
        </DialogTitle>
        <div
          style={{
            paddingBottom: '1rem',
          }}
        >
          <DialogContent
            style={{
              padding: `0 ${isMobile ? '1rem' : '2rem'}`,
              paddingBottom: '1rem',
            }}
          >
            <Grid
              container
              spacing={1}
              style={{ display: 'flex', alignItems: 'top' }}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  label='Full Name'
                  placeholder='Please Enter Your Full Name'
                  variant='outlined'
                  onChange={(evt) => {
                    updateDeliveryInfoState({ contactName: evt.target.value });
                  }}
                  value={deliveryInfoState?.contactName}
                  margin='dense'
                  fullWidth
                  required
                  className={classes.fullNameField}
                />
              </Grid>
              {userInfo && (
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <ContactField
                    required
                    onChange={({ fullContact, contact }) => {
                      updateDeliveryInfoState({
                        contactNumber: fullContact,
                        onlyContactNumber: contact,
                      });
                    }}
                    initialValue={type === 'update' ? data.contactNumber : deliveryInfoState?.contactNumber}
                  />
                </Grid>
              )}
            </Grid>
            <AddressAutocomplete
              required
              showCountry
              styleChild={{ marginTop: '0' }}
              size='small'
              onChange={({ postalCode, address, unitNo, country }) =>
                updateDeliveryInfoState({ postalCode, address, unitNo, country })
              }
              initialValue={type === 'update' ? data : initialDeliveryInfo}
            />
            {type === 'update' ? (
              <React.Fragment>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <span
                      style={{
                        border: `1px solid ${data.defaultShipping
                          ? colors.solidBlue
                          : 'black'
                          }`,
                        padding: '3px 6px',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '2px',
                        cursor: `${data.defaultShipping ? 'not-allowed' : 'pointer'
                          }`,
                        color: data.defaultShipping
                          ? colors.solidBlue
                          : 'black',
                      }}
                      onClick={() => {
                        if (!data.defaultShipping) {
                          handleUpdateDefaultAddress(
                            data.deliveryInfoID,
                            ADDRESS_TYPES.SHIPPING
                          );
                        }
                      }}
                    >
                      {data.defaultShipping
                        ? 'Default shipping'
                        : 'Set as default shipping'}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span
                      style={{
                        border: `1px solid ${data.defaultBilling
                          ? colors.solidBlue
                          : 'black'
                          }`,
                        padding: '3px 6px',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '2px',
                        color: data.defaultBilling
                          ? colors.solidBlue
                          : 'black',
                        cursor: `${data.defaultBilling ? 'not-allowed' : 'pointer'
                          }`,
                      }}
                      onClick={() => {
                        if (!data.defaultBilling) {
                          handleUpdateDefaultAddress(
                            data.deliveryInfoID,
                            ADDRESS_TYPES.BILLING
                          );
                        }
                      }}
                    >
                      {data.defaultBilling
                        ? 'Default billing'
                        : 'Set as default billing'}
                    </span>
                  </Grid>
                </Grid>
                {isMobile && (
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '2px',
                      backgroundColor: colors.hotRed,
                      color: 'white',
                      padding: '3px 6px',
                      marginTop: '0.75rem',
                    }}
                    onClick={() => {
                      updateDeliveryInfoState({ showDeletePopup: true });
                    }}
                  >
                    Delete
                  </div>
                )}
              </React.Fragment>
            ) : (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={deliveryInfoState.switchDefaultShipping}
                      onChange={(e) =>
                        updateDeliveryInfoState({
                          switchDefaultShipping: e.target.checked,
                        })
                      }
                      name='switchDefaultShipping'
                    />
                  }
                  label='Set as default shipping'
                />
                <FormControlLabel
                  className={classes.checkboxDifferentAddress}
                  control={
                    <Checkbox
                      checked={deliveryInfoState.switchDefaultBilling}
                      onChange={(e) =>
                        updateDeliveryInfoState({
                          switchDefaultBilling: e.target.checked,
                        })
                      }
                      name='switchDefaultBilling'
                    />
                  }
                  label='Set as default billing'
                />
              </div>
            )}
            <div
              style={{
                marginTop: '2rem',
                display: 'flex',
                justifyContent: 'center',
                gap: '1rem',
              }}
            >
              <GreyButton
                size='small'
                btnContent='Cancel'
                onBtnClick={onClose}
              />
              {type === 'create' ? (
                <BlueButton
                  size='small'
                  btnContent='Add address'
                  onBtnClick={handleCreateAddress}
                />
              ) : (
                <BlueButton
                  size='small'
                  btnContent='Update address'
                  onBtnClick={handleUpdateDeliveryInfo}
                />
              )}
            </div>
          </DialogContent>
        </div>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <YesNoPopup
        title='Are you sure that you want to delete this address?'
        open={deliveryInfoState.showDeletePopup}
        handleNo={() => updateDeliveryInfoState({ showDeletePopup: false })}
        handleYes={() => {
          handleDeleteDeliveryInfo();
          updateDeliveryInfoState({ showDeletePopup: false });
        }}
      />
    </React.Fragment>
  );
}

export default CreateUpdateDeliveryInfoPopup
