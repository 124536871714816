import React from "react";
import { makeStyles } from '@material-ui/core/styles/index';

import { Typography } from "@material-ui/core";
import { colors } from "../../palette";

const useStyles = makeStyles(() => ({
  container: {
    border: `1px solid ${colors.lightGray}`,
    borderRadius: 4,
    position: 'relative',
    padding: '15px 12px 12px',
  },
  title: {
    position: 'absolute',
    color: colors.fontLightGrey,
    backgroundColor: 'white',
    top: -14,
    left: 12,
    padding: 2,
    paddingLeft: 6,
    paddingRight: 6,
    fontSize: '1rem',
  },
}));

const OutlinedDiv = ({ children, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="body2">{label}</Typography>
      {children}
    </div>
  );
};
export default OutlinedDiv;
