import React, { useEffect, useReducer } from 'react';
import { uniqueId } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Container,
  Divider,
  InputAdornment,
  List,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { notifyError } from '../../services/notificationService';
import { getTechnologyOptions } from '../../apis/configurationApi';

import { getCadPartS3Key, uploadFileToS3 } from '../../services/s3Service';
import { is2DGenerationSupport } from '../../utils/fileUtils';
import { useCadPartFileUpload } from '../../hooks/useCadPartFileUpload';
import { convert2DImage } from '../../services/convert2DImageService';
import BlueDragDropMultipleParts from '../../components/BlueDragDropMultipleParts';
import FileUploadWithImageItem from '../../components/list-items/FileUploadWithImageItem';
import {
  ANGLE_MAPPING,
  COLOR_SCHEMA_MAPPING,
  VIEW_MAPPING,
  MIN_PRECISION,
  MAX_PRECISION,
  DEFAULT_PRECISION,
  DEFAULT_IMG_WIDTH,
  DEFAULT_IMG_HEIGHT,
  DEFAULT_COLOR_SCHEMA,
  DEFAULT_VIEW,
  DEFAULT_ANGLE,
  DEFAULT_MAX_FILE_SIZE
} from '../../constants/PartImageConstants';
import BlueButton from '../../components/buttons/BlueButton';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  buttonUpdate: {
    padding: '6px 16px',
    fontSize: '0.8rem',
  },
}));

export default function GeneratePartImage() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      files: [],
      precision: DEFAULT_PRECISION,
      imgWidth: DEFAULT_IMG_WIDTH,
      imgHeight: DEFAULT_IMG_HEIGHT,
      view: DEFAULT_VIEW,
      angle: DEFAULT_ANGLE,
      colorscheme: DEFAULT_COLOR_SCHEMA,
      technology: null,
      maxFileSize: DEFAULT_MAX_FILE_SIZE,
      listTechnologies: [],
      processing: false,
    }
  );

  const [
    cadPartListState,
    {
      addCadPart,
      updateCadPart,
      removeCadPart,
      update2DImageUrl,
      resetListPart,
    },
  ] = useCadPartFileUpload(dispatch);

  useEffect(() => {
    getTechnologyOptions()
      .then((data) => {
        updateLocalState({
          listTechnologies: data.options,
          technology: data.default,
        });
      })
      .catch(() => {
        notifyError('Failed get technology');
      });
  }, []);

  function handleCadFiles(files) {
    resetListPart();
    Promise.all(
      Array.from(files).map(async (file) => {
        const splitFilename = file.name.split('.');
        const fileExtension = splitFilename[splitFilename.length - 1];

        const id = uniqueId();
        addCadPart({
          id,
          uploadStatus: 'ongoing',
        });

        return uploadFileToS3(file, getCadPartS3Key(file))
          .then((data) => {
            const fileSize = parseInt(file.size / 1000, 10);
            const s3ObjectUrl = data.Location.split(' ').join('%20');
            const cadPart = {
              id,
              fileSize,
              link: s3ObjectUrl,
              imageLoading: false,
            };
            if (is2DGenerationSupport(fileExtension)) {
              cadPart.imageLoading = true;
            }
            updateCadPart(cadPart);
            if (is2DGenerationSupport(fileExtension)) {
              const parameters = {
                file_url: s3ObjectUrl,
                precision: localState.precision,
                img_width: localState.imgWidth,
                img_height: localState.imgHeight,
                view: localState.view,
                angle: localState.angle,
                colorscheme: localState.colorscheme,
                file_size_threshold: localState.maxFileSize,
              };
              convert2DImage(parameters)
                .then((data) => {
                  const twoDImageUrl = data['s3_file_url'];
                  update2DImageUrl(s3ObjectUrl, twoDImageUrl);
                })
                .catch((err) => {
                  updateCadPart({
                    id,
                    imageLoading: false,
                    error: true,
                  });
                  notifyError(err?.message, "Unexpected Error")
                });
            }
            return s3ObjectUrl;
          })
          .catch((err) => {
            alert(err);
          });
      })
    );
  }

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: '1rem',
            placeItems: 'baseline',
          }}
        >
          {localState.technology && (
            <TextField
              label='Technology'
              select
              required
              value={localState.technology}
              onChange={(evt) =>
                updateLocalState({ technology: evt.target.value })
              }
              variant='outlined'
              margin='dense'
              fullWidth={true}
            >
              {localState.listTechnologies.map((technology, index) => (
                <MenuItem key={index} value={technology}>
                  {technology}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            label='Image Width'
            value={localState.imgWidth}
            onChange={(evt) => {
              updateLocalState({ imgWidth: evt.target.value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            type='number'
            placeholder='eg: 1920'
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label='Image Height'
            value={localState.imgHeight}
            onChange={(evt) => {
              updateLocalState({ imgHeight: evt.target.value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            type='number'
            placeholder='eg: 1920'
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label='Precision'
            value={localState.precision}
            inputProps={{
              max: MAX_PRECISION,
              min: MIN_PRECISION,
              step: MIN_PRECISION,
            }}
            onChange={(evt) => {
              let value = evt.target.valueAsNumber;
              if (value > MAX_PRECISION) value = MAX_PRECISION;
              if (value < MIN_PRECISION && value !== 0) value = MIN_PRECISION;
              updateLocalState({ precision: value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            type='number'
            placeholder='eg: 0.5'
            required
            fullWidth
          />
          <TextField
            label='View'
            select
            value={localState.view}
            onChange={(evt) => {
              updateLocalState({ view: evt.target.value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            placeholder='eg: 1234'
            required
            fullWidth
          >
            {VIEW_MAPPING.map((view, index) => (
              <MenuItem key={index} value={view}>
                {view}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label='Angle'
            select
            value={localState.angle}
            onChange={(evt) => {
              updateLocalState({ angle: evt.target.value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            placeholder='eg: 1234'
            required
            fullWidth
          >
            {ANGLE_MAPPING.map((angle, index) => (
              <MenuItem key={index} value={angle}>
                {angle}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label='Color Schema'
            select
            value={localState.colorscheme}
            onChange={(evt) => {
              updateLocalState({ colorscheme: evt.target.value });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            placeholder='eg: 1234'
            required
            fullWidth
          >
            {COLOR_SCHEMA_MAPPING.map((color, index) => (
              <MenuItem key={index} value={color}>
                {color}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label='Max File Size'
            value={localState.maxFileSize}
            onChange={(evt) => {
              updateLocalState({ maxFileSize: evt.target.valueAsNumber });
            }}
            variant='outlined'
            margin='dense'
            style={{ marginRight: '1.25rem' }}
            type='number'
            placeholder='eg: 13'
            required
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">MB</InputAdornment>
              )
            }}
          />
          {cadPartListState.data.length > 0 && (
            <div style={{ marginTop: '0.5rem' }}>
              <BlueButton
                btnContent='Update'
                onBtnClick={() => handleCadFiles(localState.files)}
                className={classes.buttonUpdate}
              />
            </div>
          )}
        </div>
        <Divider style={{ margin: '1rem 0' }} />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            columnGap: '1rem',
          }}
        >
          <BlueDragDropMultipleParts
            showText={false}
            technology={localState.technology}
            supportedFileTypes={{
              fileTypes: ['step', 'stp', 'stl'],
              description: 'Please upload at least 1 file',
            }}
            handleFiles={(files) => {
              handleCadFiles(files);
              updateLocalState({ files });
            }}
          />
          <List style={{ flexWrap: 'wrap' }}>
            {cadPartListState.data.map((data, index) => (
              <FileUploadWithImageItem
                key={index}
                data={data}
                onDeleteClick={removeCadPart}
                showDownloadImage
              />
            ))}
          </List>
        </div>
      </div>
    </Container>
  );
}
