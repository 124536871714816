import React from 'react';

import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

import useDownloadAllFiles from '../../hooks/useDownloadAllFilesHook';

import { isEmptyValue } from '../../utils/commonUtils';


// ------------------------------------------------------------------------------------------------

function DownloadAllFilesButton(props) {
  const {
    folderName = 'Download',
    urlList = [],
    buttonText = '',
  } = props;

  const {
    loading,
    handleDownloadAll,
  } = useDownloadAllFiles(urlList, folderName);

  return !isEmptyValue(buttonText)
    ? (
      <Button
        onClick={handleDownloadAll}
        color="primary"
        startIcon={loading ? null : <GetAppIcon />}
        disabled={loading}
      >
        {loading && <CircularProgress size={15} style={{ marginRight: 8 }} />}
        {buttonText}
      </Button>
    )
    : (
      <Tooltip title="Download All">
        <IconButton
          size="small"
          color="primary"
          onClick={handleDownloadAll}
          disabled={loading}
        >
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    );
}

export default DownloadAllFilesButton;
