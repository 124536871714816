import React from 'react';

// Import material UI components
import { Container, Divider, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Import color palette
import { colors } from '../../palette';

// Import services and api
import { notifyError, notifySuccess } from '../../services/notificationService';
import { clearCachePPE } from '../../apis/ppeApi';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  clearPPECache: {
    width: '160px',
    background: colors.buttonColorBlueGradient,
    color: colors.fontWhite,
  },
}));

function PpeDataManagement() {
  const classes = useStyles();

  function handleClearCache() {
    clearCachePPE()
      .then(() => {
        notifySuccess('PPE cache cleared successfully!');
      })
      .catch((err) => {
        notifyError('Unable to clear PPE cache');
      });
  }

  return (
    <Container className={classes.container}>
      <div>
        <Typography variant='h6'>PPE Data Management</Typography>
        <Divider />
      </div>
      <Button className={classes.clearPPECache} onClick={handleClearCache}>
        Clear PPE Cache
      </Button>
    </Container>
  );
}

export default PpeDataManagement;
