import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles/index';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import { FtrH6, FtrLightText } from '../ftr-components/FtrTypography';

import ChatSvgIcon from '../../assets/icons/chat.svg';

import { getUserFirstName } from '../../utils/userUtils';
import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      borderRadius: 0,
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nextButton: {
    border: 'none',
    fontWeight: 500,
    textTransform: 'none',
    backgroundColor: colors.warningYellow,
    '&:hover': {
      backgroundColor: colors.orange,
    }
  },
  skipButton: {
    color: colors.bgGrey,
    textTransform: 'none',
    '&:hover': {
      background: colors.fontWhite,
      color: colors.fontBlackGrey,
    },
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const DefaultContent = () => {
  return (
    <>
      <FtrLightText>
        Explore the Factorem Platform –
      </FtrLightText>
      <FtrLightText>
        Where Innovation Meets Productivity.
      </FtrLightText>
      <FtrLightText>
        Effortless manufacturing, on your timeline
      </FtrLightText>
    </>
  )
}

function PartUploadTourPopup(props) {
  const {
    open,
    onClose = () => { },
    handleSkipTour = () => { },
    handleStartTour = () => { },
    newUser = false,
    customTitle,
    userName = '',
    content = <DefaultContent />,
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const firstName = getUserFirstName(userName);
  const title = newUser
    ? `Welcome to Factorem, ${firstName}`
    : `Welcome to a fresh new look, ${firstName}`;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      fullScreen={isMobile}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={ChatSvgIcon} alt='icon' />
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <FtrH6 style={{ marginBottom: '1.5rem' }}>
          {customTitle || title}
        </FtrH6>
        {content}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'center',
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
        }}
      >
        <Button
          className={classes.skipButton}
          variant='outlined'
          onClick={() => {
            handleSkipTour();
            onClose();
          }}
        >
          Skip tour
        </Button>
        <Button
          className={classes.nextButton}
          variant='outlined'
          onClick={() => {
            handleStartTour();
            onClose();
          }}>
          Show me around
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PartUploadTourPopup;
