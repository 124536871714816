import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { TextField } from '@material-ui/core';

import { FlexColumn } from '../layouts/FlexLayouts';
import { FtrBoldText, FtrErrorText } from './FtrTypography';

import { colors } from '../../palette';


// ------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  inputField: {
    width: '100%',
    background: colors.fontWhite,
    '& .MuiOutlinedInput-root': {
      color: colors.neutral070,
      borderRadius: 10,
    },
    '& .MuiOutlinedInput-root .Mui-disabled': {
      color: colors.neutral050,
      borderRadius: 10,
    },
  },
}));

function FtrField(props) {
  const {
    children,
  } = props;

  return (
    <FlexColumn style={{ gap: '0.3rem' }} {...props}>
      {children}
    </FlexColumn>
  );
}

export function FtrTextField(props) {
  const classes = useStyles();

  const {
    name,
    title,
    titleFontSize = '14',
    error,
  } = props;

  return (
    <FtrField {...props}>
      {title && (
        <FtrBoldText
          fontSize={titleFontSize}
          style={{
            color: colors.neutral070,
          }}
        >
          {title}
        </FtrBoldText>
      )}
      <TextField
        id={name}
        size='small'
        name={name}
        variant='outlined'
        className={classes.inputField}
        {...props}
      />
      {error && <FtrErrorText>{error}</FtrErrorText>}
    </FtrField>
  );
}

export default FtrField;
