import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FtrTypography } from '../ftr-components';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    cursor: 'pointer',
    width: 'fit-content',
    '&:hover': {
      '&:hover': {
        '& img': {
          filter: 'brightness(0.8)',
        },
      },
    },
  },
  text: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
  },
}));

function withMouseHoverHOC(WrappedComponent) {
  return function WrappedComponentWithMouseEnter(props) {
    const { textStyle, text = 'Mouse Enter', onClick = () => {} } = props;

    const [mouseEnter, setMouseEnter] = useState(false);
    const classes = useStyles();

    return (
      <div
        className={classes.container}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        onClick={onClick}
      >
        <WrappedComponent {...props} />
        {mouseEnter && (
          <FtrTypography style={textStyle} className={classes.text}>
            {text}
          </FtrTypography>
        )}
      </div>
    );
  };
}

export default withMouseHoverHOC;
