import React from 'react';
import { Avatar, useMediaQuery } from '@material-ui/core';

import { formatTitleDate } from '../../utils/dateTimeUtils';
import InfoIcon from '../icons/InfoIcon';
import { colors } from '../../palette';

function TitleDataDashboard(props) {
  const { profilePic, companyName, createdAt } = props;
  const isSmallScreen = useMediaQuery('(max-width:700px)');

  const dataLastUpdated = `Data Last Updated on | ${formatTitleDate(
    createdAt
  )}`;

  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.15)',
        padding: '0 5px',
        borderRadius: '4px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
          }}
        >
          <Avatar
            src={profilePic}
            style={{
              height: '2.5rem',
              width: '2.5rem',
              border: `2px solid ${colors.inputLabelGrey}`,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
            alt='Company Logo'
          />
          <span>|</span>
          <strong>{companyName}</strong>
        </div>
        {createdAt && (
          <React.Fragment>
            {isSmallScreen ? (
              <InfoIcon toolTipText={dataLastUpdated} />
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#12005d',
                    color: 'white',
                    borderRadius: '3px',
                    padding: '5px',
                  }}
                >
                  {dataLastUpdated}
                </span>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
      <span
        style={{
          borderTop: '1px solid rgba(0, 0, 0, 0.25)',
          display: 'flex',
          padding: '5px 0',
          color: '#515151',
          fontWeight: '600',
          fontSize: '18px',
          textTransform: 'capitalize',
        }}
      >
        Factorem Partner Dashboard
      </span>
    </div>
  );
}

export default TitleDataDashboard;
