// Import settings
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";

// Import actions
import { getItemDetails, sendNewQuote } from "../actions";

// Import material UI components
import {
  ButtonBase,
  Grid,
  Paper,
  Step,
  Stepper,
  StepConnector,
  StepLabel,
  Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles/index";
import {
  AttachFile as AttachFileIcon,
  BeenhereOutlined as BeenhereOutlinedIcon,
  DoneOutlineOutlined as DoneOutlineOutlinedIcon,
  HourglassEmptyOutlined as HourglassEmptyOutlinedIcon,
  LocalShippingOutlined as LocalShippingOutlinedIcon,
  MonetizationOnOutlined as MonetizationOnOutlinedIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { downloadS3File } from "../utils/fileUtils";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
    }
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)"
  }
});

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(4)
  },
  media: {
    height: 160
  },
  image: {
    width: 200,
    height: 200,
    border: "1px solid #d8d8d8"
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "auto",
    height: "auto"
  },
  property: {
    margin: "25px 0"
  },
  itemProperty: {
    marginBottom: "10px"
  },
  container: {
    display: "flex",
    justifyContent: "space-between"
  },
  statusContainer: {
    marginTop: "40px"
  },
  quoteInput: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SearchIcon />,
    2: <BeenhereOutlinedIcon />,
    3: <MonetizationOnOutlinedIcon />,
    4: <HourglassEmptyOutlinedIcon />,
    5: <DoneOutlineOutlinedIcon />,
    6: <LocalShippingOutlinedIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

export function ItemForCustomer(props) {
  const { match, onPageLoad, item } = props;
  const classes = useStyles();

  useEffect(() => {
    onPageLoad(match.params.itemID);
  }, [item.itemID]);

  function getSteps() {
    if (item != null && item.price != null) {
      return [
        "Processing",
        "Sourcing",
        "Quotes available",
        "Order in progress",
        "Order ready",
        "Delivery"
      ];
    }
    return [
      "Verifying",
      "Sourcing",
      "Quotes available",
      "Order in progress",
      "Order ready",
      "Delivery"
    ];
  }

  const steps = getSteps();

  return (
    <div className={classes.body}>
      <Paper elevation={1} variant="outlined" style={{ padding: "70px" }}>
        <div className={classes.container}>
          <Grid container fixed="true" spacing={2}>
            <Grid item style={{ marginRight: "30px" }}>
              <ButtonBase disabled className={classes.image}>
                {item.imageFile ? (
                  <img
                    className={classes.img}
                    alt="complex"
                    src={item.imageFile}
                  />
                ) : (
                  <img
                    className={classes.img}
                    alt="complex"
                    src="https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder"
                  />
                )}
              </ButtonBase>
            </Grid>
            <Grid item>
              {item.name ? (
                <Typography
                  style={{
                    fontWeight: "bold",
                    margin: 0,
                    fontSize: "20pt",
                    marginBottom: "12px"
                  }}
                >
                  {item.name}
                </Typography>
              ) : (
                <div />
              )}
              {item.description ? (
                <div style={{ marginBottom: "20px" }}>
                  <Typography gutterBottom variant="body2">
                    {item.description}
                  </Typography>
                </div>
              ) : (
                <div />
              )}
              <div style={{ display: "flex", marginTop: "15px" }}>
                <div style={{ width: "320px" }}>
                  {item.datePosted ? (
                    <div className={classes.itemProperty}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Date posted
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        {item.datePosted.substring(0, 10)}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                  {item.quantity ? (
                    <div className={classes.itemProperty}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Quantity
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        {item.quantity}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                  {item.expectedPrice ? (
                    <div className={classes.itemProperty}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Estimated budget
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        ${item.expectedPrice}
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.property}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Estimated budget
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        N.A.
                      </Typography>
                    </div>
                  )}
                  {item.deadline ? (
                    <div className={classes.itemProperty}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Deadline
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        {item.deadline.substring(0, 10)}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
                <div>
                  {item.tolerance ? (
                    <div className={classes.itemProperty}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Minimum Tolerance Required
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        {item.tolerance}mm
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                  {item.technology ? (
                    <div className={classes.itemProperty}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Technology
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        {item.technology}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                  {item.surfaceFinish ? (
                    <div className={classes.itemProperty}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Surface Finish
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        {item.surfaceFinish}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                  {item.material ? (
                    <div className={classes.itemProperty}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        style={{ fontWeight: "bold", margin: 0 }}
                      >
                        Material
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        {item.material}
                      </Typography>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <div>
                {item.cadFile ? (
                  <div className={classes.property}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{ fontWeight: "bold", margin: 0 }}
                    >
                      Design/CAD files
                    </Typography>
                    <div>
                      {item.cadFile.split(",").map(link => (
                        <div
                          key={link}
                          style={{ paddingTop: "10px", display: "flex" }}
                        >
                          <div>
                            <AttachFileIcon color="action" fontSize="small" />
                          </div>
                          <div>
                            <Link
                              onClick={(e) => {
                                e.stopPropagation();
                                downloadS3File(link);
                              }}
                            >
                              {link.split("/").reverse()[0]}
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.statusContainer}>
          <Stepper
            alternativeLabel
            activeStep={item.status - 1}
            connector={<ColorlibConnector />}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </Paper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    item: state.item.item
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    onPageLoad: itemID => dispatch(getItemDetails(itemID)),
    submitQuoteForm: newQuote => dispatch(sendNewQuote(newQuote, props))
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ItemForCustomer);
