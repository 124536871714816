import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import { Badge, Button } from '@material-ui/core';

import { toCamelCase } from '../../utils/stringUtils';

import { colors } from '../../palette';


// -----------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  buttonBase: {
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    width: 'fit-content',
    maxHeight: 27,
    padding: '6px 12px',
    textTransform: 'capitalize',
  },
  buttonBackground: {
    flex: '0 0 20%',
    textAlign: 'center',
    width: 'fit-content',
  },
  verifying: {
    background: colors.blue010,
    color: colors.blue050,
    '&:hover': {
      background: colors.blue010,
    }
  },
  quoteAvailable: {
    background: colors.mintGreen,
    color: colors.green040,
    '&:hover': {
      background: colors.mintGreen,
    }
  },
  inProgress: {
    background: colors.blue010,
    color: colors.blue050,
    '&:hover': {
      background: colors.blue010,
    }
  },
  orderReady: {
    background: colors.blue060,
    color: colors.blue010,
    '&:hover': {
      background: colors.blue060,
    }
  },
  shipping: {
    background: colors.green040,
    color: colors.neutral010,
    '&:hover': {
      background: colors.green040,
    }
  },
  delivered: {
    background: '#7E59E9',
    color: 'white',
    '&:hover': {
      background: '#7E59E9',
    }
  },
  processing: {
    background: colors.statusLightBlue,
    border: `solid 1px ${colors.statusLightBlue}`,
    color: colors.fontWhite,
  },
  sourcing: {
    background: '#FFE791',
    color: '#645A00',
    '&:hover': {
      background: '#FFE791',
    }
  },
  statusGreyButton: {
    background: colors.inputLabelGrey,
    border: `solid 1px ${colors.inputLabelGrey}`,
    color: colors.fontGrey,
  },
  greenBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: colors.badgeGreen,
      color: 'white',
      border: '1.5px solid white',
      zIndex: 0,
    },
  },
}));

function StatusButtonV2Presentational(props) {
  const classes = useStyles();

  const {
    status = 'status',
    badgeContent,
    onClick = () => { },
  } = props;

  const getClassName = (status) => {
    const key = toCamelCase(status);

    switch (key) {
      case 'verifying':
        return classes.verifying;
      case 'quotesAvailable':
      case 'viewQuotes':
        return classes.quoteAvailable;
      case 'inProgress':
      case 'orderInProgress':
        return classes.inProgress;
      case 'orderReady':
        return classes.orderReady;
      case 'shipping':
        return classes.shipping;
      case 'delivered':
        return classes.delivered;
      case 'processing':
        return classes.processing;
      case 'sourcing':
        return classes.sourcing;
      default:
        return classes.statusGreyButton;
    }
  }

  const renderButton = () => {
    const className = getClassName(status)
    return (
      <Button
        className={clsx(classes.buttonBase, className)}
        onClick={onClick}
        data-cy={`${toCamelCase(status)}StatusButton`}
      >
        {status}
      </Button>
    );
  }

  return (
    <Badge
      className={classes.greenBadge}
      badgeContent={badgeContent}
    >
      {renderButton()}
    </Badge>
  );
}

export default StatusButtonV2Presentational;
