// Import settings
import React from 'react';

// Import material UI components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import Row from '../Row';
import ShipmentTrackingIcon from '../icons/ShipmentTrackingIcon';

// Style the components
const useStyles = makeStyles(() => ({
  paper: {
    minWidth: '400px',
  },
}));

function ShipmentTrackingPopup(props) {
  const classes = useStyles();
  const { open, handleCancel, rowData } = props;

  return (
    <Dialog
      aria-labelledby='confirmation-dialog-title'
      open={open}
      classes={{ paper: classes.paper }}
      onClick={(e) => e.stopPropagation()}
      onClose={handleCancel}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <div>Shipment Information</div>
      </DialogTitle>
      <DialogContent dividers>
        <Row>
          <span>Shipment Provider:</span>
          <strong>{rowData?.shipmentProvider}</strong>
        </Row>
        <Row>
          <span>Shipment Tracking Number:</span>
          <Row>
            <strong>{rowData?.shipmentTrackingNumber || 'N.A.'}</strong>
            <ShipmentTrackingIcon
              shipmentInfo={rowData}
              iconStyle={{ fontSize: '18px' }}
            />
          </Row>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color='primary'>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShipmentTrackingPopup;
