import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Avatar,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles/index';

import { WhiteOnRedTooltip } from './CustomizedTooltips';
import DragDrop from './DragDrop';

import { getAllUsers } from '../actions';

import {
  getFileNameFromCadFile,
  getSurfaceOptionsByTech,
} from '../utils/itemUtils';

import { uploadFileToS3, uploadPublicFileToS3 } from '../services/s3Service';

import { colors } from '../palette';

import {
  TECHNOLOGY_OPTIONS,
  MATERIAL_OPTIONS,
  toleranceOptions,
  deliveryOptions,
} from '../constants/NewPartConstants';
import { CADPART_DIRNAME, REACT_APP_IMGPART_DIRNAME } from '../constants';

const NewHardwarePart = ({
  onClose,
  sendFormData,
  isDialog,
  savedPartDetails,
  createItemSuccess,
  role,
  myUserId,
  users,
  getAllUsers,
}) => {
  const [status, setItemStatus] = useState(1);
  const [name, setItemName] = useState('');
  const [qty, setItemQty] = useState(1);
  const [technology, setItemTechnology] = useState(TECHNOLOGY_OPTIONS[0]);
  const [otherTechnology, setOtherTechnology] = useState('');
  const [deliveryPref, setDeliveryPref] = useState(deliveryOptions[0].value);
  const [selectedDeadline, handleDeadlineChange] = useState(new Date());
  const [description, setItemDescription] = useState('');
  const [surfaceFinishOptions, setSurfaceFinishOptions] = useState(getSurfaceOptionsByTech(technology));
  const [surfaceFinish, setItemSurfaceFinish] = useState(surfaceFinishOptions[0]);
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState('');
  const [partApplication, setPartApplication] = useState('');
  const [tolerance, setItemTolerance] = useState(
    toleranceOptions[TECHNOLOGY_OPTIONS[0]]
  );
  const [expectedPrice, setItemExpectedPrice] = useState('');
  const [expectedUnitPrice, setItemExpectedUnitPrice] = useState('');
  const [material, setItemMaterial] = useState(
    MATERIAL_OPTIONS[TECHNOLOGY_OPTIONS[0]][0]
  );
  const [otherMaterial, setOtherMaterial] = useState('');
  const [cadPart, setCadPart] = useState([]);
  const [imgPart, setImgPart] = useState('');
  const [currentImg, setCurrentImg] = useState(
    imgPart !== ''
      ? imgPart
      : 'https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder'
  );
  const [imgUpload, setImgUpload] = useState(false);
  const [userID, setUserID] = useState(myUserId);
  const [dialog, setDialogOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [namePresent, setNamePresent] = useState(true);
  const [qtyPresent, setQtyPresent] = useState(true);

  const buyers = users.filter(
    (user) => user.role === 'buyer' || user.userID === myUserId
  );

  useEffect(() => {
    setSurfaceFinishOptions(getSurfaceOptionsByTech(technology));
  }, [technology]);

  useEffect(() => {
    if (createItemSuccess && formSubmitted) {
      setDialogOpen(true);
    }
  }, [createItemSuccess]);

  useEffect(() => {
    if (role === 'admin' || role === 'reviewer' || role === 'superadmin') {
      getAllUsers();
    }
    if (savedPartDetails) {
      setItemName(savedPartDetails.name);
      setItemExpectedUnitPrice(
        (
          (
            (savedPartDetails.priceBidded +
              (savedPartDetails.priceBidded * savedPartDetails.markupPercent) /
              100) *
            (1 + savedPartDetails.gst / 100) +
            parseFloat(savedPartDetails.deliveryFee)
          ).toFixed(2) / savedPartDetails.quantity
        ).toFixed(2)
      );
      setItemExpectedPrice(
        (
          (savedPartDetails.priceBidded +
            (savedPartDetails.priceBidded * savedPartDetails.markupPercent) /
            100) *
          (1 + savedPartDetails.gst / 100) +
          parseFloat(savedPartDetails.deliveryFee)
        ).toFixed(2)
      );
      savedPartDetails.partApplication &&
        setPartApplication(savedPartDetails.partApplication);
      setDeliveryPref(savedPartDetails.deliveryPreference);
      savedPartDetails.surfaceFinish &&
        setItemSurfaceFinish(savedPartDetails.surfaceFinish);
      savedPartDetails.tolerance &&
        setItemTolerance(savedPartDetails.tolerance);
      setItemQty(savedPartDetails.quantity);
      savedPartDetails.description &&
        setItemDescription(savedPartDetails.description);
      setItemTechnology('Others');
      setOtherTechnology(savedPartDetails.technology);
      setItemMaterial('Others');
      setOtherMaterial(savedPartDetails.material);
      // parse the url for the cad file and images and have them here as well
      if (savedPartDetails.imageFile) {
        setImgPart(savedPartDetails.imageFile);
        setCurrentImg(savedPartDetails.imageFile);
      }
      savedPartDetails.cadFile &&
        setCadPart(savedPartDetails.cadFile.split(','));
    }
  }, []);

  function handleImgPartChange(e) {
    const file = e.target.files[0];
    const s3Key = `${REACT_APP_IMGPART_DIRNAME}/${file.name}`;
    uploadPublicFileToS3(file, s3Key)
      .then(data => {
        setImgPart(data.Location.split(' ').join('%20'));
        setCurrentImg(data.Location);
        setImgUpload(true);
      })
      .catch(err => {
        console.error(err);
        setImgUpload(false);
        alert(err);
      });
  }

  function handleCadFilesChange(files) {
    let stringOfLinks = [...cadPart];
    Promise.all(
      Array.from(files).map((file) => {
        const splitFilename = file.name.split('.');
        const preFilename = splitFilename.slice(0, -1).join('.');
        const fileExtension = splitFilename[splitFilename.length - 1];
        const s3ObjectKey = `${CADPART_DIRNAME}/${preFilename}_${file.lastModified}.${fileExtension}`;
        return uploadFileToS3(file, s3ObjectKey)
          .then((data) => {
            stringOfLinks.push(data.Location.split(' ').join('%20'));
          })
          .catch((err) => {
            alert(err);
          });
      })
    ).then(() => {
      setCadPart(stringOfLinks);
    });
  }

  const handleCancel = () => {
    setItemStatus(1);
    setItemName('');
    setItemQty(1);
    setDeliveryPref(deliveryOptions[0].value);
    setItemTechnology(TECHNOLOGY_OPTIONS[0]);
    setOtherTechnology('');
    handleDeadlineChange(new Date());
    setItemDescription('');
    setItemSurfaceFinish('');
    setPartApplication('');
    setItemTolerance('');
    setItemMaterial('');
    setItemExpectedPrice('');
    setItemExpectedUnitPrice('');
    setCadPart([]);
    setImgPart('');
    setCurrentImg(
      'https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder'
    );
    setImgUpload(false);
    setUserID(myUserId);
    onClose();
  };

  const validateName = (name) => {
    if (name) {
      setNamePresent(true);
    }
  };

  const validateQty = (qty) => {
    if (qty) {
      setQtyPresent(true);
    } else {
      setQtyPresent(false);
    }
  };

  const handleClose = () => {
    const data = {
      name,
      status,
      qty,
      deliveryPref,
      technology: technology === 'Others' ? otherTechnology : technology,
      selectedDeadline,
      description,
      surfaceFinish:
        surfaceFinish === 'Others' ? otherSurfaceFinish : surfaceFinish,
      partApplication,
      tolerance,
      material: material === 'Others' ? otherMaterial : material,
      expectedPrice,
      cadPart,
      imgPart,
      userID,
    };

    if (name && qty) {
      sendFormData(data);
      setFormSubmitted(true);
    } else if (!name) {
      setNamePresent(false);
    }
  };

  const handleCreateNewPart = () => {
    setDialogOpen(false);
    handleCancel();
  };

  const classes = makeStyles(() => ({}));
  const techIsUnsure = technology === 'Others';
  const materialIsOthers = material === 'Others';
  const surfaceFinishIsOthers = surfaceFinish === 'Others';

  const contents = (
    <Fragment>
      <div style={{ fontSize: 13, marginBottom: 16 }}>
        Please upload design files for your custom hardware part so that
        suppliers will be able to provide a more accurate quote. You may upload
        multiple files below:
      </div>
      <DragDrop
        text='Please upload both 2D files (.pdf) and CAD files (.step, .stp) here'
        handleFiles={handleCadFilesChange}
      />
      <List>
        {cadPart.map((link, index) => {
          const fileName = getFileNameFromCadFile(link);
          return (
            <ListItem
              key={index}
              style={{
                backgroundColor: colors.bgLightGrey,
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <AttachFileIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                style={{
                  width: 130,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                }}
                primary={fileName}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => {
                    const cadPartFiles = [...cadPart];
                    cadPartFiles.splice(index, 1);
                    setCadPart(cadPartFiles);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <WhiteOnRedTooltip
        disableHoverListener
        title='Name cannot be blank'
        arrow
        placement='left'
        open={!namePresent}
      >
        <TextField
          variant='outlined'
          autoFocus
          margin='dense'
          id='name'
          label='Name of part'
          type='text'
          fullWidth
          onChange={(evt) => {
            setItemName(evt.target.value);
            validateName(evt.target.value);
          }}
          value={name}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </WhiteOnRedTooltip>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <FormControl
          variant='outlined'
          margin='dense'
          style={{ marginRight: '20px' }}
        >
          <InputLabel htmlFor='expectedUnitPrice'>
            Estimated budget (per unit)
          </InputLabel>
          <Tooltip
            title='Your estimated budget helps suppliers determine whether they should quote for you. If their prices are too high, they will refrain from quoting. You may leave this blank if you wish to.'
            arrow
            placement='bottom'
          >
            <OutlinedInput
              margin='dense'
              type='number'
              min={0}
              id='expectedUnitPrice'
              startAdornment={
                <InputAdornment position='start'>$</InputAdornment>
              }
              onChange={(evt) => {
                setItemExpectedUnitPrice(evt.target.value);
                setItemExpectedPrice(evt.target.value * qty);
              }}
              value={expectedUnitPrice}
              labelWidth={200}
            />
          </Tooltip>
        </FormControl>
        <div
          style={{
            marginRight: '20px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          X
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '-4px',
          }}
        >
          <WhiteOnRedTooltip
            disableHoverListener
            title='Quantity cannot be blank'
            arrow
            placement='left'
            open={!qtyPresent}
          >
            <TextField
              variant='outlined'
              style={{ width: '100px', marginRight: '20px' }}
              margin='dense'
              id='quantity'
              label='Quantity'
              type='number'
              fullWidth
              onChange={(evt) => {
                setItemQty(evt.target.value);
                setItemExpectedPrice(evt.target.value * expectedUnitPrice);
                validateQty(evt.target.value);
              }}
              value={qty}
            />
          </WhiteOnRedTooltip>
        </div>
        <div
          style={{
            marginRight: '20px',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
          }}
        >
          =
        </div>
        <div
          style={{
            marginLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '2px',
          }}
        >
          <b>Total estimated budget:</b>
          <p style={{ marginLeft: '4px' }}> $ </p>
          {expectedPrice}
        </div>
      </div>
      <TextField
        variant='outlined'
        style={{ width: '100%' }}
        margin='dense'
        id='partApplication'
        label='Application of part'
        placeholder='E.g. Vacuum Chamber, Container for Liquid, etc.'
        type='text'
        fullWidth
        onChange={(evt) => {
          setPartApplication(evt.target.value);
        }}
        value={partApplication}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl style={{ width: '100%', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <TextField
            variant='outlined'
            style={{ width: '100%' }}
            id='technology'
            select
            margin='dense'
            label='Technology'
            value={technology}
            onChange={(evt) => {
              const newTech = evt.target.value;
              setItemTechnology(newTech);
              if (
                newTech === 'Others' ||
                newTech === 'Not Sure' ||
                newTech === 'Design Service'
              ) {
                setItemMaterial('Others');
                setItemTolerance('');
              } else {
                setItemMaterial(MATERIAL_OPTIONS[newTech][0]);
                setItemTolerance(toleranceOptions[newTech]);
              }
            }}
            fullWidth={true}
          >
            {TECHNOLOGY_OPTIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <div style={{ width: '100%' }}></div>
          {techIsUnsure && (
            <TextField
              variant='outlined'
              style={{ width: '100%' }}
              margin='dense'
              id='otherTechnology'
              label='Custom Technology'
              type='text'
              fullWidth
              onChange={(evt) => {
                setOtherTechnology(evt.target.value);
              }}
              value={otherTechnology}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </div>
        <div>
          {!techIsUnsure && (
            <TextField
              variant='outlined'
              style={{ width: '100%' }}
              id='material'
              select
              margin='dense'
              label='Material'
              value={material}
              onChange={(evt) => {
                setItemMaterial(evt.target.value);
              }}
              fullWidth={true}
            >
              {!techIsUnsure &&
                MATERIAL_OPTIONS[technology].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          )}
          {techIsUnsure && (
            <TextField
              variant='outlined'
              style={{ width: '100%' }}
              margin='dense'
              select
              id='otherMaterial'
              label='Material'
              type='text'
              fullWidth
              onChange={(evt) => {
                setItemMaterial(evt.target.value);
              }}
              value={material}
            >
              {techIsUnsure &&
                MATERIAL_OPTIONS['Others'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          )}
          <div style={{ width: '100%' }}></div>
          {materialIsOthers && (
            <TextField
              variant='outlined'
              style={{ width: '100%' }}
              margin='dense'
              id='otherMaterial'
              label='Other Material'
              type='text'
              fullWidth
              onChange={(evt) => {
                setOtherMaterial(evt.target.value);
              }}
              value={otherMaterial}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </div>
        <div style={{ width: '100%' }}>
          <FormControl
            variant='outlined'
            margin='dense'
            style={{ width: '100%' }}
          >
            <InputLabel
              //shrink={true}
              htmlFor='tolerance'
              style={{ width: '215px' }}
            >
              Tightest Tolerance Required
            </InputLabel>
            <OutlinedInput
              margin='dense'
              type='number'
              id='tolerance'
              endAdornment={
                <InputAdornment position='end'>mm</InputAdornment>
              }
              onChange={(evt) => {
                setItemTolerance(evt.target.value);
              }}
              value={tolerance}
              labelWidth={210}
            />
          </FormControl>
        </div>
        <div style={{ fontSize: 13, marginBottom: 10, opacity: '80%' }}>
          *Please input the tightest tolerances for your design. The suppliers
          will check against this figure to see if it meets their capabilities.
        </div>
        <TextField
          variant='outlined'
          select
          style={{ width: '100%' }}
          margin='dense'
          id='surfaceFinish'
          label='Surface Finish (if any)'
          placeholder='Anodizing, etc.'
          type='text'
          fullWidth
          onChange={(evt) => {
            setItemSurfaceFinish(evt.target.value);
          }}
          value={surfaceFinish}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {surfaceFinishOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        {surfaceFinishIsOthers && (
          <TextField
            variant='outlined'
            style={{ width: '100%' }}
            margin='dense'
            id='otherSurfaceFinish'
            label='Custom Surface Finish'
            type='text'
            fullWidth
            onChange={(evt) => {
              setOtherSurfaceFinish(evt.target.value);
            }}
            value={otherSurfaceFinish}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      </FormControl>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          variant='outlined'
          style={{ width: '100%' }}
          margin='dense'
          id='delivery'
          select
          label='Delivery Preference'
          value={deliveryPref}
          onChange={(evt) => {
            setDeliveryPref(evt.target.value);
          }}
          fullWidth={true}
        >
          {deliveryOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <div style={{ fontSize: 13, marginBottom: 10, opacity: '80%' }}>
          *Self-collection is not available due to the current COVID-19
          situation
        </div>
        <div style={{ display: 'flex', width: '100%' }}>
          <DatePicker
            style={{
              width: '100%',
            }}
            label='Expected Delivery Date'
            value={selectedDeadline}
            margin='dense'
            onChange={handleDeadlineChange}
            animateYearScrolling
            inputVariant='outlined'
            variant='inline'
            autoOk
            minDate={new Date()}
            clearable
            clearLabel="No Preference"
          />
        </div>
      </div>
      <TextField
        style={{ width: '100%' }}
        multiline
        rows={6}
        margin='dense'
        id='description'
        label='Item description or Remarks'
        placeholder='Do include any additional requests or remarks that we should take note of. Please also indicate if you wish for quotations for varying quantities, materials or technologies.'
        type='text'
        variant='outlined'
        fullWidth
        onChange={(evt) => {
          setItemDescription(evt.target.value);
        }}
        value={description}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {role === 'admin' || role === 'reviewer' || role === 'superadmin' ? (
        <TextField
          variant='outlined'
          style={{ width: '100%' }}
          id='userID'
          select
          margin='dense'
          label='Assign part to'
          value={userID}
          defaultValue={myUserId}
          onChange={(evt) => setUserID(evt.target.value)}
        >
          {buyers.map((buyer) => (
            <MenuItem key={buyer.userID} value={buyer.userID}>
              {buyer.name}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <div />
      )}
      <div style={{ fontSize: 13, margin: '8px 0 4px' }}>
        Please upload an image of your hardware part below if you have any:
      </div>
      <div style={{ display: 'flex', margin: '10px 0', flexWrap: 'wrap' }}>
        <div style={{ marginRight: 15 }}>
          <ButtonBase
            disabled
            style={{ width: 150, height: 150, border: '1px solid #d8d8d8' }}
          >
            <img
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
              }}
              alt='complex'
              src={currentImg}
            />
          </ButtonBase>
        </div>
        <div style={{ flexBasis: '100%', height: '8px' }}></div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div>
              <input
                accept='image/*'
                className={classes.input}
                id='img-part-file'
                type='file'
                onChange={handleImgPartChange}
                onClick={(event) => event.target.value = null}
                style={{
                  display: 'none',
                }}
              />
              <label htmlFor='img-part-file'>
                <Button
                  variant='outlined'
                  component='span'
                  color='primary'
                  className={classes.button}
                  size='small'
                  style={{
                    textTransform: 'none',
                    flexBasis: '100&',
                  }}
                >
                  Upload Image
                </Button>
              </label>
            </div>
            {imgUpload ? (
              <div style={{ marginLeft: '10px' }}>
                <Button
                  variant='outlined'
                  component='span'
                  color='primary'
                  className={classes.button}
                  size='small'
                  style={{
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    setCurrentImg(
                      'https://factorem-s3-bucket.s3-ap-southeast-1.amazonaws.com/Factorem/ImagePlaceholder'
                    );
                    setImgPart('');
                  }}
                >
                  Remove image
                </Button>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={dialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby='confirmation-dialog-title'
      >
        <DialogTitle id='confirmation-dialog-title'>
          {'Part successfully added!'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='confirmation-dialog-title'>
            Our engineers are working on your requirements and you'll hear from
            us soon.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button component={Link} to='/manage-parts' color='primary'>
            View my parts
          </Button>
          <Button onClick={handleCreateNewPart} color='primary'>
            Add more parts
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );

  if (isDialog) {
    return (
      <Fragment>
        <DialogTitle id='form-dialog-title'>New Hardware Part</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please key in the relevant fields. Name, Status and Quantity are
            required.
          </DialogContentText>
          {contents}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCancel();
            }}
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClose();
              handleCancel();
            }}
            color='primary'
          >
            Create
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <div>
        <h1 style={{ display: 'inline-block' }}>Add a part</h1>
      </div>
      {contents}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={{ width: '100%', marginTop: '8px' }}
          onClick={handleClose}
          variant='contained'
          component='span'
          color='secondary'
        >
          Upload my part
        </Button>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    createItemSuccess: state.items.createItemSuccess,
    users: state.users.users,
    role: state.auth.user.role,
    myUserId: state.auth.user.userID,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    getAllUsers: () => dispatch(getAllUsers()),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(NewHardwarePart);
