export const getShippingCostOptions = ({ deliveryDateModes, deliveryModePrices }) => {
  if (!deliveryModePrices) {
    return {};
  }

  return Object.entries(deliveryDateModes).reduce((acc, [key, value]) => {
    const obj = acc[key];
    if (obj) {
      obj.estimatedDeliveryDate = value;
    }

    return acc;
  }, deliveryModePrices);
}
