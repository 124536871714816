import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

import { REACT_APP_ENV, PROD_ENV, STAGING_ENV } from './constants';

let tracePropagationTargets = [/^\//, process.env.REACT_APP_BACKEND_SERVICE];

const DEV_ENV = REACT_APP_ENV !== PROD_ENV || REACT_APP_ENV !== STAGING_ENV;

if (DEV_ENV) {
  tracePropagationTargets = [...tracePropagationTargets, 'localhost'];
}

const history = createBrowserHistory();

// we can adjust this once it's stabilized
const tracesSampleRate = REACT_APP_ENV === PROD_ENV ? 1.0 : 1.0;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: false, // set to true when needed to debug sentry
  release: process.env.REACT_APP_SENTRY_RELEASE,
  // This is to identify where's the error comes from and from which releases
  environment: REACT_APP_ENV,

  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture percentage
  // of transactions for tracing.
  tracesSampleRate,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
