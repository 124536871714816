// Import settings
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

// Import material UI components
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery, 
  useTheme 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

// Import actions
import { getAllItems, getAllMyOrders } from "../actions";

import { ITEM_STATUS_MAPPING } from "../constants";
import { TECHNOLOGY_OPTION_TYPE } from "../constants/NewPartConstants";
import { filterReadyForQuoteItems } from "../utils/itemUtils";
import ItemGridContainer from "../components/grids/ItemGridContainer";
import TechFiltersBar from "../components/filters/TechFiltersBar";
import { EXCLUDE_USER_EMAILS } from '../constants';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 5px 0'
    }
  },
  browseHeader: {},
  browseOption: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2)
  },
  searchBar: {
    justifyItems: "flex-end",
    marginLeft: "auto"
  },
  filterBar: {
    marginRight: theme.spacing(2),
    minWidth: 120
  },
}));

export function BrowseSeparateDisplay(props) {
  const classes = useStyles();

  const { items, myorders, onPageLoad, user } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const inputLabel = React.useRef(null);

  const [listOfItems, setListOfItems] = useState([]);
  const [sortedItems, setSortedItems] = React.useState([]);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [sortByValues, setSortByValues] = React.useState("");
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [filterStatus, setFilterStatus] = React.useState({
    checkedCNC: false,
    checked3d: false,
    checkedSheetMetal: false,
    checkedDesignService: false,
    checkedOthers: false
  });
  const [searchKeyword, setSearchKeyword] = React.useState("");
  

  useEffect(() => {
    onPageLoad();
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const dateSet = "2020-07-28T00:00:00.000Z";

  useEffect(() => {
    // To be removed in future - blocking of supplier for testing
    if (EXCLUDE_USER_EMAILS.includes(user.email)) {
      setListOfItems(
        _.sortBy(
          items.filter(
            x =>
              [TECHNOLOGY_OPTION_TYPE.NOT_SURE, TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING].includes(x.technology) &&
              [ITEM_STATUS_MAPPING.SOURCING, ITEM_STATUS_MAPPING.QUOTES_AVAILABLE].includes(x.status) &&
              x.datePosted > dateSet
          ),
          item => item.datePosted
        ).reverse()
      );
    } else {
      const validItems = filterReadyForQuoteItems(items);
      setListOfItems(validItems);

      // All sort/search/filter settings are reset whenever the items changes
      setSortByValues("");
      setSearchKeyword("");
      setFilterStatus({
        checkedCNC: false,
        checked3d: false,
        checkedSheetMetal: false,
        checkedDesignService: false,
        checkedOthers: false
      });
    }
  }, [items]);

  useEffect(() => {
    setSortedItems(listOfItems);
  }, [listOfItems]);

  useEffect(() => {
    setFilteredItems(sortedItems);
  }, [sortedItems]);

  const handleChange = event => {
    setSortByValues(event.target.value);
    let sortedItems = listOfItems;
    if (event.target.value === "ascendingName") {
      sortedItems = _.sortBy(listOfItems, object => object.referenceName);
    }
    if (event.target.value === "descendingName") {
      sortedItems = _.sortBy(
        listOfItems,
        object => object.referenceName
      ).reverse();
    }
    if (event.target.value === "newest") {
      sortedItems = _.sortBy(
        listOfItems,
        object => object.verifiedDate
      ).reverse();
    }
    if (event.target.value === "oldest") {
      sortedItems = _.sortBy(listOfItems, object => object.verifiedDate);
    }

    //Sorting always reset search and filters
    setSearchKeyword("");
    setFilterStatus({
      checkedCNC: false,
      checked3d: false,
      checkedSheetMetal: false,
      checkedDesignService: false,
      checkedOthers: false
    });
    setSortedItems(sortedItems);
  };

  /**
   * Searching always reset the filter checklist
   */
  const handleSearch = event => {
    let keyword = event.target.value.toLowerCase();
    let filteredItems = sortedItems;

    if (event.target.value !== "") {
      filteredItems = _.filter(sortedItems, object => {
        const index = object.referenceName.toLowerCase().indexOf(keyword);
        return index !== -1;
      });
    }
    setSearchKeyword(keyword);
    setFilteredItems(filteredItems);
    setFilterStatus({
      checkedCNC: false,
      checked3d: false,
      checkedSheetMetal: false,
      checkedDesignService: false,
      checkedOthers: false
    });
  };

  const handleFilterChange = event => {
    let filteredItems = sortedItems;

    //filter to according to search keyword first
    filteredItems = _.filter(filteredItems, object => {
      const index = object.referenceName.toLowerCase().indexOf(searchKeyword);
      return index !== -1;
    });

    let technology = "";
    if (event.target.name === "checkedCNC") {
      technology = "CNC Machining";
      setFilterStatus({
        checkedCNC: event.target.checked,
        checked3d: false,
        checkedSheetMetal: false,
        checkedDesignService: false,
        checkedOthers: false
      });
    }
    if (event.target.name === "checked3d") {
      technology = "3D Printing";
      setFilterStatus({
        checkedCNC: false,
        checked3d: event.target.checked,
        checkedSheetMetal: false,
        checkedDesignService: false,
        checkedOthers: false
      });
    }
    if (event.target.name === "checkedSheetMetal") {
      technology = "Sheet Metal Fabrication";
      setFilterStatus({
        checkedCNC: false,
        checked3d: false,
        checkedSheetMetal: event.target.checked,
        checkedDesignService: false,
        checkedOthers: false
      });
    }
    if (event.target.name === "checkedDesignService") {
      technology = "Design Service";
      setFilterStatus({
        checkedCNC: false,
        checked3d: false,
        checkedSheetMetal: false,
        checkedDesignService: event.target.checked,
        checkedOthers: false
      });
    }
    if (event.target.name === "checkedOthers") {
      technology = "Others";
      setFilterStatus({
        checkedCNC: false,
        checked3d: false,
        checkedSheetMetal: false,
        checkedDesignService: false,
        checkedOthers: event.target.checked
      });
    }
    //Filter only if a technology checkbox is ticked
    if (event.target.checked === true) {
      filteredItems = _.filter(filteredItems, object => {
        let result =
          object.technology === technology || object.technology === "Not Sure";
        return result === event.target.checked;
      });
    }
    setFilteredItems(filteredItems);
  };

  return (
    <div>
      <div className={classes.browseOption}>
        <FormControl variant="outlined" className={classes.formControl} fullWidth={isMobile}>
          <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
            Sort by
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={sortByValues}
            onChange={handleChange}
            labelWidth={labelWidth}
          >
            <MenuItem value="newest">Newest first</MenuItem>
            <MenuItem value="oldest">Oldest first</MenuItem>
            <MenuItem value="ascendingName">Name (A-Z)</MenuItem>
            <MenuItem value="descendingName">Name (Z-A)</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          fullWidth={isMobile}
          className={classes.searchBar}
          input="text"
          id="searchBar"
          placeholder="Search"
          onChange={handleSearch}
          value={searchKeyword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </div>
      {!EXCLUDE_USER_EMAILS.includes(user.email) && (
        <div className={classes.browseOption}>
          <TechFiltersBar filterStatus={filterStatus} handleFilterChange={handleFilterChange} />
        </div>
      )}
      <ItemGridContainer
        items={filteredItems}
        supplierOrders={myorders}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    items: state.items.items,
    myorders: state.myorders.myorders,
    user: state.auth.user,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => {
      dispatch(getAllItems());
      dispatch(getAllMyOrders());
    }
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(BrowseSeparateDisplay);
