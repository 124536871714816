import React, { useEffect, useRef } from 'react';

import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Delete as DeleteIcon } from "@material-ui/icons";

import { makeStyles } from '@material-ui/core/styles/index';
import {
  CMM_PRICE_STR,
  COLOR_STR,
  MATERIAL_COLOR_STR,
  MATERIAL_STR,
  OTHER_MATERIAL_STR,
  OTHER_SURFACE_FINISH_STR,
  QUANTITY_STR,
  SURFACE_FINISH_STR,
  THREE_D_INFILL,
  THREE_D_LAYER_THICKNESS,
  THREE_D_TECHNOLOGY,
  UNIT_QUOTE_STR,
  REMARKS_STR
} from '../../constants/quotationConstants';
import OtherMaterialField from './form-fields/OtherMaterialField';
import OtherSurfaceFinishingField from './form-fields/OtherSurfaceFinishingField';
import { TECHNOLOGY_OPTION_TYPE, THREE_D_P_FDM_TECH } from '../../constants/NewPartConstants';
import ThreeDInfillFieldSelect from './form-fields/ThreeDInfillFieldSelect';
import ThreeDLayerThicknessFieldSelect from './form-fields/ThreeDLayerThicknessFieldSelect';
import { isCustomMaterial, isCustomSurfaceFinish } from '../../utils/inputUtils';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import { is3DPTechnology } from '../../utils/itemUtils';
import { useItemInputConfig } from '../../hooks/useItemInputConfig';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import { isEmpty } from 'lodash';
import SurfaceFinishingField from '../inputs/SurfaceFinishingField';
import ColorInputField from '../inputs/ColorInputField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import { getCurrencySymbol } from '../../utils/currencyUtils';
import { QC_FORMAL_CMM_REPORT } from '../../constants/projectConstants';

const useStyles = makeStyles(() => ({
  quoteWrapper: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    display: "flex",
    justifyContent: "space-between",
  },
  quantityWrapper: {
    width: "7%",
    minWidth: "80px",
    marginRight: "4px",
  },
  multiplySymbolWrapper: {
    margin: "0 4px 0 4px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  unitPriceWrapper: {
    width: "14%",
    minWidth: "100px",
    marginRight: "4px",
  },
  equalSymbolWrapper: {
    margin: "0 4px 0 2px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  totalPriceWrapper: {
    width: "12%",
    minWidth: "120px",
    marginRight: "4px",
  },
  surfaceFinishWrapper: {
    width: "24%",
    minWidth: "180px",
    marginRight: "4px",
  },
  formControl: {
    minWidth: 150,
    width: "100%",
  },
  cmmReportWrapper: {
    width: "13%",
    minWidth: "100px",
    marginLeft: "4px",
  },
}));

function RfqQuotationRowInput(props) {
  const classes = useStyles();

  const unitPriceRef = useRef();
  const quantityRef = useRef();
  const cmmPriceRef = useRef();

  const {
    inputField,
    technology,
    currency,
    showDeleteButton,
    onInputFieldsChange,
    quantityError,
    unitQuoteError,
    otherMaterialError,
    otherSurfaceFinishError,
    remarksError,
    onDeleteButtonClick,
    qcReports,
    cmmPriceError,
    expanded
  } = props;

  const [
    {
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptionsForSupplier,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
    }
  ] = useItemInputConfig({
    setMaterial: (value) => onInputFieldsChange({ target: { value } }, inputField.id, MATERIAL_STR),
    setSurfaceFinish: (value) => onInputFieldsChange({ target: { value } }, inputField.id, SURFACE_FINISH_STR),
    setMaterialColor: (value) => onInputFieldsChange({ target: { value } }, inputField.id, MATERIAL_COLOR_STR),
    setColor: (value) => onInputFieldsChange({ target: { value } }, inputField.id, COLOR_STR),
  });

  useEffect(() => {
    if (isEmpty(technology) || isEmpty(inputField)) {
      return;
    }
    const {
      threeDTechnology,
      material,
      materialColor,
      surfaceFinish,
      color,
    } = inputField;
    loadSelectColorSurfaces({ technology });
    loadMaterialCategoryOptions({ technology }, false);
    if (is3DPTechnology(technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions({
        technology,
        threeDTechnology,
      }, false);
    }
    const params = {
      technology,
      threeDTechnology,
      material,
    }
    loadSurfaceFinishOptionsForSupplier(params, false);
    if (materialColor) {
      loadMaterialColorOptions(params, false);
    }
    if (color) {
      loadSurfaceFinishColorOptions({
        technology,
        surfaceFinish,
      }, false);
    }
  }, [technology, inputField]);

  useEffect(() => {
    if (!isEmpty(quantityError)) {
      quantityRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    if (!isEmpty(unitQuoteError)) {
      unitPriceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
    if (!isEmpty(cmmPriceError) && cmmPriceRef.current) {
      cmmPriceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }
  }, [quantityError, unitQuoteError, cmmPriceError]);

  const renderOther3DInputs = () => {
    return (
      <div
        style={{ display: 'flex' }}
      >
        {inputField.threeDTechnology === THREE_D_P_FDM_TECH && (
          <div style={{ width: 'auto', marginLeft: 2 }}>
            <ThreeDInfillFieldSelect
              value={inputField.threeDInfill}
              onChange={(event) => onInputFieldsChange(event, inputField.id, THREE_D_INFILL)}
            />
          </div>
        )}
        {inputField.threeDTechnology === THREE_D_P_FDM_TECH && (
          <div style={{ width: 'auto', minWidth: 150, marginLeft: 2 }}>
            <ThreeDLayerThicknessFieldSelect
              value={inputField.threeDLayerThickness}
              onChange={event => onInputFieldsChange(event, inputField.id, THREE_D_LAYER_THICKNESS)}
            />
          </div>
        )}
      </div>
    );
  }

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions)}
        value={inputField.color}
        colorInputLabel={"Color"}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => onInputFieldsChange({ target: { value: selectedColor } }, inputField.id, COLOR_STR)}
        bootstrapStyle={false}
        quotationField
      />
    );
  }

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions)}
        value={inputField.materialColor}
        colorInputLabel="Material Color"
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) => onInputFieldsChange({ target: { value: selectedColor } }, inputField.id, MATERIAL_COLOR_STR)}
        bootstrapStyle={false}
        quotationField
      />
    );
  }

  const renderRemarksInput = () => {
    return (
      <div
        style={{
          width: "100%",
          marginTop: 4,
        }}
      >
        <TextField
          required
          label="Remarks / Clarifications"
          placeholder="Please enter any remarks (type NIL for no remarks)"
          multiline={true}
          variant="outlined"
          minRows={1}
          maxRows={2}
          fullWidth
          name={REMARKS_STR}
          error={remarksError}
          helperText={remarksError}
          onChange={(event) => onInputFieldsChange(event, inputField.id, REMARKS_STR)}
          value={inputField.remarks}
          inputProps={{
            maxLength: 8000,
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.quoteWrapper}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div className={classes.quantityWrapper}>
            <TextField
              id="quantity"
              ref={quantityRef}
              variant="outlined"
              required
              margin="dense"
              label="Quantity"
              name={QUANTITY_STR}
              type="number"
              value={inputField.quantity}
              InputLabelProps={{ shrink: true }}
              error={quantityError}
              helperText={quantityError}
              onChange={(event) => onInputFieldsChange(event, inputField.id, QUANTITY_STR)}
              disabled={inputField.id === 0 ? true : false}
              style={{ width: "100%" }}
            />
          </div>
          <div className={classes.multiplySymbolWrapper}>x</div>
          <div className={classes.unitPriceWrapper}>
            <Tooltip
              title="Include all charges (fabrication, shipping, etc.) except for GST"
              arrow
            >
              <TextField
                style={{ width: "100%" }}
                id="unit-price"
                ref={unitPriceRef}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div>{getCurrencySymbol(currency)}</div>
                    </InputAdornment>
                  ),
                }}
                required
                margin="dense"
                label="Unit Price"
                name={UNIT_QUOTE_STR}
                type="number"
                value={inputField.unitQuote}
                placeholder="00.00"
                error={unitQuoteError}
                helperText={unitQuoteError}
                onWheel={(e) => e.target.blur()}
                onChange={(event) => onInputFieldsChange(event, inputField.id, UNIT_QUOTE_STR)}
              />
            </Tooltip>
          </div>
          <div className={classes.equalSymbolWrapper}>=</div>
          <div className={classes.totalPriceWrapper}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div>{getCurrencySymbol(currency)}</div>
                  </InputAdornment>
                ),
              }}
              disabled
              fullWidth
              margin="dense"
              label="Total (excl. GST)"
              name="quote"
              type="number"
              value={inputField.quote}
              placeholder="00.00"
            />
          </div>
          <div style={{ width: 'auto' }}>
            <ThreeDPrintingTechnologyInputField
              visible={is3DPTechnology(technology)}
              value={inputField.threeDTechnology}
              onChange={(newValue) => {
                const event = { target: { value: newValue } };
                onInputFieldsChange(event, inputField.id, THREE_D_TECHNOLOGY);
                const params = {
                  technology,
                  threeDTechnology: newValue,
                }
                threeDTechnologyHasChanged(params);
              }}
              threeDTechnologyOptions={threeDTechnologyOptions}
              bootstrapStyle={false}
            />
          </div>
          <div style={{ width: 'auto', minWidth: 150 }}>
            <MaterialCategoriesInputField
              visible={!is3DPTechnology(technology)}
              value={inputField.material}
              onSelect={(value) => {
                onInputFieldsChange({ target: { value } }, inputField.id, MATERIAL_STR)
                const {
                  threeDTechnology,
                } = inputField;
                const params = {
                  technology,
                  threeDTechnology,
                  material: value,
                }
                materialHasChanged(params);
              }}
              materialCategoryOptions={materialCategoryOptions}
              bootstrapStyle={false}
            />
            <ThreeDPrintingMaterialField
              visible={is3DPTechnology(technology)}
              value={inputField.material}
              onSelect={(_material) => {
                onInputFieldsChange({ target: { value: _material } }, inputField.id, MATERIAL_STR)
                const {
                  threeDTechnology,
                } = inputField;
                const params = {
                  technology,
                  threeDTechnology,
                  material: _material,
                }
                materialHasChanged(params);
              }}
              threeDMaterialOptions={threeDMaterialOptions}
              defaultThreeDMaterial={defaultThreeDMaterial}
              bootstrapStyle={false}
            />
          </div>
          {isCustomMaterial(inputField.material) && (
            <div style={{ width: 'auto', minWidth: 150, marginLeft: 4 }}>
              <OtherMaterialField
                value={inputField.otherMaterial}
                error={otherMaterialError}
                helperText={otherMaterialError}
                onChange={(event) => onInputFieldsChange(event, inputField.id, OTHER_MATERIAL_STR)}
              />
            </div>
          )}
          {renderMaterialColorInputField()}
          <div style={{ width: 'auto', minWidth: 150, marginLeft: 4 }}>
            <SurfaceFinishingField
              visible={!isEmpty(surfaceFinishOptions) || isCustomSurfaceFinish(inputField.surfaceFinish)}
              value={inputField.surfaceFinish}
              onSelect={(newValue) => {
                onInputFieldsChange({ target: { value: newValue } }, inputField.id, SURFACE_FINISH_STR);
                const {
                  material,
                } = inputField;
                const params = {
                  technology,
                  material,
                  surfaceFinish: newValue,
                }
                surfaceFinishHasChanged(params);
              }}
              surfaceFinishOptions={surfaceFinishOptions}
              selectColorSurfaces={selectColorSurfaces}
              bootstrapStyle={false}
            />
          </div>
          {isCustomSurfaceFinish(inputField.surfaceFinish) && (
            <div style={{ width: 'auto', minWidth: 220, marginLeft: 2 }}>
              <OtherSurfaceFinishingField
                value={inputField.otherSurfaceFinish}
                error={otherSurfaceFinishError}
                helperText={otherSurfaceFinishError}
                onChange={(event) => onInputFieldsChange(event, inputField.id, OTHER_SURFACE_FINISH_STR)}
              />
            </div>
          )}
          {renderSurfaceFinishColor()}
          {technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING && renderOther3DInputs()}
          {qcReports && qcReports.main === QC_FORMAL_CMM_REPORT && (
            <div className={classes.cmmReportWrapper}>
              <Tooltip
                title='Enter CMM report price. Insert "0" if no additional charge for CMM report'
                arrow
              >
                <TextField
                  style={{ width: "100%" }}
                  id="cmm-report"
                  ref={cmmPriceRef}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div>{getCurrencySymbol(currency)}</div>
                      </InputAdornment>
                    ),
                  }}
                  required
                  margin="dense"
                  label="CMM Price"
                  name={CMM_PRICE_STR}
                  type="number"
                  value={inputField.cmmPrice}
                  placeholder="00.00"
                  error={cmmPriceError}
                  helperText={cmmPriceError}
                  onChange={(event) => onInputFieldsChange(event, inputField.id, CMM_PRICE_STR)}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div>
          {showDeleteButton && (
            <IconButton
              aria-label="delete"
              onClick={onDeleteButtonClick}
              disabled={inputField.id === 0}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </div>
      {expanded && renderRemarksInput()}
    </div>
  );
}

export default RfqQuotationRowInput;
