import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { Publish as PublishIcon } from '@material-ui/icons';
import { isEqual } from 'lodash';

import { colors } from '../palette';
import { ALL_FILES_TYPES, DEFAULT_SUPPORT_FILES } from '../constants/NewPartConstants';

const useStyles = makeStyles(() => ({
  supportFilesText: {
    fontWeight: 'bold',
    color: colors.blue050,
  },
}));

function BlueDragDropMultipleParts(props) {
  const classes = useStyles();

  const {
    handleFiles,
    showText = true,
    supportedFileTypes,
  } = props;

  const [dragging, setDragging] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [supportFilesText, setSupportFilesText] = useState(String(DEFAULT_SUPPORT_FILES.supportFileTypes));
  const [twoDFilesText, setTwoDFilesText] = useState(DEFAULT_SUPPORT_FILES.descriptionText);

  useEffect(() => {
    if (supportedFileTypes) {
      const { fileTypes, description } = supportedFileTypes;
      const text = isEqual(fileTypes, ALL_FILES_TYPES)
        ? String(fileTypes)
        : fileTypes.map(o => `.${o}`).join(', ');
      setSupportFilesText(text);
      setTwoDFilesText(description);
    }
  }, [supportedFileTypes]);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    setIsEnter(false);
    handleFiles(e.dataTransfer.files);
  };

  return (
    <div>
      <h4 style={{ margin: 0, marginBottom: showText ? 0 : '0.85rem'}}>Upload your parts</h4>
      {showText && (
        <p
          style={{
            fontWeight: '100',
            fontSize: '10pt',
            marginTop: 6,
            marginBottom: 6,
          }}
        >
          If you do not have a 3D CAD file, please select&nbsp;
          <span
            style={{
              fontWeight: '600',
            }}
          >
            Custom Fabrication Technology
          </span>
          &nbsp;under the Technology Option to upload other file types and proceed to next page
        </p>
      )}
      <input
        id='cad-part-file'
        type='file'
        accept={supportFilesText}
        multiple
        onChange={(evt) => handleFiles(evt.target.files)}
        onClick={(event) => event.target.value = null}
        style={{
          display: 'none',
        }}
      />
      <label
        htmlFor='cad-part-file'
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div
          data-cy='upload-multi-cad-files'
          style={{
            borderRadius: '5px',
            border: `dashed ${colors.blue060} 1px`,
            backgroundColor: colors.dragdropBg,
            height: '285px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            opacity: isEnter ? 0.8 : 1,
            width: '100%',
          }}
        >
          <PublishIcon style={{ margin: '1.5rem', fontSize: 43 }} />
          {dragging ? (
            'Release to upload files'
          ) : (
            <div
              style={{
                color: colors.fontGrey,
                fontSize: 16,
                lineHeight: '21px',
                paddingRight: '1rem',
              }}
            >
              <div>Drop your files here.</div>
              <div style={{ display: 'flex' }}>
                <div>Or</div>
                <div
                  style={{
                    marginLeft: 4,
                    color: colors.blue060,
                    fontWeight: 600,
                    textDecoration: 'underline',
                  }}
                >
                  Browse
                </div>
              </div>
              <ul style={{ margin: 0, paddingLeft: 19 }}>
                <li>
                  <div
                    style={{ fontSize: '13px', color: colors.fontLightGrey }}
                  >
                    Supports:{' '}
                    <span className={classes.supportFilesText}>
                      {supportFilesText}
                    </span>
                  </div>
                </li>
                <li>
                  <div
                    style={{ fontSize: '13px', color: colors.fontLightGrey }}
                  >
                    {twoDFilesText}
                  </div>
                </li>
                <li>
                  <div
                    style={{ fontSize: '13px', color: colors.fontLightGrey }}
                  >
                    All files uploads are secure and confidential
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </label>
    </div>
  );
}

export default BlueDragDropMultipleParts;
