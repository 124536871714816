import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";

import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Input,
  FormHelperText,
  TextField,
  InputAdornment,
  IconButton,
  ListSubheader,
} from "@material-ui/core";

import {
  Search as SearchIcon,
  Close as CloseIcon,
  HighlightOff as HighlightOffIcon,
} from "@material-ui/icons";

import { colors } from '../../palette';
import { useEffect } from 'react';
import { first, isEmpty, isEqual } from 'lodash';
import { useRef } from 'react';
import clsx from 'clsx';
import { isEmptyValue } from '../../utils/commonUtils';
import { usePrevious } from '../../hooks/usePrevious';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(12),
    paddingTop: theme.spacing(5)
  },
  container: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    gap: '0.5rem',
    width: '100%',
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    backgroundColor: colors.menuItemSelected,
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 200,
    }
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  menuItem: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: colors.menuItemHover,
    },
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: colors.menuItemSelected,
      '&:hover': {
        backgroundColor: colors.menuItemSelected,
      },
    },
  },
  itemSelected: {
    backgroundColor: colors.menuItemSelected,
  },
  chipDeleteIcon: {
    color: colors.blue060,
    '&:hover': {
      color: colors.blue060,
    },
  },
  chipDeletable: {
    '&:focus': {
      backgroundColor: colors.menuItemSelected,
    },
  },
  closeButton: {
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    '&:hover': {
      backgroundColor: colors.expansionBackgroundColor,
      color: colors.blue050,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null,
};

const DEFAULT_ITEM_LIST = [
  {
    "key": "3d printing",
    "name": "3D Printing",
    "value": [
      {
        "key": 404,
        "text": "Supplier4 <Supplier4@mail.com>",
      },
    ],
  },
  {
    "key": "cnc machine",
    "name": "CNC Machine",
    "value": [
      {
        "text": "Supplier5 <Supplier5@mail.com>",
        "key": 405,
      },
    ],
  },
  {
    "key": "others",
    "name": "Others",
    "value": [
      {
        "key": 396,
        "text": "Factorem Instant Quote <instantquote@factorem.co>",
      },
      {
        "key": 395,
        "text": "Factorem Rocket Quote <rocketquote@factorem.co>",
      },
    ],
  },
];

function MultiSelectChipDropdownWithCategory(props) {
  const classes = useStyles();

  const inputRef = useRef();

  const {
    id = 'multi-select-chip-dropdown',
    itemList = DEFAULT_ITEM_LIST,
    value = [],
    selectedText = '',
    label = 'Select',
    onSelect = () => { },
    error,
    errorMessage,
    searchable = false,
    multiple = true,
    disabled = false,
  } = props;

  const [displayedItems, setDisplayedItems] = useState(itemList);
  const [flatItems, setFlatItems] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [selectedItems, setSelectedItems] = useState(value || []);

  const valuePrev = usePrevious(value);

  useEffect(() => {
    if (!isEqual(value, valuePrev)) {
      setSelectedItems(value)
    }
  }, [value]);

  useEffect(() => {
    const newDisplayedItems = searchable && !isEmpty(filterText)
      ? itemList.map(category => {
        const filteredItems = category.value.filter(item => item.text.toLowerCase().includes(filterText.toLowerCase()));
        return {
          ...category,
          value: filteredItems,
        };
      }).filter(category => !isEmpty(category.value))
      : itemList;
    setDisplayedItems(newDisplayedItems);
    setFlatItems(itemList.flatMap(category => category.value));
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [itemList, filterText]);

  const handleChange = (key, text) => {
    if (typeof onSelect === 'function') {
      const _selectedItems = selectedItems.includes(key)
        ? selectedItems.filter(item => item !== key)
        : multiple
          ? [...selectedItems, key]
          : [key];
      setSelectedItems(_selectedItems);
      onSelect(_selectedItems, text);
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChipDelete = (key) => {
    const newSelectedItems = selectedItems.filter(item => item !== key);
    if (typeof onSelect === 'function') {
      setSelectedItems(newSelectedItems);
      onSelect(newSelectedItems, selectedText);
    }
  }

  const handlePrepareDelete = (key) => {
    if (disabled) {
      return;
    }
    return () => {
      handleChipDelete(key);
    }
  }

  const renderChipSelectedItem = (selectedKeys) => {
    return (
      <div className={classes.chips}>
        {isEmptyValue(selectedText) && selectedKeys?.map((key) => {
          const item = flatItems.find(i => i.key === key)
          const label = item
            ? item.text
            : '';
          if (isEmptyValue(label)) {
            return null;
          }
          return (
            <Chip
              classes={{
                deleteIcon: classes.chipDeleteIcon,
                deletable: classes.chipDeletable,
              }}
              className={classes.chip}
              key={key}
              label={label}
              onMouseDown={(event) => event.stopPropagation()}
              onDelete={() => {
                if (disabled) {
                  return;
                }
                handleChipDelete(key);
              }}
              deleteIcon={<HighlightOffIcon />}
            />
          )
        })}
        {!isEmptyValue(selectedText) && (
          <Chip
            classes={{
              deleteIcon: classes.chipDeleteIcon,
              deletable: classes.chipDeletable,
            }}
            className={classes.chip}
            label={selectedText}
            disabled={disabled}
            onMouseDown={(event) => event.stopPropagation()}
            deleteIcon={<HighlightOffIcon />}
            onDelete={handlePrepareDelete(first(selectedKeys))}
          />
        )}
      </div>
    );
  }

  const renderMenuItem = ({ key, text }) => {
    return (
      <MenuItem
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
        className={clsx(classes.menuItem, selectedItems.includes(key) ? classes.itemSelected : null)}
        key={key}
        value={key}
        onClick={() => {
          handleChange(key, text);
        }}
      >
        {text}
      </MenuItem>
    );
  }

  const renderSearchInput = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 9999,
          backgroundColor: 'white',
        }}
        key={`search-input-${id}`}
      >
        <IconButton
          className={classes.closeButton}
          aria-label='delete'
          size='small'
          onClick={(event) => {
            event.stopPropagation();
            setFilterText('');
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          <CloseIcon style={{ fontSize: '15pt' }} />
        </IconButton>
        <TextField
          id={`search-input-${id}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onFocus={(event) => {
            event.stopPropagation();
          }}
          onSelect={(event) => {
            event.stopPropagation();
          }}
          onKeyUp={(event) => {
            event.stopPropagation();
          }}
          inputRef={inputRef}
          value={filterText}
          onChange={(event) => {
            setFilterText(event.target.value);
            event.stopPropagation();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          autoFocus
        />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl} error={error}>
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          labelId={id}
          id={id}
          data-cy={id}
          variant="outlined"
          multiple={multiple}
          value={selectedItems}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selectedKeys) => renderChipSelectedItem(selectedKeys)}
          MenuProps={MenuProps}
          onKeyDown={(event) => {
            event.preventDefault();
            if (event.key === 'Enter') {
              return;
            }
            setFilterText(`${filterText}${event.key}`);
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
          disabled={disabled}
        >
          {searchable && renderSearchInput()}
          {displayedItems.map(({ key: catKey, name, value }) => {
            return (
              <div key={catKey}>
                <ListSubheader>{name}</ListSubheader>
                {value.map(({ key, text }) => renderMenuItem({ key, text }))}
              </div>
            );
          })}
        </Select>
        {error && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}

export default MultiSelectChipDropdownWithCategory;
