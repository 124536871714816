import React from 'react';

import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import { HighlightOff } from '@material-ui/icons';
import { colors } from '../../palette';

const useStyles = (style) => makeStyles(() => ({
  chip: {
    ...style,
  },
}));

const getChipStyle = (type, variant) => {
  switch (type) {
    case 'info':
      return {
        borderColor: colors.blue070,
        color: variant === 'outlined' ? colors.blue070 : 'white',
        backgroundColor: variant === 'outlined' ? 'white' : colors.blue070,
      }
    case 'success':
      return {
        borderColor: 'green',
        color: variant === 'outlined' ? 'green' : 'white',
        backgroundColor: variant === 'outlined' ? 'white' : 'green',
      }
    case 'error':
    case 'fail':
    case 'failed':
      return {
        borderColor: 'red',
        color: variant === 'outlined' ? 'red' : 'white',
        backgroundColor: variant === 'outlined' ? 'white' : 'red',
      }
    case 'stop':
      return {
        borderColor: 'orange',
        color: variant === 'outlined' ? 'orange' : 'white',
        backgroundColor: variant === 'outlined' ? 'white' : 'orange',
      }
    case 'not_start':
    case 'disabled':
      return {
        borderColor: 'grey',
        color: variant === 'outlined' ? 'grey' : 'white',
        backgroundColor: variant === 'outlined' ? 'white' : 'grey',
      }
    default:
      return {}
  }
}

function FtrChip(props) {
  const {
    label = '',
    style = {},
    showDeleteButton = false,
    onDelete,
    variant = 'outlined',
    type = 'info',
    tooltip = '',
  } = props;

  const classes = useStyles(style);

  const chipStyle = getChipStyle(type, variant);

  return (
    <Tooltip title={tooltip}>
      <Chip
        className={classes.chip}
        label={label}
        onMouseDown={(event) => event.stopPropagation()}
        onDelete={onDelete}
        deleteIcon={showDeleteButton ? <HighlightOff /> : null}
        variant={variant}
        style={{
          ...chipStyle,
        }}
      />
    </Tooltip>
  );
}

export default FtrChip;
