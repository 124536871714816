import React from 'react';
import { Box } from '@material-ui/core';

import { colors } from '../../palette';

function ConfirmJobTag() {
  return (
    <Box
      style={{
        padding: `4px 24px`,
        margin: 4,
        marginTop: 8,
        marginLeft: 12,
        border: `2px solid ${colors.greenTagColor}`,
        borderRadius: 5,
        color: colors.greenTagColor,
        fontWeight: 'bold',
      }}
    >
      CONFIRMED JOB
    </Box>
  );
}

export default ConfirmJobTag;
