import React, { useState } from 'react';
import { startCase } from 'lodash';

import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@material-ui/icons';
import {
  Avatar,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import { colors } from '../palette';
import DataGridLinkCell from './tables/cells/DataGridLinkCell';

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
}));

/**
 *
 * @param {Object} props
 * @param {Number} props.userID
 * @param {String} props.name
 * @param {Array} props.values
 * @param {Object} props.selectedRadioButtons
 * @param {() => void} props.setSelectedRadioButtons
 */

function DifferentValueCompanyAndUser(props) {
  const {
    userID,
    name,
    values,
    selectedRadioButtons,
    setSelectedRadioButtons,
  } = props;

  const classes = useStyles();
  const [show, setShow] = useState(false);

  if (values.length === 0) return null;

  const renderProfilePic = (profilePic) => {
    return (
      <Avatar
        src={profilePic}
        id='radio-profilePic'
        style={{
          height: '3rem',
          width: '3rem',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      />
    );
  }

  const renderLabel = (value, property) => {
    const listPropertyUrl = ['quotationTermsUrl'];

    if (property === 'profilePic') {
      return renderProfilePic(value);
    } else if (listPropertyUrl.includes(property) && value) {
      return <DataGridLinkCell url={value} />;
    } else if (value === null) {
      return 'N.A.'
    } 

    return value
  }

  return (
    <div key={userID} style={{ marginTop: '1rem' }}>
      <div className={classes.title} onClick={() => setShow(!show)}>
        <span>
          {name} ({userID})
        </span>
        {show ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </div>
      {show && (
        <FormControl
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '1rem',
          }}
        >
          {selectedRadioButtons[userID] &&
            values.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  <FormLabel
                    id={`radio-${[value.property]}`}
                    style={{ marginTop: '1rem' }}
                  >
                    {startCase([value.property])}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby={`radio-${[value.property]}`}
                    defaultValue={value.user}
                    name='radio-property-group'
                    onChange={(event) => {
                      const eValue = event.target.value;
                      setSelectedRadioButtons((prev) => ({
                        ...prev,
                        [userID]: {
                          ...prev[userID],
                          [value.property]:
                            eValue === ''
                              ? null
                              : !isNaN(+eValue)
                              ? +eValue
                              : eValue,
                        },
                      }));
                    }}
                  >
                    <FormControlLabel
                      checked={
                        selectedRadioButtons[userID][value.property] ===
                        value.company
                      }
                      value={value.company}
                      control={<Radio />}
                      label={renderLabel(value.company, value.property)}
                    />
                    <FormControlLabel
                      checked={
                        selectedRadioButtons[userID][value.property] ===
                        value.user
                      }
                      value={value.user}
                      control={<Radio />}
                      label={renderLabel(value.user, value.property)}
                    />
                  </RadioGroup>
                </React.Fragment>
              );
            })}
        </FormControl>
      )}
    </div>
  );
}

export default DifferentValueCompanyAndUser;
