import React from 'react';

import { Box } from '@material-ui/core';

import { colors } from '../../palette';


// ------------------------------------------------------------------

function GrayBox(props) {
  return (
    <Box
      style={{
        backgroundColor: colors.neutral020,
        borderRadius: '10px',
        padding: '0.5rem 1rem',
        border: `0.8px solid ${colors.neutral030}`,
        boxSizing: 'border-box',
        ...(props.style || {}),
      }}
    >
      {props.children}
    </Box>
  );
}

export default GrayBox;
