import { sendLeaveQuoteFeedback } from "../apis/userFeedbackApi"

import { notifySuccess } from "../services/notificationService";

export const sendLeaveFeedback = (data) => (dispatch, getState) => {
  const formDataAvailable = getState().item.formDataAvailable;
  const costEstimationInfo = getState().costEstimationInfo;
  const body = {
    ...data,
    items: formDataAvailable,
    costEstimationInfo,
  }
  sendLeaveQuoteFeedback(body).then(() => {
    notifySuccess(`Thank you for your feedback!`);
  });
}
