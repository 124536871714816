import { useQuery } from 'react-query';

import { getActionTrackingApi } from '../apis/actionTrackingApi';

import { isEmptyValue } from '../utils/commonUtils';


// -------------------------------------------------------------------------------------------------

const useActionTracking = (userID, actionTrackingKey) => {
  const {
    data,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['getActionTrackings', userID, actionTrackingKey],
    () => {
      if (isEmptyValue(userID) || isEmptyValue(actionTrackingKey)) {
        return null;
      }

      return getActionTrackingApi({
        userID,
        actionTrackingKey,
      });
    },
  );

  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};

export default useActionTracking;
