import { colors } from "../palette";

export const WATCHING_JOB_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const watchingStatusInfo = {
  color: {
    [WATCHING_JOB_STATUS.ACTIVE]: colors.successGreen,
    [WATCHING_JOB_STATUS.INACTIVE]: 'brown',
  },
  text: {
    [WATCHING_JOB_STATUS.ACTIVE]: 'Actively watching',
    [WATCHING_JOB_STATUS.INACTIVE]: 'Stopped watching',
  },
};