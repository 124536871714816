import Decimal from 'decimal.js';
import { COUNTRY_NAMES } from '../constants/countryConstants';
import {
  OVERSEAS_CUSTOMER_MARGIN,
  LOCAL_CUSTOMER_MARGIN,
} from '../constants/marginConstants';

import { truncateDecimals, isNumber } from './numberUtils';
import { isEmptyValue } from './commonUtils';

/**
 * Calculates the supplier price based on a given target customer price and country.
 * It applies a margin depending on whether the customer is local (Singapore) or overseas.
 * If the customer is from Singapore, the local margin is applied; for all other countries, the overseas margin is applied.
 * The function uses Decimal.js for precision in calculations and truncates the result to avoid unnecessary decimals.
 *
 * @param {number|string} targetPrice - The target price provided by the customer.
 * @param {string|null} [country=null] - The country of the customer. Defaults to null.
 * @returns {Decimal} The calculated supplier price after applying the appropriate margin.
 */
export const getMarginalizedPrice = (
  price,
  country = null
) => {
  if (!isNumber(price) || isEmptyValue(country)) return 0.0

  const isOverseasCustomer =
    !isEmptyValue(country) && country !== COUNTRY_NAMES.SINGAPORE;
  const margin = isOverseasCustomer
    ? OVERSEAS_CUSTOMER_MARGIN
    : LOCAL_CUSTOMER_MARGIN;

  const marginalizedPrice = truncateDecimals(new Decimal(price).times(1 - margin));
  return marginalizedPrice;
};
