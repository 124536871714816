import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logoutUser } from '../actions';

function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();

  dispatch(logoutUser());
  history.push('/login');

  return '';
}

export default Logout;
