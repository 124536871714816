/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';

import UploadPartsStepper from '../../components/labels/UploadPartsStepper';

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputBase,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';

import {
  Cancel,
  CheckCircle
} from '@material-ui/icons';

import SolidBlueButton from '../../components/buttons/SolidBlueButton';
import BlueDragDropMultipleParts from '../../components/BlueDragDropMultipleParts';
import FileUploadWithImageItem from '../list-items/FileUploadWithImageItem';
import ItalicNote from '../texts/italicNote';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import TechnologyInputField from '../inputs/TechnologyInputField';
import BootstrapInput from '../inputs/BootstrapInput';
import ColorInputField from '../inputs/ColorInputField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import SurfaceFinishingField from '../inputs/SurfaceFinishingField';
import PpePriceLoadingBackdrop from '../backdrops/PpePriceLoadingBackdrop';
import ThreeDInfillSelectField from '../inputs/ThreeDInfillSelectField';

import { useCadPartFileUpload } from '../../hooks/useCadPartFileUpload';
import { useItemInputConfig } from '../../hooks/useItemInputConfig';

import { getSupportedFileTypesWithCache } from '../../apis/configurationApi';

import { reset2DImagesMapping } from '../../actions/twoDImages';

import { getItemNameFromCadFile, is3DPTechnology } from '../../utils/itemUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { getPpePriceForItem } from '../../utils/ppeUtils';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../utils/inputUtils';

import { notifyError } from '../../services/notificationService';

import { uploadProjectFilesSteps } from '../../actions';

import { colors } from '../../palette';

import { TWO_D_IMAGE_URLS_KEY } from '../../constants';
import { UNIT_TYPES } from '../../constants/unitConstants';
import { ppe3dpInfillOptions, ppe3dpLayerThicknessOptions } from '../../constants/PPEConstants';
import {
  TECHNOLOGY_OPTION_TYPE,
  MAX_UPLOAD_FILES,
  THREE_D_P_FDM_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  ALL_FILES_TYPES,
  PART_APPLICATION_TECHS,
  TOLERANCE_OPTIONS,
  STANDARD_TOLERANCE_OPTIONS,
  THREE_D_TOLERANCE_OPTIONS,
  MAX_UPLOAD_FILES_ERROR_MESSAGE,
} from '../../constants/NewPartConstants';
import { convertMetricToImperial } from '../../utils/userUtils';

const useStyles = makeStyles(() => ({
  colorButton: {
    padding: '5px 14px',
    width: '100%',
    justifyContent: 'left',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: colors.fontGrey,
    marginBottom: '13px',
    textTransform: 'none',
    fontSize: '16px',
  },
  dropBox: {
    marginBottom: 12,
  },
  formComponent: {
    padding: '1rem 2rem',
  },
  inputField: {
    padding: '5px 14px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    marginBottom: '13px',
    width: '100%',
  },
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  optionalBox: {
    marginTop: 15,
  },
  buttonAreaContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 0',
    borderTop: `1px solid ${colors.bgLightGrey}`,
  },
  pageButtonComponent: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectField: {
    width: '100%',
  },
  checkboxFileTypes: {
    '& > .MuiFormControlLabel-label': {
      fontWeight: '100',
      fontSize: '10pt',
      paddingRight: '10px',
    }
  }
}));

/**
 * @deprecated
 * @param {*} param0 
 * @returns 
 */
const MultiplePartUploadForm = ({ myUserId, myUnitType, sendFormData, changeTitle }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [status, setItemStatus] = useState(1);
  const [qty, setItemQty] = useState(1);
  const [technology, setTechnology] = useState(null);
  const [material, setMaterial] = useState(null);
  const [threeDTechnology, setThreeDTechnology] = useState(null);
  const [surfaceFinish, setSurfaceFinish] = useState(null);
  const [materialColor, setMaterialColor] = useState(null);
  const [color, setColor] = useState('');

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
      showPartApplication,
    },
    {
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      loadShowPartApplication,
      setSurfaceFinishColorOptions,
    }
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const [otherTechnology, setOtherTechnology] = useState('');
  const [otherMaterial, setOtherMaterial] = useState('');
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState('');

  const [
    cadPartListState,
    {
      removeCadPart,
      uploadCadFile,
    },
  ] = useCadPartFileUpload(dispatch);
  const [partApplication, setPartApplication] = useState('');
  const [userID, setUserID] = useState(myUserId);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const [nextButtonEnable, setNextButtonEnable] = useState(true);
  const [nameOfInvalidFiles, setNameOfInvalidFiles] = useState([]);
  const [numOfUploadedFiles, setNumOfUploadedFiles] = useState(0);
  const [numOfUploadingFiles, setNumOfUploadingFiles] = useState(0);
  const [threeDInfill, setThreeDInfill] = useState('');
  const [threeDLayerThickness, setThreeDLayerThickness] = useState('');
  const [supportedFileTypes, setSupportedFileTypes] = useState({
    fileTypes: [],
    description: "",
    allowPdf: false,
    oldFileTypes: [],
    oldDescription: '',
  })

  const is2DImageGeneratorRunning = cadPartListState.data
    ? cadPartListState.data.some((item) => item.imageLoading === true)
    : false;

  const techIs3DPrinting = technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING;
  const surfaceFinishIsPainting = surfaceFinish === 'Painting';
  const surfaceFinishIsCoating = surfaceFinish === 'Powder Coating';
  /**
   * If technology is 3D Printing and surface finish is painting
   * or powder coating, color input label will take on the label
   * of the surface finish
   */
  let colorInputLabel = surfaceFinishIsPainting
    ? 'Paint Color'
    : surfaceFinishIsCoating
      ? 'Powder Coat Color'
      : 'Color';

  useEffect(() => {
    dispatch(reset2DImagesMapping());
    loadTechnologyOptions();
    return () => {
      // Component Will Unmount
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(cadPartListState.data)) {
      const numOfUploaded = cadPartListState.data.reduce((acc, item) => {
        return item.uploadStatus === 'success' ? acc + 1 : acc;
      }, 0);
      setNumOfUploadedFiles(numOfUploaded);
      const numOfUploading = cadPartListState.data.reduce((acc, item) => {
        return item.uploadStatus === 'ongoing' ? acc + 1 : acc;
      }, 0);
      setNumOfUploadingFiles(numOfUploading);
      setNextButtonEnable(cadPartListState.filesUploadStatus === 'success');
    }
  }, [cadPartListState]);

  useEffect(() => {
    if (surfaceFinishIsPainting || surfaceFinishIsCoating || techIs3DPrinting) {
      setColor(color || 'Does not matter');
    } else {
      setColor('');
    }
  }, [technology, surfaceFinish]);

  useEffect(() => {
    const getApiSupportedFileTypes = async () => {
      const { supportFileTypes, description } = await getSupportedFileTypesWithCache({ technology });
      setSupportedFileTypes(prev => ({ ...prev, fileTypes: supportFileTypes, description }))
      if (isEqual(supportFileTypes, ALL_FILES_TYPES)) {
        if (typeof changeTitle === "function") {
          changeTitle('Upload Design Files');
        }
        dispatch(uploadProjectFilesSteps([
          "Upload Designs",
          "Configure part information",
          "Order submitted",
        ]));
      } else {
        if (typeof changeTitle === "function") {
          changeTitle('Upload 3D Models');
        }
        dispatch(uploadProjectFilesSteps([
          "Upload 3D files",
          "Configure part information and upload 2D files",
          "Order submitted",
        ]));
      }
    }
    getApiSupportedFileTypes()
  }, [technology]);

  useEffect(() => {
    if (isEmpty(technology)) {
      return;
    }
    const params = {
      technology,
    }
    if (!is3DPTechnology(technology)) {
      loadMaterialCategoryOptions(params);
    } else {
      load3DTechnologyOptions();
    }
    loadShowPartApplication(params)
    const listHidePBrainTechs = [TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING, TECHNOLOGY_OPTION_TYPE.CUSTOM_FABRICATION_TECHNOLOGY]
    // hide PBrain after selected one of the listHidePBrainTechs
    if (listHidePBrainTechs.includes(technology) && window.PBrain) {
      window.PBrain.config({ showhide: 'hide' })
    }
  }, [technology]);

  useEffect(() => {
    if (isEmpty(threeDTechnology)) {
      return;
    }
    const params = {
      technology,
      threeDTechnology,
    }
    loadThreeDMaterialOptions(params);
    if (threeDTechnology === THREE_D_P_FDM_TECH) {
      setThreeDInfill(threeDPrintingInfillDefault);
      setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
    } else {
      setThreeDInfill('');
      setThreeDLayerThickness('');
    }
  }, [threeDTechnology]);

  useEffect(() => {
    if (isEmpty(material) || isEmpty(technology)) {
      return;
    }
    const params = {
      technology,
      threeDTechnology,
      material,
    }
    loadSurfaceFinishOptions(params);
    loadMaterialColorOptions(params);
  }, [material, technology, threeDTechnology]);

  useEffect(() => {
    if (isEmpty(surfaceFinish)) {
      setSurfaceFinishColorOptions([]);
      return;
    }
    const params = {
      technology,
      material,
      surfaceFinish,
    }
    loadSurfaceFinishColorOptions(params);
  }, [surfaceFinish, technology, material]);

  function handleCadFilesChange(files) {
    if (files.length + cadPartListState.data.length > MAX_UPLOAD_FILES) {
      notifyError(MAX_UPLOAD_FILES_ERROR_MESSAGE);
      return;
    }
    setNextButtonEnable(false);
    Promise.all(
      Array.from(files).map(async (file) => {
        const splitFilename = file.name.split('.');
        const fileExtension = splitFilename[splitFilename.length - 1];
        const allowAnyFileUpload = isEqual(supportedFileTypes.fileTypes, ALL_FILES_TYPES)

        if (!(allowAnyFileUpload
          || supportedFileTypes.fileTypes.includes(fileExtension.toLowerCase()))) {
          nameOfInvalidFiles.push(file.name);
          return 0;
        }
        uploadCadFile(file);
      })
    );
  }

  const handleSubmitColor = (selectedColor) => {
    setColor(selectedColor || color);
  };

  const handleSubmitPart = async () => {
    if (isEmpty(cadPartListState.data)) {
      notifyError('No files were uploaded. Please upload at least one file.');
      return;
    }
    if (cadPartListState.data.length > MAX_UPLOAD_FILES) {
      notifyError(`Oops that's one too many files uploaded. Please note that you can upload a maximum of ${MAX_UPLOAD_FILES} files at one time.`);
      return;
    }
    // Quantity is only for positive numbers
    if (!qty || Number(qty) < 1) {
      notifyError('Invalid quantity.');
      return;
    }
    if (isCustomTechnology(technology) && isEmpty(otherTechnology)) {
      notifyError('Missing Custom Technology field.');
      return;
    }
    if (isCustomMaterial(material) && isEmpty(otherMaterial)) {
      notifyError('Missing Custom Material field.');
      return;
    }
    if (isCustomSurfaceFinish(surfaceFinish) && isEmpty(otherSurfaceFinish)) {
      notifyError('Missing Custom Surface Finish field.');
      return;
    }
    if (!isEmpty(surfaceFinishColorOptions) && isEmptyValue(color)) {
      notifyError('Missing Color field.');
      return;
    }
    if (PART_APPLICATION_TECHS.includes(technology) && isEmpty(partApplication)) {
      notifyError('Please fill in your desired part application.');
      return;
    }

    setLoading(true);
    setItemStatus(1);
    setUserID(myUserId);

    // Split each cad file uploaded into an individual part order
    let tolerance = TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][technology] || STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][3];
    tolerance = myUnitType === UNIT_TYPES.IMPERIAL
      ? convertMetricToImperial(tolerance)
      : tolerance;
    let dataArray = [];
    cadPartListState.data.forEach((item, index) => {
      const {
        link: cadFile,
        fileSize,
        '2DImageUrl': twoDImageUrl,
        imageConvertingError,
      } = item;
      let data = {
        id: index.toString(),
        status,
        qty,
        technology,
        otherTechnology,
        surfaceFinish,
        otherSurfaceFinish,
        material,
        otherMaterial,
        materialColor,
        cadPart: [cadFile],
        cadPartObjectList: [...cadPartListState.data],
        userID,
        tolerance,
        remarks: '',
        checked: false,
        partApplication,
        name: getItemNameFromCadFile(cadFile),
        deleted: false,
        imageFile: '',
        deliveryPreference: 'on_premise',
        color,
        price: null,
        markupPercent: null,
        totalPrice: null,
        originalPrice: null,
        fileSize: fileSize,
        expectedLeadTime: null,
        unitType: myUnitType,
        imageConvertingError,
      };
      if (twoDImageUrl) {
        data[TWO_D_IMAGE_URLS_KEY] = [twoDImageUrl];
      }
      if (is3DPTechnology(technology)) {
        data = {
          ...data,
          threeDTechnology,
          threeDInfill,
          threeDLayerThickness,
          tolerance: THREE_D_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][threeDTechnology] || STANDARD_TOLERANCE_OPTIONS[UNIT_TYPES.METRIC][1]
        }
      }
      dataArray.push(data);
    });
    dataArray = await Promise.all(dataArray.map((item) => getPpePriceForItem(item)));
    sendFormData(dataArray);
    if (isMounted.current) {
      setLoading(false);
    }
    // hide PBrain after clicked the Next Button
    if (window.PBrain) {
      window.PBrain.config({ showhide: 'hide' })
    }
  };

  const handleChangeNotHave3DCadFile = (e) => {
    const checked = e.target.checked
    if (checked) {
      setSupportedFileTypes(prev => ({
        ...prev,
        allowPdf: checked,
        fileTypes: ['pdf'],
        description: 'Please upload 1 file for each component. Additional files can be added at next stage',
        oldFileTypes: prev.fileTypes,
        oldDescription: prev.description
      }))
    } else {
      setSupportedFileTypes(prev => ({
        ...prev,
        allowPdf: checked,
        fileTypes: prev.oldFileTypes,
        description: prev.oldDescription
      }))
    }
  }

  const asteriskRequiredField = (
    <span
      style={{
        color: 'red',
        fontWeight: 'normal',
      }}
    >
      *
    </span>
  );

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmpty(surfaceFinishColorOptions)}
        value={color}
        colorInputLabel={colorInputLabel}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) => handleSubmitColor(selectedColor)}
      />
    );
  }

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmpty(materialColorOptions)}
        value={materialColor}
        colorInputLabel="Material Color"
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) => setMaterialColor(selectedColor || materialColor)}
      />
    );
  }

  const renderNextButton = () => {
    return (
      <div className={classes.buttonAreaContainer}>
        <div className={classes.pageButtonComponent}>
          <SolidBlueButton
            onBtnClick={() => handleSubmitPart()}
            btnContent='Next'
            disabled={!nextButtonEnable}
          />
        </div>
      </div>
    );
  }

  const renderUploadStatusInfo = () => {
    if (isEmpty(cadPartListState.data) && isEmpty(nameOfInvalidFiles)) {
      return null;
    }
    const totalFiles = cadPartListState.data.length;
    return (
      <div>
        {numOfUploadingFiles > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <CircularProgress
              style={{
                width: '1.5rem',
                height: '1.5rem',
              }}
              color='primary'
            />
            &nbsp;
            <Typography
              variant="body2"
            >
              Uploading {numOfUploadingFiles} {numOfUploadingFiles > 1 ? 'files' : 'file'}
            </Typography>
          </div>
        )}
        {numOfUploadedFiles !== 0 && numOfUploadedFiles === totalFiles
          && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
              >
                Uploaded&nbsp;
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'green',
                  }}
                >
                  {numOfUploadedFiles}
                </span>
                &nbsp;{numOfUploadedFiles > 1 ? 'files' : 'file'}&nbsp;
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'green',
                  }}
                >
                  successfully&nbsp;
                </span>
              </Typography>
              <Box
                style={{
                  color: 'green',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <CheckCircle color="inherit" />
              </Box>
            </div>
          )}
        {nameOfInvalidFiles.length > 0 && (<div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body2">
              <span
                style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}
              >
                {nameOfInvalidFiles.length}
              </span>
              &nbsp;{nameOfInvalidFiles.length > 1 ? 'files' : 'file'} uploaded&nbsp;
              <span
                style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}
              >
                unsuccessfully&nbsp;
              </span>
            </Typography>
            <Box
              style={{
                color: 'red',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Cancel color="inherit" />
            </Box>
          </div>
          <div style={{ display: 'block' }}>
            <Typography variant="body2">
              <span style={{ color: 'red' }}>
                Please upload supplementary drawings in the next step OR switch to an appropriate technology and try again.
              </span>
            </Typography>
            {nameOfInvalidFiles.map((name) => {
              return (
                <Typography
                  key={name}
                  variant="body2"
                  style={{
                    color: 'red',
                    display: 'block'
                  }}
                >
                  &nbsp;• {name}
                </Typography>
              );
            })}
          </div>
        </div>)}
        {is2DImageGeneratorRunning === true && (
          <div
            style={{
              marginTop: '0.5rem',
              marginBottom: '0.5rem',
            }}
          >
            <ItalicNote
              color={colors.blue050}
              variant="body2"
              text='2D Image(s) generation is running but you can still click NEXT button to continue'
            />
          </div>
        )}
      </div>
    );
  }

  const render3DPrintingTechnologySelect = () => {
    return (
      <div className={classes.dropBox}>
        <ThreeDPrintingTechnologyInputField
          visible={techIs3DPrinting}
          value={threeDTechnology}
          onChange={(newValue) => setThreeDTechnology(newValue)}
          threeDTechnologyOptions={threeDTechnologyOptions}
        />
      </div>
    );
  }

  const render3DInfillSelect = () => {
    return (
      <div className={classes.dropBox}>
        <ThreeDInfillSelectField
          visible={is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH}
          value={threeDInfill}
          onSelect={(newValue) => setThreeDInfill(newValue)}
          ppe3dpInfillOptions={ppe3dpInfillOptions}
        />
      </div>
    );
  }

  const render3DLayerThicknessSelect = () => {
    return (
      <div className={classes.dropBox}>
        <InputLabel className={classes.inputLabel}>Layer Thickness</InputLabel>
        <Select
          id='3d-layer-thickness'
          className={classes.selectField}
          input={<BootstrapInput />}
          value={threeDLayerThickness}
          onChange={(evt) => setThreeDLayerThickness(evt.target.value)}
        >
          {ppe3dpLayerThicknessOptions['FDM'].map((option) => {
            const displayedText = `${option.toFixed(2)} mm`;
            return (
              <MenuItem key={displayedText} value={option}>
                {displayedText}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  }

  const renderTechnologyInputField = () => {
    return (
      <TechnologyInputField
        value={technology}
        onChange={(newTech) => {
          setTechnology(newTech);
          setNameOfInvalidFiles([]);
        }}
        technologyOptions={technologyOptions}
        id="technology-upload-3d-files"
      />
    );
  }

  const renderPartApplicationField = () => {
    if (!showPartApplication) {
      return null;
    }
    return (
      <div className={classes.optionalBox}>
        <div className={classes.sectionFormField}>
          <InputLabel className={classes.inputLabel}>
            Part Application&nbsp;{asteriskRequiredField}
          </InputLabel>
          <InputBase
            id='partApplication'
            data-cy='part-application'
            name='partApplication'
            className={classes.inputField}
            value={partApplication}
            onChange={(evt) => setPartApplication(evt.target.value)}
            placeholder="e.g. For exhibition / Underwater use / Heat sink"
          />
        </div>
      </div>
    );
  }

  const renderMaterialCategories = () => {
    return (
      <MaterialCategoriesInputField
        technology={technology}
        visible={!techIs3DPrinting}
        value={material}
        onSelect={(value) => setMaterial(value)}
        materialCategoryOptions={materialCategoryOptions}
      />
    );
  }

  const render3DPrintingMaterialField = () => {
    return (
      <ThreeDPrintingMaterialField
        technology={technology}
        threeDTechnology={threeDTechnology}
        visible={techIs3DPrinting}
        value={material}
        onSelect={(_material) => setMaterial(_material)}
        threeDMaterialOptions={threeDMaterialOptions}
        defaultThreeDMaterial={defaultThreeDMaterial}
      />
    );
  }

  const renderSurfaceFinishingField = () => {
    return (
      <SurfaceFinishingField
        visible={!isEmpty(surfaceFinishOptions) || isCustomSurfaceFinish(surfaceFinish)}
        value={surfaceFinish}
        onSelect={(newValue) => setSurfaceFinish(newValue)}
        surfaceFinishOptions={surfaceFinishOptions}
        selectColorSurfaces={selectColorSurfaces}
      />
    );
  }

  const renderContents = () => (
    <Fragment>
      <div
        style={{
          marginBottom: 20,
          borderBottom: `1px solid ${colors.bgLightGrey}`,
        }}
      >
        <UploadPartsStepper activeStep={0} />
      </div>
      <Grid container spacing={3}>
        <Grid id="upload-parts" item xs={12} md={6}>
          <BlueDragDropMultipleParts
            showText={false}
            handleFiles={handleCadFilesChange}
            setSupportedFileTypes={setSupportedFileTypes}
            supportedFileTypes={supportedFileTypes}
          />
          {!isEqual(supportedFileTypes.fileTypes, ALL_FILES_TYPES)
            && (!supportedFileTypes.fileTypes.includes('pdf') || supportedFileTypes.allowPdf) && (
              <FormControlLabel
                id="not-have-3d-cad-file"
                control={
                  <Checkbox
                    checked={supportedFileTypes.allowPdf}
                    onChange={handleChangeNotHave3DCadFile}
                    name="checkedNotHave3DCadFile"
                  />
                }
                className={classes.checkboxFileTypes}
                label="Don't have 3D CAD available"
              />
            )}
          <div style={{ margin: '20px 0 45px' }}>
            {renderUploadStatusInfo()}
            <div>
              <List style={{ flexWrap: 'wrap' }}>
                {cadPartListState.data.map((data, index) => (
                  <FileUploadWithImageItem
                    key={index}
                    data={data}
                    onDeleteClick={removeCadPart}
                    show3dCAD
                  />
                ))}
              </List>
            </div>
          </div>
        </Grid>
        <Grid id="configure-parts" item xs={12} md={6}>
          <div>
            <InputLabel className={classes.inputLabel}>Quantity&nbsp;{asteriskRequiredField}</InputLabel>
            <InputBase
              id='quantity'
              name='quantity'
              onWheel={(e) => e.target.blur()}
              type='number'
              className={classes.inputField}
              inputProps={{
                step: 1,
                min: 0,
                max: 100000,
                type: 'number',
              }}
              value={qty}
              onChange={(evt) => {
                setItemQty(evt.target.valueAsNumber);
              }}
            />
          </div>
          <div className={classes.dropBox}>
            {renderTechnologyInputField()}
            {isCustomTechnology(technology) && (
              <div className={classes.optionalBox}>
                <InputLabel className={classes.inputLabel}>
                  Custom Technology&nbsp;{asteriskRequiredField}
                </InputLabel>
                <InputBase
                  id='otherTechnology'
                  name='otherTechnology'
                  type='text'
                  onChange={(evt) => {
                    const technology = evt.target.value;
                    setOtherTechnology(technology);
                  }}
                  className={classes.inputField}
                  value={otherTechnology}
                />
              </div>
            )}
          </div>
          {render3DPrintingTechnologySelect()}
          {render3DPrintingMaterialField()}
          {renderMaterialCategories()}
          <div className={classes.dropBox}>
            {isCustomMaterial(material) && (
              <div className={classes.optionalBox}>
                <InputLabel className={classes.inputLabel}>
                  Custom Material&nbsp;{asteriskRequiredField}
                </InputLabel>
                <InputBase
                  id='otherMaterial'
                  name='otherMaterial'
                  className={classes.inputField}
                  value={otherMaterial}
                  onChange={(evt) => {
                    setOtherMaterial(evt.target.value);
                  }}
                />
              </div>
            )}
          </div>
          {renderMaterialColorInputField()}
          {is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH && render3DInfillSelect()}
          {is3DPTechnology(technology) && threeDTechnology === THREE_D_P_FDM_TECH && render3DLayerThicknessSelect()}
          <div className={classes.dropBox}>
            {renderSurfaceFinishingField()}
            {isCustomSurfaceFinish(surfaceFinish) && (
              <div className={classes.optionalBox}>
                <InputLabel className={classes.inputLabel}>
                  Custom Surface Finish&nbsp;{asteriskRequiredField}
                </InputLabel>
                <InputBase
                  id='otherSurfaceFinish'
                  name='otherSurfaceFinish'
                  type='text'
                  onChange={(evt) => setOtherSurfaceFinish(evt.target.value)}
                  className={classes.inputField}
                  value={otherSurfaceFinish}
                />
              </div>
            )}
          </div>
          {!isEmpty(surfaceFinishColorOptions) && renderSurfaceFinishColor()}
          {renderPartApplicationField()}
          {renderNextButton()}
        </Grid>
      </Grid>
      <PpePriceLoadingBackdrop open={loading} />
    </Fragment >
  );

  return (
    <Fragment>
      <div className={classes.formComponent}>
        {renderContents()}
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    myUserId: state.auth.user.userID,
    myUnitType: state.auth.user.unitType,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(MultiplePartUploadForm);
