export const OUTPUT_TO_NON_MATCHED_FIELDS_MAPPING = {
  PAPER_SIZE: "Paper Size (Extracted)",
  SCALE: "Scale (Extracted)",
  STANDARD: "Standard (Extracted)",
  KEYWORDS: "Keywords (Extracted)",
}

export const OUTPUT_TO_MATCHED_FIELDS_MAPPING = {
  QUANTITY: "Quantity (Extracted)",
  MATERIAL: "Material (Extracted)",
  MATCHED_MATERIAL: "Material (Matched)",
  FINISH: "Finish (Extracted)",
  MATCHED_FINISH: "Finish (Matched)",
  MEASUREMENT_UNIT: "Measurement Unit (Extracted)",
  MATCHED_MEASUREMENT_UNIT: "Measurement Unit (Matched)",
  MIN_TOLERANCE: "Tightest Tolerance (Extracted)",
  COLOR: "Color (Extracted)",
  MATCHED_METRIC_TOLERANCE: "Tightest Tolerance (Matched)",
}

export const MATCHED_TDE_OUTPUT_MAPPING = {
  material: "matched_material",
  surfaceFinish: "matched_finish",
  unitType: "matched_unit_type",
  editTolerance: "matched_metric_tolerance",
  color: "color",
  editQuantity: "quantity",
  anodizingType: "anodizing_type"
}

export const OPS_TDE_OUTPUT_MAPPING = {
  material: 'ops_material',
  surfaceFinish: 'ops_finish',
  unitType: 'ops_unit_type',
  editTolerance: 'ops_tolerance',
  color: 'ops_color',
  editQuantity: 'ops_quantity',
};

export const HIDDEN_FIELDS = ["IMAGE_URL"]

export const TDE_LOCATIONS = {
  CUSTOMER_PLATFORM_STEP_2_PART_UPLOAD: 'Customer Platform Step 2 of Part Upload',
  ADMIN_PLATFORM_CREATE_PROJECT_FLOW: 'Admin Platform Create Project Flow',
  ADMIN_PLATFORM_VERIFY_OR_EDIT_ITEM: 'Admin Platform Verify Item / Edit Item',
  ADMIN_PLATFORM_GENERATION_TOOLS: 'Admin Platform Generation Tools',
  ADMIN_PLATFORM_TDE_LOGS: 'Admin Platform TDE LOGS',
}

export const VISIBLE_FIELDS = [
  "material",
  "surfaceFinish",
  "tolerance",
  "unitType",
  "qty"
]

export const TDE_RESPONSE_KEYS = {
  all_tolerances_consolidated: 'all_tolerances_consolidated',
  all_tolerances_expanded: 'all_tolerances_expanded',
  color: 'color',
  finish: 'finish',
  keywords: 'keywords',
  llm_version: 'llm_version',
  matched_finish: 'matched_finish',
  matched_imperial_tolerance: 'matched_imperial_tolerance',
  matched_material: 'matched_material',
  matched_metric_tolerance: 'llm_version',
  matched_unit_type: 'matched_unit_type',
  material: 'material',
  measurement_unit: 'measurement_unit',
  min_tolerance: 'min_tolerance',
  paper_size: 'paper_size',
  pdf_hash: 'pdf_hash',
  quantity: 'quantity',
  repeat_pdf: 'repeat_pdf',
  scale: 'scale',
  special_threads: 'special_threads',
  standard: 'standard',
}

export const CHECK_TDE_RESPONSE_VALUES = {
  special_threads: 'Not found'
}
