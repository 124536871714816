import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

import BlueButton from '../buttons/BlueButton';
import Title from '../Title';
import { downloadS3File } from '../../utils/fileUtils';
import { notifySuccess } from '../../services/notificationService';
import { FileCopy } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
}));

function MultiCheckoutDeliveryOrderFormsPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose = () => { },
    rowData = {},
  } = props;

  const renderBody = () => {
    const displayItems = rowData.deliveryOrderFormUrl ?
      rowData.deliveryOrderFormUrl.split(';')
      : [];
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {displayItems.map((url) => {
          return (
            <div
              key={url}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                lineHeight: 'normal',
              }}
            >
              <Link
                key={url}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(url);
                }}
              >
                {url.split('/').pop()}
              </Link>
              <IconButton
                edge='end'
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  notifySuccess('Url Copied');
                }}
                style={{
                  padding: 6,
                }}
              >
                <FileCopy />
              </IconButton>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Title contentTitle={`Checkout Info for Tracking ID ${rowData.id}`} size="small" />
        </DialogTitle>
        <div
          style={{
            paddingBottom: '1rem',
          }}
        >
          <DialogContent
            style={{
              padding: '0 30px',
              paddingBottom: '2rem',
              textAlign: 'center',
            }}
          >
            <React.Fragment>
              {renderBody()}
            </React.Fragment>
          </DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BlueButton
              onBtnClick={() => {
                handleClose();
              }}
              btnContent="OK"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default MultiCheckoutDeliveryOrderFormsPopup;
