import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import BlueButton from '../../buttons/BlueButton';
import Title from '../../Title';

import { colors } from '../../../palette';

import GreenTickIcon from '../../../assets/img/green-tick.png';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
    marginTop: 20,
    [theme.breakpoints.up('md')]: {
      minWidth: 800,
    },
  },
  partItem: {
    fontSize: '12pt',
    color: colors.fontGrey,
    marginBottom: 15,
    textAlign: 'center',
    fontWeight: 600,
  },
}));

function AcceptedMultipleQuotationsPopup(props) {
  const classes = useStyles();

  const { dialog, items, onBtnClick } = props;

  return (
    <Dialog
      maxWidth='sm'
      open={dialog}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <div>
          <Title
            contentTitle='Quote Accepted'
            iconEnd={
              <img
                src={GreenTickIcon}
                alt='icon'
                style={{ height: 30, marginLeft: 10 }}
              />
            }
          />
        </div>
      </DialogTitle>
      <DialogContent style={{ color: colors.fontGrey, textAlign: 'center' }}>
        {items && items.map(item => (
          <div key={item.itemID} className={classes.partItem}>{item ? item.name : ''}</div>
        ))}
        <div
          style={{
            padding: 10,
          }}
        >
          You may retrieve the quotation form and upload your own PO when you view the parts details in
          'Manage Projects'.
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 30, justifyContent: 'center' }}>
        <BlueButton
          id="check-progress-btn"
          btnContent='Check Progress'
          onBtnClick={onBtnClick}
        />
      </DialogActions>
    </Dialog>
  );
}

export default AcceptedMultipleQuotationsPopup;
