import React from 'react';

import FtrTooltip from '../FtrTooltip';
import FtrSvgImage from '../../images/FtrSvgImage';
import { FtrIconButton } from '../FtrButton';

import EmailIcon from '../../../assets/icons/email_icon.svg';


// ----------------------------------------------------------------------------------

function ShareQuoteViaEmailButton(props) {
  const {
    onClick = () => { },
  } = props;

  return (
    <FtrTooltip description='Share Quote via Email'>
      <div>
        <FtrIconButton
          onClick={onClick}
        >
          <FtrSvgImage
            src={EmailIcon}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </FtrIconButton>
      </div>
    </FtrTooltip>
  );
}

export default ShareQuoteViaEmailButton;
