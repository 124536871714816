import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { colors } from '../palette';

const StyledMenu = withStyles({
  paper: {
    border: `1px solid ${colors.lightGray}`,
    '& .MuiList-padding': {
      padding: '0',
    },
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

/**
 *
 * @param {object} props
 * @param {string} props.title if parent false, you can use title to change name button
 * @param {React.ReactElement} props.parent as a button
 * @param {React.ReactElement} props.children as a content list button
 * @param {React.MouseEventHandler} props.onClick as a content list button
 * @param {boolean} props.keep to keep dropdown when click the area
 * @param {boolean} props.showDropdown to toggle show dropdown
 * @param {string} props.buttonClassName to custom button className
 * @param {string} props.id to create specific id for test
 */

export default function MenuList(props) {
  const {
    title = 'Download',
    parent,
    children,
    onClick = () => null,
    keep = false,
    showDropdown = true,
    buttonClassName = null,
    id,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    onClick(event);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!showDropdown) {
      setAnchorEl(null);
    }
  }, [showDropdown]);

  return (
    <div
      style={{
      }}
      data-cy={id}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={handleClick}
      >
        {parent ? (
          parent
        ) : (
          <Button
            className={buttonClassName}
            aria-controls='customized-menu'
            aria-haspopup='true'
            size='small'
            variant='outlined'
            disableElevation
            style={{
              textWrap: 'wrap',
            }}
          >
            {title}
          </Button>
        )}
      </div>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div
          onClick={(event) => {
            if (keep) return;
            handleClose(event);
          }}
        >
          {children}
        </div>
      </StyledMenu>
    </div>
  );
}
