import React, { useReducer } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Radio,
  TextField,
} from '@material-ui/core';

import ChatSvgIcon from '../../assets/icons/chat.svg';

import FtrTextArea from '../ftr-components/FtrTextArea';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowSpaceBetween,
} from '../layouts/FlexLayouts';
import { FtrBoldText, FtrButton, FtrH6, FtrTypography } from '../ftr-components';
import FtrFieldLabel from '../ftr-components/FtrFieldLabel';
import ImageWith3DViewer from '../images/ImageWith3DViewer';
import KeyValueInfoDisplay from '../labels/KeyValueInfoDisplay';

import { colors } from '../../palette';

import { getUserCurrencySelector } from '../../selectors/userSelector';

import { isEmptyValue } from '../../utils/commonUtils';
import { getCurrencySymbol } from '../../utils/currencyUtils';

import { SUPPLIER_FEEDBACK } from '../../constants/ppeFeedbackConstants';

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
  option: {
    padding: '0 0 0 1rem',
    '&:hover': {
      backgroundColor: colors.neutral020,
      borderRadius: 12,
      cursor: 'pointer',
    },
  },
  optionSelected: {
    backgroundColor: colors.blue010,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: colors.blue010,
      borderRadius: 12,
      cursor: 'pointer',
    },
  },
}));

 const OPTIONS = [
  {
    text: SUPPLIER_FEEDBACK.TAKE_ORDER_WISH_PRICE_TO_REVISE,
    displayComponent: (
      <FtrTypography>
        I will take on this order but wish to see price&nbsp;
        <span style={{fontWeight: 'bold'}}>revised next time.</span>
      </FtrTypography>
    )
  },
  {
    text: SUPPLIER_FEEDBACK.TAKE_ORDER_IF_PRICE_IS_REVISED,
    displayComponent: (
      <FtrTypography>
        I will take on this order if the price is&nbsp;
        <span style={{fontWeight: 'bold'}}>revised for this order.</span>
      </FtrTypography>
    )
  },
  {
    text: SUPPLIER_FEEDBACK.NO_ACTIONS,
    displayComponent: (
      <FtrTypography>
        <span style={{fontWeight: 'bold'}}>I just wish to leave feedback.</span>&nbsp;
        No further action required.
      </FtrTypography>
    )
  },
];

const DEFAULT_FEEDBACK_DETAILS = OPTIONS.reduce((acc, opt) => {
  acc[opt.text] = false;
  return acc;
}, {});

function PriceFeedbackSupplierPopup(props) {
  const classes = useStyles();

  const {
    open,
    imageUrl,
    unitPriceStr,
    onClose = () => {},
    onSubmit = () => {},
    targetUnitPrice,
    feedbackDetails,
    additionalRemarks,
    supplierQuoted,
  } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      targetUnitPrice: targetUnitPrice || null,
      feedbackDetails: feedbackDetails || DEFAULT_FEEDBACK_DETAILS,
      additionalRemarks: additionalRemarks || null,
    }
  );

  const currency = useSelector(getUserCurrencySelector);

  const handleOptionClick = (option) => {
    const currentState = localState.feedbackDetails[option];
    const feedbackDetails = { ...localState.feedbackDetails };
    for (const feedbackOption in feedbackDetails) {
      // single selection
      feedbackDetails[feedbackOption] = feedbackOption === option ? !currentState : false;
    }
    updateLocalState({
      feedbackDetails,
    });
  };

  const handleSubmit = () => {
    onSubmit(localState);
  };

  const renderAdditionalRemarks = () => {
    return (
      <FlexColumn style={{ gap: '1rem', padding: '0 1rem', marginTop: '1rem' }}>
        <FtrBoldText>Tell us more (optional)</FtrBoldText>
        <FtrTextArea
          value={localState.additionalRemarks}
          onChange={(e) =>
            updateLocalState({ additionalRemarks: e.target.value })
          }
        />
      </FlexColumn>
    );
  };

  const renderDialogActions = () => {
    return (
      <FlexColumnCenter
        style={{
          gap: '1rem',
          marginTop: '2rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <FtrButton
          id='proceed-to-create-project-btn'
          color='blue'
          variant='contained'
          onClick={() => {
            handleSubmit();
            onClose();
          }}
          autoFocus
          fullWidth
          disabled={
            Object.values(localState.feedbackDetails).every((v) => v === false) ||
            isEmptyValue(localState.targetUnitPrice)
          }
        >
          Submit
        </FtrButton>
        <FtrButton variant='text-black' onClick={onClose}>
          Go back to quote
        </FtrButton>
      </FlexColumnCenter>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={ChatSvgIcon} alt='icon' />
      </DialogTitle>
      <DialogContent>
        <FtrH6 style={{ marginBottom: '1rem', textAlign: 'center' }}>
          How would you like to proceed?
        </FtrH6>
        <FlexRow>
          <ImageWith3DViewer
            borderRadius={0}
            twoDImageUrl={imageUrl}
            width={50}
            height={50}
            noBorder={false}
            style={{ marginLeft: 30 }}
          />
          <KeyValueInfoDisplay
            name='Unit Price'
            value={unitPriceStr}
            bigValueFontSize
            color={colors.blue050}
          />
          <FlexColumn style={{ gap: 0, width: 160, marginLeft: '1.5rem' }}>
            <FtrFieldLabel>Proposed Unit Price</FtrFieldLabel>
            <TextField
              size='small'
              type='number'
              margin='none'
              value={localState.targetUnitPrice}
              variant='outlined'
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
                startAdornment: (
                  <InputAdornment position='start'>
                    {getCurrencySymbol(currency)}
                  </InputAdornment>
                ),
              }}
              onChange={(evt) =>
                updateLocalState({ targetUnitPrice: evt.target.value })
              }
            />
          </FlexColumn>
        </FlexRow>
        <FlexColumn
          style={{
            width: '100%',
            gap: '1rem',
            padding: '1rem',
            boxSizing: 'border-box',
          }}
        >
          {OPTIONS
            .filter((option) => !supplierQuoted || option.text !== SUPPLIER_FEEDBACK.TAKE_ORDER_IF_PRICE_IS_REVISED)
            .map((option) => {
              return (
                <FlexRowSpaceBetween
                  key={option.text}
                  className={clsx(
                    classes.option,
                    localState.feedbackDetails[option.text]
                      ? classes.optionSelected
                      : null
                  )}
                  onClick={() => handleOptionClick(option.text)}
                >
                  {option.displayComponent}
                  <Radio
                    color='primary'
                    checked={localState.feedbackDetails[option.text]}
                    onClick={() => handleOptionClick(option.text)}
                  />
                </FlexRowSpaceBetween>
              );
            }
          )}
          {renderAdditionalRemarks()}
        </FlexColumn>
        <FlexColumnCenter>{renderDialogActions()}</FlexColumnCenter>
      </DialogContent>
    </Dialog>
  );
}

export default PriceFeedbackSupplierPopup;
