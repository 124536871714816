import React, { useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import AddressAutocomplete from '../../AddressAutocomplete';
import CountryDropDown from '../CountryDropDown';
import { FlexRow } from '../../layouts/FlexLayouts';
import { FtrButton, FtrTextField } from '../../ftr-components';
import { FtrH6 } from '../../ftr-components/FtrTypography';
import { FtrSmallButton } from '../../ftr-components/FtrButton';

import { createDeliveryInfo } from '../../../apis/deliveryInfoApi';

import { isEmptyValue } from '../../../utils/commonUtils';
import { getContactWithCountryCode, validatePhoneNumberByCountry } from '../../../utils/phoneNumberUtils';

import { notifyError, notifySuccess } from '../../../services/notificationService';

import { COUNTRY_NAMES } from '../../../constants/countryConstants';
import { GOOGLE_API_KEY } from '../../../constants/apiConstants';

import { colors } from '../../../palette';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: "blur(3px)",
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      borderRadius: 0,
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputField: {
    width: '100%',
    borderRadius: '10px',
    marginBottom: '0.95rem',
    background: colors.fontWhite,
    '& .MuiOutlinedInput-root': {
      color: colors.neutral070,
    },
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: 600,
    paddingBottom: '0.25rem',
    color: colors.neutral070,
  },
}));

function AddShippingAddressPopup(props) {
  const {
    open,
    onClose = () => { },
    onSuccess = () => { },
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const userID = useSelector(state => state.auth.user.userID);
  const userCountry = useSelector(state => state.auth?.user?.country ?? state.createUser?.country);
  const defaultCountry = userCountry;

  const [
    formState,
    updateFormState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      fullName: '',
      phoneNumber: '',
      callingCode: COUNTRY_NAMES.SINGAPORE,
      showCallingCode: false,
      address: '',
      country: defaultCountry,
      postalCode: '',
      unitNo: '',
    },
  );

  const requiredFields = ['fullName', 'phoneNumber', 'address', 'country'];

  const [formError, setFormError] = useState({});

  function validateForm() {
    let newFormError = { ...formError }
    for (const fieldName of requiredFields) {
      if (isEmptyValue(formState[fieldName])) {
        newFormError = {
          ...newFormError,
          [fieldName]: 'Required field',
        }
      }
    }
    setFormError(newFormError);
    if (!isEmptyValue(newFormError)) {
      return newFormError;
    }
    if (!validatePhoneNumberByCountry(
      formState.phoneNumber,
      formState.callingCode ?? COUNTRY_NAMES.SINGAPORE
    )) {
      newFormError = {
        ...newFormError,
        phoneNumber: 'Please enter a valid contact number!',
      };
    }
    setFormError(newFormError);
    return newFormError;
  }

  function handleSubmitForm() {
    setFormError({});
    const formError = validateForm();
    if (!isEmptyValue(formError)) {
      return;
    }
    const body = {
      userID,
      contactName: formState?.fullName,
      contactNumber: getContactWithCountryCode(formState?.phoneNumber, formState.callingCode),
      country: formState?.country,
      address: formState?.address,
      postalCode: formState?.postalCode,
      unitNo: formState?.unitNo,
    };
    createDeliveryInfo(body)
      .then((data) => {
        notifySuccess('Added new address successfully');
        const { deliveryInfoID } = data;
        const newAddress = {
          deliveryInfoID,
          contactName: body.contactName,
          contactNumber: body.contactNumber,
          country: body.country,
          address: body.address,
          postalCode: body.postalCode,
          unitNo: body.unitNo,
        }
        onSuccess(newAddress);
        onClose();
      })
      .catch(() => {
        notifyError('Failed created new address');
      });
  }

  const renderNameField = () => {
    return (
      <FtrTextField
        title='Full Name *'
        id='full-name'
        name='full-name'
        style={{ width: '100%' }}
        disableUnderline
        value={formState.fullName}
        onChange={(evt) => {
          setFormError({});
          updateFormState({ fullName: evt.target.value });
        }}
        error={!!formError.fullName}
        helperText={formError.fullName}
      />
    );
  };

  const renderPhoneNumberField = () => {
    return (
      <div style={{ display: !isMobile && 'flex' }}>
        <div style={{ display: 'block', width: '100%' }}>
          <Typography className={classes.inputLabel}>Contact Number *</Typography>
          <div style={{ display: 'flex', overflow: 'hidden', width: '100%' }}>
            <Slide in={formState.showCallingCode} direction='right'>
              <div
                style={{
                  width: '30%',
                  display: formState.showCallingCode ? 'block' : 'none',
                  marginRight: '0.5rem',
                }}
              >
                <CountryDropDown
                  id='countryCode'
                  showFlag={false}
                  isCountryCodeDropDown
                  onSelect={(value) => {
                    setFormError({});
                    updateFormState({ callingCode: value });
                  }}
                  valueSelected={formState.callingCode}
                  showDefaultFlag
                  overrideStyle={{ width: '100%' }}
                />
              </div>
            </Slide>
            <FtrTextField
              id='phone'
              name='full-name'
              type='number'
              style={{ width: '100%' }}
              disableUnderline
              value={formState.phoneNumber}
              onChange={(evt) => {
                setFormError({});
                updateFormState({ phoneNumber: evt.target.value });
              }}
              error={!!formError.phoneNumber}
              helperText={formError.phoneNumber}
              onFocus={() => {
                updateFormState({ showCallingCode: true });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderAddressField = () => {
    return GOOGLE_API_KEY ? (
      <AddressAutocomplete
        className={classes.inputField}
        margin="none"
        size="small"
        label={null}
        showSubFieldTitle
        onChange={({ postalCode, unitNo, address, country }) => {
          setFormError({});
          updateFormState({
            address,
            postalCode,
            unitNo,
            country,
          });
        }}
        initialValue={{
          address: '',
          postalCode: '',
          unitNo: '',
          country: defaultCountry,
        }}
        addressError={!!formError.address}
        addressHelperText={formError.address}
      />
    ) : (
      <TextField
        id='address'
        name='address'
        className={classes.inputField}
        value={formState.address}
        onChange={({ target }) => {
          setFormError({});
          updateFormState({ address: target.value });
        }}
        variant='outlined'
        size='small'
      />
    );
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      fullScreen={isMobile}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <FtrH6>
          Shipping Address
        </FtrH6>
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        {renderNameField()}
        {renderPhoneNumberField()}
        <>
          <Typography className={classes.inputLabel}>Address *</Typography>
          {renderAddressField()}
        </>
        <FlexRow
          style={{
            justifyContent: 'end',
            marginTop: '1rem',
          }}
        >
          <FtrButton
            color='black'
            size='small'
            variant='text'
            onClick={onClose}
          >
            Cancel
          </FtrButton>
          <FtrSmallButton
            onClick={handleSubmitForm}
          >
            Confirm
          </FtrSmallButton>
        </FlexRow>
      </DialogContent>
    </Dialog>
  );
}

export default AddShippingAddressPopup;
