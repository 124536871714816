import React, { useEffect, useRef, useState } from 'react';
import Fuse from 'fuse.js';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Tooltip
} from '@material-ui/core';

import {
  withStyles
} from "@material-ui/core/styles";

import InfoIcon from '@material-ui/icons/Info';

import { TreeItem, TreeView } from '@material-ui/lab';
import { FtrBanner, FtrTypography } from '../ftr-components';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
  Search as SearchIcon,
  Close as CloseIcon,
} from "@material-ui/icons";

import { getMaterialDesc } from '../../hooks/useMaterialInfo';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  buyerSelectField: {
    width: '100%',
    borderRadius: 12,
    color: '#4F4F4F',
    fontWeight: 600,
    backgroundColor:  colors.fontWhite,
  },
  selectField: {
    width: '100%',
  },
  buyerTreeItem: {
    marginLeft: '0.5rem',
    marginRight: '0.4rem',
    '& .MuiTreeItem-label': {
      padding: '0.5rem',
      userSelect: 'none',
      borderRadius: '12px',
    },
    borderRadius: '12px',
  },
  treeItem: {
    marginLeft: '0.5rem',
    '& .MuiTreeItem-label': {
      padding: '0.5rem',
      userSelect: 'none',
    },
  },
  selectContainer: {
    borderRadius: 12,
  },
}));

const DescTooltip = withStyles({
  tooltip: {
    backgroundColor: "transparent"
  }
})(Tooltip);

const DEFAULT_OPTIONS = [
  {
    category: 'Alu',
    options: [
      'ALU1',
      'ALU32',
    ],
  },
  {
    category: 'Alu2',
    options: [
      'ALU3',
      'ALU4',
    ],
  },
]

const fuseOptions = {
  isCaseSensitive: false,
  includeScore: true,
};

/**
 * This can be considered as V3 of TreeViewBootstrapDropDown
 * It uses fuzzy search to filter options
 * @param {*} props 
 * @returns 
 */
function TreeViewBootstrapDropDownFuzzySearch(props) {
  const classes = useStyles();

  const inputRef = useRef();

  const {
    technology,
    value,
    onSelect,
    onBlur = () => { },
    options: treeViewOptions = DEFAULT_OPTIONS,
    searchable = true,
    disabled = false,
    materialCategoryOptions,
    isBuyer = false,
    treeStyle,
  } = props;

  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState(value);
  const [selectedDisplay, setSelectedDisplay] = useState(value);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [currCategory, setCurrCategory] = useState(null);
  const [hoveredOption, setHoveredOption] = useState(null);

  const handleMouseEnter = (option) => {
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  useEffect(() => {
    let _selected = value;
    const _expanded = [];
    for (const treeItem of displayOptions) {
      const obj = treeItem.options.find(o => o === value);
      if (obj) {
        const { recommended } = treeItem;
        if (recommended.includes(value)) {
          // eslint-disable-next-line no-unused-vars
          _selected = `${value} (Recommended)`; // append Recommended but not used for now
        }
        _expanded.push(treeItem.category);
        _expanded.push(obj);
        break;
      }
    }
    setSelected(value);
    // setSelectedDisplay(_selected);
    setSelectedDisplay(value);
    if (isEmptyValue(filterText)) {
      setExpanded(_expanded);
    }
  }, [value, displayOptions]);

  useEffect(() => {
    if (isEmptyValue(displayOptions)) {
      return;
    }
    setCategories(displayOptions.map(o => o.category))
  }, [displayOptions]);

  useEffect(() => {
    if (isEmptyValue(treeViewOptions)) {
      return;
    }

    if (isEmptyValue(filterText)) {
      setDisplayOptions(treeViewOptions);
      return;
    }

    const _displayOptions = [];
    const _expanded = [];
    for (const categoryOption of treeViewOptions) {
      const { category, options, ...rest } = categoryOption;
      const fuse = new Fuse(options, fuseOptions);
      const filteredOptions = fuse.search(filterText.toLowerCase());
      if (isEmptyValue(filteredOptions)) {
        continue;
      }

      _displayOptions.push({
        category,
        options: filteredOptions.map(o => o.item),
        ...rest,
      });
      _expanded.push(category);
    }
    setDisplayOptions(_displayOptions);
    setExpanded(_expanded);
  }, [treeViewOptions, filterText]);


  const [
    {
      desc,
    },
    {
      setDescParams,
    }
  ] = getMaterialDesc();

  useEffect(() => {
    if (isEmptyValue(technology) || isEmptyValue(materialCategoryOptions) || isEmptyValue(hoveredOption)) {
      return;
    }
    for (const treeItem of materialCategoryOptions) {
      const obj = treeItem.options.find(o => o === hoveredOption);
      if (obj) {
        setCurrCategory(treeItem.category);
        break;
      }
    }
    const params = {
      technology,
      category: currCategory,
      material: hoveredOption,
    }
    setDescParams(params);
  }, [technology, currCategory, hoveredOption]);

  const handleToggle = (event, nodeIds) => {
    setExpanded([nodeIds[0]]);
    event.stopPropagation();
  };

  const handleSelect = (event, nodeIds) => {
    if (!categories.includes(nodeIds)) {
      setSelected(nodeIds);
      onSelect(nodeIds);
      setFilterText('');
    }
  };

  const renderSearchInput = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 9999,
          backgroundColor: 'white',
        }}
        key={`search-input-material-category`}
      >
        <IconButton
          className={classes.closeButton}
          aria-label='delete'
          size='small'
          onClick={(event) => {
            event.stopPropagation();
            setFilterText('');
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          <CloseIcon style={{ fontSize: '15pt' }} />
        </IconButton>
        <TextField
          id={`search-input-material-category`}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onFocus={(event) => {
            event.stopPropagation();
          }}
          onSelect={(event) => {
            event.stopPropagation();
          }}
          onKeyUp={(event) => {
            event.stopPropagation();
          }}
          onKeyDown={(evt) => {
            if (evt.key === "Escape") {
              setFilterText('');
            }
            evt.stopPropagation();
          }}
          inputRef={inputRef}
          value={filterText}
          onChange={(event) => {
            setFilterText(event.target.value);
            event.stopPropagation();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          autoFocus
          disabled={disabled}
        />
      </div>
    );
  }

  const renderTooltipIcon = () => {
    return (
      <div
        style={{
          marginLeft: '0.5rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DescTooltip
          title={
            <FtrBanner
              type='info'
              style={{
                backgroundColor: "white",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                color: colors.neutral080
              }}
            >
              <FtrTypography type='heading' fontSize='16'>
                {hoveredOption}
              </FtrTypography>
              <br />
              <FtrTypography type='body' fontSize='14'>
                {desc}
              </FtrTypography>
            </FtrBanner>
          }
          placement='right'
        >
          <InfoIcon style={{ color: colors.neutral040 }} />
        </DescTooltip>
      </div>
    )
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
    >
      {!isBuyer && (
        <InputLabel shrink>Material</InputLabel>
      )}
      <Select
        id='tree-view-bootstrap-dropdown'
        label={isBuyer ? undefined : 'Material'}
        className={isBuyer ? classes.buyerSelectField : classes.selectField}
        variant="outlined"
        margin="dense"
        value={selected}
        onChange={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
        }}
        onBlur={onBlur}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        renderValue={() => {
          return (
            <div>{selectedDisplay}</div>
          );
        }}
        disabled={disabled}
      >
        <div
          style={{
            maxHeight: 500,
          }}
        >
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            style={treeStyle}
          >
            {searchable && renderSearchInput()}
            {displayOptions.map(object => {
              const { options, recommended } = object;
              return (
                <TreeItem
                  key={object.category}
                  className={isBuyer ? classes.buyerTreeItem : classes.treeItem}
                  nodeId={object.category}
                  label={object.category}
                >
                  {options.map(option => {
                    const label = recommended.includes(option)
                      ? (
                        <span>
                          {option}&nbsp;
                          <span
                            style={{
                              fontStyle: 'italic',
                              color: colors.blue050,
                              fontSize: '0.8rem',
                            }}
                          >
                            (Preferred)
                          </span>
                        </span>
                      )
                      : option;
                    return (
                      isBuyer
                      ?
                      <TreeItem
                        key={option}
                        nodeId={option}
                        label={
                          <div
                            onMouseEnter={() => handleMouseEnter(option)}
                            onMouseLeave={handleMouseLeave}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                          >
                            {label}
                            {desc && (hoveredOption === option) && renderTooltipIcon()}
                          </div>
                        }
                      />
                      :
                      <TreeItem
                        key={option}
                        nodeId={option}
                        label={label}
                      />
                    )
                  })}
                </TreeItem>
              );
            })}
          </TreeView>
        </div>
      </Select>
    </FormControl>
  );
}

export default TreeViewBootstrapDropDownFuzzySearch;
