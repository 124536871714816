import React from 'react';

import {
  Link as LinkButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles(() => ({
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

function DataGridJsonDisplayCell(props) {
  const classes = useStyles();

  const {
    jsonObj,
    seeMoreButtonClick = () => { }
  } = props;

  if (!jsonObj) {
    return 'N.A.';
  }
  let content = JSON.stringify(jsonObj, null, 2) ?? '';
  const rows = content.split("\n");
  const length = rows.length;
  content = rows.slice(0, 4).join('\n');
  return (
    <div style={{
      whiteSpace: 'pre-wrap',
      lineHeight: 'normal',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    }}>
      {content}
      {length > 4 && (
        <LinkButton
          className={classes.seeMoreButton}
          variant="link"
          onClick={seeMoreButtonClick}
        >
          see more...
        </LinkButton>
      )}
    </div>
  );
}

export default DataGridJsonDisplayCell;
