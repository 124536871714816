import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { getAllPpeControlConfigs } from '../../apis/ppeSettingsApi';

import PpeAllowedOrderSelectionForm from '../../components/forms/PpeAllowedOrderSelectionForm';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  twoColumns: {
    display: 'flex',
    columnGap: '25px',
    padding: '0'
  },
  saveButtonWrapper: {},
}));

function PpeControlPanelMaterialSurfaceTab() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [materialsCheckboxState, setMaterialsCheckboxState] = useState([]);
  const [surfaceFinishesCheckboxState, setSurfaceFinishesCheckboxState] = useState([]);

  const getPpeState = () => {
    setLoading(true);
    getAllPpeControlConfigs()
      .then((response) => {
        setMaterialsCheckboxState(response.materials);
        setSurfaceFinishesCheckboxState(response.surfaceFinishes);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getPpeState();
  }, []);

  return (
    <div className={classes.body}>
      {loading === true && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={40} />
        </div>
      )}
      {loading === false && (
        <PpeAllowedOrderSelectionForm
          materialsCheckboxState={materialsCheckboxState}
          surfaceFinishesCheckboxState={surfaceFinishesCheckboxState}
        />
      )}
    </div>
  );
}

export default PpeControlPanelMaterialSurfaceTab;
