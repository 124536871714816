import { GET_SUBMITTED_PROJECT_ORDER_READY_COUNT } from "../actions/orderReady";

const defaultState = {
  submittedCount: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_SUBMITTED_PROJECT_ORDER_READY_COUNT: {
      return {
        ...state,
        submittedCount: action.payload,
      };
    }
    default:
      return {
        ...state,
      }
  }
};
