import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles/index';

import Title from '../Title';
import DefectsVisualization from '../DefectsVisualization';
import ThreeDViewer from '../../pages/generation-tools/ThreeDViewer';
import DfmDefectsForm from '../forms/DfmDefectsForm';

import { getNotEmptyValueFromObject } from '../../utils/objectUtils';
import { getCadFileTo3DRenderer } from '../../utils/fileUtils';
import { isEmptyValue } from '../../utils/commonUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: '40rem',
    maxWidth: '70rem',
    height: '45rem',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  dialogContent: {
    padding: '0 3rem',
    paddingBottom: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0.5rem',
    },
  },
}));

function DefectVisualizationPopup(props) {
  const {
    onClose,
    selectedDefectUrl: defaultDefectUrl,
    open = true,
    defectsOutput,
    cadFile,
  } = props;

  const classes = useStyles();

  const [selectedDefectUrl, setSelectedDefectUrl] = useState(
    defaultDefectUrl || getNotEmptyValueFromObject(defectsOutput?.visualization_links)
  );

  const cadFileTo3dViewer = getCadFileTo3DRenderer(cadFile);

  const renderCadRenderer = () => {
    if (!selectedDefectUrl && !cadFileTo3dViewer) {
      return null;
    }
    if (selectedDefectUrl) {
      return (
        <DefectsVisualization
          defectUrls={defectsOutput.visualization_links}
          selectedDefectUrl={selectedDefectUrl}
        />
      )
    }
    return (
      <ThreeDViewer fileUrl={cadFileTo3dViewer} hideUploadFile />
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='3d-cad-renderer-popup'
      classes={{ paper: classes.paper, root: classes.root }}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <DialogTitle id='3d-viewer-popup'>
        <Title contentTitle='Defect Visualization' size='small' />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid items xs={!isEmptyValue(defectsOutput) ? 6 : 12}>
            {renderCadRenderer()}
          </Grid>
          <Grid items xs={6}>
            <DfmDefectsForm
              {...props}
              handleView3DDefect={(defectUrl) => {
                // if the defectUrl is already selected, unselect it
                setSelectedDefectUrl(selectedDefectUrl === defectUrl ? null : defectUrl);
              }}
              selectedDefectUrl={selectedDefectUrl}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default DefectVisualizationPopup;
